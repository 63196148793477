import React from 'react';
import HotelSearchComponent from '../../components/hotel/HotelSearchComponent';
import { connect } from 'react-redux'
import { bannerAction, noticeAction,getHotelCitiesAction ,getNationalityDetails} from '../../actions/shared/sharedAction';
import { hotelSearchSaveQuery } from '../../actions/hotel/hotelSearchAction';
import {filterhotelResults} from '../../actions/hotel/hotelSearchDetailsAction'

import { getCountryDetails,getlocation } from "../../actions/shared/registerAction";

class HotelSearch extends React.Component {
    componentDidMount()
    {
        let productUrl  = 'hotel-booking';
        let pageUrl     = 'searching';
        this.props.bannerAction('/'+productUrl+'/'+pageUrl);//call banner API
        this.props.noticeAction('/'+productUrl);//call notice API
        //this.props.getlocation();
        this.props.getCountryDetails();
    }
    render() {
        return (
           <HotelSearchComponent {...this.props}/> 
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bannerResponse: state.shared.bannerResponse,
        noticeResponse: state.shared.noticeResponse,
        citiesResponse: state.shared.hotelCitiesResponse,
        getNationalityDetails: state.shared.getNationalityDetails,
        locations          : state.register.locations,
        country_details : state.register.countryDetails,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        bannerAction: (credentials)   => dispatch(bannerAction(credentials)),
        noticeAction: (credentials)   => dispatch(noticeAction(credentials)),
        getHotelCitiesAction:(credentials, successCb, errorCb) => dispatch(getHotelCitiesAction(credentials, successCb, errorCb)),
        getNationalityAction:(credentials, successCb, errorCb) => dispatch(getNationalityDetails(credentials, successCb, errorCb)),
        getlocation :()               => dispatch(getlocation()),//get country ,state and city
        hotelSearchSaveQuery:(formData) => dispatch(hotelSearchSaveQuery(formData)),
        filterhotelResults: (filters)  => dispatch(filterhotelResults(filters)),
        getCountryDetails: () => dispatch(getCountryDetails()), //For get all country details like Nationality, Short name 
    }
};

export default connect(mapStateToProps,mapDispatchToProps) (HotelSearch)