import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTrainCancelDetail, emailTrainCreditNote } from '../../actions/railway/railwayAction';
import PrintRailwayCreditNoteComponent from '../../components/railway/PrintRailwayCreditNoteComponent';

class PrintRailwayCreditNoteContainer extends Component {
    constructor() {
        super();
        this.state = {
            booking_id: null
        }
    }
    componentDidMount() {
        let data = localStorage.getItem('print-train-credit-note');

        let booking_id = JSON.parse(data).booking_id;
        this.setState({
            booking_id: booking_id
        })
        const booking = {
            "booking_id": parseInt(booking_id)
        };

        this.props.getTrainCancelDetail(booking);
    }
    render() {
        return (
            <React.Fragment>
                <PrintRailwayCreditNoteComponent {...this.props} booking_id={this.state.booking_id} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        trainCancelDetails: state.railwayReducer.trainCancelDetails,
        settingResponse: state.shared.settingResponse,
        accountResponse: state.myAccount,//my account details
        trainEmailCreditNote: state.railwayReducer.trainEmailCreditNote
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTrainCancelDetail: (data, successCb, errorCb) => dispatch(getTrainCancelDetail(data, successCb, errorCb)),
        emailTrainCreditNote: (data, successCb, errorCb) => dispatch(emailTrainCreditNote(data, successCb, errorCb))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintRailwayCreditNoteContainer)   