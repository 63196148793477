import React, { Component } from 'react';
import DepositsComponent from '../../components/agent/deposits/DepositsComponent';
import { connect } from 'react-redux'
import { myDepositRequestAction, depositTypeAction, submitNewDepositRequest, getBankDetail, myAccountAction } from '../../actions/agentAction';
import { getCurrentDate } from '../../shared/commonHelper';
import { Formik } from "formik";
import { logoutAction } from '../../actions/shared/sessionAction';
import moment from 'moment';

class AgentDeposit extends Component {

    constructor(props) {
        super(props);
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            loading: true,
            error: null,
            submitDepositMsg: null,
            perPageLimit: 10,
            searchValues: "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'),
            currentPage: 1,
            hideMsg: false,
            showConfirmationPopup: false,
            currentDate: current_date,
            confimationAction : null,
            showLoaderOnPopup: false,
        };
    }

    componentDidMount() {
        this.setState({
            searchValues: "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'),
            currentPage: 1,
            submitDepositMsg: null,
        });
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
        this.props.myDepositRequestAction(limitOffset, "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'));
        this.props.depositTypeAction();
        this.props.getBankDetail();
    }

    //***************************************Start Confimation Popup********************************************/
    handleConfirmationPopup = (event, action) => {
        this.setState({
            showConfirmationPopup : event,
            confimationAction : action
        })
    }
    //***************************************End Confimation Popup*********************************************/

    submitRequestForm(values) {
        this.setState({
            showLoaderOnPopup : true
		})
        var defaultDepositTypeId = this.props.accountResponse.depositTypeResponse.response && (this.props.accountResponse.depositTypeResponse !== null ? this.props.accountResponse.depositTypeResponse.response[0].deposit_type_id : '');
        var defaultBeneficiary = this.props.accountResponse.agentBankDetail.response && ((this.props.accountResponse.agentBankDetail.response !== null && this.props.accountResponse.agentBankDetail.response.response.length > 0) ? this.props.accountResponse.agentBankDetail.response.response[0].bank_id : '');

        const formValues = {
            "amount": parseInt(values.amount),
            "date_deposit": values.date_deposit,
            "deposit_type_id": (values.deposit_type_id !== '' ? parseInt(values.deposit_type_id) : parseInt(defaultDepositTypeId)),
            "bank_id": (values.beneficiary_name !== '' ? values.beneficiary_name : defaultBeneficiary),
            "cheque_dd_number": values.cheque_dd_number,
            "requester_bank_name": values.requester_bank_name,
            "requester_bank_account_number": values.requester_bank_account_number,
            "requester_bank_branch": values.requester_bank_branch,
            "date_issueance": values.date_issueance,
            "remarks": values.remarks,
        };
        const successCb = () => {
            this.setState({
                submitDepositMsg: "success",
                searchValues: "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'),
                currentPage: 1,
                hideMsg: false,
                showLoaderOnPopup : false
            });
            var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
            this.props.myDepositRequestAction(limitOffset, this.state.searchValues);
            this.setState({showConfirmationPopup : false})

        }
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({ submitDepositMsg: 'error', hideMsg: false });
        }
        this.props.submitNewDepositRequest(formValues, successCb, errorCb);
        values.amount = '';
        values.cheque_dd_number = '';
        values.requester_bank_name = '';
        values.requester_bank_account_number = '';
        values.requester_bank_branch = '';
        values.date_issueance = '';
        values.remarks = '';
    }

    showReplyForm = () => {
        this.setState({ hideMsg: true });
    };
    searchRequestForm(values) {
        var searchDate = "";
        if (values.start_date && values.end_date) {
            searchDate = "&start_date=" + values.start_date + "&end_date=" + values.end_date;
        }
        this.setState({
            searchValues: searchDate,
            currentPage: 1,
        });
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
        this.props.myDepositRequestAction(limitOffset, searchDate);
    }
    getRecordsPerPage(activePage) {
        this.setState({ currentPage: activePage });
        var offset = (activePage - 1) * this.state.perPageLimit;
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=" + offset;
        this.props.myDepositRequestAction(limitOffset, this.state.searchValues);
    }
    render() {
        const initialValues = {
            start_date: this.state.currentDate,
            end_date: this.state.currentDate,
        };
        return (
            <Formik
                initialValues={initialValues} //Set initial values of fields to blank
                onSubmit={(values) => {
                    this.searchRequestForm(values);
                }}
                render={formikProps =>
                    <DepositsComponent  {...this.props} {...this.state} {...formikProps} submitRequestForm={this.submitRequestForm.bind(this)} handleConfirmationPopup={this.handleConfirmationPopup} searchRequestForm={this.searchRequestForm.bind(this)} getRecordsPerPage={this.getRecordsPerPage.bind(this)} showReplyForm={this.showReplyForm} />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse: state.shared.productResponse,
        pagesResponse: state.shared.pagesResponse,
        agentBankDetails: state.myAccount.agentBankDetail,
        agentProductAccess: state.shared.agentProductAccess,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        myDepositRequestAction: (limitOffset, searchValues) => dispatch(myDepositRequestAction(limitOffset, searchValues)),
        depositTypeAction: () => dispatch(depositTypeAction()),
        submitNewDepositRequest: (data, successCb, errorCb) => dispatch(submitNewDepositRequest(data, successCb, errorCb)),
        getBankDetail: () => dispatch(getBankDetail()),
        logoutAction: () => dispatch(logoutAction()),
        myAccountAction: () => dispatch(myAccountAction())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentDeposit)
