import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import BookingSearch from '../../agent/bookings/MyBookingSearchComponent';
import HotelBookingSearch from '../../agent/bookings/HotelBookingSearchComponent';
import history from "../../../shared/history";
import TrainBookingSearchComponent from './TrainBookingSearchComponent';

class AgentBookings extends Component {
    constructor(props) {
        super();
        this.state = {
            hideMsg: false,
            leftNavActive: '/my-bookings',
            url: history.location.pathname,
            releasePnrStatus: null,
            releasedBooking: null,
            showConfirmationPopup: false,
            clickedProductCode: '',
            productType: null,
            checkPnrStatus: null,
            pnrBooking: null,
            stausHotelTripJackBooking:null
        }
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    redirectToTermsAndCondition() {
        window.open("/cancellation-terms-and-conditions", "_blank");
    }

    releaseHoldPnr = (values) => {
        let data = {
            "booking_id": values.booking_id,
            "tbo_booking_id": values.transaction_no,
            "source": values.source,
        }
        this.setState({
            releasedBooking: values.booking_id
        })
        const successCb = () => {
            let self = this;
            setTimeout(function () { self.props.getMyBookings(self.props.limitOffSet); }, 5000);
            this.setState({
                releasePnrStatus: 'true'
            })
        }
        const errorCb = () => {
            this.setState({
                releasePnrStatus: 'false'
            })
        }
        this.props.releasePnr(data, successCb, errorCb);
    }

    releasePendingPnr = (values) => {
        let data ={
            "kaf_booking_id":values.kafila_booking_id,
            "booking_id":values.booking_id,
            "offered_fare": values.fare.offered_fare, 
            "agent_discount_type":"per_passenger",
            "category_name":"Domestic",
            "pnr_check" : true,
        }
        this.setState({
            pnrBooking: values.booking_id
        })
        const successCb = () => {
            let self = this;
            setTimeout(function () { self.props.getMyBookings(self.props.limitOffSet); }, 5000);
            this.setState({
                checkPnrStatus: 'true'
            })
        }
        const errorCb = () => {
            this.setState({
                checkPnrStatus: 'false'
            })
        }
        this.props.kafilaPnrCheck(data, successCb, errorCb);
    }
    

    chekStatusHotelTripJackApi = (values,limitOffset) => {
        let data ={
            "tripjack_bookingId":values.tripjack_booking_id,
            "booking_id":values.booking_id,
        }
        this.setState({
            stausHotelTripJackBooking: values.booking_id
        })
        const successCb = () => {
            setTimeout(() => {
                 this.props.getMyHotelBookings(limitOffset);
            }, 5000);
            
                
            this.setState({
                checkPnrStatus: 'true'
            })
        }
        const errorCb = () => {
            this.setState({
                checkPnrStatus: 'false'
            })
        }
        this.props.hotelTripjackCheckStatusDetails(data, successCb, errorCb);
    }


    setClickedProductCode = (product_url, product_code) => {
        let pageDescription;
        if (this.props.pagesResponse.response) {
            //process the page response and set the description as per the clicked category
            /* eslint-disable-next-line */
            this.props.pagesResponse.response.pages['my_transaction'].map((page, index) => {
                if (page.product_code === product_code) {
                    pageDescription = page.page_description;
                }
            })
        }

        this.props.history.push({
            pathname: "/" + product_url,
            state: {
                clickedProductCode: product_code,
                pageDescription: pageDescription
            }
        });
    }

    releaseHoldTripJack = (values) => {
        let data = {
            "booking_id": values.booking_id,
            "tripjack_bookingId": values.tripjack_booking_id,
            "pnr": values.airline_pnr,
        }
        this.setState({
            releasedBooking: values.booking_id
        })
        const successCb = () => {
            let self = this;
            setTimeout(function () { self.props.getMyBookings(self.props.limitOffSet); }, 5000);
            this.setState({
                releasePnrStatus: 'true'
            })
        }
        const errorCb = () => {
            this.setState({
                releasePnrStatus: 'false'
            })
        }
        this.props.releaseTripJack(data, successCb, errorCb);
    }

    pnrCheckedTripJack = (values) => {
        let bookingData = '';
        bookingData = {
            "pnr": values && values.pnr,
            "booking_id": parseInt(values.booking_id),
            "offered_fare": Number(values && values && values.fare.offered_fare),
            "agent_discount": Number(values && values && values.fare.agent_discount),
            "service_charge": Number(values && values && values.fare.service_charge),
            "cgst": Number(values && values && values.fare.cgst),
            "igst": Number(values && values && values.fare.igst),
            "sgst": Number(values && values && values.fare.sgst),
			"category_name" : values && values.category_name,
            "passengers": [
                {
                    "pax_id": values && values && values.passengers && values.passengers[0].pax_id,
                    "pax_type": parseInt(values && values && values.passengers && values.passengers[0].pax_type),
                    "pax_title": values && values && values.passengers && values.passengers[0].pax_title,
                    "ticket_id": values && values && values.passengers && values.passengers[0].ticket_id,
                    "ticket_no": values && values && values.passengers && values.passengers[0].ticket_no,
                    "ticket_status": values && values && values.passengers && values.passengers[0].ticket_status,
                    "validating_airline": values && values && values.passengers && values.passengers[0].validating_airline,
                    "first_name": values && values && values.passengers && values.passengers[0].first_name,
                    "last_name": values && values && values.passengers && values.passengers[0].last_name,
                    "dateofbirth": values && values && values.passengers && values.passengers[0].dateofbirth,
                    "passport_no": values && values && values.passengers && values.passengers[0].passport_no,
                    "passport_expire_date": values && values && values.passengers && values.passengers[0].passport_expire_date,
                    "passport_issue_place": values && values && values.passengers && values.passengers[0].passport_issue_place,
                    "passport_issue_date": values && values && values.passengers && values.passengers[0].passport_issue_date,
                    "ff_no": values && values && values.passengers && values.passengers[0].ff_no,
                    "visa_type": values && values && values.passengers && values.passengers[0].visa_type,
                    "city": values && values && values.passengers && values.passengers[0].city,
                    "country_code": values && values && values.passengers && values.passengers[0].country_code,
                    "country_name": values && values && values.passengers && values.passengers[0].country_name,
                    "nationality": values && values && values.passengers && values.passengers[0].nationality,
                    "contact_no": values && values && values.passengers && values.passengers[0].pax_title,
                    "email": values && values && values.passengers && values.passengers[0].email,
                    "address_line1": values && values && values.passengers && values.passengers[0].address_line1,
                    "address_line2": values && values && values.passengers && values.passengers[0].address_line2,
                    "is_lead_pax": values && values && values.passengers && values.passengers[0].is_lead_pax,
                    "gst_company_address": values && values && values.passengers && values.passengers[0].gst_company_address,
                    "gst_company_contact_number": values && values && values.passengers && values.passengers[0].gst_company_contact_number,
                    "gst_company_email": values && values && values.passengers && values.passengers[0].gst_company_email,
                    "gst_company_name": values && values && values.passengers && values.passengers[0].gst_company_name,
                    "gst_number": values && values && values.passengers && values.passengers[0].gst_number,
                    "fare": {
                        "base_fare": values && values && values.fare.pax_title
                    },
                }
            ],
            "segments": [
                {
                    "origin": values && values && values.segments && values.segments[0][0].origin,
                    "destination": values && values && values.segments && values.segments[0][0].destination,
                    "airline_code": values && values && values.segments && values.segments[0][0].airline_code,
                    "flight_number": values && values && values.segments && values.segments[0][0].flight_no,
                    "origin_airport_name": values && values && values.segments && values.segments[0][0].origin_airport_name,
                    "origin_airport_terminal": values && values && values.segments && values.segments[0][0].origin_airport_terminal,
                    "destination_airport_name": values && values && values.segments && values.segments[0][0].destination_airport_name,
                    "destination_airport_terminal": values && values && values.segments && values.segments[0][0].destination_airport_terminal,
                    "airline_pnr": values && values && values.segments && values.segments[0][0].airline_pnr ? values && values && values.segments && values.segments[0][0].airline_pnr : null,
                    "fare_class": values && values && values.segments && values.segments[0][0].fare_class,
                    "stop_point": values && values && values.segments && values.segments[0][0].stop_point,
                    "stop_point_arrival_time": values && values && values.segments && values.segments[0][0].stop_point_arrival_time,
                    "stop_point_departure_time": values && values && values.segments && values.segments[0][0].stop_point_departure_time,
                },
            ],
            "fare": {
                "published_fare": values && values && values.fare.published_fare,
                "offered_fare": values && values && values.fare.offered_fare,
                "other_charges": values && values && values.fare.other_charges,
                "tax": values && values && values.fare.tax,
                "total_baggage_charges": values && values && values.fare.total_baggage_charges,
                "total_meal_charges": values && values && values.fare.total_meal_charges,
                "total_seat_charges": values && values && values.fare.total_seat_charges,
            },
            "tds": values && values && values.fare.tds,
            "source": values && values.source,
            "drop_booking": false,
            "price_modified": {
                "yq_tax": 0,
                "tbo_tds": 0,
                "admin_unique_id": 0,
                "agent_unique_id": 0,
                "tds": 0,
                "agent_discount": 0,
                "tbo_earned": 0,
                "other_charges": 0,
                "base_fare": 0,
                "service_charge_amount": 0,
                "sgst": 0,
                "cgst": 0,
                "igst": 0,
                "offered_fare": 0,
                "published_fare": 0,
                "tax": 0
            },
            "origin": values && values.origin,
            "destination": values && values.destination,
            "booking_type": values && values && values.booking_type,
            "tripjack_booking_id": values && values && values.tripjack_booking_id,
            "tripjack_bookingId": values && values && values.tripjack_booking_id,
            "pnr_check" : true,
        }
        this.setState({
            pnrBooking: values.booking_id
        })
        const successCb = () => {
            let self = this;
            setTimeout(function () { self.props.getMyBookings(self.props.limitOffSet); }, 5000);
            this.setState({
                checkPnrStatus: 'true'
            })
        }
        const errorCb = () => {
            this.setState({
                checkPnrStatus: 'false'
            })
        }
        this.props.tripJackBookDetails(bookingData, successCb, errorCb);
    }


    render() {
        const { accountResponse, productResponse, agentProductAccess } = this.props;
        let activeLeftNavigation = this.state.url;
        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props} />
                {/*nav-section end*/}
                <section className="mid-wrapper">
                    <AgentCommonHeader {...this.props} />
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction} />
                                    {this.props.type === 'my-bookings' ?
                                        <div className="profile-detail-info">
                                            <div className="edit-profile-bar">
                                                <div className="pull-right">
                                                    { /* eslint-disable-next-line */}
                                                    <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                                </div>
                                            </div>
                                            <div className="tabs-container-detail">
                                                <div className="mark-up-info">
                                                    <div className="tabs-top">
                                                        <div className="tabs-title">
                                                            <h2><i className="fa fa-exchange"></i><span> Booking Category </span></h2>
                                                        </div>
                                                    </div>
                                                    <div className="category-list updates-list">
                                                        <ul>
                                                            {
                                                                !agentProductAccess.loading
                                                                    ?
                                                                    agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0
                                                                        ?
                                                                        agentProductAccess.response.user_access_info.map((products, index) => {
                                                                            let product_icon;
                                                                            if (products.product_name === 'Flight Booking') {
                                                                                product_icon = "fa fa-exchange";
                                                                            } else if (products.product_name === 'Hotel Booking') {
                                                                                product_icon = "fa fa-bed";
                                                                            } else if (products.product_name === 'Train Booking') {
                                                                                product_icon = "fa fa-train";
                                                                            } else if (products.product_name === 'Tour Packages') {
                                                                                product_icon = "fa fa-ticket";
                                                                            }
                                                                            return (
                                                                                <li key={index} onClick={() => this.setClickedProductCode("view-" + products.product_url, products.product_code)}>
                                                                                    {/* <Link to={"view-"+products.product_url}> */}
                                                                                    <div className="category-card">
                                                                                        <figure><i className={product_icon}></i></figure>
                                                                                        <div className="category-card-bottom">
                                                                                            <h3>{products.product_name}</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* </Link> */}
                                                                                </li>
                                                                            );
                                                                        })
                                                                        :
                                                                        <li>
                                                                            <div className="category-card">
                                                                                <div className="category-card-bottom">
                                                                                    No results available.
                                                                                    </div>
                                                                            </div>
                                                                        </li>
                                                                    :
                                                                    <div className="loader relative-loader" style={{ "display": "block" }}>
                                                                        <div className="loader-content">
                                                                            <div className="loader-ring blue-ring">
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    {this.props.type === 'view-flight-booking' ? <BookingSearch pnrBooking={this.state.pnrBooking} checkPnrStatus={this.state.checkPnrStatus} releasedBooking={this.state.releasedBooking} releasePnrStatus={this.state.releasePnrStatus} releaseHoldPnr={this.releaseHoldPnr} releaseHoldTripJack={this.releaseHoldTripJack} releasePendingPnr={this.releasePendingPnr} pnrCheckedTripJack={this.pnrCheckedTripJack} {...this.props} showCancelTicket={this.showCancelTicket} clickedProductCode={this.state.clickedProductCode} /> : ''}
                                    {this.props.type === 'view-hotel-booking' ? <HotelBookingSearch {...this.props} clickedProductCode={this.state.clickedProductCode} showHotelCancelTicket={this.showHotelCancelTicket}  checkPnrStatus={this.state.checkPnrStatus} stausHotelTripJackBooking={this.state.stausHotelTripJackBooking} chekStatusHotelTripJackApi={this.chekStatusHotelTripJackApi} /> : ''}
                                    {this.props.type === 'view-train-booking' ? <TrainBookingSearchComponent {...this.props} clickedProductCode={this.state.clickedProductCode} showFailBooking={this.showFailBooking} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )

    }
}

export default (AgentBookings)