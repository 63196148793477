import React, { Component } from 'react';
import CreditsComponent from '../../components/agent/credits/CreditsComponent';
import { connect } from 'react-redux'
import { myViewCreditRequestAction,addCreditRequestAction, myAccountAction} from '../../actions/agentAction';
import { getCurrentDate } from '../../shared/commonHelper';
import { Formik } from "formik";
import { logoutAction } from '../../actions/shared/sessionAction';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

class AgentCredits extends Component {
    constructor(props) {
        super(props);
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            loading: true,
            error: null,
            addCreditRequest:null,
            perPageLimit: 10,
            searchValues:"&start_date="+moment().format('YYYY/MM/DD')+"&end_date="+moment().format('YYYY/MM/DD'),
            currentPage:1,
            showConfirmationPopup : false,
            currentDate :current_date,
            confimationAction : null,
            showLoaderOnPopup : false,
        };
    }
    componentDidMount() {
        this.setState({
            searchValues: "&start_date="+moment().format('YYYY/MM/DD')+"&end_date="+moment().format('YYYY/MM/DD'),
            currentPage: 1,
            addCreditRequest:null,
        });
        var limitOffset = "?limit="+this.state.perPageLimit+"&offset=0";
        this.props.myViewCreditRequestAction(limitOffset,"&start_date="+moment().format('YYYY/MM/DD')+"&end_date="+moment().format('YYYY/MM/DD'));
       
    }

    //***************************************Start Confimation Popup********************************************/
	handleConfirmationPopup = (event, action)=>{
		this.setState({
			showConfirmationPopup : event,
            confimationAction : action
		})		
	}
    //***************************************End Confimation Popup*********************************************/
    submitCreditForm(values){
        this.setState({
            showLoaderOnPopup : true
		})
        const formValues = {
            "amount":parseInt(values.amount),
            "remarks": values.remarks 
        }
        
        const successCb = () => {
            this.setState({
                addCreditRequest:"success",
                searchValues: "&start_date="+moment().format('YYYY/MM/DD')+"&end_date="+moment().format('YYYY/MM/DD'),
                currentPage: 1,
                showLoaderOnPopup : false
            });
            
            var limitOffset = "?limit="+this.state.perPageLimit+"&offset=0";
            this.props.myViewCreditRequestAction(limitOffset,this.state.searchValues);
            this.setState({showConfirmationPopup : false})
        }
        const errorCb = () => {
            this.setState({addCreditRequest:"error",showLoaderOnPopup : false});
            this.handleConfirmationPopup(false)
        }
       this.props.addCreditRequestAction(formValues,successCb,errorCb);
       values.amount = '';
       values.remarks = '';
       
    }

    searchRequestForm(values)
    {
        var searchDate ="";
        if(values.start_date && values.end_date)
        {
            searchDate = "&start_date="+values.start_date+"&end_date="+values.end_date;
        }
        this.setState({
            searchValues: searchDate,
            currentPage: 1,
        });
        var limitOffset = "?limit="+this.state.perPageLimit+"&offset=0";
        this.props.myViewCreditRequestAction(limitOffset,searchDate);
    }
    getRecordsPerPage(activePage) {
        this.setState({currentPage: activePage});
        var offset = (activePage-1)*this.state.perPageLimit;
        var limitOffset = "?limit="+this.state.perPageLimit+"&offset="+offset;
        this.props.myViewCreditRequestAction(limitOffset,this.state.searchValues);     
    }
    renderRedirect = () => {
        return <Redirect to='/my-account' />
    }
    render() {
        const initialValues = {
            start_date: this.state.currentDate,
            end_date: this.state.currentDate,
        };
        let is_admin_parent = this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.is_credit_allowed && this.props.accountResponse.myAccountResponse.response.is_credit_allowed.toLowerCase() === 'y'
        return (
            !is_admin_parent ?
            this.renderRedirect()
            :
            <Formik
                    initialValues={initialValues} //Set initial values of fields to blank
                        onSubmit={(values) => {
                        this.searchRequestForm(values);
                    }}
                    render={formikProps =>
                        <CreditsComponent  {...this.props} {...formikProps} {...this.state} submitCreditForm={this.submitCreditForm.bind(this)} handleConfirmationPopup={this.handleConfirmationPopup} searchRequestForm={this.searchRequestForm.bind(this)} getRecordsPerPage={this.getRecordsPerPage.bind(this)} />
                    }
            />              
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse : state.shared.productResponse,
        pagesResponse   : state.shared.pagesResponse,
        agentProductAccess : state.shared.agentProductAccess,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        myViewCreditRequestAction: (limitOffset,searchValues) => dispatch(myViewCreditRequestAction(limitOffset,searchValues)),
        addCreditRequestAction:(data, successCb, errorCb) => dispatch(addCreditRequestAction(data, successCb, errorCb)),
        logoutAction        : () => dispatch(logoutAction()),
        myAccountAction : () => dispatch(myAccountAction())
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(AgentCredits)
