
import {
    CHECK_USERNAME,
    USERNAME_AVAILABLE,
    USERNAME_NOT_AVAILABLE,
    AGENT_REGISTRATION,
    AGENT_REGISTRATION_OK,
    AGENT_REGISTRATION_ERR,
    FILE_UPLOAD_START,
    FILE_UPLOAD_OK,
    FILE_UPLOAD_ERR,
    OWNERSHIP_START,
    OWNERSHIP_OK,
    OWNERSHIP_ERR,
    ADDRESSPROOF_START,
    ADDRESSPROOF_OK,
    ADDRESSPROOF_ERR,
    LOCATION_START,
    LOCATION_OK,
    LOCATION_ERR,
    COUNTRY_DETAILS_START,
    COUNTRY_DETAILS_OK,
    COUNTRY_DETAILS_ERR
} from '../../actions/shared/registerAction';

export const initialSessionState = {
    checkusername : {
        error: null,
        loading: false,
        success : null
    },
    register : {
        error: null,
        loading: false,
        success : null,
        response: null
    },
    uploadedFile : {
        error: null,
        loading: false,
        response : null
    },
    ownershipTypes : {
        error: null,
        loading: false,
        response : null
    },
    addressProof : {
        error: null,
        loading: false,
        response : null
    },
    locations : {
        error: null,
        loading: false,
        response : null
    },
    countryDetails : {
        error: null,
        loading: false,
        response : null
    }
};


export function register(state = initialSessionState, action) {
    switch (action.type) {
    // to check availibility of username=============================
        case CHECK_USERNAME:
        return {
            ...state, 
            checkusername:  {
                error: false,
                loading: true
            }
        };
        case USERNAME_AVAILABLE:
            return {
                ...state, 
                checkusername:  {
                    error   : false,
                    loading : false,
                    success: true
                }
            };

        case USERNAME_NOT_AVAILABLE:
        return {
            ...state, 
            checkusername : {
                error: true,
                loading: false,
                success: false
            }
        };
    // For agent registration=============================        
        case AGENT_REGISTRATION:
        return {
            ...state, 
            register : {
                error: false,
                loading: true
            }
        }

        case AGENT_REGISTRATION_OK:
        return {
            ...state, 
            register : {
                error   : false,
                loading : false,
                success: true
            }
        }

        case AGENT_REGISTRATION_ERR:
        return {
            ...state, 
            register : {
                error: true,
                loading: false,
                success: false,
                response: action.payload
            }
        }
        // For uploading file=============================        
        case FILE_UPLOAD_START:
        return {
            ...state, 
            uploadedFile : {
                error: false,
                loading: true
            }
        }

        case FILE_UPLOAD_OK:
        return {
            ...state, 
            uploadedFile : {
                error   : false,
                loading : false,
                response: action
            }
        }

        case FILE_UPLOAD_ERR:
        return {
            ...state, 
            uploadedFile : {
                error: true,
                loading: false,
                success: false
            }
        }
        // For getting type of ownership data=============================        
        case OWNERSHIP_START:
        return {
            ...state, 
            ownershipTypes : {
                error: false,
                loading: true
            }
        }

        case OWNERSHIP_OK:
        return {
            ...state, 
            ownershipTypes : {
                error   : false,
                loading : false,
                response: action.payload
            }
        }

        case OWNERSHIP_ERR:
        return {
            ...state, 
            ownershipTypes : {
                error: true,
                loading: false,
                response: false
            }
        }
        // For getting type of addressproof data=============================  
        case ADDRESSPROOF_START:
        return {
            ...state, 
            addressProof : {
                error: false,
                loading: true
            }
        }

        case ADDRESSPROOF_OK:
        return {
            ...state, 
            addressProof : {
                error   : false,
                loading : false,
                response: action.payload
            }
        }

        case ADDRESSPROOF_ERR:
        return {
            ...state, 
            addressProof : {
                error: true,
                loading: false,
                response: false
            }
        }
        // For getting locations data=============================  
        case LOCATION_START:
        return {
            ...state, 
            locations : {
                error: false,
                loading: true
            }
        }

        case LOCATION_OK:
        return {
            ...state, 
            locations : {
                error   : false,
                loading : false,
                response: action.payload
            }
        }

        case LOCATION_ERR:
        return {
            ...state, 
            locations : {
                error: true,
                loading: false,
                response: false
            }
        }

        // For getting country data=============================  
        case COUNTRY_DETAILS_START:
        return {
            ...state, 
            countryDetails : {
                error: false,
                loading: true
            }
        }

        case COUNTRY_DETAILS_OK:
        return {
            ...state, 
            countryDetails : {
                error   : false,
                loading : false,
                response: action.payload
            }
        }

        case COUNTRY_DETAILS_ERR:
        return {
            ...state, 
            countryDetails : {
                error: true,
                loading: false,
                response: false
            }
        }

        default:
            return state;
    }
}


