import React, { Component } from 'react';
import TopUpDetails from '../../components/agent/topup/AgentTopUpComponent';
import { connect } from 'react-redux'
import { logoutAction, generateTokenAction } from '../../actions/shared/sessionAction';
import { getCurrentDate } from '../../shared/commonHelper';
import { Formik } from "formik";
import { topUpPaymentInitAction, topUpPaymentResponseAction, allTopupFetchAction, myAccountAction } from '../../actions/agentAction';
import moment from 'moment';

class AgentTopUpDetails extends Component {

    constructor(props) {
        super(props);
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            loading: true,
            error: null,
            perPageLimit: 10,
            searchValues: "",
            currentPage: 1,
            currentDate: current_date,
        };
    }
    componentDidMount() {
        this.setState({
            searchValues: "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'),
            currentPage: 1,
            addCreditRequest: null,
        });
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
        this.props.allTopupFetchAction(limitOffset, "&start_date=" + moment().format('YYYY/MM/DD') + "&end_date=" + moment().format('YYYY/MM/DD'));

    }

    getRecordsPerPage = (activePage) => {
        this.setState({ currentPage: activePage });
        var offset = (activePage - 1) * this.state.perPageLimit;
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=" + offset;
        this.props.allTopupFetchAction(limitOffset, this.state.searchValues);
    }

    searchRequestForm = (values) => {
        var searchDate = "";
        if (values.start_date && values.end_date) {
            searchDate = "&start_date=" + values.start_date + "&end_date=" + values.end_date;
        }
        this.setState({
            searchValues: searchDate,
            currentPage: 1,
        });
        var limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
        this.props.allTopupFetchAction(limitOffset, searchDate);
    }

    render() {
        const initialValues = {
            start_date: this.state.currentDate,
            end_date: this.state.currentDate,
        };
        return (
            <Formik
                initialValues={initialValues} //Set initial values of fields to blank
                onSubmit={(values) => {
                    this.searchRequestForm(values);
                }}
                render={formikProps =>
                    <TopUpDetails {...this.props} {...this.state} {...formikProps} getRecordsPerPage={this.getRecordsPerPage} searchRequestForm={this.searchRequestForm} />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse: state.shared.productResponse,
        pagesResponse: state.shared.pagesResponse,
        agentProductAccess: state.shared.agentProductAccess,
        allTopUpInfo: state.myAccount.allTopUpInfo,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        logoutAction: () => dispatch(logoutAction()),
        topUpPaymentInitAction: (credentials, successCb, errorCb) => dispatch(topUpPaymentInitAction(credentials, successCb, errorCb)),
        topUpPaymentResponseAction: (credentials, successCb, errorCb) => dispatch(topUpPaymentResponseAction(credentials, successCb, errorCb)),
        allTopupFetchAction: (successCb, errorCb) => dispatch(allTopupFetchAction(successCb, errorCb)),
        generateTokenAction: (credentials, successCb, errorCb) => dispatch(generateTokenAction(credentials, successCb, errorCb)),
        myAccountAction: () => dispatch(myAccountAction()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentTopUpDetails)
