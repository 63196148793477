import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RootContainer from './containers/shared/RootContainer';
import reducer from './reducers';
import middleware from './middleware';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
const store = createStore(reducer, middleware);

/* DEBUG: add the store to the window to enable calling methods in the console */
window.store = store;

ReactDOM.render(
                <Provider store={store}>
                    <RootContainer/>
                </Provider>
                , document.getElementById('root'));
