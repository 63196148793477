import React from 'react';
import { dateFormat ,jsUcfirst} from '../../../shared/commonHelper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as flightHelper from '../../../shared/flightHelper';

class FlightReviewBookingComponent extends React.Component {
    constructor(){
        super()
        this.state={
            showOfferedPrice : null,
            buttonClickType  : null
        }
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }
    // Fare Rule Information
    renderFareRule(flightFareRuleDetails) {
        let flightFareRulesData = flightFareRuleDetails.response;
        let fairRuleSeatOriginDestination = '';
        let fairRules = [];
        let tripJackFlight = false;
        // let airiqFlight = false;
        // let eflyFlight=false
        // let kafilaFlight=false
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return'  || this.props.location.state.searchType === 'multi-city') && this.props.location.state.data.api_source === "tripjack")
        {
            tripJackFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[this.props.location.state.index].api_source === "tripjack"){
            tripJackFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "omairiq")
        {
            // airiqFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[this.props.location.state.index].api_source === "omairiq"){
            // airiqFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "omairiq")	
        {	
            // eflyFlight = true;	
        }	
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[this.props.location.state.index].api_source === "efly"){	
            // eflyFlight = true;	
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "kafila")
        {
            // kafilaFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[this.props.location.state.index].api_source === "kafila"){
            // kafilaFlight = true;
        }

        if(tripJackFlight  && flightFareRulesData && Object.keys(flightFareRulesData) && Object.keys(flightFareRulesData).length && Object.keys(flightFareRulesData)[0] !== '0')
        {
            fairRuleSeatOriginDestination = flightFareRulesData['fareRule'] ?  Object.keys(flightFareRulesData['fareRule'])  :'';
            flightFareRulesData['fareRule'] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            flightFareRulesData['fareRule'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index) => {
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'][fareRule]).length > 0 &&
                        Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'][fareRule]).forEach((item, key) => {
                            let rulesArrKey = ""
                            if (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== undefined || flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== undefined) {
                                rulesArrKey = (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== '' ?
                                    flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st + " hr - " : '') +
                                    (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '' ?
                                        (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '8760' ?
                                            flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et + ' hr' :
                                            (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et / 24) + ' days') : '');
                            } else {
                                rulesArrKey = flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].pp
                            }
                            if (!Array.isArray(fairRules[rulesArrKey])) {
                                fairRules[rulesArrKey] = [];
                            }
                            if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                                fairRules[rulesArrKey][fareRule] = [];
                            }
                            fairRules[rulesArrKey][fareRule] = flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item];
                        })
                }
            })
        }
		return (
            <PerfectScrollbar>
                <div className="inner-fare-block">
                    {

                        tripJackFlight === true && flightFareRulesData && flightFareRulesData['fareRule'] &&
                            Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] !== '0' ?

                            <React.Fragment>
                                <p>{fairRuleSeatOriginDestination}</p>
                                <div className="table-outer">
                                    <div className="table-container content-block-cms">
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr>
                                                    <th width="15%">Time Frame</th>
                                                    <th style={{ minWidth: '100px' }}>Cancellation Fee</th>
                                                    <th>Date Changes Fee</th>
                                                    <th>No Show</th>
                                                    {/* <th width="16%">Seat Chargeable</th> */}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index) => {

                                                        let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                                        let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                                        let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                                        // let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                                        let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                                        let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                                        let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                                        let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>' + fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                                        // let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                                        // let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';

                                                        fairRuleCancellationFee = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount + fairRuleCancellationFeeAdFee && "  " + fairRuleCancellationFeeAmount + " " + fairRuleCancellationFee;
                                                        fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange : fairRuleDateChangeAmount + fairRuleDateChangeAdFee && "  " + fairRuleDateChangeAmount + " " + fairRuleDateChange;

                                                        return (<tr key={index}>
                                                            <td><div dangerouslySetInnerHTML={{ __html: fareRule.replaceAll("_", " ") }} /></td>
                                                            <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee }} /> </td>
                                                            <td><div dangerouslySetInnerHTML={{ __html: fairRuleDateChange }} /> </td>
                                                            <td><div dangerouslySetInnerHTML={{ __html: fairRuleNoShow }} /></td>
                                                            {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <br />
                                        <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                        <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                        <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                        <p>For more information Please contact our helpdesk.<br /></p>
                                    </div>
                                </div>

                            </React.Fragment> : tripJackFlight === true &&
                            flightFareRulesData.map(function (data, index) {
                                
                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                return (
                                    <React.Fragment key={index}>
                                        <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                        <div className='content-block-cms'><div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} /></div>
                                    </React.Fragment>
                                )
                            })
                    }
    {
                        // kafilaFlight && this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare_rules&&this.props.fareQuote.response.results.fare_rules.length>0 ?
                        // <React.Fragment>
                        //                 <h5>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h5>
                        //                 <div className="table-outer">
                        //                      <div className="table-container">
                        //                          <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        //                              <thead>
                        //                                  <tr>
                        //                                      <th width="15%">Time Frame</th>
                        //                                      <th>Cancellation Fee</th>
                        //                                      <th>Date Changes Fee</th>
                        //                                     { /*<th>No Show</th>*/}
                        //                                      {/* <th width="16%">Seat Chargeable</th> */}
                                                             
                        //                                  </tr>
                        //                              </thead>
                        //                              <tbody>
                        //                                  {
                        //                                      this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare_rules.map((item,index)=>{
                        //                                             return  (<tr key= {index}>
                        //                                             <td><div dangerouslySetInnerHTML={{__html: item.time_frame}}/></td>
                        //                                             <td><div dangerouslySetInnerHTML={{ __html: item.cancellation_fee}}/> </td>
                        //                                             <td><div  dangerouslySetInnerHTML={{ __html: item.date_change_fee}}/> </td>
                        //                                             {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                        //                                             {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                        //                                         </tr>)
                        //                                     })                                                                                                          
                        //                                   }
                        //                              </tbody>
                        //                          </table>
                        //                          <br />
                        //                              <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                        //                              <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                        //                              <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                        //                              <p>For more information Please contact our helpdesk.<br /></p>
                        //                      </div>
                        //                  </div>
                        //              </React.Fragment> : <React.Fragment>Fare Rules Are Not Available</React.Fragment>
                    }
                        {
                        tripJackFlight === false && flightFareRulesData && flightFareRulesData.map((item,index) => {
                         
                            return (
                                <React.Fragment key={index}>
                                    <p className='clear_both'>
                                        {item.airline}
                                        {(this.props.location.state && this.props.location.state.data &&
                                            (
                                                (this.props.location.state.data.api_source === "gofly" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "gofly")) ||
                                                this.props.location.state.data.api_source === "omairiq" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "omairiq") ||
                                                this.props.location.state.data.api_source === "efly" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "efly") ||
                                                this.props.location.state.data.api_source === "satkar" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "satkar") ||
                                                this.props.location.state.data.api_source === "fbq" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "fbq") ||
                                                this.props.location.state.data.api_source === "riya_travel" || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "riya_travel")
                                            )
                                        )
                                            ? ''
                                            : ':'}{' '}
                                        {item.origin}-{item.destination}
                                        <br />
                                    </p>
                                    <div className='content-block-cms'>
                                        {item.fare_rule_detail ? (
                                            <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                        ) : (
                                            <p>Fare Rules not available.</p>
                                        )}
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </PerfectScrollbar>
            );
	}

    render() {
        //let index = this.props.location.state.index
        // let data = this.props.location.state.data;
        let hideHold = false;
        let kafilaTicketButton = false;
        let tripJackFlight = false;
        let airIQFlight = false;
        let goflyFlight = false;
        let eflyFlight=false;
        let satkarFlight = false;
        let riyaFlight = false;
        let fareBoutiqueFlight = false;
        let tboFlight = false;
        let flightIndex = this.props.location.state.index ? this.props.location.state.index : 0;
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source ==="kafila"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            kafilaTicketButton = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'multi-city') && this.props.location.state.data.api_source === "tripjack")
        {
            tripJackFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "tripjack"){
            this.props.ssrDetails.loading = false;
            tripJackFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "kafila"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            kafilaTicketButton = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "omairiq")
        {
            hideHold = true;
            airIQFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "omairiq"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            airIQFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "gofly")
        {
            hideHold = true;
            goflyFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "gofly"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            goflyFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "efly")
        {
            hideHold = true;
            eflyFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "efly"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            eflyFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "satkar")
        {
            hideHold = true;
            satkarFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "satkar"){
            this.props.ssrDetails.loading = false;
            hideHold = true;
            satkarFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "riya_travel")
        {
            
            riyaFlight = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "riya_travel"){
            this.props.ssrDetails.loading = false;
         
            riyaFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.data.api_source === "fbq")
            {
                hideHold = true;
                fareBoutiqueFlight = true;
            }
            if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')  && this.props.location.state.data[flightIndex].api_source === "fbq"){
                this.props.ssrDetails.loading = false;
                hideHold = true;
                fareBoutiqueFlight = true;
            }

        if ((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'multi-city') && this.props.location.state.data.api_source === "tbo") {
            tboFlight = true;
        }
        else if ((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return') && this.props.location.state.data[flightIndex].api_source === "tbo") {
            this.props.ssrDetails.loading = false;
            tboFlight = true;
        }
        // let seat = this.props.getTotalSeatPrice();
        // let totalFare = parseInt(this.props.fare.totalFare) + parseInt(this.props.fare.extraBaggage) + parseInt(this.props.fare.meal);
        //Check if account balance is sufficient to pay ticket amount
        let accountResponse = this.props.accountResponse.myAccountResponse.response
        let totalAccountBalance = 0;
        if(accountResponse){
            if(parseInt(accountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(accountResponse.credit_limit);
            }
        }
        let offeredFare = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare.offered_fare;
        let showInsuffient = false
        if(totalAccountBalance < offeredFare){
            showInsuffient = true;
        }
        let isBA = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.isBA;
        let isLcc = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.is_lcc;
        let isAllowedHoldBooking = accountResponse && accountResponse.allow_hold_booking;
        let isRiyaHoldBooking = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.hold_allowed
        let isHoldAllowed = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.isHoldAllowed;
        
        function timeConversion(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            if (rhours.toString().length < 2)
                rhours = "0" + rhours;
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes.toString().length < 2)
                rminutes = "0" + rminutes;
            return `${rhours}h:${rminutes}m`
        }

        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return (
            <div className="tabs-content-wrap">
                <div className="step-content">
                    <h3 className="step-title"><span>Booking Summary</span> </h3>
                    
                    <div className="review-filght-step">
                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Flight detail</span> </div>
                                <div className="cols pull-right"></div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                {
                                    this.props.fareQuote.loading
                                    ?
                                    <div className="loader relative-loader" style={{display: 'block'}}>
                                        <div className="loader-content"><div className="loader-ring blue-ring"> 
                                    <span/> </div></div></div>
                                    :
                                    this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments &&
                                    this.props.fareQuote.response.results.segments.map((itemListing,keyLisitng) => {
                                        let stopData = flightHelper.getFlightStops(itemListing);

                                        return (
                                            <div className="flight-details" key={keyLisitng}>
                                                <div className="flight-date"> <i className="icon icon-flight" /> <span>{dateFormat(this.props.searchQuery.segments[keyLisitng].preferred_departure_time,'DD MMM YYYY, FDAY')}</span> </div>
                                                <ul>
                                                    {
                                                        stopData.map((item,key) => {

                                                            let originTerminal = '';
                                                            let destinationTerminal = '';
                                                            if(item.origin_terminal !== '' && item.origin_terminal){
                                                                if(tripJackFlight)
                                                                {
                                                                    originTerminal = <React.Fragment>- <strong>{item.origin_terminal}</strong></React.Fragment>;
                                                                }
                                                                else
                                                                {
                                                                    originTerminal = <React.Fragment>- <strong>T-{item.origin_terminal}</strong></React.Fragment>;
                                                                }
                                                            }
                                                            if(item.destination_terminal !== '' && item.destination_terminal){

                                                                if(tripJackFlight)
                                                                {
                                                                    destinationTerminal = <React.Fragment>- <strong>{item.destination_terminal}</strong></React.Fragment>;
                                                                }
                                                                else
                                                                {
                                                                    destinationTerminal = <React.Fragment>- <strong>T-{item.destination_terminal}</strong></React.Fragment>;
                                                                }
                                                            }
                                                            return (
                                                                <li key={key}>
                                                                    <div className="flight-card">
                                                                        <div className="traveller-flight">
                                                                            <figure className="pull-left"><img src={item.airline.flight_logo ? item.airline.flight_logo : ''} alt="" /></figure>
                                                                            <div className="flight-dat"> <span>{item.airline.airline_name+', '+item.airline.airline_code+(item.airline.flight_number ? (" - "+item.airline.flight_number) : "")}</span>
                                                                                {
                                                                                    kafilaTicketButton === false && airIQFlight === false && goflyFlight === false && eflyFlight === false &&
                                                                                    <p>{(item && item.airline && item.airline.operating_carrier_name) ? ('Operated By - ' + item.airline.operating_carrier_name) : (item && item.airline && item.airline.operating_carrier) ? ('Operated By - ' + item.airline.operating_carrier) : null}</p>
                                                                                }
                                                                                {
                                                                                    airIQFlight === false && goflyFlight === false && eflyFlight===false&& fareBoutiqueFlight === false && <p>Aircraft - {item.craft}</p>
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight-stops">
                                                                            <div className="flight-start">
                                                                                <h3>{item.origin_city} </h3>
                                                                                <span>{dateFormat(item.origin_dep_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.origin_airport_name} {originTerminal}</p>
                                                                            </div>
                                                                            <div className={
                                                                                item.cabin_class_name !== null && (
                                                                                    (item.stop_over && item.stop_over.length >= 1) || item.stop_over
                                                                                )
                                                                                    ? "flight-direction flight-luggage flight-cabin flight-time"
                                                                                    : (item.cabin_class_name !== null
                                                                                        ? "flight-direction flight-luggage flight-cabin"
                                                                                        : "flight-direction flight-luggage")
                                                                            }>
                                                                                <div className="fl-mid-inner">
                                                                                    <ul>
                                                                                        {
                                                                                            (kafilaTicketButton === true || airIQFlight === true || goflyFlight === true || eflyFlight === true  || fareBoutiqueFlight === true ) &&
                                                                                            <li>
                                                                                                <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{flightHelper.getDuration(itemListing, 'h')}</span>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            tripJackFlight === true && hideHold === false &&
                                                                                            <li>
                                                                                                <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)} </span>
                                                                                            </li>
                                                                                        }
                                                                                        {
                                                                                            tripJackFlight === false && hideHold === false &&
                                                                                            <li>
                                                                                                <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)} </span>
                                                                                            </li>
                                                                                        }
                                                                                        {/* {
                                                                                            riyaFlight === true &&
                                                                                            <li>
                                                                                                <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{timeConversion(item.duration)} </span>
                                                                                            </li>
                                                                                        } */}
                                                                                        {
                                                                                            item.cabin_baggage ?
                                                                                                <li className="mid-meal"> <i className="material-icons">card_travel</i> <span>{item.cabin_baggage}</span> </li> : null
                                                                                        }
                                                                                        {
                                                                                            item.baggage ?
                                                                                                <li> <i className="material-icons">shopping_cart</i> <span>{item.baggage}</span> </li> : null
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                                {/* round trip  normal return start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === 'tripjack' && this.props.location.state.searchType === "special-return" && //segment.length === 1 &&

                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>

                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && (this.props.location.state.data.api_source === 'tbo'|| this.props.location.state.data.api_source === 'kafila' || this.props.location.state.data.api_source === 'riya_travel') && this.props.location.state.searchType === "special-return" &&//segment.length === 1 &&
                                                                                    <div className='time-col' style={{ display: item.stop_over ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/* round trip  normal return end*/}


                                                                                {/* ======================================*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.searchType === "round-trip-complete" && //segment.length === 1 &&

                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>

                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.searchType === "round-trip-complete" &&//segment.length === 1 &&
                                                                                    <div className='time-col' style={{ display: item.stop_point_full_name ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/* ======================================*/}

                                                                                {/* round trip  special return international start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === 'tripjack' && this.props.location.state.searchType === "lcc-special-return" && //segment.length === 1 &&

                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>

                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && (this.props.location.state.data.api_source === 'tbo' || this.props.location.state.data.api_source === 'kafila' || this.props.location.state.data.api_source === 'riya_travel') && this.props.location.state.searchType === "lcc-special-return" &&//segment.length === 1 &&
                                                                                    <div className='time-col' style={{ display: item.stop_over ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/*  round trip  special return international end*/}

                                                                                {/* round trip  special return domestic start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.searchType === "lcc-special-return" && //segment.length === 1 &&

                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>

                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.searchType === "lcc-special-return" &&//segment.length === 1 &&
                                                                                    <div className='time-col' style={{ display: item.stop_point_full_name ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/*  round trip  special return domestic end*/}

                                                                                {/* one way  start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === 'tripjack' && this.props.location.state.searchType === "one-way" && //segment.length === 1 &&

                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>

                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.data && (this.props.location.state.data.api_source === 'tbo' || this.props.location.state.data.api_source === 'kafila' || this.props.location.state.data.api_source === 'riya_travel') && this.props.location.state.searchType === "one-way" &&//segment.length === 1 &&
                                                                                    <div className='time-col' style={{ display: item.stop_over ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/* one way  end*/}
                                                                                {/* round trip domestic start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.searchType === "round-trip" && this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "tripjack" &&
                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.searchType === "round-trip" && ((this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "tbo") || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "kafila") || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "riya_travel")) &&
                                                                                    <div className='time-col' style={{ display: item.stop_over ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/* round trip domestic end*/}

                                                                                {/* multi city  start*/}
                                                                                {
                                                                                    this.props.location && this.props.location.state && this.props.location.state.searchType === "multi-city" && this.props.location.state.data && this.props.location.state.data.api_source === "tripjack" &&
                                                                                    <div className='time-col' style={{ display: item.stop_over && item.stop_over.length >= 1 ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {flightHelper.getCityCodeTripJack(item)}</span>
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                   this.props.location &&
                                                                                   this.props.location.state &&
                                                                                   this.props.location.state.searchType === "multi-city" &&
                                                                                   this.props.location.state.data &&
                                                                                   (this.props.location.state.data.api_source === "tbo" || this.props.location.state.data.api_source === "kafila" || this.props.location.state.data.api_source === "riya_travel")
                                                                                    &&
                                                                                    <div className='time-col' style={{ display: item.stop_over ? "block" : "none" }}>
                                                                                        <span style={{ fontWeight: "bold", color: "black" }}>Technical Stop Via {item.stop_point_full_name}</span>
                                                                                    </div>
                                                                                }
                                                                                {/* multi city  end*/}
                                                                                <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                                {
                                                                                   item.cabin_class_name !== null && 
                                                                                   <div className='cabin-info'>
                                                                                    <strong>Cabin : </strong>
                                                                                    <span>{item.cabin_class_name}</span>
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                            <div className="flight-start flight-landing">
                                                                                <h3>{item.destination_city}</h3>
                                                                                <span>{dateFormat(item.destination_arr_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.destination_airport_name} {destinationTerminal}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                {/* {
                                    this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.is_remark_available === true && this.props.fareQuote.response.results.airline_remark !== '' && <div className="fl-sme"><span>{this.props.fareQuote.response.results.airline_remark}</span></div>
                                } */}
                                
                            </div>
                        </div>

                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Passenger Details</span> </div>
                                <div className="cols pull-right">  </div>
                            </div>
                            <div className= "review-step-deatil">
                                <div className="step-deatil-outer">
                                    <div className="passenger-information">
                                        <div className="passenger-fl-info">
                                        {
                                            this.props.passengers.map((item,index) => {
                                                let paxType = '';
                                                if(item.passenger_type ===1)
                                                    paxType = 'Adult';
                                                else if(item.passenger_type ===2)
                                                    paxType = 'Child';
                                                else 
                                                    paxType = 'Infant';
                                              
                                                let titleName = item.title_name;
                                                if ((paxType === 'Adult') && (titleName === 'Mr' || titleName === 'Mstr'|| titleName === "Miss")) {
                                                    titleName = 'MR';
                                                   
                                                }else if((paxType === "Child" ||paxType === "Infant") && (titleName === 'Mr' || titleName === "Ms" || titleName === "Mrs")){
                                                    titleName = 'Mstr'
                                                }
                                                return (
                                                <div className="cols" key={index}>
                                                    <div className="adult-title"><span>Passenger {index+1}</span> <small>({paxType})</small></div>
                                                        <ul className="psg-list">
                                                            <li>
                                                                <span>Name:</span>
                                                                <small style={{textTransform: 'uppercase'}}>
                                                                    {titleName + ' ' + capitalize(item.first_name) + ' ' + capitalize(item.last_name)}
                                                                </small>
                                                            </li>
                                                            <li>
                                                                <span>Gender:</span>
                                                                <small>{jsUcfirst(item.gender)}</small>
                                                            </li>
                                                            <li  style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Mobile No:</span>
                                                                <small>{item.mobile_no}</small>
                                                            </li>
                                                            {
                                                                item.date_of_birth &&
                                                                <li style={{ display: (item.date_of_birth) ? 'none' : 'block' }}>
                                                                    <span>DOB:</span>
                                                                    <small>{dateFormat(item.date_of_birth, 'DD MMM YYYY')}</small>
                                                                </li>
                                                            }
                                                            
                                                            <li  style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Email:</span>
                                                                <small>{item.email_id}</small>
                                                            </li>
                                                            <li style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Address:</span>
                                                                <small>{item.addressLine1+''+(item.addressLine2?item.addressLine2:'')}</small>
                                                            </li>
                                                            {
                                                                !tripJackFlight && !riyaFlight && item.meal_dynamic.length > 0 &&
                                                                    item.meal_dynamic.map((itemInner,indexInner) => {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Meal Preferences:</span>
                                                                                <small>{itemInner.airline_description+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                             {
                                                                riyaFlight && !tripJackFlight && item.meal_dynamic.length > 0 &&
                                                                    item.meal_dynamic.map((itemInner,indexInner) => {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Meal Preferences:</span>
                                                                                <small>{itemInner.description+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            {
                                                                kafilaTicketButton && item.baggage.length > 0 &&
                                                                item.baggage.map((itemInner, indexInner) => {
                                                                    const numericWeightMatch = itemInner.weight && itemInner.weight.match(/\d+/);
                                                                    const numericWeight = numericWeightMatch ? parseFloat(numericWeightMatch[0]) : null;
                                                                    if (numericWeight  > 0) {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.weight + 'KG' + '(' + itemInner.origin + '-' + itemInner.destination + ')'}</small>
                                                                            </li>
                                                                        )
                                                                    }else if(numericWeight === null)
                                                                        {
                                                                            return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.weight+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        ) 
                                                                    }else{return null;}
                                                                })
                                                            }
                                                            {
                                                                !tripJackFlight && !kafilaTicketButton && item.baggage.length > 0 &&
                                                                item.baggage.map((itemInner, indexInner) => {
                                                                    if (itemInner.weight > 0) {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.weight + 'KG' + '(' + itemInner.origin + '-' + itemInner.destination + ')'}</small>
                                                                            </li>
                                                                        )
                                                                    }else if(parseFloat(itemInner.weight&&itemInner.weight.match(/\d+/)[0])>0)
                                                                        {
                                                                            return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.weight+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        ) 
                                                                    }else{return null;}
                                                                })
                                                            }
                                                            {
                                                                !tripJackFlight && !kafilaTicketButton && !riyaFlight && item.seat_dynamic.length > 0 &&
                                                                    item.seat_dynamic.map((itemInner,indexInner) => {
                                                                 
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Seat:</span>
                                                                                <small>{itemInner.row_no+itemInner.seat_no+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            {
                                                                kafilaTicketButton && !riyaFlight && item.seat_dynamic.length > 0 &&
                                                                    item.seat_dynamic.map((itemInner,indexInner) => {
                                                          
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Seat:</span>
                                                                                <small>{itemInner.code+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            {
                                                                paxType !=='Infant' && tripJackFlight && !riyaFlight && item.meal_dynamic.length > 0 &&
                                                                    item.meal_dynamic.map((itemInner,indexInner) => {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Meal Preferences:</span>
                                                                                <small>{itemInner.desc+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            
                                                            {
                                                                paxType !=='Infant' && tripJackFlight && !riyaFlight && item.baggage.length > 0 &&
                                                                    item.baggage.map((itemInner,indexInner) => {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.desc+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            {
                                                                paxType !=='Infant' && riyaFlight && !tripJackFlight && item.baggage.length > 0 &&
                                                                    item.baggage.map((itemInner,indexInner) => {
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Baggage:</span>
                                                                                {/* eslint-disable-next-line */}
                                                                                <small>{itemInner.description+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                            {
                                                                paxType !=='Infant' && tripJackFlight && item.seat_dynamic.length > 0 &&
                                                                    item.seat_dynamic.map((itemInner,indexInner) => {
                                                                       
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Seat:</span>
                                                                                <small>{itemInner.seatNo+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                             {
                                                                 paxType !=='Infant' && !kafilaTicketButton && !tripJackFlight && riyaFlight && item.seat_dynamic.length > 0 &&
                                                                    item.seat_dynamic.map((itemInner,indexInner) => {
                                                                    
                                                                        return (
                                                                            <li key={indexInner}>
                                                                                <span>Seat:</span>
                                                                                <small>{itemInner.SeatName+'('+itemInner.origin+'-'+itemInner.destination+')'}</small>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                        </ul>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        {/* <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>TRAVELLERS detail</span> </div>
                                <div className="cols pull-right"></div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                    <div className="travellers-details">
                                        <div className="travellers">
                                            <div className="travellers-head">
                                                <div className="cols"><i className="icon icon-adult" /><span>Adult ({this.props.searchQuery.adult_count})</span></div>
                                                <div className="cols">
                                                {
                                                    this.props.searchQuery.child_count > 0 && 
                                                    <React.Fragment>
                                                    <i className="icon icon-child" /><span>Child ({this.props.searchQuery.child_count})</span></React.Fragment>
                                                }
                                                </div>
                                                <div className="cols">
                                                {
                                                    this.props.searchQuery.infant_count > 0 && 
                                                    <React.Fragment>
                                                    <i className="icon icon-infant" /><span>Infant ({this.props.searchQuery.infant_count})</span></React.Fragment>
                                                }
                                                </div>
                                            </div>
                                            <div className="travellers-list">
                                                <div className="cols">
                                                    <ul>
                                                        {
                                                            this.props.passengers.map((item,key) => {
                                                                if(item.passenger_type === 1)
                                                                    return (
                                                                    <li key={key}>{(parseInt(key)+1)+'. '+item.title_name+" "+jsUcfirst(item.first_name)+" "+jsUcfirst(item.last_name)} (<span>Mobile:</span> {item.mobile_no})</li>
                                                                    )
                                                                else
                                                                    return false;
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="cols">
                                                    <ul>
                                                        {
                                                            this.props.passengers.map((item,key) => {
                                                                if(item.passenger_type === 2)
                                                                    return (
                                                                        <li key={key}>{(parseInt(key)+1)+'. '+item.title_name+" "+jsUcfirst(item.first_name)+" "+jsUcfirst(item.last_name)}</li>
                                                                    )
                                                                else
                                                                    return false;
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="cols">
                                                    <ul>
                                                        {
                                                            this.props.passengers.map((item,key) => {
                                                                if(item.passenger_type === 3)
                                                                    return (
                                                                        <li key={key}>{(parseInt(key)+1)+'. '+item.title_name+" "+jsUcfirst(item.first_name)+" "+jsUcfirst(item.last_name)}</li>
                                                                    )
                                                                else
                                                                    return false;
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                 
                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                <div className="cols pull-right"> </div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                    <div className="fare-rule-info">
                                        {
                                            this.renderFareRule(tripJackFlight ? this.props.tripJackFlightFareRuleDetails : airIQFlight ? this.props.airIQFlightFareRuleDetails : kafilaTicketButton ? this.props.kafilaFlightFareRuleDetails : goflyFlight ? this.props.goflyFlightFareRuleDetails : eflyFlight ? this.props.eflyFlightFareRuleDetails: satkarFlight ? this.props.satkarFlightFareRuleDetails: fareBoutiqueFlight ? this.props.fareBoutiqueFlightFareRuleDetails: riyaFlight ? this.props.riyaFlightFareRuleDetails : this.props.flightFareRuleDetails )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Ticket Amount</span> </div>
                                <div className="cols pull-right"> 
                                    <a className="collapse-icon refresh-icon" href="javascript:void(0);" onClick={() => this.offeredPriceDisplay()}>
                                        <img src="assets/images/offer-icon-2.svg" alt="" />
                                    </a> 
                                </div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                    <div className="price-breakup">
                                        <h3 className="price-title">PRICE BREAKUP</h3>
                                        <ul className="price-table">
                                            <li>
                                                <div className="pull-left">Sub Total <span>({this.props.searchQuery.adult_count} Adult(s), {this.props.searchQuery.child_count} Child, {this.props.searchQuery.infant_count} Infant)</span></div>
                                                <div className="pull-right"><i className="icon icon-rupee" /><span>{this.props.fareQuote.response && 
                                                this.props.fareQuote.response.results && currencyFormat(parseInt(this.props.fareQuote.response.results.fare.published_fare) + parseInt(seat))}</span></div>
                                            </li>
                                        </ul>
                                        <div className="total-adults-price">
                                            <div className="pull-left">Total <span>({this.props.searchQuery.adult_count} Adult(s), {this.props.searchQuery.child_count} Child, {this.props.searchQuery.infant_count} Infant)</span></div>
                                            <div className="pull-right"><i className="icon icon-rupee" /><span>{this.props.fareQuote.response && 
                                                this.props.fareQuote.response.results && currencyFormat(parseInt(this.props.fareQuote.response.results.fare.published_fare) + parseInt(seat))}</span></div>
                                        </div>
                                    </div>
                                    <div className="price-breakup net-price" style={{display:this.state.showOfferedPrice?'block':'none'}}>
                                        <ul className="price-table">
                                            <li>
                                                <div className="pull-left">Discount(-)  </div>
                                                <div className="pull-right"><i className="icon icon-rupee" /><span> {this.props.fareQuote.response && 
                                                this.props.fareQuote.response.results && currencyFormat(this.props.fareQuote.response.results.fare.discount)}</span></div>
                                            </li>
                                        </ul>
                                        <div className="total-adults-price">
                                            <div className="pull-left">Net Payable Amount </div>
                                            <div className="pull-right"><i className="icon icon-rupee" /><span>{this.props.fareholdhistoryQuote.response && 
                                                this.props.fareQuote.response.results && currencyFormat(this.props.fareQuote.response.results.fare.offered_fare)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <span className="insufficient_balance
                        " style={{display:showInsuffient?'block':'none'}}>Sorry insufficient balance in your account. Booking can't be proceed</span>
                         {/* <button type="button" className="btn btn-blue" onClick={() => this.props.handleHold()}>hold</button> */}
                        <div className="btn-block text-right">
                      
                        {
                             tripJackFlight === true && isBA === true && isAllowedHoldBooking === 'Y' && hideHold === false && 
                             <React.Fragment>
                               
                                { /* eslint-disable-next-line */}
                                <a href="/" className={`btn btn-blue loading-btn 
                                ${this.props.holdBookingData.loading && this.state.buttonClickType === 'hold' ? 'btn-disabled' : ''} 
                                ${this.props.fareQuote.loading || this.props.ssrDetails.loading ? 'btn-disabled' : ''}`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.props.handleHold('hold');
                                    this.setState({
                                        buttonClickType : 'hold'
                                    });
                                    this.props.handleConfirmationPopup(true,'ticket-hold') 
                                }
                                }>
                                {
                                    (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                    <div className="btn-loader loader-ring"> <span></span> </div>
                                }
                                <span>hold </span>
                                </a>
                                {
                                    (this.props.holdBookingData.loading && this.state.buttonClickType === 'hold')
                                    ?
                                    <div className="loader-hotel">
                                        <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                        <div className="overlay-loader show"></div>
                                    </div>
                                        :''
                                }
                            </React.Fragment>
                        }
                        {
                            tripJackFlight === false && riyaFlight === false && tboFlight === false && isLcc === false && isAllowedHoldBooking === 'Y' && hideHold === false &&
                            <React.Fragment>
                                { /* eslint-disable-next-line */}
                                <a href="/" className={`btn btn-blue loading-btn 
                                ${this.props.holdBookingData.loading && this.state.buttonClickType === 'hold' ? 'btn-disabled' : ''} 
                                ${this.props.fareQuote.loading || this.props.ssrDetails.loading ? 'btn-disabled' : ''}`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.props.handleHold('hold');
                                    this.setState({
                                        buttonClickType : 'hold'
                                    });
                                    this.props.handleConfirmationPopup(true,'ticket-hold') 
                                }
                                }>
                                {
                                    (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                    <div className="btn-loader loader-ring"> <span></span> </div>
                                }
                                <span>hold</span>
                                </a>
                                {
                                    (this.props.holdBookingData.loading && this.state.buttonClickType === 'hold')
                                    ?
                                    <div className="loader-hotel">
                                        <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                        <div className="overlay-loader show"></div>
                                    </div>
                                        :''
                                }
                            </React.Fragment>
                        }
                        
                        {
                            tboFlight === true && isHoldAllowed === true && isAllowedHoldBooking === 'Y' && 
                            <React.Fragment>
                                { /* eslint-disable-next-line */}
                                <a href="/" className={`btn btn-blue loading-btn 
                                ${this.props.holdBookingData.loading && this.state.buttonClickType === 'hold' ? 'btn-disabled' : ''} 
                                ${this.props.fareQuote.loading || this.props.ssrDetails.loading ? 'btn-disabled' : ''}`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.props.handleHold('hold');
                                    this.setState({
                                        buttonClickType : 'hold'
                                    });
                                    this.props.handleConfirmationPopup(true,'ticket-hold') 
                                }
                                }>
                                {
                                    (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                    <div className="btn-loader loader-ring"> <span></span> </div>
                                }
                                <span>hold</span>
                                </a>
                                {
                                    (this.props.holdBookingData.loading && this.state.buttonClickType === 'hold')
                                    ?
                                    <div className="loader-hotel">
                                        <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                        <div className="overlay-loader show"></div>
                                    </div>
                                        :''
                                }
                            </React.Fragment>
                        }
                       
                         {
                             riyaFlight === true && isRiyaHoldBooking && 
                             <React.Fragment>
                               
                                { /* eslint-disable-next-line */}
                                <a href="/" className={`btn btn-blue loading-btn 
                                ${this.props.holdBookingData.loading && this.state.buttonClickType === 'hold' ? 'btn-disabled' : ''} 
                                ${this.props.fareQuote.loading || this.props.ssrDetails.loading ? 'btn-disabled' : ''}`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.props.handleHold('hold');
                                    this.setState({
                                        buttonClickType : 'hold'
                                    });
                                    this.props.handleConfirmationPopup(true,'ticket-hold') 
                                }
                                }>
                                {
                                    (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                    <div className="btn-loader loader-ring"> <span></span> </div>
                                }
                                <span>hold</span>
                                </a>
                                {
                                    (this.props.holdBookingData.loading && this.state.buttonClickType === 'hold')
                                    ?
                                    <div className="loader-hotel">
                                        <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                        <div className="overlay-loader show"></div>
                                    </div>
                                        :''
                                }
                            </React.Fragment>
                        }
                        {
                            isLcc === true || airIQFlight || tripJackFlight || goflyFlight|| eflyFlight || satkarFlight || fareBoutiqueFlight|| riyaFlight ?
                            <React.Fragment>
                                { /* eslint-disable-next-line */} 
                                <a href="/" className={`btn btn-orange loading-btn 
                                ${this.props.ticketBookingData.loading ? 'btn-disabled': ''} 
                                ${this.props.fareQuote.loading || this.props.ssrDetails.loading ? 'btn-disabled' : ''}
                                ${showInsuffient ? 'btn-disabled' : ''}
                                `}  onClick={(e) =>{e.preventDefault(); this.props.handleConfirmationPopup(true,'ticket-booking')} 
                                    // this.props.bookTicket()
                                }>
                                {
                                    (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                    <div className="btn-loader loader-ring"> <span></span> </div>
                                }
                                <span>Ticket</span>
                                </a>
                                {
                                    (this.props.ticketBookingData.loading || this.props.pnrRetrieveData.loading || this.props.isBookingPendingData)
                                        ?
                                        <div className="loader-hotel">
                                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                            <div className="overlay-loader show"></div>
                                        </div>
                                        :''
                                }
                            </React.Fragment>
                            : 
                            <React.Fragment>
                                { /* eslint-disable-next-line */}
                                {
                                    kafilaTicketButton === true
                                    ?
                                    <React.Fragment>
                                         {/* eslint-disable-next-line */}
                                        <a href="/" className={`btn btn-orange loading-btn 
                                        ${(this.props.holdBookingData.loading || this.props.pnrRetrieveData.loading) && this.state.buttonClickType === 'ticket' ? 'btn-disabled':''}
                                        ${(this.props.fareQuote.loading || this.props.ssrDetails.loading) ? 'btn-disabled':''}
                                        ${showInsuffient ? 'btn-disabled' : ''}
                                        `}  onClick={(e) => {
                                            e.preventDefault();
                                            // this.props.handleHold('ticket')
                                            this.setState({
                                                buttonClickType : 'ticket'
                                            })
                                            this.props.handleConfirmationPopup(true,'ticket-booking') 
                                        }}>
                                        {
                                            (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                            <div className="btn-loader loader-ring"> <span></span> </div>
                                        }
                                        <span>Ticket</span>
                                        </a>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                         {/* eslint-disable-next-line */}
                                        <a href="/" className={`btn btn-orange loading-btn 
                                        ${(this.props.holdBookingData.loading || this.props.ticketBookingData.loading) && this.state.buttonClickType === 'ticket' ? 'btn-disabled':''}
                                        ${(this.props.fareQuote.loading || this.props.ssrDetails.loading) ? 'btn-disabled':''}
                                        ${showInsuffient ? 'btn-disabled' : ''}
                                        `}  onClick={(e) => {
                                            e.preventDefault();
                                            // this.props.handleHold('ticket')
                                            this.setState({
                                                buttonClickType : 'ticket'
                                            })
                                            this.props.handleConfirmationPopup(true,'hold-ticket-booking') 
                                        }}>
                                        {
                                            (this.props.fareQuote.loading || this.props.ssrDetails.loading) &&
                                            <div className="btn-loader loader-ring"> <span></span> </div>
                                        }
                                        <span>Ticket</span>
                                        </a>
                                    </React.Fragment>
                                } 
                                {
                                    ((this.props.tripJackTicketBookDetails && this.props.tripJackTicketBookDetails.loading) || (this.props.airIQBookDetails && this.props.airIQBookDetails.loading) || this.props.holdBookingData.loading || this.props.ticketBookingData.loading || this.props.pnrRetrieveData.loading) && this.state.buttonClickType === 'ticket'
                                        ?
                                        <div className="loader-hotel">
                                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                            <div className="overlay-loader show"></div>
                                        </div>
                                        :''
                                }
                            </React.Fragment>
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FlightReviewBookingComponent