import React, { Component } from 'react';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import history from "../../shared/history";
import { lastSeenFormat } from '../../shared/commonHelper';


class AgentChangePassword extends Component {

    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/change-password',
          url: history.location.pathname,
          strengthClass:null,
          formFlag:true,
        }
      }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    checkPasswordStrength = (e) => {
        var targetValue = e.target.value;
        var validationaWarning =  new RegExp(/[0-9]/).test(targetValue) && (new RegExp(/[a-z]/).test(targetValue) || new RegExp(/[A-Z]/).test(targetValue) || new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue));
        var validationSuccess =  new RegExp(/[0-9]/).test(targetValue) && new RegExp(/[a-z]/).test(targetValue) && new RegExp(/[A-Z]/).test(targetValue) && new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue);
       if(targetValue.length >= 4 && validationaWarning && validationSuccess)
        {
            this.setState({ strengthClass: 'success',});
        }
        else if(targetValue.length >= 4 && validationaWarning)
        {
            this.setState({ strengthClass: 'warning',});
        }
        else  if(targetValue.length >= 1)
        {
            this.setState({ strengthClass: 'danger',});
        }
        else{
            this.setState({ strengthClass: null,});
        }
    }
    

    render() {
        const { errors, handleBlur, handleChange, handleSubmit,accountResponse,productResponse,pagesResponse} = this.props;
        let activeLeftNavigation  = this.state.url;
        var errorMsg,successMsg;
        if(!this.state.formFlag){
            document.getElementById("credit_request").reset();
            this.setState({formFlag: false});
        }
        const showReplyForm = () => {
            this.setState({hideMsg: true});
        };
        if(this.props.agentChangePass === 'success')
        {
            errorMsg = '';
            successMsg = 'Password has been successfully changed.';
        }
        else if(this.props.agentChangePass === 'error')
        {
            errorMsg = 'Incorrect old password.';
            successMsg = '';
        }

        let passwordLastUpdatedDate = accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.password_last_updated?accountResponse.myAccountResponse.response.password_last_updated : '';
        let passwordUpdatedDays = lastSeenFormat(passwordLastUpdatedDate);
        let passWordDayText = passwordUpdatedDays.split(' ');
        return (
        <React.Fragment>
             {/*nav-section Start*/}
             <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
             {/*nav-section end*/}
            <section className="mid-wrapper">
                <AgentCommonHeader {...this.props}/>
                <div className="my-account-info">
                    <div className="container">
                        <div className="my-account-sec">
                            <div className="my-account-outer">
                            <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                <div className="profile-detail-info">
                                    <div className="edit-profile-bar">
                                        <div className="pull-right">
                                            { /* eslint-disable-next-line */}
                                            <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                        </div>
                                    </div>
                                    <div className="tabs-container-detail">
                                        <div className="statement-info">
                                            <div className="tabs-top">
                                                <div className="tabs-title">
                                                    <h2><i className="icon icon-key"></i><span>Change Password</span></h2>
                                                    <div className="pull-right">
                                                        <span className={passWordDayText[1].includes("Days") && passWordDayText[0] > 25 ?'red-text':''}>{passwordUpdatedDays}</span>
                                                    </div>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['change_password'].page_description }} />
                                               
                                            </div>
                                            <div className="change-pass-info">
                                                <div className="change-pass-form">
                                                    <div className="change-pass-icon">
                                                        <figure><img src="assets/images/change-pass-icon.svg" alt=""/></figure>
                                                    </div>
                                                    <form autoComplete="off" tabIndex="0">
                                                        <div className="form-label">
                                                            <p>You must change your password now and login again!</p>
                                                        </div>
                                                        
                                                        {successMsg && <div className="alert alert-success" style={{ display: (this.state.hideMsg === true)?'none':'' }}> 
                                                            <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i> </div>}
                                                        {errorMsg && <div className="alert alert-danger" style={{ display: (this.state.hideMsg === true)?'none':'' }}> 
                                                            <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i></div>}
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input className="form-control custom-filed" type="password" name="oldPassword" placeholder="Old Password" onBlur={handleBlur} onChange={handleChange} />
                                                                {errors.oldPassword && <span className="error-msg">{errors.oldPassword}</span>}
                                                            </div>
                                                        
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input className="form-control custom-filed" type="password" name="newPassword" placeholder="New Password" onBlur={handleBlur} onChange={handleChange} onKeyUp={((e) => this.checkPasswordStrength(e))} />
                                                                {errors.newPassword &&<span className="error-msg">{errors.newPassword}</span>}
                                                                {this.state.strengthClass?
                                                                    <div className="progress progress-bar-block">
                                                                        <div className={"progress-bar progress-bar-striped bg-"+this.state.strengthClass} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div> 
                                                                :""
                                                                }   
                                                                <span className="info-block"><i className="icon icon-info"></i>
                                                                    <span className="info-tooltip"><small>Password longer than 4 characters or at least 1 lowercase or uppercase or number or special char</small></span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input className="form-control custom-filed" type="password" name="confirmPassword" placeholder="Confirm Password" onBlur={handleBlur} onChange={handleChange}/>
                                                                {errors.confirmPassword &&<span className="error-msg">{errors.confirmPassword}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="btn-block text-center">
                                                            <div className="col1"><button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>{(this.props.accountResponse.myAgentChangePasswordResponse.loading === false)?'Submit':'Submitting..'}</button></div>
                                                            <div className="col1"><button type="reset" onClick={()=>this.setState({strengthClass:null})} className="btn btn-dark">Reset</button></div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </React.Fragment>
        )
    }
}

export default (AgentChangePassword)