import React, { Component } from 'react';
import AgentLeftBar from './AgentLeftBarComponent';
import AgentHeaderNavigation from './AgentHeaderNavigationComponent';
import AgentCommonHeader from './AgentCommonHeaderComponent';
import history from "../../shared/history";
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { getCurrentDate } from '../../shared/commonHelper';

class CreateReportComponent extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/create-report',
          url: history.location.pathname,
          date:moment(),
          focused:false,
          calendarFocused:false,
          createdAt:moment(),
          travelStartDate:moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00',
          travelEndDate:moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T23:59:59',
        }
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    onDateChange = (createdAt)=>{
        this.setState(()=>({createdAt}));
    }

    onFocusChange =({focused})=>{
        this.setState(()=>({calendarFocused:focused}))
    }

    //get Booking report
    getBookingReport = () => {
        let reportDate = {
            "travel_start_date": this.state.travelStartDate,
            "travel_end_date": this.state.travelEndDate,
        }
        this.props.bookingReport(reportDate);
    }

    render() {
        const { accountResponse,productResponse} = this.props;
        let activeLeftNavigation  = this.state.url;
        const {focused, date} = this.state;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                <div className="profile-detail-info">
                                    <div className="edit-profile-bar">
                                        <div className="pull-right">
                                            { /* eslint-disable-next-line */}
                                            <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                        </div>
                                    </div>
                                    <div className="tabs-container-detail">
                                        <div className="statement-info">
                                            <div className="tabs-top">
                                                <div className="tabs-title">
                                                    <h2><i className="icon icon-result-file"></i><span>Create Report</span></h2>
                                                </div>
                                                {/* <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['top_up'].page_description }} /> */}
                                            </div>
                                        <div className="statement-outer create-report">
                                            <div className="search-report-form ">
                                            <div className="form-row">
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Start Date :</label>
                                                                    <div className="input-group input-addon single-calendar">
                                                                        { 
                                                                            <SingleDatePicker
                                                                                date={this.state.createdAt}
                                                                                onDateChange={createdAt => {
                                                                                    this.setState({ createdAt });
                                                                                    this.setState({ travelStartDate: moment(createdAt).format('YYYY-MM-DD')+'T00:00:00'});
                                                                                    
                                                                                }}
                                                                                focused={this.state.calendarFocused}
                                                                                onFocusChange={this.onFocusChange}
                                                                                id="start_date"
                                                                                displayFormat="DD-MM-YYYY"
                                                                                readOnly={true}
                                                                                showDefaultInputIcon={true}
                                                                                inputIconPosition={"after"}
                                                                                isOutsideRange={() => false}
                                                                                hideKeyboardShortcutsPanel={true}
                                                                                reopenPickerOnClearDate={true}
                                                                                withPortal={true}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="form-group">
                                                                <label className="control-label">End Date :</label>
                                                                    <div className="input-group input-addon single-calendar">
                                                                        { 
                                                                            <SingleDatePicker
                                                                                date={date} 
                                                                                onDateChange={date => {
                                                                                    this.setState({ date });
                                                                                    this.setState({ travelEndDate: moment(date).format('YYYY-MM-DD')+'T23:59:59'});
                                                                                }}
                                                                                focused={focused}
                                                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                                                id="end_date"
                                                                                displayFormat="DD-MM-YYYY"
                                                                                readOnly={true}
                                                                                showDefaultInputIcon={true}
                                                                                inputIconPosition={"after"}
                                                                                isOutsideRange={() => false}
                                                                                hideKeyboardShortcutsPanel={true}
                                                                                reopenPickerOnClearDate={true}
                                                                                withPortal={true}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-block text-center">
                                                                <div className="col1">
                                                                    <button type="button" className="btn btn-sky-blue" onClick={() => this.getBookingReport()}>Export Report</button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default CreateReportComponent