import API from '..';

var VENDOR_API = {
    meta: {},
};

VENDOR_API.addVendorBookingsAPI = (credentials) => {
    return API.postResponseSession('/flight/vendor/add-booking',credentials);
};

VENDOR_API.viewVendorBookingsAPI = () => {
    return API.getResponseSession('/flight/vendor/list');
};

VENDOR_API.editVendorBookingsAPI = (vendorId,credentials) => {
    return API.postResponseSession('/flight/vendor/update-booking/'+vendorId,credentials);
};

VENDOR_API.deleteVendorBookingsAPI = (credentials) => {
    return API.postResponseSession('/flight/vendor/delete-booking',credentials);
};

VENDOR_API.editStatusVendorBookingsAPI = (vendorId,credentials) => {
    return API.postResponseSession('/flight/vendor/update-booking-status/'+vendorId,credentials);
};

export default VENDOR_API;