/* external dependencies */
import React from 'react';

class RailwayVerifyContainer extends React.Component {
    

    render() {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}


export default RailwayVerifyContainer;