import React, { Component } from 'react';
import { connect } from 'react-redux'
import { myAccountAction} from '../../actions/agentAction';
import { logoutAction } from '../../actions/shared/sessionAction';
import VendorComponent from '../../components/agent/vendor/VendorComponent';
import { getCitiesAction } from '../../actions/shared/sharedAction';
import { getAllAirlines } from '../../actions/flight/flightSearchAction';
import { addVendorBookings, viewVendorBookings, editVendorBookings, deleteVendorBookings, editStatusVendorBookings} from '../../actions/shared/vendorAction';

class VendorContainer extends Component {

    render() {
        return (
            this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.is_vendor === 'Y' ?
            <VendorComponent {...this.props}/>
            :
            <React.Fragment></React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse     : state.myAccount,//my account details
        productResponse     : state.shared.productResponse,//get products for navigations
        agentProductAccess  : state.shared.agentProductAccess,
        citiesResponse      : state.shared.citiesResponse,
        allAirlinesData     : state.flightSearchReducer.allAirlinesData,
        addVendorResponse   : state.vendorReducer.addVendorResponse,//Get add vendor bookings response
        viewVendorResponse  : state.vendorReducer.viewVendorResponse,//Get add vendor bookings response
        editVendorResponse  : state.vendorReducer.editVendorResponse,//Get edit vendor bookings response
        deleteVendorResponse: state.vendorReducer.deleteVendorResponse,//Get edit vendor bookings response
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        myAccountAction     : (credentials) => dispatch(myAccountAction(credentials)),
        logoutAction        : () => dispatch(logoutAction()),
        getCitiesAction     :(credentials,successCb,errorCb) => dispatch(getCitiesAction(credentials,successCb,errorCb)),
        getAllAirlines      :(credentials) => dispatch(getAllAirlines(credentials)),
        addVendorBookings   :(credentials,actionCb) => dispatch(addVendorBookings(credentials,actionCb)),//Add vendor bookings
        viewVendorBookings  :(successCb,errorCb) => dispatch(viewVendorBookings(successCb,errorCb)),//Add vendor bookings
        editVendorBookings  :(vendorId,credentials,successCb,errorCb) => dispatch(editVendorBookings(vendorId,credentials,successCb,errorCb)),//Edit vendor bookings
        deleteVendorBookings:(credentials,successCb,errorCb) => dispatch(deleteVendorBookings(credentials,successCb,errorCb)),//Edit vendor bookings
        editStatusVendorBookings  :(vendorId,credentials,successCb,errorCb) => dispatch(editStatusVendorBookings(vendorId,credentials,successCb,errorCb)),//enable/disable vendor bookings
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorContainer)
