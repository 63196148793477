import API from '../api';

var FLIGHT_API = {
    meta: {},
};

FLIGHT_API.getClasses = (productUrl) => {
    return API.getResponseSession('/flight/settings/classes');
};

FLIGHT_API.getAirlines = (productUrl) => {
    return API.getResponseSession('/flight/settings/airlines');
};

export default FLIGHT_API;