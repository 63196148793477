import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrintRailwayInvoiceComponent from '../../components/railway/PrintRailwayInvoiceComponent';
import { getTrainBookingDetail, emailTrainInvoice } from '../../actions/railway/railwayAction';

class PrintRailwayInvoiceContainer extends Component {
    constructor() {
        super();
        this.state = {
            booking_id: null
        }
    }
    componentDidMount() {
        let data = localStorage.getItem('print-train-invoice');
        let booking_id = JSON.parse(data).booking_id;
        this.setState({
            booking_id: booking_id
        })
        const booking = {
            "booking_id": parseInt(booking_id)
        };
        this.props.getTrainBookingDetail(booking);
    }
    render() {
        return (
            <React.Fragment>
                <PrintRailwayInvoiceComponent {...this.props} booking_id={this.state.booking_id} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        myTrainBookingDetails: state.railwayReducer.myTrainBookingDetails,
        settingResponse: state.shared.settingResponse,
        accountResponse: state.myAccount,//my account details
        trainEmailInvoice: state.railwayReducer.trainEmailInvoice
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTrainBookingDetail: (data, successCb, errorCb) => dispatch(getTrainBookingDetail(data, successCb, errorCb)),
        emailTrainInvoice: (data, successCb, errorCb) => dispatch(emailTrainInvoice(data, successCb, errorCb))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintRailwayInvoiceContainer)   