import API from '..';

var PASSWORD_API = {
    meta: {},
};

PASSWORD_API.verifyEmail = (email) => {
    return API.postResponse('/forgot-password',email);
};

PASSWORD_API.resetPassword = (inputs) => {
    return API.postResponse('/reset-password',{email: inputs.email, otp: inputs.otp, password: inputs.password});
};

export default PASSWORD_API;