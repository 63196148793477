
import {
    ADD_VENDOR_BOOKINGS_START,
    ADD_VENDOR_BOOKINGS_OK,
    ADD_VENDOR_BOOKINGS_ERR,
    VIEW_VENDOR_BOOKINGS_START,
    VIEW_VENDOR_BOOKINGS_OK,
    VIEW_VENDOR_BOOKINGS_ERR,
    EDIT_VENDOR_BOOKINGS_START,
    EDIT_VENDOR_BOOKINGS_OK,
    EDIT_VENDOR_BOOKINGS_ERR,
    DELETE_VENDOR_BOOKINGS_START,
    DELETE_VENDOR_BOOKINGS_OK,
    DELETE_VENDOR_BOOKINGS_ERR
} from '../../actions/shared/vendorAction';

export const initialSessionState = {
    addVendorResponse : {
        error: null,
        loading: false,
        response : null
    },
    viewVendorResponse : {
        error: null,
        loading: false,
        response : null
    },
    editVendorResponse : {
        error: null,
        loading: false,
        response : null
    },
    deleteVendorResponse : {
        error: null,
        loading: false,
        response : null
    },
};


export function vendor(state = initialSessionState, action) {
    switch (action.type) {
    // Add vendor bookings =============================
        case ADD_VENDOR_BOOKINGS_START:
        return {
            ...state, 
            addVendorResponse:  {
                error: false,
                loading: true,
                response: false
            }
        };
        case ADD_VENDOR_BOOKINGS_OK:
            return {
                ...state, 
                addVendorResponse:  {
                    error   : false,
                    loading : false,
                    response: action.response
                }
            };

        case ADD_VENDOR_BOOKINGS_ERR:
        return {
            ...state, 
            addVendorResponse : {
                error: action.message,
                loading: false,
                response: false
            }
        };
        // View vendor bookings =============================
        case VIEW_VENDOR_BOOKINGS_START:
        return {
            ...state, 
            viewVendorResponse:  {
                error: false,
                loading: true,
                response: false
            }
        };
        case VIEW_VENDOR_BOOKINGS_OK:
            return {
                ...state, 
                viewVendorResponse:  {
                    error   : false,
                    loading : false,
                    response: action.response
                }
            };

        case VIEW_VENDOR_BOOKINGS_ERR:
        return {
            ...state, 
            viewVendorResponse : {
                error: true,
                loading: false,
                response: false
            }
        };
        // Edit vendor bookings =============================
        case EDIT_VENDOR_BOOKINGS_START:
        return {
            ...state, 
            editVendorResponse:  {
                error: false,
                loading: true,
                response: false
            }
        };
        case EDIT_VENDOR_BOOKINGS_OK:
            return {
                ...state, 
                editVendorResponse:  {
                    error   : false,
                    loading : false,
                    response: action.response
                }
            };

        case EDIT_VENDOR_BOOKINGS_ERR:
        return {
            ...state, 
            editVendorResponse : {
                error: action.message,
                loading: false,
                response: false
            }
        };
        // delete vendor bookings =============================
        case DELETE_VENDOR_BOOKINGS_START:
        return {
            ...state, 
            deleteVendorResponse:  {
                error: false,
                loading: true,
                response: false
            }
        };
        case DELETE_VENDOR_BOOKINGS_OK:
            return {
                ...state, 
                deleteVendorResponse:  {
                    error   : false,
                    loading : false,
                    response: action.response
                }
            };

        case DELETE_VENDOR_BOOKINGS_ERR:
        return {
            ...state, 
            deleteVendorResponse : {
                error: action.message,
                loading: false,
                response: false
            }
        };
        default:
            return state;
    }
}


