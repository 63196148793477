import { getNewDateFormted, currencyFormat, compareDate } from "./commonHelper";

//Helper for hotel
//Function to filter results according to adnvance search
export const filterResults = (filterArr, allHotels) => {
	let allHotelsArray = allHotels;
	if (filterArr && filterArr.hotel_name && filterArr.hotel_name !== "") //If filter is applied for hotel name
	{
		let flightsNew = [];
		for (let i = 0; i < allHotelsArray.length; i++) {
			let item = allHotelsArray[i];
			if (item.hotel_name.includes(filterArr.hotel_name) || item.hotel_name.includes(filterArr.hotel_name)
				|| item.hotel_name.toUpperCase().includes(filterArr.hotel_name) || item.hotel_name.toUpperCase().includes(filterArr.hotel_name)
				|| item.hotel_name.toLowerCase().includes(filterArr.hotel_name) || item.hotel_name.toLowerCase().includes(filterArr.hotel_name)
			) {
				flightsNew.push(item);
			}
		}
		allHotelsArray = flightsNew;
	}
	if (filterArr && filterArr.hotel_location && filterArr.hotel_location !== "") //If filter is applied for hotel location
	{
		let flightsNew = [];
		for (let i = 0; i < allHotelsArray.length; i++) {
			let item = allHotelsArray[i];
			if (item.hotel_address && item.hotel_address.adr) {
				if ((item.hotel_address && item.hotel_address.adr && 
					(item.hotel_address.adr.includes(filterArr && filterArr.hotel_location) ||
					 item.hotel_address.adr.toUpperCase().includes(filterArr && filterArr.hotel_location) ||
					 item.hotel_address.adr.toLowerCase().includes(filterArr && filterArr.hotel_location))
				   )) {
				   flightsNew.push(item);
			   }
			} else {
				if ((item.hotel_address && 
					(item.hotel_address.includes(filterArr && filterArr.hotel_location) ||
					 (item.hotel_address.toUpperCase().includes(filterArr && filterArr.hotel_location)) ||
					 (item.hotel_address.toLowerCase().includes(filterArr && filterArr.hotel_location))
					)
				   )) {
				   flightsNew.push(item);
			   }
			}
		}
		allHotelsArray = flightsNew;
	}
	if (filterArr && filterArr.hotel_rating && filterArr.hotel_rating.length > 0) { //If filter is applied for hotel rating
		allHotelsArray = allHotelsArray.filter(singleHotel => filterArr.hotel_rating.includes(singleHotel.star_rating) === true);
	}
	if (filterArr && filterArr.hot_deals && filterArr.hot_deals !== "") {//If filter is applied for hotel promotions
		allHotelsArray = allHotelsArray.filter(singleHotel => singleHotel.hotel_promotion !== '');
	}
	if (filterArr && filterArr.hotel_price && filterArr.hotel_price.min_price && filterArr.hotel_price.max_price) {//If filter is applied for hotel price
		allHotelsArray = allHotelsArray.filter(singleHotel => (filterArr.hotel_price.min_price <= singleHotel.price.published_price && filterArr.hotel_price.max_price >= singleHotel.price.published_price) === true);
	}
	return allHotelsArray;
};

export const hotelDateFormat = (date) => {
	return date.split('/')[2]+'-'+date.split('/')[1]+'-'+date.split('/')[0];
}

export const getHotelByRating = (data,rating) => {
	let ratingCount = 0;
	if(data){
		for(let i = 0;i<data.length;i++){
			if(data[i].star_rating === rating){
				ratingCount =  ratingCount+1;
			}
		}
	}
	return ratingCount;
}

export const getHotelByHotDeals = (data) => {
	let count = 0;
	if(data){
		for(let i = 0;i<data.length;i++){
			if(data[i].hotel_promotion !== '' && data[i].hotel_promotion !== null){
				count =  count+1;
			}
		}
	}
	return count;
}

export const getMinPrice = (data) => {
	let minPrice = 0;
	if(data){
		for(let i = 0;i<data.length;i++){
			minPrice = i ===0 ? (data[i] && data[i].price_modified && data[i].price_modified.published_price) : minPrice;
			if((data[i] && data[i].price_modified && data[i].price_modified.published_price) < minPrice){
				minPrice = data[i].price_modified.published_price;
			}
		}
	}
	return minPrice;
}

export const getMaxPrice = (data) => {
	let maxPrice = 0;
	if(data){
		for(let i = 0;i<data.length;i++){
			maxPrice = i ===0 ? (data[i] && data[i].price_modified && data[i].price_modified.published_price) : maxPrice;
			if((data[i] && data[i].price_modified && data[i].price_modified.published_price) > maxPrice){
				maxPrice = data[i].price_modified.published_price;
			}
		}
	}
	return maxPrice;
}

//Function to get hotels from room index
export const getHotelFromRoomIndex = (indexes,allRooms) => {
	let allHotelsArray = '';
	allHotelsArray = allRooms.filter(singleHotel => indexes.room_index.includes(singleHotel.room_index) === true);
	return allHotelsArray;
}

export const getHotelFromRoomId = (indexes,allRooms) => {
	let allHotelsArray = '';
	let data = [];
	allRooms && allRooms.map((item) => {
		return(
			item && item.room_combination.map((itemOuter, key) => {
				data.push(itemOuter);
				return null;
			})
		)
	})
	allHotelsArray = data.filter(singleHotel => indexes.room_id.includes(singleHotel.room_id) === true);
	return allHotelsArray;
}

//Function to get rates of rooms week wise for rate break up
export const getDayRateBreakUpWeekWise = (data) => {
	let weeks = [];
	let days = [];
	for(let i =0;i<data.length;i++){
		let item = data[i];
		if(item.date !== ''){
		let date = getNewDateFormted(item.date);
		let day = date.getDay();
		days[day] = item;
		if(day === 6){ //If day is 6 i.e Sunday then push it into week array
			weeks.push(days);
			days = [];
		}
	}
	}
	if(days.length !== 0){
		weeks.push(days);
	}
	return weeks
}

//Function to day from date
export const getDayFromDate = (date) => {
	let day = getNewDateFormted(date);
	return day.getDay();
}

//Function to get day rates from get-booking-details
export const getDayRateFromBookingDetails = (startday,numberOfdays,price) => {
	let weeks = [];
	let days = [];
	let count = startday;
	let maxNum = Number(startday) + Number(numberOfdays);
	for(let i =0;i<maxNum;i++){		
		if(startday !== 6){																														
			if(count === i){
				days.push({
					'amount' : currencyFormat(price)
				});
				startday = Number(startday) + 1;
				count 	 = Number(count) + 1;
			}else{
				days.push({
					'amount' : ''
				});
			}
		}else{
			days.push({
				'amount' : currencyFormat(price)
			});																														
			weeks.push(days);
			days = [];
			startday = 0;
			count 	 = Number(count) + 1;
		}
	}
	if(days.length !== 0){
		weeks.push(days);
	}
	return weeks
}

export const getPanNotRequired = (rooms) =>
{
	return rooms.is_pan_mandatory;
}

export const getTripjackPanNotRequired = (rooms) =>
{
	let data = '';
	rooms && rooms.room_combination.map((item) => {
		data = item.is_pan_mandatory;
		return null;
	})
	return data;
}

export const getFreeCancellation = (rooms) =>
{
	return compareDate('currentDate',rooms.last_cancellation_date);
}

export const getTripjackFreeCancellation = (rooms) =>
{
	let cancellation = ''
	rooms && rooms.room_combination.map((item) => {
		cancellation = compareDate('currentDate',item.last_cancellation_date);
		return null;
	})
	return cancellation;
}

export const getInclusionRooms = (rooms,value) =>
{
	let flag = false;
	/* eslint-disable-next-line */
	rooms && rooms.inclusion && rooms.inclusion.map((item,index) => {
		if (item.includes('breakfast') || item.toUpperCase().includes('breakfast') || item.toLowerCase().includes('breakfast')){
			flag = 'breakfast';
		}
		if (item.includes('Half Board') || item.toUpperCase().includes('Half Board') || item.toLowerCase().includes('Half Board')){
			flag = 'half_board';
		}
		if (item.includes('Full Board') || item.toUpperCase().includes('Full board') || item.toLowerCase().includes('Full board')){
			flag = 'full_board';
		}
	})
	return flag;
}

export const getTripjackInclusionRooms = (rooms,value) =>
{
	let flag = false;
	/* eslint-disable-next-line */
	rooms && rooms.room_combination.map((item1) => {
		if(item1){
			if (item1.inclusion.includes('breakfast') || item1.inclusion.toUpperCase().includes('breakfast') || item1.inclusion.toLowerCase().includes('breakfast')){
				flag = 'breakfast';
			}
			if (item1.inclusion.includes('Half Board') || item1.inclusion.toUpperCase().includes('Half Board') || item1.inclusion.toLowerCase().includes('Half Board') || item1.inclusion.includes('HALF BOARD')){
				flag = 'half_board';
			}
			if (item1.inclusion.includes('Full Board') || item1.inclusion.toUpperCase().includes('Full board') || item1.inclusion.toLowerCase().includes('Full board') || item1.inclusion.includes('FULL BOARD')){
				flag = 'full_board';
			}
			if (item1.inclusion.includes('ROOM ONLY') || item1.inclusion.toUpperCase().includes('Room Only') || item1.inclusion.toLowerCase().includes('room only') || item1.inclusion.includes('Room Only')){
				flag = 'room_only';
			}
			if (item1.inclusion.includes('All Inclusive') || item1.inclusion.toUpperCase().includes('All Inclusive') || item1.inclusion.toLowerCase().includes('all inclusive') || item1.inclusion.includes('ALL INCLUSIVE')){
				flag = 'all_inclusive';
			}
		}
	})
	return flag;
}

export const filterHotelDetails = (hotelDetails,hotelFilters,supplier_type) => 
{
	for (let index in hotelFilters) 
	{
		if(hotelFilters[index])
		{
			if(index === 'pan_not_required')
			{
				if(supplier_type === 'tripjack'){
					hotelDetails = hotelDetails.filter(rooms => getTripjackPanNotRequired(rooms) === false);
				}else{
					hotelDetails = hotelDetails.filter(rooms => getPanNotRequired(rooms,'') === false);
				}
			} 
			if(index === 'free_cancellation')
			{
				if(supplier_type === 'tripjack'){
					hotelDetails = hotelDetails.filter(rooms => getTripjackFreeCancellation(rooms) === 'false');
				}else{
					hotelDetails = hotelDetails.filter(rooms => getFreeCancellation(rooms,'') === 'false');
				}
			}
			if(index === 'inclusion' && hotelFilters[index].length > 0)
			{
				if(supplier_type === 'tripjack'){
					hotelDetails = hotelDetails.filter(singleHotel => hotelFilters[index].includes(getTripjackInclusionRooms(singleHotel)) === true);
				}else{
					hotelDetails = hotelDetails.filter(singleHotel => hotelFilters[index].includes(getInclusionRooms(singleHotel)) === true);
				}
			}
		}
	}
	const sortedResults=hotelDetails.sort((a, b) => {
		const priceA = a.room_combination[0].price_modified.offered_price;
		const priceB = b.room_combination[0].price_modified.offered_price;
		return priceA - priceB;
	  });
	return sortedResults;
}

// hotel search results merge
export const mergeSearchResults = (oldResults, newResults) => {
	const mergedResults = oldResults === undefined ? [...newResults] : [...oldResults];
	newResults.forEach((newResult) => {
		const matchingResultIndex = mergedResults.findIndex(
			(result) => result.result_index === newResult.result_index
		);
		if (matchingResultIndex >= 0) {
			mergedResults[matchingResultIndex] = {
				...mergedResults[matchingResultIndex],
				...newResult,
			};
		} else {
			mergedResults.push(newResult);
		}
	});
	const sortedResults=mergedResults && mergedResults.sort((a,b)=>(a.price_modified.offered_price > b.price_modified.offered_price) ? 1 : -1)
	return sortedResults;
}



