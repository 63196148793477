import API from "../../api/shared/resetPasswordApi";

export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
const startVerifyEmail = () => {
    return {
        type: VERIFY_EMAIL_START
    };
};

export const VERIFY_EMAIL_OK = 'VERIFY_EMAIL_OK';
const verifyEmailSuccess = (status, message) => {
    return {
        type: VERIFY_EMAIL_OK,
        status,
        message
    }
};

export const VERIFY_EMAIL_ERR = 'VERIFY_EMAIL_ERR';
const verifyEmailError = (status, message, errors) => {
    return {
        type: VERIFY_EMAIL_ERR,
        status,
        message,
        errors
    }
};

export const COMPONENT_CLASS = 'COMPONENT_CLASS';
export const changeComponentClass = (flipCardClass) => {
    return {
        type: COMPONENT_CLASS,
        flipCardClass
    }
};

export function verifyEmail (email, flipCardClass, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(startVerifyEmail());
        
        return API.verifyEmail(email)
            .then((payload) => {
                if (payload.status === 'ok') {
                    setTimeout(() => dispatch(changeComponentClass(flipCardClass)), 1000);
                    dispatch(verifyEmailSuccess(payload.status, payload.message));
                    successCb && successCb();
                    
                } else {
                    dispatch(verifyEmailError(payload.status, payload.message, payload.errors));
                    dispatch(changeComponentClass('show-send-otp'));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
const startResetPassword = () => {
    return {
        type: RESET_PASSWORD_START
    };
};

export const RESET_PASSWORD_OK = 'RESET_PASSWORD_OK';
const resetPasswordSuccess = (status, message) => {
    return {
        type: RESET_PASSWORD_OK,
        status,
        message
    }
};

export const RESET_PASSWORD_ERR = 'RESET_PASSWORD_ERR';
const resetPasswordError = (status, message, errors) => {
    return {
        type: RESET_PASSWORD_ERR,
        status,
        message,
        errors
    }
};

export function resetPassword (inputs, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(startResetPassword());

        return API.resetPassword(inputs)
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(resetPasswordSuccess(payload.status, payload.message));
                    successCb && successCb();
                } else {
                    dispatch(resetPasswordError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}