import API from './index.js';
export const apiRailwayDetailVerfication = (payload) => {
	return API.postResponseSession('/train-ticket-info', payload, true)
		.then(response => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

export const apiRailwayPayment = (payload) => {
	return API.postResponseSession('/train-payment', payload, true)
		.then(response => {
			return response;
		})
		.catch((error) => {
			return error;
		});
};

//calling Train Bookings API
export const apiGetTrainBookings = (data, queryString) => {
	return API.postResponseSession('/train/booking-list' + queryString, data, false);
};

//calling Train Booking detail
export const apiGetTrainBookingDetail = (data) => {
	return API.postResponseSession('/train/single-booking-detail', data, false);
};

//calling email Train Invoice
export const apiEmailTrainInvoice = (data) => {
	return API.postResponseSession('/train/send-invoice-email', data, false);
};

//calling Train Booking detail
export const apiGetTrainCancelDetail = (data) => {
	return API.postResponseSession('/train/get-booking-cancel-detail', data, false);
};

//calling email Train Credit note
export const apiEmailTrainCreditNote = (data) => {
	return API.postResponseSession('/train/email-credit-note', data, false);
};