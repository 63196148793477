import {
    HOTEL_SEARCH_START,
    HOTEL_SEARCH_OK,
    HOTEL_ROOM_DETAIL_START,
    HOTEL_ROOM_DETAIL_OK,
    HOTEL_ROOM_DETAIL_ERR,
    HOTEL_DETAIL_START,
    HOTEL_DETAIL_OK,
    HOTEL_DETAIL_ERR,
    HOTEL_ADVANCE_SEARCH_UPDATED,
    ITINERARIES_EMAIL_START,
    ITINERARIES_EMAIL_OK,
    ITINERARIES_EMAIL_ERR,

    HOTEL_TRIPJACK_SEARCH_OK,
    HOTEL_TRIPJACK_SEARCH_START,
    HOTEL_TRIPJACK_DETAIL_START,
    HOTEL_TRIPJACK_DETAIL_OK,
    HOTEL_TRIPJACK_DETAIL_ERR,
    HOTEL_TRIPJACK_ROOM_DETAIL_START,
    HOTEL_TRIPJACK_ROOM_DETAIL_OK,
    HOTEL_TRIPJACK_ROOM_DETAIL_ERR,
    HOTEL_TRIPJACK_CANCEL_START,
    HOTEL_TRIPJACK_CANCEL_OK,
    HOTEL_TRIPJACK_CANCEL_ERR,
    HOTEL_SEARCH_API_TYPE
} from '../../actions/hotel/hotelSearchDetailsAction';
import { mergeSearchResults } from '../../shared/hotelHelper';

export const initialState = {
    loading: true,
    hotelSearchResult: null,
    tboSearched :false,
    tripJackSearched :false,
	hotelAdvanceSearchFilter: 
    {
        hotel_name : null,
        hotel_rating : [],
        hot_deals   : null,
        hotel_price  : {
            min_price : null,
            max_price : null
        }
    },
    hotelRoomDetail : {
        response : null,
        loading  : false
    },
    hotelDetail : {
        response : null,
        loading  : false
    },
    ItinerariesMail : {
        response : null,
        loading  : false
    },
    hotelReview : {
        response : null,
        loading  : false
    },
    cancelPolicy : {
        response : null,
        loading  : false
    },
    hote_search_api_type:null
};

export function hotelSearchDetailsReducer(state = initialState, action) {
    switch (action.type) {
        //================================ For Hotel Search API ===================================
        case HOTEL_SEARCH_START:
            return {
                ...state,
                loading: true,
                hotelSearchResult: null,
                tboSearched: false,
                tripJackSearched: false,
                hotelAdvanceSearchFilter: [
                    {
                        hotel_name: null,
                        hotel_rating: [],
                        hot_deals: null,
                        hotel_price: {
                            min_price: null,
                            max_price: null
                        }
                    }
                ]
            };

        case HOTEL_TRIPJACK_SEARCH_START:
            return {
                ...state,
                loading: true,
                tripJackSearched: false,
            };
        
        case HOTEL_SEARCH_API_TYPE:
            return {
                ...state,
                hotelSearchResult: null,
                hote_search_api_type: action.api_type
            }

        case HOTEL_SEARCH_OK:
            let searchDetailsStateTbo = Object.assign({}, state);
            if (searchDetailsStateTbo.tripJackSearched) {
                searchDetailsStateTbo.tboSearched = true;
                if (searchDetailsStateTbo.tboSearched && action.output && action.output.trace_id) {
                    searchDetailsStateTbo.hotelSearchResult.trace_id = action.output.trace_id;
                }

                let mergedResults = [];
                if (action.output && searchDetailsStateTbo.hotelSearchResult) {
                    mergedResults = mergeSearchResults(searchDetailsStateTbo&&searchDetailsStateTbo.hotelSearchResult&&searchDetailsStateTbo.hotelSearchResult.hotel_results, action.output&&action.output.hotel_results) //.sort((a, b) => (a.price.offered_fare > b.price.offered_fare) ? 1 : -1)
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateTbo.hotelSearchResult.hotel_results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateTbo.hotelSearchResult = action.output;
                }

                if (searchDetailsStateTbo.tripJackSearched) {
                    searchDetailsStateTbo.loading = false;
                }
                else {
                    searchDetailsStateTbo.loading = true;
                }
                return searchDetailsStateTbo;
            } else {
                let load=true;
                if(searchDetailsStateTbo.hote_search_api_type === "tbo"){
                    load=false
                }
                return {
                    ...state,
                    loading: load,
                    hotelSearchResult: action.output ? action.output : {},
                    tboSearched: true,
                };
            }

        case HOTEL_TRIPJACK_SEARCH_OK:
            let searchDetailsStateTripJack = Object.assign({}, state);
            if (searchDetailsStateTripJack.tboSearched) {
                let mergedResults = [];
                if (action.output && searchDetailsStateTripJack.hotelSearchResult) {
                    mergedResults = mergeSearchResults(searchDetailsStateTripJack&& searchDetailsStateTripJack.hotelSearchResult && searchDetailsStateTripJack.hotelSearchResult.hotel_results, action.output && action.output.hotel_results) //.sort((a, b) => (a.price.offered_fare > b.price.offered_fare) ? 1 : -1)
                }
                if (mergedResults.length > 0) {
                    searchDetailsStateTripJack.hotelSearchResult.hotel_results = mergedResults;
                }
                else if (action.output) {
                    searchDetailsStateTripJack.hotelSearchResult = action.output;
                }
                // if (searchDetailsStateTripJack.tboSearched) {
                //     searchDetailsStateTripJack.loading = false;
                // }
                else {
                    searchDetailsStateTripJack.loading = true;
                }
                searchDetailsStateTripJack.loading = false;
                searchDetailsStateTripJack.tripJackSearched = true;
                return searchDetailsStateTripJack;
            } else {
                // let load=true;
                // if( searchDetailsStateTripJack.hote_search_api_type=="tripjack"){
                //     load=false
                // }
                return {
                    ...state,
                    loading: false,
                    hotelSearchResult: action.output ? action.output : {},
                    tripJackSearched: true,
                }
            }

        //================================ For Hotel room detail API ===================================
        case HOTEL_ROOM_DETAIL_START:
            return {
                ...state,
                hotelRoomDetail : {
                    response : null,
                    loading  : true
                }
            };

        case HOTEL_ROOM_DETAIL_OK:
            return {
                ...state,
                hotelRoomDetail : {
                    response : action.output,
                    loading  : false
                }
            };

        case HOTEL_ROOM_DETAIL_ERR:
            return {
                ...state,
                hotelRoomDetail : {
                    response : null,
                    loading  : false
                }
            };

     //================================ For Hotel detail API ===================================
        case HOTEL_DETAIL_START:
        return {
            ...state,
            hotelDetail : {
                response : null,
                loading  : true
            }
        };

        case HOTEL_DETAIL_OK:
            return {
                ...state,
                hotelDetail : {
                    response : action.output,
                    loading  : false
                }
            };

        case HOTEL_DETAIL_ERR:
            return {
                ...state,
                hotelDetail : {
                    response : null,
                    loading  : false
                }
            };
        case HOTEL_ADVANCE_SEARCH_UPDATED :
            return {
                ...state, 
                hotelAdvanceSearchFilter : action.advanceFilters
            };
        //================================ For ITINERARIES mails ===================================
        case ITINERARIES_EMAIL_START :
            return {
             ...state, 
             ItinerariesMail : {
                 reponse : null,
                 loading  : true
             }
         }
        case ITINERARIES_EMAIL_OK :
            /* if ITINERARIES send successfully */
            return {
                ...state, 
                ItinerariesMail : {
                    response    : action.response,
                    loading     : false
                }
            };
        case ITINERARIES_EMAIL_ERR :
            /* if ITINERARIES send falied */
            return {
                ...state, 
                ItinerariesMail : {
                    reponse : null,
                    loading : false
                }
            }   

        //================================ For TripJack Hotel detail API ===================================
        case HOTEL_TRIPJACK_DETAIL_START:
            return {
                ...state,
                hotelDetail: {
                    response: null,
                    loading: true
                }
            };

        case HOTEL_TRIPJACK_DETAIL_OK:
            return {
                ...state,
                hotelDetail: {
                    response: action.output,
                    loading: false
                }
            };

        case HOTEL_TRIPJACK_DETAIL_ERR:
            return {
                ...state,
                hotelDetail: {
                    response: null,
                    loading: false
                }
            };

        //================================ For Tripjack Hotel room detail API ===================================
        case HOTEL_TRIPJACK_ROOM_DETAIL_START:
            return {
                ...state,
                hotelRoomDetail: {
                    response: null,
                    loading: true
                }
            };

        case HOTEL_TRIPJACK_ROOM_DETAIL_OK:
            return {
                ...state,
                hotelRoomDetail: {
                    response: action.output,
                    loading: false
                }
            };

        case HOTEL_TRIPJACK_ROOM_DETAIL_ERR:
            return {
                ...state,
                hotelRoomDetail: {
                    response: null,
                    loading: false
                }
            };

        case HOTEL_TRIPJACK_CANCEL_START:
            return {
                ...state,
                cancelPolicy: {
                    response: null,
                    loading: true
                }
            };

        case HOTEL_TRIPJACK_CANCEL_OK:
            return {
                ...state,
                cancelPolicy: {
                    response: action.output,
                    loading: false
                }
            };

        case HOTEL_TRIPJACK_CANCEL_ERR:
            return {
                ...state,
                cancelPolicy: {
                    response: null,
                    loading: false
                }
            };

        default:
            return state;
    }
}


