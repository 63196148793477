import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import { Formik } from "formik";

class DomesticMarkupTool extends Component {
    constructor(props) {
        super(props);
        props.getMarkupToolInfo('domestic');
        this.state = {
            valueError:null,
            validationFlag:false,
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    
    renderForm = (formikProps) =>{
    const {handleChange,handleSubmit} = formikProps;
    const { accountResponse} = this.props;
    var errorMsg,successMsg;
    if(this.props.submitDomesticMarkupMsg === 'success')
    {
        errorMsg = '';
        successMsg = 'Domestic Mark-Up Tool Request has been Updated successfully.';
    }
    else if(this.props.submitDomesticMarkupMsg === 'error')
    {
        errorMsg = 'Domestic Mark-Up Tool Request updation failed.';
        successMsg = '';
    }
   
    return(
        <div>
            {successMsg && <div className="alert alert-success" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
            <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
            {errorMsg && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
            <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i></div>}
            {this.props.validationFlags && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> <strong><i className="fa fa-exclamation-triangle"></i>Please enter a valid number</strong><i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
            {accountResponse.getMarkupToolResponse.loading === true?
                <div className="loader relative-loader" style={{"display":"block"}}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
            :
            <form>
                <div className="mark-up-outer">
                    <div className="mark-up-form">
                        <div className="form-row">
                        {
                            accountResponse.getMarkupToolResponse.response && accountResponse.getMarkupToolResponse.response.map((values,key)=>{
                            return(
                                <div key={key} className="col-sm-12 col-lg-6">
                                    <div className="mark-up-bx">
                                        <div className="mark-bx-outer">
                                            <figure><img src={values.airline_logo} alt="" /></figure>
                                            <div className="markup-field">
                                                <div className="cols">
                                                    <div className="custom-label">
                                                    <label>Fixed</label>
                                                    </div>
                                                </div>
                                                <div className="cols">
                                                    <input className="form-control custom-filed" name={"mark_up_value["+values.flight_id+"]"} type="text" defaultValue={values.mark_up_value}  onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <span>{values.airline_name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    <div className="form-btn-block text-center">
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>{accountResponse.updateDomesticMarkupToolResponse.loading === false?"Update Mark-Up":"Updating..."}</button>
						<button type="reset" className="btn btn-grey">Reset</button>
                    </div>
                </div>
            </div></form>}
            
        </div>   
    ) }
    render() {
        return (
        <div className="tabs-container-detail">
            <div className="mark-up-info">
                <div className="tabs-top">
                    <div className="tabs-title">
                        <h2><span>DOMESTIC MARK-UP TOOL</span></h2>
                        <div className="pull-right"> <Link to="international-markup-tool"className="sm-btn blue-sm-btn">Set International Carries</Link> </div>
                    </div>
                </div>
                <Formik
                    onSubmit={(values) => {this.props.updateDomesticMarkupTool(values)}}
                    render={this.renderForm}
                />
            
            </div>
        </div>
        )
    }
}

export default (DomesticMarkupTool)