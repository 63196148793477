import React from 'react';
import { currencyFormat } from '../../../shared/commonHelper';

class FlightFareSummaryComponent extends React.Component {
    constructor(props) {
        super(props)
        
        //Getting and calucating passengers count and fare
        let adultCount = 0;
        let adultFare = 0;
        let childCount = 0;
        let childFare = 0;
        let infantCount = 0;
        let infantFare = 0;
        let tax = 0;
        let yqtax = 0;
        let gst = 0;
        let serviceCharge = 0;
        if(props.fareQuote && !props.booking && props.fareQuote.fare_breakdown){
            for(let i = 0;i<props.fareQuote.fare_breakdown.length;i++){
                let item = props.fareQuote.fare_breakdown[i];
                if (item.passenger_type === 1) {
                    adultCount = item.passenger_count;
                    adultFare = item.base_fare;
                } else if (item.passenger_type === 2) {
                    childCount = item.passenger_count;
                    childFare = item.base_fare;
                } else if (item.passenger_type === 3) {
                    infantCount = item.passenger_count;
                    infantFare = item.base_fare;
                }else{
                    adultCount = item.passenger_count;
                    adultFare = item.base_fare;
                }
                tax = Number(tax) + (item.tax ? item.tax : 0);
                yqtax = Number(yqtax) + (item.yqtax ? item.yqtax : 0);
            }
        }else if(props.booking){
            props.fareQuote.passenger.map( (item,index) => {
                if (item.pax_type === 1) {
                    adultCount = Number(adultCount)+1;
                    adultFare = Number(adultFare) + Number(item.fare.base_fare);
                } else if (item.pax_type === 2) {
                    childCount = Number(childCount)+1;
                    childFare = Number(childFare) + Number(item.fare.base_fare);
                } else if (item.pax_type === 3) {
                    infantCount = Number(infantCount)+1;
                    infantFare = Number(infantFare) + Number(item.fare.base_fare);
                }
                tax = Number(tax) + (item.fare.tax ? item.fare.tax : 0);
                yqtax = Number(yqtax) + (item.fare.yqtax ? item.fare.yqtax : 0);
                return true;
            })
        }
        let totalBaseFare = props && props.fareQuote && props.fareQuote.fare && props.fareQuote.fare.base_fare;//Number(adultFare) + Number(childFare) + Number(infantFare);
        let totalTax = Number(tax) + Number(yqtax);
        let publishedFare = props.fareQuote && props.fareQuote.fare.published_fare;
        let grandTotal = Number(publishedFare) + Number(totalTax);
        // let grandTotal = parseInt(totalBaseFare) + parseInt(totalTax) + parseInt(props.extraBaggage) + parseInt(props.meal);
        let tds = props.fareQuote && props.fareQuote.fare.tds;
        serviceCharge = (props.fareQuote && props.fareQuote.fare.service_charge)?props.fareQuote.fare.service_charge:0;
        let cgst = props && props.fareQuote && props.fareQuote.fare && props.fareQuote.fare.cgst ? props.fareQuote.fare.cgst : 0;
        let igst = props && props.fareQuote && props.fareQuote.fare && props.fareQuote.fare.igst ? props.fareQuote.fare.igst : 0;
        let sgst = props && props.fareQuote && props.fareQuote.fare && props.fareQuote.fare.sgst ? props.fareQuote.fare.sgst : 0;
        gst = props.fareQuote && Number(cgst) + Number(igst) + Number(sgst);
        let discount = props.fareQuote && Number(props.fareQuote.fare.discount) + Number(props.fareQuote.fare.agent_discount);
        let offeredFare = props.fareQuote && props.fareQuote.fare.offered_fare;
        this.state = {
            adultCount: adultCount,
            childCount: childCount,
            infantCount: infantCount,
            grandTotal: grandTotal,
            adultFare: adultFare,
            childFare: childFare,
            infantFare: infantFare,
            totalBaseFare: totalBaseFare,
            tax: tax,
            yqtax: yqtax,
            totalTax: totalTax,
            publishedFare: publishedFare,
            discount: discount,
            offeredFare: offeredFare,
            showOfferedPrice  : null,
            gst : gst,
            tds  : tds,
            serviceCharge: serviceCharge
        }
    }

    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }

    getChildInfantfare = (fareQuoteData) => {
        let adultFare,childFare,infantFare = 0;
        if(fareQuoteData && fareQuoteData.fare_breakdown)
        {
            for(let i = 0;i < fareQuoteData.fare_breakdown.length;i++){
                let item =  fareQuoteData.fare_breakdown[i];
                if (item.passenger_type === 1) {
                    adultFare = item.base_fare;
                } else if (item.passenger_type === 2) {
                    childFare = item.base_fare;
                } else if (item.passenger_type === 3) {
                    infantFare = item.base_fare;
                }
            }
        }
        return [adultFare,childFare,infantFare];
    }

    render() {
        let grandTotal= '';
        let seat = 0;
        let offeredFare = 0;
        let baggageAmt  = 0;
        let mealAmt     = 0;
        let discount    = 0;
        let gst         = 0;
        let tds         = 0;
        let serviceCharge = 0;
        let baseFare = 0;
        let adultFare = this.state.adultFare;
        let childFare = this.state.childFare;
        let infantFare = this.state.infantFare;
        if(!this.props.booking){
            seat =  this.props.getTotalSeatPrice();
            baggageAmt = this.props.baggageAmt;
            mealAmt    = this.props.mealAmt;
        }else{
            baggageAmt = this.props.fareQuote && this.props.fareQuote.fare.total_baggage_charges;
            mealAmt    = this.props.fareQuote && this.props.fareQuote.fare.total_meal_charges;
            seat       = this.props.fareQuote && this.props.fareQuote.fare.total_seat_charges;
        }
        if(!this.props.booking && !this.props.addPassengerSuccess && !this.props.priceChangedOk){
            grandTotal = (this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.published_fare)) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = (this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.offered_fare)) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            discount = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.discount) + Number(this.props.fareQuote_updated.fare.agent_discount);
            gst = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.igst) + Number(this.props.fareQuote_updated.fare.cgst) + Number(this.props.fareQuote_updated.fare.sgst);
            tds = this.props.fareQuote_updated && this.props.fareQuote_updated.fare.tds;
            serviceCharge = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.service_charge);
            baseFare = this.props.fareQuote_updated && this.props.fareQuote_updated.fare.base_fare;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote_updated);
        }else if(!this.props.booking && this.props.addPassengerSuccess && !this.props.priceChangedOk){
            grandTotal = this.props.fareQuote && Number(this.props.fareQuote.fare.published_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = this.props.fareQuote && Number(this.props.fareQuote.fare.offered_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat); 
            discount = this.props.fareQuote && Number(this.props.fareQuote.fare.discount) + Number(this.props.fareQuote.fare.agent_discount);
            gst = this.props.fareQuote && Number(this.props.fareQuote.fare.igst) + Number(this.props.fareQuote.fare.cgst) + Number(this.props.fareQuote.fare.sgst);
            tds = this.props.fareQuote && Number(this.props.fareQuote.fare.tds);
            serviceCharge = this.props.fareQuote && this.props.fareQuote.fare.service_charge;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote);
            baseFare = this.state.totalBaseFare;
        }else if(this.props.fareQuote && this.props.priceChangedOk){
            grandTotal = this.props.fareQuote && Number(this.props.fareQuote.fare.published_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = this.props.fareQuote && Number(this.props.fareQuote.fare.offered_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat); 
          
            discount = this.props.fareQuote && this.props.fareQuote.fare.discount;
            gst = this.props.fareQuote && Number(this.props.fareQuote.fare.igst) + Number(this.props.fareQuote.fare.cgst) + Number(this.props.fareQuote.fare.sgst);
            tds = this.props.fareQuote && this.props.fareQuote.fare.tds;
            serviceCharge = this.props.fareQuote && this.props.fareQuote.fare.service_charge;
            baseFare = this.props.fareQuote && this.props.fareQuote.fare.base_fare;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote);
        }else if(!this.props.booking && !this.props.addPassengerSuccess && !this.props.kafilaPriceChangedOk){
            grandTotal = (this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.published_fare)) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = (this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.offered_fare)) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            discount = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.discount) + Number(this.props.fareQuote_updated.fare.agent_discount);
            gst = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.igst) + Number(this.props.fareQuote_updated.fare.cgst) + Number(this.props.fareQuote_updated.fare.sgst);
            tds = this.props.fareQuote_updated && this.props.fareQuote_updated.fare.tds;
            serviceCharge = this.props.fareQuote_updated && Number(this.props.fareQuote_updated.fare.service_charge);
            baseFare = this.props.fareQuote_updated && this.props.fareQuote_updated.fare.base_fare;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote_updated);
        }else if(!this.props.booking && this.props.addPassengerSuccess && !this.props.kafilaPriceChangedOk){
            grandTotal = this.props.fareQuote && Number(this.props.fareQuote.fare.published_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = this.props.fareQuote && Number(this.props.fareQuote.fare.offered_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat); 
            discount = this.props.fareQuote && Number(this.props.fareQuote.fare.discount) + Number(this.props.fareQuote.fare.agent_discount);
            gst = this.props.fareQuote && Number(this.props.fareQuote.fare.igst) + Number(this.props.fareQuote.fare.cgst) + Number(this.props.fareQuote.fare.sgst);
            tds = this.props.fareQuote && Number(this.props.fareQuote.fare.tds);
            serviceCharge = this.props.fareQuote && this.props.fareQuote.fare.service_charge;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote);
            baseFare = this.state.totalBaseFare;
        }else if(this.props.fareQuote && this.props.kafilaPriceChangedOk){
            grandTotal = this.props.fareQuote && Number(this.props.fareQuote.fare.published_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat);
            offeredFare = this.props.fareQuote && Number(this.props.fareQuote.fare.offered_fare) + Number(this.props.baggageAmt) + Number(this.props.mealAmt) + Number(seat); 
          
            discount = this.props.fareQuote && this.props.fareQuote.fare.discount;
            gst = this.props.fareQuote && Number(this.props.fareQuote.fare.igst) + Number(this.props.fareQuote.fare.cgst) + Number(this.props.fareQuote.fare.sgst);
            tds = this.props.fareQuote && this.props.fareQuote.fare.tds;
            serviceCharge = this.props.fareQuote && this.props.fareQuote.fare.service_charge;
            baseFare = this.props.fareQuote && this.props.fareQuote.fare.base_fare;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote);
        }else{
            grandTotal = this.props.fareQuote && this.props.fareQuote.fare.published_fare;
            offeredFare = this.state.offeredFare; 
            discount = this.state.discount;
            gst = this.state.gst;
            tds = this.state.tds;
            serviceCharge = this.state.serviceCharge;
            baseFare = this.state.totalBaseFare;
            [adultFare,childFare,infantFare] = this.getChildInfantfare(this.props.fareQuote);
        }
        let otherTax = this.props.fareQuote && this.props.fareQuote.fare.other_tax ? this.props.fareQuote.fare.other_tax : 0;
        let totalTaxes = this.props.fareQuote && Number(this.props.fareQuote.fare.tax) + Number(this.props.fareQuote.fare.other_charges) + Number(otherTax) + Number(gst) + Number(serviceCharge);
        return (
            <React.Fragment>
                <div className="title-fare">
                    { /* eslint-disable-next-line */}
                    <a href="/" onClick={(e)=>e.preventDefault()} className="close-fare icon icon-arrow-left2"></a>
                    <h3 className="fare-title">Fare Summary 
                        <span className="offer-amount-icon btn btn-blue net-fare-button-round" onClick={() => this.offeredPriceDisplay()}>{this.state.showOfferedPrice ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                    </h3>
                </div>
                <div className="fare-outer">
                    {
                        this.props.loading
                            ?
                            <div className="loader relative-loader white-ring" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className="cols">
                                    <h4>Base FARE</h4>
                                    <ul>
                                        <li> <span className="pull-left">{this.state.adultCount} Adult(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(adultFare)}</span> </li>
                                        {
                                            this.state.childCount > 0 && 
                                            <li> <span className="pull-left">{this.state.childCount} Child(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(childFare)}</span> </li>
                                        }
                                        {
                                            this.state.infantCount > 0 &&
                                            <li> <span className="pull-left">{this.state.infantCount} Infant(s)</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(infantFare)}</span> </li>
                                        }
                                    </ul>
                                    <div className="total-amount"> <span className="pull-left">Total Base Fare</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(baseFare)}</span> </div>
                                </div>
                                <div className="cols">
                                    <h4>TAXES &amp; SURCHARGES</h4>
                                    <ul>
                                        {
                                            this.props.fareQuote && this.props.fareQuote.fare.tax !== 0
                                            ?<li><span className="pull-left">Tax 
                                                </span><span className="pull-right"><i className="fa fa-rupee" aria-hidden="true"></i> {this.props.fareQuote && currencyFormat(this.props.fareQuote.fare.tax)}</span>
                                            </li>
                                            :''
                                        }

                                        {
                                            this.props.fareQuote && this.props.fareQuote.fare.yq_tax  !== 0  
                                            ?<li> <span className="pull-left">YQ Tax {(this.props.fareQuote.api_source === 'tbo' || this.props.fareQuote.api_source === 'tripjack') && '(included)'}</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat(this.props.fareQuote.fare.yq_tax)}</span> </li>
                                            :''
                                        }       

                                        {
                                            this.props.fareQuote && this.props.fareQuote.fare.other_charges  !== 0  
                                            ?<li> <span className="pull-left">Other Charges</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat( this.props.fareQuote.fare.other_charges)}</span> </li>
                                            :''
                                        }
                                        {
                                            this.props.fareQuote && this.props.fareQuote.fare.other_tax  !== 0  
                                            ?<li> <span className="pull-left">Other Tax</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat( this.props.fareQuote.fare.other_tax)}</span> </li>
                                            :''
                                        }
                                        {
                                            serviceCharge !== 0  
                                            ?<li> <span className="pull-left">Service Charge</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat(serviceCharge)}</span> </li>
                                            :''
                                        }
                                        {
                                            gst  !== 0  
                                            ?<li> <span className="pull-left">GST</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat(gst)}</span> </li>
                                            :''
                                        }

                                        {/* {
                                            this.props.fareQuote && this.props.fareQuote.fare.additional_txn_fee_pub  !== 0  
                                            ?<li> <span className="pull-left">Transaction Fee</span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareQuote && currencyFormat( this.props.fareQuote.fare.additional_txn_fee_pub)}</span> </li>
                                            :''
                                        } */}
                                    </ul>
                                    <div className="total-amount"> <span className="pull-left">Total Taxes &amp; Surcharges</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(totalTaxes)}</span> </div>
                                </div>
                                <div className="cols">
                                    <h4>Extra Fare</h4>
                                    <ul>
                                        <li> <span className="pull-left">Excess Baggage</span> <span className="pull-right"><i className="icon icon-rupee" />{baggageAmt}</span> </li>
                                        <li> <span className="pull-left">Meal</span> <span className="pull-right"><i className="icon icon-rupee" />{mealAmt}</span> </li>
                                        <li> <span className="pull-left">Seat Charges</span> <span className="pull-right"><i className="icon icon-rupee" />{seat}</span> </li>
                                    </ul>
                                    {/* <div className="total-amount"> <span className="pull-left">Total Base Fare</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(this.state.totalBaseFare)}</span> </div> */}
                                </div>
                                <div className="grand-total"> <span className="pull-left">Grand Total</span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(grandTotal)}</span> </div>
                                <div className="cols net-booking" style={{display:this.state.showOfferedPrice?'block':'none'}}>
                                    {/*<h4>Booking Details</h4>*/}
                                    <ul>
                                        {/* <li> <span className="pull-left">Ticket Amount </span> <span className="pull-right"><i className="icon icon-rupee" />  4679</span> </li>
                                     */}
                                        <li> <span className="pull-left">Discount(-) </span> <span className="pull-right"><i className="icon icon-rupee" />{currencyFormat(discount)}</span> </li>
                                        <li> <span className="pull-left">TDS(+) </span> <span className="pull-right"><i className="icon icon-rupee" />{currencyFormat(tds)}</span> </li>
                                    </ul>
                                    <div className="grand-total"> <span className="pull-left">Net Payable Amount </span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(offeredFare)}</span> </div>
                                </div>
                            </React.Fragment>
                    }

                </div>
            </React.Fragment>
        )
    }
}

export default FlightFareSummaryComponent
