/* external dependencies */
import React from 'react';

class RailwayLandingContainer extends React.Component {


    render() {
        return (
            <div className="handler-center">
                <div className="handler-img">
                    <img src="assets/images/dark_logo.svg" alt=""/>
                </div>
                <h4>Welcome to Railway Booking.</h4></div>
        )
    }
}


export default RailwayLandingContainer;