import API from '..';

var SHARED_API = {
	meta: {},
};

SHARED_API.getBanners = (productUrl) => {
	return API.getResponseSession('/info/banners' + productUrl);
};

SHARED_API.getNotices = (productUrl) => {
	return API.getResponseSession('/info/notices' + productUrl);
};

SHARED_API.cities = (filter) => {
	return API.getResponseSession('/flight/settings/cities/' + filter);
};

SHARED_API.hotelCities = (filter) => {
	return API.getResponseSession('/hotel/settings/cities/' + filter);
};

SHARED_API.subscribe = (credentials) => {
	return API.postResponse('/subscribe', credentials);
};

SHARED_API.getProducts = () => {
	return API.getResponse('/info/products', true);
};

SHARED_API.getSettings = () => {
	return API.getResponse('/info/general-settings', true);
};

SHARED_API.getPages = () => {
	return API.getResponse('/info/pages', true);
};
SHARED_API.getChatInfo = (credentials) => {
	return API.postResponseSession('/messages/chat', credentials);
}
SHARED_API.postMessage = (credentials) => {
	return API.postResponseSession('/messages/insert', credentials);
}

SHARED_API.getNotifications = () => {
	return API.getResponseSession('/agent/notifications');
};

SHARED_API.updateNotifications = (data) => {
	return API.postResponseSession('/agent/update-notifications', data);
};

SHARED_API.getProductAccessApi = () => {
	return API.getResponseSession('/agent/product-access', true);
};

SHARED_API.contactUs = (values) => {
	return API.postResponse('/contact-us', values);
};

SHARED_API.addPassengerApi = (payload) => {
	return API.postResponseSession('/flight/search/add-passengers', payload)
		.then(response => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

SHARED_API.getCustomersApi = () => {
	return API.getResponseSession('/flight/search/get-passengers')
		.then(response => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

SHARED_API.addCompanyGSTDetailsApi = (payload) => {
	return API.postResponseSession('/add-company-gst-details', payload)
		.then(response => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

SHARED_API.getIPApi = (credentials) => {
	return API.getResponse('/get-ip', true);
};
// nationality api 
// SHARED_API.apiNationality = (payload) => {
// 	return API.getResponseSession('/nationality-details') 
// 	.then(response => {
// 		return response;
// 	})
// 	.catch((error) => {
// 		return error;
// 	});
// }
SHARED_API.apiNationality = () => {
	return API.getResponse('/nationality-details');
};

export default SHARED_API;