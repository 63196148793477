import React from 'react';
import { dateFormat, jsUcfirst } from '../../../shared/commonHelper';

class ViewVendorBookingDetailComponent extends React.Component {
    render() {
        let data = this.props.location.state.data;
        return (
            <div className="profile-detail-info booking-hold-block">
                <div className="profile-tabs-info">
                    <div className="booking-hold-card">
                        <div className="step-content">
                            <div className="review-filght-step">
                                <div className="review-step">
                                    <div className="review-step-deatil">
                                        <div className="step-deatil-outer">
                                            <div className="flight-details">
                                                <div className="flight-date"> <i className="icon icon-flight" />
                                                    <span>{dateFormat(data.booking_date_from, 'DD MMM YYYY, FDAY')}</span>
                                                </div>
                                                <ul>
                                                    {
                                                        /* eslint-disable-next-line */
                                                        data && data.segments.map((item, key) => {
                                                            if (item.trip_type === 'onward') {
                                                                return (
                                                                    <li key={key}>
                                                                        <div className="flight-card">
                                                                            <div className="traveller-flight">
                                                                                <figure className="pull-left"><img src={item.airline_logo} alt="" /></figure>
                                                                                <div className="flight-dat">
                                                                                    <span>{item.airline_name + ', ' + item.airline_code + " - " + item.flight_no}</span>
                                                                                    {
                                                                                        item.fare_class &&
                                                                                        <p>Flight Class - {item.fare_class}</p>
                                                                                    }
                                                                                    {
                                                                                        item.aircraft &&
                                                                                        <p>Aircraft - {item.aircraft}</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight-stops">
                                                                                <div className="flight-start">
                                                                                    <h3>{item.origin} </h3>
                                                                                    <span>{item.departure}</span>
                                                                                    <p>{item.origin_airport_name}</p>
                                                                                    <p>{item.origin_terminal && 'T-'+item.origin_terminal}</p>
                                                                                </div>
                                                                                <div className="flight-direction">
                                                                                    <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                                </div>
                                                                                <div className="flight-start flight-landing">
                                                                                    <h3>{item.destination} </h3>
                                                                                    <span>{item.arrival}</span>
                                                                                    <p>{item.destination_airport_name}</p>
                                                                                    <p>{item.destination_terminal && 'T-'+item.destination_terminal}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            data.journey_type === 2 &&
                                            <div className="step-deatil-outer">
                                                <div className="flight-details">
                                                    <div className="flight-date"> <i className="icon icon-flight" />
                                                        <span>{dateFormat(data.booking_date_to, 'DD MMM YYYY, FDAY')}</span>
                                                    </div>
                                                    <ul>
                                                        {
                                                            /* eslint-disable-next-line */
                                                            data && data.segments.map((item, key) => {
                                                                if (item.trip_type === 'return') {
                                                                    return (
                                                                        <li key={key}>
                                                                            <div className="flight-card">
                                                                                <div className="traveller-flight">
                                                                                    <figure className="pull-left"><img src={item.airline_logo} alt="" /></figure>
                                                                                    <div className="flight-dat">
                                                                                        <span>{item.airline_name + ', ' + item.airline_code + " - " + item.flight_no}</span>
                                                                                        <p>Flight Class - {item.fare_class}</p>
                                                                                        <p>Aircraft - {item.aircraft}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight-stops">
                                                                                    <div className="flight-start">
                                                                                        <h3>{item.origin} </h3>
                                                                                        <span>{item.departure}</span>
                                                                                        <p>{item.origin_airport_name}</p>
                                                                                    </div>
                                                                                    <div className="flight-direction">
                                                                                        <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                                    </div>
                                                                                    <div className="flight-start flight-landing">
                                                                                        <h3>{item.destination} </h3>
                                                                                        <span>{item.arrival}</span>
                                                                                        <p>{item.destination_airport_name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="review-step">
                                    <div className="review-step-title">
                                        <div className="cols pull-left"> <span>Booking Details</span> </div>
                                        <div className="cols pull-right">  </div>
                                    </div>
                                    <div className= "review-step-deatil">
                                        <div className="step-deatil-outer">
                                            <div className="passenger-information">
                                                <div className="passenger-fl-info">
                                                    <div className="cols">
                                                        <ul className="psg-list">
                                                            <li>
                                                                <span>PNR:</span>
                                                                <small>{data.pnr ? data.pnr : '-'}</small>
                                                            </li>
                                                            <li>
                                                                <span>Cabin Class :</span>
                                                                <small>{data.cabin_class ?  jsUcfirst(data.cabin_class.replace('_',' ')) : '-'}</small>
                                                            </li>
                                                            <li>
                                                                <span>ReFundable :</span>
                                                                <small>{data.is_refundable ?  'Yes' : 'No'}</small>
                                                            </li>
                                                            <li>
                                                                <span>Black Out From :</span>
                                                                <small>{data.black_out_from ?  dateFormat(data.black_out_from, 'DD MMM YYYY, FDAY') : '-'}</small>
                                                            </li>
                                                            <li>
                                                                <span>Black Out To :</span>
                                                                <small>{data.black_out_to ?  dateFormat(data.black_out_to, 'DD MMM YYYY, FDAY') : '-'}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                {
                                    data.fare_rule_details && data.fare_rule_details !== '' &&
                                    <div className="review-step">
                                        <div className="review-step-title">
                                            <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                            <div className="cols pull-right"> </div>
                                        </div>
                                        <div className="review-step-deatil">
                                            <div className="step-deatil-outer">
                                                <div className="fare-rule-info">
                                                    <div className="inner-fare-block">
                                                        <div dangerouslySetInnerHTML={{ __html: data.fare_rule_details }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="review-step">
                                    <div className="review-step-title">
                                        <div className="cols pull-left"> <span>Available Seat / Price</span> </div>
                                        <div className="cols pull-right"> </div>
                                    </div>
                                    <div className="review-step-deatil">
                                        <div className="step-deatil-outer vendor-seats-price">
                                            <div className="balance-table">
                                                <div className="table-outer">
                                                    <div className="table-container">
                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No.</th>
                                                                    <th>Start Date</th>
                                                                    <th>End Date</th>
                                                                    <th>Available Seats</th>
                                                                    <th>Adult Fare</th>
                                                                    <th>Adult Tax</th>
                                                                    <th>Child Fare</th>
                                                                    <th>Child Tax</th>
                                                                    <th>Infant Fare</th>
                                                                    <th>Infant Tax</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>                                  
                                                            {
                                                                data.seat_info && data.seat_info.map((item,key) => {
                                                                    return ( 
                                                                        <tr key={key}>
                                                                            <td>{key + 1}</td>
                                                                            <td>{dateFormat(item.travel_date_from,'DD MMM YYYY, FDAY')}</td>
                                                                            <td>{dateFormat(item.travel_date_to,'DD MMM YYYY, FDAY')}</td>
                                                                            <td>{item.seat_available}</td>
                                                                            <td>{item.adult_base_fare}</td>
                                                                            <td>{item.adult_tax}</td>
                                                                            <td>{item.child_base_fare}</td>
                                                                            <td>{item.child_tax}</td>
                                                                            <td>{item.infant_base_fare}</td>
                                                                            <td>{item.infant_tax}</td>
                                                                        </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>                                            
                                                    </div>
                                                </div>
                                                <div className="table-footer">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ViewVendorBookingDetailComponent