/* external dependencies */
import React from 'react';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import history from "../../shared/history";

class MyAccountComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'account-information',
            leftNavActive: '/my-account',
            url: history.location.pathname
        }
    }
    // componentDidMount() {
    //     history.listen((location, action) => {
    //         this.setState({
    //         url: location.pathname,
    //         });
    //     });
    // }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    handleAgentTabClick(searchType, event) {
        this.setState({ activeTab: searchType });
    }

    render() {
        const { logoutAction,values, errors, touched,handleSubmit, accountResponse, productResponse,setFieldTouched,setFieldValue } = this.props;
      
        let activeLeftNavigation  = this.state.url;
        var errorMsg,successMsg;
        const showReplyForm = () => {
            this.setState({hideMsg: true});
          };

        if(this.props.updateAgentMsg === 'success')
        {
            errorMsg = '';
            successMsg = 'Company logo has been successfully changed.';
        }
        else if(this.props.updateAgentMsg === 'error')
        {
            errorMsg = 'Company logo upload failed.';
            successMsg = '';
        }
        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                {/*nav-section end*/}
                {/*Mid Wrapper Start*/}
                <section className="mid-wrapper">
                    <AgentCommonHeader />
                    
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar logoutAction={logoutAction} handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} />
                                    {activeLeftNavigation === '/my-account' ?
                                        <div className="profile-detail-info">
                                            <div className="edit-profile-bar">
                                                <div className="pull-right">
                                                    { /* eslint-disable-next-line */}
                                                    <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                                </div>
                                            </div>
                                            <div className="profile-tabs-info">
                                                <div className="account-information">
                                                    <div className="information-tabs">
                                                        <ul className="tabs-nav">
                                                            <li className={(this.state.activeTab === "account-information") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'account-information')}><i className="icon icon-currency-noti" /><span>Account Information</span></span></li>
                                                            <li className={(this.state.activeTab === "personal-information") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'personal-information')}><i className="icon icon-user-fill" /><span>Personal Information</span></span></li>
                                                            <li className={(this.state.activeTab === "contact-information") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'contact-information')}><i className="icon icon-contact-file" /><span>Contact Information</span></span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-container">
                                                        <div className="tab-content" id="account_information" style={{ display: (this.state.activeTab === 'account-information')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="account-deatil">
                                                                <div className="account-deatil-top">
                                                                        <div className="col">
                                                                            <span>COMPANY NAME</span>
                                                                            <p dangerouslySetInnerHTML={{ __html: accountResponse.myAccountResponse.loading ? "------" : accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.company_name }}></p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>user name</span>
                                                                            <p>{accountResponse.myAccountResponse.loading ? "------" : accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.username}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>AGENCY CODE</span>
                                                                            <p>{accountResponse.myAccountResponse.loading ? "------" : accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.agency_code}</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="upload-profile-pic">
                                                                    {successMsg && <div className="alert alert-success" style={{ display: (this.state.hideMsg === true)?'none':'' }}> 
                                                                        <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i> </div>}
                                                                    {errorMsg && <div className="alert alert-danger" style={{ display: (this.state.hideMsg === true)?'none':'' }}> 
                                                                    <   strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i></div>}
                                                                        <div className="upload-profile-outer">
                                                                            <div className="upload-file-col">
																			{errors.company_logo_file && touched.company_logo_file && <span className="error-msg">{errors.company_logo_file}</span>}
                                                                                <div className="fle-upload">
                                                                                    <figure><i className="icon icon-camera-off" /></figure>
                                                                                    {/* <input type="file" /> */}
                                                                                    <input type="file" id="uploadCompnyLogo" name="company_logo" onChange={(event) => {
                                                                                        setFieldTouched('company_logo_file');
                                                                                        setFieldValue("company_logo_file", event.currentTarget.files[0]);
                                                                                        this.props.uploadFile(this,'company_logo',event.currentTarget.files[0]);
                                                                                    }}/>
                                                                                </div>
                                                                                <div className="addressProofFile">{values.company_logo_file && values.company_logo_file.name && values.company_logo_file.name}</div>
                                                                            </div>
                                                                            
                                                                            <div className="file-update">
                                                                                { /* eslint-disable-next-line */}
                                                                                <a href="#" className="green-bdr-btn" onClick={handleSubmit}>Update</a>
                                                                            </div>
                                                                            <div className="file-instruction"><p>Image width and height should be 326px*59px and size should less than 25KB.</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content" id="personal_information" style={{ display: (this.state.activeTab === 'personal-information')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="account-deatil">
                                                                    <div className="account-deatil-top">
                                                                        <div className="col">
                                                                            <span>FIRST NAME</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.first_name}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>LAST NAME</span>
                                                                            <p>{accountResponse.myAccountResponse.response && (accountResponse.myAccountResponse.response.last_name !== '') ? accountResponse.myAccountResponse.response.last_name : '-'}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>PAN (Permanent Account Number)</span>
                                                                            <p>{accountResponse.myAccountResponse.response && (accountResponse.myAccountResponse.response.pan_number !== null) ? accountResponse.myAccountResponse.response.pan_number : '-'}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content" id="contact_information" style={{ display: (this.state.activeTab === 'contact-information')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="account-deatil">
                                                                    <div className="account-deatil-top">
                                                                        <div className="col">
                                                                            <span>ADDRESS</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.office_address ? accountResponse.myAccountResponse.response.office_address:"-"}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>CITY</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.city ? accountResponse.myAccountResponse.response.city:"-"}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>STATE</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.state ? accountResponse.myAccountResponse.response.state:"-"}</p>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className="account-deatil-top">
                                                                        <div className="col">
                                                                            <span>PIN CODE</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.pincode ? accountResponse.myAccountResponse.response.pincode:"-"}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>EMAIL</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.email_address ? accountResponse.myAccountResponse.response.email_address:"-"}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>TEL. NO.</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.phone_number ? accountResponse.myAccountResponse.response.phone_number:"-"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="account-deatil-top">
                                                                        <div className="col">
                                                                            <span>CELL NO.</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.mobile_number ? accountResponse.myAccountResponse.response.mobile_number:"-"}</p>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span>ALT. CELL NO.</span>
                                                                            <p>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.alternative_mobile_number?accountResponse.myAccountResponse.response.alternative_mobile_number:"-"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default MyAccountComponent