import { combineReducers } from 'redux'
import { session } from './shared/sessionReducer';
import { register } from './shared/registerReducer';
import { shared } from './shared/sharedReducer';
import { resetPassword } from "./shared/resetPasswordReducer";
import { flightSearchReducer } from './flight/flightSearchReducer';
import { flightSearchDetailsReducer } from './flight/flightSearchDetailsReducer';
import { flightBookTicketReducer } from './flight/flightBookTicketReducer';
import { myAccount } from "./agentReducer";
import { hotelSearchDetailsReducer } from './hotel/hotelSearchDetailsReducer';
import { hotelSearchReducer } from './hotel/hotelSearchReducer';
import { hotelBookReducer } from './hotel/hotelBookReducer';
import { vendor } from './shared/vendorReducer';
import { railwayReducer } from './railway/railwayReducer';

export default combineReducers({
    session: session,
    register: register,
    shared: shared,
    resetPassword: resetPassword,
    flightSearchReducer: flightSearchReducer,
    flightSearchDetailsReducer: flightSearchDetailsReducer,
    myAccount: myAccount,
    hotelSearchDetailsReducer: hotelSearchDetailsReducer,
    flightBookTicketReducer: flightBookTicketReducer,
    hotelBookReducer: hotelBookReducer,
    hotelSearchReducer: hotelSearchReducer,
    vendorReducer: vendor,
    railwayReducer: railwayReducer
})