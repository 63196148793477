import React from 'react';
import { Field } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addLeadingZero, dateFormat, currencyFormat, compareDate } from '../../../shared/commonHelper';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from "moment"
class HotelGuestComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showConfirmationPopup: true,
            selectedIssueYear: "",
            selectedIssueMonth: "",
            selectedIssueDay: "",
            selectedDobYear: "",
            selectedDobMonth: "",
            selectedDobDay: "",
        }
    }

    // ----------Booking Confirmation popup-------------
    handleConfirmationPopup = (action) => {
        if (action === false) {
            this.props.history.push({
                pathname: "/hotel-search-details",
                state: {
                    searchAPINo: true
                }
            })
        }
        else {
            this.setState({ showConfirmationPopup: !this.state.showConfirmationPopup });
        }
    }

    


    // Hotel Norms Information
    renderHotelNorms(hotelNorms) {
        return (
            <PerfectScrollbar>
                <div className="inner-fare-block hotel-norms">
                    <ul className="hotel-norms-list">
                        {hotelNorms && hotelNorms.map((hotelNorm, key) => {
                            let norm = hotelNorm.replace(/\|\\r\n/g, '<br/>');
                            return (
                                norm && norm !== '' &&
                                <li key={key}>
                                    <span dangerouslySetInnerHTML={{ __html: norm }} ></span>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </PerfectScrollbar>
        );
    }

    copyPanToOtherField = () => {
        let { setFieldValue, values, searchQuery } = this.props;
        searchQuery.room_guests.map((room, index) => {
            if (index === 0) {
                if (values['isCopyPANToAll'] === false) {
                    for (let i = 2; i <= room.no_of_adults; i++) {
                        setFieldValue(`pan_no_room${index + 1}_adult${i}`, values[`pan_no_room1_adult1`]);
                    }
                } else {
                    for (let i = room.no_of_adults; i > 1; i--) {
                        setFieldValue(`pan_no_room${index + 1}_adult${i}`, '');
                    }
                }
            } else {
                for (let i = 1; i <= room.no_of_adults; i++) {
                    if (values['isCopyPANToAll'] === false) {
                        setFieldValue(`pan_no_room${index + 1}_adult${i}`, values[`pan_no_room1_adult1`]);
                    } else {
                        setFieldValue(`pan_no_room${index + 1}_adult${i}`, '');
                    }
                }
            }
            return null;
        });
    }
    

    render() {
        let { handleSubmit, values,showConfirmationPopupNRI,handlePopupSubmit} = this.props;
        let self = this;
        let roomDetails = this.props.blockHotelDetails;
        return (
            <div className="tabs-content-wrap">
                <div className="step-content" >
                    <h3 className="step-title">
                        <span>Please enter names as mentioned on your government ID proof</span>
                        <span className="cols pull-right">
                            { /* eslint-disable-next-line */}
                            <div className="pull-right"><button className="btn btn-yellow sm-yellow-btn" onClick={() =>
                                this.props.history.push({
                                    pathname: "/hotel-search-details",
                                    state: {
                                        searchAPINo: false
                                    }
                                })
                            } >Choose another hotel</button></div>

                            {/* <a href="javascript:void(0);" onClick={() => 
                                this.props.history.push({
                                    pathname:"/hotel-search-details",
                                    state:{
                                        searchAPINo : true
                                    }
                                })
                            } >Choose another hotel</a> */}
                        </span>
                    </h3>
                    <div className="review-filght-step">
                        {/*------------------- Hotel booked confirmation details --------------------*/}
                        {<BookingConfirmationDetailsComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} cancelPolicy={this.props.cancelPolicy} />}

                        {/*------------------- Number of guest details room wise --------------------*/}
                        {<GuestRoomDetailsComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} />}

                        {/*------------------- Passenger details --------------------*/}
                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left">
                                    <span>Enter Guest Details</span>
                                </div>
                                {/* <div className="cols pull-left">
                                    <div className="radio-check radio-check-pan inline-check">
                                        <Field type="radio" name="isCopyPANToAll" id="11" value={true} defaultChecked={values['isCopyPANToAll']} />
                                        <label htmlFor="11">Copy PAN to All</label>
                                    </div>
                                </div> */}
                                <div className="check-pan">
                                        <small className="checkbox"><Field name="isCopyPANToAll" type="checkbox" id="11" class="form-control" value={true} defaultChecked={values['isCopyPANToAll']} 
                                        onClick={() => this.copyPanToOtherField()}/><label></label></small>
                                        <span htmlFor="11">Copy PAN to All</span>
                                    </div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                    {this.props.searchQuery.room_guests.map( (room, index) =>{
                                        return (
                                            <React.Fragment key={index}>
                                                {<PassengerDetailComponent  {...self.props} travellerType={'adult'}  trevellerCount={room.no_of_adults} childAge={room.child_age} roomNumber={index + 1}/>}
                                                {<PassengerDetailComponent {...self.props} travellerType={'child'}  trevellerCount={room.no_of_child} childAge={room.child_age} roomNumber={index + 1}/>}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {/*------------------- Guest arrival details --------------------*/}
                        {<GuestArrivalDetailComponent {...this.props} />}

                        {/*------------------- Guest arrival details --------------------*/}
                        {<IsCorporateComponent {...this.props} />}

                        {/*------------------- Room cancellation and charge details --------------------*/}
                        {<RoomCancellationAndChargeDetailComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} cancelPolicy={this.props.cancelPolicy} />}

                        {/*------------------- Amenities detail --------------------*/}
                        {<AmenitiesDetailComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} />}

                        {/*------------------- Hotel norm details -----------------*/}
                        {<HotelNormDetailComponent roomDetails={roomDetails} renderHotelNorms={this.renderHotelNorms} />}

                        {
                          <div className={`popup-crad confirmation-popup nri-confirmation-popup ${showConfirmationPopupNRI ? 'show' : ''}`}>
                            <div className="popup-outer">
                                <div className="popup-content">
                                    <div className="popup-head">
                                        <h2>CONFIRM TO PROCEED</h2>
                                        <div className="popup-close"><span className="icon icon-cross" onClick={() => this.props.handleConfirmationPopupNRI(false)} /></div>
                                    </div>
                                    <div className="popup-body popup-scorll">
                                        <div className="inner-confirmation">
                                            <p>You are making a booking for a non indian national guest(s).Passport of the guest(s) is mandatory and to be submitted after completion of booking</p>
                                            <p>Please mail the above documents to holidays@jaipurflight.com</p>
                                            <div className="popup-footer">
                                                <button className="btn btn-orange" onClick={() => {handlePopupSubmit(handleSubmit,true)}}>Yes, I Accept to provide the above</button>
                                                <button className="btn btn-grey" onClick={() => this.props.handleConfirmationPopupNRI(false)}>back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-overlay" />
                        </div>
                        }

                        <div className="btn-block text-right">
                        {
                                roomDetails.loading ?
                                    <button type="button" className="btn btn-primary loading-btn">
                                        <span>Continue</span>
                                    </button> :
                                    <button type="button" className="btn btn-primary loading-btn" onClick={() => {
                                        handleSubmit();
                                        setTimeout(() => {
                                           this.props.scrollToFirstError(this.props.errors) 
                                        }, );
                                        }}>
                                        <span>Continue</span>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <ChangedPolicyConfirmationComponent {...this.props} {...this.state} handleConfirmationPopup={this.handleConfirmationPopup} />
            </div>
        )
    }
}

/*--------- Hotel confirmation details component ------------*/
class BookingConfirmationDetailsComponent extends React.Component {
    render() {
        let { roomDetails, cancelPolicy } = this.props;
        let hotelInfo = roomDetails.response && roomDetails.response;
        let ratingLi = [];

        if (hotelInfo) {
            for (let i = 0; i < hotelInfo.star_rating; i++) {
                ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
            }
        }

        let checkInDate = this.props.searchQuery && this.props.searchQuery.check_in_date.split('/')[2] + '-' + this.props.searchQuery.check_in_date.split('/')[1] + '-' + this.props.searchQuery.check_in_date.split('/')[0];
        let checkOutDate = this.props.searchQuery && this.props.searchQuery.check_out_date.split('/')[2] + '-' + this.props.searchQuery.check_out_date.split('/')[1] + '-' + this.props.searchQuery.check_out_date.split('/')[0];
        let bookingUnderCancellation = hotelInfo && hotelInfo.supplier_type !== 'tripjack' && hotelInfo.hotel_room_details[0].last_cancellation_date ? compareDate('currentDate', hotelInfo.hotel_room_details[0].last_cancellation_date) : 'false';
        let cancellationDateTripjack=hotelInfo && hotelInfo.supplier_type === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies && cancelPolicy.response.cancellation_policies.length > 0 && 
        cancelPolicy.response.cancellation_policies.filter((date)=>{
           return date.charge === 0;
        });
        if(cancellationDateTripjack && cancellationDateTripjack.length > 0){
            if(cancellationDateTripjack && cancellationDateTripjack.length > 1){
                cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                  return  new Date(current.to_date) > new Date(max.to_date) ? current : max;
               });
           }else{
                cancellationDateTripjack=cancellationDateTripjack[0]
           }
        }
        let bookingUnderCancellation1 = (cancellationDateTripjack === null) || (cancellationDateTripjack && cancellationDateTripjack.length === 0)  ? "true" : 'false';
        return (
            <div className="review-step">
                <div className="review-step-deatil">
                    {!roomDetails.loading && hotelInfo ?
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    {
                                         bookingUnderCancellation === 'true' &&
                                        <h2 className="booking-cancellation">Booking Under Cancellation</h2>
                                    }
                                    {
                                        bookingUnderCancellation1 === 'true' &&
                                        <h2 className="booking-cancellation">Booking Under Cancellation</h2>
                                    }
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr><td><span className="text-bold">Hotel Name : </span> {hotelInfo.hotel_name} <ul className="rating">{ratingLi}</ul></td></tr>
                                            {
                                                hotelInfo.supplier_type === 'tripjack' &&
                                                <tr>
                                                    <td>
                                                        <span className="text-bold">Hotel Address : </span> 
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.adr + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.city && hotelInfo.address.city.name + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.state && hotelInfo.address.state.name + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.postalCode + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.country && hotelInfo.address.country.name}</span>

                                                    </td>
                                                </tr>
                                            }
                                            {
                                                hotelInfo.supplier_type !== 'tripjack' &&
                                                <tr><td><span className="text-bold">Hotel Address : </span> {hotelInfo.address_line1} {hotelInfo.address_line2 && ', ' + hotelInfo.address_line2}</td></tr>
                                            }
                                            <tr>
                                                <td>
                                                    <div className="travell-date">
                                                        <div className="cols"><span className="text-bold">Check In Date: </span> <small>{dateFormat(checkInDate, 'DD MMM YYYY')}</small></div>
                                                        <div className="cols"> <span className="text-bold">Check Out Date: </span> <small>{dateFormat(checkOutDate, 'DD MMM YYYY')}</small></div>
                                                    </div>
                                                    <div className="travell-date">
                                                        {hotelInfo && hotelInfo.supplier_type !== 'tripjack' && hotelInfo.hotel_room_details && hotelInfo.hotel_room_details[0] && hotelInfo.hotel_room_details[0].last_cancellation_date &&
                                                            <div className="last-cancel-date cols">
                                                                <span className="text-bold">Last Cancellation Date: </span>{dateFormat(hotelInfo && hotelInfo.hotel_room_details[0] && hotelInfo.hotel_room_details[0].last_cancellation_date, 'DD MMM YYYY')}
                                                            </div>
                                                        }
                                                        {cancellationDateTripjack && cancellationDateTripjack.to_date && hotelInfo && hotelInfo.supplier_type === 'tripjack' && 
                                                            <div className="last-cancel-date cols">
                                                                <span className="text-bold">Last Cancellation Date: </span>{dateFormat(cancellationDateTripjack && cancellationDateTripjack.to_date, 'DD MMM YYYY')}
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="loader relative-loader" style={{ display: 'block' }}>
                            <div className="loader-content">
                                <div className="loader-ring blue-ring"> <span></span> </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

/*-------------- Passenger details component -----------------*/
class PassengerDetailComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedIssueYear: "",
            selectedIssueMonth: "",
            selectedIssueDay: "",

            selectedDobYear: "",
            selectedDobMonth: "",
            selectedDobDay: "",
        }
    }

    years = [...Array(100).keys()].map((i) => i + new Date().getFullYear());
    Issueyears = [...Array(100).keys()].map((i) => new Date().getFullYear() - i);
    Dobyears = [...Array(100).keys()].map((i) => new Date().getFullYear() - i).filter((year) => year <= 2011);
    DobChildyears = [...Array(100).keys()].map((i) => new Date().getFullYear() - i).filter((year) => year >= 2011 && year <= new Date().getFullYear() - 2);
    DobInfantyears = [...Array(3).keys()].map((i) => new Date().getFullYear() - 2 + i);

    months = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];
    days = [...Array(31).keys()].map((i) => i + 1);
    //Function set date field value

    setDateFieldValue = (field, value) => {
        this.props.setFieldValue(field, value);
        this.props.touched[field] = true;
    }

    handlePAN = (event,ind) => {
        let { travellerType, trevellerCount, roomNumber, setFieldValue } = this.props;
        let firstPersonPAN = event.target.value;
        for (let i = 2; i <= trevellerCount; i++) {
            setFieldValue('pan_no_room' + roomNumber + '_' + travellerType + ind, firstPersonPAN);
        }
    }

    render() {
        let { travellerType, trevellerCount, roomNumber, handleChange, handleBlur, errors, values, childAge, touched, searchQuery,setFieldValue } = this.props;
        let { roomData,hoteldata } = this.props.location.state;
        const isPassportMandatory = roomData[roomNumber - 1] && roomData[roomNumber - 1].is_passport_mandatory === true ? true : false;
        const isPanMandatory = roomData[roomNumber - 1] && roomData[roomNumber - 1].is_pan_mandatory;
        let supplierType = hoteldata && hoteldata.supplier_type
        let rows = [];
       
        for (let i = 1; i <= trevellerCount; i++) {
            rows.push(
                <div className="review-step" key={i}>
                    <div className="passenger-title">
                        <div className="cols pull-left">
                            <i className={`icon ${travellerType === 'child' ? 'icon-child' : 'icon-adult'}`}></i>
                            <span>Room {roomNumber}</span><small>({travellerType + ' ' + (i)})</small> </div>
                        <div className="cols pull-right">
                            { /* eslint-disable-next-line */}
                            <a className="link-col" href="/" onClick={(e) => {e.preventDefault();this.props.handlePopup(travellerType, roomNumber, i)}} >Select Guest from Customer List</a>
                        </div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="adult-form">
                                <div className="block form-card">
                                    <div className="adult-form-outer">
                                        <div className="form-row">
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Salutation<sup>*</sup></label>
                                                    <div className="custom-select-box">
                                                        <select value={values['guest_title_room' + roomNumber + '_' + travellerType + i]} className={(errors['guest_title_room' + roomNumber + '_' + travellerType + i] && touched['guest_title_room' + roomNumber + '_' + travellerType + i]) ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} name={'guest_title_room' + roomNumber + '_' + travellerType + i} onChange={handleChange} onBlur={handleBlur}>
                                                            {
                                                                this.props.paxTitles.response && this.props.paxTitles.response.pax_titles.map((item, key) => {
                                                                    if (item.pax_type.toLowerCase() === travellerType) {
                                                                        return (
                                                                            <option key={key} value={item.passenger_title}>{item.passenger_title}</option>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return false;
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-lg-3 ${errors['first_name_room' + roomNumber + '_' + travellerType + i] && errors['first_name_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                <div className="form-group">
                                                    <label className="control-label">First Name <sup>*</sup></label>
                                                    <Field value={values['first_name_room' + roomNumber + '_' + travellerType + i]} className={(errors['first_name_room' + roomNumber + '_' + travellerType + i] && touched['first_name_room' + roomNumber + '_' + travellerType + i]) ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="First Name" type="text" name={'first_name_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} style={{textTransform:"uppercase"}} />
                                                    {errors['first_name_room' + roomNumber + '_' + travellerType + i] && touched['first_name_room' + roomNumber + '_' + travellerType + i] &&
                                                        <span className="error-msg">{errors['first_name_room' + roomNumber + '_' + travellerType + i]}</span>}
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-lg-3 ${errors['last_name_room' + roomNumber + '_' + travellerType + i] && errors['last_name_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                <div className="form-group">
                                                    <label className="control-label">Last Name <sup>*</sup></label>
                                                    <Field value={values['last_name_room' + roomNumber + '_' + travellerType + i]} className={(errors['last_name_room' + roomNumber + '_' + travellerType + i] && touched['last_name_room' + roomNumber + '_' + travellerType + i]) ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Last Name" type="text" name={'last_name_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} style={{textTransform:"uppercase"}} />
                                                    {errors['last_name_room' + roomNumber + '_' + travellerType + i] && touched['last_name_room' + roomNumber + '_' + travellerType + i] &&
                                                        <span className="error-msg">{errors['last_name_room' + roomNumber + '_' + travellerType + i]}</span>}
                                                </div>
                                            </div>
                                            {
                                                travellerType === 'adult' &&
                                                <div className={`col-sm-12 col-lg-3 ${errors['pan_no_room' + roomNumber + '_' + travellerType + i] && errors['pan_no_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                    {
                                                        searchQuery.is_domestic === 'true' && supplierType === 'tripjack' &&
                                                        <div className="form-group">
                                                        <label className="control-label">PAN{
                                                            isPanMandatory && <sup>*</sup>
                                                        }</label>
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    <Field value={values['pan_no_room' + roomNumber + '_' + travellerType + i]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={(e) => this.handlePAN(e,i)} style={{textTransform:"uppercase"}} />
                                                                    :
                                                                    <Field value={values['pan_no_room' + roomNumber + '_' + travellerType + i]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} style={{textTransform:"uppercase"}} />
                                                            }
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + 1]}</span>
                                                                    :
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + i]}</span>

                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        searchQuery.is_domestic === 'false' && supplierType === 'tripjack' &&
                                                        <div className="form-group">
                                                            <label className="control-label">PAN{isPanMandatory ? <sup>*</sup> : ""}</label>
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    <Field value={values['pan_no_room' + 1 + '_' + travellerType + 1]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + 1] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={(e) => this.handlePAN(e)} style={{textTransform:"uppercase"}} />
                                                                    :
                                                                    <Field value={values['pan_no_room' + roomNumber + '_' + travellerType + i]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} style={{textTransform:"uppercase"}} />
                                                            }
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + 1] && touched['pan_no_room' + roomNumber + '_' + travellerType + 1] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + 1]}</span>
                                                                    :
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + i]}</span>

                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        supplierType !== 'tripjack' &&
                                                        <div className="form-group">
                                                            <label className="control-label">PAN{isPanMandatory ? <sup>*</sup> : ""}</label>
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    <Field value={values['pan_no_room' + 1 + '_' + travellerType + 1]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + 1] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={(e) => this.handlePAN(e)} style={{textTransform:"uppercase"}} />
                                                                    :
                                                                    <Field value={values['pan_no_room' + roomNumber + '_' + travellerType + i]} className={errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="PAN" type="text" name={'pan_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} style={{textTransform:"uppercase"}} />
                                                            }
                                                            {
                                                                values['isCopyPANToAll']
                                                                    ?
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + 1] && touched['pan_no_room' + roomNumber + '_' + travellerType + 1] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + 1]}</span>
                                                                    :
                                                                    errors['pan_no_room' + roomNumber + '_' + travellerType + i] && touched['pan_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                    <span className="error-msg">{errors['pan_no_room' + roomNumber + '_' + travellerType + i]}</span>

                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                (travellerType === 'adult' && isPassportMandatory === true) &&  supplierType === 'tripjack' &&
                                                <React.Fragment>
                                                    <div className={`col-sm-12 col-lg-3 ${errors['passport_no_room' + roomNumber + '_' + travellerType + i] && errors['passport_no_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                        <div className="form-group">
                                                            <label className="control-label">Passport No. {isPassportMandatory ? <sup>*</sup> : ""}</label>
                                                            <Field value={values['passport_no_room' + roomNumber + '_' + travellerType + i]} className={errors['passport_no_room' + roomNumber + '_' + travellerType + 1] && touched['passport_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Passport No" type="text" name={'passport_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} />
                                                            {errors['passport_no_room' + roomNumber + '_' + travellerType + i] && touched['passport_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                <span className="error-msg">{errors['passport_no_room' + roomNumber + '_' + travellerType + i]}</span>}
                                                        </div>
                                                    </div>
                                                        <div className={`col-sm-12 col-lg-3 ${errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Passport Issue {isPassportMandatory ? <sup>*</sup> : ""}
                                                                </label>
                                                                <div className="date-picker">
                                                                    <label className="input-group input-addon">

                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                className={errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && touched['passport_issue_date_room' + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedIssueDay: event.target.value, });
                                                                                    setFieldValue('passporDaytIssue' + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values['passporYeartIssue' + roomNumber + '_' + travellerType + i] !== null && values['passporMonthtIssue' + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue('passport_issue_date_room' + roomNumber + '_' + travellerType + i, `${values['passporYeartIssue' + roomNumber + '_' + travellerType + i]}-${values['passporMonthtIssue' + roomNumber + '_' + travellerType + i]}-${parseInt(event.nativeEvent.target.value, 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={'passporDaytIssue' + roomNumber + '_' + travellerType + i}
                                                                                value={values['passporDaytIssue' + roomNumber + '_' + travellerType + i] ? values['passporDaytIssue' + roomNumber + '_' + travellerType + i] : "" } >
                                                                                <option value="" disabled>
                                                                                    Day
                                                                                </option>
                                                                                {this.days.map((day) => (
                                                                                    <option key={day} value={day}>
                                                                                        {day}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={values['passporDaytIssue' + roomNumber + '_' + travellerType + i] === null}
                                                                                className={errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && touched['passport_issue_date_room' + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedIssueMonth: event.target.value, });
                                                                                    setFieldValue('passporMonthtIssue' + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values['passporYeartIssue' + roomNumber + '_' + travellerType + i] !== null && values['passporDaytIssue' + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue('passport_issue_date_room' + roomNumber + '_' + travellerType + i, `${values['passporYeartIssue' + roomNumber + '_' + travellerType + i]}-${event.nativeEvent.target.value}-${parseInt(values['passporDaytIssue' + roomNumber + '_' + travellerType + i], 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={'passporMonthtIssue' + roomNumber + '_' + travellerType + i}
                                                                                value={values['passporMonthtIssue' + roomNumber + '_' + travellerType + i] ? values['passporMonthtIssue' + roomNumber + '_' + travellerType + i] : ""}  >
                                                                                <option value="" disabled>
                                                                                    Month
                                                                                </option>
                                                                                {this.months.map((month, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={month.value}
                                                                                    >
                                                                                        {month.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={values['passporMonthtIssue' + roomNumber + '_' + travellerType + i] === null}
                                                                                className={errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && touched['passport_issue_date_room' + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedIssueYear: event.target.value, });
                                                                                    setFieldValue('passporYeartIssue' + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values['passporMonthtIssue_' + roomNumber + '_' + travellerType + i] !== null && values["passporDaytIssue" + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue('passport_issue_date_room' + roomNumber + '_' + travellerType + i, `${event.nativeEvent.target.value}-${values['passporMonthtIssue' + roomNumber + '_' + travellerType + i]}-${parseInt(values['passporDaytIssue' + roomNumber + '_' + travellerType + i], 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={'passporYeartIssue' + roomNumber + '_' + travellerType + i}
                                                                                value={
                                                                                    //this.state.selectedIssueYear !== '' ? this.state.selectedIssueYear :
                                                                                    values['passporYeartIssue' + roomNumber + '_' + travellerType + i] ? values['passporYeartIssue' + roomNumber + '_' + travellerType + i] : ""
                                                                                }>
                                                                                <option value="" disabled>
                                                                                    Year
                                                                                </option>
                                                                                {this.Issueyears.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                {errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && touched['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && (
                                                                    <span className="error-msg">
                                                                        {
                                                                            errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i]
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={`col-sm-12 col-lg-3 ${errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Passport Expiry{isPassportMandatory ? <sup>*</sup> : ""}
                                                                </label>

                                                                <div className="date-picker">
                                                                    <label className="input-group input-addon">
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                className={errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && touched["passport_exp_date_room" + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedDay: event.target.value, });
                                                                                    setFieldValue("passportDayExpiry" + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values["passportYearExpiry" + roomNumber + '_' + travellerType + i] !== null && values["passportMonthExpiry" + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue("passport_exp_date_room" + roomNumber + '_' + travellerType + i, `${values["passportYearExpiry" + roomNumber + '_' + travellerType + i]}-${values["passportMonthExpiry" + roomNumber + '_' + travellerType + i]}-${parseInt(event.nativeEvent.target.value, 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={"passportDayExpiry" + roomNumber + '_' + travellerType + i}
                                                                                value={values["passportDayExpiry" + roomNumber + '_' + travellerType + i] ? values["passportDayExpiry" + roomNumber + '_' + travellerType + i] : ""} >
                                                                                <option value="" disabled>
                                                                                    Day
                                                                                </option>
                                                                                {this.days.map((day) => (
                                                                                    <option key={day} value={day}>
                                                                                        {day}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={values["passportDayExpiry" + roomNumber + '_' + travellerType + i] === null}
                                                                                className={errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && touched["passport_exp_date_room" + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedMonth: event.target.value, });
                                                                                    setFieldValue("passportMonthExpiry" + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values["passportYearExpiry" + roomNumber + '_' + travellerType + i] !== null && values["passportDayExpiry" + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue("passport_exp_date_room" + roomNumber + '_' + travellerType + i, `${values["passportYearExpiry" + roomNumber + '_' + travellerType + i]}-${event.nativeEvent.target.value}-${parseInt(values["passportDayExpiry" + roomNumber + '_' + travellerType + i], 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={"passportMonthExpiry" + roomNumber + '_' + travellerType + i}
                                                                                value={values["passportMonthExpiry" + roomNumber + '_' + travellerType + i] ? values["passportMonthExpiry" + roomNumber + '_' + travellerType + i] : ""}  >
                                                                                <option value="" disabled>
                                                                                    Month
                                                                                </option>
                                                                                {this.months.map((month, index) => (
                                                                                    <option key={index} value={month.value}>
                                                                                        {month.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={values["passportMonthExpiry" + roomNumber + '_' + travellerType + i] === null}
                                                                                className={errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && touched["passport_exp_date_room" + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({ selectedYear: event.target.value, });
                                                                                    setFieldValue("passportYearExpiry" + roomNumber + '_' + travellerType + i, event.target.value);
                                                                                    if (values["passportMonthExpiry" + roomNumber + '_' + travellerType + i] !== null && values["passportDayExpiry" + roomNumber + '_' + travellerType + i] !== null) {
                                                                                        setFieldValue("passport_exp_date_room" + roomNumber + '_' + travellerType + i, `${event.nativeEvent.target.value}-${values["passportMonthExpiry" + roomNumber + '_' + travellerType + i]}-${parseInt(values["passportDayExpiry" + roomNumber + '_' + travellerType + i], 10).toString().padStart(2, "0")}`);
                                                                                    }
                                                                                }}
                                                                                name={"passportYearExpiry" + roomNumber + '_' + travellerType + i}
                                                                                value={values["passportYearExpiry" + roomNumber + '_' + travellerType + i] ? values["passportYearExpiry" + roomNumber + '_' + travellerType + i] : ""} >
                                                                                <option value="" disabled>
                                                                                    Year
                                                                                </option>
                                                                                {this.years.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                {errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && touched["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && (
                                                                    <span className="error-msg">
                                                                        {errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i]}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                </React.Fragment>
                                            }
                                            {
                                                ((travellerType === 'adult' && searchQuery.is_domestic === 'false') || isPassportMandatory) &&  supplierType !== 'tripjack' &&
                                                <React.Fragment>
                                                    <div className={`col-sm-12 col-lg-3 ${errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                        <div className="form-group">
                                                            <label className="control-label">Passport No.{isPassportMandatory ? <sup>*</sup> : ""}</label>
                                                            <Field value={values['passport_no_room' + roomNumber + '_' + travellerType + i]} className={errors['passport_no_room' + roomNumber + '_' + travellerType + i] && touched['passport_no_room' + roomNumber + '_' + travellerType + i] ? "form-control custom-filed error-shadow" : "form-control custom-filed"} placeholder="Passport No" type="text" name={'passport_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} />
                                                            {errors['passport_no_room' + roomNumber + '_' + travellerType + i] && touched['passport_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                <span className="error-msg">{errors['passport_no_room' + roomNumber + '_' + travellerType + i]}</span>}
                                                        </div>
                                                    </div>
                                                        <div className={`col-sm-12 col-lg-3 ${errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] && errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Passport Issue
                                                                    {isPassportMandatory ? <sup>*</sup> : ""}
                                                                </label>
                                                                <div className="date-picker">
                                                                    <label className="input-group input-addon">

                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                className={
                                                                                    errors[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedIssueDay:
                                                                                            event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        'passporDaytIssue' + roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            'passport_issue_date_room' + roomNumber + '_' + travellerType + i,
                                                                                            `${values[
                                                                                            'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${values[
                                                                                            'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${parseInt(
                                                                                                event.nativeEvent.target
                                                                                                    .value,
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedIssueDay !== '' ? this.state.selectedIssueDay :
                                                                                    values[
                                                                                        'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Day
                                                                                </option>
                                                                                {this.days.map((day) => (
                                                                                    <option key={day} value={day}>
                                                                                        {day}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={
                                                                                    values[
                                                                                    'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                    ] === null
                                                                                }
                                                                                className={
                                                                                    errors[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedIssueMonth:
                                                                                            event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        'passporMonthtIssue' + roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            'passport_issue_date_room' + roomNumber + '_' + travellerType + i,
                                                                                            `${values[
                                                                                            'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${event.nativeEvent.target.value
                                                                                            }-${parseInt(
                                                                                                values[
                                                                                                'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                                ],
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedIssueMonth !== '' ? this.state.selectedIssueMonth :
                                                                                    values[
                                                                                        'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Month
                                                                                </option>
                                                                                {this.months.map((month, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={month.value}
                                                                                    >
                                                                                        {month.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={
                                                                                    values[
                                                                                    'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                    ] === null
                                                                                }
                                                                                className={
                                                                                    errors[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedIssueYear:
                                                                                            event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        'passporYeartIssue' + roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        'passporMonthtIssue_' + roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        "passporDaytIssue" + roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            'passport_issue_date_room' + roomNumber + '_' + travellerType + i,
                                                                                            `${event.nativeEvent.target.value
                                                                                            }-${values[
                                                                                            'passporMonthtIssue' + roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${parseInt(
                                                                                                values[
                                                                                                'passporDaytIssue' + roomNumber + '_' + travellerType + i
                                                                                                ],
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedIssueYear !== '' ? this.state.selectedIssueYear :
                                                                                    values[
                                                                                        'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        'passporYeartIssue' + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Year
                                                                                </option>
                                                                                {this.Issueyears.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                {errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i] &&
                                                                    touched[
                                                                    'passport_issue_date_room' + roomNumber + '_' + travellerType + i
                                                                    ] && (
                                                                        <span className="error-msg">
                                                                            {
                                                                                errors['passport_issue_date_room' + roomNumber + '_' + travellerType + i]
                                                                            }
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        </div>

                                                        <div className={`col-sm-12 col-lg-3 ${errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Passport Expiry{isPassportMandatory ? <sup>*</sup> : ""}

                                                                </label>

                                                                <div className="date-picker">
                                                                    <label className="input-group input-addon">

                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                className={
                                                                                    errors[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedDay: event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        "passportDayExpiry" + roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        "passportYearExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        "passportMonthExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            "passport_exp_date_room" + roomNumber + '_' + travellerType + i,
                                                                                            `${values[
                                                                                            "passportYearExpiry" +
                                                                                            roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${values[
                                                                                            "passportMonthExpiry" +
                                                                                            roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${parseInt(
                                                                                                event.nativeEvent.target.value,
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    "passportDayExpiry" + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedDay !== '' ? this.state.selectedDay :
                                                                                    values[
                                                                                        "passportDayExpiry" + roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        "passportDayExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Day
                                                                                </option>
                                                                                {this.days.map((day) => (
                                                                                    <option key={day} value={day}>
                                                                                        {day}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={
                                                                                    values[
                                                                                    "passportDayExpiry" + roomNumber + '_' + travellerType + i
                                                                                    ] === null
                                                                                }
                                                                                className={
                                                                                    errors[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedMonth: event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        "passportMonthExpiry" +
                                                                                        roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        "passportYearExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        "passportDayExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            "passport_exp_date_room" + roomNumber + '_' + travellerType + i,
                                                                                            `${values[
                                                                                            "passportYearExpiry" +
                                                                                            roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${event.nativeEvent.target.value
                                                                                            }-${parseInt(
                                                                                                values[
                                                                                                "passportDayExpiry" +
                                                                                                roomNumber + '_' + travellerType + i
                                                                                                ],
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    "passportMonthExpiry" + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedMonth !== '' ? this.state.selectedMonth :
                                                                                    values[
                                                                                        "passportMonthExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        "passportMonthExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Month
                                                                                </option>
                                                                                {this.months.map((month, index) => (
                                                                                    <option key={index} value={month.value}>
                                                                                        {month.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="custom-select-box select-col">
                                                                            <select
                                                                                disabled={
                                                                                    values[
                                                                                    "passportMonthExpiry" +
                                                                                    roomNumber + '_' + travellerType + i
                                                                                    ] === null
                                                                                }
                                                                                className={
                                                                                    errors[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                    ] &&
                                                                                        touched[
                                                                                        "passport_exp_date_room" + roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        ? "form-control custom-filed error-shadow"
                                                                                        : "form-control custom-filed"
                                                                                }
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    this.setState({
                                                                                        selectedYear: event.target.value,
                                                                                    });
                                                                                    setFieldValue(
                                                                                        "passportYearExpiry" +
                                                                                        roomNumber + '_' + travellerType + i,
                                                                                        event.target.value
                                                                                    );
                                                                                    if (
                                                                                        values[
                                                                                        "passportMonthExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null &&
                                                                                        values[
                                                                                        "passportDayExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ] !== null
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            "passport_exp_date_room" + roomNumber + '_' + travellerType + i,
                                                                                            `${event.nativeEvent.target.value
                                                                                            }-${values[
                                                                                            "passportMonthExpiry" +
                                                                                            roomNumber + '_' + travellerType + i
                                                                                            ]
                                                                                            }-${parseInt(
                                                                                                values[
                                                                                                "passportDayExpiry" +
                                                                                                roomNumber + '_' + travellerType + i
                                                                                                ],
                                                                                                10
                                                                                            )
                                                                                                .toString()
                                                                                                .padStart(2, "0")}`
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name={
                                                                                    "passportYearExpiry" + roomNumber + '_' + travellerType + i
                                                                                }
                                                                                value={
                                                                                    //this.state.selectedYear !== '' ? this.state.selectedYear :
                                                                                    values[
                                                                                        "passportYearExpiry" + roomNumber + '_' + travellerType + i
                                                                                    ]
                                                                                        ? values[
                                                                                        "passportYearExpiry" +
                                                                                        roomNumber + '_' + travellerType + i
                                                                                        ]
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Year
                                                                                </option>
                                                                                {this.years.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                {errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i] &&
                                                                    touched["passport_exp_date_room" + roomNumber + '_' + travellerType + i] && (
                                                                        <span className="error-msg">
                                                                            {errors["passport_exp_date_room" + roomNumber + '_' + travellerType + i]}
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        </div>  
                                                </React.Fragment>
                                            }
                                            {
                                                i === 1 && travellerType === 'adult' &&
                                                <React.Fragment>
                                                    <div className={`col-sm-12 col-lg-3 ${errors['contact_no_room' + roomNumber + '_' + travellerType + i] && errors['contact_no_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                        <div className="form-group">
                                                            <label className="control-label">Contact No</label>
                                                            <Field value={values['contact_no_room' + roomNumber + '_' + travellerType + i]} className={errors['contact_no_room' + roomNumber + '_' + travellerType + i] && touched['contact_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Contact No" type="text" name={'contact_no_room' + roomNumber + '_' + travellerType + i} onBlur={handleBlur} />
                                                            {errors['contact_no_room' + roomNumber + '_' + travellerType + i] && touched['contact_no_room' + roomNumber + '_' + travellerType + i] &&
                                                                <span className="error-msg">{errors['contact_no_room' + roomNumber + '_' + travellerType + i]}</span>}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {/*
                                                childAge && travellerType === 'child' &&
                                                <div className="col-sm-12 col-lg-3">
                                                    <div className="child-age">
                                                        <span>Age : {childAge[i - 1]}</span>
                                                    </div>
                                                </div>
                                                
                                        */}
                                            {
                                                childAge && travellerType === 'child' &&   <React.Fragment>
                                                <div className={`col-sm-12 col-lg-3 ${errors['contact_no_room' + roomNumber + '_' + travellerType + i] && errors['contact_no_room' + roomNumber + '_' + travellerType + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                                    <div className="form-group">
                                                        <label className="control-label">Age</label>
                                                        <Field value={childAge[i - 1]} className={errors['contact_no_room' + roomNumber + '_' + travellerType + i] && touched['contact_no_room' + roomNumber + '_' + travellerType + i] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Contact No" type="text" name={'contact_no_room' + roomNumber + '_' + travellerType + i} title="Read Only" disabled />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            }
                                        </div>
                                        {/* {errMessage && <div style={{color:"red"}}>*{errMessage}</div>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return rows;
    }
}

/*------------- Guest arriving info component ------------*/
class IsCorporateComponent extends React.Component {

    render() {
        let { values } = this.props;
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Is Corporate</span></div>
                </div>
                <div className="review-step-deatil">
                    <div className="step-deatil-outer">
                        <div className="adult-form">
                            <div className="block form-card">
                                <div className="adult-form-outer">
                                    <div className="form-row">
                                        <div className="col-sm-12 col-lg-3">
                                            <div className="form-group">
                                                <div className="control-group">
                                                    <div className="show-check">
                                                        <small className="checkbox">
                                                            <Field name="is_corporate" type="checkbox" id="10" className="form-control" defaultChecked={values['is_corporate']} />
                                                            <label></label>
                                                        </small>
                                                        <span>is Corporate</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/*------------- Guest arriving info component ------------*/
class GuestArrivalDetailComponent extends React.Component {
    render() {
        let { handleBlur, values, setFieldValue, handleChange, errors, touched } = this.props;
        // get all hours numbers
        let hours = [];
        for (let i = 0; i <= 23; i++) {
            let n = addLeadingZero(i);
            hours.push(n);
        }
        // get all minutes numbers
        let minutes = [];
        for (let i = 0; i <= 59; i++) {
            let n = addLeadingZero(i);
            minutes.push(n);
        }
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Arrival Details</span></div>
                </div>
                <div className="review-step-deatil">
                    <div className="step-deatil-outer">
                        <div className="adult-form">
                            <div className="block form-card">
                                <div className="adult-form-outer">
                                    <div className="form-row">
                                        <div className="col-sm-12 col-lg-3">
                                            <div className="form-group">
                                                <div className="control-group">
                                                    <div className="radio-check inline-check">
                                                        <Field name="transport_type" type="radio" id="1" defaultChecked={values.transport_type === 'flight' ? 'checked' : ''} classes="form-control" value="flight" onBlur={handleBlur} />
                                                        <label htmlFor="1">Arriving by flight</label>
                                                    </div>
                                                    <div className="radio-check inline-check">
                                                        <Field name="transport_type" type="radio" id="2" defaultChecked={values.transport_type === 'surface' ? 'checked' : ''} classes="form-control" value="surface" onBlur={handleBlur} />
                                                        <label htmlFor="2">Arriving by surface</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {values.transport_type === 'flight' &&
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Flight No</label>
                                                    <Field className={errors['flight_no'] && touched['flight_no'] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Flight No" type="text" name="flight_no" onBlur={handleBlur} />
                                                </div>
                                            </div>
                                        }

                                        {values.transport_type === 'surface' &&
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Transport Type/No</label>
                                                    <Field className={errors['transport_no'] && touched['transport_no'] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} placeholder="Transport Type/No" type="text" name="transport_no" onBlur={handleBlur} />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-sm-12 col-lg-2">
                                            <div className="form-group">
                                                <label className="control-label">Date</label>
                                                <div>
                                                    <label className="input-group input-addon">
                                                        <DatePicker
                                                            selected={values.check_in_date}
                                                            className="form-control custom-filed date"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            name="check_in_date"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            onChange={(date) => setFieldValue('check_in_date', date)}
                                                        />
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span>
                                                    </label></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-2">
                                            <div className="form-group">
                                                <label className="control-label">Hours</label>
                                                <div className="custom-select-box">
                                                    <select value={values['arrival_hour']} className={errors['arrival_hour'] && touched['arrival_hour'] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} name="arrival_hour" onChange={handleChange}>
                                                        <option value="">Hours</option>
                                                        {
                                                            hours.map((item, key) => {
                                                                return (
                                                                    <option key={key} value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-2">
                                            <div className="form-group">
                                                <label className="control-label">Minutes</label>
                                                <div className="custom-select-box">
                                                    <select value={values['arrival_minute']} className={errors['arrival_minute'] && touched['arrival_minute'] ? 'form-control custom-filed error-shadow' : 'form-control custom-filed'} name="arrival_minute" onChange={handleChange}>
                                                        <option value="">Minutes</option>
                                                        {
                                                            minutes.map((item, key) => {
                                                                return (
                                                                    <option key={key} value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/*------------ Guest room details component --------------*/
class GuestRoomDetailsComponent extends React.Component {
    render() {
        let { searchQuery, roomDetails } = this.props;
        let hotelRoomInfo = roomDetails.response && roomDetails.response.hotel_room_details && roomDetails.response.hotel_room_details;
        let hotelRoomInfoTripjack = roomDetails.response && roomDetails.response.hotel_rooms_details && roomDetails.response.hotel_rooms_details;
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Room Details</span></div>
                </div>
                <div className="review-step-deatil"><div className="step-deatil-outer">
                    <div className="flight-information">
                        {!roomDetails.loading ?
                            <div className="inner-fl-info">
                                <table className="fl-book-info">
                                    <thead>
                                        <tr>
                                            <th>Rooms</th>
                                            <th>Room Type</th>
                                            <th>No. of Guests</th>
                                        </tr>
                                    </thead>
                                    {!roomDetails.loading && hotelRoomInfo &&
                                        <tbody>
                                            {/* { hotelRoomInfo.map((room, index) => { */}
                                            {searchQuery.room_guests.map((room, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{'Room ' + (index + 1)}</td>
                                                        <td className="room-inclusion">
                                                            <span>{hotelRoomInfo[index] && hotelRoomInfo[index].room_type_name + ' '}</span>
                                                            {hotelRoomInfo[index] && hotelRoomInfo[index].amenities.length !== 0 && <span className="">Incl: {hotelRoomInfo[index] && hotelRoomInfo[index].amenities.join(',')}</span>}
                                                            {/* {hotelRoomInfo[index].room_promotion && <span className="red-text">{hotelRoomInfo[index].room_promotion.replace(/\|/g, "")}</span>} */}
                                                        </td>
                                                        <td>{room.no_of_adults + ' Adult(s)'} {room.no_of_child !== 0 && ',' + room.no_of_child + 'Child(s)'}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    }
                                    {!roomDetails.loading && hotelRoomInfoTripjack &&
                                        <tbody>
                                            {searchQuery.room_guests.map((room, index) => {
                                                return (
                                                    hotelRoomInfoTripjack && hotelRoomInfoTripjack.map((item) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{'Room ' + (index + 1)}</td>
                                                                <td className="room-inclusion">
                                                                    <span>{item && item.room_combination[index] && item.room_combination[index].room_type_name + ' '}</span>
                                                                    {item && item.room_combination && item.room_combination[index] && item.room_combination[index].inclusion && <span className="">Incl: {item && item.room_combination[index].inclusion}</span>}
                                                                </td>
                                                                <td>{room.no_of_adults + ' Adult(s)'} {room.no_of_child !== 0 && ',' + room.no_of_child + 'Child(s)'}</td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                            :
                            <div className="loader relative-loader" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


/*--------- Room cancellation and charge details component ------------*/
class RoomCancellationAndChargeDetailComponent extends React.Component {
    render() {
        let { searchQuery, roomDetails,cancelPolicy } = this.props;
        let HotelRoomDetails = roomDetails.response && roomDetails.response.hotel_room_details;
        let HotelRoomDetail = roomDetails && roomDetails.response && roomDetails.response.hotel_rooms_details;
        let supplier_type = roomDetails && roomDetails.response && roomDetails.response.supplier_type;
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Cancellation and Charges</span></div>
                </div>
                {
                    supplier_type !== 'tripjack' &&
                    <div className="review-step-deatil">
                        {!roomDetails.loading ?
                            HotelRoomDetails && searchQuery.room_guests.map((room, index) => {
                                let cancellationPolicies = HotelRoomDetails[index] && HotelRoomDetails[index].cancellation_policies;
                                return (
                                    <div className="step-deatil-outer" key={index}>
                                        <div className="flight-information">
                                            <div className="inner-fl-info">
                                                <div className="pull-left"><span>{'Room ' + (index + 1)} : {HotelRoomDetails[index].room_type_name}</span></div>
                                                <table className="fl-book-info">
                                                    <thead>
                                                        <tr>
                                                            <th>Cancelled on or After</th>
                                                            <th>Cancelled on or Before</th>
                                                            <th>Cancellation Charges</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cancellationPolicies.map((Policies, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                    <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                    {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                    {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="loader relative-loader" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                        }
                        <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                    </div>
                }
                {
                    supplier_type === 'tripjack' &&
                    <div className="review-step-deatil">

                        {supplier_type === 'tripjack' && !cancelPolicy.loading ?
                            HotelRoomDetail && searchQuery.room_guests.map((room, index) => {
                                return(
                                    HotelRoomDetail[index] && HotelRoomDetail[index].room_combination.map((itee, ind) => {
                                        return (
                                            <div className="step-deatil-outer" key={index}>
                                                <div className="flight-information">
                                                    <div className="inner-fl-info">
                                                        <div className="pull-left"><span>{'Room ' + (ind + 1)} : {itee && itee.room_type_name}</span></div>
                                                        <table className="fl-book-info">
                                                            <thead>
                                                                <tr>
                                                                    <th>Cancelled on or After</th>
                                                                    <th>Cancelled on or Before</th>
                                                                    <th>Cancellation Charges</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((Policies, cancellationKey) => {
                                                                        return (
                                                                            <tr key={cancellationKey}>
                                                                                <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                                <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                                {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                                {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                                
                            })
                            :
                            <div className="loader relative-loader" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                        }
                        <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                    </div>
                }
            </div>
        );
    }
}

/*--------- Hotel norm details component ------------*/
class HotelNormDetailComponent extends React.Component {
    render() {
        let { roomDetails, renderHotelNorms } = this.props;
        let hotelNorms = roomDetails && roomDetails.response && roomDetails.response.hotel_policy_detail && roomDetails.response.hotel_policy_detail.split('|');
        let hotelNorm = roomDetails && roomDetails.response && roomDetails.response.norms
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Norms</span></div>
                </div>
                <div className="review-step-deatil">
                    <div className="step-deatil-outer">
                        {!roomDetails.loading ?
                            <div className="fare-rule-info">
                                {
                                    roomDetails && roomDetails.response && roomDetails.response.supplier_type !== 'tripjack' &&
                                    renderHotelNorms(hotelNorms)
                                }
                                {
                                    roomDetails && roomDetails.response && roomDetails.response.supplier_type === 'tripjack' &&
                                    renderHotelNorms(hotelNorm)
                                }

                            </div>
                            :
                            <div className="loader relative-loader" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


/*--------- Amenities details component ------------*/
class AmenitiesDetailComponent extends React.Component {
    render() {
        let { searchQuery, roomDetails } = this.props;
        let hotelRoomInfo = roomDetails && roomDetails.response && roomDetails.response.hotel_room_details ? roomDetails.response.hotel_room_details : roomDetails && roomDetails.response && roomDetails.response.hotel_rooms_details;
        return (
            <div className="review-step">
                <div className="review-step-title">
                    <div className="cols pull-left"><span>Amenities</span></div>
                </div>
                <div className="review-step-deatil"><div className="step-deatil-outer">
                    <div className="flight-information">
                        {!roomDetails.loading ?
                            <div className="inner-fl-info">
                                <table className="fl-book-info">
                                    <thead></thead>
                                    {!roomDetails.loading && hotelRoomInfo &&
                                        <tbody>
                                            {roomDetails && roomDetails.response && roomDetails.response.supplier_type !== 'tripjack' && searchQuery.room_guests.map((room, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{'Room ' + (index + 1)}</td>
                                                        <td>
                                                            {hotelRoomInfo[index].amenities && hotelRoomInfo[index].amenities.length !== 0 ? <span>{hotelRoomInfo[index].amenities.join(',')}</span> : '-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {
                                                roomDetails && roomDetails.response && roomDetails.response.supplier_type === 'tripjack' && searchQuery.room_guests.map((room, index) => {
                                                    return (
                                                        hotelRoomInfo[index] && hotelRoomInfo[index].room_combination.map((itemRoom, indexRoom) => {
                                                            return (
                                                                <tr key={indexRoom}>
                                                                    <td>{'Room ' + (indexRoom + 1)}</td>
                                                                    <td>
                                                                        <span>{itemRoom && itemRoom.amenities}</span> : '-'
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                            :
                            <div className="loader relative-loader" style={{ display: 'block' }}>
                                <div className="loader-content">
                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

/*--------- Changed policy confirmation component ------------*/
class ChangedPolicyConfirmationComponent extends React.Component {

    render() {
        let { showConfirmationPopup, handleConfirmationPopup, blockHotelDetails, searchQuery, location } = this.props;
        let HotelRoomDetails = blockHotelDetails.response && blockHotelDetails.response.hotel_room_details;
        let newPrice = blockHotelDetails.response && blockHotelDetails.response.total_price && blockHotelDetails.response.total_price.published_price;
        let oldPrice = location && location.state && location.state.hoteldata && location.state.hoteldata.price_modified && location.state.hoteldata.price_modified.published_price;
        let isChangedCancelPolicy = blockHotelDetails.response && blockHotelDetails.response.supplier_type !== 'tripjack' && blockHotelDetails.response.is_cancellation_policy_changed;
        let OldHotelRoomDetails = location.state.roomData;
        return (
            isChangedCancelPolicy &&
            <div className={`popup-crad hotel-book-confirmation ${showConfirmationPopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>The price of your itinerary has been changed. Would you like to continue ?</h2>
                        </div>
                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                <div className="review-step-deatil">
                                    {/*-------------------- Section for old policy ------------------------*/}
                                    {OldHotelRoomDetails && searchQuery.room_guests.map((room, index) => {
                                        let cancellationPolicies = OldHotelRoomDetails[index].cancellation_policies;
                                        return (
                                            <div className="step-deatil-outer" key={index}>
                                                <div className="flight-information">
                                                    {index === 0 &&
                                                        <div className="hotel-popup-header">
                                                            <div className="pull-left"><span>Old Cancellation Policy</span></div>
                                                            <div className="pull-right"><span>Old Price: Rs. {currencyFormat(oldPrice)}</span></div>
                                                        </div>
                                                    }
                                                    <div className="inner-fl-info">
                                                        <div className="pull-block"><span>{'Room ' + (index + 1)} : {OldHotelRoomDetails[index].room_type_name}</span></div>
                                                        <table className="fl-book-info">
                                                            <thead>
                                                                <tr>
                                                                    <th>Cancelled on or After</th>
                                                                    <th>Cancelled on or Before</th>
                                                                    <th>Cancellation Charges</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {cancellationPolicies.map((Policies, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')}</td>
                                                                            <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')}</td>
                                                                            {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                            {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {/*-------------------- Section for new policy ------------------------*/}
                                    {HotelRoomDetails && searchQuery.room_guests.map((room, index) => {
                                        let cancellationPolicies = HotelRoomDetails[index].cancellation_policies;
                                        return (
                                            <div className="step-deatil-outer" key={index}>
                                                <div className="flight-information">
                                                    {index === 0 &&
                                                        <div className="hotel-popup-header">
                                                            <div className="pull-left"><span>New Cancellation Policy</span></div>
                                                            <div className="pull-right"><span>New Price: Rs. {currencyFormat(newPrice)}</span></div>
                                                        </div>
                                                    }
                                                    <div className="inner-fl-info">
                                                        <div className="pull-block"><span>{'Room ' + (index + 1)} : {HotelRoomDetails[index].room_type_name}</span></div>
                                                        <table className="fl-book-info">
                                                            <thead>
                                                                <tr>
                                                                    <th>Cancelled on or After</th>
                                                                    <th>Cancelled on or Before</th>
                                                                    <th>Cancellation Charges</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {cancellationPolicies.map((Policies, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')}</td>
                                                                            <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')}</td>
                                                                            {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                            {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                <div className="popup-footer">
                                    <div className="cancellation-note"><span>Click "YES" to fill the guest details or click "NO" to get redirected to search results.</span></div>
                                    <button className="btn btn-orange" onClick={() => handleConfirmationPopup(null)}>Yes</button>
                                    <button className="btn btn-grey" onClick={() => handleConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
        );
    }
}
export default HotelGuestComponent