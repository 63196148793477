import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import { currencyFormat, compareDate, getCurrentDate } from '../../shared/commonHelper';
import moment from 'moment';
import { apiUrl } from '../../api';

class AgentCommonHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cashBalance: 0,
            creditBalance: 0,
            load:false,
            userId:'',
            expiryDate:null,
            fetchResult:null
          };
        this.handleRecharge = this.handleRecharge.bind(this);
        
    }

    handleRecharge() {
        window.open('/my-account', '_blank');
    }

    cashBalance = () => {
        return Number(
            (this.state.fetchResult && this.state.fetchResult.cash_balance ?this.state.fetchResult && this.state.fetchResult.cash_balance : 0)
        );
    };
    creditBalance = () => {
        return Number(
            (this.state.fetchResult && this.state.fetchResult.credit_limit ? this.state.fetchResult && this.state.fetchResult.credit_limit : 0)
        );
    };
    componentDidMount(){
        this.myAccountApi()
    }
    myAccountApi = () => {
        this.setState({load:true})

        const requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.getItem('token')
            }
        };

        fetch(`${apiUrl}/account/my-account`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    this.setState({load:false})
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTimeout(() => {
                    this.setState({load:false})
                }, 1000);
                
                let result= data && data.content && data.content.agent_info
                this.setState({fetchResult:result})
            })
            .catch(error => {
                this.setState({load:false})
                
            });

    }
    // fetchBalance = async () => {
    //    this.myAccountApi()
    //     console.log(this.state.fetchResult && this.state.fetchResult.cash_balance);
    //     // if (!this.state.load) {
    //     //     this.setState({ load: true })
    //     //     await this.props.myAccountAction();
    //     //     let apiBalance = this.state.fetchResult && this.state.fetchResult.cash_balance ? this.state.fetchResult.cash_balance : this.props.accountResponse.myAccountResponse &&
    //     //         this.props.accountResponse.myAccountResponse.response &&
    //     //         this.props.accountResponse.myAccountResponse.response.cash_balance;
    //     //     let apiCreditBalance = this.state.fetchResult && this.state.fetchResult.credit_limit ? this.state.fetchResult.credit_limit :  this.props.accountResponse.myAccountResponse &&
    //     //         this.props.accountResponse.myAccountResponse.response &&
    //     //         this.props.accountResponse.myAccountResponse.response.credit_limit;
    //     //     let user_type_id = this.state.fetchResult && this.state.fetchResult.user_type_id ? this.state.fetchResult.user_type_id : this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.user_type_id;
    //     //     let expiryDate = this.state.fetchResult && this.state.fetchResult.credit_expiry_date ? this.state.fetchResult.credit_expiry_date : this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.credit_expiry_date

    //     //     if (this.props.accountResponse.myAccountResponse.loading === false) {
    //     //         setTimeout(() => {
    //     //             this.setState({ load: false });
    //     //         }, 1000);
    //     //     }
    //     //     if (user_type_id !== this.state.userId) {
    //     //         this.setState({ userId: user_type_id });
    //     //     }
    //     //     if (expiryDate !== this.state.expiryDate) {
    //     //         this.setState({ expiryDate: expiryDate });
    //     //     }
    //     //     if (apiCreditBalance !== this.state.creditBalance) {
    //     //         this.setState({ creditBalance: apiCreditBalance });
    //     //     }
    //     //     if (apiBalance !== this.state.cashBalance) {
    //     //         this.setState({ cashBalance: apiBalance });
    //     //         return apiBalance;
    //     //     } else {
    //     //         return this.state.cashBalance;
    //     //     }
    //     // }
    // }
    // componentDidUpdate = (props, state) => {
    //     let apiBalance = props.accountResponse.myAccountResponse &&
    //         props.accountResponse.myAccountResponse.response &&
    //         props.accountResponse.myAccountResponse.response.cash_balance;
    //     if (apiBalance !== state.cashBalance) {
    //         this.setState({ cashBalance: apiBalance });
    //         return apiBalance;
    //     } else {
    //         return state.cashBalance;
    //     }
    // }
    render() {
        // const { accountResponse } = this.props;
        let user_type_id = this.state.fetchResult && this.state.fetchResult.user_type_id
        let cashBalance = this.state.fetchResult && this.state.fetchResult.cash_balance && currencyFormat(this.state.fetchResult.cash_balance);
        let expiryDate = this.state.fetchResult && this.state.fetchResult.credit_expiry_date
        let is_credit_allowed =this.state.fetchResult && this.state.fetchResult.is_credit_allowed && this.state.fetchResult.is_credit_allowed.toLowerCase() === 'y';
        return (
            <div className="page-title-bar">
                <div className="container">
                    <div className="page-title-outer">
                        <div className="pull-left page-title-card">
                            <div className="breadcrumb"> { /* eslint-disable-next-line */}<Link to="/flight-search" className="breadcrumb-item">Home</Link> <span className="breadcrumb-item">My Account</span> </div>
                            <h1>My Account</h1>
                        </div>
                        <div className="cash-card-info" style={{ display: (this.state.userId ? this.state.userId : user_type_id) ?'block':'none' }}>
                            <Link to="/deposit-request">
                                <div className={`col ${parseInt(cashBalance) > 0 ? 'credit-limit-col' : ''}`}>
                                    <div className="cash-card"> <i className="icon icon-currency2" />
                                        <div className="cahs-detail"> <span>Cash Balance</span>
                                            <p>₹<strong>{currencyFormat(this.state.cashBalance ? this.state.cashBalance : this.cashBalance())}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            {
                                is_credit_allowed && <Link to="/credit-request">
                                <div className="col credit-limit-col">
                                    <div className="cash-card"> <i className="icon icon-currency" />
                                        <div className="cahs-detail"> <span>Credit Limit</span>  
                                        <p>₹<strong>{currencyFormat(this.state.creditBalance ? this.state.creditBalance : this.creditBalance())}</strong></p>
                                            {compareDate(this.state.expiryDate ? this.state.expiryDate : expiryDate,getCurrentDate()) === 'true' ?
                                            <span>(valid till-{moment(this.state.expiryDate ? this.state.expiryDate : expiryDate).format('DD MMM YYYY')})</span> : ''}
                                        </div>
                                    </div>
                                    {/* {accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.credit_expiry_date && compareDate(accountResponse.myAccountResponse.response.credit_expiry_date,getCurrentDate()) === 'true' ?
                                    <div className="tooltip-date"><span>{"Valid till "+getDateWithOrdinal(accountResponse.myAccountResponse.response.credit_expiry_date,'DD MMM YYYY')}</span></div>:""} */}
                                </div>
                            </Link>
                            }
                            {
                                this.state.load && <span
                                className={
                                   is_credit_allowed
                                            ? "refresh-icons refresh-icons-agent spin-loading"
                                            : "refresh-icons  refresh-icons-agent_credit spin-loading"
                                }
                                onClick={() => {this.myAccountApi()}}
                            >
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </span>
                            }
                            {
                                !this.state.load && <span
                                className={
                                   is_credit_allowed
                                            ? "refresh-icons refresh-icons-agent"
                                            : "refresh-icons  refresh-icons-agent_credit"
                                }
                                onClick={() => this.myAccountApi()}
                            >
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </span>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (AgentCommonHeader)
