import React from "react";
import FlightSearchHeaderComponent from "../../components/flights/FlightSearchHeaderComponent";
import { connect } from "react-redux";
import { flightSearchSaveQuery, getAllAirlines } from '../../actions/flight/flightSearchAction';
import { getPaxTitle, holdBooking, saveFlightOutboundPaxInfo } from "../../actions/flight/flightBookTicketAction";
import { getlocation } from "../../actions/shared/registerAction";
import { Formik } from "formik";
import * as Yup from "yup"
import { myAccountAction } from '../../actions/agentAction';
import { addPassenger, getCustomerList } from '../../actions/shared/sharedAction';
import { getCurrentDate, newDateFunction } from "../../shared/commonHelper";
import VendorFlightBookTicketComponent from "../../components/flights/booking/vendor_bookings/VendorFlightBookTicketComponent";
import VendorBookingConfirmComponent from "../../components/flights/booking/vendor_bookings/VendorBookingConfirmComponent";
import moment from 'moment';
import { getPassengerDetails, getSegments, getFare } from "../../shared/flightHelper";


class FlightBookTicketContainer extends React.Component {
    constructor() {
        super()
        this.state = {
            addPassengerSuccess : null,
            passengers          : null,
            booking_id          : null,
            formikValues        : null
        }
    }

    componentDidMount() {
        this.props.getAllAirlines(null);
        this.props.getPaxTitle();
        this.props.getlocation();
        this.props.getCustomerList();
    }

    //Function to save passengers into customer list and to save passengers data for booking
    addPassengersValues = (values) => {
        let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
        let fare = null;
        if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
            let index = this.props.location.state.index;
            fare = this.props.location.state.data[index].fare_breakdown;
        }else{
            fare = this.props.location.state.data.fare_breakdown;
        }
        let finalArray = [];
        let saveCustomer = [];
        let typeArray = ['adult', 'child', 'infant'];
        let passengerCount = 0;
        for (let j = 0; j < 3; j++) {
            for (let i = 1; i <= this.props.searchQuery[typeArray[j] + '_count']; i++) {
                //Get fare according to passenger
                let fare_distribution = null;
                if (fare) {
                    for (let k = 0; k < fare.length; k++) {
                        if (fare[k].passenger_type === parseInt(j) + 1)
                            fare_distribution = fare[k];
                    }
                }
                passengerCount = parseInt(passengerCount) + 1;
                //Array to save customers for final booking
                let pax_title = '';
                let pax_title_id = 0;
                let count = 0;
                if ((!values["title_name_" + typeArray[j] + "_" + i] || values["title_name_" + typeArray[j] + "_" + i] === '') && this.props.paxTitles.response) {
                    for (let i = 0; i < this.props.paxTitles.response.pax_titles.length; i++) {
                        let item = this.props.paxTitles.response.pax_titles[i];
                        if (item.pax_type.toLowerCase() === typeArray[j]) {
                            if (count === 0) {
                                pax_title = item.passenger_title;
                                pax_title_id = item.title_id;
                            }
                            count = parseInt(count) + 1;
                        }
                    }
                } else {
                    pax_title = values["title_name_" + typeArray[j] + "_" + i];
                    pax_title_id = values["passenger_title_" + typeArray[j] + "_" + i];
                }

                finalArray.push({
                    "first_name": values["first_name_" + typeArray[j] + "_" + i],
                    "last_name": values["last_name_" + typeArray[j] + "_" + i],
                    "mobile_no": parseInt(values.mobile_no),
                    "passenger_type": parseInt(j) + 1,
                    "title_name": pax_title,
                    "date_of_birth": values["dob_" + typeArray[j] + "_" + i] ? moment(values["dob_" + typeArray[j] + "_" + i]).format('YYYY-MM-DD') + 'T00:00:00' : '',
                    "passport_no": isDomestic === 'false' ? values["passport_" + typeArray[j] + "_" + i] : null,
                    "passport_exp": isDomestic === 'false' ? (values["passportExpiry_" + typeArray[j] + "_" + i] ? (moment(values["passportExpiry_" + typeArray[j] + "_" + i]).format('YYYY-MM-DD') + 'T00:00:00') : null) : null,
                    "frequent_flyer_no": values["frequentFlyer_" + typeArray[j] + "_" + i],
                    "ff_airline_code": values["ff_airline_" + typeArray[j] + "_" + i],
                    "addressLine1": values.addressLine1 && values.addressLine1 !== '' ? values.addressLine1 : (this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.office_address : ''),
                    "addressLine2": values.addressLine2,
                    "city_name": values.city_name,
                    "email_id": values.email,
                    "is_lead_pax": (j === 0 && i === 1) ? true : false,
                    "gender": values["gender_" + typeArray[j] + "_" + i],
                    "country_name": values.country_name,
                    "country_code": values.country_code,
                    "fare_distribution": fare_distribution,
                    "nationality": isDomestic === 'false' ? values["nationality_" + typeArray[j] + "_" + i] : null,
                })
                if (values["saveCustomer_" + typeArray[j] + "_" + i]) {
                    //Array to save passangers into customer list
                    saveCustomer.push({
                        "first_name": values["first_name_" + typeArray[j] + "_" + i],
                        "last_name": values["last_name_" + typeArray[j] + "_" + i],
                        "passenger_type": parseInt(j) + 1,
                        "gender": values["gender_" + typeArray[j] + "_" + i],
                        "date_of_birth": values["dob_" + typeArray[j] + "_" + i] ? moment(values["dob_" + typeArray[j] + "_" + i]).format('YYYY-MM-DD') : '',
                        "passport_no": values["passport_" + typeArray[j] + "_" + i],
                        "passport_exp": moment(values["passportExpiry_" + typeArray[j] + "_" + i]).format('YYYY-MM-DD'),
                        "passenger_title": parseInt(pax_title_id),
                        "frequent_flyer_no": values["frequentFlyer_" + typeArray[j] + "_" + i],
                        "nationality": isDomestic === 'false' ? values["nationality_" + typeArray[j] + "_" + i] : null,
                        "mobile_no": parseInt(values.mobile_no), //Add mobile_no only for first adult,
                        "email_id": (j === 0 && i === 1 && values.email) ? values.email : null, //Add email only for first adult,
                        "address": (j === 0 && i === 1 && values.addressLine1) ? values.addressLine1 + ' ' + values.addressLine2 : null, //Add address only for first adult,
                        "country": (j === 0 && i === 1 && values.country) ? values.country : null, //Add country only for first adult,
                        "city": (j === 0 && i === 1 && values.city) ? values.city : null, //Add city only for first adult,
                    })
                }
            }
        }
        this.setState({
            passengers: finalArray,
            addPassengerSuccess: true,
            formikValues : values
        })
        //Save passengers data into customer list
        if (saveCustomer.length > 0) {
            this.props.addPassenger(saveCustomer);
        }
    }

    initialState = () => {
        this.setState({
            addPassengerSuccess: null,
        })
    }

    bookingError = (response) => {
        this.props.history.push({
            pathname: "/booking-error",
            state: {
                page: 'booking',
                response: response.message,
                searchQuery: this.props.searchQuery,
                productResponse: this.props.productResponse,
                agentProductAccess: this.props.agentProductAccess
            }
        });
    }

    //Function to handle hold button click
    handleHold = () => {
        let fareQuote = {};
        let price_modified = null;
        let originCity      = "";
        let destinationCity = "";
        if(this.props.location.state.searchType === 'round-trip'){
            let index = this.props.location.state.index;
            fareQuote.results = this.props.location.state.data[index];
            price_modified = this.props.location.state.data[index].fare.price_modified;
            originCity = this.props.searchQuery.originAirportCode;
            destinationCity = this.props.searchQuery.destinationAirportCode;
        }else if(this.props.location.state.searchType === 'round-trip-complete'){
            let index = this.props.location.state.index;
            fareQuote.results = this.props.location.state.data[index];
            price_modified = this.props.location.state.data[index].fare.price_modified;
            originCity = this.props.searchQuery.destinationAirportCode;
            destinationCity = this.props.searchQuery.originAirportCode;
        }else{
            fareQuote.results = this.props.location.state.data;
            price_modified = this.props.location.state.data.fare.price_modified;
            originCity = this.props.searchQuery.originAirportCode;
            destinationCity = this.props.searchQuery.destinationAirportCode;
        }
        let passengersList = getPassengerDetails(this.state.passengers, fareQuote);
        let segments = getSegments(fareQuote);
        let fare = getFare(fareQuote);
        let bookingData = fareQuote.results;
        let holdData = {
            "result_index": bookingData.result_index,
            "flight_cabin_class": parseInt(this.props.searchQuery.segments[0].flight_cabin_class),
            "trip_type": parseInt(this.props.searchQuery.journey_type),
            "agent_discount": bookingData.fare.agent_discount,
            "agent_discount_type": bookingData.fare.agent_discount_type,
            "passengers": passengersList,
            "admin_unique_id": bookingData.fare.admin_unique_id,
            "agent_unique_id": bookingData.fare.agent_unique_id,
            "segments": segments,
            "service_charge": bookingData.fare.service_charge,
            "cgst": bookingData.fare.cgst,
            "igst": bookingData.fare.igst,
            "sgst": bookingData.fare.sgst,
            "offered_fare": parseInt(bookingData.fare.offered_fare),
            "fare": fare,
            "category_name": this.props.searchQuery && this.props.searchQuery.is_domestic === 'true' ? 'Domestic' : 'International',
            "tds": bookingData.fare.tds,
            "source": 6,
            "is_vendor": true,
            "price_modified": price_modified,
            "origin":originCity,
            "destination": destinationCity,
            "fare_rule": bookingData.fare_rules[0].fare_rule_detail
        }

        const successCb = (response) => {
            if(this.props.location.state.searchType === 'round-trip'){
                this.props.saveFlightOutboundPaxInfo(this.state.formikValues,response.booking_id,response.tbo_response.flight_itinerary.fare.published_fare);
            }
            this.props.history.push({
                pathname: "/pending-booking",
                state: {
                    data: response,
                    bookingType: 'drop',
                    searchType: this.props.location.state.searchType,
                    searchData: this.props.location.state.data,
                    index     : this.props.location.state.index,
                    initialState: this.initialState(),
                    trace_id :this.props.location.state.trace_id,
                    param: this.props.location.state.param,
                    param_rt: this.props.location.state.param_rt
                }
            });
        }
        this.props.holdBooking(holdData, successCb, this.bookingError);
    }

    //Function to switch page between traveller and review booking
    switchPage = (value) =>{
        this.setState({
            addPassengerSuccess : value
        })
    }

    render() {
        let initialValues = {}
        let passportValidation = {
            adult: false,
            child: false,
            infant: false
        };
        let country_id = '';
        if (!this.props.type) {
            let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
            let airline_code = null;
            if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
                let index = this.props.location.state.index;
                airline_code = this.props.location.state.data[index].segments[0][0].airline.airline_code;
            }else{
                airline_code = this.props.location.state.data.segments[0][0].airline.airline_code;
            }
            let typeArray = ['adult', 'child', 'infant'];
            var validationSchema = {};
            let self = this;
            if(this.props.location.state.searchType === "round-trip-complete"){
                initialValues = this.props.outboundPaxInfo.details;
            }
            //Set dynamic initial value for passengers detail
            for (let j = 0; j < 3; j++) {
                for (let i = 1; i <= this.props.searchQuery[typeArray[j] + '_count']; i++) {
                    if(this.props.location.state.searchType !== "round-trip-complete"){
                        initialValues["first_name_" + typeArray[j] + "_" + i] = "";
                        initialValues["last_name_" + typeArray[j] + "_" + i] = "";
                        initialValues["gender_" + typeArray[j] + "_" + i] = "male";
                        initialValues["dob_" + typeArray[j] + "_" + i] = null;
                        initialValues["frequentFlyer_" + typeArray[j] + "_" + i] = "";
                        initialValues["ff_airline_" + typeArray[j] + "_" + i] = "";
                        initialValues["passport_" + typeArray[j] + "_" + i] = "";
                        initialValues["passenger_title_" + typeArray[j] + "_" + i] = "1";
                        initialValues["saveCustomer_" + typeArray[j] + "_" + i] = "";
                        initialValues["title_name_" + typeArray[j] + "_" + i] = "";
                        initialValues["passportExpiry_" + typeArray[j] + "_" + i] = null;
                        initialValues["nationality_" + typeArray[j] + "_" + i] = "IN";
                    }
                    validationSchema = Object.assign(validationSchema,
                        {
                            ["first_name_" + typeArray[j] + "_" + i]: Yup.string()
                                .required('First Name is required'),
                            ["last_name_" + typeArray[j] + "_" + i]: Yup.string()
                                .required('Last Name is required'),
                            ["ff_airline_" + typeArray[j] + "_" + i]: Yup.string()
                                .test(["ff_airline_" + typeArray[j] + "_" + i], 'Airline code seems to be invalid.',
                                    function (value) {
                                        if (value === '' || !value) {
                                            return true
                                        } else {
                                            let flag = false;
                                            if (self.props.allAirlinesData) {
                                                for (let i = 0; i < self.props.allAirlinesData.response.length; i++) {
                                                    if (value === self.props.allAirlinesData.response[i].airline_code)
                                                        flag = true;
                                                }
                                            }
                                            if (flag)
                                                return true;
                                            else
                                                return false;
                                        }
                                    }
                                )
                        })
                    //DOB of Child and Infant are mandatory
                    if (typeArray[j] !== 'adult') {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .required('Date of birth is required')
                                    .test(["dob_" + typeArray[j] + "_" + i], 'Valid Infant age should be under 2 years.',
                                        function (value) {
                                            let dt1 = getCurrentDate();
                                            let dt2 = newDateFunction(value);
                                            let diff = (dt2.getTime() - dt1.getTime()) / 1000;
                                            diff /= (60 * 60 * 24);
                                            let dateDiff = Math.abs(Math.round(diff / 365.25));
                                            if (typeArray[j] === 'infant') {
                                                if (dateDiff > 2) {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            } else {
                                                return true;
                                            }
                                        }
                                    )
                                    .test(["dob_" + typeArray[j] + "_" + i], 'Valid child age is less than twelve years and greater than equal to 2 years.',
                                        function (value) {
                                            let dt1 = getCurrentDate();
                                            let dt2 = newDateFunction(value);
                                            let diff = (dt2.getTime() - dt1.getTime()) / 1000;
                                            diff /= (60 * 60 * 24);
                                            let dateDiff = Math.abs(Math.round(diff / 365.25));
                                            if (typeArray[j] === 'child') {
                                                if (dateDiff > 2 && dateDiff < 12) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else
                                                return true;
                                        }
                                    ),
                            })
                    } else {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .test(["dob_" + typeArray[j] + "_" + i], 'Adult age should be not be less than 12 years.',
                                        function (value) {
                                            if (value === null) {
                                                return true;
                                            } else {
                                                let dt1 = getCurrentDate();
                                                let dt2 = newDateFunction(value);
                                                let diff = (dt2.getTime() - dt1.getTime()) / 1000;
                                                diff /= (60 * 60 * 24);
                                                let dateDiff = Math.abs(Math.round(diff / 365.25));
                                                if (typeArray[j] === 'adult') {
                                                    if (dateDiff < 12) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                } else {
                                                    return true;
                                                }
                                            }
                                        }
                                    )
                            })
                    }

                    validationSchema = Object.assign(validationSchema,
                        {
                            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                                .test(["dob_" + typeArray[j] + "_" + i], 'Date of birth is required.',
                                    function (value) {
                                        let passportNo = this.resolve(Yup.ref("passport_" + typeArray[j] + "_" + i));
                                        let passportExpDate = this.resolve(Yup.ref("passportExpiry_" + typeArray[j] + "_" + i));
                                        if (passportNo || passportExpDate) {
                                            return value ? true : false;
                                        }
                                        else {
                                            return true;
                                        }

                                    }
                                )
                        })

                    //Spice Jet Passenger Name(First + Last) must be distinct 
                    if (airline_code === 'SG') {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["first_name_" + typeArray[j] + "_" + i]: Yup.string()
                                    .required('First Name is required'),
                                ["last_name_" + typeArray[j] + "_" + i]: Yup.string()
                                    // .notOneOf([Yup.ref("first_name_"+typeArray[j]+"_"+i),null],"First and last name can't same.")
                                    .required('Last Name is required'),
                            })
                    }
                    //For FlyDubai, Passport is mandatory for all types of Passenger.
                    if (isDomestic === 'false' && airline_code === 'FZ') {
                        passportValidation = {
                            adult: true,
                            child: true,
                            infant: true
                        };
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .test(["passportExpiry_" + typeArray[j] + "_" + i], 'Your passpost is expired.',
                                        function (value) {
                                            let isDomestic = self.props.searchQuery && self.props.searchQuery.is_domestic;
                                            if (isDomestic === 'true')
                                                return true;
                                            else {
                                                let triplength = self.props.searchQuery.segments.length;
                                                let tripDate = self.props.searchQuery.segments[triplength - 1].preferred_arrival_time;
                                                if (newDateFunction(tripDate) > newDateFunction(value)) {
                                                    return false;
                                                }
                                                else {
                                                    return true;
                                                }
                                            }
                                        }
                                    ),
                                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                                    .test(["passport_" + typeArray[j] + "_" + i], 'Passport is required',
                                        function (value) {
                                            let isDomestic = self.props.searchQuery && self.props.searchQuery.is_domestic;
                                            if (isDomestic === 'true')
                                                return true;
                                            else {
                                                if (!value)
                                                    return false
                                                else
                                                    return true;
                                            }
                                        }
                                    )
                            })
                    }

                    //If Source is SpiceJet and Passenger is Adult then DOB is mandatory for international flight
                    if (typeArray[j] === 'adult' && (airline_code === 'SG' || airline_code === 'G8' || airline_code === '6E') && isDomestic === 'false') {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .required('Date of birth is required.')
                            })
                    }

                    //If Source is AirAsia and Passenger is Adult then DOB is mandatory 
                    if (typeArray[j] === 'adult' && (airline_code === 'IX' || airline_code === 'G9' || airline_code === 'I5' || airline_code === 'FD' || airline_code === 'AK' || airline_code === 'D7' || airline_code === 'QZ' || airline_code === 'QZ2' || airline_code === 'YY')) {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .required('Date of birth is required.')
                            })
                    }

                    if (isDomestic === 'false' && (airline_code === 'IX' || airline_code === 'G9' || airline_code === 'I5' || airline_code === 'FD' || airline_code === 'AK' || airline_code === 'D7' || airline_code === 'QZ' || airline_code === 'QZ2' || airline_code === 'YY')) {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .test(["passportExpiry_" + typeArray[j] + "_" + i], 'Your passpost is expired.',
                                        function (value) {
                                            return true;
                                        }
                                    ),
                                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                                    .test(["passport_" + typeArray[j] + "_" + i], 'Passport is required',
                                        function (value) {
                                            return true;
                                        }
                                    )
                            })
                    }

                    if (typeArray[j] === 'adult' && (airline_code === 'IX' || airline_code === 'G9')) {
                        validationSchema = Object.assign(validationSchema,
                            {
                                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                                    .test(["passportExpiry_" + typeArray[j] + "_" + i], 'Your passpost is expired.',
                                        function (value) {
                                            return true;
                                        }
                                    ),
                                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                                    .test(["passport_" + typeArray[j] + "_" + i], 'Passport is required',
                                        function (value) {
                                            return true;
                                        }
                                    )
                            })
                    }

                }

            }

            //Set dynamic validation for passengers detail
            validationSchema = Object.assign(validationSchema,
                {
                    country: Yup.string()
                        .required('Please select country'),
                    addressLine1: Yup.string()
                        .required('Please enter address'),
                    mobile_no: Yup.string()
                        .required("Please enter phone number")
                        .test('test-name', 'Please enter valid mobile number',
                            function (value) {
                                var numberPattern = /^[+]?[(]?[0-9]{3}[)]?[-\\.]?[0-9]{3}[-\\.]?[0-9]{4,6}$/;
                                return numberPattern.test(value);
                            }),
                    email: Yup.string()
                        .required('Please enter email address')
                        .email('Please enter a valid email address'),
                })
            this.props.locations.response && this.props.locations.response.country && this.props.locations.response.country.map((item, key) => {
                if (item.country === 'India')
                    country_id = item.id;
                return true;
            })
            //Set common intital values
            if(this.props.location.state.searchType !== "round-trip-complete"){
                initialValues["email"] = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.email_address : '';
                initialValues["addressLine1"] = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.office_address : '';
                initialValues["addressLine2"] = "";
                initialValues["country"] = country_id !== '' ? country_id : '';
                initialValues["city"] = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.city_id : '';
                initialValues["country_name"] = "India";
                initialValues["city_name"] = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.city : '';
                initialValues["mobile_no"] = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.mobile_number : '';
                initialValues["submit"] = null;
                initialValues["country_code"] = "IN";
            }
            var validationSchemas = Yup.object().shape(
                validationSchema
            );
        }

        return (
            <React.Fragment>
                <FlightSearchHeaderComponent {...this.props} pageType="booking" />
                <Formik
                    initialValues={initialValues} //Set initial values of fields to blank
                    validationSchema={validationSchemas}
                    onSubmit={(values) => this.addPassengersValues(values)}
                    render={formikProps =>
                        this.props.type
                            ?
                            <VendorBookingConfirmComponent {...formikProps} {...this.props}
                                initialState={this.initialState}
                                fareQuoteData={this.fareQuoteData}
                                bookingError={this.bookingError} />
                            :
                            <VendorFlightBookTicketComponent {...formikProps} {...this.props}
                                switchPage={this.switchPage}
                                addPassengerSuccess={this.state.addPassengerSuccess}
                                passengers={this.state.passengers}
                                passportValidation={passportValidation}
                                country_id={country_id}
                                handleHold={this.handleHold} />
                    }
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        searchQuery: state.flightSearchReducer.searchQuery,
        addPassengerData: state.shared.addPassenger,
        customerList: state.shared.customerList,
        paxTitles: state.flightBookTicketReducer.paxTitles,
        locations: state.register.locations,
        productResponse: state.shared.productResponse, //get products for header
        accountResponse: state.myAccount,//my account details
        allAirlinesData: state.flightSearchReducer.allAirlinesData,//Get types of airlines
        agentProductAccess: state.shared.agentProductAccess,
        holdBookingData: state.flightBookTicketReducer.holdBookingData,//get response from hold booking
        outboundPaxInfo     : state.flightBookTicketReducer.outboundPaxInfo,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        flightSearchSaveQuery: (formData) => dispatch(flightSearchSaveQuery(formData)),
        addPassenger: (data, successcb, errorcb) => dispatch(addPassenger(data, successcb, errorcb)), //add passenger in customer list 
        getCustomerList: () => dispatch(getCustomerList()),//get customer list
        getPaxTitle: () => dispatch(getPaxTitle()),//get pax titles
        getlocation: () => dispatch(getlocation()),//get country ,state and city
        getAllAirlines: (credentials) => dispatch(getAllAirlines(credentials)),
        myAccountAction: () => dispatch(myAccountAction()),
        holdBooking: (credentials, successCb, errorcb) => dispatch(holdBooking(credentials, successCb, errorcb)),
        saveFlightOutboundPaxInfo:(data,bookig_id,outboundPub) => dispatch(saveFlightOutboundPaxInfo(data,bookig_id,outboundPub)),//Save outbound booking pax details for showing pax details prefilled in inbound details
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookTicketContainer);
