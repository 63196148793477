import { apiHotelSearchResults, apiGetHotelRoomDetails,apiGetHotelDetails, apiSendItinerariesEmail,apiTripJackHotelSearchResults,apiGetTripJackHotelDetails,apiGetTripJackHotelRoomDetails,apiGetTripJackCancelPolicy } from '../../api/hotel';

export const HOTEL_SEARCH_START = 'HOTEL_SEARCH_START';
export const hotelSearchStart = () => {
    return {
        type: HOTEL_SEARCH_START
    };
};

export const HOTEL_SEARCH_OK = 'HOTEL_SEARCH_OK';
export const hotelSearchOk = (output) => {
    return {
        type: HOTEL_SEARCH_OK,
        output: output
    };
};

export const HOTEL_SEARCH_API_TYPE = 'HOTEL_SEARCH_API_TYPE';
export const hotel_search_api_type = (payload) => {
    return {
        type: HOTEL_SEARCH_API_TYPE,
        api_type: payload
    };
};

export const getHotelSearchResults = (payload, callback) => {
    return (dispatch, getState) => {
        dispatch(hotelSearchStart());
        return apiHotelSearchResults(payload)
            .then((response) => {
                dispatch(hotelSearchOk(response));
                response && callback && callback(response.results);
            });
    }
};

// ======================= get hotel room details ======================
export const HOTEL_ROOM_DETAIL_START = 'HOTEL_ROOM_DETAIL_START';
export const hotelRoomDetailStart = () => {
    return {
        type: HOTEL_ROOM_DETAIL_START
    };
};

export const HOTEL_ROOM_DETAIL_OK = 'HOTEL_ROOM_DETAIL_OK';
export const hotelRoomDetailOk = (output) => {
    return {
        type: HOTEL_ROOM_DETAIL_OK,
        output: output
    };
};

export const HOTEL_ROOM_DETAIL_ERR = 'HOTEL_ROOM_DETAIL_ERR';
export const hotelRoomDetailErr = (status, message, errors) => {
    return {
        type: HOTEL_ROOM_DETAIL_ERR,
        output: message
    };
};

export const getHotelRoomDetails = (data) => {
    return (dispatch, getState) => {
       
        dispatch(hotelRoomDetailStart());

        return apiGetHotelRoomDetails(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelRoomDetailOk(payload));

                } else {
                    dispatch(hotelRoomDetailErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}


// ======================= get hotel details ======================
export const HOTEL_DETAIL_START = 'HOTEL_DETAIL_START';
export const hotelDetailStart = () => {
    return {
        type: HOTEL_DETAIL_START
    };
};

export const HOTEL_DETAIL_OK = 'HOTEL_DETAIL_OK';
export const hotelDetailOk = (output) => {
    return {
        type: HOTEL_DETAIL_OK,
        output: output
    };
};

export const HOTEL_DETAIL_ERR = 'HOTEL_DETAIL_ERR';
export const hotelDetailErr = (status, message, errors) => {
    return {
        type: HOTEL_DETAIL_ERR,
        output: message
    };
};

export const getHotelDetails = (trace_id, result_index, hotel_code) => {
    return (dispatch, getState) => {
        dispatch(hotelDetailStart());
        return apiGetHotelDetails(trace_id, result_index, hotel_code)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelDetailOk(payload));

                } else {
                    dispatch(hotelDetailErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}
// ======================= update hotel advance search ======================
export const HOTEL_ADVANCE_SEARCH_UPDATED = 'HOTEL_ADVANCE_SEARCH_UPDATED';
export const filterhotelResults = (filters) => {
    return {
        type: HOTEL_ADVANCE_SEARCH_UPDATED,
        advanceFilters: filters
    };
};

//Send email of multiple flights to user
export const ITINERARIES_EMAIL_START = 'ITINERARIES_EMAIL_START';
export const itinerariesEmailStart = () => {
    return {
        type: ITINERARIES_EMAIL_START
    };
};

export const ITINERARIES_EMAIL_OK = 'ITINERARIES_EMAIL_OK';
export const itinerariesEmailSuccess = (output) => {
    return {
        type: ITINERARIES_EMAIL_OK,
        output: output
    };
};

export const ITINERARIES_EMAIL_ERR = 'ITINERARIES_EMAIL_ERR';
export const itinerariesEmailError = (output) => {
    return {
        type: ITINERARIES_EMAIL_ERR,
        output: output
    };
};

export const sendItinerariesEmail = (payload,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(itinerariesEmailStart());
        return apiSendItinerariesEmail(payload)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(itinerariesEmailSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(itinerariesEmailError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

// =======================tripjack search hotel start ======================
export const HOTEL_TRIPJACK_SEARCH_START = 'HOTEL_TRIPJACK_SEARCH_START';
export const hotelTripjackSearchStart = () => {
    return {
        type: HOTEL_TRIPJACK_SEARCH_START
    };
};

export const HOTEL_TRIPJACK_SEARCH_OK = 'HOTEL_TRIPJACK_SEARCH_OK';
export const hotelTripjackSearchOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_SEARCH_OK,
        output: output
    };
};

export const getTripjackHotelSearchResults = (payload, callback) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackSearchStart());
        return apiTripJackHotelSearchResults(payload)
            .then((response) => {
                dispatch(hotelTripjackSearchOk(response));
                //response && callback && callback(response.results);
            });
    }
};
// =======================tripjack search hotel end ======================

// ======================= get tripjack hotel details start ======================
export const HOTEL_TRIPJACK_DETAIL_START = 'HOTEL_TRIPJACK_DETAIL_START';
export const tripjackHotelDetailStart = () => {
    return {
        type: HOTEL_TRIPJACK_DETAIL_START
    };
};

export const HOTEL_TRIPJACK_DETAIL_OK = 'HOTEL_TRIPJACK_DETAIL_OK';
export const tripjackHotelDetailOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_DETAIL_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_DETAIL_ERR = 'HOTEL_TRIPJACK_DETAIL_ERR';
export const tripjackHotelDetailErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_DETAIL_ERR,
        output: message
    };
};

export const getTripjackHotelDetails = (trace_id, result_index, hotel_code) => {
    return (dispatch, getState) => {
        dispatch(tripjackHotelDetailStart());
        return apiGetTripJackHotelDetails(trace_id, result_index, hotel_code)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(tripjackHotelDetailOk(payload));
                } else {
                    dispatch(tripjackHotelDetailErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}
// ======================= get tripjack hotel details end ======================

// ======================= get tripjack hotel room details start ======================
export const HOTEL_TRIPJACK_ROOM_DETAIL_START = 'HOTEL_TRIPJACK_ROOM_DETAIL_START';
export const hotelTripjackRoomDetailStart = () => {
    return {
        type: HOTEL_TRIPJACK_ROOM_DETAIL_START
    };
};

export const HOTEL_TRIPJACK_ROOM_DETAIL_OK = 'HOTEL_TRIPJACK_ROOM_DETAIL_OK';
export const hotelTripjackRoomDetailOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_ROOM_DETAIL_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_ROOM_DETAIL_ERR = 'HOTEL_TRIPJACK_ROOM_DETAIL_ERR';
export const hotelTripjackRoomDetailErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_ROOM_DETAIL_ERR,
        output: message
    };
};

export const getTripjackHotelRoomDetails = (data) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackRoomDetailStart());
        return apiGetTripJackHotelRoomDetails(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelTripjackRoomDetailOk(payload));

                } else {
                    dispatch(hotelTripjackRoomDetailErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}
// =================================== get tripjack hotel room details end =======================================

export const HOTEL_TRIPJACK_CANCEL_START = 'HOTEL_TRIPJACK_CANCEL_START';
export const hotelTripjackCancelStart = () => {
    return {
        type: HOTEL_TRIPJACK_CANCEL_START
    };
};

export const HOTEL_TRIPJACK_CANCEL_OK = 'HOTEL_TRIPJACK_CANCEL_OK';
export const hotelTripjackCancelOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_CANCEL_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_CANCEL_ERR = 'HOTEL_TRIPJACK_CANCEL_ERR';
export const hotelTripjackCancelErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_CANCEL_ERR,
        output: message
    };
};

export const getTripjackCancelPolicy = (data) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackCancelStart());
        return apiGetTripJackCancelPolicy(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelTripjackCancelOk(payload));

                } else {
                    dispatch(hotelTripjackCancelErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}