import React from 'react';
import UserSubscribe from '../../containers/shared/UserSubscribeContainer';
import Link from "react-router-dom/es/Link";

class Footer extends React.Component {
    
   render() {
        let { settingResponse , pagesResponse } = this.props;
        return (
            <footer className="footer">
                <div className="question-block">
                    <div className="row">
                        <div className="container">
                            <div className="col-box"><h3>{pagesResponse.response && pagesResponse.response.pages['subscription'].page_title}</h3></div>
                            <div className="col-box contact-col">
                                <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['subscription'].page_description }} />
                                <span className="contact-no"><i className="fa fa-mobile" aria-hidden="true"></i> <small>{settingResponse.response && settingResponse.response.mobile_number}, {settingResponse.response && settingResponse.response.phone_number} </small> </span>
                            </div>
                                <UserSubscribe />
                                <div className="col-box mail-col">
                                    <a href={settingResponse.response && "mailto:" +settingResponse.response.global_email}><i className="fa fa-envelope" aria-hidden="true"></i> {settingResponse.response && settingResponse.response.global_email}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="inner-footer">
                            <div className="top-footer">
                                <div className="address-block"> <i className="fa fa-map-marker" aria-hidden="true"></i> {settingResponse.response && settingResponse.response.address}   </div>
                                <ul className="footer-link">
                                    { /* eslint-disable-next-line */}
                                    <li><Link to="about-us">About us</Link></li>
                                    { /* eslint-disable-next-line */}
                                    <li><Link to="contact-us">Contact us</Link></li>
                                </ul>
                            </div>
                            <div className="bottom-footer">
                                <span className="copyright">{settingResponse.response && settingResponse.response.footer_text}</span>
                                <div className="pull-right">
                                    <span className="condition-link">
                                    { /* eslint-disable-next-line */}
                                    <a href="/terms-and-conditions">Terms & Conditions</a></span>
                                    <span className="follow-us">Follow Us On</span>
                                    <ul className="social-link">
                                        <li className="twitter-icon">
                                        { /* eslint-disable-next-line */}
                                        <a href={settingResponse.response && settingResponse.response.twitter_link}><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li className="facebook-icon">
                                        { /* eslint-disable-next-line */}
                                        <a href={settingResponse.response && 'https://'+settingResponse.response.facebook_link}><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                )
            }

        }
export default Footer;