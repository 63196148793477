import {
    GET_DETAIL_VERFICATION_START,
    GET_DETAIL_VERFICATION_OK,
    GET_DETAIL_VERFICATION_ERR,
    RAILWAY_PAYMENT_START,
    RAILWAY_PAYMENT_OK,
    RAILWAY_PAYMENT_ERR,
    GET_TRAIN_BOOKINGS_START,
    GET_TRAIN_BOOKINGS_OK,
    GET_TRAIN_BOOKINGS_ERR,
    GET_TRAIN_BOOKING_DETAIL_START,
    GET_TRAIN_BOOKING_DETAIL_OK,
    GET_TRAIN_BOOKING_DETAIL_ERR,
    EMAIL_TRAIN_INVOICE_START,
    EMAIL_TRAIN_INVOICE_OK,
    EMAIL_TRAIN_INVOICE_ERR,
    GET_TRAIN_CANCEL_DETAILS_START,
    GET_TRAIN_CANCEL_DETAILS_OK,
    GET_TRAIN_CANCEL_DETAILS_ERR,
    EMAIL_TRAIN_CREDIT_NOTE_START,
    EMAIL_TRAIN_CREDIT_NOTE_OK,
    EMAIL_TRAIN_CREDIT_NOTE_ERR
} from '../../actions/railway/railwayAction';

export const initialState = {
    detailVerification: {
        error: null,
        loading: false,
        response: null
    },
    railwayPaymentDetail: {
        error: null,
        loading: false,
        response: null
    },
    myTrainBookings: {
        error: null,
        loading: false,
        response: null
    },
    myTrainBookingDetails: {
        error: null,
        loading: false,
        response: null
    },
    trainEmailInvoice: {
        error: null,
        loading: false,
        response: null
    },
    trainCancelDetails: {
        error: null,
        loading: false,
        response: null
    },
    trainEmailCreditNote: {
        error: null,
        loading: false,
        response: null
    },
};

export function railwayReducer(state = initialState, action) {
    switch (action.type) {
        //Get detail verfication
        case GET_DETAIL_VERFICATION_START:
            return {
                ...state,
                detailVerification: {
                    error: '',
                    loading: true
                }
            }
        case GET_DETAIL_VERFICATION_OK:
            return {
                ...state,
                detailVerification: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_DETAIL_VERFICATION_ERR:
            return {
                ...state,
                detailVerification: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        //Railway payment
        case RAILWAY_PAYMENT_START:
            return {
                ...state,
                railwayPaymentDetail: {
                    error: '',
                    loading: true
                }
            }
        case RAILWAY_PAYMENT_OK:
            return {
                ...state,
                railwayPaymentDetail: {
                    error: '',
                    loading: true,
                    response: action.response,
                }
            };

        case RAILWAY_PAYMENT_ERR:
            return {
                ...state,
                railwayPaymentDetail: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }

        //Get Railway Bookings 
        case GET_TRAIN_BOOKINGS_START:
            return {
                ...state,
                myTrainBookings: {
                    error: '',
                    loading: true
                }
            }
        case GET_TRAIN_BOOKINGS_OK:
            return {
                ...state,
                myTrainBookings: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_TRAIN_BOOKINGS_ERR:
            return {
                ...state,
                myTrainBookings: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        //Get Railway Bookings 
        case GET_TRAIN_BOOKING_DETAIL_START:
            return {
                ...state,
                myTrainBookingDetails: {
                    error: '',
                    loading: true
                }
            }
        case GET_TRAIN_BOOKING_DETAIL_OK:
            return {
                ...state,
                myTrainBookingDetails: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_TRAIN_BOOKING_DETAIL_ERR:
            return {
                ...state,
                myTrainBookingDetails: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        //Get response of Email Train Invoice API
        case EMAIL_TRAIN_INVOICE_START:
            return {
                ...state,
                trainEmailInvoice: {
                    error: '',
                    loading: true
                }
            }
        case EMAIL_TRAIN_INVOICE_OK:
            return {
                ...state,
                trainEmailInvoice: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case EMAIL_TRAIN_INVOICE_ERR:
            return {
                ...state,
                trainEmailInvoice: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        //Get response of Train credit note
        case GET_TRAIN_CANCEL_DETAILS_START:
            return {
                ...state,
                trainCancelDetails: {
                    error: '',
                    loading: true
                }
            }
        case GET_TRAIN_CANCEL_DETAILS_OK:
            return {
                ...state,
                trainCancelDetails: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_TRAIN_CANCEL_DETAILS_ERR:
            return {
                ...state,
                trainCancelDetails: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        //Get response of Email Train credit note
        case EMAIL_TRAIN_CREDIT_NOTE_START:
            return {
                ...state,
                trainEmailCreditNote: {
                    error: '',
                    loading: true
                }
            }
        case EMAIL_TRAIN_CREDIT_NOTE_OK:
            return {
                ...state,
                trainEmailCreditNote: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case EMAIL_TRAIN_CREDIT_NOTE_ERR:
            return {
                ...state,
                trainEmailCreditNote: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        default:
            return state;
    }
}