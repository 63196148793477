import React, { Component } from 'react';
import { Formik } from "formik";

class HotelTypesComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            valueError:null,
            validationFlag:false,
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    
    renderForm = (formikProps) =>{
        const {handleChange,handleSubmit} = formikProps;
        const {getHotelMarkupResponse,submitHotelMarkupResponse } = this.props;
        var errorMsg,successMsg;
        if(this.props.submitHotelMarkupMarkupMsg === 'success')
        {
            errorMsg = '';
            successMsg = 'Mark-Up Tool Request has been Updated successfully.';
        }
        else if(this.props.submitHotelMarkupMarkupMsg === 'error')
        {
            errorMsg = 'Mark-Up Tool Request updation failed.';
            successMsg = '';
        }
    
        return(
            <div>
                {successMsg && <div className="alert alert-success" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
                {errorMsg && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i></div>}
                {this.props.validationFlags && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> <strong><i className="fa fa-exclamation-triangle"></i>Please enter a valid number</strong><i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
                {/* {accountResponse.getMarkupToolResponse.loading === true?
                    <div className="loader relative-loader" style={{"display":"block"}}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
                : */}
                <form>
                    <div className="mark-up-outer">
                        <div className="mark-up-form">
                            <div className="form-row"> 
                                <div className="col-sm-12 col-lg-6">
                                    <div className="mark-up-bx">
                                        <div className="mark-bx-outer">
                                            {/* <figure><img src="" alt="" /></figure> */}
                                            <div className="markup-field">
                                                <div className="cols">
                                                    <div className="custom-label">
                                                    <label>Fixed</label>
                                                    </div>
                                                </div>
                                                <div className="cols">
                                                    <input className="form-control custom-filed" name="domestic_markup_value" type="text" onChange={handleChange} defaultValue={getHotelMarkupResponse && getHotelMarkupResponse.response && getHotelMarkupResponse.response[0] && getHotelMarkupResponse.response[0].markup_value} />
                                                </div>
                                            </div>
                                            <span>Domestic</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="mark-up-bx">
                                        <div className="mark-bx-outer">
                                            {/* <figure><img src="" alt="" /></figure> */}
                                            <div className="markup-field">
                                                <div className="cols">
                                                    <div className="custom-label">
                                                        <label>Fixed</label>
                                                    </div>
                                                </div>
                                                <div className="cols">
                                                    <input className="form-control custom-filed" name="international_markup_value" type="text" onChange={handleChange} defaultValue={getHotelMarkupResponse && getHotelMarkupResponse.response && getHotelMarkupResponse.response[1] && getHotelMarkupResponse.response[1].markup_value}/>
                                                </div>
                                            </div>
                                            <span>International</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-btn-block text-center">
                                <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}> {submitHotelMarkupResponse.loading === false?"Update Mark-Up":"Updating..."}</button>
                                <button type="reset" className="btn btn-grey">Reset</button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* } */}
            </div>   
        ) 
    }


    render() {
        
        const initialValues = {
            domestic_markup_value         : this.props.getHotelMarkupResponse && this.props.getHotelMarkupResponse.response && this.props.getHotelMarkupResponse.response[0] && this.props.getHotelMarkupResponse.response[0].markup_value,
            international_markup_value    : this.props.getHotelMarkupResponse && this.props.getHotelMarkupResponse.response && this.props.getHotelMarkupResponse.response[1] && this.props.getHotelMarkupResponse.response[1].markup_value,
        };
        return (
        <div className="tabs-container-detail">
            <div className="mark-up-info">
                <div className="tabs-top">
                    <div className="tabs-title">
                        <h2><span>HOTEL MARK-UP TOOL</span></h2>
                        {/* <div className="pull-right"> <Link to="international-markup-tool"className="sm-btn blue-sm-btn">Set International Carries</Link> </div> */}
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values) => {this.props.updateHotelMarkupTool(values)}}
                    render={this.renderForm}
                />
            
            </div>
        </div>
        )
    }
}

export default (HotelTypesComponent)