import React, { Component } from "react";

class CommonError extends Component {
  handleBackToHome = () => {
   window.location.href = window.location.origin+'/flight-search';
  };

  render() {
    return (
      <div className="mid-wrapper error-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="error-content">
                <div className="error-outer">
                  <figure>
                    <img src="assets/images/wrong-icon.svg" alt="" />
                  </figure>
                  <h2>Oooops!</h2>
                  <span>Something Went Wrong</span>
                  <p>
                    Please try exiting and returning or check your internet
                    connection to see if it works. Otherwise please come back
                    shortly.
                  </p>
                  <button onClick={this.handleBackToHome} className="btn-back-to-home">
                    Back to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommonError;
