import React, {Component} from 'react';
import Link from "react-router-dom/es/Link";
import { Field } from 'formik';

class CommunicationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader       : false,
            selectedFile : null,
            addressFlage:false,
            panFlage:false,
        };
    };

    changeCountry = (e,setFieldValue) => {
        setFieldValue("country",e.target.value);
        setFieldValue("state","");
    }
    
    render() {
        const {values, errors, touched, handleBlur, handleChange ,handleSubmit,setFieldValue,setFieldTouched, agentRegistration} = this.props;
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Office Address<span className="red-error">*</span></label>
                        <div className="control-group">
                            <textarea className="form-control" name="office_address" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.office_address && touched.office_address && <span className="error-msg">{errors.office_address}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Country<span className="red-error">*</span></label>
                        <div className="control-group">
                            <div className="select-custom">
                                <select className="form-control" name="country" onChange={(e) => this.changeCountry(e,setFieldValue)} onBlur={handleBlur}>
                                    <option value="">Select Country</option>
                                    {
                                        this.props.allCities && this.props.allCities.country.map((item,key) => {
                                                return (
                                                    <option key={key} value={item.id}>{item.country}</option>
                                                )
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {errors.country && touched.country && <span className="error-msg">{errors.country}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">State<span className="red-error">*</span></label>
                        <div className="control-group">
                            <div className="select-custom">
                                <select className="form-control" name="state" onChange={handleChange} onBlur={handleBlur}>
                                    <option value="">Select State</option>
                                    {
                                        this.props.allCities && values.country && this.props.allCities.country_state[values.country].map((item,key) => {
                                                return (
                                                    <option key={key} value={item.id}>{item.state}</option>
                                                )
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {errors.state && touched.state && <span className="error-msg">{errors.state}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">City/District<span className="red-error">*</span></label>
                        <div className="control-group">
                            <div className="select-custom">
                                <select className="form-control" name="city" onChange={handleChange} onBlur={handleBlur}>
                                    <option>Select City/District</option>
                                    {
                                        this.props.allCities && values.state && this.props.allCities.city[values.state].map((item,key) => {
                                                return (
                                                    <option key={key} value={item.id}>{item.city}</option>
                                                )
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {errors.city && touched.city && <span className="error-msg">{errors.city}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Phone Number</label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 01412222222)" name="phone_number" onBlur={handleBlur} onChange={handleChange}/>
                        </div>
                        {errors.phone_number && touched.phone_number && <span className="error-msg">{errors.phone_number}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Mobile Number<span className="red-error"></span></label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 9999999999)" readOnly name="mobile_number" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.mobile_number && touched.mobile_number && <span className="error-msg">{errors.mobile_number}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Alternative Mobile Number</label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 9999999999)" name="alternative_mobile_number" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.alternative_mobile_number && touched.alternative_mobile_number && <span className="error-msg">{errors.alternative_mobile_number}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Email Address<span className="red-error">*</span></label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. abc@gmail.com)" name="email_address" onBlur={handleBlur} onChange={handleChange}  />
                        </div>
                        {errors.email_address && touched.email_address && <span className="error-msg">{errors.email_address}</span>}
                    </div>
                </div>      
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">PAN Number<span className="red-error">*</span></label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 123456)" name="pan_number" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.pan_number && touched.pan_number && <span className="error-msg">{errors.pan_number}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block" >
                        <label className="control-label">PAN<span className="red-error">*</span></label>
                        <div className="input-group">
                            <div className="file-upload-filed">
                                <div className="file-btn">Upload File
                                    <input type="file" id="uploadPan" name="pan_card" onChange={(event) => {
                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                        {
                                            setFieldTouched('pan_file');
                                            setFieldValue("pan_file", event.currentTarget.files[0]);
                                            this.props.uploadFile(this,'pan_card',event.currentTarget.files[0]);
                                            this.setState({panFlage:true});
                                        }
                                        else{
                                            this.setState({panFlage:false});
                                            setFieldValue("pan_file", "");
                                            setFieldValue("pan_card", "");
                                         }
                                         
                                    }}/>
                                </div>
                                <div className="panFileName">{this.state.panFlage && values.pan_file.name}</div>
                            </div>
                        </div>
                        {errors.pan_file && touched.pan_file && <span className="error-msg">{errors.pan_file}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Address Proof Type<span className="red-error">*</span></label>
                        <div className="control-group">
                            <div className="select-custom">
                                <select className="form-control" name="address_proof_type" onChange={handleChange} onBlur={handleBlur}>
                                    <option>Select Address Proof Type</option>
                                    {
                                        this.props.addressProof.response && this.props.addressProof.response.address_proofs &&
                                        this.props.addressProof.response.address_proofs.map((item,key) => {
                                                return (
                                                    <option key={key} value={item.type_id}>{item.address_proof_type}</option>
                                                )
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {errors.address_proof_type && touched.address_proof_type && <span className="error-msg">{errors.address_proof_type}</span>}
                    </div>
                </div>

               <div className="col-md-6" style={{display:'flex',paddingLeft:0}}>
                    <div className="col-md-6">
                    <div className="form-block" >
                        <label className="control-label">Address Proof Front<span className="red-error">*</span></label>
                        <div className="input-group">
                            <div className="file-upload-filed">
                                <div className="file-btn">Upload File
                                    <input type="file" id="aadharFront" name="aadhar_front" onChange={(event) => {
                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                        {
                                            setFieldTouched('aadhar_front_file');
                                            setFieldValue("aadhar_front_file", event.currentTarget.files[0]);
                                            this.props.uploadFile(this,'aadhar_front',event.currentTarget.files[0]);
                                            this.setState({addressFlage:true});
                                        }
                                        else{
                                            this.setState({addressFlage:false});
                                            setFieldValue("aadhar_front_file", "");
                                            setFieldValue("aadhar_front", "");
                                         }
                                    }}/>
                                </div>
                                <div className="addressProofFile">{this.state.addressFlage && values.aadhar_front_file.name}</div>
                            </div>
                        </div>
                        {errors.aadhar_front_file && touched.aadhar_front_file && <span className="error-msg">{errors.aadhar_front_file}</span>}
                    </div>
                    </div>
                    
                    <div className="col-md-6">
                    <div className="form-block" >
                        <label className="control-label">Address Proof Back<span className="red-error">*</span></label>
                        <div className="input-group">
                            <div className="file-upload-filed">
                                <div className="file-btn">Upload File
                                    <input type="file" id="aadharBack" name="aadhar_back" onChange={(event) => {
                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                        {
                                            setFieldTouched('aadhar_back_file');
                                            setFieldValue("aadhar_back_file", event.currentTarget.files[0]);
                                            this.props.uploadFile(this,'aadhar_back',event.currentTarget.files[0]);
                                            this.setState({addressFlage:true});
                                        }
                                        else{
                                            this.setState({addressFlage:false});
                                            setFieldValue("aadhar_back_file", "");
                                            setFieldValue("aadhar_back", "");
                                         }
                                    }}/>
                                </div>
                                <div className="addressProofFile">{this.state.addressFlage && values.aadhar_back_file.name}</div>
                            </div>
                        </div>
                        {errors.aadhar_back_file && touched.aadhar_back_file && <span className="error-msg">{errors.aadhar_back_file}</span>}
                    </div>
                </div>
                </div>

                

                <div className="col-md-6">
                    <div className="form-block" >
                        <label className="control-label">Office Adress Proof<span className="red-error">*</span></label>
                        <div className="input-group">
                            <div className="file-upload-filed">
                                <div className="file-btn">Upload File
                                    <input type="file" id="uploadAddressProof" name="address_proof" onChange={(event) => {
                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                        {
                                            setFieldTouched('address_proof_file');
                                            setFieldValue("address_proof_file", event.currentTarget.files[0]);
                                            this.props.uploadFile(this,'address_proof',event.currentTarget.files[0]);
                                            this.setState({addressFlage:true});
                                        }
                                        else{
                                            this.setState({addressFlage:false});
                                            setFieldValue("address_proof_file", "");
                                            setFieldValue("address_proof", "");
                                         }
                                    }}/>
                                </div>
                                <div className="addressProofFile">{this.state.addressFlage && values.address_proof_file.name}</div>
                            </div>
                        </div>
                        {errors.address_proof_file && touched.address_proof_file && <span className="error-msg">{errors.address_proof_file}</span>}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Pincode<span className="red-error">*</span></label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 304001)" name="pincode" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.pincode && touched.pincode && <span className="error-msg">{errors.pincode}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Website</label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. https://www.jaipurflight.com)" name="website" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.website && touched.website && <span className="error-msg">{errors.website}</span>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-block">
                        <div className="control-group">
                            <div className="check-term">
                                <small className="checkbox">
                                    <Field type="checkbox" name="termsAccepted" onBlur={handleBlur} onChange={handleChange} />
                                        <label></label>
                                </small>
                                <span>I have read &amp; accepted jaipurflight.com's <Link to='/terms-and-conditions'>Terms &amp; Conditions</Link></span></div>
                        </div>
                        {errors.termsAccepted && touched.termsAccepted && <span className="error-msg">{errors.termsAccepted}</span>}
                    </div>
                </div>

                <div className="form-btn-block">
				    { /* eslint-disable-next-line */} 
                    <a href="/" className="btn btn-dark" onClick={(e)=>{e.preventDefault();this.props.back()}}><i className="fa fa-arrow-left"></i><span>Back</span></a>
                    { /* eslint-disable-next-line */} 
                    <a href="/" onClick={(e)=>{e.preventDefault();handleSubmit()}} className={`btn btn-orange loading-btn 
                    ${(values.office_address && 
                    values.state && values.city && 
                    values.mobile_number && 
                    values.email_address && 
                    values.pan_card && 
                    values.termsAccepted && 
                    values.address_proof_type && 
                    values.address_proof && 
                    values.username && values.pincode) ? "" : "disabled" }`}><span>Continue</span>
                        {
                             this.props.agentRegistration.loading
                                ?
                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                <img src="/assets/images/flight-continue.svg" alt=""/></label></div>
                                :''
                        }
                    </a>
                    <Link to={'/login'} className="btn btn-grey">Cancel</Link>
                    {
                        agentRegistration.response && !agentRegistration.success? 
                        <div className="register-error-msg">
                            <span className="red-text">{agentRegistration.response.message}</span>
                        </div>
                        :
                        ''
                    }
                    {
                        !values.username &&
                        <div className="register-error-msg">
                            <span className="red-text">Please check user availibility.</span>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default CommunicationDetail;