import React, { Component } from 'react';
import BankDetails from '../../components/agent/AgentBankDetailComponent';
import { connect } from 'react-redux'
import { getBankDetail, myAccountAction } from '../../actions/agentAction';
import { logoutAction } from '../../actions/shared/sessionAction';

class AgentBankDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
        };
    }
    componentDidMount() {
		this.props.getBankDetail();
    }

    render() {
        return (
            <div>
                <BankDetails {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        agentBankDetails : state.myAccount.agentBankDetail,
        productResponse : state.shared.productResponse,
        pagesResponse   : state.shared.pagesResponse,
        agentProductAccess     : state.shared.agentProductAccess,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {

        getBankDetail   : () => dispatch(getBankDetail()),
        logoutAction    : () => dispatch(logoutAction()),
        myAccountAction : () => dispatch(myAccountAction())
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(AgentBankDetail)
