/* external dependencies */
import React from 'react';
import { connect } from 'react-redux';
import PrintHotelInvoiceComponent from '../../components/hotel/PrintHotelInvoiceComponent';
import { hotelInvoiceAction, emailHotelInvoice} from '../../actions/agentAction';

class PrintHotelInvoiceContainer extends React.Component {
    componentDidMount() {
        let data = localStorage.getItem('print-hotel-invoice');
        let booking_id = JSON.parse(data).booking_id;
        const booking = {
             "booking_id": parseInt(booking_id)
           // "booking_id": parseInt('68')
        };
        this.props.hotelInvoiceAction(booking);
    }

    render() {
        return (
            <PrintHotelInvoiceComponent {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        hotelInvoiceInfoResponse         : state.myAccount.hotelInvoiceInfoResponse,
        settingResponse                  : state.shared.settingResponse,
        accountResponse                  : state.myAccount,//my account details
        emailHotelInvoiceDetails         : state.myAccount.emailHotelInvoiceDetails,//Email invoice ticket
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        hotelInvoiceAction: (data) => dispatch(hotelInvoiceAction(data)),
        emailHotelInvoice: (data) => dispatch(emailHotelInvoice(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintHotelInvoiceContainer);