import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates'
import { Formik } from "formik";
import Pagination from '../../shared/Pagination';
import {dateFormat } from '../../../shared/commonHelper';
import { Alert } from 'reactstrap';

class ViewTopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: moment(),
            focused: false,
            createdAt: moment(),
            calendarFocused: false,
            dataValue: null
        }
    }
    onDateChange = (createdAt) => {
        this.setState(() => ({ createdAt }));
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }))
    }
    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    
    renderForm = (formikProps) => {
        const { focused, date, createdAt } = this.state;
        const { handleSubmit, setFieldValue } = formikProps;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return (
            <div className="search-report-form">
                {
                    diffDays > 31 ? <Alert color="danger">You can search top ups of maximum 31 days.</Alert> : ''
                }
                <div className="form-row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">START DATE:</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={this.state.createdAt}
                                        placeholder={"START DATE"}
                                        onDateChange={createdAt => {
                                            this.setState({ createdAt });
                                            setFieldValue("start_date", this.changeDateFormat(createdAt));
                                        }}
                                        focused={this.state.calendarFocused}
                                        onFocusChange={this.onFocusChange}
                                        id="start_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        isOutsideRange={() => false}
                                        inputIconPosition={"after"}
                                        hideKeyboardShortcutsPanel={true}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">END DATE:</label>
                            <div className="input-group input-addon single-calendar" >
                                <SingleDatePicker
                                    date={date}
                                    placeholder={"END DATE"}
                                    onDateChange={date => {
                                        this.setState({ date });
                                        setFieldValue("end_date", this.changeDateFormat(date));
                                    }}
                                    focused={focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    id="end_date"
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    isOutsideRange={() => false}
                                    inputIconPosition={"after"}
                                    hideKeyboardShortcutsPanel={true}
                                    reopenPickerOnClearDate={true}
                                    withPortal={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btn-block text-right">
                        {diffDays && diffDays > 31 ?
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                            :
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    initialValues = {
        start_date : moment().format('YYYY-MM-DD'),
        end_date :moment().format('YYYY-MM-DD'),
    }

    render() {
        const { date, createdAt } = this.state;
        var total_topups = this.props.allTopUpInfo.response && this.props.allTopUpInfo.response.total_topups ? this.props.allTopUpInfo.response.total_topups : 10
        const { perPageLimit,currentPage } = this.props;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn">
                            <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <div className="pull-right"> <Link to="topup-request" className="sm-btn blue-sm-btn">New Top Up Request</Link> </div>
                                <h2><i className="icon icon-currency2"></i><span>View All Top UPs</span></h2>
                            </div>
                        </div>

                        <div className="statement-outer">
                            <Formik
                                onSubmit={(values) => this.props.searchRequestForm(values)}
                                initialValues={this.initialValues}
                                render={this.renderForm}
                            />
                            <div className="balance-sheet-info">
                                <div className="balance-table">
                                    <div className="table-outer">
                                        <div className="table-container">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Requested on</th>
                                                        <th>Requested Amount</th>
                                                        <th>Total Deducted Amount</th>
                                                        <th className="tleft">Remarks</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.props.allTopUpInfo.loading === false) ?
                                                        (this.props.allTopUpInfo.response && this.props.allTopUpInfo.response.user_payments.length > 0 && diffDays <= 31 ? this.props.allTopUpInfo.response.user_payments.map((item, key) => {
                                                           var recordCount = perPageLimit*(currentPage-1);
                                                           var statusClass = 'status-txt status-success';
                                                           if(item.txn_status === 'Pending')
                                                           {
                                                                statusClass = 'status-txt status-pending';
                                                           }else if(item.txn_status === 'Rejected' || item.txn_status === 'Cancelled'){
                                                                statusClass = 'status-txt status-rejected';
                                                           }

                                                            return (
                                                                <tr key={key}>
                                                                    <td>{key + 1 + recordCount}</td>
                                                                    <td>{dateFormat(item.date_added,'DD MM YYYY HH:MM')}</td>
                                                                    <td>₹{item.amount ? item.amount.toFixed(2) : "0.00"}</td>
                                                                    <td>₹{item.easepay_net_amount_debit ? item.easepay_net_amount_debit.toFixed(2) : "0.00"}</td>
                                                                    <td className="tleft">{item.user_remark}</td>
                                                                    <td>
                                                                        <span className={statusClass}>{item.txn_status === 'Succeeded' ? 'Success' : item.txn_status}</span>
                                                                    </td>
                                                                </tr>
                                                                );
                                                            }) 
                                                            :<tr><td colSpan="9"><span className="no-records">THERE IS NO TOP UP REQUEST FOUND.</span></td></tr>
                                                        ):
                                                        <tr><td colSpan="10"><div className="loader relative-loader" style={{ "display": "block" }}><div className="loader-content"><div className="loader-ring blue-ring"><span></span> </div></div></div></td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    diffDays <= 31 ?
                                        <div className="table-footer">
                                            <Pagination
                                                totalRecord={total_topups}
                                                pageLimit={perPageLimit}
                                                activePage={currentPage} 
                                                getRecordsPerPage = {(value) => this.props.getRecordsPerPage(value)}/>
                                        </div>
                                    : ''
                                }        
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default (ViewTopUp)