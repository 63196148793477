import API from './index.js';
var { postApiCall, bufferDataFun } = require('./index.js');
//const apiUrl      = 'http://jfapidev.testappsnow.com:3001';
export const apiFlightSearchResults = (payload) => {
	// if(payload && payload.journey_type === "2" && payload.is_domestic === 'false'){
	// 	return bufferDataFun('/flight/search', payload,60000)
	// 		.then(response => {
	// 			return response;
	// 		})
	// 		.catch((error) => {
	// 			return error;
	// 		});
	// }else{
	// 	return postApiCall('/flight/search', payload, 60000)
	// 		.then(response => {
	// 			return response;
	// 		})
	// 		.catch((error) => {
	// 			return error;
	// 		});
	// }
	return bufferDataFun('/jfot/flight/search', payload,60000)
			.then(response => {
				return response;
			})
			.catch((error) => {
				return error;
			});

};

export const apiSendItinerariesEmail = (payload) => {
    return API.postResponseSession('/flight/search/itineraries-email', payload)
};

export const apiGetFlightFareRuleDetails = (payload) => {
	return postApiCall('/jfot/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetFlightPriceDetails = (payload) => {
	return API.postResponseSession('/jfot/flight/search/advance-search-price', payload) 

	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const getFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfot/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const addPassengerApi = (payload) => {
	return API.postResponseSession('/flight/search/add-passengers', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const getCustomersApi = () => {
	return API.getResponseSession('/flight/search/get-passengers')
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//get ssr details of flight
export const getSsrDetailApi = (payload) => {
	return API.postResponseSession('/jfot/flight/search/ssr',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//Get pax title
export const getPaxTitleApi = () => {
	return API.getResponseSession('/flight/search/pax-titles')
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}


//Hold booking
export const holdBookingApi = (payload) => {
	return API.postResponseSession('/jfot/flight/booking/hold',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//ticket booking
export const ticketBookingApi = (payload) => {
	return API.postResponseSession('/jfot/flight/booking/ticket',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}



//Get company GST details
export const getCompanyDetailApi = () => {
	return API.getResponseSession('/company-details')
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
//Get cell country codes
export const getCellCountryCodeApi = () => {
	return API.getResponseSession('/flight/search/cell-country-codes')
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
//========================================= Kafila APIs section ====================================================
export const apiKafilaFlightSearchResults = (payload) => {
    // return postApiCall('/kafila/v2/flight/search', payload,60000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	return bufferDataFun('/jfak/v2/flight/search', payload,60000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
};



export const getKafilaFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfak/v2/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

// kafila ssr api
export const getKafilaSsrDetailApi = (payload) => {
	return API.postResponseSession('/jfak/v2/flight/search/ssr',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetKafilaFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfak/v2/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const kafilaPnrCreateApi = (payload) => {
	return API.postResponseSession('/jfak/v2/flight/booking/pnr-create',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const kafilaPnrRetrieveApi = (payload) => {
	return API.postResponseSession('/jfak/v2/flight/booking/pnr-retrieve',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const kafilaPnrCheckApi = (payload) => {
	return API.postResponseSession('/jfak/v2/flight/booking/check-pnr',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const kafilaCanceReasonApi = () => {
	return API.getResponseSession('/jfak/v2/flight/booking/cancel-reason',)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//========================================= Trip Jack APIs section ====================================================
export const apiTripJackFlightSearchResults = async (payload) => {
	// if(payload && payload.journey_type === "2" && payload.is_domestic === 'false'){
	// 	return bufferDataFun('/trip-jack/flight/search', payload,60000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	// }else{
	// 	return postApiCall('/trip-jack/flight/search', payload,60000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	// }
	return bufferDataFun('/jfkt/flight/search', payload,60000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
};


export const apiGetTripJackFareRuleDetails = (payload) => {
	
	return postApiCall('/jfkt/flight/search/fare-rules-v2', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getTripJactFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfkt/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const getTripJackSeatInfoApi = (payload) => {
	return API.postResponseSession('/jfkt/flight/search/seat-info', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//Trip Jack ticket booking
export const tripJackTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfkt/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const tripJackBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfkt/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}
export const validateTripJackTicketApi=(payload)=>{
	return API.postResponseSession('/jfkt/flight/fare-validate',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}
export const tripJackBookConfirmApi=(payload)=>{
	return API.postResponseSession('/jfkt/flight/confirm-book',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

export const apiAirIQFlightSearchResults=(payload)=>{
	// return postApiCall('/oa/flight/search', payload,60000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	return bufferDataFun('/jfqa/flight/search', payload,60000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetAirIQFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfqa/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getAirIQFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfqa/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//Air IQ ticket booking
export const airIQTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfqa/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
export const airIQBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfqa/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

//========================================= Gofly APIs section Start====================================================
export const apiGoflyFlightSearchResults=(payload)=>{
	// return postApiCall('/gfy/flight/search', payload,120000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	return bufferDataFun('/jfyg/flight/search', payload,120000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetGoflyFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfyg/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getGoflyFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfyg/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//GoFly ticket booking
export const goflyTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfyg/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
export const goflyBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfyg/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

//========================================= Gofly APIs section End====================================================

//=========================================efly API's section Start===================================================
export const apieflyFlightSearchResults=(payload)=>{
	// return postApiCall('/efly/flight/search', payload,120000)
	// .then(response => {
	// 	return response.content;
	// })
	// .catch((error) => {
	// 	return error;
	// });
	return bufferDataFun('/jfye/flight/search', payload,120000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const geteflyFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfye/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGeteflyFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfye/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

//eFly ticket booking
export const eflyTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfye/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const eflyBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfye/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

//=========================================efly API's section End===================================================

//========================================= satkar APIs section Start====================================================
export const apiSatkarFlightSearchResults=(payload)=>{
	return bufferDataFun('/jfrs/flight/search', payload,30000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetSatkarFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfrs/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getSatkarFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfrs/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//Satkar ticket booking
export const satkarTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfrs/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
export const satkarBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfrs/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}
//========================================= satkar APIs section End====================================================

//========================================= riya APIs section Start====================================================
export const apiRiyaFlightSearchResults=(payload)=>{
	return bufferDataFun('/jfar/flight/search', payload,120000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetRiyaFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfar/flight/search/fare-rules', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getRiyaFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfar/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const getRiyaSeatInfoApi = (payload) => {
	return API.postResponseSession('/jfar/flight/search/seat-info', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//riya booking Api 
export const riyaTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfar/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const riyaBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfar/flight/booking-details',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

export const riyaHoldBookingApi = (payload) => {
	return API.postResponseSession('/jfar/flight/booking',payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

//confirm-booking
export const riyaBookConfirmApi=(payload)=>{
	return API.postResponseSession('/jfar/flight/confirm-book',payload)
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}

//========================================= APIs Fare Boutique section Start====================================================
export const apiFareBoutiqueFlightSearchResults=(payload)=>{
	return bufferDataFun('/jfef/flight/search', payload,120000)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const apiGetFareBoutiqueFlightFareRuleDetails = (payload) => {
	
	return postApiCall('/jfef/flight/search/fare-rules', payload) 
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
}

export const getFareBoutiqueFareQuoteApi = (payload) => {
	return API.postResponseSession('/jfef/flight/search/fare-quote', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}


//Fare Boutique booking Api
export const fareBoutiqueTicketBookingApi = (payload) => {
	return API.postResponseSession('/jfef/flight/booking', payload)
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}
export const fareBoutiqueBookDetailsApi=(payload)=>{
	return API.postResponseSession('/jfef/flight/booking-details',payload) 
	.then((response)=>{
		return response;
	}).catch((error)=>{
		return error;
	})
}