import React, { Component } from 'react';
import { Formik } from 'formik';
import * as yup from "yup";

class HotelCancellationComponent extends Component {
    constructor() {
        super();
        this.state = {
            showHotelConfirmationPopup: null,
            cancelMessage: null,
        }
    }

    initialValuesHotel = {
        hotel_remark: '',
        hotel_cancel_type: 4,
        termAndCondition: false
    };

    /* create our form validator */
    cancelHotelValidator = yup.object()
        .shape({
            hotel_remark: yup.string()
                .required('Remark is required.'),
            hotel_cancel_type: yup.string()
                .required('Remark is required.'),
            termAndCondition: yup.bool()
                .required("Required")
                .oneOf([true], "Must agree Terms and Conditions")
        });

    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.initialValuesHotel}
                    onSubmit={() => this.handleHotelConfirmationPopup(true)}
                    validationSchema={this.cancelHotelValidator}
                    render={this.renderHotelForm}
                />
            </React.Fragment>
        )
    }

    handleHotelConfirmationPopup = (event) => {
        this.setState({
            showHotelConfirmationPopup: event
        })
    }

    cancelHotelTicket = (values) => {
        this.setState({
            showHotelConfirmationPopup: false
        })
        const formValues = {
            "booking_id": this.props.cancelHotelPopupValue.booking_id,
            "remarks": values.hotel_remark
        };
        const tripjackFormValues = {
            "tripjack_bookingId" : this.props.cancelHotelPopupValue.tripjack_booking_id,
            "booking_id" : this.props.cancelHotelPopupValue.booking_id,
            "remarks" : values.hotel_remark
        }
        const successCb = () => {
            this.props.getMyHotelBookings(this.props.limitOffSet);
            this.setState({ cancelMessage: 'success' });
        }
        const errorCb = () => {
            this.setState({ cancelMessage: 'error' });
        }
        if(this.props.cancelHotelPopupValue.supplier_type !== 'tripjack'){
            this.props.cancelHotelBooking(formValues, successCb, errorCb);
        }else{
            this.props.cancelTripjackHotelBooking(tripjackFormValues, successCb, errorCb);
        }
    }

    redirectToTermsAndCondition(){
        window.open("/cancellation-terms-and-conditions", "_blank");
    }
    
    renderHotelForm = (formikProps) => {
        const { handleSubmit, handleChange, handleBlur, touched, errors, setFieldValue, values } = formikProps;
        const { showHotelConfirmationPopup } = this.state;
        const { cancelHotelPopupValue } = this.props;
        return (
            <React.Fragment>
                {/* Start Showing Hotel Cancel Popup */}
                {/* <div className="popup-crad change-request-popup show"> */}
                <div className={`popup-crad change-request-popup show`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>You are about to cancel the booking. Please review the details carefully before cancelling.</h2>
                                <div className="popup-close" onClick={() => {
                                    this.setState({
                                        cancelMessage: null
                                    }, () => this.props.showHotelCancelTicket())
                                }
                                }><span className="icon icon-cross"></span></div>
                            </div>
                            <div className="popup-scorll">
                                <div className="alert alert-success" style={{ display: this.state.cancelMessage === 'success' ? 'block' : 'none' }}>
                                    <strong><i className="fa fa-check-circle" />{this.props.cancelHotelBookingDetail.response
                                        && this.props.cancelHotelBookingDetail.response.message}</strong>
                                </div>
                                <div className="alert alert-danger" style={{ display: this.state.cancelMessage === 'error' ? 'block' : 'none' }}>
                                    <strong><i className="fa fa-exclamation-triangle" />Error in sending cancellation request</strong>
                                </div>

                                <div className="change-requrst-block">
                                    <div className="block">
                                        <h5>Hotel Booking</h5>
                                        <div>
                                            {
                                                cancelHotelPopupValue && cancelHotelPopupValue.pax_names.map((data, newKey) => {
                                                    return (
                                                        data.is_lead_pax === 1 &&
                                                        <span key={newKey}>Pax Name: {data.name}<br></br></span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>  <span>Hotel Name: {cancelHotelPopupValue && cancelHotelPopupValue.hotel_name}</span></div>
                                        <div>  <span className="red-text">Note: For amendments, please contact Jaipur Flight Operations.</span></div>
                                    </div>
                                    <div className="block select-block">
                                        <h5>Cancel Booking<span className="red-error">*</span></h5>
                                        <div className="select-custom">
                                            <select className={errors.hotel_cancel_type && touched.hotel_cancel_type ? 'form-control error-shadow' : 'form-control'}
                                                name="hotel_cancel_type" onChange={handleChange} onBlur={handleBlur} value={values.hotel_cancel_type}>
                                                <option value="cancelled">Cancel Hotel Booking</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="block remark-sector">
                                        <h5>Please enter remarks<span className="red-error">*</span></h5>
                                        <textarea className={errors.hotel_remark && touched.hotel_remark ? "error-shadow" : ''} name='hotel_remark' value={values.hotel_remark}
                                            onChange={(event) => {
                                                setFieldValue('hotel_remark', event.target.value);
                                            }} onBlur={handleBlur} />
                                    </div>
                                    <div className="block note-sector">
                                        <p className="red-text m-3">Auto cancellation enabled, please reverify the details before proceeding for cancellation</p>
                                        <div className="select-sector ml-3"> <small className="checkbox">
                                            <input
                                                name="termAndCondition"
                                                type="checkbox"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // disabled={values.cancel_type === 'cancel_ticket' || values.cancel_type === 'full_cancellation'}
                                            // checked={values.termAndCondition}
                                            />
                                            <label />
                                            { /* eslint-disable-next-line */}
                                        </small> <span>I Accept  <a href="/" onClick={(e)=>{e.preventDefault(); this.redirectToTermsAndCondition()}}>Terms and Conditions</a></span>
                                            {
                                                (errors.termAndCondition && touched.termAndCondition) &&
                                                <p className="red-text">{errors.termAndCondition}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-footer">
                                {/* <a href="javascript:void(0);" className="btn btn-orange loading-btn" onClick={handleSubmit}><span>Send Request</span></a> */}
                                { /* eslint-disable-next-line */}
                                <a href="/" className={`btn btn-orange loading-btn`}
                                    onClick={(e)=>{e.preventDefault(); handleSubmit()}}><span>Send Request</span>
                                    {
                                        this.props.cancelHotelBookingDetail.loading
                                            ?
                                            <div className="flight-animated"><label>
                                                <img src="/assets/images/btn-search_hotel.gif" alt="" /></label></div>
                                            : ''
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
                {/* END Showing Hotel Cancel Popup */}
                {/* Start hotel Popup for confirmation */}
                <div className={`popup-crad confirmation-popup ${showHotelConfirmationPopup ? 'show' : ''}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Cancellation Confirmation</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleHotelConfirmationPopup(false)} /></div>
                            </div>
                            <div className="popup-body popup-scorll">
                                <div className="inner-confirmation">
                                    <p>Are you sure you want to continue?</p>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.cancelHotelTicket(values)}>Yes</button>
                                        <button className="btn btn-grey" onClick={() => this.handleHotelConfirmationPopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay" />
                </div>
            </React.Fragment>
        )
    }
}

export default HotelCancellationComponent;