import React, { Component } from 'react';
import RegisterView from "../../components/shared/register";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup"
import { checkUsernameAction, agentRegistration ,fileUpload ,getOwnership ,getAddressProofType ,getlocation} from '../../actions/shared/registerAction';

const mapStateToProps = state => {
    
    return {
        usernameResponse    : state.register.checkusername, //For getting username avalibility
        agentRegistration   : state.register.register,//For getting agent registration
        fileUploadData      : state.register.uploadedFile, //for uploading any file
        ownerShip           : state.register.ownershipTypes, // For getting type of ownership
        addressProof        : state.register.addressProof,// For getting type of addressproof
        allCities           : state.register.locations.response,// For getting all cities and states
    };
};
const mapDispatchToProps = dispatch => {
    return {
        checkUserName             : (data,successCb,errorCb) => dispatch(checkUsernameAction(data,successCb,errorCb)),//For getting username avalibility
        submitRegistration        : (data,successCb,errorCb) => dispatch(agentRegistration(data,successCb,errorCb)),//For getting agent registrationvvvv
        fileUpload                : (data,success,error) => dispatch(fileUpload(data,success,error)), //for uploading any file
        getOwnership              : () => dispatch(getOwnership()),// For getting type of ownership
        getAddressProofType       : () => dispatch(getAddressProofType()),// For getting type of addressproof
        getlocation               : () => dispatch(getlocation())// For getting all cities and states
    };
};
class RegisterUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading     : true,
            error       : null,
            success     : null,
            uploadFileData : null,
            registration : null,
            showUserName : null
        };
    }

    //Initially calling ownership ,addressproof and cities and state API to fill dropdown
    componentDidMount(){
        this.props.getOwnership(); 
        this.props.getAddressProofType();
        this.props.getlocation();
    }

    //Function called from dumb view to check username availibility
    checkUserNameValidation=(username, callback)=>{
        const userNameData = {
            username : username
        };
        this.setState({showUserName:username});
        /* on successful login, forward the user to the Searching container */
        const successCb = (success) => {
            this.setState({success: 'success'});
            callback && callback();
            //window.location.href = "/login";
        }
        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({error: 'error'});
        }
        
        this.props.checkUserName(userNameData,successCb,errorCb);
    }

    //Function called after final form submittion
    formatValues(values) {
        var self = this;
        //if user doesnot check username availibilty before final submittion then first check username availibilty then final submit
        this.checkUserNameValidation(values.username, function() {
            self.submitForm(values);
        });
    }

    //After all validations submit form
    submitForm(values) {
        let distributorID = this.props.match.params.distributorID?this.props.match.params.distributorID:'';
        const formValues = {
            "username": values.username,
            "password": values.password,
            "first_name": values.first_name,
            "last_name": values.last_name,
            "gender": values.gender,
            "dateofbirth": values.dobyear + "-" + values.dobmonth + "-" + values.dobDay,
            "pan_card": values.pan_card,
            "gst_certificate": values.gst_certificate,
            "gst_number": values.gst_no,
            "office_address_proof": values.address_proof,
            "address_proof": values.aadhar_front,
            "address_proof_back":values.aadhar_back,
            "address_proof_type": values.address_proof_type,
            "company_name": values.company_name,
            "office_address": values.office_address,
            "country": values.country,
            "state": values.state,
            "city": values.city,
            "pincode": values.pincode,
            "phone_number": values.phone_number,
            "mobile_number": values.mobile_number,
            "email_address": values.email_address,
            "alternative_mobile_number": values.alternative_mobile_number,
            "website": values.website,
            "ownership_type" : values.ownershipType,
            "pan_number"     : values.pan_number,
            "distributor_id" : distributorID,
        };
        const successCb = () => {
            //this.setState({registration: 'success'});
            this.props.history.push('/thank-you');
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({registration: 'error'});
        }
       this.props.submitRegistration(formValues,successCb,errorCb);
    } 

    //Function to be called from dumb view to upload file
    uploadfile=(self,file_type,file)=>{
        /* on successful file upload */
        const successCb = (success) => {
            self.props.setFieldValue(file_type, success);
        }
        /* on error, update the error in the state */
        const errorCb = () => {
            self.props.setFieldValue(file_type, '');
        }
        this.props.fileUpload(file,successCb,errorCb);  
    }

    render() {
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/png",
            "image/svg",
			"application/pdf"
        ];
        const initialValues = {
            username        : "",
            first_name      : "",
            last_name       : "",
            gender          : "male",
            password        : "",
            confirmPassword : "",
            dobDay          : "",
            dobmonth        : "",
            dobyear         : "",
            company_name    : "",
            ownershipType   : "",
            office_address  : "",
            country         : "",
            state           : "",
            city            : "",
            phone_number    : "",
            mobile_number   : "",
            email_address   : "",
            alternative_mobile_number: "",
            website         : "",
            pan_card        : "",
            termsAccepted   : "",
            pincode         : "",
            check_gst       : "",
            gst_certificate : "",
            address_proof_type: "",
            address_proof   : "",
            aadhar_front   : "",
            aadhar_back   : "",
            gst_certificate_file: "",
            pan_file        : "",
            address_proof_file : "",
            aadhar_back_file : "",
            aadhar_front_file : "",
            pan_number       : "",
            gst_no          : ""
        };
        const validationSchemaBasicDetails = Yup.object().shape({
            username:
                Yup.string()
                    .required("Please enter user name"),
            first_name:
                Yup.string()
                    .required("Please enter first name"),
            last_name:
                Yup.string()
                    .required("Please enter last name"),
            gender:
                Yup.string()
                    .required("Please enter gender"),
            // dobDay:
            //     Yup.string()
            //         .required('Please select day'),
            // dobMonth:
            //     Yup.string()
            //         .required('Please Select Month'),
            // dobYear:
            //     Yup.string()
            //         .required('Please Select Year'),
            password: 
            Yup.string()
                .min(4, 'Password has to be longer than 4 characters') 
                .matches(/[a-z]/,'Password should have at least one lowercase character')
                .matches(/[A-Z]/,'Password should have at least one uppercase character')
                .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'Password should have at least 1 number or special char.')
               .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password must match'),
            company_name:
                Yup.string()
                    .required('Please Enter Company Name'),
            ownershipType:
                Yup.string()
                    .required('Please select ownership type'),
            office_address:
                Yup.string()
                    .required('Please enter office address'),
            country:
                Yup.string()
                    .required('Please select country'),
            state:
                Yup.string()
                    .required('Please select state'),
            city:
                Yup.string()
                    .required('Please select city/district'),
            phone_number:
                Yup.number()
                .typeError('Please enter valid phone number'),
            email_address:
                Yup.string()
                    .required('Please enter email address')
                    .email('Please enter a valid email address'),
            // alternative_mobile_number:
            //     Yup.string()
            //         .test('test-name', 'Please enter valid mobile number',
            //         function (value) {
            //             var numberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
            //             return numberPattern.test(value);
            //         }),
            website:
                Yup.string()
                    .url('Please enter valid website'),
            pan_card:
                Yup.string()
                    .required('Please select PAN card'),
            termsAccepted:
                Yup.boolean()
                    .required('Must Accept Terms and Conditions')
                    .oneOf([true], 'Must Accept Terms and Conditions'),
            pincode:
                Yup.number()
                    .required('Please enter pincode')
                    .typeError('Please enter valid pincode'),
            pan_number:
                Yup.string()
                    .required('Please enter PAN number'),
            address_proof_type:
                Yup.string()
                    .required('Please select address proof type number'),
            check_gst: 
                Yup.boolean(),
            gst_certificate_file:
                Yup.mixed()
                    .when("check_gst", {
                        is: true,
                        then: Yup.mixed().test(
                                        "fileFormat",
                                        "Unsupported Format. Please select image or PDF",
                                        value => value && SUPPORTED_FORMATS.includes(value.type)
                                    ),
                    }),
            gst_no:
                Yup.string()
                    .when("check_gst", {
                        is: true,
                        then: Yup.string().required('Please enter GST number'),
                    }),
            pan_file:
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileFormat",
                        "Unsupported Format. Please select image or PDF",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
            address_proof_file:
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileFormat",
                        "Unsupported Format. Please select image or PDF",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
            aadhar_front_file:
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileFormat",
                        "Unsupported Format. Please select image or PDF",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
                aadhar_back_file:
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileFormat",
                        "Unsupported Format. Please select image or PDF",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),

        });
        return (
            <Formik
                initialValues={initialValues} //Set initial values of fields to blank
                validationSchema={validationSchemaBasicDetails}
                onSubmit={(values) => {
                    this.formatValues(values);
                }}
                render={formikProps =>
                    <RegisterView {...formikProps} {...this.props} {...this.state} checkUserNameValidation={this.checkUserNameValidation} uploadFile={this.uploadfile} />
                }
            />
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RegisterUser);
