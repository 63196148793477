import React from 'react';
import { dateFormat ,jsUcfirst} from '../../../../shared/commonHelper';
import PerfectScrollbar from 'react-perfect-scrollbar';

class VendorFlightReviewBookingComponent extends React.Component {
    constructor(){
        super()
        this.state={
            showOfferedPrice : null
        }
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }
    // Fare Rule Information
    renderFareRule(flightFareRuleDetails) {
		return (
            <PerfectScrollbar>
                <div className="inner-fare-block">
                    {
                        flightFareRuleDetails.response && flightFareRuleDetails.response.map((item,index) => {
                            return (
                                <React.Fragment key={index}>
                                    <p>{item.airline}:{item.origin}-{item.destination}<br/></p>
                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </PerfectScrollbar>
            );
	}

    render() {
        //Check if account balance is sufficient to pay ticket amount
        let accountResponse = this.props.accountResponse.myAccountResponse.response
        let totalAccountBalance = 0;
        if(accountResponse){
            if(parseInt(accountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(accountResponse.credit_limit);
            }
        }
        let locationData = null;
		if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
			let index = this.props.location.state.index;
            locationData = this.props.location.state.data[index];
        }else{
			locationData = this.props.location.state.data;
        }
        let offeredFare = locationData.fare.offered_fare;
        let showInsuffient = true
        if(totalAccountBalance < offeredFare){
            showInsuffient = false;
        }
        return (
            <div className="tabs-content-wrap">
                <div className="step-content">
                    <h3 className="step-title"><span>Booking Summary</span> </h3>
                    <div className="review-filght-step">
                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Flight detail</span> </div>
                                <div className="cols pull-right"></div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                {
                                    locationData.segments.map((itemListing,keyLisitng) => {
                                        return (
                                            <div className="flight-details" key={keyLisitng}>
                                                <div className="flight-date"> <i className="icon icon-flight" /> <span>{dateFormat(this.props.searchQuery.segments[keyLisitng].preferred_departure_time,'DD MMM YYYY, FDAY')}</span> </div>
                                                <ul>
                                                    {
                                                        itemListing.map((item,key) => {
                                                            let originTerminal = '';
                                                            let destinationTerminal = '';
                                                            if(item.origin.airport.terminal !== ''){
                                                                originTerminal = <React.Fragment>- <strong>T-{item.origin.airport.terminal}</strong></React.Fragment>;
                                                            }
                                                            if(item.destination.airport.terminal !== '')
                                                            {
                                                                destinationTerminal = <React.Fragment>- <strong>T-{item.destination.airport.terminal}</strong></React.Fragment>;
                                                            }
                                                            return (
                                                                <li key={key}>
                                                                    <div className="flight-card">
                                                                        <div className="traveller-flight">
                                                                            <figure className="pull-left"><img src={item.airline.flight_logo} alt="" /></figure>
                                                                            <div className="flight-dat"> <span>{item.airline.airline_name+', '+item.airline.airline_code+" - "+item.airline.flight_number}</span>
                                                                                <p>Aircraft - {item.craft}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight-stops">
                                                                            <div className="flight-start">
                                                                                <h3>{item.origin.airport.city_name} </h3>
                                                                                <span>{dateFormat(item.origin.dep_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.origin.airport.airport_name} {originTerminal}</p>
                                                                            </div>
                                                                            <div className="flight-direction">
                                                                                <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                            </div>
                                                                            <div className="flight-start flight-landing">
                                                                                <h3>{item.destination.airport.city_name}</h3>
                                                                                <span>{dateFormat(item.destination.arr_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.destination.airport.airport_name} {destinationTerminal}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>

                        <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Passenger Details</span> </div>
                                <div className="cols pull-right">  </div>
                            </div>
                            <div className= "review-step-deatil">
                                <div className="step-deatil-outer">
                                    <div className="passenger-information">
                                        <div className="passenger-fl-info">
                                        {
                                            this.props.passengers.map((item,index) => {
                                                let paxType = '';
                                                if(item.passenger_type ===1)
                                                    paxType = 'Adult';
                                                else if(item.passenger_type ===2)
                                                    paxType = 'Child';
                                                else 
                                                    paxType = 'Infant';
                                                return (
                                                <div className="cols" key={index}>
                                                    <div className="adult-title"><span>Passenger {index+1}</span> <small>({paxType})</small></div>
                                                        <ul className="psg-list">
                                                            <li>
                                                                <span>Name:</span>
                                                                <small>{item.title_name+' '+jsUcfirst(item.first_name)+' '+jsUcfirst(item.last_name)}</small>
                                                            </li>
                                                            <li>
                                                                <span>Gender:</span>
                                                                <small>{jsUcfirst(item.gender)}</small>
                                                            </li>
                                                            <li  style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Mobile No:</span>
                                                                <small>{item.mobile_no}</small>
                                                            </li>
                                                            <li style={{ display: (item.date_of_birth)?'none':'block' }}>
                                                                <span>DOB:</span>
                                                                <small>{dateFormat(item.date_of_birth,'DD MMM YYYY')}</small>
                                                            </li>
                                                            <li  style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Email:</span>
                                                                <small>{item.email_id}</small>
                                                            </li>
                                                            <li style={{ display: (item.is_lead_pax === true)?'block':'none' }}>
                                                                <span>Address:</span>
                                                                <small>{item.addressLine1+''+(item.addressLine2?item.addressLine2:'')}</small>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            locationData.fare_rules[0].fare_rule_detail &&
                            <div className="review-step">
                                <div className="review-step-title">
                                    <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                    <div className="cols pull-right"> </div>
                                </div>
                                <div className="review-step-deatil">
                                    <div className="step-deatil-outer">
                                            <div className="fare-rule-info">
                                                <div className="inner-fare-block">
                                                {
                                                    locationData.fare_rules.map((item,index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <span className="insufficient_balance
                        " style={{display:showInsuffient?'block':'none'}}>Sorry insufficient balance in your account. Booking can't be proceed</span>
                        <div className="btn-block text-right">
                            <React.Fragment>
                                { /* eslint-disable-next-line */} 
                                <a href="/" className={`btn btn-orange loading-btn 
                                ${showInsuffient ? 'btn-disabled' : ''}
                                `}  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleConfirmationPopup(true) 
                                }}>
                                <span>Ticket</span>
                                </a>
                                {
                                    (this.props.holdBookingData.loading)
                                        ?
                                        <div className="loader-hotel">
                                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>        
                                            <div className="overlay-loader show"></div>
                                        </div>
                                        :''
                                }
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VendorFlightReviewBookingComponent