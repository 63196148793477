import {getPaxTitleApi, apiBlockHotelRoom , apiHotelBooking, apiGetBookingDetails , apiGenerateVoucher, apiEmailHotelVoucher, apiGetTripJackHotelReview, apiTripjackHotelBooking, apiConfirmBooking, apiTripjackBookingDetails,apiTripjackCheckPnr } from '../../api/hotel';

//Get pax title
export const GET_PAX_TITLE_START = 'GET_PAX_TITLE_START';
export const getPaxTitleStart = () => {
    return {
        type: GET_PAX_TITLE_START
    };
};

export const GET_PAX_TITLE_OK = 'GET_PAX_TITLE_OK';
export const getPaxTitleSuccess = (response) => {
    return {
        type: GET_PAX_TITLE_OK,
        response: response
    };
};

export const GET_PAX_TITLE_ERR = 'GET_PAX_TITLE_ERR';
export const getPaxTitleError = (response) => {
    return {
        type: GET_PAX_TITLE_ERR,
        response: response
    };
};

export const getPaxTitle = (data) => {
    return (dispatch, getState) => {
        dispatch(getPaxTitleStart());
        return getPaxTitleApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(getPaxTitleSuccess(response));
                } else{
                    dispatch(getPaxTitleError(response));
                }
            });
    }
};
// ======================= Hotel block room ======================
export const BLOCK_HOTEL_ROOM_START = 'BLOCK_HOTEL_ROOM_START';
export const blockHotelRoomStart = () => {
    return {
        type: BLOCK_HOTEL_ROOM_START
    };
};

export const BLOCK_HOTEL_ROOM_OK = 'BLOCK_HOTEL_ROOM_OK';
export const blockHotelRoomOk = (output) => {
    return {
        type: BLOCK_HOTEL_ROOM_OK,
        output: output
    };
};

export const BLOCK_HOTEL_ROOM_ERR = 'BLOCK_HOTEL_ROOM_ERR';
export const blockHotelRoomErr = (status, message, errors) => {
    return {
        type: BLOCK_HOTEL_ROOM_ERR,
        output: message
    };
};

export const blockHotelRoom = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(blockHotelRoomStart());
        return apiBlockHotelRoom(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(blockHotelRoomOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(blockHotelRoomErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= Hotel booking ======================
export const HOTEL_BOOKING_START = 'HOTEL_BOOKING_START';
export const hotelBookingStart = () => {
    return {
        type: HOTEL_BOOKING_START
    };
};

export const HOTEL_BOOKING_OK = 'HOTEL_BOOKING_OK';
export const hotelBookingOk = (output) => {
    return {
        type: HOTEL_BOOKING_OK,
        output: output
    };
};

export const HOTEL_BOOKING_ERR = 'HOTEL_BOOKING_ERR';
export const hotelBookingErr = (status, message, errors) => {
    return {
        type: HOTEL_BOOKING_ERR,
        output: message
    };
};

export const hotelBooking = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(hotelBookingStart());
        return apiHotelBooking(data)
            .then((payload) => {
                if (payload.status === "ok") {
                    /* fire off our success message */
                    dispatch(hotelBookingOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(hotelBookingErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= get Hotel booked details ======================
export const GET_HOTEL_BOOKING_START = 'GET_HOTEL_BOOKING_START';
export const getBookingDetailsStart = () => {
    return {
        type: GET_HOTEL_BOOKING_START
    };
};

export const GET_HOTEL_BOOKING_OK = 'GET_HOTEL_BOOKING_OK';
export const getBookingDetailsOk = (output) => {
    return {
        type: GET_HOTEL_BOOKING_OK,
        output: output
    };
};

export const GET_HOTEL_BOOKING_ERR = 'GET_HOTEL_BOOKING_ERR';
export const getBookingDetailsErr = (status, message, errors) => {
    return {
        type: GET_HOTEL_BOOKING_ERR,
        output: message
    };
};

export const getBookingHotelDetails = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getBookingDetailsStart());
        return apiGetBookingDetails(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getBookingDetailsOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(getBookingDetailsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= Generate Voucher ======================
export const GENERATE_VOUCHER_START = 'GENERATE_VOUCHER_START';
export const generateVoucherStart = () => {
    return {
        type: GENERATE_VOUCHER_START
    };
};

export const GENERATE_VOUCHER_OK = 'GENERATE_VOUCHER_OK';
export const generateVoucherOk = (output) => {
    return {
        type: GENERATE_VOUCHER_OK,
        output: output
    };
};

export const GENERATE_VOUCHER_ERR = 'GENERATE_VOUCHER_ERR';
export const generateVoucherErr = (status, message, errors) => {
    return {
        type: GENERATE_VOUCHER_ERR,
        output: message
    };
};

export const generateVoucher = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(generateVoucherStart());
        return apiGenerateVoucher(data)
            .then((payload) => {
                if (payload.status === 1) {
                    /* fire off our success message */
                    dispatch(generateVoucherOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(generateVoucherErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= Email hotel voucher ======================
export const EMAIL_HOTEL_VOUCHER_START = 'EMAIL_HOTEL_VOUCHER_START';
export const emailHotelVoucherStart = () => {
    return {
        type: EMAIL_HOTEL_VOUCHER_START
    };
};

export const EMAIL_HOTEL_VOUCHER_OK = 'EMAIL_HOTEL_VOUCHER_OK';
export const emailHotelVoucherOk = (output) => {
    return {
        type: EMAIL_HOTEL_VOUCHER_OK,
        output: output
    };
};

export const EMAIL_HOTEL_VOUCHER_ERR = 'EMAIL_HOTEL_VOUCHER_ERR';
export const emailHotelVoucherErr = (status, message, errors) => {
    return {
        type: EMAIL_HOTEL_VOUCHER_ERR,
        output: message
    };
};

export const emailHotelVoucher = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(emailHotelVoucherStart());
        return apiEmailHotelVoucher(data)
            .then((payload) => {
                if (payload.status === "ok") {
                    /* fire off our success message */
                    dispatch(emailHotelVoucherOk(payload.message));
                    successCb && successCb(payload);
                } else {
                    dispatch(emailHotelVoucherErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// tripjack hotel review 
export const HOTEL_TRIPJACK_HOTEL_REVIEW_START = 'HOTEL_TRIPJACK_HOTEL_REVIEW_START';
export const hotelTripjackReviewStart = () => {
    return {
        type: HOTEL_TRIPJACK_HOTEL_REVIEW_START
    };
};

export const HOTEL_TRIPJACK_HOTEL_REVIEW_OK = 'HOTEL_TRIPJACK_HOTEL_REVIEW_OK';
export const hotelTripjackReviewOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_HOTEL_REVIEW_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_HOTEL_REVIEW_ERR = 'HOTEL_TRIPJACK_HOTEL_REVIEW_ERR';
export const hotelTripjackReviewErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_HOTEL_REVIEW_ERR,
        output: message
    };
};

export const getTripjackHotelReview = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackReviewStart());
        return apiGetTripJackHotelReview(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelTripjackReviewOk(payload));
                    successCb && successCb(payload);

                } else {
                    dispatch(hotelTripjackReviewErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= Hotel Tripjack booking ======================
export const HOTEL_TRIPJACK_BOOKING_START = 'HOTEL_TRIPJACK_BOOKING_START';
export const hotelTripjackBookingStart = () => {
    return {
        type: HOTEL_TRIPJACK_BOOKING_START
    };
};

export const HOTEL_TRIPJACK_BOOKING_OK = 'HOTEL_TRIPJACK_BOOKING_OK';
export const hotelTripjackBookingOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_BOOKING_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_BOOKING_ERR = 'HOTEL_TRIPJACK_BOOKING_ERR';
export const hotelTripjackBookingErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_BOOKING_ERR,
        output: message
    };
};

export const hotelTripjackBooking = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackBookingStart());
        return apiTripjackHotelBooking(data)
            .then((payload) => {
                if (payload.status === "ok") {
                    /* fire off our success message */
                    dispatch(hotelTripjackBookingOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(hotelTripjackBookingErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= Tripjack Confirm Book======================
export const HOTEL_TRIPJACK_CONFIRM_BOOK_START = 'HOTEL_TRIPJACK_CONFIRM_BOOK_START';
export const generateConfirmBookStart = () => {
    return {
        type: HOTEL_TRIPJACK_CONFIRM_BOOK_START
    };
};

export const HOTEL_TRIPJACK_CONFIRM_BOOK_OK = 'HOTEL_TRIPJACK_CONFIRM_BOOK_OK';
export const generateConfirmBookOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_CONFIRM_BOOK_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_CONFIRM_BOOK_ERR = 'HOTEL_TRIPJACK_CONFIRM_BOOK_ERR';
export const generateConfirmBookErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_CONFIRM_BOOK_ERR,
        output: message
    };
};

export const generateConfirmBook = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(generateConfirmBookStart());
        return apiConfirmBooking(data)
            .then((payload) => {
                if (payload.status.httpStatus === 200) {
                    /* fire off our success message */
                    dispatch(generateConfirmBookOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(generateConfirmBookErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// ======================= get Hotel booked details ======================
export const GET_TRIPJACK_HOTEL_BOOKING_START = 'GET_TRIPJACK_HOTEL_BOOKING_START';
export const getTripjackBookingDetailsStart = () => {
    return {
        type: GET_TRIPJACK_HOTEL_BOOKING_START
    };
};

export const GET_TRIPJACK_HOTEL_BOOKING_OK = 'GET_TRIPJACK_HOTEL_BOOKING_OK';
export const getTripjackBookingDetailsOk = (output) => {
    return {
        type: GET_TRIPJACK_HOTEL_BOOKING_OK,
        output: output
    };
};

export const GET_TRIPJACK_HOTEL_BOOKING_ERR = 'GET_TRIPJACK_HOTEL_BOOKING_ERR';
export const getTripjackBookingDetailsErr = (status, message, errors) => {
    return {
        type: GET_TRIPJACK_HOTEL_BOOKING_ERR,
        output: message
    };
};

export const getTripjackBookingHotelDetails = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getTripjackBookingDetailsStart());
        return apiTripjackBookingDetails(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getTripjackBookingDetailsOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(getTripjackBookingDetailsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

// hotel tripjack check status api

export const HOTEL_TRIPJACK_CHECK_STATUS_START = 'HOTEL_TRIPJACK_CHECK_STATUS_START';
export const hotelTripjackCheckStatusStart = () => {
    return {
        type: HOTEL_TRIPJACK_CHECK_STATUS_START
    };
};

export const HOTEL_TRIPJACK_CHECK_STATUS_OK = 'HOTEL_TRIPJACK_CHECK_STATUS_OK';
export const hotelTripjackCheckStatusOk = (output) => {
    return {
        type: HOTEL_TRIPJACK_CHECK_STATUS_OK,
        output: output
    };
};

export const HOTEL_TRIPJACK_CHECK_STATUS_ERR = 'HOTEL_TRIPJACK_CHECK_STATUS_ERR';
export const hotelTripjackCheckStatusErr = (status, message, errors) => {
    return {
        type: HOTEL_TRIPJACK_CHECK_STATUS_ERR,
        output: message
    };
};

export const hotelTripjackCheckStatusDetails = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(hotelTripjackCheckStatusStart());
        return apiTripjackCheckPnr(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelTripjackCheckStatusOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(hotelTripjackCheckStatusErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}