import API from '..';

var REGISTER_API = {
    meta: {},
};

REGISTER_API.register = (credentials) => {
    return API.postResponse('/agent/register',credentials);
};

REGISTER_API.checkusername = (credentials) => {
    return API.postResponse('/check-username',credentials);
};

REGISTER_API.uploadFile = (credentials) => {
    return API.uploadFile('/file-upload',credentials);
};

REGISTER_API.ownership = () => {
    return API.getResponse('/ownership');
};

REGISTER_API.addressProof = () => {
    return API.getResponse('/address-proof');
};

REGISTER_API.location = () => {
    return API.getResponse('/locations');
};
REGISTER_API.countryDetails = () => {
    return API.getResponse('/country-details');
};


export default REGISTER_API;