import {
    GET_FARE_START,
    GET_FARE_OK,
    GET_FARE_ERR,
    GET_SSR_START,
    GET_SSR_OK,
    GET_SSR_ERR,
    GET_PAX_TITLE_START,
    GET_PAX_TITLE_OK,
    GET_PAX_TITLE_ERR,
    HOLD_BOOKING_START,
    HOLD_BOOKING_OK,
    HOLD_BOOKING_ERR,
    TICKET_BOOKING_START,
    TICKET_BOOKING_OK,
    TICKET_BOOKING_ERR,
    GET_COMPANY_DETAIL_START,
    GET_COMPANY_DETAIL_OK,
    GET_COMPANY_DETAIL_ERR,
    GET_ROUND_FARE_START,
    GET_ROUND_FARE_OK,
    GET_ROUND_FARE_ERR,
    GET_ROUND_SSR_START,
    GET_ROUND_SSR_OK,
    GET_ROUND_SSR_ERR,
    SET_FARE_QUOTE_VALUE,
    SET_SSR_VALUE,
    SET_TRIP_JACK_SEAT_INFO,
    SAVE_OUTBOUND_PAX_INFO,
    CREATE_PNR_START,
    CREATE_PNR_OK,
    CREATE_PNR_ERR,
    RETRIEVE_PNR_START,
    RETRIEVE_PNR_OK,
    RETRIEVE_PNR_ERR,
    PNR_CHECK_START,
    PNR_CHECK_OK,
    PNR_CHECK_ERR,
    GET_CELL_COUNTRY_CODE_START,
    GET_CELL_COUNTRY_CODE_OK,
    GET_CELL_COUNTRY_CODE_ERR,
    GET_SEAT_INFO_START,
    GET_SEAT_INFO_OK,
    GET_SEAT_INFO_ERR,
    GET_ROUND_SEAT_INFO_START,
    GET_ROUND_SEAT_INFO_OK,
    GET_ROUND_SEAT_INFO_ERR,
    GET_ROUND_FARE_TRIP_JACK_START,
    GET_ROUND_FARE_TRIP_JACK_OK,
    GET_ROUND_FARE_TRIP_JACK_ERR,
    GET_FARE_TRIP_JACK_START,
    GET_FARE_TRIP_JACK_OK,
    GET_FARE_TRIP_JACK_ERR,
    TRIP_JACK_TICKET_BOOKING_START,
    TRIP_JACK_TICKET_BOOKING_OK,
    TRIP_JACK_TICKET_BOOKING_ERR,
    GET_TRIP_JACK_BOOK_DETAILS_START,
    GET_TRIP_JACK_BOOK_DETAILS_OK,
    GET_TRIP_JACK_BOOK_DETAILS_ERR,
    GET_TRIP_JACK_BOOK_VALIDATE_OK,
    GET_TRIP_JACK_BOOK_VALIDATE_ERR,
    GET_TRIP_JACK_BOOK_CONFIRM_OK,
    GET_TRIP_JACK_BOOK_CONFIRM_ERR,
    AIRIQ_TICKET_BOOKING_START,
    AIRIQ_TICKET_BOOKING_OK,
    AIRIQ_TICKET_BOOKING_ERR,
    GET_AIRIQ_BOOK_DETAILS_START,
    GET_AIRIQ_BOOK_DETAILS_OK,
    GET_AIRIQ_BOOK_DETAILS_ERR,
    GOFLY_TICKET_BOOKING_START,
    GOFLY_TICKET_BOOKING_OK,
    GOFLY_TICKET_BOOKING_ERR,
    GET_GOFLY_BOOK_DETAILS_START,
    GET_GOFLY_BOOK_DETAILS_OK,
    GET_GOFLY_BOOK_DETAILS_ERR,
    EFLY_TICKET_BOOKING_START,
    EFLY_TICKET_BOOKING_OK,
    EFLY_TICKET_BOOKING_ERR,
    GET_EFLY_BOOK_DETAILS_START,
    GET_EFLY_BOOK_DETAILS_OK,
    GET_EFLY_BOOK_DETAILS_ERR,
    SATKAR_TICKET_BOOKING_START,
    SATKAR_TICKET_BOOKING_OK,
    SATKAR_TICKET_BOOKING_ERR,
    GET_SATKAR_BOOK_DETAILS_START,
    GET_SATKAR_BOOK_DETAILS_OK,
    GET_SATKAR_BOOK_DETAILS_ERR,
    GET_KAFILA_SSR_START,
    GET_KAFILA_SSR_OK,
    GET_KAFILA_SSR_ERR,
    GET_KAFILA_ROUND_SSR_START,
    GET_KAFILA_ROUND_SSR_OK,
    GET_KAFILA_ROUND_SSR_ERR,
    KAFILA_CANCEL_REASON_START,
    KAFILA_CANCEL_REASON_OK,
    KAFILA_CANCEL_REASON_ERR,
    KAFILA_SEAT_STATE,
    GET_RIYA_SEAT_INFO_START,
    GET_RIYA_SEAT_INFO_OK,
    GET_RIYA_SEAT_INFO_ERR,
    GET_RIYA_ROUND_SEAT_INFO_START,
    GET_RIYA_ROUND_SEAT_INFO_OK,
    GET_RIYA_ROUND_SEAT_INFO_ERR,
    GET_ROUND_FARE_RIYA_START,
    GET_ROUND_FARE_RIYA_OK,
    GET_ROUND_FARE_RIYA_ERR,
    GET_FARE_RIYA_START,
    GET_FARE_RIYA_OK,
    GET_FARE_RIYA_ERR,
    RIYA_TICKET_BOOKING_START,
    RIYA_TICKET_BOOKING_OK,
    RIYA_TICKET_BOOKING_ERR,
    GET_RIYA_BOOK_DETAILS_START,
    GET_RIYA_BOOK_DETAILS_OK,
    GET_RIYA_BOOK_DETAILS_ERR,
    RIYA_HOLD_BOOKING_START,
    RIYA_HOLD_BOOKING_OK,
    RIYA_HOLD_BOOKING_ERR,
    GET_RIYA_BOOK_CONFIRM_OK,
    GET_RIYA_BOOK_CONFIRM_ERR,

    GET_ROUND_FARE_FAREBOUTIQUE_START,
    GET_ROUND_FARE_FAREBOUTIQUE_OK,
    GET_ROUND_FARE_FAREBOUTIQUE_ERR,
    GET_FARE_FAREBOUTIQUE_START,
    GET_FARE_FAREBOUTIQUE_OK,
    GET_FARE_FAREBOUTIQUE_ERR,

    FAREBOUTIQUE_TICKET_BOOKING_START,
    FAREBOUTIQUE_TICKET_BOOKING_OK,
    FAREBOUTIQUE_TICKET_BOOKING_ERR,
    GET_FAREBOUTIQUE_BOOK_DETAILS_START,
    GET_FAREBOUTIQUE_BOOK_DETAILS_OK,
    GET_FAREBOUTIQUE_BOOK_DETAILS_ERR,

    

} from '../../actions/flight/flightBookTicketAction';

export const initialState = {
    fareQuote: {
        error :null,
        loading: false,
        response:null
    },
    //Fare Quote for Round trip 
    fareQuoteRound: {
        error :null,
        loading: false,
        response:null
    },
    ssrDetails: {
        error :null,
        loading: false,
        response:null
    },
    //SSR for Round trip 
    ssrDetailsRound: {
        error :null,
        loading: false,
        response:null
    },
    //Seat Info 
    seatInfo: {
        error :null,
        loading: false,
        response:null
    },
    //Seat Info Round Trip
    seatInfoRound: {
        error :null,
        loading: false,
        response:null
    },
    paxTitles: {
        error :null,
        loading: false,
        response:null
    },
    holdBookingData: {
        error :null,
        loading: false,
        response:null
    },
    ticketBookingData: {
        error :null,
        loading: false,
        response:null
    },
    pnrCreateData: {
        error :null,
        loading: false,
        response:null
    },
    pnrRetrieveData: {
        error :null,
        loading: false,
        response:null
    },
    pnrCheckData:{
        error :null,
        loading: false,
        response:null
    },
    companyDetails: {
        error :null,
        loading: false,
        response:null
    },
    cellCountryCode: {
        error :null,
        loading: false,
        response:null
    },
    outboundPaxInfo:{
        details : null,
        booking_id: null,
        outboundPub: null
    },
    tripJackTicketBookDetails: {
        error :null,
        loading: false,
        response:null
    },
    airIQBookDetails : {
        error : null,
        loading: false,
        response : null
    },
    goflyBookDetails : {
        error : null,
        loading: false,
        response : null
    },
    eflyBookDetails : {
        error : null,
        loading: false,
        response : null
    },
    satkarBookDetails : {
        error : null,
        loading: false,
        response : null
    },
    ssrKafilaDetails: {
        error :null,
        loading: false,
        response:null
    },
    //SSR for Round trip 
    ssrKafilaDetailsRound: {
        error :null,
        loading: false,
        response:null
    },
    kafilaPnrCancelReason:{
        loading:false,
        error:null,
        response:null
    },
    kafilaSeatState:[],
    //Seat Info 
    seatRiyaInfo: {
        error: null,
        loading: false,
        response: null
    },
    //Seat Info Round Trip
    seatRiyaInfoRound: {
        error: null,
        loading: false,
        response: null
    },
    //FareBoutique 
    fareBoutiqueBookDetails: {
        error : null,
        loading: false,
        response : null
    },

};

export function flightBookTicketReducer(state = initialState, action) {
    switch (action.type) {
        case KAFILA_SEAT_STATE :
            return {
                ...state, 
                kafilaSeatState : action.response
            }
        //Get fare Quote
        case GET_FARE_START :
            return {
                ...state, 
                fareQuote : {
                    error   : '',
                    loading : true
                }
            }
        case GET_FARE_OK :
            return {
                ...state, 
                fareQuote : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_FARE_ERR :
            return {
                ...state, 
                fareQuote : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }
        // //Get Fare Quote for Round trip itenary    
        case GET_ROUND_FARE_START :
            return {
                ...state, 
                fareQuoteRound : {
                    error   : '',
                    loading : true
                },
                fareQuote: {
                    error :"",
                    loading: true,
                },
            }
        case GET_ROUND_FARE_OK :
           
            if((action.response &&  action.response.isKafila)){
                return {
                    ...state, 
                    fareQuoteRound : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    fareQuote : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    
                };
            }else{
                return {
                    ...state, 
                    fareQuoteRound : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    fareQuote : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    
                };
            }

        case GET_ROUND_FARE_ERR :
            return {
                ...state, 
                fareQuoteRound : {
                    error   : true,
                    loading : false,
                    response : action.response
                },
                fareQuote : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }    


        //Get SSR details
        case GET_SSR_START :
            return {
                ...state, 
                ssrDetails : {
                    error   : '',
                    loading : true
                }
            }
        case GET_SSR_OK :
            return {
                ...state, 
                ssrDetails : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_SSR_ERR :
            return {
                ...state, 
                ssrDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }
        //Get SSR details for Round trip
        case GET_ROUND_SSR_START :
            return {
                ...state, 
                ssrDetailsRound : {
                    error   : '',
                    loading : true
                }
            }
        case GET_ROUND_SSR_OK :
            return {
                ...state, 
                ssrDetailsRound : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                },
                ssrDetails : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_ROUND_SSR_ERR :
            return {
                ...state, 
                ssrDetailsRound : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }  
            case GET_FARE_TRIP_JACK_START :
                return {
                    ...state, 
                    fareQuote : {
                        error   : '',
                        loading : true
                    },
                    ssrDetails : {
                        error       : '',
                        loading : true
                    },
                    seatInfo : {
                        error   : '',
                        loading : true
                    }
                }
            case GET_FARE_TRIP_JACK_OK :
                return {
                    ...state, 
                    fareQuote : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    ssrDetails : {
                        error       : '',
                        loading     : false,
                        response    : action.response.results.segments  ? action.response.results.segments  : {},
                    }
                };
    
            case GET_FARE_TRIP_JACK_ERR :
                return {
                    ...state, 
                    fareQuote : {
                        error   : true,
                        loading : false,
                        response : action.response
                    },
                    ssrDetails : {
                        error   : true,
                        loading : false,
                        response : {}
                    }
                }
            //Get Fare Quote for Round trip itenary    
            case GET_ROUND_FARE_TRIP_JACK_START :
                return {
                    ...state, 
                    fareQuoteRound : {
                        error   : '',
                        loading : true
                    },
                    ssrDetailsRound : {
                        error   : '',
                        loading : true
                    },
                    seatInfoRound : {
                        error   : '',
                        loading : true
                    }
                }
            case GET_ROUND_FARE_TRIP_JACK_OK :
                return {
                    ...state, 
                    fareQuoteRound : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    },
                    ssrDetailsRound : {
                        error       : '',
                        loading     : false,
                        response    : action.response.results.segments  ? action.response.results.segments  : {},
                    }
                };
    
            case GET_ROUND_FARE_TRIP_JACK_ERR :
                return {
                    ...state, 
                    fareQuoteRound : {
                        error   : true,
                        loading : false,
                        response : action.response
                    },
                    ssrDetailsRound : {
                        error       : true,
                        loading     : false,
                        response    :  {},
                    }
                }         
         //Get Seat Info
         case GET_SEAT_INFO_START :
            return {
                ...state, 
                seatInfo : {
                    error   : '',
                    loading : true
                }
            }
        case GET_SEAT_INFO_OK :
            return {
                ...state, 
                seatInfo : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_SEAT_INFO_ERR :
            return {
                ...state, 
                seatInfo : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }
        //Get SSR details for Round trip
        case GET_ROUND_SEAT_INFO_START :
            return {
                ...state, 
                seatInfoRound : {
                    error   : '',
                    loading : true
                }
            }
        case GET_ROUND_SEAT_INFO_OK :
            return {
                ...state, 
                seatInfoRound : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_ROUND_SEAT_INFO_ERR :
            return {
                ...state, 
                seatInfoRound : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }        
             //Get Pax details
        case GET_PAX_TITLE_START :
            return {
                ...state, 
                paxTitles : {
                    error   : '',
                    loading : true
                }
            }
        case GET_PAX_TITLE_OK :
            return {
                ...state, 
                paxTitles : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_PAX_TITLE_ERR :
            return {
                ...state, 
                paxTitles : {
                    error   : action.error,
                    loading : false
                }
            }

        //hold booking 
        case HOLD_BOOKING_START :
             return {
                 ...state, 
                 holdBookingData : {
                     error   : '',
                     loading : true,
                     response : null
                 }
             }
        case HOLD_BOOKING_OK :
             return {
                 ...state, 
                 holdBookingData : {
                     error       : '',
                     loading     : false,
                     response    : action.response,
                 }
             };
 
        case HOLD_BOOKING_ERR :
             return {
                 ...state, 
                 holdBookingData : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             }
        //ticket booking
        case TICKET_BOOKING_START :
             return {
                 ...state, 
                 ticketBookingData : {
                     error   : '',
                     loading : true,
                     response : null
                 }
             }
         case TICKET_BOOKING_OK :
             return {
                 ...state, 
                 ticketBookingData : {
                     error       : '',
                     loading     : false,
                     response    : action.response,
                 }
             };
 
         case TICKET_BOOKING_ERR :
             return {
                 ...state, 
                 ticketBookingData : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             }
             //Get company detail
        case GET_COMPANY_DETAIL_START :
            return {
                ...state, 
                companyDetails : {
                    error   : '',
                    loading : true,
                    response : null
                }
            }
        case GET_COMPANY_DETAIL_OK :
            return {
                ...state, 
                companyDetails : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_COMPANY_DETAIL_ERR :
            return {
                ...state, 
                companyDetails : {
                error   : true,
                loading : false,
                response : action.response
                }
            }
            case GET_CELL_COUNTRY_CODE_START :
                return {
                    ...state, 
                    cellCountryCode : {
                        error   : '',
                        loading : true,
                        response : null
                    }
                }
            case GET_CELL_COUNTRY_CODE_OK :
                return {
                    ...state, 
                    cellCountryCode : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };
    
            case GET_CELL_COUNTRY_CODE_ERR :
                return {
                    ...state, 
                    cellCountryCode : {
                    error   : true,
                    loading : false,
                    response : action.response
                    }
                }    
        case SET_FARE_QUOTE_VALUE :
            return {
                ...state, 
                fareQuote : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            }
        case SET_SSR_VALUE :
            return {
                ...state, 
                ssrDetails : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            } 
        
        case SET_TRIP_JACK_SEAT_INFO :
                return {
                    ...state, 
                    seatInfo : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                }      
        case SAVE_OUTBOUND_PAX_INFO :
            return {
                ...state, 
                outboundPaxInfo : {
                    details     : action.outboundDetails,
                    booking_id  : action.booking_id,
                    outboundPub : action.outboundPub,
                }
            }

        case CREATE_PNR_START:
            return {
                ...state,
                pnrRetrieveData: {
                    error: '',
                    loading: true,
                    response: null
                }
            }
        case CREATE_PNR_OK:
            return {
                ...state,
                pnrRetrieveData: {
                    error: '',
                    loading: action.loadingVal,
                    response: action.response,
                }
            };

        case CREATE_PNR_ERR:
            return {
                ...state,
                pnrRetrieveData: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            };
            case KAFILA_CANCEL_REASON_START:
            return {
                ...state,
                kafilaPnrCancelReason: {
                    error: "",
                    loading: true,
                    response: null
                }
            } ;
            case KAFILA_CANCEL_REASON_OK:
            return {
                ...state,
                kafilaPnrCancelReason: {
                    error: "",
                    loading: false,
                    response: action.response
                }
            } ;
            case KAFILA_CANCEL_REASON_ERR:
            return {
                ...state,
                kafilaPnrCancelReason: {
                    error: true,
                    loading: false,
                    response: null
                }
            }    

        case RETRIEVE_PNR_START:
            return {
                ...state,
                pnrRetrieveData: {
                    error: '',
                    loading: true,
                    response: null
                }
            }
        case RETRIEVE_PNR_OK:
            return {
                ...state,
                pnrRetrieveData: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case RETRIEVE_PNR_ERR:
            return {
                ...state,
                pnrRetrieveData: {
                    error: action,
                    loading: false,
                    response: false
                }
            } 
            case PNR_CHECK_START:
                return {
                    ...state,
                    pnrCheckData: {
                        error: '',
                        loading: true,
                        response: null
                    }
                }
            case PNR_CHECK_OK:
                return {
                    ...state,
                    pnrCheckData: {
                        error: '',
                        loading: false,
                        response: action.response,
                    }
                };
    
            case PNR_CHECK_ERR:
                return {
                    ...state,
                    pnrCheckData: {
                        error: action,
                        loading: false,
                        response: action.response
                    }
                };    
        //ticket booking trip Jack
        case TRIP_JACK_TICKET_BOOKING_START :
            return {
                ...state, 
                ticketBookingData : {
                    error   : '',
                    loading : true,
                    response : null
                }
            }
        case TRIP_JACK_TICKET_BOOKING_OK :
            return {
                ...state, 
                ticketBookingData : {
                    error       : '',
                    loading : false,
                    response    : action.response,
                }
            };

        case TRIP_JACK_TICKET_BOOKING_ERR :
            return {
                ...state, 
                ticketBookingData : {
                   error   : true,
                   loading : false,
                   response : action.response
                }
            }    
        case GET_TRIP_JACK_BOOK_DETAILS_START:
            return {
                ...state,
                tripJackTicketBookDetails: {
                    error :'',
                    loading: true,
                    response:null
                },
                ticketBookingData:{	
                    loading:true	
                }
            };
        case  GET_TRIP_JACK_BOOK_DETAILS_OK:
            return {
                ...state,
                tripJackTicketBookDetails: {
                    error :'',
                    loading: false,
                    response:action.response
                }, 
                ticketBookingData : {
                    loading : false,
                }
            };
        case  GET_TRIP_JACK_BOOK_DETAILS_ERR:
            return {
                ...state,
                tripJackTicketBookDetails: {
                    error : action,
                    loading: false,
                    response:action.response
                },
                ticketBookingData : {
                    loading : false,
                }
            }
        case GET_TRIP_JACK_BOOK_VALIDATE_OK :
            return {
                ...state, 
                ticketBookingData : {
                    error       : '',
                    loading : true,
                    response    : action.response,
                }
            };
        
        case GET_TRIP_JACK_BOOK_VALIDATE_ERR :
            return {
                ...state, 
                ticketBookingData : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            } 
        case GET_TRIP_JACK_BOOK_CONFIRM_OK :
            return {
                ...state, 
                ticketBookingData : {
                    error       : '',
                    loading : true,
                    response    : action.response,
                }
            };
            
        case GET_TRIP_JACK_BOOK_CONFIRM_ERR :
            return {
                ...state, 
                ticketBookingData : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }            
        //ticket booking Air IQ
        case AIRIQ_TICKET_BOOKING_START :
            return {
                ...state, 
                ticketBookingData : {
                    error   : '',
                    loading : true,
                    response : null
                }
            }
        case AIRIQ_TICKET_BOOKING_OK :
            return {
                ...state, 
                ticketBookingData : {
                    error       : '',
                    loading : false,
                    response    : action.response,
                }
            };

        case AIRIQ_TICKET_BOOKING_ERR :
            return {
                ...state, 
                ticketBookingData : {
                   error   : true,
                   loading : false,
                   response : action.response
                }
            }    
            case GET_AIRIQ_BOOK_DETAILS_START:
                return {
                    ...state,
                    airIQBookDetails: {
                        error :'',
                        loading: true,
                        response:null
                    },
                    ticketBookingData:{
                        loading:true
                    }
                };
            case  GET_AIRIQ_BOOK_DETAILS_OK:
                return {
                    ...state,
                    airIQBookDetails: {
                        error :'',
                        loading: false,
                        response:action.response
                    }, 
                    ticketBookingData : {
                        loading : false,
                    }
                };
            case  GET_AIRIQ_BOOK_DETAILS_ERR:
                return {
                    ...state,
                    airIQBookDetails: {
                        error : action,
                        loading: false,
                        response:action.response
                    },
                    ticketBookingData : {
                        loading : false,
                    }
                }
                
                 //ticket booking GOfly
        case GOFLY_TICKET_BOOKING_START :
            return {
                ...state, 
                ticketBookingData : {
                    error   : '',
                    loading : true,
                    response : null
                }
            }
        case GOFLY_TICKET_BOOKING_OK :
            return {
                ...state, 
                ticketBookingData : {
                    error       : '',
                    loading : false,
                    response    : action.response,
                }
            };

        case GOFLY_TICKET_BOOKING_ERR :
            return {
                ...state, 
                ticketBookingData : {
                   error   : true,
                   loading : false,
                   response : action.response
                }
            }    
            case GET_GOFLY_BOOK_DETAILS_START:
                return {
                    ...state,
                    goflyBookDetails: {
                        error :'',
                        loading: true,
                        response:null
                    },
                    ticketBookingData:{
                        loading:true
                    }
                };
            case  GET_GOFLY_BOOK_DETAILS_OK:
                return {
                    ...state,
                    goflyBookDetails: {
                        error :'',
                        loading: false,
                        response:action.response
                    }, 
                    ticketBookingData : {
                        loading : false,
                    }
                };
            case  GET_GOFLY_BOOK_DETAILS_ERR:
                return {
                    ...state,
                    goflyBookDetails: {
                        error : action,
                        loading: false,
                        response:action.response
                    },
                    ticketBookingData : {
                        loading : false,
                    }
                }
        //efly booking start

        case EFLY_TICKET_BOOKING_START:
            return {
                ...state,
                ticketBookingData: {
                    error: '',
                    loading: true,
                    response: null
                }
            }
        case EFLY_TICKET_BOOKING_OK:
            return {
                ...state,
                ticketBookingData: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case EFLY_TICKET_BOOKING_ERR:
            return {
                ...state,
                ticketBookingData: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        case GET_EFLY_BOOK_DETAILS_START:
            return {
                ...state,
                eflyBookDetails: {
                    error: '',
                    loading: true,
                    response: null
                },
                ticketBookingData: {
                    loading: true
                }
            };
        case GET_EFLY_BOOK_DETAILS_OK:
            return {
                ...state,
                eflyBookDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                },
                ticketBookingData: {
                    loading: false,
                }
            };
        case GET_EFLY_BOOK_DETAILS_ERR:
            return {
                ...state,
                eflyBookDetails: {
                    error: action,
                    loading: false,
                    response: action.response
                },
                ticketBookingData: {
                    loading: false,
                }
            } 
            
        //ticket booking satkar
        case SATKAR_TICKET_BOOKING_START:
            return {
                ...state,
                ticketBookingData: {
                    error: '',
                    loading: true,
                    response: null
                }
            }
        case SATKAR_TICKET_BOOKING_OK:
            return {
                ...state,
                ticketBookingData: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case SATKAR_TICKET_BOOKING_ERR:
            return {
                ...state,
                ticketBookingData: {
                    error: true,
                    loading: false,
                    response: action.response
                }
            }
        case GET_SATKAR_BOOK_DETAILS_START:
            return {
                ...state,
                satkarBookDetails: {
                    error: '',
                    loading: true,
                    response: null
                },
                ticketBookingData: {
                    loading: true
                }
            };
        case GET_SATKAR_BOOK_DETAILS_OK:
            return {
                ...state,
                satkarBookDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                },
                ticketBookingData: {
                    loading: false,
                }
            };
        case GET_SATKAR_BOOK_DETAILS_ERR:
            return {
                ...state,
                satkarBookDetails: {
                    error: action,
                    loading: false,
                    response: action.response
                },
                ticketBookingData: {
                    loading: false,
                }
            }
            
            //Get SSR details
            case GET_KAFILA_SSR_START :
                return {
                    ...state, 
                    ssrKafilaDetails : {
                        error   : '',
                        loading : true,
                    },
                    ssrDetails : {
                        error       : '',
                        loading     : false,
                        response    : null,
                    }
                }
            case GET_KAFILA_SSR_OK :
                return {
                    ...state, 
                    ssrKafilaDetails : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };
    
            case GET_KAFILA_SSR_ERR :
                return {
                    ...state, 
                    ssrKafilaDetails : {
                        error   : true,
                        loading : false,
                        response : action.response
                    }
                }
            //Get SSR details for Round trip
            case GET_KAFILA_ROUND_SSR_START :
                return {
                    ...state, 
                    ssrKafilaDetailsRound : {
                        error   : '',
                        loading : true
                    },
                    ssrDetailsRound: {
                        error :null,
                        loading: false,
                        response:null
                    },
                }
            case GET_KAFILA_ROUND_SSR_OK :
                return {
                    ...state, 
                    ssrKafilaDetailsRound : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };

            case GET_KAFILA_ROUND_SSR_ERR :
                return {
                    ...state, 
                    ssrKafilaDetailsRound : {
                        error   : true,
                        loading : false,
                        response : action.response
                    }
                }  

            //Get Riy Seat Info
            case GET_RIYA_SEAT_INFO_START:
                return {
                    ...state,
                    seatRiyaInfo: {
                        error: '',
                        loading: true
                    }
                }
            case GET_RIYA_SEAT_INFO_OK:
                return {
                    ...state,
                    seatRiyaInfo: {
                        error: '',
                        loading: false,
                        response: action.response,
                    }
                };

            case GET_RIYA_SEAT_INFO_ERR:
                return {
                    ...state,
                    seatRiyaInfo: {
                        error: true,
                        loading: false,
                        response: action.response
                    }
                }
            //Get SSR details for Round trip
            case GET_RIYA_ROUND_SEAT_INFO_START:
                return {
                    ...state,
                    seatRiyaInfoRound: {
                        error: '',
                        loading: true
                    }
                }
            case GET_RIYA_ROUND_SEAT_INFO_OK:
                return {
                    ...state,
                    seatRiyaInfoRound: {
                        error: '',
                        loading: false,
                        response: action.response,
                    }
                };

            case GET_RIYA_ROUND_SEAT_INFO_ERR:
                return {
                    ...state,
                    seatRiyaInfoRound: {
                        error: true,
                        loading: false,
                        response: action.response
                    }
                }  
                
                case GET_FARE_RIYA_START :
                    return {
                        ...state, 
                        fareQuote : {
                            error   : '',
                            loading : true
                        },
                        ssrDetails : {
                            error       : '',
                            loading : true
                        },
                        seatInfo : {
                            error   : '',
                            loading : true
                        }
                    }
                case GET_FARE_RIYA_OK :
                    return {
                        ...state, 
                        fareQuote : {
                            error       : '',
                            loading     : false,
                            response    : action.response,
                        },
                        ssrDetails : {
                            error       : '',
                            loading     : false,
                            response    : action.response  ? action.response  : {},
                        }
                    };
        
                case GET_FARE_RIYA_ERR :
                    return {
                        ...state, 
                        fareQuote : {
                            error   : true,
                            loading : false,
                            response : action.response
                        },
                        ssrDetails : {
                            error   : true,
                            loading : false,
                            response : {}
                        }
                    }
                //Get Fare Quote for Round trip itenary    
                case GET_ROUND_FARE_RIYA_START :
                    return {
                        ...state, 
                        fareQuoteRound : {
                            error   : '',
                            loading : true
                        },
                        ssrDetailsRound : {
                            error   : '',
                            loading : true
                        },
                        seatInfoRound : {
                            error   : '',
                            loading : true
                        }
                    }
                case GET_ROUND_FARE_RIYA_OK :
                    return {
                        ...state, 
                        fareQuoteRound : {
                            error       : '',
                            loading     : false,
                            response    : action.response,
                        },
                        ssrDetailsRound : {
                            error       : '',
                            loading     : false,
                            response    : action.response  ? action.response  : {},
                        }
                    };
        
                case GET_ROUND_FARE_RIYA_ERR :
                    return {
                        ...state, 
                        fareQuoteRound : {
                            error   : true,
                            loading : false,
                            response : action.response
                        },
                        ssrDetailsRound : {
                            error       : true,
                            loading     : false,
                            response    :  {},
                        }
                    }    
                //ticket booking riya
                case RIYA_TICKET_BOOKING_START :
                    return {
                        ...state, 
                        ticketBookingData : {
                            error   : '',
                            loading : true,
                            response : null
                        }
                    }
                case RIYA_TICKET_BOOKING_OK :
                    return {
                        ...state, 
                        ticketBookingData : {
                            error       : '',
                            loading : false,
                            response    : action.response,
                        }
                    };

                case RIYA_TICKET_BOOKING_ERR :
                    return {
                        ...state, 
                        ticketBookingData : {
                        error   : true,
                        loading : false,
                        response : action.response
                        }
                    }    
                case GET_RIYA_BOOK_DETAILS_START:
                    return {
                        ...state,
                        riyaTicketBookDetails: {
                            error :'',
                            loading: true,
                            response:null
                        },
                        ticketBookingData:{	
                            loading:true	
                        }
                    };
                case  GET_RIYA_BOOK_DETAILS_OK:
                    return {
                        ...state,
                        riyaTicketBookDetails: {
                            error :'',
                            loading: false,
                            response:action.response
                        }, 
                        ticketBookingData : {
                            loading : false,
                        }
                    };
                case  GET_RIYA_BOOK_DETAILS_ERR:
                    return {
                        ...state,
                        riyaTicketBookDetails: {
                            error : action,
                            loading: false,
                            response:action.response
                        },
                        ticketBookingData : {
                            loading : false,
                        }
                    }    
                
                    case RIYA_HOLD_BOOKING_START :
                        return {
                            ...state, 
                            holdBookingData : {
                                error   : '',
                                loading : true,
                                response : null
                            }
                        }
                   case RIYA_HOLD_BOOKING_OK :
                        return {
                            ...state, 
                            holdBookingData : {
                                error       : '',
                                loading     : false,
                                response    : action.response,
                            }
                        };
            
                   case RIYA_HOLD_BOOKING_ERR :
                        return {
                            ...state, 
                            holdBookingData : {
                               error   : true,
                               loading : false,
                               response : action.response
                            }
                        }

                        case GET_RIYA_BOOK_CONFIRM_OK :
                            return {
                                ...state, 
                                ticketBookingData : {
                                    error       : '',
                                    loading : true,
                                    response    : action.response,
                                }
                            };
                            
                        case GET_RIYA_BOOK_CONFIRM_ERR :
                            return {
                                ...state, 
                                ticketBookingData : {
                                    error   : true,
                                    loading : false,
                                    response : action.response
                                }
                            }  

                        //Get Fare Quote for Fare Boutique Itenary
                        case GET_FARE_FAREBOUTIQUE_START :
                            return {
                                ...state, 
                                fareQuote : {
                                    error   : '',
                                    loading : true
                                },
                               
                            }
                        case GET_FARE_FAREBOUTIQUE_OK :
                            return {
                                ...state, 
                                fareQuote : {
                                    error       : '',
                                    loading     : false,
                                    response    : action.response,
                                }
                            };
                
                        case GET_FARE_FAREBOUTIQUE_ERR :
                            return {
                                ...state, 
                                fareQuote : {
                                    error   : true,
                                    loading : false,
                                    response : action.response
                                }
                            }
                        //Get Fare Quote for Round trip itenary    
                        case GET_ROUND_FARE_FAREBOUTIQUE_START :
                            return {
                                ...state, 
                                fareQuoteRound : {
                                    error   : '',
                                    loading : true
                                },
                                fareQuote: {
                                    error :"",
                                    loading: true,
                                },
                            }
                        case GET_ROUND_FARE_FAREBOUTIQUE_OK :
                            return {
                                ...state, 
                                fareQuoteRound : {
                                    error       : '',
                                    loading     : false,
                                    response    : action.response,
                                },
                                fareQuote : {
                                    error       : '',
                                    loading     : false,
                                    response    : action.response,
                                },
                                
                            };
                
                        case GET_ROUND_FARE_FAREBOUTIQUE_ERR :
                            return {
                                ...state, 
                                fareQuoteRound : {
                                    error   : true,
                                    loading : false,
                                    response : action.response
                                },
                                fareQuote : {
                                    error   : true,
                                    loading : false,
                                    response : action.response
                                }
                            }
                        
                              //ticket booking FareBoutique
                            case FAREBOUTIQUE_TICKET_BOOKING_START:
                                return {
                                    ...state,
                                    ticketBookingData: {
                                        error: '',
                                        loading: true,
                                        response: null
                                    }
                                }
                            case FAREBOUTIQUE_TICKET_BOOKING_OK:
                                return {
                                    ...state,
                                    ticketBookingData: {
                                        error: '',
                                        loading: false,
                                        response: action.response,
                                    }
                                };

                            case FAREBOUTIQUE_TICKET_BOOKING_ERR:
                                return {
                                    ...state,
                                    ticketBookingData: {
                                        error: true,
                                        loading: false,
                                        response: action.response
                                    }
                                }
                            case GET_FAREBOUTIQUE_BOOK_DETAILS_START:
                                return {
                                    ...state,
                                    fareBoutiqueBookDetails: {
                                        error: '',
                                        loading: true,
                                        response: null
                                    },
                                    ticketBookingData: {
                                        loading: true
                                    }
                                };
                            case GET_FAREBOUTIQUE_BOOK_DETAILS_OK:
                                return {
                                    ...state,
                                    fareBoutiqueBookDetails: {
                                        error: '',
                                        loading: false,
                                        response: action.response
                                    },
                                    ticketBookingData: {
                                        loading: false,
                                    }
                                };
                            case GET_FAREBOUTIQUE_BOOK_DETAILS_ERR:
                                return {
                                    ...state,
                                    fareBoutiqueBookDetails: {
                                        error: action,
                                        loading: false,
                                        response: action.response
                                    },
                                    ticketBookingData: {
                                        loading: false,
                                    }
                                }
                    
        default:
            return state;
            
    }
}