import React, { Component } from 'react';
// import PrintTicket from '../../components/agent/PrintTicket';
import PrintOfflineBookingTicket from '../../components/agent/PrintOfflineBookingTicket';
import { getCalendarDetail,bookingInfoAction } from '../../actions/agentAction';
import { connect } from 'react-redux';

class PrintTicketContainer extends Component {
    componentDidMount() {
        let data = localStorage.getItem('print-ticket-data');
        let booking_id = JSON.parse(data).jf_booking_id;
        const booking = {
            "booking_id": parseInt(booking_id)
        };
        this.props.bookingInfoAction(booking);
    }
    render() {
        let data = localStorage.getItem('print-ticket-data');
        let bookingData = null;
        // if(JSON.parse(data).booking_type === 'offline'){
            //if(this.props.calendarResponse.response){
                if(this.props.bookingInfoResponse.response){
                for(let i = 0;i<this.props.bookingInfoResponse.response.length ; i++){
                    for(let j=0;j<this.props.bookingInfoResponse.response[i].bookings.length;j++){
                        let value = this.props.bookingInfoResponse.response[i].bookings[j]
                        if(String(value.details.transaction_no) === String(JSON.parse(data).tbo_booking_id) && value.details.pnr === JSON.parse(data).pnr){
                            bookingData = value;
                        }
                    }
                }
            }
        // }
        return (
            <React.Fragment>
                {/* {
                    data && JSON.parse(data).booking_type === 'online' &&
                    <PrintTicket {...this.props}/>
                }
                {
                    data && JSON.parse(data).booking_type === 'offline' && */}
                    <PrintOfflineBookingTicket {...this.props} bookingData={bookingData}/>
                {/* } */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        calendarResponse            : state.myAccount.calendarResponse,
        bookingInfoResponse         : state.myAccount.bookingInfoResponse,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCalendarDetail   :() => dispatch(getCalendarDetail()),
        bookingInfoAction: (data) => dispatch(bookingInfoAction(data)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(PrintTicketContainer)