import React from "react";
import FlightBookTicketContainer from "./FlightBookTicketContainer";
import VendorFlightBookTicketContainer from "./VendorFlightBookTicketContainer";

class FlightBookMainContainer extends React.Component {
    render() {
        let is_vendor = 0;
        if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
            is_vendor = this.props.location.state.data[this.props.location.state.index].is_vendor;
        }else{
            is_vendor = this.props.location.state.data.is_vendor;
        }
        return (
            <React.Fragment>
                {
                    is_vendor === 1 
                    ? 
                    <VendorFlightBookTicketContainer {...this.props}/>
                    :
                    <FlightBookTicketContainer {...this.props} />
                }
            </React.Fragment>
        );
    }
}

export default FlightBookMainContainer;
