/* external dependencies */
import React from 'react';
import LoginOtpComponent from '.';

class VerifyLoginPage extends React.Component {

	render() {
		let { productResponse, location } = this.props;

		return (
			<section className="mid-wrapper">
				<div className="container">
					<div className="login-container">
						<div className="login-area">
							<div className="our-services">
								<ul>
									{
										productResponse.response && productResponse.response.map((item, index) => {
											return (
												<li key={index}>
													<i className="">
														<img src={item.product_image} alt="" /></i>
													<h3>{item.product_name}</h3>
												</li>
											)
										})
									}
								</ul>
							</div>
							<div className="login-block login-wcard">
								<div className={`flip-card show-send-otp-login`}>
									<div className="flip-card-inner">
										<LoginOtpComponent {...this.props} location={location && location.pathname} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		);
	}
}

export default VerifyLoginPage;