/* external dependencies */
import React from 'react';
import { connect } from 'react-redux'
import RailwayLoginComponent from '../../components/railway/RailwayLoginComponent';
import { loginAction } from '../../actions/shared/sessionAction';

class RailwayLoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            loginSuccess: null,
        };
    }

    /* define our submit handler */
    handleLoginSubmit = (values, actions) => {
        let encData = this.props.history.location.search;
        this.setState({ error: null });
        /* on successful login, detail page */
        const successCb = (data) => {
            if (data.password_expires_in <= 5) {
                this.setState({
                    passwordExpiry: data.password_expires_in
                })
            } else {
                this.setState({ loginSuccess: true });
                setTimeout(function () {
                    window.location = "/jfrb/detail-verification" + encData;
                    // self.props.history.push({
                    //     pathname: "/jfrb/detail-verification" + encData,
                    // });
                }, 1000);
            }
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({ error: error });
            actions.setSubmitting(false);
        }
        values['from'] = 'front';
        this.props.loginAction(values, successCb, errorCb);

    };

    render() {
        return (
            <RailwayLoginComponent
                {...this.props}
                handleLoginSubmit={this.handleLoginSubmit}
                errorMsg={this.state.error}
                successMsg={this.state.loginSuccess}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        productResponse: state.shared.productResponse
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginAction: (credentials, successCb, errorCb) => dispatch(loginAction(credentials, successCb, errorCb)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RailwayLoginContainer);