/* external dependencies */
import React from 'react';

class TermsAndConditionsComponent extends React.Component {
    render() {
        let {pagesResponse} = this.props;
        return (
            <section className="mid-wrapper">
                <div className="page-title">
                    <figure><img src={pagesResponse.response && pagesResponse.response.pages['terms_and-conditions'].page_image} alt="Terms and conditions Banner"/></figure>
                    <div className="banner-title">
                        <div className="container">
                            <h1>{!pagesResponse.response ? '-' : pagesResponse.response.pages['terms_and-conditions'].page_title}</h1>
                        </div>
                    </div>
                </div>
                <div className="about-info">
                    <div className="container">
                        <div className="about-content">
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['terms_and-conditions'].page_description }} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TermsAndConditionsComponent;