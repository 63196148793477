import React from 'react';

class FlightSearchDetailsLoaderComponent extends React.Component {
    render() {
        return ( 
            <section className="mid-wrapper inner-mid-section">
                <div className="container">
                    <div className="search-result-section search-loader">
                        <div className="searching">
                            <div className="container">
                                <div className="search-title"> 
                                    <span>Searching your needs....</span>
                                    <h1>Please be patient!</h1>
                                </div>
                                <div className="searching-outer">
                                    <div className="searching-space">
                                        <img src="assets/images/searching-bg.jpg" alt=""/>
                                    </div>
                                    <div className="searching-slide" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default FlightSearchDetailsLoaderComponent