import AGENT_API from '../api/agentApi';
//=========================== for banner API =========================================
export const MY_ACCOUNT_START = 'MY_ACCOUNT_START';
const myAccountFetch = () => {
    return {
        type: MY_ACCOUNT_START
    };
};

export const MY_ACCOUNT_OK = 'MY_ACCOUNT_OK';
const myAccountFetchSuccess = (data) => {
    return {
        type: MY_ACCOUNT_OK,
        response: data
    }
};

export const MY_ACCOUNT_ERR = 'MY_ACCOUNT_ERR';
const myAccountFetchError = (status, message, errors) => {
    return {
        type: MY_ACCOUNT_ERR,
        status,
        message,
        errors
    }
};

export function myAccountAction(credentials) {
    return (dispatch, getState) => {

        dispatch(myAccountFetch());

        return AGENT_API.getMyAccount(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(myAccountFetchSuccess(payload.agent_info));

                } else {
                    dispatch(myAccountFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}


//============================================= Agent Change Password ==============================================//
export const AGENT_CHANGE_PASSWORD_START = 'AGENT_CHANGE_PASSWORD_START';
const agentChangePasswordFetch = () => {
    return {
        type: AGENT_CHANGE_PASSWORD_START
    };
};

export const AGENT_CHANGE_PASSWORD_OK = 'AGENT_CHANGE_PASSWORD_OK';
const agentChangePasswordSuccess = (data) => {
    return {
        type: AGENT_CHANGE_PASSWORD_OK,
        response: data
    }
};

export const AGENT_CHANGE_PASSWORD_ERR = 'AGENT_CHANGE_PASSWORD_ERR';
const agentChangePasswordError = (status, message, errors) => {
    return {
        type: AGENT_CHANGE_PASSWORD_ERR,
        status,
        message,
        errors
    }
};

export function submitAgentChangePassword(credentials,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(agentChangePasswordFetch());

        return AGENT_API.agentChangePassword(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(agentChangePasswordSuccess(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(agentChangePasswordError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Agent Bank Details ==============================================//
export const BANK_DETAIL_START = 'BANK_DETAIL_START';
const agentBankDetailStart = () => {
    return {
        type: BANK_DETAIL_START
    };
};

export const BANK_DETAIL_OK = 'BANK_DETAIL_OK';
const bankDeatilSuccess = (data) => {
    return {
        type: BANK_DETAIL_OK,
        response: data.bank_details
    }
};

export const BANK_DETAIL_ERR = 'BANK_DETAIL_ERR';
const bankDeatilError = (status, message, errors) => {
    return {
        type: BANK_DETAIL_ERR,
        status,
        message,
        errors
    }
};

export function getBankDetail() {
    return (dispatch, getState) => {
        dispatch(agentBankDetailStart());
        return AGENT_API.agentBankDetails()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(bankDeatilSuccess(payload));
                } else {
                    dispatch(bankDeatilError(payload.status, payload.message, payload.errors));
                }
            });
    }
}


//=========================================== Agent Deposit For ============================================
export const DEPOSIT_REQUEST_START = 'DEPOSIT_REQUEST_START';
const depositRequestFetch = () => {
    return {
        type: DEPOSIT_REQUEST_START
    };
};

export const DEPOSIT_REQUEST_OK = 'DEPOSIT_REQUEST_OK';
const depositRequestSuccess = (response,total_count) => {
    return {
        type: DEPOSIT_REQUEST_OK,
        response: response,
        total_count: total_count
    }
};

export const DEPOSIT_REQUEST_ERR = 'DEPOSIT_REQUEST_ERR';
const depositRequestError = (status, message, errors) => {
    return {
        type: DEPOSIT_REQUEST_ERR,
        status,
        message,
        errors
    }
};

export function myDepositRequestAction(limitOffset,searchValues) {
    return (dispatch, getState) => {
        dispatch(depositRequestFetch());
        searchValues = searchValues ?searchValues:""
        return AGENT_API.getDepositRequest(limitOffset,searchValues)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(depositRequestSuccess(payload.deposit_request,payload.total_count));

                } else {
                    dispatch(depositRequestError(payload.status, payload.message, payload.errors));
                }
            });
    }
}


//======================================================== Agent Credit for ==================================//

export const VIEW_CREDIT_REQUEST_START = 'VIEW_CREDIT_REQUEST_START';
const viewCreditRequestFetch = () => {
    return {
        type: VIEW_CREDIT_REQUEST_START
    };
};

export const VIEW_CREDIT_REQUEST_OK = 'VIEW_CREDIT_REQUEST_OK';
const viewCreditRequestSuccess = (response,total_count) => {
    return {
        type: VIEW_CREDIT_REQUEST_OK,
        response:response,
        total_count:total_count,
    }
};

export const VIEW_CREDIT_REQUEST_ERR = 'VIEW_CREDIT_REQUEST_ERR';
const viewCreditRequestError = (status, message, errors) => {
    return {
        type: VIEW_CREDIT_REQUEST_ERR,
        status,
        message,
        errors
    }
};

export function myViewCreditRequestAction(limitOffset,searchValues) {
    return (dispatch, getState) => {
        dispatch(viewCreditRequestFetch());
        searchValues = searchValues ?searchValues:""
        return AGENT_API.getViewCreditRequest(limitOffset,searchValues)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(viewCreditRequestSuccess(payload.credit_request,payload.total_count));

                } else {
                    dispatch(viewCreditRequestError(payload.status, payload.message, payload.errors));
                }
            });
    }
}


//======================================================== Deposit Request Types ==================================//

export const DEPOSIT_TYPE_START = 'DEPOSIT_TYPE_START';
const depositTypeFetch = () => {
    return {
        type: DEPOSIT_TYPE_START
    };
};

export const DEPOSIT_TYPE_OK = 'DEPOSIT_TYPE_OK';
const depositTypeSuccess = (data) => {
    return {
        type: DEPOSIT_TYPE_OK,
        response: data
    }
};

export const DEPOSIT_TYPE_ERR = 'DEPOSIT_TYPE_ERR';
const depositTypeError = (status, message, errors) => {
    return {
        type: DEPOSIT_TYPE_ERR,
        status,
        message,
        errors
    }
};

export function depositTypeAction() {
    return (dispatch, getState) => {
        dispatch(depositTypeFetch());

        return AGENT_API.getDepositType()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(depositTypeSuccess(payload.deposit_types));

                } else {
                    dispatch(depositTypeError(payload.status, payload.message, payload.errors));
                }
            });
    }
}


//============================================= New Deposit Submit Request ==============================================//
export const SUBMIT_DEPOSIT_START = 'SUBMIT_DEPOSIT_START';
const depositSubmitFetch = () => {
    return {
        type: SUBMIT_DEPOSIT_START
    };
};

export const SUBMIT_DEPOSIT_OK = 'SUBMIT_DEPOSIT_OK';
const depositSubmitSuccess = (data) => {
    return {
        type: SUBMIT_DEPOSIT_OK,
        response: data
    }
};

export const SUBMIT_DEPOSIT_ERR = 'SUBMIT_DEPOSIT_ERR';
const depositSubmitError = (status, message, errors) => {
    return {
        type: SUBMIT_DEPOSIT_ERR,
        status,
        message,
        errors
    }
};

export function submitNewDepositRequest(credentials,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(depositSubmitFetch());

        return AGENT_API.newDepositRequest(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(depositSubmitSuccess(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(depositSubmitError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

export const ADD_CREDIT_REQUEST_START = "ADD_CREDIT_REQUEST_START";
const addCreditRequstFatch = () => {
    return {
        type:ADD_CREDIT_REQUEST_START
    };
}

export const ADD_CREDIT_REQUEST_OK = "ADD_CREDIT_REQUEST_OK";
const addCreditRequestSuccess = (data) => {
    return {
        type: ADD_CREDIT_REQUEST_OK,
        response: data
    };
}

export const ADD_CREDIT_REQUEST_ERROR = "ADD_CREDIT_REQUEST_ERROR";
const addCreditRequestError = (status,message) => {
    return {
        type: ADD_CREDIT_REQUEST_ERROR,
        status,
        message
    };
}

export function addCreditRequestAction(credentials,successCb,errorCb){
    return (dispatch, getState) => {
        dispatch(addCreditRequstFatch());

        return AGENT_API.addCreditRequest(credentials)
        .then((payload) => {
            if (payload.status === "ok") {
                dispatch(addCreditRequestSuccess(payload.message));
                successCb && successCb();
            } else {
                dispatch(addCreditRequestError(payload.status,payload.message));
                errorCb && errorCb();
            }
        });
    };
}

//============================================= Update Agent Details ==============================================//
export const UPDATE_AGENT_INFO_START = 'UPDATE_AGENT_INFO_START';
const updateAgentInfoFetch = () => {
    return {
        type: UPDATE_AGENT_INFO_START
    };
};

export const UPDATE_AGENT_INFO_OK = 'UPDATE_AGENT_INFO_OK';
const updateAgentInfoSuccess = (data) => {
    return {
        type: UPDATE_AGENT_INFO_OK,
        response: data
    }
};

export const UPDATE_AGENT_INFO_ERR = 'UPDATE_AGENT_INFO_ERR';
const updateAgentInfoError = (status, message, errors) => {
    return {
        type: UPDATE_AGENT_INFO_ERR,
        status,
        message,
        errors
    }
};

export function updateAgentInfo(credentials,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(updateAgentInfoFetch());

        return AGENT_API.updateAgent(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(updateAgentInfoSuccess(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(updateAgentInfoError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Agent Markup Tool Updates Statrt ==============================================//
export const GET_MARKUP_TOOL_START = "GET_MARKUP_TOOL_START";
const getMarkupToolStart = () => {
    return{
        type:GET_MARKUP_TOOL_START
    }
}

export const GET_MARKUP_TOOL_OK = "GET_MARKUP_TOOL_OK"
const getMarkupToolSuccess = (response) => {
    return{
        type:GET_MARKUP_TOOL_OK,
        response:response
    }
}

export const GET_MARKUP_TOOL_ERROR = "GET_MARKUP_TOOL_ERROR"
const getcMarkupToolError = (error) => {
    return{
        type:GET_MARKUP_TOOL_ERROR,
        error:error
    }
}

export function getcMarkupToolResponse(category){
    return (dispatch,getState) => {
        dispatch(getMarkupToolStart());

        return AGENT_API.getMarkupToolInfo(category)
        .then((payload) => {
            if(payload.status === "ok"){
                dispatch(getMarkupToolSuccess(payload.markups))
            }else{
                dispatch(getcMarkupToolError(payload))
            }
        })
    }
}

/* Update domestic markup tool */

export const DOMESTIC_MARKUP_TOOL_START = "DOMESTIC_MARKUP_TOOL_START"
const updateDomesticMarkupToolStart = () => {
    return{
        type:DOMESTIC_MARKUP_TOOL_START
    }
}
export const DOMESTIC_MARKUP_TOOL_SUCCESS = "DOMESTIC_MARKUP_TOOL_SUCCESS"
const updateDomesticMarkupToolSuccess = (response) => {
    return{
        type:DOMESTIC_MARKUP_TOOL_SUCCESS,
        response:response
    }
}
export const DOMESTIC_MARKUP_TOOL_ERROR = "DOMESTIC_MARKUP_TOOL_ERROR"
const updateDomesticMarkupToolError = (status,message,errors) => {
    return{
        type:DOMESTIC_MARKUP_TOOL_ERROR,
        status,
        message,
        errors
    }
}
export function submitDomesticMarkupTool(credentials,successCb,errorCb)
{
    return (dispatch, getState) => {

        dispatch(updateDomesticMarkupToolStart());

        return AGENT_API.updateDomesticMarkupTool(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(updateDomesticMarkupToolSuccess(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(updateDomesticMarkupToolError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

/* Update International markup tool */
export const INTERNATIONAL_MARKUP_TOOL_START = "INTERNATIONAL_MARKUP_TOOL_START"
const updateInternationalMarkupToolStart = () => {
    return{
        type:INTERNATIONAL_MARKUP_TOOL_START
    }
}
export const INTERNATIONAL_MARKUP_TOOL_SUCCESS = "INTERNATIONAL_MARKUP_TOOL_SUCCESS"
const updateInternationalMarkupToolSuccess = (response) => {
    return{
        type:INTERNATIONAL_MARKUP_TOOL_SUCCESS,
        response:response
    }
}
export const INTERNATIONAL_MARKUP_TOOL_ERROR = "INTERNATIONAL_MARKUP_TOOL_ERROR"
const updateInternationalMarkupToolError = (status,message,errors) => {
    return{
        type:INTERNATIONAL_MARKUP_TOOL_ERROR,
        status,
        message,
        errors
    }
}
export function submitInternationalMarkupTool(credentials,successCb,errorCb)
{
    return (dispatch, getState) => {

        dispatch(updateInternationalMarkupToolStart());

        return AGENT_API.updateInternationalMarkupTool(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(updateInternationalMarkupToolSuccess(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(updateInternationalMarkupToolError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}
//============================================= Agent Markup Tool Updates End ==============================================//

//============================================= Agent Calendar Details START ===============================================//
export const CALENDAR_DETAILS_START = 'CALENDAR_DETAILS_START';
const calendarBankDetailStart = () => {
    return {
        type: CALENDAR_DETAILS_START
    };
};

export const CALENDAR_DETAILS_OK = 'CALENDAR_DETAILS_OK';
const calendarDeatilSuccess = (data) => {
    return {
        type: CALENDAR_DETAILS_OK,
        response: data
    }
};

export const CALENDAR_DETAILS_ERROR = 'CALENDAR_DETAILS_ERROR';
const calendarDeatilError = (status, message, errors) => {
    return {
        type: CALENDAR_DETAILS_ERROR,
        status,
        message,
        errors
    }
};

export function getCalendarDetail(params) {
    return (dispatch, getState) => {
        dispatch(calendarBankDetailStart());
        return AGENT_API.calendarDetails(params)
            .then((payload,pay) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(calendarDeatilSuccess(payload.calendar_details,pay));
                } else {
                    dispatch(calendarDeatilError(payload.status, payload.message, payload.errors));
                }
            });
    }
}
//============================================= Agent Calendar Details END =================================================//
//============================================= Get Booking details START ===============================================//
export const BOOKING_DETAILS_START = 'BOOKING_DETAILS_START';
const getBookingDetailsStart = () => {
    return {
        type: BOOKING_DETAILS_START
    };
};

export const BOOKING_DETAILS_OK = 'BOOKING_DETAILS_OK';
const getBookingDetailsSuccess = (data) => {
    return {
        type: BOOKING_DETAILS_OK,
        response: data
    }
};

export const BOOKING_DETAILS_ERROR = 'BOOKING_DETAILS_ERROR';
const getBookingDetailsError = (status, message, errors) => {
    return {
        type: BOOKING_DETAILS_ERROR,
        status,
        message,
        errors
    }
};

export function getBookingDetails(data) {
    return (dispatch, getState) => {
        dispatch(getBookingDetailsStart());
        return AGENT_API.getBookingDetailsApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getBookingDetailsSuccess(payload));
                } else {
                    dispatch(getBookingDetailsError(payload.status, payload.message, payload.errors));
                }
            });
    }
}
//============================================= Agent Calendar Details END =================================================//

// ==============================================REQUEST CANCELLATION =======================================================//

export const REQUEST_CANCELLATION_START = 'REQUEST_CANCELLATION_START';
const getRequestCancellationStart = () => {
    return {
        type: REQUEST_CANCELLATION_START
    };
};

export const REQUEST_CANCELLATION_OK = 'REQUEST_CANCELLATION_OK';
const getRequestCancellationSuccess = (data) => {
    return {
        type: REQUEST_CANCELLATION_OK,
        response: data
    }
};

export const REQUEST_CANCELLATION_ERROR = 'REQUEST_CANCELLATION_ERROR';
const getRequestCancellationError = (status, message, errors) => {
    return {
        type: REQUEST_CANCELLATION_ERROR,
        status,
        message,
        errors
    }
};

export function getRequestCancellationDetails(data) {
    return (dispatch, getState) => {
        dispatch(getRequestCancellationStart());
        return AGENT_API.getMyRequestCancellationApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getRequestCancellationSuccess(payload));
                } else {
                    dispatch(getRequestCancellationError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

// ==============================================REQUEST CANCELLATION END =======================================================//


//============================================= GET user type data ===============================================//
export const USER_TYPE_START = 'USER_TYPE_START';
const getUserTypeStart = () => {
    return {
        type: USER_TYPE_START
    };
};

export const USER_TYPE_OK = 'USER_TYPE_OK';
const getUserTypeOk = (data) => {
    return {
        type: USER_TYPE_OK,
        response: data
    }
};

export const USER_TYPE_ERROR = 'USER_TYPE_ERROR';
const getUserTypeError = (status, message, errors) => {
    return {
        type: USER_TYPE_ERROR,
        status,
        message,
        errors
    }
};

export function getUserType() {
    return (dispatch, getState) => {
        dispatch(getUserTypeStart());
        return AGENT_API.getUserTypeApi()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getUserTypeOk(payload));
                } else {
                    dispatch(getUserTypeError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= GET transaction type data ===============================================//
export const TRANSACTION_TYPE_START = 'TRANSACTION_TYPE_START';
const getTransactionStart = () => {
    return {
        type: TRANSACTION_TYPE_START
    };
};

export const TRANSACTION_TYPE_OK = 'TRANSACTION_TYPE_OK';
const getTransactionOk = (data) => {
    return {
        type: TRANSACTION_TYPE_OK,
        response: data
    }
};

export const TRANSACTION_TYPE_ERROR = 'TRANSACTION_TYPE_ERROR';
const getTransactionError = (status, message, errors) => {
    return {
        type: TRANSACTION_TYPE_ERROR,
        status,
        message,
        errors
    }
};

export function getTransactionType() {
    return (dispatch, getState) => {
        dispatch(getTransactionStart());
        return AGENT_API.getTransactionTypeApi()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getTransactionOk(payload));
                } else {
                    dispatch(getTransactionError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= GET booking status data ===============================================//
export const BOOKING_STATUS_START = 'BOOKING_STATUS_START';
const getBookingStatusStart = () => {
    return {
        type: BOOKING_STATUS_START
    };
};

export const BOOKING_STATUS_OK = 'BOOKING_STATUS_OK';
const getBookingStatusOk = (data) => {
    return {
        type: BOOKING_STATUS_OK,
        response: data
    }
};

export const BOOKING_STATUS_ERROR = 'BOOKING_STATUS_ERROR';
const getBookingStatusError = (status, message, errors) => {
    return {
        type: BOOKING_STATUS_ERROR,
        status,
        message,
        errors
    }
};

export function getBookingStatus(queryString) {
    return (dispatch, getState) => {
        dispatch(getBookingStatusStart());
        return AGENT_API.getBookingStatusApi(queryString)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getBookingStatusOk(payload));
                } else {
                    dispatch(getBookingStatusError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= GET Account Statement data ===============================================//
export const ACCOUNT_STATEMENT_START = 'ACCOUNT_STATEMENT_START';
const getAccountStatementStart = () => {
    return {
        type: ACCOUNT_STATEMENT_START
    };
};

export const ACCOUNT_STATEMENT_OK = 'ACCOUNT_STATEMENT_OK';
const getAccountStatementOk = (data) => {
    return {
        type: ACCOUNT_STATEMENT_OK,
        response: data
    }
};

export const ACCOUNT_STATEMENT_ERROR = 'ACCOUNT_STATEMENT_ERROR';
const getAccountStatementError = (status, message, errors) => {
    return {
        type: ACCOUNT_STATEMENT_ERROR,
        status,
        message,
        errors
    }
};

export function getAccountStatement(queryStr) {
    return (dispatch, getState) => {
        dispatch(getAccountStatementStart());
        return AGENT_API.getAccountStatementApi(queryStr)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getAccountStatementOk(payload));
                } else {
                    dispatch(getAccountStatementError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= GET my booking data ===============================================//
export const MY_BOOKINGS_START = 'MY_BOOKINGS_START';
const getMyBookingsStart = () => {
    return {
        type: MY_BOOKINGS_START
    };
};

export const MY_BOOKINGS_OK = 'MY_BOOKINGS_OK';
const getMyBookingsOk = (data) => {
    return {
        type: MY_BOOKINGS_OK,
        response: data
    }
};

export const MY_BOOKINGS_ERROR = 'MY_BOOKINGS_ERROR';
const getMyBookingsError = (status, message, errors) => {
    return {
        type: MY_BOOKINGS_ERROR,
        status,
        message,
        errors
    }
};

export function getMyBookings(queryString) {
    return (dispatch, getState) => {
        dispatch(getMyBookingsStart());
        return AGENT_API.getMyBookingsApi(queryString)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getMyBookingsOk(payload));
                } else {
                    dispatch(getMyBookingsError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= Cancel booking ===============================================//
export const CANCEL_BOOKING_START = 'CANCEL_BOOKING_START';
const cancelBookingStart = () => {
    return {
        type: CANCEL_BOOKING_START
    };
};

export const CANCEL_BOOKING_OK = 'CANCEL_BOOKING_OK';
const cancelBookingOk = (data) => {
    return {
        type: CANCEL_BOOKING_OK,
        response: data
    }
};

export const CANCEL_BOOKING_ERROR = 'CANCEL_BOOKING_ERROR';
const cancelBookingError = (status, message, errors) => {
    return {
        type: CANCEL_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelBookingStart());
        return AGENT_API.cancelBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Release PNR ===============================================//
export const RELEASE_PNR_START = 'RELEASE_PNR_START';
const releasePnrStart = () => {
    return {
        type: RELEASE_PNR_START
    };
};

export const RELEASE_PNR_OK = 'RELEASE_PNR_OK';
const releasePnrOk = (data) => {
    return {
        type: RELEASE_PNR_OK,
        response: data
    }
};

export const RELEASE_PNR_ERROR = 'RELEASE_PNR_ERROR';
const releasePnrError = (status, message, errors) => {
    return {
        type: RELEASE_PNR_ERROR,
        status,
        message,
        errors
    }
};

export function releasePnr(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(releasePnrStart());
        return AGENT_API.releasePnrApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(releasePnrOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(releasePnrError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}


//*********************************************************Update transaction fee and discount************************************************************/
export const UPDATE_TICKET_START = 'UPDATE_TICKET_START';
export const updateTicketStart = () => {
    return {
        type: UPDATE_TICKET_START
    };
};

export const UPDATE_TICKET_OK = 'UPDATE_TICKET_OK';
export const updateTicketSuccess = (response) => {
    return {
        type: UPDATE_TICKET_OK,
        response: response
    };
};

export const UPDATE_TICKET_ERR = 'UPDATE_TICKET_ERR';
export const updateTicketErr = (response) => {
    return {
        type: UPDATE_TICKET_ERR,
        response: response
    };
};

export const updateTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(updateTicketStart());
        return AGENT_API.updateTicketApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(updateTicketSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(updateTicketErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//*********************************************************Email Ticket************************************************************/
export const EMAIL_TICKET_START = 'EMAIL_TICKET_START';
export const emailTicketStart = () => {
    return {
        type: EMAIL_TICKET_START
    };
};

export const EMAIL_TICKET_OK = 'EMAIL_TICKET_OK';
export const emailTicketSuccess = (response) => {
    return {
        type: EMAIL_TICKET_OK,
        response: response
    };
};

export const EMAIL_TICKET_ERR = 'EMAIL_TICKET_ERR';
export const emailTicketErr = (response) => {
    return {
        type: EMAIL_TICKET_ERR,
        response: response
    };
};

export const emailTicket = (data) => {
    return (dispatch, getState) => {
        dispatch(emailTicketStart());
        return AGENT_API.emailTicketApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailTicketSuccess(response.message));
                } else{
                    dispatch(emailTicketErr(response));
                }
            });
    }
};

//*********************************************************Email offline Ticket************************************************************/
export const EMAIL_OFFLINE_TICKET_START = 'EMAIL_OFFLINE_TICKET_START';
export const emailOfflineTicketStart = () => {
    return {
        type: EMAIL_OFFLINE_TICKET_START
    };
};

export const EMAIL_OFFLINE_TICKET_OK = 'EMAIL_OFFLINE_TICKET_OK';
export const emailOfflineTicketSuccess = (response) => {
    return {
        type: EMAIL_OFFLINE_TICKET_OK,
        response: response
    };
};

export const EMAIL_OFFLINE_TICKET_ERR = 'EMAIL_OFFLINE_TICKET_ERR';
export const emailOfflineTicketErr = (response) => {
    return {
        type: EMAIL_OFFLINE_TICKET_ERR,
        response: response
    };
};

export const emailOfflineTicket = (data) => {
    return (dispatch, getState) => {
        dispatch(emailOfflineTicketStart());
        return AGENT_API.emailOfflineTicketApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailOfflineTicketSuccess(response.message));
                } else{
                    dispatch(emailOfflineTicketErr(response));
                }
            });
    }
};

//============================================= Cancel Refund ===============================================//
export const CANCEL_REFUND_START = 'CANCEL_REFUND_START';
const cancelRefundStart = () => {
    return {
        type: CANCEL_REFUND_START
    };
};

export const CANCEL_REFUND_OK = 'CANCEL_REFUND_OK';
const cancelRefundOk = (data) => {
    return {
        type: CANCEL_REFUND_OK,
        response: data
    }
};

export const CANCEL_REFUND_ERR = 'CANCEL_REFUND_ERR';
const cancelRefundError = (status, message, errors) => {
    return {
        type: CANCEL_REFUND_ERR,
        status,
        message,
        errors
    }
};

export function cancelRefund(data) {
    return (dispatch, getState) => {
        dispatch(cancelRefundStart());
        return AGENT_API.cancelRefundApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelRefundOk(payload.first_result));
                    //successCb && successCb(payload);
                } else {
                    dispatch(cancelRefundError(payload.status, payload.message, payload.errors));
                    //errorCb && errorCb(payload);
                }
            });
    }
}

//============================================= Get booking report data ===============================================//
export const BOOKING_REPORT_START = 'BOOKING_REPORT_START';
const bookingReportStart = () => {
    return {
        type: BOOKING_REPORT_START
    };
};

export const BOOKING_REPORT_OK = 'BOOKING_REPORT_OK';
const bookingReportOk = (data) => {
    return {
        type: BOOKING_REPORT_OK,
        response: data
    }
};

export const BOOKING_REPORT_ERR = 'BOOKING_REPORT_ERR';
const bookingReportError = (status, message, errors) => {
    return {
        type: BOOKING_REPORT_ERR,
        status,
        message,
        errors
    }
};

export function bookingReport(data) {
    return (dispatch, getState) => {
        dispatch(bookingReportStart());
        return AGENT_API.bookingReportApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(bookingReportOk(payload));
                } else {
                    dispatch(bookingReportError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//*********************************************************Email Invoice************************************************************/
export const EMAIL_INVOICE_START = 'EMAIL_INVOICE_START';
export const emailInvoiceStart = () => {
    return {
        type: EMAIL_INVOICE_START
    };
};

export const EMAIL_INVOICE_OK = 'EMAIL_INVOICE_OK';
export const emailInvoiceSuccess = (response) => {
    return {
        type: EMAIL_INVOICE_OK,
        response: response
    };
};

export const EMAIL_INVOICE_ERR = 'EMAIL_INVOICE_ERR';
export const emailInvoiceErr = (response) => {
    return {
        type: EMAIL_INVOICE_ERR,
        response: response
    };
};

export const emailInvoice = (data) => {
    return (dispatch, getState) => {
        dispatch(emailInvoiceStart());
        return AGENT_API.emailInvoiceApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailInvoiceSuccess(response.message));
                } else{
                    dispatch(emailInvoiceErr(response));
                }
            });
    }
};

//*********************************************************Email offline booking Invoice************************************************************/
export const EMAIL_OFFLINE_INVOICE_START = 'EMAIL_OFFLINE_INVOICE_START';
export const emailOfflineInvoiceStart = () => {
    return {
        type: EMAIL_OFFLINE_INVOICE_START
    };
};

export const EMAIL_OFFLINE_INVOICE_OK = 'EMAIL_OFFLINE_INVOICE_OK';
export const emailOfflineInvoiceSuccess = (response) => {
    return {
        type: EMAIL_OFFLINE_INVOICE_OK,
        response: response
    };
};

export const EMAIL_OFFLINE_INVOICE_ERR = 'EMAIL_OFFLINE_INVOICE_ERR';
export const emailOfflineInvoiceErr = (response) => {
    return {
        type: EMAIL_OFFLINE_INVOICE_ERR,
        response: response
    };
};

export const emailOfflineInvoice = (data) => {
    return (dispatch, getState) => {
        dispatch(emailOfflineInvoiceStart());
        return AGENT_API.emailOfflineInvoiceApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailOfflineInvoiceSuccess(response.message));
                } else{
                    dispatch(emailOfflineInvoiceErr(response));
                }
            });
    }
};
//=========================================== Booking INFO ============================================
export const BOOKING_INFO_START = 'BOOKING_INFO_START';
const bookinfInfoFetch = () => {
    return {
        type: BOOKING_INFO_START
    };
};

export const BOOKING_INFO_OK = 'BOOKING_INFO_OK';
const bookingInfoSuccess = (response) => {
    return {
        type: BOOKING_INFO_OK,
        response: response
    }
};

export const BOOKING_INFO_ERR = 'BOOKING_INFO_ERR';
const bookingInfoError = (status, message, errors) => {
    return {
        type: DEPOSIT_REQUEST_ERR,
        status,
        message,
        errors
    }
};

export function bookingInfoAction(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(bookinfInfoFetch());
        return AGENT_API.getBookingInfo(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(bookingInfoSuccess(payload.booking_result));
                    successCb && successCb(payload.booking_result);

                } else {
                    dispatch(bookingInfoError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Agent HOTEL Markup Tool Updates Statrt ==============================================//
export const GET_HOTEL_MARKUP_TOOL_START = "GET_HOTEL_MARKUP_TOOL_START";
const getHotelMarkupStart = () => {
    return{
        type:GET_HOTEL_MARKUP_TOOL_START
    }
}

export const GET_HOTEL_MARKUP_TOOL_OK = "GET_HOTEL_MARKUP_TOOL_OK"
const getHotelMarkupSuccess = (response) => {
    return{
        type:GET_HOTEL_MARKUP_TOOL_OK,
        response:response
    }
}

export const GET_HOTEL_MARKUP_TOOL_ERR = "GET_HOTEL_MARKUP_TOOL_ERR"
const getHotelMarkupError = (error) => {
    return{
        type:GET_HOTEL_MARKUP_TOOL_ERR,
        error:error
    }
}

export function getHotelAgentMarkUp(){
    return (dispatch,getState) => {
        dispatch(getHotelMarkupStart());

        return AGENT_API.getHotelMarkupInfo()
        .then((payload) => {
            if(payload.status === "ok"){
                dispatch(getHotelMarkupSuccess(payload.agent_markups))
            }else{
                dispatch(getHotelMarkupError(payload))
            }
        })
    }
}

//============================================= SUBMIT Agent HOTEL Markup Tool Updates Statrt ==============================================//
export const UPDATE_HOTEL_MARKUP_START = "UPDATE_HOTEL_MARKUP_START";
const submitHotelMarkupStart = () => {
    return{
        type:UPDATE_HOTEL_MARKUP_START
    }
}

export const UPDATE_HOTEL_MARKUP_SUCCESS = "UPDATE_HOTEL_MARKUP_SUCCESS"
const submitHotelMarkupSuccess = (response) => {
    return{
        type:UPDATE_HOTEL_MARKUP_SUCCESS,
        response:response
    }
}

export const UPDATE_HOTEL_MARKUP_ERR = "UPDATE_HOTEL_MARKUP_ERR"
const submitHotelMarkupError = (error) => {
    return{
        type:UPDATE_HOTEL_MARKUP_ERR,
        error:error
    }
}

export function submitHotelAgentMarkUp(credentials,successCb,errorCb){
    return (dispatch,getState) => {
        dispatch(submitHotelMarkupStart());

        return AGENT_API.submitHotelMarkupInfo(credentials)
        .then((payload) => {
            if(payload.status === "ok"){
                dispatch(submitHotelMarkupSuccess(payload.status))
                successCb && successCb();
            }else{
                dispatch(submitHotelMarkupError(payload.status, payload.message, payload.errors))
                errorCb && errorCb();
            }
        })
    }
}



//============================================= Getting Hotel Invoice Information ==============================================//
export const HOTEL_INVOICE_START = 'HOTEL_INVOICE_START';
const hotelInfoFetch = () => {
    return {
        type: HOTEL_INVOICE_START
    };
};

export const HOTEL_INVOICE_OK = 'HOTEL_INVOICE_OK';
const hotelInfoSuccess = (response) => {
    return {
        type: HOTEL_INVOICE_OK,
        response: response
    }
};

export const HOTEL_INVOICE_ERR = 'HOTEL_INVOICE_ERR';
const hotelInfoError = (status, message, errors) => {
    return {
        type: HOTEL_INVOICE_ERR,
        status,
        message,
        errors
    }
};

export function hotelInvoiceAction(data) {
    return (dispatch, getState) => {
        dispatch(hotelInfoFetch());
        return AGENT_API.getHotelInvoiceInfo(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(hotelInfoSuccess(payload));

                } else {
                    dispatch(hotelInfoError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= GET my hotel booking data ===============================================//
export const MY_HOTEL_BOOKINGS_START = 'MY_HOTEL_BOOKINGS_START';
const getMyHotelBookingsStart = () => {
    return {
        type: MY_HOTEL_BOOKINGS_START
    };
};

export const MY_HOTEL_BOOKINGS_OK = 'MY_HOTEL_BOOKINGS_OK';
const getMyHotelBookingsOk = (data) => {
    return {
        type: MY_HOTEL_BOOKINGS_OK,
        response: data
    }
};

export const MY_HOTEL_BOOKINGS_ERROR = 'MY_HOTEL_BOOKINGS_ERROR';
const getMyHotelBookingsError = (status, message, errors) => {
    return {
        type: MY_HOTEL_BOOKINGS_ERROR,
        status,
        message,
        errors
    }
};

export function getMyHotelBookings(queryString) {
    return (dispatch, getState) => {
        dispatch(getMyHotelBookingsStart());
        return AGENT_API.getMyHotelBookingsApi(queryString)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getMyHotelBookingsOk(payload));
                } else {
                    dispatch(getMyHotelBookingsError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//============================================= Cancel Hotel booking ===============================================//
export const CANCEL_HOTEL_BOOKING_START = 'CANCEL_HOTEL_BOOKING_START';
const cancelHotelBookingStart = () => {
    return {
        type: CANCEL_HOTEL_BOOKING_START
    };
};

export const CANCEL_HOTEL_BOOKING_OK = 'CANCEL_HOTEL_BOOKING_OK';
const cancelHotelBookingOk = (data) => {
    return {
        type: CANCEL_HOTEL_BOOKING_OK,
        response: data
    }
};

export const CANCEL_HOTEL_BOOKING_ERROR = 'CANCEL_HOTEL_BOOKING_ERROR';
const cancelHotelBookingError = (status, message, errors) => {
    return {
        type: CANCEL_HOTEL_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelHotelBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelHotelBookingStart());
        return AGENT_API.cancelHotelBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelHotelBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelHotelBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//*********************************************************Email HOTEL Invoice************************************************************/
export const EMAIL_HOTEL_INVOICE_START = 'EMAIL_HOTEL_INVOICE_START';
export const emailHotelInvoiceStart = () => {
    return {
        type: EMAIL_HOTEL_INVOICE_START
    };
};

export const EMAIL_HOTEL_INVOICE_OK = 'EMAIL_HOTEL_INVOICE_OK';
export const emailHotelInvoiceSuccess = (response) => {
    return {
        type: EMAIL_HOTEL_INVOICE_OK,
        response: response
    };
};

export const EMAIL_HOTEL_INVOICE_ERR = 'EMAIL_HOTEL_INVOICE_ERR';
export const emailHotelInvoiceErr = (response) => {
    return {
        type: EMAIL_HOTEL_INVOICE_ERR,
        response: response
    };
};

export const emailHotelInvoice = (data) => {
    return (dispatch, getState) => {
        dispatch(emailHotelInvoiceStart());
        return AGENT_API.emailHotelInvoiceApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailHotelInvoiceSuccess(response.message));
                } else{
                    dispatch(emailHotelInvoiceErr(response));
                }
            });
    }
};

//============================================= Cancel Refund ===============================================//
export const CANCEL_HOTEL_REFUND_START = 'CANCEL_HOTEL_REFUND_START';
const cancelHotelRefundStart = () => {
    return {
        type: CANCEL_HOTEL_REFUND_START
    };
};

export const CANCEL_HOTEL_REFUND_OK = 'CANCEL_HOTEL_REFUND_OK';
const cancelHotelRefundOk = (data) => {
    return {
        type: CANCEL_HOTEL_REFUND_OK,
        response: data
    }
};

export const CANCEL_HOTEL_REFUND_ERR = 'CANCEL_HOTEL_REFUND_ERR';
const cancelHOtelRefundError = (status, message, errors) => {
    return {
        type: CANCEL_HOTEL_REFUND_ERR,
        status,
        message,
        errors
    }
};

export function cancelHotelRefund(data) {
    return (dispatch, getState) => {
        dispatch(cancelHotelRefundStart());
        return AGENT_API.cancelHotelRefundApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelHotelRefundOk(payload.cancel_details)); 
                    //successCb && successCb(payload);
                } else {
                    dispatch(cancelHOtelRefundError(payload.status, payload.message, payload.errors));
                    //errorCb && errorCb(payload);
                }
            });
    }
}

//*********************************************************Email flight credit note************************************************************/
export const EMAIL_FLIGHT_CREDIT_NOTE_START = 'EMAIL_FLIGHT_CREDIT_NOTE_START';
export const emailFlightCreditNoteStart = () => {
    return {
        type: EMAIL_FLIGHT_CREDIT_NOTE_START
    };
};

export const EMAIL_FLIGHT_CREDIT_NOTE_OK = 'EMAIL_FLIGHT_CREDIT_NOTE_OK';
export const emailFlightCreditNoteSuccess = (response) => {
    return {
        type: EMAIL_FLIGHT_CREDIT_NOTE_OK,
        response: response
    };
};

export const EMAIL_FLIGHT_CREDIT_NOTE_ERR = 'EMAIL_FLIGHT_CREDIT_NOTE_ERR';
export const emailFlightCreditNoteErr = (response) => {
    return {
        type: EMAIL_FLIGHT_CREDIT_NOTE_ERR,
        response: response
    };
};

export const emailFlightCreditNote = (data) => {
    return (dispatch, getState) => {
        dispatch(emailFlightCreditNoteStart());
        return AGENT_API.emailFlightCreditNoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailFlightCreditNoteSuccess(response.message));
                } else{
                    dispatch(emailFlightCreditNoteErr(response));
                }
            });
    }
};

//*********************************************************Email hotel credit note************************************************************/
export const EMAIL_HOTEL_CREDIT_NOTE_START = 'EMAIL_HOTEL_CREDIT_NOTE_START';
export const emailHotelCreditNoteStart = () => {
    return {
        type: EMAIL_HOTEL_CREDIT_NOTE_START
    };
};

export const EMAIL_HOTEL_CREDIT_NOTE_OK = 'EMAIL_HOTEL_CREDIT_NOTE_OK';
export const emailHotelCreditNoteSuccess = (response) => {
    return {
        type: EMAIL_HOTEL_CREDIT_NOTE_OK,
        response: response
    };
};

export const EMAIL_HOTEL_CREDIT_NOTE_ERR = 'EMAIL_HOTEL_CREDIT_NOTE_ERR';
export const emailHotelCreditNoteErr = (response) => {
    return {
        type: EMAIL_HOTEL_CREDIT_NOTE_ERR,
        response: response
    };
};

export const emailHotelCreditNote = (data) => {
    return (dispatch, getState) => {
        dispatch(emailHotelCreditNoteStart());
        return AGENT_API.emailHotelCreditNoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(emailHotelCreditNoteSuccess(response.message));
                } else{
                    dispatch(emailHotelCreditNoteErr(response));
                }
            });
    }
};

//*********************************************************Get credit note list for partial cancellation************************************************************/
export const PARTIAL_CANCELLATIONS_START = 'PARTIAL_CANCELLATIONS_START';
export const partialCancellationStart = () => {
    return {
        type: PARTIAL_CANCELLATIONS_START
    };
};

export const PARTIAL_CANCELLATIONS_OK = 'PARTIAL_CANCELLATIONS_OK';
export const partialCancellationSuccess = (response) => {
    return {
        type: PARTIAL_CANCELLATIONS_OK,
        response: response
    };
};

export const PARTIAL_CANCELLATIONS_ERR = 'PARTIAL_CANCELLATIONS_ERR';
export const partialCancellationErr = (response) => {
    return {
        type: PARTIAL_CANCELLATIONS_ERR,
        response: response
    };
};

export const partialCancellationDetail = (data) => {
    return (dispatch, getState) => {
        dispatch(partialCancellationStart());
        return AGENT_API.partialCancellationApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(partialCancellationSuccess(response));
                } else{
                    dispatch(partialCancellationErr(response));
                }
            });
    }
};
//=========================== for top up API =========================================
export const TOP_UP_PAYMENT_INIT_START = 'TOP_UP_PAYMENT_INIT_START';
const topUpPaymentInitFetch = () => {
    return {
        type: TOP_UP_PAYMENT_INIT_START
    };
};

export const TOP_UP_PAYMENT_INIT_OK = 'TOP_UP_PAYMENT_INIT_OK';
const topUpPaymentInitFetchSuccess = (data) => {
    return {
        type: TOP_UP_PAYMENT_INIT_OK,
        response: data
    }
};

export const TOP_UP_PAYMENT_INIT_ERR = 'TOP_UP_PAYMENT_INIT_ERR';
const topUpPaymentInitFetchError = (status, message, errors) => {
    return {
        type: TOP_UP_PAYMENT_INIT_ERR,
        status,
        message,
        errors
    }
};

export function topUpPaymentInitAction(credentials,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(topUpPaymentInitFetch());

        return AGENT_API.getTopUpApi(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(topUpPaymentInitFetchSuccess(payload.agent_info));
                    successCb(payload);
                } else {
                    dispatch(topUpPaymentInitFetchError(payload.status, payload.message, payload.errors));
                    errorCb(payload);
                }
            });
    }
}

//=========================== for top up payment success API =========================================
export const TOP_UP_PAYMENT_RESPONSE_START = 'TOP_UP_PAYMENT_RESPONSE_START';
const topUpPaymentResponseFetch = () => {
    return {
        type: TOP_UP_PAYMENT_RESPONSE_START
    };
};

export const TOP_UP_PAYMENT_RESPONSE_OK = 'TOP_UP_PAYMENT_RESPONSE_OK';
const topUpPaymentResponseFetchSuccess = (data) => {
    return {
        type: TOP_UP_PAYMENT_RESPONSE_OK,
        response: data
    }
};

export const TOP_UP_PAYMENT_RESPONSE_ERR = 'TOP_UP_PAYMENT_RESPONSE_ERR';
const topUpPaymentResponseFetchError = (status, message, errors) => {
    return {
        type: TOP_UP_PAYMENT_RESPONSE_ERR,
        status,
        message,
        errors
    }
};

export function topUpPaymentResponseAction(credentials,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(topUpPaymentResponseFetch());

        return AGENT_API.getTopUpPaymentApi(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(topUpPaymentResponseFetchSuccess(payload.agent_info));
                    successCb(payload);
                } else {
                    dispatch(topUpPaymentResponseFetchError(payload.status, payload.message, payload.errors));
                    errorCb(payload);
                }
            });
    }
}

//=========================== for fetch all topup API =========================================
export const ALL_TOP_UP_FETCH_START = 'ALL_TOP_UP_FETCH_START';
const allTopUpFetch = () => {
    return {
        type: ALL_TOP_UP_FETCH_START
    };
};

export const ALL_TOP_UP_FETCH_OK = 'ALL_TOP_UP_FETCH_OK';
const allTopupFetchSuccess = (data) => {
    return {
        type: ALL_TOP_UP_FETCH_OK,
        response: data
    }
};

export const ALL_TOP_UP_FETCH_ERR = 'ALL_TOP_UP_FETCH_ERR';
const allTopupFetchError = (status, message, errors) => {
    return {
        type: ALL_TOP_UP_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function allTopupFetchAction(limitOffset,searchValues,successCb,errorCb) {
    return (dispatch, getState) => {

        dispatch(allTopUpFetch());

        return AGENT_API.getAllTopUpApi(limitOffset,searchValues)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(allTopupFetchSuccess(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(allTopupFetchError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

//============================================= Cancel Train booking ===============================================//
export const CANCEL_TRAIN_BOOKING_START = 'CANCEL_TRAIN_BOOKING_START';
const cancelTrainBookingStart = () => {
    return {
        type: CANCEL_TRAIN_BOOKING_START
    };
};

export const CANCEL_TRAIN_BOOKING_OK = 'CANCEL_TRAIN_BOOKING_OK';
const cancelTrainBookingOk = (data) => {
    return {
        type: CANCEL_TRAIN_BOOKING_OK,
        response: data
    }
};

export const CANCEL_TRAIN_BOOKING_ERROR = 'CANCEL_TRAIN_BOOKING_ERROR';
const cancelTrainBookingError = (status, message, errors) => {
    return {
        type: CANCEL_TRAIN_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelTrainBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelTrainBookingStart());
        return AGENT_API.cancelTrainBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelTrainBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelTrainBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Release TripJack Hold Cancel ===============================================//
export const RELEASE_TRIPJACK_START = 'RELEASE_TRIPJACK_START';
const releaseTripJackStart = () => {
    return {
        type: RELEASE_TRIPJACK_START
    };
};

export const RELEASE_TRIPJACK_OK = 'RELEASE_TRIPJACK_OK';
const releaseTripJackOk = (data) => {
    return {
        type: RELEASE_TRIPJACK_OK,
        response: data
    }
};

export const RELEASE_TRIPJACK_ERROR = 'RELEASE_TRIPJACK_ERROR';
const releaseTripJackError = (status, message, errors) => {
    return {
        type: RELEASE_TRIPJACK_ERROR,
        status,
        message,
        errors
    }
};

export function releaseTripJack(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(releaseTripJackStart());
        return AGENT_API.cancelHoldBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(releaseTripJackOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(releaseTripJackError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Cancel Tripjack booking ===============================================//
export const CANCEL_TRIPJACK_BOOKING_START = 'CANCEL_TRIPJACK_BOOKING_START';
const cancelTripjackBookingStart = () => {
    return {
        type: CANCEL_TRIPJACK_BOOKING_START
    };
};

export const CANCEL_TRIPJACK_BOOKING_OK = 'CANCEL_TRIPJACK_BOOKING_OK';
const cancelTripjackBookingOk = (data) => {
    return {
        type: CANCEL_TRIPJACK_BOOKING_OK,
        response: data
    }
};

export const CANCEL_TRIPJACK_BOOKING_ERROR = 'CANCEL_TRIPJACK_BOOKING_ERROR';
const cancelTripjackBookingError = (status, message, errors) => {
    return {
        type: CANCEL_TRIPJACK_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelTripjackBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelTripjackBookingStart());
        return AGENT_API.cancelTripjackBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelTripjackBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelTripjackBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Cancel kafila booking ===============================================//
export const CANCEL_KAFILA_BOOKING_START = 'CANCEL_KAFILA_BOOKING_START';
const cancelKafilaBookingStart = () => {
    return {
        type: CANCEL_KAFILA_BOOKING_START
    };
};

export const CANCEL_KAFILA_BOOKING_OK = 'CANCEL_KAFILA_BOOKING_OK';
const cancelKafilaBookingOk = (data) => {
    return {
        type: CANCEL_KAFILA_BOOKING_OK,
        response: data
    }
};

export const CANCEL_KAFILA_BOOKING_ERROR = 'CANCEL_KAFILA_BOOKING_ERROR';
const cancelKafilaError = (status, message, errors) => {
    return {
        type: CANCEL_KAFILA_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelKafilaBooking(data,successCb,errorCb,pnrCancelScb) {
    return (dispatch, getState) => {
        dispatch(cancelKafilaBookingStart());
        return AGENT_API.cancelKafilaBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    successCb && successCb();
                    dispatch(cancelKafilaBookingOk(payload));
                } else {
                    dispatch(cancelKafilaError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Cancel Hotel booking ===============================================//
export const CANCEL_TRIPJACK_HOTEL_BOOKING_START = 'CANCEL_TRIPJACK_HOTEL_BOOKING_START';
const cancelTripjackHotelBookingStart = () => {
    return {
        type: CANCEL_TRIPJACK_HOTEL_BOOKING_START
    };
};

export const CANCEL_TRIPJACK_HOTEL_BOOKING_OK = 'CANCEL_TRIPJACK_HOTEL_BOOKING_OK';
const cancelTripjackHotelBookingOk = (data) => {
    return {
        type: CANCEL_TRIPJACK_HOTEL_BOOKING_OK,
        response: data
    }
};

export const CANCEL_TRIPJACK_HOTEL_BOOKING_ERROR = 'CANCEL_TRIPJACK_HOTEL_BOOKING_ERROR';
const cancelTripjackHotelBookingError = (status, message, errors) => {
    return {
        type: CANCEL_TRIPJACK_HOTEL_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelTripjackHotelBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelTripjackHotelBookingStart());
        return AGENT_API.cancelTripjackHotelBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelTripjackHotelBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelTripjackHotelBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}
//============================================= Release TripJack Hold Cancel hotels ===============================================//
export const RELEASE_TRIPJACK_HOTEL_START = 'RELEASE_TRIPJACK_HOTEL_START';
const releaseHotelTripJackStart = () => {
    return {
        type: RELEASE_TRIPJACK_HOTEL_START
    };
};

export const RELEASE_HOTEL_TRIPJACK_OK = 'RELEASE_HOTEL_TRIPJACK_OK';
const releaseHotelTripJackOk = (data) => {
    return {
        type: RELEASE_HOTEL_TRIPJACK_OK,
        response: data
    }
};

export const RELEASE_HOTEL_TRIPJACK_ERROR = 'RELEASE_HOTEL_TRIPJACK_ERROR';
const releaseHotelTripJackError = (status, message, errors) => {
    return {
        type: RELEASE_HOTEL_TRIPJACK_ERROR,
        status,
        message,
        errors
    }
};

export function releaseHotelTripJack(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(releaseHotelTripJackStart());
        return AGENT_API.releaseHoldBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(releaseHotelTripJackOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(releaseHotelTripJackError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

//============================================= Cancel booking ===============================================//
export const CANCEL_RIYA_BOOKING_START = 'CANCEL_RIYA_BOOKING_START';
const cancelRiyaBookingStart = () => {
    return {
        type: CANCEL_RIYA_BOOKING_START
    };
};

export const CANCEL_RIYA_BOOKING_OK = 'CANCEL_RIYA_BOOKING_OK';
const cancelRiyaBookingOk = (data) => {
    return {
        type: CANCEL_RIYA_BOOKING_OK,
        response: data
    }
};

export const CANCEL_RIYA_BOOKING_ERROR = 'CANCEL_RIYA_BOOKING_ERROR';
const cancelRiyaBookingError = (status, message, errors) => {
    return {
        type: CANCEL_RIYA_BOOKING_ERROR,
        status,
        message,
        errors
    }
};

export function cancelRiyaBooking(data,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(cancelRiyaBookingStart());
        return AGENT_API.cancelRiyaBookingApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(cancelRiyaBookingOk(payload));
                    successCb && successCb();
                } else {
                    dispatch(cancelRiyaBookingError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}