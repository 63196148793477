import {
    VERIFY_EMAIL_START,
    VERIFY_EMAIL_OK,
    VERIFY_EMAIL_ERR,
    RESET_PASSWORD_START,
    RESET_PASSWORD_OK,
    RESET_PASSWORD_ERR,
    COMPONENT_CLASS
} from '../../actions/shared/resetPasswordAction';

export const initialSessionState = {
    error: null,
    emailVerified: false,
    passwordChanged: false,
    loading: false,
    flipCardClass: ''
};


export function resetPassword(state = initialSessionState, action) {
    switch (action.type) {
        
        case VERIFY_EMAIL_START:
            return Object.assign({}, state, {
                error: '',
                emailVerified: false,
                loading: true,
            });

        case VERIFY_EMAIL_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return Object.assign({}, state, {
                error: '',
                emailVerified: true,
                loading: false,
            });

        case COMPONENT_CLASS:
            /* set session as new object, to make sure no old session info lingers ... */
            return Object.assign({}, state, {
                flipCardClass: action.flipCardClass
        });

        case VERIFY_EMAIL_ERR:
            /* reset session to initial state with the error */
            return Object.assign({}, initialSessionState, {
                error: action.message,
                emailVerified: false,
                loading: false,
            });

        case RESET_PASSWORD_START:
            return Object.assign({}, state, {
                error: '',
                loading: true,
            });

        case RESET_PASSWORD_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return Object.assign({}, state, {
                error: '',
                passwordChanged: true,
                loading: false,
            });

        case RESET_PASSWORD_ERR:
            /* reset session to initial state with the error */
            return Object.assign({}, initialSessionState, {
                error: action.message,
                loading: false,
            });

        default:
            return state;
    }
}


