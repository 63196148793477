/* external dependencies */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from "yup";

class EmailVerifyForm extends React.Component {
    enterPressed = (formikProps, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if(code === 13) { 
            formikProps.handleSubmit();
        } 
    }

    emailValidator = yup.object()
        .shape({
            email: yup.string()
                .email('Invalid email')
                .required('Email is required')
        });
    
    handleCancelClick = () => {
        this.props.handleComponentClass('');
    }

    hideAlertBox = () => {
        // onClick={() =>self.hideAlertBox()}
        const alertDivs = document.getElementsByClassName('alert');
        for(let i = 0; i < alertDivs.length; i++) {
            alertDivs[i].setAttribute('class', 'hide-alert');
        }
    }

    renderForm = (formikProps) => {
        const self = this;
        const {handleSubmit, isSubmitting, isValid, errors, touched} = formikProps;
        const {errorMsg, resetPassword} = this.props;
        
        return (
            <div className="inner-login">
                <h2>Reset Password</h2>
                {   errors.email && touched.email &&
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errors.email}</strong>
                    <i className="fa fa-times" aria-hidden="true" onClick={() =>self.hideAlertBox()}></i></div>
                }
                {   errorMsg && <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong>
                    <i className="fa fa-times" aria-hidden="true"></i></div>
                }
                {   resetPassword.emailVerified &&
                    <div className="alert alert-success"><strong> <i className="fa fa-check-circle"></i>
                    Please check your mail for OTP.</strong><i className="fa fa-times" aria-hidden="true"></i></div>
                }

                <div className="login_form">
                    <p>For your security we need to verify your identity. Please enter your email id below we can send an OTP on.</p>
                    <div className="form-group">
                        <Field
                            type="text"
                            name="email"
                            className="form-control" required
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                        />
                        <i className="material-icons">email</i>
                        <label className="control-label">Email</label>
                    </div>
                </div>
                <div className="login-btn">
                    <button className="btn btn-dark" onClick={this.handleCancelClick}>Cancel</button>
                    <button
                        type="submit"
                        className="btn btn-green loading-btn"
                        disabled={isSubmitting || !isValid} 
                        onClick={handleSubmit}>Continue
                        {
                            this.props.resetPassword.loading
                            ?
                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span><img
                                    src="assets/images/flight-continue.svg" alt=""/></label></div>
                            :''
                        }
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const flipCardClass = 'show-reset-pass';
        return (
            <div className="login-send-otp">
                <div className="login-outer">
                    <Formik
                        initialValues={{email: ''}}
                        onSubmit={(values, actions) => this.props.handleEmailSubmit(values, actions, flipCardClass)}
                        validationSchema={this.emailValidator}
                        render={this.renderForm}
                    /> 
                </div>
            </div>
        );
    }
}

export default EmailVerifyForm;