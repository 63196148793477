import React, { Component } from 'react';
import AccountStatement from '../../components/agent/AgentAccountStatementComponent';
import { connect } from 'react-redux';
import { getAccountStatement, myDepositRequestAction, allTopupFetchAction, myAccountAction } from '../../actions/agentAction';
import { Formik } from "formik";
import { logoutAction } from '../../actions/shared/sessionAction';
import moment from 'moment';


class AgentAccountStatements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            diffDays: null
            // start_date  : null,
            // end_date    : null
        };
    }

    componentDidMount() {

        const year = new Date(moment()).getFullYear();
        const month = new Date(moment()).getMonth() + 1;
        const queryStr = '?month='+month+'&year='+year;
        this.props.getAccountStatement(queryStr);
        this.props.myDepositRequestAction();
        this.props.allTopupFetchAction();
    }

    updateSearchResult = (createdAt, date) => {
        
        let startDate	= new Date(createdAt);
		let endDate	    = new Date(date);
		
		let diffTime	= Math.abs(startDate - endDate);
        let diffDays    = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.setState({
            diffDays : diffDays
        });

		if(diffDays > 31)
		{
            this.setState({error: 'You can search transaction of maximum 31 days.'});
		}
        else
        {
            this.setState({error: null}, function()
            {
                let startYear       = startDate.getFullYear();
                let endYear         = endDate.getFullYear();
                let startMonth      = startDate.getMonth() + 1;
                let endMonth        = endDate.getMonth() + 1;

                let monthStr        = (startMonth !== endMonth) ? startMonth+','+endMonth : startMonth;
                let yearStr         = (startYear !== endYear) ? startYear+','+endYear : startYear;
                const queryStr      = '?month='+monthStr + '&year='+yearStr;
              
                this.props.getAccountStatement(queryStr);
            });
        }
    }


    render() {
        // const initialValues = {
        //     start_date : "",
        //     end_date   : ""
        // };
        return (
            <div>
                <Formik
                    // initialValues={initialValues} //Set initial values of fields to blank
                    // onSubmit={(values) => {
                    //     this.getSearchingData(values);
                    // }}
                    render={formikProps =>
                        <AccountStatement {...this.props} {...formikProps} errorMsg={this.state.error} diffDays={this.state.diffDays} startDate={this.state.start_date} endDate={this.state.end_date} updateSearchResult={this.updateSearchResult} />
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse : state.shared.productResponse,
        pagesResponse   : state.shared.pagesResponse,
        accountStatementDetails : state.myAccount.accountStatementDetails,
        agentProductAccess     : state.shared.agentProductAccess,
        settingResponse: state.shared.settingResponse,
        depositRequestResponse : state.myAccount.depositRequestResponse,
        allTopUpInfo : state.myAccount.allTopUpInfo
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAccountStatement: (queryStr) => dispatch(getAccountStatement(queryStr)),
        logoutAction: () => dispatch(logoutAction()),
        myDepositRequestAction: () => dispatch(myDepositRequestAction('','')), //get deposit request
        allTopupFetchAction: () => dispatch(allTopupFetchAction('','')), //get deposit request
        myAccountAction : () => dispatch(myAccountAction())
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(AgentAccountStatements)
