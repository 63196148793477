/* external dependencies */
import React from 'react';
// import Link from "react-router-dom/es/Link";
import * as Yup from "yup";

class ContactUsComponent extends React.Component {

    renderContactForm = (formikProps) => {

        return (
            <React.Fragment>
                {/* <div className="form-row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Name</label>
                            <Field className="form-control gray-field" placeholder="Name" type="text" name="name" value={values.name} onChange={handleChange} />
                            {touched.name && errors.name && <span className="error-msg">{errors.name}</span>}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Email Address</label>
                            <Field className="form-control gray-field" placeholder="Email" type="email" name="email" value={values.email} onChange={handleChange} />
                            {touched.email && errors.email && <span className="error-msg">{errors.email}</span>}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Contact Number</label>
                            <Field className="form-control gray-field" placeholder="Contact Number" type="tel" name="contact_no" value={values.contact_no} onChange={handleChange} />
                            {touched.contact_no && errors.contact_no && <span className="error-msg">{errors.contact_no}</span>}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Message</label>
                            <textarea className="form-control gray-field" placeholder="Your Message" name="message" value={values.message} onChange={handleChange} />
                            {touched.message && errors.message && <span className="error-msg">{errors.message}</span>}
                        </div>
                    </div>
                </div>
                <div className="btn-block">
                    <button type="submit" onClick={handleSubmit} className="btn btn-blue">Submit</button>
                </div> */}
            </React.Fragment>
        );
    }

    validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        email: Yup.string()
            .email("Enter a valid email")
            .required('Email is required'),
        contact_no: Yup.string()
            .required('Contact number is required'),
        message: Yup.string()
            .required('Message is required')
    });

    render() {
        let {settingResponse, pagesResponse} = this.props;
        
        return (
            <section className="mid-wrapper">
                <div className="page-title">
                    <figure><img src={pagesResponse.response && pagesResponse.response.pages['contact_us'].page_image} alt="Contact Banner"/></figure>
                    <div className="banner-title">
                        <div className="container">
                            <h1>{!pagesResponse.response ? '-' : pagesResponse.response.pages.contact_us.page_title}</h1>
                        </div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="container">
                        <div className="contact-outer">
                            <div className="contact-cols">
                                <div className="contact-label">
                                    <h2>{!pagesResponse.response ? '-' : pagesResponse.response.pages.contact_us.page_title}</h2>
                                    <div className="contact-deatil">
                                        <div className="col">
                                            <i className="icon icon-location"></i>
                                            <div className="col-label">
                                            <span>Address</span>
                                            <p>{!settingResponse.response ? '-' : settingResponse.response.address}</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <i className="icon icon-envelop"></i>
                                            <div className="col-label">
                                            <span>Email Address</span>
                                            <p>{!settingResponse.response ? '-' : settingResponse.response.global_email}</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <i className="icon icon-phone"></i>
                                            <div className="col-label">
                                            <span>Phone Number</span>
                                            <p>{!settingResponse.response ? '-' : settingResponse.response.phone_number}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="contact-form">
                                    <div className="contact-form-outer">
                                        <h2 dangerouslySetInnerHTML={{ __html: !pagesResponse.response ? ' ' : pagesResponse.response.pages.contact_us.page_description} } />
                                        {
                                            alertClass && 
                                            <div className={alertClass}> <strong><i className="fa fa-exclamation-triangle"></i>{alertMessage}</strong> <i className="fa fa-times" aria-hidden="true"></i></div>
                                        }
            {/* {successMsg && <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>  You have logged in Successfully. Please wait while we are redirecting </strong>  </div>} */}
                                        {/* <Formik
                                            initialValues={{
                                                name: '', 
                                                email: '', 
                                                contact_no: '', 
                                                message: '', 
                                            }}
                                            validationSchema={this.validationSchema}
                                            onSubmit={(values, actions) => 
                                                {
                                                    this.props.submitContactUs(values, actions)
                                                }
                                            }
                                            render={(formikProps) => this.renderContactForm(formikProps)}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactUsComponent;