import React from 'react';

class Pagination extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { totalRecord, pageLimit, activePage } = this.props;
        var recordCount = parseInt(totalRecord);
        var perPage = parseInt(pageLimit);
        var currentPage = parseInt(activePage);
        var noOfPages = Math.ceil(recordCount / perPage);
        var pageEnd = (activePage + 5 > noOfPages) ? noOfPages : Math.max((activePage + 5), 10);
        pageEnd = (pageEnd > noOfPages) ? noOfPages : pageEnd;
        var pageStart = (pageEnd - 9 <= 0) ? 1 : (pageEnd - 9);
        let pageObject = [];
        for (var i = pageStart; i <= pageEnd; i++) {
            pageObject.push(i);
        }
        return (
            <React.Fragment>
                {(recordCount > perPage)?
                <ul className="pagination">
                    {(currentPage !== 1) ? <li className="page-item prev">
                    { /* eslint-disable-next-line */}
                    <a href="/" onClick={(e)=>{e.preventDefault();this.props.getRecordsPerPage( currentPage - 1)}} className="page-link"><i className="icon icon-arrow-left"></i>Previous</a></li> : ""}
                    {pageObject.map((value, index) =>
                        (value !== currentPage) ? <li className="page-item" key={index}>
                        { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>{e.preventDefault();this.props.getRecordsPerPage(value)}} className="page-link">{value}</a></li>
                            : <li className="page-item active" key={index}>
                            { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>{e.preventDefault();this.props.getRecordsPerPage(currentPage)}} className="page-link">{value}</a></li>)}
                    {(currentPage !== noOfPages) ? <li className="page-item">
                    { /* eslint-disable-next-line */}
                    <a href="/" onClick={(e)=>{e.preventDefault();this.props.getRecordsPerPage(currentPage + 1)}} className="page-link">Next<i className="icon icon-arrow-right"></i> </a></li> : ""}
                </ul>:""}
            </React.Fragment>
        )
    }
}

export default Pagination