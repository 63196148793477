import React from 'react';
import HotelVoucherComponent from '../../components/agent/hotel/HotelVoucherComponent';
import { getBookingHotelDetails, emailHotelVoucher } from '../../actions/hotel/hotelBookAction';
import { connect } from 'react-redux'
import { myAccountAction } from '../../actions/agentAction';

class HotelVoucherContainer extends React.Component {

    componentDidMount()
    {  
        let data = localStorage.getItem('print-hotel-voucher-data');  
        this.props.getBookingDetails(data);
    }

    render() {
        
        return (
           <HotelVoucherComponent {...this.props}/> 
        )
    }
}

const mapStateToProps = (state) => {
    return {
        hotelBookedDetails         : state.hotelBookReducer.hotelBookedDetails,
        myAccountResponse          : state.myAccount.myAccountResponse,
        emailHotelVoucherResponse  : state.hotelBookReducer.emailHotelVoucher 
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBookingDetails: (data) => dispatch(getBookingHotelDetails(data)),
        emailHotelVoucher: (data, successCb, errorCb) => dispatch(emailHotelVoucher(data, successCb, errorCb)),
        myAccount: () => dispatch(myAccountAction())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(HotelVoucherContainer)