import React, {Component} from 'react';
import OwnerShip from "./ownerShipComponent";
import CompanyDetail from "./companyComponent";
import CommunicationDetail from "./communicationComponent";

class RegisterView extends Component {
    constructor(props){
        super(props);
        this.state = (
            {
                ownerShipDetail : null,
                companyDetail : null,
                userAvailibiltyLoader : false,
                userName : '',
                activeTab   : 'owner-detail',
                userIdError:null,
                userIdValidationFlag:false,
            }
        );
    }
    handleOwnerShip(data){
        this.setState({
            ownerShipDetail :data,
            activeTab   : 'company-detail'
        });
    }

    handleCompanyDetail(data){
        this.setState({
            companyDetail :data,
            activeTab   : 'communication-detail'
        });
    }

    handleCommunicationDetail(data){
        // this.checkAvailability();
        this.setState({
            companyDetail :data,
        });
    }

    backCompanyDetail(data){
        this.setState({
            activeTab   : 'owner-detail'
        });
    }
    backCommunicationDetail(data){
        this.setState({
            activeTab   : 'company-detail'
        });
    }
    
    checkAvailability=()=>{
        const userName = this.state.userName;
        if(userName.trim() && this.state.userIdValidationFlag)
        {
            this.props.checkUserNameValidation(userName);
            this.setState({clickCall: true});
        }
    };
    checkValidNumber(event)
    {
        this.setState({clickCall: false});
        var targetValue = event.target.value;
        var numberPattern = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-.]?[0-9]{3}[-.]?[0-9]{3,6}$/).test(targetValue)
        if(targetValue.trim() && numberPattern)
        {
            this.setState({
                userIdError:null,
                userIdValidationFlag:true,
            });
        }
        else if(targetValue.length === 0)
        {
            this.setState({
                userIdError:null,
                userIdValidationFlag:false,
            });
        }
        else
        {
            this.setState({
                userIdError:'Please enter valid mobile number',
                userIdValidationFlag:false,
            });
        }
    }

    render() {
        const {values, handleBlur,setFieldValue ,usernameResponse} = this.props;
        
        return (
            <React.Fragment>
                    <section className="mid-wrapper">
                        <div className="container">
                            <div className="register-mid">
                                <h2><i><img src="/assets/images/register-icon.svg" alt="register-icon"/></i>Application For Agent
                                    Registration</h2>
                                    <div className="col-login">
                                     <div className="inner-login-user">
                                        <div className="form-group-login">
                                            <label>User Id<span className="red-error">*</span> </label>
                                            <div className="user-login-input">
                                                <input type="text" placeholder="(ex.9999999999)" name="username" value={values.username} onBlur={handleBlur} onChange={(event) => {this.setState({
                                                    userName : event.target.value
                                                }); setFieldValue("username", event.target.value); setFieldValue("mobile_number", event.target.value);
                                                }}  onKeyUp={((e) => this.checkValidNumber(e))}/>
                                                <button className="btn-sky-blue" onClick={this.checkAvailability}>Check Availability</button>
                                            </div>
                                            {
                                                usernameResponse && usernameResponse.error &&  this.state.clickCall && (
                                                    <div className="after-login">
                                                        <span className="login-error"><i className="material-icons">close</i>Sorry! {this.props.showUserName} is not available.</span>
                                                   </div>
                                                )
                                            }
                                            {   this.state.userIdError && <div className="after-login"><span className="login-error">{this.state.userIdError}</span></div>}
                                            {
                                                usernameResponse && usernameResponse.success &&  this.state.clickCall && (
                                                    <div className="after-login">
                                                         <span className="login-success" ><i className="material-icons">check</i>Congratulation! {this.props.showUserName} is available.</span>
                                                   </div>
                                                )
                                            }
                                            {
                                                usernameResponse && usernameResponse.loading
                                                &&
                                                    <div className="after-login">
                                                        <span className="loading-login">
                                                        <img src="/assets/images/loader.gif" alt="loader" /> Checking availability....</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div> 
                                <div className="registration-step">
                                    <h3>Complete easy steps to become an agent!</h3>
                                    <div className="step-block">
                                        <ul className="step-block-inner">
                                            <li className={this.state.activeTab === 'owner-detail' ? 'active-step' : ''}>
                                                <div className="icon-block">
                                                    <figure><img src="/assets/images/ownership-icon.svg" alt=""/> 
                                                    <span className="check-sign"><i className="material-icons">check</i></span>
                                                    </figure>
                                                </div>
                                                <span><small>Ownership Details</small></span></li>
                                            <li className={this.state.activeTab === 'company-detail' ? 'active-step' :''}>
                                                <div className="icon-block">
                                                    <figure><img src="/assets/images/company-info-icon.svg" alt=""/>
                                                    <span className="check-sign"><i className="material-icons">check</i></span>
                                                    </figure>
                                                </div>
                                                <span><small>Company Information</small></span></li>
                                            <li className={this.state.activeTab === 'communication-detail' ? 'active-step' :''}>
                                                <div className="icon-block">
                                                    <figure><img src="/assets/images/communication-icon.svg" alt=""/>
                                                    <span className="check-sign"><i className="material-icons">check</i></span>
                                                    </figure>
                                                </div>
                                                <span><small>Communication Details</small></span></li>
                                        </ul>
                                        <div className="step-line"></div>
                                    </div>
                                </div>
                                {
                                    this.state.activeTab === 'owner-detail' &&
                                    <div className="form-section">
                                        <OwnerShip handleValues={this.handleOwnerShip.bind(this)} {...this.props}/>
                                    </div>
                                }
                                {
                                    this.state.activeTab === 'company-detail' &&
                                    <div className="form-section">
                                        <CompanyDetail handleValues={this.handleCompanyDetail.bind(this)} {...this.props} back={this.backCompanyDetail.bind(this)} />
                                    </div>
                                }
                                {
                                    this.state.activeTab === 'communication-detail' &&
                                    <div className="form-section">
                                        <CommunicationDetail handleValues={this.handleCommunicationDetail.bind(this)} {...this.props} back={this.backCommunicationDetail.bind(this)}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
            </React.Fragment>
        );
    }
}

export default RegisterView;
