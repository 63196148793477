import FLIGHT_API from '../../api/flightSearch';
//=========================== for flight search =========================================
export const FLIGHT_SEARCH_SAVE_QUERY = 'FLIGHT_SEARCH_SAVE_QUERY';
export const flightSearchSaveQuery = (formData) => {
    return {
        type: FLIGHT_SEARCH_SAVE_QUERY,
        flightSerarchQuery: formData
    };
};
//=========================== for Classes API =========================================
export const CLASSES_FETCH_START = 'CLASSES_FETCH_START';
const startClassesFetch = () => {
    return {
        type: CLASSES_FETCH_START
    };
};

export const CLASSES_FETCH_OK = 'CLASSES_FETCH_OK';
const classesFetchSuccess = (data) => {
    return {
        type: CLASSES_FETCH_OK,
        response:data
    }
};

export const CLASSES_FETCH_ERR = 'CLASSES_FETCH_ERR';
const classesFetchError = (status, message, errors) => {
    return {
        type: CLASSES_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function getAllClasses (credentials) {
    return (dispatch, getState) => {
       
        dispatch(startClassesFetch());

        return FLIGHT_API.getClasses(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(classesFetchSuccess(payload.classes));

                } else {
                    dispatch(classesFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}
//=========================== for Airlines API =========================================
export const AIRLINES_FETCH_START = 'AIRLINES_FETCH_START';
const startAirlinesFetch = () => {
    return {
        type: AIRLINES_FETCH_START
    };
};

export const AIRLINES_FETCH_OK = 'AIRLINES_FETCH_OK';
const airlinesFetchSuccess = (data) => {
    return {
        type: AIRLINES_FETCH_OK,
        response:data
    }
};

export const AIRLINES_FETCH_ERR = 'AIRLINES_FETCH_ERR';
const airlinesFetchError = (status, message, errors) => {
    return {
        type: AIRLINES_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function getAllAirlines (credentials) {
    
    return (dispatch, getState) => {
       
        dispatch(startAirlinesFetch());

        return FLIGHT_API.getAirlines(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(airlinesFetchSuccess(payload.airlines));

                } else {
                    dispatch(airlinesFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}