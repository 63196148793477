
/* external dependencies */
import React from 'react';
import LoginForm from './LoginComponent';
import EmailVerifyForm from './EmailVerifyComponent';
import ConfirmPasswordForm from './ConfirmPasswordComponent';

class LoginPageComponent extends React.Component {

	render() {
		let { productResponse, resetPassword } = this.props;
		return (
			<section className="mid-wrapper">
				<div className="container">
					<div className="login-container">
						<div className="login-area">
							<div className="our-services">
								<ul>
									{
										productResponse.response && productResponse.response.map((item, index) => {
											return (
												<li key={index}>
													<i className="">
														<img src={item.product_image} alt="" /></i>
													<h3>{item.product_name}</h3>
												</li>
											)
										})
									}
								</ul>
							</div>
							<div className="login-block login-wcard">
								<div className={`flip-card ${resetPassword.flipCardClass}`}>
									<div className="flip-card-inner">
										<LoginForm {...this.props} />
										<EmailVerifyForm {...this.props} />
										<ConfirmPasswordForm {...this.props} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					this.props.passwordExpiry !== null &&
					this.passwordExpiryPopUp()
				}
			</section>
		);
	}

	passwordExpiryPopUp = () => {
		return (
			<div className={`popup-crad confirmation-popup show`}>
				<div className="popup-outer">
					<div className="popup-content">
						<div className="popup-head">
							<h2>Password Expiry</h2>
							<div className="popup-close"></div>
						</div>
						<div className="popup-body popup-scorll">
							<div className="inner-confirmation">
								<p>Your Password will expire {this.props.passwordExpiry === 0 ? 'today' : 'in ' + this.props.passwordExpiry + ' days' }. Please reset your password.</p>
								<div className="popup-footer">
									<button className="btn btn-orange" onClick={() => {this.props.loginRedirection();}}>Ok</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-overlay"></div>
			</div>
		);
	}
}

export default LoginPageComponent;