import React from 'react';
import FlightSearchComponent from '../../components/flights/FlightSearchComponent';
import { connect } from 'react-redux'
import { bannerAction, noticeAction, getCitiesAction,scroll_position } from '../../actions/shared/sharedAction';
import { getAllClasses, getAllAirlines, flightSearchSaveQuery } from '../../actions/flight/flightSearchAction';
import { myAccountAction } from '../../actions/agentAction';

class FlightSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null
        };
    }

    componentDidMount()
    {
        let productUrl  = 'flight-booking';
        let pageUrl     = 'searching';
        this.props.bannerAction('/'+productUrl+'/'+pageUrl);//call banner API
        this.props.noticeAction('/'+productUrl);//call notice API
        this.props.getAllClasses(null);
        this.props.getAllAirlines(null);
        this.props.myAccountAction();
    }

    render() {
        return (
            <FlightSearchComponent {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bannerResponse: state.shared.bannerResponse,
        noticeResponse: state.shared.noticeResponse,
        citiesResponse: state.shared.citiesResponse,
        allClassesData: state.flightSearchReducer.allClassesData,
        allAirlinesData: state.flightSearchReducer.allAirlinesData,
        accountResponse    : state.myAccount,//my account details
        agentProductAccess     : state.shared.agentProductAccess,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        bannerAction: (credentials) => dispatch(bannerAction(credentials)),
        noticeAction: (credentials) => dispatch(noticeAction(credentials)),
        getCitiesAction:(credentials, successCb, errorCb) => dispatch(getCitiesAction(credentials, successCb, errorCb)),
        flightSearchSaveQuery:(formData) => dispatch(flightSearchSaveQuery(formData)),
        getAllClasses:(credentials) => dispatch(getAllClasses(credentials)),
        getAllAirlines:(credentials) => dispatch(getAllAirlines(credentials)),
        myAccountAction: ()        => dispatch(myAccountAction()),
        scroll_position: (response) => dispatch(scroll_position(response)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps) (FlightSearch)