
import API from './index.js';
var { postApiCall,bufferDataFun } = require('./index.js');
//const apiUrl      = 'http://jfapidev.testappsnow.com:3001';
export const apiHotelSearchResults = (payload) => {
	
    return postApiCall('/jfot/hotel/search', payload) // change
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
};

export const apiGetHotelRoomDetails = (data) => {	
	return API.postResponseSession('/jfot/hotel/get-hotel-rooms',data) //change
};

export const apiGetHotelDetails = (trace_id, result_index, hotel_code) => {	
	return API.postResponseSession('/jfot/hotel/details', {trace_id: trace_id, result_index: result_index, hotel_code: hotel_code}) //change
};

//Get pax title
export const getPaxTitleApi = () => {
	return API.getResponseSession('/flight/search/pax-titles') 
	.then(response => {
		return response;
	})
	.catch((error) => {
		return error;
	});
}

export const apiBlockHotelRoom = (data) => {	
	return API.postResponseSession('/jfot/hotel/block-room', data) //cha
};

export const apiHotelBooking = (data) => {	
	return API.postResponseSession('/jfot/hotel/booking/book', data) //cha
};

// Get booked hotel room details
export const apiGetBookingDetails = (data) => {	
	return API.postResponseSession('/hotel/offline/get-booking-details', data)
};

// send itineraries email
export const apiSendItinerariesEmail = (payload) => {
    return API.postResponseSession('/hotel/search/itineraries-email', payload)
};

//calling generate voucher
export const apiGenerateVoucher = (data) => {
    return API.postResponseSession('/jfot/hotel/generate-voucher',data); //cha
};

//calling email voucher
export const apiEmailHotelVoucher = (data) => {
    return API.postResponseSession('/account/email-hotel-voucher',data);
};

//Tripjack Hotel Search
export const apiTripJackHotelSearchResults = (payload) => {
    return bufferDataFun('/jfkt/hotel/search', payload)
	.then(response => {
		return response.content;
	})
	.catch((error) => {
		return error;
	});
};

//Tripjack Hotel Room Details
export const apiGetTripJackHotelRoomDetails = (data) => {	
	return API.postResponseSession('/jfkt/hotel/get-hotel-rooms',data)
};

//Tripjack Hotel Details
export const apiGetTripJackHotelDetails = (trace_id, result_index, hotel_code) => {	
	return API.postResponseSession('/jfkt/hotel/details', {trace_id: trace_id, result_index: result_index, hotel_code: hotel_code})
};

//Tripjack Hotel Cancellation Policy
export const apiGetTripJackCancelPolicy = (data) => {	
	return API.postResponseSession('/jfkt/hotel/cancellation-policy',data)
};

//Tripjack Hotel Review
export const apiGetTripJackHotelReview = (data) => {	
	return API.postResponseSession('/jfkt/hotel/review',data)
};

//Tripjack Hotel Book
export const apiTripjackHotelBooking = (data) => {	
	return API.postResponseSession('/jfkt/hotel/booking', data)
};

//calling confirm book
export const apiConfirmBooking = (data) => {
    return API.postResponseSession('/jfkt/hotel/confirm-book',data);
};

//Tripjack  Hotel Booking Details
export const apiTripjackBookingDetails = (data) => {	
	return API.postResponseSession('/jfkt/hotel/booking-details', data)
};

//tripjack check pnr
export const apiTripjackCheckPnr = (payload) => {
	return API.postResponseSession('/jfkt/hotel/check-status',payload)
}