import React, { Component } from 'react';
class CancellationTermsAndConditions extends Component {

    render() {
        let { pagesResponse } = this.props;
        return (
            <section className="mid-wrapper">
                <div className="page-title">
                    <figure><img src={pagesResponse.response && pagesResponse.response.pages['cancellation_terms-and-conditions'].page_image} alt="Cancellation Terms and conditions Banner" /></figure>
                    <div className="banner-title">
                        <div className="container">
                            <h1>{!pagesResponse.response ? '-' : pagesResponse.response.pages['cancellation_terms-and-conditions'].page_title}</h1>
                        </div>
                    </div>
                </div>
                <div className="about-info">
                    <div className="container">
                        <div className="about-content">
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['cancellation_terms-and-conditions'].page_description }} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default (CancellationTermsAndConditions);