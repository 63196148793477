import React, { Component } from 'react';
import MarkUpTool from '../../components/agent/markup/AgentMarkUpToolComponent';
import { connect } from 'react-redux'
import { getcMarkupToolResponse,submitDomesticMarkupTool,submitInternationalMarkupTool,getHotelAgentMarkUp,submitHotelAgentMarkUp, myAccountAction } from '../../actions/agentAction';
import { logoutAction } from '../../actions/shared/sessionAction';

class AgentMarkUpTools extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            submitDomesticMarkupMsg:null,
            submitInternationalMarkupMsg:null,
            submitHotelMarkupMarkupMsg:null,
            hideMsg: false,
            validationFlags:false,
            validationFlagInt:false,
            hideMsgInt: false,
        };
    }
    
    componentDidMount() {
		this.props.getHotelAgentMarkUp();
    }
    getMarkupToolInfo(category){
        this.props.getcMarkupToolResponse(category);
    }
    dynamicJson = (markUpValue, flightID) => {
        return { 
            "flight_id":flightID,
            "mark_up_type":"flat",
            "mark_up_value":parseInt(markUpValue)
        }
    }
    updateDomesticMarkupTool(values){
        let markupToolFormValues = [];
        this.setState({validationFlags:false});
        values.mark_up_value && values.mark_up_value.forEach((markUpValue,flightID) =>{
            if(markUpValue && !new RegExp(/^[0-9\b]+$/).test(markUpValue))
            {
                this.setState({
                    validationFlags:true,
                    submitDomesticMarkupMsg:null,
                    hideMsg: false,
                });
            }
            else if(markUpValue)
            {
                markupToolFormValues.push(this.dynamicJson(markUpValue,flightID));
            }
        });
        const successCb = () => {
            this.setState({
                submitDomesticMarkupMsg:"success",
                hideMsg: false,
            });
            this.props.getcMarkupToolResponse("domestic");
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({
                submitDomesticMarkupMsg: 'error',
                hideMsg: false,
            });
        }
        if(markupToolFormValues.length)
        {
            this.props.submitDomesticMarkupTool(markupToolFormValues,successCb,errorCb);
        }
    }
    showReplyForm = () => {
        this.setState({
            hideMsg: true,
            hideMsgInt:true,
        });
    };

    updateInternationalMarkupTool(values){
        this.setState({validationFlagInt:false});
        let formValues = [];
        values.mark_up_values && values.mark_up_values.forEach((markUpValue,flightID) =>{
           
            if(markUpValue && !new RegExp(/^[0-9\b]+$/).test(markUpValue))
            {
                this.setState({
                    validationFlagInt:true,
                    submitInternationalMarkupMsg:null,
                    hideMsgInt: false,
                });
            }
            else if(markUpValue)
            {
                formValues.push(this.dynamicJson(markUpValue,flightID));
            }
        });
        
        const successCb = () => {
            this.setState({
                submitInternationalMarkupMsg:"success",
                hideMsgInt: false,
            });
            this.props.getcMarkupToolResponse("international");
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({
                submitInternationalMarkupMsg: 'error',
                hideMsgInt: false,
            });
        }
        if(formValues.length)
        {
            this.props.submitInternationalMarkupTool(formValues,successCb,errorCb);
        }
    }

    updateHotelMarkupTool = (values) =>
    {
        let domestic = '';
        let internatinal = '';
        if(values.domestic_markup_value !== '' && values.domestic_markup_value !== null){
            domestic = {
                "category_id": 1,
                "markup_value": parseInt(values.domestic_markup_value),
            }
        }else{
            domestic = {
                "category_id": 1
            }
        }

        if(values.international_markup_value !== '' && values.international_markup_value !== null){
            internatinal = {
                "category_id": 2,
                "markup_value":parseInt(values.international_markup_value)
            }
        }else{
            internatinal = {
                "category_id": 2
            
            }
        }
            
        const formValues = [
            domestic,
            internatinal

        ];
        const successCb = () => {
            this.setState({
                submitHotelMarkupMarkupMsg:"success",
                hideMsg: false,
            });
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({
                submitHotelMarkupMarkupMsg: 'error',
                hideMsg: false,
            });
        }
        this.props.submitHotelAgentMarkUp(formValues,successCb,errorCb);
        this.props.getHotelAgentMarkUp();
    }

    render() {
        return (
            <MarkUpTool {...this.props} {...this.state} showReplyForm={this.showReplyForm} updateDomesticMarkupTool={this.updateDomesticMarkupTool.bind(this)} getMarkupToolInfo={this.getMarkupToolInfo.bind(this)} updateInternationalMarkupTool={this.updateInternationalMarkupTool.bind(this)} updateHotelMarkupTool={this.updateHotelMarkupTool.bind(this)} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse : state.shared.productResponse,
        agentProductAccess     : state.shared.agentProductAccess,
        getHotelMarkupResponse     : state.myAccount.getHotelMarkupResponse,
        submitHotelMarkupResponse     : state.myAccount.submitHotelMarkupResponse,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getcMarkupToolResponse          :   (category)                  =>  dispatch(getcMarkupToolResponse(category)),
        submitDomesticMarkupTool        :   (data,successCb,errorCb)    =>  dispatch(submitDomesticMarkupTool(data,successCb,errorCb)),
        submitInternationalMarkupTool   :   (data,successCb,errorCb)    =>  dispatch(submitInternationalMarkupTool(data,successCb,errorCb)),
        logoutAction                    : () => dispatch(logoutAction()),
        getHotelAgentMarkUp             : () =>  dispatch(getHotelAgentMarkUp()),
        submitHotelAgentMarkUp: (data,successCb,errorCb) => dispatch(submitHotelAgentMarkUp(data,successCb,errorCb)),
        myAccountAction                    : () => dispatch(myAccountAction()),
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(AgentMarkUpTools)
