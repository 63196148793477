import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb'
 
class SliderComponent extends React.Component {
  render() {
    return (
        <Nouislider range={{ min: this.props.minPrice, max: this.props.maxPrice }} start={[this.props.minChangedPrice, this.props.maxChangedPrice]} tooltips={true} onSlide={this.props.getPriceValue} connect 
        format={ wNumb({ decimals: 0 }) } 
        />
    );
  }
}

export default SliderComponent;


