/* external dependencies */
import React from 'react'
//import { connect } from 'react-redux'
import AboutUsComponent from '../../components/shared/AboutUsComponent';

class AboutUsContainer extends React.Component {
    render() {
        let {pagesResponse} = this.props;
        return (
            <AboutUsComponent pagesResponse={pagesResponse} />
        )
    }
}

// const mapStateToProps = (state) => {
//     return {
//     }
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//     }
// };

//export default connect(mapStateToProps,mapDispatchToProps)(AboutUsContainer)
export default (AboutUsContainer)