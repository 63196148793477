/* external dependencies */
import React from 'react'
import { connect } from 'react-redux'
import BackToTopComponent from '../../components/shared/BackToTopComponenet';
import { isFilterChecked,isFilterCheckedInbound,scroll_position} from "../../actions/shared/sharedAction";

class BackToTopContainer extends React.Component {
    render() {
        return (
            <BackToTopComponent {...this.props}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFilterCheckedStatus    : state.shared.isFilterChecked,
        track_scroll_position    : state.shared.scroll_position
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        isFilterChecked: (response) => dispatch(isFilterChecked(response)),
        scroll_position: (response) => dispatch(scroll_position(response)),
        isFilterCheckedInbound: (response) => dispatch(isFilterCheckedInbound(response)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(BackToTopContainer)