import VENDOR_API from '../../api/shared/vendorApi';

//============================================= Add vendor bookings ==============================================//
export const ADD_VENDOR_BOOKINGS_START = 'ADD_VENDOR_BOOKINGS_START';
const addVendorBookingsStart = () => {
    return {
        type: ADD_VENDOR_BOOKINGS_START
    };
};

export const ADD_VENDOR_BOOKINGS_OK = 'ADD_VENDOR_BOOKINGS_OK';
const addVendorBookingsOk = (data) => {
    return {
        type: ADD_VENDOR_BOOKINGS_OK,
        response: data
    }
};

export const ADD_VENDOR_BOOKINGS_ERR = 'ADD_VENDOR_BOOKINGS_ERR';
const addVendorBookingsErr = (status, message, errors) => {
    return {
        type: ADD_VENDOR_BOOKINGS_ERR,
        status,
        message,
        errors
    }
};

export function addVendorBookings(credentials,actionCb) {
    return (dispatch, getState) => {
        dispatch(addVendorBookingsStart());
        return VENDOR_API.addVendorBookingsAPI(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(addVendorBookingsOk(payload.status));
                } else {
                    dispatch(addVendorBookingsErr(payload.status, payload.message, payload.errors));
                }
                actionCb && actionCb(payload);
            });
    }
}

//============================================= View vendor bookings ==============================================//
export const VIEW_VENDOR_BOOKINGS_START = 'VIEW_VENDOR_BOOKINGS_START';
const viewVendorBookingsStart = () => {
    return {
        type: VIEW_VENDOR_BOOKINGS_START
    };
};

export const VIEW_VENDOR_BOOKINGS_OK = 'VIEW_VENDOR_BOOKINGS_OK';
const viewVendorBookingsOk = (data) => {
    return {
        type: VIEW_VENDOR_BOOKINGS_OK,
        response: data
    }
};

export const VIEW_VENDOR_BOOKINGS_ERR = 'VIEW_VENDOR_BOOKINGS_ERR';
const viewVendorBookingsErr = (status, message, errors) => {
    return {
        type: VIEW_VENDOR_BOOKINGS_ERR,
        status,
        message,
        errors
    }
};

export function viewVendorBookings(successCb, errorCb) {
    return (dispatch, getState) => {
        dispatch(viewVendorBookingsStart());
        return VENDOR_API.viewVendorBookingsAPI()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(viewVendorBookingsOk(payload));
                    successCb && successCb(payload);
                } else {
                    dispatch(viewVendorBookingsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

//============================================= Edit vendor bookings ==============================================//
export const EDIT_VENDOR_BOOKINGS_START = 'EDIT_VENDOR_BOOKINGS_START';
const editVendorBookingsStart = () => {
    return {
        type: EDIT_VENDOR_BOOKINGS_START
    };
};

export const EDIT_VENDOR_BOOKINGS_OK = 'EDIT_VENDOR_BOOKINGS_OK';
const editVendorBookingsOk = (data) => {
    return {
        type: EDIT_VENDOR_BOOKINGS_OK,
        response: data
    }
};

export const EDIT_VENDOR_BOOKINGS_ERR = 'EDIT_VENDOR_BOOKINGS_ERR';
const editVendorBookingsErr = (status, message, errors) => {
    return {
        type: EDIT_VENDOR_BOOKINGS_ERR,
        status,
        message,
        errors
    }
};

export function editVendorBookings(vendorId, credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(editVendorBookingsStart());
        return VENDOR_API.editVendorBookingsAPI(vendorId, credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(editVendorBookingsOk(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(editVendorBookingsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

//============================================= Delete vendor bookings ==============================================//
export const DELETE_VENDOR_BOOKINGS_START = 'DELETE_VENDOR_BOOKINGS_START';
const deleteVendorBookingsStart = () => {
    return {
        type: DELETE_VENDOR_BOOKINGS_START
    };
};

export const DELETE_VENDOR_BOOKINGS_OK = 'DELETE_VENDOR_BOOKINGS_OK';
const deleteVendorBookingsOk = (data) => {
    return {
        type: DELETE_VENDOR_BOOKINGS_OK,
        response: data
    }
};

export const DELETE_VENDOR_BOOKINGS_ERR = 'DELETE_VENDOR_BOOKINGS_ERR';
const deleteVendorBookingsErr = (status, message, errors) => {
    return {
        type: DELETE_VENDOR_BOOKINGS_ERR,
        status,
        message,
        errors
    }
};

export function deleteVendorBookings(credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(deleteVendorBookingsStart());
        return VENDOR_API.deleteVendorBookingsAPI(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(deleteVendorBookingsOk(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(deleteVendorBookingsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}

//============================================= Edit status vendor bookings ==============================================//
export const EDIT_STATUS_VENDOR_BOOKINGS_START = 'EDIT_STATUS_VENDOR_BOOKINGS_START';
const editStatusVendorBookingsStart = () => {
    return {
        type: EDIT_STATUS_VENDOR_BOOKINGS_START
    };
};

export const EDIT_STATUS_VENDOR_BOOKINGS_OK = 'EDIT_STATUS_VENDOR_BOOKINGS_OK';
const editStatusVendorBookingsOk = (data) => {
    return {
        type: EDIT_STATUS_VENDOR_BOOKINGS_OK,
        response: data
    }
};

export const EDIT_STATUS_VENDOR_BOOKINGS_ERR = 'EDIT_STATUS_VENDOR_BOOKINGS_ERR';
const editStatusVendorBookingsErr = (status, message, errors) => {
    return {
        type: EDIT_STATUS_VENDOR_BOOKINGS_ERR,
        status,
        message,
        errors
    }
};

export function editStatusVendorBookings(vendorId, credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(editStatusVendorBookingsStart());
        return VENDOR_API.editStatusVendorBookingsAPI(vendorId, credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(editStatusVendorBookingsOk(payload.status));
                    successCb && successCb();
                } else {
                    dispatch(editStatusVendorBookingsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload);
                }
            });
    }
}