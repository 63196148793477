import React, { Component } from 'react';
import * as hotelHelper from '../../shared/hotelHelper';
import SliderComponent from '../shared/SliderComponent';

class HotelSearchDetailsLeftComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters:
                {
                    hotel_name: null, 
                    hotel_rating : [],
                    hot_deals    : null,
                    hotel_price  : {
                        min_price : null,
                        max_price : null
                    }
                },
            minChangedPrice: null,
            maxChangedPrice: null
        };
    }

    resetFilters = (event) => {
        this.setState({filters:
            {
                hotel_name: null, 
                hotel_rating : [],
                hot_deals : null,
                hotel_price  : {
                    min_price : null,
                    max_price : null
                }
            }}, () => {
                this.props.filterhotelResults(this.state.filters)
        });
    }

    toggleFilter = (filterGroup, filter) => {
        const container = document.querySelector('.page-containers-start');
              container && container.scrollTo(0, 0)
              let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
                let filterHEight=document.getElementById('adv-filter');
                let cliHeight=filterHEight && filterHEight.clientHeight;
                let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
                let isResultToREnder = resultToREnder && resultToREnder > 15 ? resultToREnder : 15;
                this.props.isFilterChecked(isResultToREnder)
        // for (let i = 6; i <= isResultToREnder; i = i + 3) {
        //     setTimeout(() => {
        //         this.props.isFilterChecked(i);
        //     }, 20 * (i - 1));
        // }
        let filters = null;
        if(filterGroup === 'hotel_rating'){
            let hotel_rating = this.state.filters.hotel_rating;
            if(hotel_rating.includes(filter)){
                var indexValue = hotel_rating.indexOf(filter)
                hotel_rating.splice(indexValue, 1);
            }else{
                hotel_rating.push(filter);
            }
            filters = {...this.state.filters, [filterGroup]: hotel_rating}
        }else{
            filters = {...this.state.filters, [filterGroup]: filter}
        }
        this.setState({filters}, () => {
            this.props.filterhotelResults(JSON.parse(JSON.stringify(this.state.filters)))
        });
    }

    resetSingleFilter = (filter) => {
        let val = [];
        if(filter === 'hot_deals' || filter === 'hotel_name' || filter === 'hotel_location'){
             val = null
        }
        else if(filter === 'hotel_price'){
            val = {
                min_price : null,
                max_price : null
            }
            let minPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMinPrice(this.props.hotelSearchResult.hotel_results) : 0;
            let maxPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMaxPrice(this.props.hotelSearchResult.hotel_results) : Number(minPrice) + 1;
            this.setState({ 
                minChangedPrice:minPrice,
                maxChangedPrice:maxPrice
            });
        }
        let filters = {...this.state.filters, [filter]: val}
        this.setState({filters}, () => {
                this.props.filterhotelResults(this.state.filters);
        });
    }

    getPriceValue = (render, handle, value, un, percent) => {
        let hotel_price = {
            min_price : render[0],
            max_price : render[1]
        }
        this.setState({ 
            minChangedPrice:render[0],
            maxChangedPrice:render[1]
        });
        this.toggleFilter('hotel_price',hotel_price);
    }

    componentDidMount()
    {
        let minPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMinPrice(this.props.hotelSearchResult.hotel_results) : 0;
        let maxPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMaxPrice(this.props.hotelSearchResult.hotel_results) : Number(minPrice) + 1;
        this.setState({ 
            minChangedPrice:minPrice,
            maxChangedPrice:maxPrice
        });
    }

	render() {
        let minPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMinPrice(this.props.hotelSearchResult.hotel_results) : 0;
        let maxPrice = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results ? hotelHelper.getMaxPrice(this.props.hotelSearchResult.hotel_results) : Number(minPrice) + 1;
        //Check if min and max are same
        if(minPrice === maxPrice){
            maxPrice = Number(maxPrice) + 1;
        }
		return (
			<div className="left-side">
                <div className="filter-section">
                    <div className="filter-title">
                    { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>e.preventDefault()} className="close-ui icon icon-arrow-left2" />
                        <h3>Filter Results</h3>
                        { /* eslint-disable-next-line */}
                        <a className="reset-link" href="#" onClick={this.resetFilters}><span>Reset All</span> <i className="icon icon-refresh" /></a>
                    </div>
                    <div className="inner-filter" id='adv-filter'>
                        <div className="filter-block">
                            <div className="filter-col">
                                <h4>Hotel Name <span className="icon icon-refresh" onClick={() =>this.resetSingleFilter('hotel_name')}/></h4>
                                <div className="col-inner">
                                    <div className="ft-hotel-name">
                                        <div className="form-group">
                                            <input value={this.state.filters.hotel_name?this.state.filters.hotel_name:''} onChange={(event) => this.toggleFilter('hotel_name',event.target.value)} className="form-control custom-filed" type="text" placeholder="Please enter hotel name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-col">
                                <h4>Location<span className="icon icon-refresh" onClick={() =>this.resetSingleFilter('hotel_location')} /></h4>
                                <div className="col-inner">
                                    <div className="ft-hotel-location">
                                        <div className="form-group">
                                            <input value={this.state.filters.hotel_location?this.state.filters.hotel_location:''} onChange={(event) => this.toggleFilter('hotel_location',event.target.value)} className="form-control custom-filed" type="text" placeholder="Please enter location" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-col">
                                <h4>Hot Deals<span className="icon icon-refresh" onClick={() =>this.resetSingleFilter('hot_deals')} /></h4>
                                <div className="col-inner">
                                    <div className="ft-hot-deals">
                                        <ul className="hot-deal-info">
                                            <li>
                                                <label className="pull-left">
                                                    <div className="ht-list-check"> <small className="checkbox">
                                                        <input type="checkbox" name="remember_me" onChange={(event) => this.toggleFilter('hot_deals',event.target.checked)}
                                                        checked={this.state.filters.hot_deals?true:false}/>
                                                        <label />
                                                    </small> <span>Hot Deals</span> </div>
                                                </label>
                                                <div className="pull-right"> <span className="ht-prize-list">{this.props.hotelSearchResult && hotelHelper.getHotelByHotDeals(this.props.hotelSearchResult.hotel_results)}</span> </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-col">
                                <h4>Price In Rupees<span className="icon icon-refresh" onClick={() =>this.resetSingleFilter('hotel_price')}/></h4>
                                <div className="col-inner slider-range">
                                    <SliderComponent minPrice={minPrice} maxPrice={maxPrice} minChangedPrice={this.state.minChangedPrice} maxChangedPrice={this.state.maxChangedPrice} getPriceValue={this.getPriceValue}/>
                                    {/* <div className="ft-price-filter"> <img src="assets/images/nouise-slider.png" alt=""/> </div> */}
                                </div>
                            </div>
                            <div className="filter-col">
                                <h4>STAR RATING<span className="icon icon-refresh" onClick={() =>this.resetSingleFilter('hotel_rating')}/></h4>
                                <div className="col-inner">
                                    <div className="ft-star-rating">
                                        <ul>
                                            <li className={this.state.filters.hotel_rating.includes(0)?'active':''} onClick={() => this.toggleFilter('hotel_rating',0)}>
                                                <div className="anchor-star"> <span>0</span> <samp className="rating-zero"><i className="icon icon-star-full" /></samp> 
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,0) }</small> </div>
                                            </li>
                                            <li className={this.state.filters.hotel_rating.includes(1)?'active':''} onClick={() => this.toggleFilter('hotel_rating',1)}>
                                                <div className="anchor-star"> <span>1</span> <samp><i className="icon icon-star-full" /></samp> 
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,1) }</small> </div>
                                            </li>
                                            <li className={this.state.filters.hotel_rating.includes(2)?'active':''} onClick={() => this.toggleFilter('hotel_rating',2)}>
                                                <div className="anchor-star"> <span>2</span> <samp><i className="icon icon-star-full" /></samp> 
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,2) }</small> </div>
                                            </li>
                                            <li className={this.state.filters.hotel_rating.includes(3)?'active':''} onClick={() => this.toggleFilter('hotel_rating',3)}>
                                                <div className="anchor-star"> <span>3</span> <samp><i className="icon icon-star-full" /></samp> 
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,3) }</small> </div>
                                            </li>
                                            <li className={this.state.filters.hotel_rating.includes(4)?'active':''} onClick={() => this.toggleFilter('hotel_rating',4)}>
                                                <div className="anchor-star"> <span>4</span> <samp><i className="icon icon-star-full" /></samp>
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,4) }</small> </div>
                                            </li>
                                            <li className={this.state.filters.hotel_rating.includes(5)?'active':''} onClick={() => this.toggleFilter('hotel_rating',5)}>
                                                <div className="anchor-star"> <span>5</span> <samp><i className="icon icon-star-full" /></samp> 
                                                <small>{this.props.hotelSearchResult && hotelHelper.getHotelByRating(this.props.hotelSearchResult.hotel_results,5) }</small> </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

export default HotelSearchDetailsLeftComponent