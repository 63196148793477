import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import ViewDeposits from '../../agent/deposits/ViewDepositsComponent';
import NewDeposit from '../../agent/deposits/NewDepositComponent';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';

class AgentDeposits extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/deposits',
          url: history.location.pathname
        }
    }
    
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    render() {
        const { accountResponse,productResponse} = this.props;
        let depositResponse = accountResponse.depositRequestResponse.response;
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction} />
                                    {this.props.type === '/deposits' ?
                                    <div className="profile-detail-info">
                                        <div className="edit-profile-bar">
                                            <div className="pull-right">
                                            { /* eslint-disable-next-line */}
                                                <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                            </div>
                                        </div>
                                        <div className="tabs-container-detail">
                                            <div className="mark-up-info">
                                                <div className="tabs-top">
                                                    <div className="tabs-title">
                                                        <h2><i className="icon icon-money"></i><span>Deposits</span></h2>
                                                    </div>
                                                </div>
                                                <div className="category-list updates-list">
                                                    <ul>
                                                    <li>
                                                            <Link to="view-deposit">
                                                                <div className="category-card">
                                                                    <figure><i className="icon icon-view-deposit"></i></figure>
                                                                    <div className="category-card-bottom">
                                                                        <h3>View Deposit Request</h3>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>  
                                                        <li>
                                                            <Link to ="deposit-request">
                                                                <div className="category-card">
                                                                    <figure><i className="icon icon-deposit-request"></i></figure>
                                                                    <div className="category-card-bottom">
                                                                        <h3>New Deposit Request</h3>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>  
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>: ''}
                                    {this.props.type === '/view-deposit' ? <ViewDeposits  {...this.props} depositResponseList = {depositResponse}/> : ''}
                                    {this.props.type === '/deposit-request' ? <NewDeposit  {...this.props} addNewDeposit={this.addNewDeposit}/> : ''}                         
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        )
    }
}

export default (AgentDeposits)