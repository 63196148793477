/* external dependencies */
import React from 'react';
import { Field } from 'formik';

class UserSubscribeForm extends React.Component {
    render() {
        let { handleSubmit } = this.props;
        return (
            <React.Fragment>
                <div className="col-box search-block">
                    <div className="search-msg">
                        {this.props.subscribeResponse.response && 
                        <span className="login-success"><i className="material-icons">check</i>{this.props.subscribeResponse.response}</span> }
                        {this.props.subscribeResponse.error && 
                        <span className="login-error"><i className="material-icons">close</i>{this.props.subscribeResponse.error}</span> }
                    </div>
                    <div className="search-box">
                        <Field
                            type="search"
                            name="email"
                            placeholder="Enter your Email"
                        />
                        <button 
                            className="submit-button" 
                            type="submit"
                            onClick={handleSubmit}
                            >GO</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserSubscribeForm;