import {
    CLASSES_FETCH_START,
    CLASSES_FETCH_OK,
    CLASSES_FETCH_ERR,
    AIRLINES_FETCH_START,
    AIRLINES_FETCH_OK,
    AIRLINES_FETCH_ERR,
    FLIGHT_SEARCH_SAVE_QUERY
} from '../../actions/flight/flightSearchAction';

export const initialState = {
    searchQuery: JSON.parse(sessionStorage.getItem('flightSearchQuery')) || {
        "direct_flight":"false",
        "one_stop_flight":"false",
        "journey_type":"1",
        "journey_type_name":"One Way",
        "segments": [
            {
                "origin":"JAI",
                "originCityName": "Jaipur",
                "originCityFullName": "Jaipur, India",
                "destination":"",
                "destinationCityName": "",
                "destinationCityFullName": "",
                "preferred_departure_time":"2019-02-08T00:00:00",
                "preferred_arrival_time":"2019-02-08T00:00:00",
                "flight_cabin_class":"1",
                "destinationCountryName": "",
                "originCountryName": "India"
            }
        ],
        "preferred_airlines":[""],
        "adult_count":1,
        "child_count":0,
        "infant_count":0,
        "flightCabinClassName":"Any",
        "preferredAirlinesName":["Any"]
    },
    allClassesData: {
        error :null,
        loading: false,
        response:null
    },
    allAirlinesData: {
        error :null,
        loading: false,
        response:null
    },
    flightSearchTestingDev:false
};

export function flightSearchReducer(state = initialState, action) {
    switch (action.type) {
        case FLIGHT_SEARCH_SAVE_QUERY :
            sessionStorage.setItem("flightSearchQuery", JSON.stringify(action.flightSerarchQuery))
            return {
                ...state,
                searchQuery: action.flightSerarchQuery
            }
        case CLASSES_FETCH_START :
            return {
                ...state, 
                allClassesData : {
                    error   : '',
                    loading : true
                }
            }
        case CLASSES_FETCH_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state, 
                allClassesData : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
            };

        case CLASSES_FETCH_ERR :
            /* reset session to initial state with the error */
            return {
                ...state, 
                allClassesData : {
                    error   : action.error,
                    loading : false
                }
            }
        //================================ For Preferred Airlines ===================================
        case AIRLINES_FETCH_START :
            return {
                ...state, 
                allAirlinesData : {
                    error   : '',
                    loading : true
                }
            }
        case AIRLINES_FETCH_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state, 
                allAirlinesData : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
            };

        case AIRLINES_FETCH_ERR :
            /* reset session to initial state with the error */
            return {
                ...state, 
                allAirlinesData : {
                    error   : action.error,
                    loading : false
                }
            }

        default:
            return state;
    }
}