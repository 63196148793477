//import all files
import { apiRailwayDetailVerfication, apiRailwayPayment, apiGetTrainBookings, apiGetTrainBookingDetail, apiEmailTrainInvoice, apiGetTrainCancelDetail, apiEmailTrainCreditNote } from '../../api/railway';

//Get details from encrypted data
export const GET_DETAIL_VERFICATION_START = 'GET_DETAIL_VERFICATION_START';
export const getDetailVerificationStart = () => {
    return {
        type: GET_DETAIL_VERFICATION_START
    };
};

export const GET_DETAIL_VERFICATION_OK = 'GET_DETAIL_VERFICATION_OK';
export const getDetailVerificationSuccess = (response) => {
    return {
        type: GET_DETAIL_VERFICATION_OK,
        response: response
    };
};

export const GET_DETAIL_VERFICATION_ERR = 'GET_DETAIL_VERFICATION_ERR';
export const getDetailVerificationError = (response) => {
    return {
        type: GET_DETAIL_VERFICATION_ERR,
        response: response
    };
};

export const getDetailVerification = (data, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getDetailVerificationStart());
        return apiRailwayDetailVerfication(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(getDetailVerificationSuccess(response));
                } else {
                    dispatch(getDetailVerificationError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Call payment verification 
export const RAILWAY_PAYMENT_START = 'RAILWAY_PAYMENT_START';
export const railwayPaymentStart = () => {
    return {
        type: RAILWAY_PAYMENT_START
    };
};

export const RAILWAY_PAYMENT_OK = 'RAILWAY_PAYMENT_OK';
export const railwayPaymentSuccess = (response) => {
    return {
        type: RAILWAY_PAYMENT_OK,
        response: response
    };
};

export const RAILWAY_PAYMENT_ERR = 'RAILWAY_PAYMENT_ERR';
export const railwayPaymentError = (response) => {
    return {
        type: RAILWAY_PAYMENT_ERR,
        response: response
    };
};

export const railwayPayment = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(railwayPaymentStart());
        return apiRailwayPayment(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(railwayPaymentSuccess(response));
                    successCb && successCb(response);
                } else {
                    dispatch(railwayPaymentError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Get train bookings
export const GET_TRAIN_BOOKINGS_START = 'GET_TRAIN_BOOKINGS_START';
export const getTrainBookingStart = () => {
    return {
        type: GET_TRAIN_BOOKINGS_START
    };
};

export const GET_TRAIN_BOOKINGS_OK = 'GET_TRAIN_BOOKINGS_OK';
export const getTrainBookingOk = (response) => {
    return {
        type: GET_TRAIN_BOOKINGS_OK,
        response: response
    };
};

export const GET_TRAIN_BOOKINGS_ERR = 'GET_TRAIN_BOOKINGS_ERR';
export const getTrainBookingError = (response) => {
    return {
        type: GET_TRAIN_BOOKINGS_ERR,
        response: response
    };
};

export const getTrainBookings = (data, queryString, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getTrainBookingStart());
        return apiGetTrainBookings(data, queryString)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(getTrainBookingOk(response));
                    successCb && successCb(response);
                } else {
                    dispatch(getTrainBookingError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Get invoice details
export const GET_TRAIN_BOOKING_DETAIL_START = 'GET_TRAIN_BOOKING_DETAIL_START';
export const getTrainBookingDetailStart = () => {
    return {
        type: GET_TRAIN_BOOKING_DETAIL_START
    };
};

export const GET_TRAIN_BOOKING_DETAIL_OK = 'GET_TRAIN_BOOKING_DETAIL_OK';
export const getTrainBookingDetailOk = (response) => {
    return {
        type: GET_TRAIN_BOOKING_DETAIL_OK,
        response: response
    };
};

export const GET_TRAIN_BOOKING_DETAIL_ERR = 'GET_TRAIN_BOOKING_DETAIL_ERR';
export const getTrainBookingDetailError = (response) => {
    return {
        type: GET_TRAIN_BOOKING_DETAIL_ERR,
        response: response
    };
};

export const getTrainBookingDetail = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getTrainBookingDetailStart());
        return apiGetTrainBookingDetail(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(getTrainBookingDetailOk(response));
                    successCb && successCb(response);
                } else {
                    dispatch(getTrainBookingDetailError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Email invoice for train
export const EMAIL_TRAIN_INVOICE_START = 'EMAIL_TRAIN_INVOICE_START';
export const emailTrainInvoiceStart = () => {
    return {
        type: EMAIL_TRAIN_INVOICE_START
    };
};

export const EMAIL_TRAIN_INVOICE_OK = 'EMAIL_TRAIN_INVOICE_OK';
export const emailTrainInvoiceOk = (response) => {
    return {
        type: EMAIL_TRAIN_INVOICE_OK,
        response: response
    };
};

export const EMAIL_TRAIN_INVOICE_ERR = 'EMAIL_TRAIN_INVOICE_ERR';
export const emailTrainInvoiceError = (response) => {
    return {
        type: EMAIL_TRAIN_INVOICE_ERR,
        response: response
    };
};

export const emailTrainInvoice = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(emailTrainInvoiceStart());
        return apiEmailTrainInvoice(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(emailTrainInvoiceOk(response));
                    successCb && successCb(response);
                } else {
                    dispatch(emailTrainInvoiceError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Get credit note details
export const GET_TRAIN_CANCEL_DETAILS_START = 'GET_TRAIN_CANCEL_DETAILS_START';
export const getTrainCancelDetailStart = () => {
    return {
        type: GET_TRAIN_CANCEL_DETAILS_START
    };
};

export const GET_TRAIN_CANCEL_DETAILS_OK = 'GET_TRAIN_CANCEL_DETAILS_OK';
export const getTrainCancelDetailOk = (response) => {
    return {
        type: GET_TRAIN_CANCEL_DETAILS_OK,
        response: response
    };
};

export const GET_TRAIN_CANCEL_DETAILS_ERR = 'GET_TRAIN_CANCEL_DETAILS_ERR';
export const getTrainCancelDetailError = (response) => {
    return {
        type: GET_TRAIN_CANCEL_DETAILS_ERR,
        response: response
    };
};

export const getTrainCancelDetail = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(getTrainCancelDetailStart());
        return apiGetTrainCancelDetail(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(getTrainCancelDetailOk(response));
                    successCb && successCb(response);
                } else {
                    dispatch(getTrainCancelDetailError(response));
                    errorCb && errorCb();
                }
            });
    }
};

//Email invoice for train
export const EMAIL_TRAIN_CREDIT_NOTE_START = 'EMAIL_TRAIN_CREDIT_NOTE_START';
export const emailTrainCreditNoteStart = () => {
    return {
        type: EMAIL_TRAIN_CREDIT_NOTE_START
    };
};

export const EMAIL_TRAIN_CREDIT_NOTE_OK = 'EMAIL_TRAIN_CREDIT_NOTE_OK';
export const emailTrainCreditNoteOk = (response) => {
    return {
        type: EMAIL_TRAIN_CREDIT_NOTE_OK,
        response: response
    };
};

export const EMAIL_TRAIN_CREDIT_NOTE_ERR = 'EMAIL_TRAIN_CREDIT_NOTE_ERR';
export const emailTrainCreditNoteError = (response) => {
    return {
        type: EMAIL_TRAIN_CREDIT_NOTE_ERR,
        response: response
    };
};

export const emailTrainCreditNote = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(emailTrainCreditNoteStart());
        return apiEmailTrainCreditNote(data)
            .then((response) => {
                if (response && response.status === 'ok') {
                    dispatch(emailTrainCreditNoteOk(response));
                    successCb && successCb(response);
                } else {
                    dispatch(emailTrainCreditNoteError(response));
                    errorCb && errorCb();
                }
            });
    }
};

