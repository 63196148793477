import React, { Component } from 'react';
import RailwayDetailVerification from '../../components/railway/RailwayDetailVerificationComponent';
import { connect } from 'react-redux'
import { getDetailVerification, railwayPayment } from '../../actions/railway/railwayAction';

class RailwayDetailVerificationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailError: false
        }
    }

    //Function to make payment and get encripted data from URL and send it into return URL from train-ticket-info API
    makePayment = () => {
        let encdata = this.props.history.location.search.split("=");
        let data = {
            encdata: encdata[1]
        }
        const successCb = (response) => {
            if (response.result && response.result && response.result !== "" && response.result.encrypted_response && response.result.encrypted_response !== "") {
                let redirect = this.props.detailVerification.response && this.props.detailVerification.response.ticket_info.return_url;
                window.location = redirect + "?encdata=" + response.result.encrypted_response;
            }
        }
        const errorCb = () => {
            alert('Payment failed');
        }
        this.props.railwayPayment(data, successCb, errorCb);

    }
    componentDidMount() {
        let encdata = this.props.history.location.search.split("=");
        let data = {
            encdata: encdata[1]
        }
        const errorCb = () => {
            this.setState({
                detailError: true
            })
        }
        this.props.getDetailVerification(data, errorCb); //Calling train-ticket-info API to get details of booking
    }

    render() {
        return (
            <RailwayDetailVerification
                {...this.state}
                {...this.props}
                makePayment={this.makePayment}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        detailVerification: state.railwayReducer.detailVerification,
        railwayPaymentDetail: state.railwayReducer.railwayPaymentDetail
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailVerification: (credentials, errorCb) => dispatch(getDetailVerification(credentials, errorCb)),//Calling train-ticket-info API to get details of booking
        railwayPayment: (credentials, successCb, errorCb) => dispatch(railwayPayment(credentials, successCb, errorCb)), //Calling railway payment API
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RailwayDetailVerificationContainer);