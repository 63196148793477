/* external dependencies */
import React from 'react'
import TermsAndConditionsComponent from '../../components/shared/TermsAndConditionsComponent';
import CancellationTermsAndConditionsComponent from '../../components/shared/CancellationTermsAndConditions';
import TopUpTermsAndConditionsComponent from '../../components/shared/TopUpTermsAndConditions';

class TermsAndConditionsContainer extends React.Component {
    render() {
        let {pagesResponse, type} = this.props;
        let TargetComponent;
        if(type === "/terms-and-conditions")
            TargetComponent = TermsAndConditionsComponent
        else if(type === "/topup-terms-and-conditions")
        {
            TargetComponent = TopUpTermsAndConditionsComponent
        }    
        else
            TargetComponent = CancellationTermsAndConditionsComponent
        return (
            <TargetComponent pagesResponse={pagesResponse} />

        )
    }
}

export default (TermsAndConditionsContainer)