import React from 'react';
import * as common from './commonHelper';

export const getDuration = (segments, format) => {
	
	let startTime, endTime;
	if(Array.isArray(segments))
	{
		startTime = segments[0].origin.dep_time;
		endTime = segments[segments.length-1].destination.arr_time;
	}
	else	
	{
		startTime = segments.origin.dep_time;
		endTime = segments.destination.arr_time;
	}
	let totalDuration = common.timeDifference(startTime, endTime);
	let tmp = common.minutesToDuration(totalDuration, format);

	return tmp;
};

// export const getCommonDuration = (segments, format) => {
// 	let acumulatedDuration = 0;
// 	if(Array.isArray(segments))
// 	{
// 		for(let i=0;i<segments.length;i++)
// 		{
// 			acumulatedDuration =  Number(acumulatedDuration) + Number(segments[i].duration) + Number(segments[i].ground_time);
// 			if(!acumulatedDuration)
// 			{
// 				let startTime = segments[0].stop_point_departure_time;
// 				let endTime = segments[segments.length-1].stop_point_arrival_time;
// 				acumulatedDuration =  Number(acumulatedDuration) + common.timeDifference(startTime, endTime);
// 			}
// 		}
// 	}
// 	else	
// 	{
// 		acumulatedDuration =  Number(acumulatedDuration) + Number(segments.duration) + Number(segments.ground_time);
// 		if(!acumulatedDuration)
// 		{
// 			let startTime = segments.stop_point_departure_time;
// 			let endTime = segments.stop_point_arrival_time;
// 			acumulatedDuration = Number(acumulatedDuration) + common.timeDifference(startTime, endTime);
// 		}
// 	}
// 	let tmp = common.minutesToDuration(acumulatedDuration, format);
// 	return tmp;
// }

export const getCommonDuration = (segments, format, api_source,data) => {
	let acumulatedDuration = 0;
	var triplayover=0;	
	var datatrip = []
	segments&&segments.map(function (stopSegment, stopSegmentIndex) {
		 datatrip.push((stopSegmentIndex !== 0 && common.timeDifference(segments[stopSegmentIndex - 1].destination.arr_time, stopSegment.origin.dep_time) > 0) ?
			(common.timeDifference(segments[stopSegmentIndex-1 ].destination.arr_time, stopSegment.origin.dep_time)) : '')
			return null;
	})
	for (let i = 1; i < datatrip.length; i++) {
        triplayover += datatrip[i];
    }
   
	var getlayover=triplayover/2
	var getlayovers=((Math.round(triplayover))/3)
	if(Array.isArray(segments))
	{
		acumulatedDuration = Number(acumulatedDuration) + (segments[segments.length-1].accumulated_duration) ? Number(segments[segments.length-1].accumulated_duration) : 0;
		if(api_source === "tbo" && !acumulatedDuration && data && !data.is_vendor)
		{
			for(let i=0;i<segments.length;i++)
			{
				acumulatedDuration = Number(acumulatedDuration) + Number(segments[i].duration) + Number(segments[i].ground_time);
			}
		}
		if (api_source === "tripjack" && !acumulatedDuration && data && !data.is_vendor) {
			for (let i = 0; i < segments.length; i++) {
				if (datatrip.length === 2) {
					acumulatedDuration = Number(acumulatedDuration) + Number(segments[i].duration) + Number((getlayover))
				}
				if (datatrip.length === 3) {
					acumulatedDuration = Number(acumulatedDuration) + Number(segments[i].duration) + Number(Math.round(getlayovers))
				}
				if (datatrip.length === 1) {
					acumulatedDuration = Number(acumulatedDuration) + Number(segments[i].duration)
				}
			}
		}
		//If there is accumulated duration node is emty and Duration node is also empty then get duration from departure and arrival date difference
		if(!acumulatedDuration){

			let startTime = segments[0].origin.dep_time;
			let endTime = segments[segments.length-1].destination.arr_time;
			acumulatedDuration = Number(acumulatedDuration) + common.timeDifference(startTime, endTime);
		}
	}
	else	
	{
		acumulatedDuration = Number(acumulatedDuration) + Number(segments.accumulated_duration);
	}
	let tmp;
	if(format === 'N/A')
	{
		tmp = acumulatedDuration;
	}else{
		tmp = common.minutesToDuration(acumulatedDuration, format);
	}
	
	return tmp;
}

export const getDepartureDate = (departureTime, format) => {
	return common.dateFormat(departureTime, format);
};

export const getDepartureTime = (departureTime) => {
	return common.timeFormatHhMm(departureTime);
};

export const getArivalDate = (segments) => {
	return common.dateFormat(segments[segments.length - 1].destination.arr_time, 'DD MMM');
};

export const getArivalTime = (segments) => {
	return common.timeFormatHhMm(segments[segments.length - 1].destination.arr_time);
};

export const getCombinedAirlineName = (segments) => {
	let airlineNames = []
	segments && segments.map(function(segment, index) {
		return airlineNames.push(segment.airline.airline_name)
	});

	airlineNames = common.arrayUnique(airlineNames);
	if(airlineNames.length === 1)
		return airlineNames[0];
	else
		return "Multiple";
};

export const getCombinedAirlineCode = (segments) => {
	let airlineCodes = []
	segments.map(function (segment, index) {
		return airlineCodes.push(segment.airline.airline_code + (segment.airline.flight_number ? ('-' + segment.airline.flight_number) : ''))
	});

	airlineCodes = common.arrayUnique(airlineCodes);
	if(airlineCodes.length === 1)
		return airlineCodes[0];
	else
		return airlineCodes.join(', ');
};

export const getCombinedAirlineCodeForGDSRound = (segments) => {
	let airlineCodes = []
		segments.map(function (item1, index) {
			item1 && item1.map(function (item2, indx2) {
				return airlineCodes.push(item2.airline.airline_code + '-' + item2.airline.flight_number)
			})
			return null;
		})
		airlineCodes = common.arrayUnique(airlineCodes);
		if(airlineCodes.length === 1)
			return airlineCodes[0];
		else
			return airlineCodes.join(', ');
};

export const getAirlineNameAndCode = (segment) => {
	let airlineName = segment.airline.airline_name;
	let airlineCode = segment.airline.flight_number ? (segment.airline.airline_code + '-' + segment.airline.flight_number) : (segment.airline.airline_code);
	return airlineName + ', ' + airlineCode;
};

export const getJournyType = (segments) => {
	// let count = 0;
	let stop_point_count=0

	segments.forEach(element => {
		if(element && element.techStop)
			// count++;
		if(element && element.stop_over === true){
			stop_point_count++
		}
		
	});
	let totalStop = segments.length + stop_point_count - 1;

	return totalStop > 0 ? totalStop + ' Stops': "Non Stop";

	// if ((segments.length + count) === 1){
	// 	if(segments[0] && segments[0].stop_over===true){
	// 		return count+1 + " Stops"
	// 	}
	// 	else{
	// 		return 'Non Stop';
	// 	}
		
	// }else if(segments.length && segments[1] && segments[1].stop_over==true){
	// 	return segments.length + count + " Stops"
	// }else if(segments.length && segments[0] && segments[0].stop_over==true){
	// 	return segments.length + count + " Stops"
	// }
	// else{
	// 	return (segments.length + count - 1) + ' Stops';
	// }
};

export const getJournyTypeTripJack = (segments) => {
	// let count = 0
	let stop_point_count=0
	segments.forEach(element => {
		if(element.techStop)
			// count++;
			if(element.stop_over && element.stop_over.length){
				stop_point_count++
			}
	});
	let totalStop = segments.length + stop_point_count - 1;
	return totalStop > 0 ? totalStop + ' Stops': "Non Stop";
	// if ((segments.length + count) === 1) {
	// 	if (segments[0] && segments[0].stop_over) {
	// 		if (segments[0].stop_over.length >= 1) {
	// 			return (segments[0].stop_over.length) + ' Stops'
	// 		}
	// 		else if (segments[0].stop_over === '' || segments[0].stop_over === '0' || segments[0].stop_over === 0 || segments[0].stop_over === null || segments[0].stop_over.length === 0) {
	// 			return 'Non Stop';
	// 		}
	// 	}else if(segments[1] && segments[1].stop_over){
	// 		if (segments[1].stop_over.length >= 1) {
	// 			return (segments[1].stop_over.length) + ' Stops'
	// 		}
	// 	} else {
	// 		return 'Non Stop';
	// 	}
	// } else{
	// 	 if (segments.length && segments[1] && segments[1].stop_over && segments[1].stop_over.length) {
	// 		return segments[1].stop_over.length + count + 1 + ' Stops'
	// 	}else if(segments.length && segments[0] && segments[0].stop_over && segments[0].stop_over.length){
	// 		return segments[0].stop_over.length + count + 1 + ' Stops'
	// 	}
	// 	else {
	// 		return (segments.length + count - 1) + ' Stops';
	// 	}
	// }
	
};

export const getCityCodeTripJack = (segments) => {
	if(segments.stop_over && segments.stop_over.length > 0) {
		return segments.stop_over[0].full_name
	}
	return null
};

export const isRefundable = (type) => {
	if (type === true)
		return (<span className="green-border-btn">Refundable</span>);
	else
		return (<span className="red-border-btn">Non Refundable</span>);

};

export const getPaxSummary = (adult, child, infant) => {
	let summary = [];

	if(parseInt(adult) === 1)
		summary.push("1 Adult");
	else if(parseInt(adult) > 1)
		summary.push(adult + " Adults");

	if(parseInt(child) === 1)
		summary.push("1 Child");
	else if(parseInt(child) > 1)
		summary.push(child + " Children");

	if(parseInt(infant) === 1)
		summary.push("1 Infant");
	else if(parseInt(infant) > 1)
		summary.push(infant + " Infants");

	return summary.join(' + ');
};

export const getUniqueAirlines = (allFlights) => {
	let airlineArr = []
	allFlights && allFlights.map(function(singleFlight, index) {
		return singleFlight.segments[0].map(function(segment, index) {
			return segment.airline.airline_name && airlineArr.push(segment.airline.airline_name)
		});
	});
	return common.arrayUnique(airlineArr);
};


export const getAllUniqueLayovers = (allFlights) => {
	let layoverArr = []
	allFlights && allFlights.map(function(singleFlight, index) {
		return singleFlight.segments[0].map(function(segment, index) {
				if(index > 0)
				return layoverArr.push([segment.origin.airport.airport_code, segment.origin.airport.city_name])
			else
				return false;
		});


	});
	let data = common.arrayUnique(layoverArr);
	data = data.reduce((acc, current) => {
		const index = acc.findIndex(item => item[0] === current[0]);
		if (index === -1) {
			acc.push(current);
		}
		return acc;
	}, []);
	data = data.reduce((acc, current) => {
		const index = acc.findIndex(item => item[1] === current[1]);
		if (index === -1) {
			acc.push(current);
		}
		return acc;
	}, []);
	return data
};

export const getAllUniqueDestination = (allFlights) => {
	let destinationArr = []
	allFlights && allFlights.map(function(singleFlight, index) {
		return singleFlight.segments[0].map(function(segment, index) {
			if(index === singleFlight.segments[0].length-1)
				return segment.destination && destinationArr.push([segment.destination.airport.airport_code, segment.destination.airport.city_name+'('+segment.destination.airport.airport_code+')'])
			else
				return false;
		});
	});
	let data = common.arrayUnique(destinationArr);
	data = data.reduce((acc, current) => {
		const index = acc.findIndex(item => item[0] === current[0]);
		if (index === -1) {
			acc.push(current);
		}
		return acc;
	}, []);
	return data;
};

export const getSingleFlightLayoversCodes = (singleFlight) => {
	let layoverArr = []
	singleFlight && singleFlight.segments && singleFlight.segments[0].map(function(segment, index) {
		if(index > 0)
			return layoverArr.push(segment.origin.airport.airport_code)
		else
			return false;
	});
	return layoverArr;
};

export const getSingleFlightMultiLayoversCodes = (singleFlight,segmentStops) => {
	let layoverArr = []
	const segmentIndex = segmentStops[0];
	singleFlight && singleFlight.segments && singleFlight.segments[segmentIndex].map(function(segment, index) {
		if(index > 0)
			return layoverArr.push(segment.origin.airport.airport_code)
		else
			return false;
	});
	return layoverArr;
};

export const getSingleFlightDestinationCodes = (singleFlight) => {
	let layoverArr = []
	singleFlight && singleFlight.segments && singleFlight.segments[0].map(function(segment, index) {
		if(index === singleFlight.segments[0].length-1)
			return layoverArr.push(segment.destination.airport.airport_code)
		else
			return false;
	});
	return layoverArr;
};

export const getSingleFlightMultiDestinationCodes = (singleFlight,segmentStops) => {
	let layoverArr = []
	const segmentIndex = segmentStops[0];
	singleFlight && singleFlight.segments && singleFlight.segments[segmentIndex].map(function(segment, index) {
		if(index === singleFlight.segments[segmentIndex].length-1)
			return layoverArr.push(segment.destination.airport.airport_code)
		else
			return false;
	});
	return layoverArr;
};


export const getSingleFlightAirlinesCodes = (singleFlight) => {
	let airlineArr = []
	singleFlight && singleFlight.segments && singleFlight.segments[0].map(function(segment, index) {
		return airlineArr.push(segment.airline.airline_code)
	});
	return airlineArr;
};

export const getSingleFlightMultiAirlinesCodes = (singleFlight,segmentStops) => {
	let airlineArr = []
	const segmentIndex = segmentStops[0];
	singleFlight && singleFlight.segments && singleFlight.segments[segmentIndex].map(function(segment, index) {
		return airlineArr.push(segment.airline.airline_code)
	});
	return airlineArr;
};

export const makeFilterSelected = (searchValue, searchArr) => {
	if(searchValue !== undefined && searchArr !== undefined && searchArr.includes(searchValue)){
		return 'active';
	}
	else{
		return '';
	}
};

export const filterResults = (filterArr, allFlights,sortingFilters,tripType,jounrey_type) => {
	for (let index in filterArr) {
		if(filterArr && filterArr[index] && filterArr[index].length > 0)
		{
			if (index === 'stops') {
				if(jounrey_type === 'multi-city'){
					if (filterArr['stops'].length > 0) {
						if (filterArr['segmentStops'] && filterArr['segmentStops'].length > 0) {
							allFlights = allFlights.filter(singleFlight => filterArr[index].includes(getStopsFilterCodeMulti(singleFlight, filterArr['stops'], filterArr['segmentStops'])) === true);
						}else{} 
					}
				}else{
					allFlights = allFlights.filter(singleFlight => filterArr[index].includes(getStopsFilterCode(singleFlight)) === true);

					}
				
			}
			if(index === 'departTime')
			{
				
				if (jounrey_type === 'multi-city') {
					allFlights = allFlights.filter(singleFlight => filterArr[index].includes(getDepartTimeMultiFilterCode(singleFlight, filterArr['segmentStops'], jounrey_type)) === true);
				} else {
					allFlights = allFlights.filter(singleFlight => filterArr[index].includes(getDepartTimeFilterCode(singleFlight)) === true);
				}
			}
			if(index === 'fareType')
			{
				allFlights = allFlights.filter(singleFlight => filterArr[index].includes(getRefundableFilterCode(singleFlight)) === true);
			}
			if (index === 'airlines') {
				if (jounrey_type === 'multi-city') {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightMultiAirlinesCodes(singleFlight, filterArr['segmentStops'], jounrey_type)).length > 0);
				} else {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightAirlinesCodes(singleFlight)).length > 0);
				}
			}
			if (index === 'layovers') {
				if (jounrey_type === 'multi-city') {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightMultiLayoversCodes(singleFlight, filterArr['segmentStops'], jounrey_type)).length > 0);
				} else {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightLayoversCodes(singleFlight)).length > 0);
				}
			}
			if (index === 'destinations') {
				if (jounrey_type === 'multi-city') {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightMultiDestinationCodes(singleFlight, filterArr['segmentStops'], jounrey_type)).length > 0);
				} else {
					allFlights = allFlights.filter(singleFlight => common.arrayIntersection(filterArr[index], getSingleFlightDestinationCodes(singleFlight)).length > 0);
				}
			}
			if (index === 'flight_number' && filterArr && filterArr[index]) {
				const segmentIndex = filterArr['segmentStops'][0];
				let filteredFlights = [];
				const filterInput = filterArr[index].trim(); // Trim the input to remove any leading or trailing spaces
			
				// Check if the filter input includes alphabets or a hyphen
				const includesAlphabets = /[a-zA-Z]/.test(filterInput);
				const includesHyphen = filterInput.includes('-');
			
				if (!includesAlphabets && !includesHyphen) {
					// If the filter input contains only numbers (no alphabets or hyphen)
					const flightNumber = filterInput;
					for (let i = 0; i < allFlights.length; i++) {
						const item = allFlights[i];
						const segments = item.segments;
						const segment = segments && segments[segmentIndex];
						for (let k = 0; k < segment.length; k++) {
							const segmentItem = segment[k];
							// Check if flight number matches
							const numberMatches = segmentItem.airline.flight_number.startsWith(flightNumber);
							if (numberMatches) {
								filteredFlights.push(item);
								// Break out of the loop once a match is found
								break;
							}
						}
					}
				} else {
					// If the filter input includes alphabets or a hyphen
					const [airlineCode, flightNumber] = filterInput.split('-').map(part => part.trim());
					for (let i = 0; i < allFlights.length; i++) {
						const item = allFlights[i];
						const segments = item.segments;
						const segment = segments && segments[segmentIndex];
						for (let k = 0; k < segment.length; k++) {
							const segmentItem = segment[k];
							// Check if airline code matches and either flight number is not provided or it matches
							const codeMatches = !airlineCode || segmentItem.airline.airline_code.toLowerCase().includes(airlineCode.toLowerCase()) || segmentItem.airline.airline_code.toUpperCase().includes(airlineCode.toUpperCase());;
							const numberMatches = !flightNumber || segmentItem.airline.flight_number.startsWith(flightNumber);
							if (codeMatches && numberMatches) {
								filteredFlights.push(item);
								// Break out of the loop once a match is found
								break;
							}
						}
					}
				}
			
				allFlights = filteredFlights;
				// Now 'filteredFlights' array contains the flights that match the filter
			}
		}
		
	};
	
	if(sortingFilters && tripType)
	{
		allFlights = clickSortFlightResults(sortingFilters,allFlights,tripType);
	}
	return allFlights;
};

export const clickSortFlightResults = (sortingFilters,allFlights,tripType) => {

	var sortByData = tripType === 'inbound' ? 'inboundSortBy' : 'sortBy';
	var sortTypeData = tripType === 'inbound' ? 'inboundSortType' : 'sortType';

	if(sortingFilters[sortByData] === 'departTime' || sortingFilters[sortByData] === 'inboundDepartTime')
	{
		allFlights = allFlights.sort((a,b) => {
			return new Date(a.segments[0][0].origin.dep_time).getTime() - 
			    new Date(b.segments[0][0].origin.dep_time).getTime()
		});
		if(sortingFilters[sortTypeData] === 'desc')
		{
			allFlights = allFlights.reverse();
		}
	}
	else if(sortingFilters[sortByData] === 'arriveTime' || sortingFilters[sortByData] === 'inboundArriveTime')
	{
		allFlights = allFlights.sort((a,b) => {
			let segmentLength = a.segments.length - 1;
			let innerSegment = a.segments[segmentLength].length - 1;
			let bSegmentLength = b.segments.length - 1;
			let bInnerSegment = b.segments[bSegmentLength].length - 1;
			return new Date(a.segments[segmentLength][innerSegment].destination.arr_time).getTime() - 
			    new Date(b.segments[bSegmentLength][bInnerSegment].destination.arr_time).getTime()
		});
		if(sortingFilters[sortTypeData] === 'desc')
		{
			allFlights = allFlights.reverse();
		}
	}
	else if(sortingFilters[sortByData] === 'duration' || sortingFilters[sortByData] === 'inboundDuration')
	{
		allFlights = allFlights.sort((a,b) => {
			return getCommonDuration(a.segments[0], 'N/A', a.api_source,a) - 
			getCommonDuration(b.segments[0], 'N/A', b.api_source,b)
		});
		if(sortingFilters[sortTypeData] === 'desc')
		{
			allFlights = allFlights.reverse();
		}
	}
	return allFlights;
}

export const getStopsFilterCode = (flight) => {
	let flightStopsCode;
	if (flight.api_source === 'tripjack') {
		if (flight.segments && flight.segments[0] && flight.segments[0].length === 1) {
			if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over === null) {
				flightStopsCode = 'STOPS_0';
			}
			if (flight.segments && flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over) {
				if (flight.segments[0][0].stop_over.length === 0) {
					flightStopsCode = 'STOPS_0';
				} else if (flight.segments[0][0].stop_over.length >= 1) {
					flightStopsCode = 'STOPS_1';
				}
			}
		} else if (flight.segments && flight.segments[0] && flight.segments[0].length === 2) {
			if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over === null) {
				flightStopsCode = 'STOPS_1';
			} else if (flight.segments[0] && flight.segments[0][1] && flight.segments[0][1].stop_over === null) {
				flightStopsCode = 'STOPS_1';
			}
			if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over && flight.segments[0][0].stop_over.length === 0) {
				flightStopsCode = 'STOPS_1';
			} else if (flight.segments[0] && flight.segments[0][1] && flight.segments[0][1].stop_over && flight.segments[0][1].stop_over.length === 0) {
				flightStopsCode = 'STOPS_1';
			}
			else if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over && flight.segments[0][0].stop_over.length >= 1) {
				flightStopsCode = 'STOPS_2PLUS';
			} else if (flight.segments[0] && flight.segments[0][1] && flight.segments[0][1].stop_over && flight.segments[0][1].stop_over.length >= 1) {
				flightStopsCode = 'STOPS_2PLUS';
			}
		} else {
			flightStopsCode = 'STOPS_2PLUS';
		}
	}
	if (flight.api_source !== 'tripjack') {
		if (flight.segments && flight.segments[0] && flight.segments[0].length === 1) {
			if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over === true) {
				flightStopsCode = 'STOPS_1';
			} else {
				flightStopsCode = 'STOPS_0';
			}

		}
		else if (flight.segments && flight.segments[0] && flight.segments[0].length === 2) {
			if (flight.segments[0] && flight.segments[0][0] && flight.segments[0][0].stop_over === true) {
				flightStopsCode = 'STOPS_2PLUS';
			} else if (flight.segments[0] && flight.segments[0][1] && flight.segments[0][1].stop_over === true) {
				flightStopsCode = 'STOPS_2PLUS';
			} else {
				flightStopsCode = 'STOPS_1';
			}
		}
		else
			flightStopsCode = 'STOPS_2PLUS';
	}
	return flightStopsCode;
};

export const getStopsFilterCodeMulti = (flight, stops, segmentStops) => {
	let flightStopsCode;
	const segmentIndex = segmentStops[0];
	if (flight.api_source === 'tripjack') {
		if (flight.segments && flight.segments[segmentIndex] && flight.segments[segmentIndex].length === 1) {
			if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over === null) {
				flightStopsCode = 'STOPS_0';
			}
			if (flight.segments && flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over) {
				if (flight.segments[segmentIndex][0].stop_over.length === 0) {
					flightStopsCode = 'STOPS_0';
				} else if (flight.segments[segmentIndex][0].stop_over.length >= 1) {
					flightStopsCode = 'STOPS_1';
				}
			}
		} else if (flight.segments && flight.segments[segmentIndex] && flight.segments[segmentIndex].length === 2) {
			if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over === null) {
				flightStopsCode = 'STOPS_1';
			} else if (flight.segments[segmentIndex] && flight.segments[segmentIndex][1] && flight.segments[segmentIndex][1].stop_over === null) {
				flightStopsCode = 'STOPS_1';
			}
			if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over && flight.segments[segmentIndex][0].stop_over.length === 0) {
				flightStopsCode = 'STOPS_1';
			} else if (flight.segments[segmentIndex] && flight.segments[segmentIndex][1] && flight.segments[segmentIndex][1].stop_over && flight.segments[segmentIndex][1].stop_over.length === 0) {
				flightStopsCode = 'STOPS_1';
			}
			else if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over && flight.segments[segmentIndex][0].stop_over.length >= 1) {
				flightStopsCode = 'STOPS_2PLUS';
			} else if (flight.segments[segmentIndex] && flight.segments[segmentIndex][1] && flight.segments[segmentIndex][1].stop_over && flight.segments[segmentIndex][1].stop_over.length >= 1) {
				flightStopsCode = 'STOPS_2PLUS';
			}
		} else {
			flightStopsCode = 'STOPS_2PLUS';
		}
	}
	if (flight.api_source !== 'tripjack') {
		if (flight.segments && flight.segments[segmentIndex] && flight.segments[segmentIndex].length === 1) {
			if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over === true) {
				flightStopsCode = 'STOPS_1';
			} else {
				flightStopsCode = 'STOPS_0';
			}
		}
		else if (flight.segments && flight.segments[segmentIndex] && flight.segments[segmentIndex].length === 2) {
			if (flight.segments[segmentIndex] && flight.segments[segmentIndex][0] && flight.segments[segmentIndex][0].stop_over === true) {
				flightStopsCode = 'STOPS_2PLUS';
			} else if (flight.segments[segmentIndex] && flight.segments[segmentIndex][1] && flight.segments[segmentIndex][1].stop_over === true) {
				flightStopsCode = 'STOPS_2PLUS';
			} else {
				flightStopsCode = 'STOPS_1';
			}
		}
		else
			flightStopsCode = 'STOPS_2PLUS';
	}
	return flightStopsCode;
};
export const getDepartTimeFilterCode = (flight) => {
	let flightDepartTime = common.timeFormatHhMm(flight.segments[0][0].origin.dep_time);
	let flightDepartTimeCode;

	if(flightDepartTime >= '00:00' && flightDepartTime <= '06:00')
		flightDepartTimeCode = '00_06';
	else if(flightDepartTime >= '06:00' && flightDepartTime <= '12:00')
		flightDepartTimeCode = '06_12';
	else if(flightDepartTime >= '12:00' && flightDepartTime <= '18:00')
		flightDepartTimeCode = '12_18';
	else
		flightDepartTimeCode = '18_00';
	return flightDepartTimeCode;
};

export const getDepartTimeMultiFilterCode = (flight,segmentStops) => {
	const segmentIndex = segmentStops[0];
	let flightDepartTime = common.timeFormatHhMm(flight.segments[segmentIndex][0].origin.dep_time);
	let flightDepartTimeCode;

	if(flightDepartTime >= '00:00' && flightDepartTime <= '06:00')
		flightDepartTimeCode = '00_06';
	else if(flightDepartTime >= '06:00' && flightDepartTime <= '12:00')
		flightDepartTimeCode = '06_12';
	else if(flightDepartTime >= '12:00' && flightDepartTime <= '18:00')
		flightDepartTimeCode = '12_18';
	else
		flightDepartTimeCode = '18_00';
	return flightDepartTimeCode;
};

export const getRefundableFilterCode = (flight) => {
	let flightRefundableCode;
	if(flight.is_refundable === true)
		flightRefundableCode = 'REFUNDABLE';
	else
		flightRefundableCode = 'NON_REFUNDABLE';
	return flightRefundableCode;
};

export const getMinFareByFlightStops = (allFlights) => {
	let allStops = [];
	allStops[0] = []; // Non Stop
	allStops[1] = []; // 1 Stop
	allStops[2] = []; // 2+ Stops

	allFlights && allFlights.map(function (singleFlight, index) {
		let totalSegments = singleFlight.segments[0].length;
		if (totalSegments === 1) {
			if (singleFlight.api_source !== 'tripjack') {
				singleFlight && singleFlight.segments && singleFlight.segments[0] && singleFlight.segments[0].map((item, index) => {
					if (item && item.stop_over === false) {
						allStops[0].push(Math.round(singleFlight.fare.published_fare));
					}
				})
			}
			if (singleFlight.api_source === 'tripjack') {
				singleFlight && singleFlight.segments && singleFlight.segments[0] && singleFlight.segments[0].map((item, index) => {
					if ((item && item.stop_over === null)) {
						allStops[0].push(Math.round(singleFlight.fare.published_fare));
					}
					if ((item && item.stop_over && item.stop_over.length === 0)) {
						allStops[0].push(Math.round(singleFlight.fare.published_fare));
					}
					if (item && item.stop_over === true && item.stop_over.length === 0) {
						allStops[0].push(Math.round(singleFlight.fare.published_fare));
					}
				})
			}
		}
		else if (totalSegments === 2) {
			if (singleFlight.api_source !== 'tripjack') {
				singleFlight && singleFlight.segments && singleFlight.segments[0] && singleFlight.segments[0].map((item, index) => {
					if (item && item.stop_over === false) {
						allStops[1].push(Math.round(singleFlight.fare.published_fare));
					}
				})
			}
			if (singleFlight.api_source === 'tripjack') {
				singleFlight && singleFlight.segments && singleFlight.segments[0] && singleFlight.segments[0].map((item, index) => {
					if ((item && item.stop_over === null)) {
						allStops[1].push(Math.round(singleFlight.fare.published_fare));
					}
					if ((item && item.stop_over && item.stop_over.length === 0)) {
						allStops[1].push(Math.round(singleFlight.fare.published_fare));
					}
				})
			}
		}
		else {
			allStops[2].push(Math.round(singleFlight.fare.published_fare));
		}
		return true;
	});
	allStops[0].sort(function(a, b){return a-b});
	allStops[1].sort(function(a, b){return a-b});
	allStops[2].sort(function(a, b){return a-b});
	return allStops;
};

export const getMinFareByFlightStopsMulti = (allFlights) => {
	let allStops = [];
	allStops[0] = []; // Non Stop
	allStops[1] = []; // 1 Stop
	allStops[2] = []; // 2+ Stops
	allFlights && allFlights.map(function(singleFlight, index) {
		singleFlight && singleFlight.segments && singleFlight.segments.map(function(singleFlightInner, innerIndex) {
			let totalSegments = singleFlightInner.length;
			if(totalSegments === 1)
				allStops[0].push(Math.round(singleFlight.fare.published_fare));
			else if(totalSegments === 2)
				allStops[1].push(Math.round(singleFlight.fare.published_fare));
			else
				allStops[2].push(Math.round(singleFlight.fare.published_fare));
			return true;
		})
	});
	allStops[0].sort(function(a, b){return a-b});
	allStops[1].sort(function(a, b){return a-b});
	allStops[2].sort(function(a, b){return a-b});
	return allStops;
};

 //Function to get fares for booking request JSON
export const getFare = (values) =>{
	let fare = null
	if(values){
		fare = {
			"published_fare":values.results.fare.published_fare,
			"offered_fare":values.results.fare.offered_fare,
			"other_charges":values.results.fare.other_charges,
			"commission_earned":values.results.fare.commission_earned,
			"plb_earned":values.results.fare.plb_earned,
			"incentive_earned" : values.results.fare.incentive_earned,
			"tds_on_incentive":values.results.fare.tds_on_incentive,
			"tax":values.results.fare.tax,
			"total_baggage_charges":values.results.fare.total_baggage_charges,
			"total_meal_charges":values.results.fare.total_meal_charges,
			"total_seat_charges":values.results.fare.total_seat_charges,
			"tds_on_commission" : values.results.fare.tds_on_commission,
			"tds_on_plb" : values.results.fare.tds_on_plb
		}
	}
	return fare;
}

 //Function to get passenger details for booking request JSON
 export const getPassengerDetails = (values,fareQuote) =>{
	//Get number of pax excluding infant pax
	let paxCount = 0;
	for(let j=0;j<values.length;j++){
		if(parseInt(values[j].passenger_type) !==  3){
			paxCount = paxCount + 1;
		}
	}
	let k3Gst = 0;
	if(fareQuote.results.fare.tax_breakup){
		for(let i = 0;i<fareQuote.results.fare.tax_breakup.length;i++){
				if(fareQuote.results.fare.tax_breakup[i].key === 'K3'){
				k3Gst = fareQuote.results.fare.tax_breakup[i].value;
			}
		}
	}
	let passengersList = [];
	if(values){
		passengersList = values.map((item,index) => {
			let title;
            if (item.passenger_type === 1 && (item.title_name === "Mr" || item.title_name === "Mstr" || item.title_name === "Miss")) {
                title = "Mr";
            } else if ((item.passenger_type === 2 || item.passenger_type === 3) && (item.title_name === "Mr" || item.title_name === "Ms" || item.title_name === "Mrs")) {
                title = "Mstr";
            } else {
                title = item.title_name && item.title_name;
            }
			//Calculate adult and child offered fare
			let tax = item.fare_distribution && parseInt(item.fare_distribution.tax)/parseInt(item.fare_distribution.passenger_count);
			let baseFare = item.fare_distribution && parseInt(item.fare_distribution.base_fare)/parseInt(item.fare_distribution.passenger_count);
			let otherCharges = parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.other_charges && fareQuote.results.fare.other_charges !== 0 ? Number(fareQuote.results.fare.other_charges)/paxCount:0);
			let publishedFare = Number(tax) + Number(baseFare) + Number(otherCharges);
			let commisionEarned = parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.commission_earned && fareQuote.results.fare.commission_earned !== 0 ? Number(fareQuote.results.fare.commission_earned) / paxCount:0);
			let incentiveEarned = parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.incentive_earned && fareQuote.results.fare.incentive_earned  !== 0 ? Number(fareQuote.results.fare.incentive_earned) / paxCount:0);
			let plbEarned = parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.plb_earned && fareQuote.results.fare.plb_earned  !== 0 ? Number(fareQuote.results.fare.plb_earned) / paxCount:0);
			let offeredFare = Number(publishedFare) - Number(commisionEarned) - Number(incentiveEarned) - Number(plbEarned);
			return ({
				"title": title,
				"first_name": item.first_name && item.first_name.toUpperCase(),
				"last_name": item.last_name && item.last_name.toUpperCase(),
				"pax_type": parseInt(item.passenger_type),
				"date_of_birth": item.date_of_birth,
				"senior_citizen_id":item.senior_citizen_id,
				"gender": item.gender === 'male'?'1':'2',
				"passport_no": item.passport_no,
				"passport_expiry": item.passport_exp,
				"passport_issue_date": item.passport_issue_date,
				"passport_issue_country_code": item.passport_issue_country_code,
				"address_line_1": item.addressLine1,
				"address_line_2": item.addressLine2,
                "nationality_short_name":item.nationality_short_name,
				"fare": {
					"currency": item.fare_distribution && item.fare_distribution.currency,
					"base_fare": baseFare,
					"tax": tax,
					"transaction_fee": fareQuote && fareQuote.results && fareQuote.results.fare.transaction_fee ? fareQuote.results.fare.transaction_fee : 0.0,
					"yq_tax":  parseInt(item.passenger_type) === 3 ? 0 :((item.fare_distribution && item.fare_distribution.yqtax) ? (parseInt(item.fare_distribution.yqtax)/parseInt(item.fare_distribution.passenger_count)) : 0),
					"additional_txn_fee_pub": item.fare_distribution &&
					(item.fare_distribution.additional_txn_fee_pub ? (parseInt(item.fare_distribution.additional_txn_fee_pub)/parseInt(item.fare_distribution.passenger_count)) : 0),
					"additional_txn_fee_ofrd": item.fare_distribution &&
					(item.fare_distribution.additional_txn_fee_ofrd ? (parseInt(item.fare_distribution.additional_txn_fee_ofrd)/parseInt(item.fare_distribution.passenger_count)) : 0),
					"air_trans_fee": fareQuote && fareQuote.results && fareQuote.results.fare.air_trans_fee ? fareQuote.results.fare.air_trans_fee : 0.0,
					"service_fee":fareQuote && fareQuote.results.fare.service_fee && fareQuote.results.fare.service_fee !== 0 ? Number(fareQuote.results.fare.service_fee)/values.length:0,
					"other_charges": otherCharges,
					"discount":fareQuote && fareQuote.results.fare.discount && fareQuote.results.fare.discount !== 0 ? Number(fareQuote.results.fare.discount)/values.length:0,
					"commission_earned": parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.commission_earned && fareQuote.results.fare.commission_earned !== 0 ? Number(fareQuote.results.fare.commission_earned) / paxCount:0),
					"plb_earned": parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.plb_earned && fareQuote.results.fare.plb_earned  !== 0 ? Number(fareQuote.results.fare.plb_earned) / paxCount:0),
					"incentive_earned": parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.incentive_earned && fareQuote.results.fare.incentive_earned  !== 0 ? Number(fareQuote.results.fare.incentive_earned) / paxCount:0),
					"tds_on_commission":parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.tds_on_commission && fareQuote.results.fare.tds_on_commission !== 0 ? Number(fareQuote.results.fare.tds_on_commission)/paxCount:0),
					"tds_on_plb":parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.tds_on_plb && fareQuote.results.fare.tds_on_plb !== 0 ? Number(fareQuote.results.fare.tds_on_plb)/paxCount:0),
					"tds_on_incentive":parseInt(item.passenger_type) === 3 ? 0 :(fareQuote && fareQuote.results.fare.tds_on_incentive && fareQuote.results.fare.tds_on_incentive !== 0 ? Number(fareQuote.results.fare.tds_on_incentive)/paxCount:0),
					"k3_gst" : parseInt(item.passenger_type) === 3 ? 0 :(k3Gst && k3Gst !== 0 ? Number(k3Gst)/ paxCount: 0),
					"published_fare" : publishedFare,
					"offered_fare" : offeredFare
				},
				"baggage":item.baggage ? item.baggage : null,
				"meal_dynamic":item.meal_dynamic ? item.meal_dynamic :null,
				"seat_dynamic":item.seat_dynamic ? item.seat_dynamic : null,
				"city": item.city_name,
				"country_name": (item.country_name && item.country_name !== '') ? item.country_name : 'India',
				"country_code": (item.country_code && item.country_code !== '') ? item.country_code : 'IN',
				"cell_country_code": (item.cell_country_code && item.cell_country_code !== '') ? item.cell_country_code : '+91',
				"nationality": item.nationality,
				"contact_no": item.mobile_no,
				"email": item.email_id,
				"is_lead_pax": item.is_lead_pax,
				"ff_airline_code": item.ff_airline_code,
				"ff_number": item.frequent_flyer_no,
				"gst_company_address": item.gst_company_address,
				"gst_company_contact_number": item.gst_company_contact_no,
				"gst_company_name": item.gst_company_name,
				"gst_number": item.gst_no,
				"gst_company_email": item.gst_company_email,
				"meal"         : item.meal ? item.meal : null,
				"seat"         : item.seat ? item.seat :null,
				'student_id' : item.student_id
			})
		})
	}
	return passengersList;
}

//Function to get segments from fareQuote for book request JSON
export const getSegments = (fareQuote, cabinClassData,searchtype) =>{

	let cabinDataa = []
	
	if ((searchtype === 'one-way' || searchtype === 'multi-city' || searchtype === 'special-return')) {
			cabinClassData && cabinClassData.segments.map((segmentItem) =>{
				segmentItem && cabinDataa.push(segmentItem)
				return null;
			})
	}
	if ((searchtype === 'round-trip' || searchtype === 'round-trip-complete' || searchtype === 'lcc-special-return')) {
		cabinClassData.map((item) =>{
			 item && item.segments.map((segmentItem) =>{
				segmentItem && cabinDataa.push(segmentItem)
				return null;
			 })
			 return null;
		})
	}
	let segment = [];
	/* eslint-disable-next-line */
	fareQuote && fareQuote.results.segments.map((item,index) => {
		/* eslint-disable-next-line */
		 item.map((innerItem,innerIndex) => {
			segment.push({
				"origin" : innerItem.origin.airport.airport_code,
				"destination" : innerItem.destination.airport.airport_code,
				"preferred_departure_time" : innerItem.origin.dep_time,
				"preferred_arrival_time" : innerItem.destination.arr_time,
				"airline_code" : innerItem.airline.airline_code,
				"flight_number" : innerItem.airline.flight_number,
				"origin_airport_name": innerItem.origin.airport.airport_name,
				"origin_airport_terminal": innerItem.origin.airport.terminal,
				"destination_airport_name": innerItem.destination.airport.airport_name,
				"destination_airport_terminal": innerItem.destination.airport.terminal,
				"airline_pnr": innerItem.airline_pnr?innerItem.airline_pnr: null ,
				"fare_class": innerItem.airline.fare_class,
				"craft" : innerItem.craft,
				"cabin_baggage" : innerItem.cabin_baggage?innerItem.cabin_baggage:null,
				"stop_point"    : innerItem.stop_point_full_name ? (innerItem.stop_point_full_name)  : (innerItem && innerItem.stop_over && innerItem.stop_over.length > 0 && innerItem.stop_over[0] && innerItem.stop_over[0].full_name) ? innerItem.stop_over[0].full_name : null,
				"stop_point_arrival_time"    : innerItem.stop_point_arrival_time,
				"stop_point_departure_time"    : innerItem.stop_point_departure_time,
				"baggage": innerItem.baggage?innerItem.baggage:null,
				"segment_id" : innerItem.segment_id?innerItem.segment_id:null,
				'booking_class': innerItem.booking_class ? innerItem.booking_class : null,
				'operating_carrier': innerItem.airline.operating_carrier ? innerItem.airline.operating_carrier : null,
				'operating_carrier_name' : innerItem.airline.operating_carrier_name ? innerItem.airline.operating_carrier_name : null,
				'cabin_class_name' : innerItem.cabin_class_name ?  innerItem.cabin_class_name : null,
				"duration"    : innerItem.duration ? innerItem.duration : null,
			});
		})
	})
	return segment;
}

export const getSegmentsforRiya  = (segments) =>{

	
	
	let segment = [];
	/* eslint-disable-next-line */
	segments.map((item,index) => {
		
		/* eslint-disable-next-line */
		 item.map((innerItem,innerIndex) => {
	
			segment.push({
				"origin" : innerItem.origin_code,
				"destination" : innerItem.destination_code,
				"preferred_departure_time" : innerItem.departure_time,
				"preferred_arrival_time" : innerItem.arrival_time,
				"airline_code" : innerItem.airline_code,
				"flight_number" : innerItem.flight_no,
				"origin_airport_name": innerItem.origin_airport_name,
				"origin_airport_terminal": innerItem.origin_airport_terminal,
				"destination_airport_name": innerItem.destination_airport_name,
				"destination_airport_terminal": innerItem.destination_airport_terminal,
				"airline_pnr": innerItem.airline_pnr?innerItem.airline_pnr: null ,
				"fare_class": innerItem.fare_class,
				"craft" : innerItem.aircraft,
				"cabin_baggage" : innerItem.cabin_baggage?innerItem.cabin_baggage:null,
				"stop_point"  :  innerItem.stop_point ? innerItem.stop_point :null,
				//"stop_point"    : innerItem.stop_point_full_name ? (innerItem.stop_point_full_name)  : (innerItem && innerItem.stop_over && innerItem.stop_over.length > 0 && innerItem.stop_over[0] && innerItem.stop_over[0].full_name) ? innerItem.stop_over[0].full_name : null,
				"stop_point_arrival_time"    : innerItem.stop_point_arrival_time,
				"stop_point_departure_time"    : innerItem.stop_point_departure_time,
				"baggage": innerItem.baggage?innerItem.baggage:null,
				"segment_id" : innerItem.segment_id?innerItem.segment_id:null,
				//"segment_id" : innerItem.segment_id?innerItem.segment_id:null,
				'booking_class': innerItem.fare_class ? innerItem.fare_class: null,
				//'booking_class': innerItem.booking_class ? innerItem.booking_class : null,
				'operating_carrier': innerItem.operating_carrier ? innerItem.operating_carrier : null,
				'operating_carrier_name' : innerItem.operating_carrier_name ? innerItem.operating_carrier_name : null,
				'cabin_class_name' : innerItem.cabin_class_name ?  innerItem.cabin_class_name : null,
				"duration"    : innerItem.duration ? innerItem.duration : null,
			});
		})
	})

	return segment;
}

//Get Depart cityname and time of segment
export const getDepartureCityTime = (data) => {
	let values = [];
	let segmentCount = data.segments.length;
	for(let i = 0 ; i< segmentCount; i++){
		let itemOuter = data.segments[i];
		let itemOuterLength = itemOuter.length;
		for(let j=0;j<itemOuterLength;j++){
			let itemInner = itemOuter[j];
			values.push(<span key={i+'-'+j}>{itemInner.origin.airport.airport_code+'('+getDepartureTime(itemInner.origin.dep_time)+')'}</span>);
		}
	}
	return values;
}

//Get Arrival cityname and time of segment
export const getArrivalCityTime = (data) => {
	let values = [];
	let segmentCount = data.segments.length;
	for(let i = 0 ; i< segmentCount; i++){
		let itemOuter = data.segments[i];
		let itemOuterLength = itemOuter.length;
		for(let j=0;j<itemOuterLength;j++){
			let itemInner = itemOuter[j];
			values.push(<span key={i+'-'+j}>{itemInner.destination.airport.airport_code+'('+getDepartureTime(itemInner.destination.arr_time)+')'}</span>);
		}
	}
	return values;
}

//Get Depart cityname and time of segment for GDS and multicity
export const getGDSDeparuteCityTime = (data,type) => {
	let values = [];
	let segmentCount = data.length;
	for(let i = 0 ; i< segmentCount; i++){
		let itemOuter = data[i];
		if(type === 'multicity'){
			values.push(<span>
				<samp>{itemOuter.origin.airport.airport_code}</samp>
				<samp className="jf-time">({getDepartureTime(itemOuter.origin.dep_time)})</samp>
			</span>);
		}else{
			values.push(<span key={i}><samp>{itemOuter.origin.airport.airport_code}</samp>
				({getDepartureTime(itemOuter.origin.dep_time)})
				</span> );
		}
	}
	return values;
}

//Get Arrival cityname and time of segment for GDS and multicity
export const getGDSArrivalCityTime = (data,type) => {
	let values = [];
	let segmentCount = data.length;
	for(let i = 0 ; i< segmentCount; i++){
		let itemOuter = data[i];
		if(type === 'multicity'){
			values.push(<span><samp>{itemOuter.destination.airport.airport_code}</samp>
				<samp className="jf-time">({getDepartureTime(itemOuter.destination.arr_time)})</samp>
				</span> );
		}else{
			values.push(<span key={i}><samp>{itemOuter.destination.airport.airport_code}</samp>
				({getDepartureTime(itemOuter.destination.arr_time)})
			</span> );
		}
	}
	return values;
}

//Get number of stops in flight
export const getFlightStops = (allFlights) => {
	let allValues = allFlights;
	let stopPoint = [];
	const is_kafila_user = localStorage.getItem('is_kafila_user');
	/* eslint-disable-next-line */
	allValues.map((itemInner,indexInner) => {
		let valueFirst = {};
		if(itemInner.stop_over && itemInner.stop_point !== "" && itemInner.stop_point !== null && is_kafila_user === 'N'){
			
			valueFirst = {
				'origin_airport_code' : itemInner.origin.airport.airport_code,
				'airline_code' : itemInner.airline.airline_code,
				'flight_number':itemInner.airline.flight_number,
				'destination_airport_code' : itemInner.stop_point,
				'origin_dep_time':itemInner.origin.dep_time,
				'destination_arr_time' : itemInner.stop_point_arrival_time,
				'craft' : itemInner.craft,
				'remark' : itemInner.remark,
				'origin_city' : itemInner.origin.airport.city_name,
				'destination_city' : itemInner.stop_point,
				'airline' : itemInner.airline,
				'origin_airport_name' : itemInner.origin.airport.airport_name,
				'destination_airport_name' : '',
				'origin_terminal' : itemInner.origin.airport.terminal,
				'destination_terminal' : '',
				'status' : itemInner.status,
				'stop_over': itemInner.stop_over,
				'cabin_baggage' : itemInner.cabin_baggage,
				'baggage' : itemInner.baggage,
				'cabin_class_name': itemInner.cabin_class_name,
				'operating_carrier_name' : itemInner.operating_carrier_name,
				'operating_carrier' : itemInner.operating_carrier,
				'duration' : itemInner.duration,
				'stop_point':itemInner.stop_point,
				'stop_point_full_name':itemInner.stop_point_full_name
			};
			let valueSecond = {
				'origin_airport_code': itemInner.stop_point,
				'airline_code': itemInner.airline.airline_code,
				'flight_number': itemInner.airline.flight_number,
				'destination_airport_code': itemInner.destination.airport.airport_code,
				'origin_dep_time': itemInner.stop_point_departure_time,
				'destination_arr_time': itemInner.destination.arr_time,
				'craft': itemInner.craft,
				'remark': itemInner.remark,
				'airline' : itemInner.airline,
				'origin_city' : itemInner.stop_point,
				'destination_city' : itemInner.destination.airport.city_name,
				'origin_airport_name' : '',
				'destination_airport_name' : itemInner.destination.airport.airport_name,
				'origin_terminal' : '',
				'destination_terminal' : itemInner.destination.airport.terminal,
				'status' : itemInner.status,
				'stop_over': itemInner.stop_over,
				'cabin_baggage' : itemInner.cabin_baggage,
				'baggage' : itemInner.baggage,
				'cabin_class_name': itemInner.cabin_class_name,
				'operating_carrier_name' : itemInner.operating_carrier_name,
				'operating_carrier' : itemInner.operating_carrier,
				'duration' : itemInner.duration,
				'stop_point':itemInner.stop_point,
				'stop_point_full_name':itemInner.stop_point_full_name
			};
			stopPoint.push(valueFirst);
			stopPoint.push(valueSecond);
		}else if(itemInner.stop_over && itemInner.origin && itemInner.stop_point === ""){
			
			valueFirst = {
				'origin_airport_code' : itemInner.origin.airport.airport_code,
				'airline_code' : itemInner.airline.airline_code,
				'flight_number':itemInner.airline.flight_number,
				'destination_airport_code' : itemInner.destination.airport.airport_code,
				'origin_dep_time':itemInner.origin.dep_time,
				'destination_arr_time' : itemInner.destination.arr_time,
				'craft' : itemInner.craft,
				'remark' : itemInner.remark,
				"techStop" : true,
				'airline' : itemInner.airline,
				'origin_city' : itemInner.origin.airport.city_name,
				'destination_city' : itemInner.destination.airport.city_name,
				'origin_airport_name' : itemInner.origin.airport.airport_name,
				'destination_airport_name' : itemInner.destination.airport.airport_name,
				'origin_terminal' : itemInner.origin.airport.terminal,
				'destination_terminal' : itemInner.destination.airport.terminal,
				'status' : itemInner.status,
				'stop_over': itemInner.stop_over,
				'cabin_baggage' : itemInner.cabin_baggage,
				'baggage' : itemInner.baggage,
				'cabin_class_name': itemInner.cabin_class_name,
				'operating_carrier_name' : itemInner.operating_carrier_name,
				'operating_carrier' : itemInner.operating_carrier,
				'duration' : itemInner.duration,
				'stop_point':itemInner.stop_point,
				'stop_point_full_name':itemInner.stop_point_full_name
			};
			stopPoint.push(valueFirst);
		}
		else{
			
			valueFirst = {
				'origin_airport_code' : itemInner.origin.airport.airport_code,
				'airline_code' : itemInner.airline.airline_code,
				'flight_number':itemInner.airline.flight_number,
				'destination_airport_code' : itemInner.destination.airport.airport_code,
				'origin_dep_time':itemInner.origin.dep_time,
				'destination_arr_time' : itemInner.destination.arr_time,
				'craft' : itemInner.craft,
				'remark' : itemInner.remark,
				'airline' : itemInner.airline,
				'origin_city' : itemInner.origin.airport.city_name,
				'destination_city' : itemInner.destination.airport.city_name,
				'origin_airport_name' : itemInner.origin.airport.airport_name,
				'destination_airport_name' : itemInner.destination.airport.airport_name,
				'origin_terminal' : itemInner.origin.airport.terminal,
				'destination_terminal' : itemInner.destination.airport.terminal,
				'status' : itemInner.status,
				'stop_over': itemInner.stop_over,
				'cabin_baggage' : itemInner.cabin_baggage,
				'baggage' : itemInner.baggage,
				'cabin_class_name': itemInner.cabin_class_name,
				'operating_carrier_name' : itemInner.operating_carrier_name,
				'operating_carrier' : itemInner.operating_carrier,
				'duration' : itemInner.duration,
				'stop_point':itemInner.stop_point,
				'stop_point_full_name':itemInner.stop_point_full_name
			};
			stopPoint.push(valueFirst);
		}
	});
	return stopPoint;
}

export const formatDuration = (duration) => {
	// Split the duration into hours and minutes
	const [hours, minutes] = duration.split(':').map(Number);
  
	// Pad the hours with a leading zero if needed
	const paddedHours = String(hours).padStart(2, '0');
	const paddedMinutes = String(minutes).padStart(2, '0');
  
	// Return the formatted duration
	return `${paddedHours}h:${paddedMinutes}m`;
  }


export const getTicketFlightStops = (allFlights) => {
	let allValues = allFlights;
	let stopPoint = [];
	/* eslint-disable-next-line */
	allValues.map((itemInner,indexInner) => {
		let valueFirst = {};
		if(itemInner.stop_over && itemInner.stop_point !== "" && itemInner.stop_point !== null){
			valueFirst = {
				'origin_code': itemInner.origin_code,
				'origin': itemInner.origin,
				'destination_code': itemInner.stop_point,
				'destination': itemInner.stop_point,
				'departure_time': itemInner.departure_time,
				'arrival_time': itemInner.stop_point_departure_time,
				'flight_no': itemInner.flight_no,
				'airline_name': itemInner.airline_name,
				'airline_code': itemInner.airline_code,
				'airline_helpline_no': itemInner.airline_helpline_no,
				'airline_logo': itemInner.airline_logo,
				'fare_class':itemInner.fare_class,
				'origin_airport_name': itemInner.origin_airport_name,
				'origin_airport_terminal': itemInner.origin_airport_terminal,
				'destination_airport_name': itemInner.stop_point,
				'destination_airport_terminal': '',
				'tbo_flight_status': itemInner.tbo_flight_status,
				'tbo_status':itemInner.tbo_status,
				'airline_pnr': itemInner.airline_pnr,
				'aircraft': itemInner.aircraft,
				'stoppage_destination_city_code': itemInner.stoppage_destination_city_code,
				'stoppage_origin_city_code': itemInner.stoppage_origin_city_code,
				'operator_airline_name': itemInner.operator_airline_name,
				'operator_support_number': itemInner.operator_support_number,
				'operator_airline_code':itemInner.operator_airline_code,
				'operator_airline_logo':itemInner.operator_airline_logo,
				'stop_point':itemInner.stop_point,
				"cabin_class_name":itemInner.cabin_class_name
			};
			let valueSecond = {
				'origin_code': itemInner.stop_point,
				'origin': itemInner.stop_point,
				'destination_code': itemInner.destination_code,
				'destination': itemInner.destination,
				'departure_time': itemInner.stop_point_arrival_time,
				'arrival_time': itemInner.arrival_time,
				'flight_no': itemInner.flight_no,
				'airline_name': itemInner.airline_name,
				'airline_code': itemInner.airline_code,
				'airline_helpline_no': itemInner.airline_helpline_no,
				'airline_logo': itemInner.airline_logo,
				'fare_class':itemInner.fare_class,
				'origin_airport_name': itemInner.stop_point,
				'origin_airport_terminal': '',
				'destination_airport_name': itemInner.destination_airport_name,
				'destination_airport_terminal': itemInner.destination_airport_terminal,
				'tbo_flight_status': itemInner.tbo_flight_status,
				'tbo_status':itemInner.tbo_status,
				'airline_pnr': itemInner.airline_pnr,
				'aircraft': itemInner.aircraft,
				'stoppage_destination_city_code': itemInner.stoppage_destination_city_code,
				'stoppage_origin_city_code': itemInner.stoppage_origin_city_code,
				'operator_airline_name': itemInner.operator_airline_name,
				'operator_support_number': itemInner.operator_support_number,
				'operator_airline_code':itemInner.operator_airline_code,
				'operator_airline_logo':itemInner.operator_airline_logo,
				'stop_point':itemInner.stop_point,
				"cabin_class_name":itemInner.cabin_class_name
			};
			stopPoint.push(valueFirst);
			stopPoint.push(valueSecond);
		}
		else{
			stopPoint.push(itemInner);
		}
	});
	return stopPoint;
}

export const getSearchDetailsFormattedResults = (allFlights, flights) => {
	let finalResultsArrayOutbound = [];
	let finalResultsArrayInbound = [];
	if(allFlights && allFlights[0] && allFlights[0].length > 0)
	{
		finalResultsArrayOutbound = allFlights[0];
	}

	if(flights && flights[0] && flights[0].length > 0)
	{
		finalResultsArrayOutbound = finalResultsArrayOutbound.concat(flights[0]);
	}

	if(allFlights && allFlights[1] && allFlights[1].length > 0)
	{
		finalResultsArrayInbound = allFlights[1];
	}

	if(flights && flights[1] && flights[1].length > 0)
	{
		finalResultsArrayInbound = finalResultsArrayInbound.concat(flights[1]);
	}
	if(finalResultsArrayOutbound.length > 0)
	{
		finalResultsArrayOutbound.sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
	}
	if(finalResultsArrayInbound.length > 0)
	{
		finalResultsArrayInbound.sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
	}
	return [finalResultsArrayOutbound, finalResultsArrayInbound];
}

export const sameFlightFilterResults = (allResults) => {
	let mergedFilghts = [];
	allResults.forEach(function(item, index)
	{
		const combinedAirlineCode = getCombinedAirlineCode(item.segments[0]).replace(/ /g, "");
		if(mergedFilghts[combinedAirlineCode])
		{
			mergedFilghts[combinedAirlineCode].push(item); 
			mergedFilghts[combinedAirlineCode].sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
		}
		else
		{
			mergedFilghts[combinedAirlineCode] = [];
			mergedFilghts[combinedAirlineCode].push(item); 
			mergedFilghts[combinedAirlineCode].sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
		}
	});
	
	return (mergedFilghts);
}

export const sameFlightFilterResultsForGDSRound = (allResults) => {
	let mergedFilghts = [];
	allResults.forEach(function(item, index)
	{
		const combinedAirlineCode = getCombinedAirlineCodeForGDSRound(item.segments).replace(/ /g, "");
		if(mergedFilghts[combinedAirlineCode])
		{
			mergedFilghts[combinedAirlineCode].push(item); 
			mergedFilghts[combinedAirlineCode].sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
		}
		else
		{
			mergedFilghts[combinedAirlineCode] = [];
			mergedFilghts[combinedAirlineCode].push(item); 
			mergedFilghts[combinedAirlineCode].sort((a, b) => (a.fare.offered_fare > b.fare.offered_fare) ? 1 : -1);
		}
	});
	return (mergedFilghts);
}

export const getPendingPnr = (values) => {
	let data = null
	if (values) {
		data = {
			"booking_id": values.response.bookings[0].booking_id,
			"kaf_booking_id":  values.response.bookings[0].kafila_booking_id,
			"id": 0,
			"tripid": 0,
			"refid": 0,
			"admin_unique_id": 0,
			"agent_discount": 0,
			"agent_discount_type": "",
			"agent_unique_id": 0,
			"category_name": "Domestic",
			"cgst":  values.response.bookings[0].fare.cgst,
			"destination":  values.response.bookings[0].destination_code,
			"fare": {
				"published_fare":  values.response.bookings[0].fare.published_fare,
				"offered_fare":  values.response.bookings[0].fare.offered_fare,
				"other_charges":  values.response.bookings[0].fare.other_charges,
				"tax":  values.response.bookings[0].fare.tax
			},
			"flight_cabin_class": 1,
			"igst":  values.response.bookings[0].fare.igst,
			"offered_fare":  values.response.bookings[0].fare.offered_fare,
			"origin":  values.response.bookings[0].origin_code,
			"passengers": [
				{
					"title":  values.response.bookings[0].passengers[0].pax_title,
					"first_name":  values.response.bookings[0].passengers[0].first_name,
					"last_name":  values.response.bookings[0].passengers[0].last_name,
					"pax_type":  values.response.bookings[0].passengers[0].pax_type,
					"date_of_birth":  values.response.bookings[0].passengers[0].dateofbirth,
					"gender": "1",
					"passport_no":  values.response.bookings[0].passengers[0].passport_no,
					"passport_expiry":  values.response.bookings[0].passengers[0].passport_expiry,
					"address_line_1": "34fdgdg",
					"address_line_2": "",
					"fare": {
						"currency": "R",
						"base_fare":  values.response.bookings[0].fare.base_fare,
						"tax":  values.response.bookings[0].fare.tax,
						"transaction_fee": 0,
						"yq_tax":  values.response.bookings[0].fare.yq_tax,
						"additional_txn_fee_pub":  values.response.bookings[0].fare_breakdown[0].additional_txn_fee_pub,
						"additional_txn_fee_ofrd": values.response.bookings[0].fare_breakdown[0].additional_txn_fee_ofrd,
						"air_trans_fee": 0,
						"service_fee": 0,
						"other_charges":  values.response.bookings[0].fare.other_charges,
						"discount":  values.response.bookings[0].fare.discount,
						"commission_earned": 0,
						"plb_earned": 0,
						"incentive_earned": 0,
						"tds_on_commission": 0,
						"tds_on_plb": 0,
						"tds_on_incentive": 0,
						"k3_gst": 0,
						"published_fare":  values.response.bookings[0].fare.published_fare,
						"offered_fare":  values.response.bookings[0].fare.offered_fare,
					},
					"baggage": [
					],
					"meal_dynamic": [
					],
					"seat_dynamic": [
					],
					"city":  values.response.bookings[0].passengers[0].city,
					"country_name":  values.response.bookings[0].passengers[0].country_name,
					"country_code":  values.response.bookings[0].passengers[0].country_code,
					"cell_country_code":  values.response.bookings[0].passengers[0].cell_country_code,
					"nationality":  values.response.bookings[0].passengers[0].nationality,
					"contact_no":  values.response.bookings[0].passengers[0].contact_no,
					"email":  values.response.bookings[0].passengers[0].email,
					"is_lead_pax":  values.response.bookings[0].passengers[0].is_lead_pax,
					"ff_airline_code":  values.response.bookings[0].segments[0][0].airline_code,
					"ff_number":  values.response.bookings[0].passengers[0].ff_no,
					"gst_company_address":  values.response.bookings[0].passengers[0].gst_company_address,
					"gst_company_contact_number":  values.response.bookings[0].passengers[0].gst_company_contact_number,
					"gst_company_name":  values.response.bookings[0].passengers[0].gst_company_name,
					"gst_number":  values.response.bookings[0].passengers[0].gst_number,
					"gst_company_email":  values.response.bookings[0].passengers[0].gst_company_email,
					"meal": null,
					"seat": null
				}
			],
			"price_modified": {
				"yq_tax": values.response.bookings[0].fare.yq_tax,
				"tbo_tds": values.response.bookings[0].fare.tbo_tds,
				"admin_unique_id": 0,
				"agent_unique_id": 0,
				"tds": values.response.bookings[0].fare.tds_tax,
				"agent_discount": 0,
				"tbo_earned": null,
				"other_charges": values.response.bookings[0].fare.other_charges,
				"base_fare": values.response.bookings[0].fare.base_fare,
				"service_charge_amount": 0,
				"sgst": values.response.bookings[0].fare.sgst,
				"cgst": values.response.bookings[0].fare.cgst,
				"igst": values.response.bookings[0].fare.igst,
				"offered_fare": values.response.bookings[0].fare.offered_fare,
				"published_fare": values.response.bookings[0].fare.published_fare,
				"tax": values.response.bookings[0].fare.tax
			},
			"result_index": "KF0",
			"segments":
				[
					{
						"origin": values.response.bookings[0].segments[0][0].origin_code,
						"destination": values.response.bookings[0].segments[0][0].destination_code,
						"preferred_departure_time": values.response.bookings[0].segments[0][0].departure_time,
						"preferred_arrival_time": values.response.bookings[0].segments[0][0].arrival_time,
						"airline_code": values.response.bookings[0].segments[0][0].airline_code,
						"flight_number": values.response.bookings[0].segments[0][0].flight_no,
						"origin_airport_name": values.response.bookings[0].segments[0][0].origin_airport_name,
						"origin_airport_terminal": values.response.bookings[0].segments[0][0].origin_airport_terminal,
						"destination_airport_name": values.response.bookings[0].segments[0][0].destination_airport_name,
						"destination_airport_terminal": values.response.bookings[0].segments[0][0].destination_airport_terminal,
						"airline_pnr": null,
						"fare_class": values.response.bookings[0].segments[0][0].fare_class,
						"craft": "",
						"cabin_baggage": "7Kg",
						"stop_point": "",
						"stop_point_arrival_time": "0001-01-01T00:00:00",
						"stop_point_departure_time": "0001-01-01T00:00:00",
						"baggage": "15 KG"
					}
				],
			"sgst": values.response.bookings[0].fare.sgst,
			"source": values.response.bookings[0].source,
			"tds": null,
			"trace_id": values.response.bookings[0].trace_id,
			"trip_type": values.response.bookings[0].trip_type
		}
	}
	return data;
}

export const kafilaNumberOfSeat = (seat) => {
	let newSeat = 0;
	if(seat >= 9)
	{
		newSeat = 9;
	}else{
		newSeat = seat;
	}
	return newSeat;
}

export const getUniqueAirlinesCode = (allFlights) => {
	let airlineArr = []
	allFlights && allFlights.map(function (singleFlight, index) {
		return singleFlight.segments[0].map(function (segment, index) {
			return segment.airline.airline_code && segment.airline.airline_name && airlineArr.push({
				"airline_name": segment.airline.airline_name,
				"airline_code": segment.airline.airline_name === "AirIndiaExpress" ? "IX" : segment.airline.airline_code,
			})
		});
	});
	return common.arrayUniqueAirlineCode(airlineArr);
};

export const getFlightStopsDirectFlightLength = (allFlights, direct_flight) => {
	let allValues = allFlights;
	let stopPoint = [];
	/* eslint-disable-next-line */
	allValues && allValues.map((itemIn) => {
		itemIn && itemIn.segments[0].map((itemInner) => {
			if (direct_flight === 'true' && (itemIn.api_source === 'tripjack' || itemIn.api_source === 'kafila' || itemIn.api_source === 'tbo') && (itemInner && itemInner.stop_over && ((itemInner.stop_over === true) || (itemInner.stop_over.length > 0 && itemInner.stop_over[0] && itemInner.stop_over[0].cityCode)))) {
			} else {
				stopPoint.push(itemInner)
			}
			return null;
		})
		return null;
	})
	return stopPoint;
}

export const getAirlineOperatingCarrier = (segment) => {
	let operatingCarrierName = segment && segment.airline && segment.airline.operating_carrier_name;
	let operatingCarrier = segment && segment.airline && segment.airline.operating_carrier;
	if (operatingCarrierName !== null) {
		return 'Operated By - ' + operatingCarrierName
	} else if (operatingCarrier !== null) {
		return 'Operated By - ' + operatingCarrier
	} else {
		return null
	}
};

export const getMyBookingAirlineOperatingCarrier = (segment) => {
	let operatingCarrierName = segment && segment.operating_carrier_name;
	let operatingCarrier = segment && segment.operating_carrier;
	if (operatingCarrierName !== null) {
		return 'Operated By - ' + operatingCarrierName
	} else if (operatingCarrier !== null) {
		return 'Operated By - ' + operatingCarrier
	} else {
		return null
	}
};