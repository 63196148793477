import React from 'react';
import { currencyFormat, dateFormat, showValue, minutesToDuration, timeDifference, timeFormatHhMm, getNewDateFormted } from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';
import moment from 'moment';
class FlightAdvanceSearchDetailsRightOneWayComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendMailPopup: '',
            sendMailAddress: null,
            sendMailValues: [],
            isShowFareDetailpopup: false,
            // limit: 10,
            offeredFare: false,
            includeFare: false,
            selectedSeatClass: [],
            selectedSeatClassUniqueIndex: null,
            currentResultIndex: null,
            openSegments: null,
            kafilaFareRules:null,
            fareRuleApiSource:null,
            containerHeight:0,
            containerStyle:{}
        };
    }

    offeredClick() {
        this.setState({ offeredFare: !this.state.offeredFare });
    }

    //Show and hide send email popup
    showMainPopup = () => {
        if (this.state.sendMailPopup === '')
            this.setState({ sendMailPopup: 'show' });
        else
            this.setState({ sendMailPopup: '' });
    }

    //Set state with information of flights to be send through email
    saveEmailData = (event) => {
        if (event.target.checked === true) {
            this.state.sendMailValues.push(event.target.value);
        }
        else {
            var index = this.state.sendMailValues.indexOf(event.target.value);
            if (index > -1) {
                this.state.sendMailValues.splice(index, 1);
            }
        }
    }

    //Send itineraries mails
    sendMail = () => {
        this.props.sendItinerariesMail(this.state.sendMailAddress, this.state.sendMailValues, this.state.includeFare);
    }

    //Show fare details
    showFareDetailsPopup = (event, resultIndex = '', segements = '', apiSource = '', uid = '',fareRules,airline_remark='',airline_code,supplier_airline_remark) => {
        let traceID = this.props.searchResult.trace_id;
        // if (resultIndex !== '' && apiSource !== "kafila") {
        //     this.props.flightFareDetails(traceID, resultIndex,segements, apiSource,uid);
        // }
        if (resultIndex !== '') {
            this.props.flightFareDetails(traceID, resultIndex,segements, apiSource,uid,airline_remark,airline_code,supplier_airline_remark);
        }
        // if (apiSource === "kafila") {
        //     this.setState({
        //         kafilaFareRules: fareRules,
        //         fareRuleApiSource: apiSource
        //     })
        // }
        this.setState({ isShowFareDetailpopup: this.state.isShowFareDetailpopup === true ? false : true })
    }

    loadMoreData = () => {
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'oneway');
        const sameFlightResults = totalResults && totalResults.length > 0 && flightHelper.sameFlightFilterResults(totalResults);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightKeys.length);
        this.props.isFilterChecked(nextResultsToShow)
    };
    trackScrollingM = () => {
        const container = document.querySelector('.page-containers-start');
        const footer = document.querySelector('.footers-end');
        if (container && footer) {
            const containerScrollRemaining =
                container.scrollHeight - container.scrollTop - container.clientHeight;
            const footerHeight = footer.scrollHeight;
            if (containerScrollRemaining - 2 < footerHeight) {
                this.loadMoreData();
            }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        this.scrollTimeout = setTimeout(() => {
            if (container && container.scrollHeight < 200) {
                this.props.scroll_position({...this.props.track_scroll_position, childContainer: "" });
            } else {
                this.props.scroll_position({...this.props.track_scroll_position, childContainer: 1 });
            }
        }, 500);
    };
    trackScrolling = () => {
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        if (pageScorllRemaining < footer.scrollHeight) {
            this.loadMoreData()
        }
    };

    handleResize = () => {
        if (window.innerWidth <= 766) {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            this.setState({ containerStyle: {} })
            document.addEventListener('scroll', this.trackScrolling);
        } else {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
        }
    }
      

    componentDidMount() {
        let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
        let isResultToREnder = resultToREnder && resultToREnder > 25 ? resultToREnder : 25
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'oneway');
        const sameFlightResults = totalResults && totalResults.length > 0 && flightHelper.sameFlightFilterResults(totalResults);
        const allSameFlightKeys = sameFlightResults && Object.keys(sameFlightResults);
        if(allSameFlightKeys && allSameFlightKeys.length){
            for (let initialResults = 6; initialResults <= isResultToREnder; initialResults++) {
                setTimeout(() => {
                    this.props.isFilterChecked(initialResults);
                }, 10 * (initialResults - 1));
            }
        }
         window.addEventListener('resize',this.handleResize)
          if(window.innerWidth <= 766){
            this.setState({containerStyle:{}})
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.addEventListener('scroll', this.trackScrolling);
          }else{
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
            if( document.getElementById("cont-st")){
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
            }
          }
    }

    componentWillUnmount() {
        if(window.innerWidth <= 766){
            document.removeEventListener('scroll', this.trackScrolling);
        }else{
            document.getElementById('cont-st') && document.getElementById('cont-st').removeEventListener('scroll', this.trackScrollingM);
        }
    }
    componentDidUpdate() {
        if(document.getElementsByClassName("page-containers-start") && document.getElementsByClassName("page-containers-start").length === 0){
            if(window.innerWidth <= 766){
              }else{
                document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
                if( document.getElementById("cont-st")){
                document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
                }
              }
        }
    }
    

    //Prev day flight search
    prevDayFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[0].preferred_departure_time = prevDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //Next day flight search
    nextDayFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[0].preferred_departure_time = nextDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //get selected seat class
    getSelectedSeatClass = (selectedSeatInfo) => {
        let oldSeatInfo = this.state.selectedSeatClassUniqueIndex;
        let self = this;

        if (this.state.selectedSeatClass.length > 0) {
            if (oldSeatInfo.split('-')[0] === selectedSeatInfo.split('-')[0]) {
                this.state.selectedSeatClass.length > 0 && this.state.selectedSeatClass.map(function (seats, index) {
                    if (seats.split('-')[1] === selectedSeatInfo.split('-')[1]) {
                        self.state.selectedSeatClass.splice(index, 1);
                    }
                    return true;
                })
                this.state.selectedSeatClass.push(selectedSeatInfo);
                this.setState({ selectedSeatClassUniqueIndex: selectedSeatInfo });
            }
            else {
                let selectedSeatClass = [];
                selectedSeatClass.push(selectedSeatInfo);
                this.setState({
                    selectedSeatClassUniqueIndex: selectedSeatInfo,
                    selectedSeatClass: selectedSeatClass
                });
            }
        }
        else {
            let selectedSeatClass = [];
            selectedSeatClass.push(selectedSeatInfo);
            this.state.selectedSeatClass.push(selectedSeatInfo);
            this.setState({ selectedSeatClassUniqueIndex: selectedSeatInfo });
        }
    }

    //get flight price details
    getFlightPrice = (flightInfo, selectedSeatInfo) => {

        let flightSegments = [];
        let flightClassSeat = '';
        this.setState({ currentResultIndex: flightInfo.result_index });
        flightInfo.segments[0].map(function (segment, index) {
            selectedSeatInfo.map(function (seatClass, classIndex) {
                if (seatClass.split('-')[1] === index.toString()) {
                    flightClassSeat = seatClass.split('-')[2];
                }
                return true;
            })
            let singleSegment = {
                "trip_indicator": segment.trip_indicator,
                "segment_indicator": segment.segment_indicator,
                "airline": {
                    "airline_code": segment.airline.airline_code,
                    "airline_name": segment.airline.airline_name,
                    "flight_number": segment.airline.flight_number,
                    "fare_class": flightClassSeat,
                    "operating_carrier": segment.airline.operating_carrier,
                }
            }

            flightSegments.push(singleSegment);
            return true;
        })

        let flightData = {
            "adult_count": this.props.searchQuery.adult_count.toString(),
            "child_count": this.props.searchQuery.child_count.toString(),
            "infant_count": this.props.searchQuery.infant_count.toString(),
            "trace_id": this.props.searchResult.trace_id,
            "segments": this.props.searchQuery.segments,
            "is_domestic": this.props.searchQuery.is_domestic,
            "air_search_result": [
                {
                    "result_index": flightInfo.result_index,
                    "source": flightInfo.source,
                    "is_lcc": flightInfo.is_lcc,
                    "is_refundable": flightInfo.is_refundable,
                    "airline_remark": flightInfo.airline_remark ? flightInfo.airline_remark : "",
                    "segments": [flightSegments],
                    "fare": flightInfo.fare
                }
            ]
        }
        this.props.getFlightPriceDetails(flightData);
        return true;
    }

    // set open segment
    setOpenSegment = (resultIndex) => {
        if (this.state.openSegments === resultIndex) {
            this.setState({ openSegments: null })
        }
        else {
            this.setState({ openSegments: resultIndex })
        }
    }

    render() {
        let flightFareRulesData = this.props.flightFareRuleDetails.response;
        let totalResults = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0]);
        let traceId = this.props.searchResult && this.props.searchResult.trace_id;
        let searchQuery = this.props.searchQuery;
        let self = this;
        let offeredFared = this.state.offeredFare;

       
        return (
            <div className="right-side">
                <div className="search-right-section">
                    <div className="filter-btn"><span>Filter</span>
                        { /* eslint-disable-next-line */}
                        <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-primary"><i className="icon icon-filter"></i> Filter</a>
                    </div>
                    <div className="title-info top-info">
                        <div className="pull-left">
                            <span className="no-found">Found <samp>{(!totalResults) ? 0 : totalResults.length}</samp> Flight(s)</span>
                        </div>
                        <div className="pull-right">
                            { /* eslint-disable-next-line */}
                            <a href="/" className="btn btn-blue net-fare-button"><small className="offer-info" onClick={(e)=>{e.preventDefault(); this.offeredClick()}}>{this.state.offeredFare ? 'Hide Net Fare ' : 'Show Net Fare '}<img src="assets/images/offer-icon.svg" alt="" /></small> </a>
                        </div>
                    </div>
                    <div className="title-info">
                        <div className="pull-left">
                            <div className="city-to-city">
                                <small className="icon-info"><img src="assets/images/flight-nav-grey.svg" alt="" /></small>
                                <span>{searchQuery.segments[0].originCityName}</span> To <span>{searchQuery.segments[0].destinationCityName}</span></div>
                            <div className="date-time">{dateFormat(searchQuery.segments[0].preferred_departure_time, 'DD MMM YYYY, FDAY')}</div>
                        </div>
                        <div className="pull-right">
                            <div className="prev-next-day">
                                <span onClick={this.prevDayFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                <span onClick={this.nextDayFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    {!totalResults || totalResults.length === 0 ?
                        (
                            <div className="no-result-wrap">
                                <div className="result-wrap-outer">
                                    <div className="block">
                                        <div className="no-result-box">
                                            <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                            <h2>No results found</h2>
                                            <p>We can’t find any item matching your search.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        (
                            <div className="table-list">
                                <div className="inner-table-list">
                                    <div className="table-head">
                                        <div className="table-col table-col-first"><span>Airline</span></div>
                                        <div className="table-col"> <span>Depart</span></div>
                                        <div className="table-col"><span>Arrive</span></div>
                                        <div className="table-col"><span>Duration</span></div>
                                        <div className="table-col table-col-last">
                                            {
                                                <span>Price</span>
                                            }
                                            <div className={`btn-mail ${this.state.sendMailPopup}`}>
                                                { /* eslint-disable-next-line */}
                                                <a className="btn btn-green whatsapp-button" href="/" onClick={(e) =>{e.preventDefault(); this.props.sendItinerariesMessage(this.state.sendMailValues)}}><i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp </a>
                                                { /* eslint-disable-next-line */}
                                                <a className="btn btn-sky-blue" href="/" onClick={(e)=>{e.preventDefault(); this.showMainPopup()}}><i className="fa fa-envelope" aria-hidden="true"></i> Send Email </a>
                                                <div className="email-tooltip">
                                                    <div className="tooltip-outer">
                                                        <div className="form-group">
                                                            {
                                                                this.props.sendMailerror && !this.props.ItinerariesMail.loading &&
                                                                <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{this.props.sendMailerror}</strong></div>
                                                            }
                                                            {
                                                                this.props.sendMailsuccess && !this.props.ItinerariesMail.loading &&
                                                                <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>Email sent successfully</strong></div>
                                                            }
                                                            <input className="form-control custom-filed" type="email" placeholder="Email Address" onChange={(e) => {
                                                                this.setState({ sendMailAddress: e.target.value });
                                                            }} />
                                                        </div>
                                                        <div className="block">
                                                            <button type="submit" className={`btn btn-primary ${this.props.ItinerariesMail.loading ? 'show' : ''}`} onClick={this.sendMail}>
                                                                <div className="loader-ring"> <span></span>
                                                                </div>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-body section"  id="cont-st" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px',minHeight: '1000px' }}>
                                        <ul>
                                            {
                                                totalResults && totalResults.slice(0, this.props.isFilterCheckedStatus).map(function (data, index) {
                                                    return (
                                                        <FlightSearchDetailsSingleFlight
                                                            offeredShow={offeredFared}
                                                            key={index}
                                                            data={data}
                                                            searchQuery={searchQuery}
                                                            saveEmailData={self.saveEmailData}
                                                            showFareDetailsPopup={self.showFareDetailsPopup}
                                                            history={self.props.history}
                                                            getSelectedSeatClass={self.getSelectedSeatClass}
                                                            traceId={traceId}
                                                            getFlightPrice={self.getFlightPrice}
                                                            flightPriceData={self.props.flightPriceDetail}
                                                            setOpenSegment={self.setOpenSegment}
                                                            {...self.state} />
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className='footers-end'></div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>

                {/*Fare Detail Popup Start*/}
                <div className={this.state.isShowFareDetailpopup === true ? "popup-crad show" : "popup-crad hide"}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Fare Rules</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={this.showFareDetailsPopup}></span></div>
                            </div>
                            <div className="popup-scorll">
                                {(!this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.loading === true)  ?
                                    (<div className="loader relative-loader" style={{ display: 'block' }}>
                                        <div className="loader-content">
                                            <div className="loader-ring blue-ring"> <span></span> </div>
                                        </div>
                                    </div>)
                                //     : this.fareRuleApiSource==="kafila" &&  this.state.kafilaFareRules &&  this.state.kafilaFareRules.length>0  ? <React.Fragment>
                                //     <h3>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h3>
                                //     <div className="table-outer">
                                //          <div className="table-container">
                                //              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                //                  <thead>
                                //                      <tr>
                                //                          <th width="15%">Time Frame</th>
                                //                          <th>Cancellation Fee</th>
                                //                          <th>Date Changes Fee</th>
                                //                         { /*<th>No Show</th>*/}
                                //                          {/* <th width="16%">Seat Chargeable</th> */}
                                                         
                                //                      </tr>
                                //                  </thead>
                                //                  <tbody>
                                //                      {
                                //                         this.state.kafilaFareRules && this.state.kafilaFareRules.length>0 && this.state.kafilaFareRules.map((item)=>{
                                //                                 return  (<tr key= {""}>
                                //                                 <td><div dangerouslySetInnerHTML={{__html: item.time_frame}}/></td>
                                //                                 <td><div dangerouslySetInnerHTML={{ __html: item.cancellation_fee}}/> </td>
                                //                                 <td><div  dangerouslySetInnerHTML={{ __html: item.date_change_fee}}/> </td>
                                //                                 {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                //                                 {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                //                             </tr>)
                                //                         })                                                                                                          
                                //                       }
                                //                  </tbody>
                                //              </table>
                                //              <br />
                                //                  <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                //                  <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                //                  <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                //                  <p>For more information Please contact our helpdesk.<br /></p>
                                //          </div>
                                //      </div>
                                //  </React.Fragment>:
                                :
                                    flightFareRulesData && flightFareRulesData.length > 0 && flightFareRulesData.map(function (data, index) {
                                        let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                        return (
                                            <React.Fragment key={index}>
                                                <h3>{data.origin} - {data.destination}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {!flightFareRulesData && this.props.flightFareRuleDetails.loading === false ? <p><br />Fare Rules not available.<br /></p> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
                {/*Fare Detail Popup End*/}
            </div>
        )
    }
}

class FlightSearchDetailsSingleFlight extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    toggleDetailsAccordion = (event, resultIndex) => {
        this.props.setOpenSegment(resultIndex);
    }

    emailData = (event) => {
        this.props.saveEmailData(event);
    }

    fareData = (event, result_index,fare_rules,airline_remark,segments,supplier_airline_remark) => {
        let airline_code = segments.length && segments[0].airline_code;
        this.props.showFareDetailsPopup(event, result_index,fare_rules,"x","y","z",airline_remark,airline_code,supplier_airline_remark);
    }

    callSelectedSeatClass = (event, selectedSeatInfo) => {
        this.props.getSelectedSeatClass(selectedSeatInfo);
    }

    callGetFlightPrice = (event, flightInfo, selectedSeatInfo) => {
        this.props.getFlightPrice(flightInfo, selectedSeatInfo);
    }

    //Start booking of flight
    startBooking = (data) => {
        this.props.history.push({
            pathname: "/book-ticket",
            state: {
                searchType: 'one-way',
                trace_id: this.props.traceId,
                data: data
            }
        });
    }

    render() {
        let data = this.props.data;
        let searchQuery = this.props.searchQuery;
        let totalTraveller = this.props.searchQuery.adult_count + this.props.searchQuery.infant_count + this.props.searchQuery.child_count;
        let self = this;
        let notAvailbleSeatsClass = [];
        let availbleSeatsClass = [];
        let flightPriceData = this.props.flightPriceData.response;
        let segmentData = data.segments[0];
        let stopData = flightHelper.getFlightStops(segmentData);
        return (
            <li className={this.props.openSegments === data.result_index ? "open" : ""} id='calcHeight'>
                <div className="col-table-inner">
                    <div className="table-col table-col-first">
                        <div className="col-list-name">
                            <div className="company-info"> <span className="mob-label">Airline</span>
                                <figure><img src={data.segments[0][0].airline.flight_logo} alt="" /></figure>
                                <div className="company-nm">
                                    <span>{flightHelper.getCombinedAirlineName(data.segments[0])}</span>
                                    <small>{flightHelper.getCombinedAirlineCode(data.segments[0])}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-col"> <span className="mob-label">Depart</span>
                        <div className="col-detail">
                            {
                                flightHelper.getDepartureCityTime(data)
                            }
                            {/* <span>{flightHelper.getDepartureTime(data.segments[0][0].stop_point_departure_time)}</span>  */}
                            <small>{flightHelper.getDepartureDate(data.segments[0][0].origin.dep_time, 'DD MMM')}</small>
                        </div>
                    </div>
                    <div className="table-col"> <span className="mob-label">Arrive</span>
                        <div className="col-detail">
                            {
                                flightHelper.getArrivalCityTime(data)
                            }
                            {/* <span>{flightHelper.getArivalTime(data.segments[0])}</span>  */}
                            <small>{flightHelper.getArivalDate(data.segments[0])}</small>
                        </div>
                    </div>
                    <div className="table-col"> <span className="mob-label">Duration</span>
                        <div className="col-detail">
                            <span> {flightHelper.getCommonDuration(data.segments[0], ':',data.api_source,data)} </span>
                            {/* ----------------------- Duration Non-stop Tooltip - starts here ----------------------- */}
                            { /* eslint-disable-next-line */}
                            <a href="/" onClick={(e)=>e.preventDefault()} className="non-stop-click">
                                <small>{flightHelper.getJournyType(stopData)}</small>
                                <div className="non-stop-tooltip">
                                    <ul>
                                        {
                                            stopData.map(function (segment, segmentIndex) {
                                                return (
                                                    <li key={segmentIndex}>
                                                        <div className="col-stop">{segment.airline_code + '-' + segment.flight_number}</div>
                                                        <div className="col-stop">{segment.origin_airport_code + (segment.origin_dep_time ? " (" + timeFormatHhMm(segment.origin_dep_time) + ")" : '')}</div>
                                                        <div className="col-stop last-stop">
                                                            <i className="icon icon-arrow-right2"></i>
                                                            {segment.destination_airport_code + (segment.destination_arr_time ? " (" + timeFormatHhMm(segment.destination_arr_time) + ")" : '') }
                                                            {segment.craft && segment.remark && segment.craft === "BUS" ? <samp className="red-text">*</samp> : ""}</div>
                                                        {
                                                            (segment.techStop) &&
                                                            <div className="techstopmsg">1 Technical Stop</div>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </a>
                            {
                                data && data.api_source && data.api_source === 'kafila' ?
                                <small className="left-seat">{data.segments[0][0].no_of_seat_available !== null ? flightHelper.kafilaNumberOfSeat(data.segments[0][0].no_of_seat_available) + ' seat(s) left' : ''}</small>
                                :
                                <small className="left-seat">{data.segments[0][0].no_of_seat_available !== null ? data.segments[0][0].no_of_seat_available + ' seat(s) left' : ''}</small>
                            }
                        </div>
                    </div>
                    <div className="table-col table-col-last">
                        <span className="mob-label">Price</span>
                        {

                            flightPriceData && flightPriceData.results[0][0].result_index === data.result_index ? <div className="col-detail">
                                <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.published_fare)}</span>
                                <small className="offered-fare" style={{ display: (this.props.offeredShow === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.offered_fare)}</small>
                            </div> : <div className="col-detail"><span>-</span></div>
                        }
                        {
                            flightPriceData && flightPriceData.results[0][0].result_index === data.result_index ?
                                <ul className="list-icon">
                                    {/* Baggage Icon  --------------------------------------------------------------------- Start here */}
                                    <li className="cabin-list">
                                        { /* eslint-disable-next-line */}
                                        <span className="select-tooltip">
                                            <i className="fa icon-bag" aria-hidden="true"></i>
                                            <div className="select-operated-tooltip">
                                                <div className="inner-tooltip-card">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Sector</th>
                                                                <th>Cabin</th>
                                                                <th>Check-in</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                flightPriceData.results[0][0].segments[0].map(function (segment, segmentIndex) {
                                                                    return (
                                                                        <tr key={segmentIndex}>
                                                                            <td><span>{segment.origin.airport.airport_code}</span> - <span> {segment.destination.airport.airport_code}</span></td>
                                                                            <td>{segment.cabin_baggage ? segment.cabin_baggage : '-'}</td>
                                                                            <td>{segment.baggage ? segment.baggage : '-'}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </span>
                                    </li>
                                    {/* Fare Rule Icon  --------------------------------------------------------------------- Start here */}
                                    <li title="Fare Rules">
                                        { /* eslint-disable-next-line */}
                                        <a href="/" onClick={(event) => {event.preventDefault(); this.fareData(event, flightPriceData.results[0][0].result_index,data.fare_rules,flightPriceData.results[0][0].airline_remark,flightPriceData.airline_codes,data.supplier_airline_remark)}}>
                                            <i className="fa icon-edit-right" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    {/* Is Refundable Icon  --------------------------------------------------------------------- Start here */}
                                    {
                                        flightPriceData.results[0][0].is_refundable
                                            ?
                                            <li title="Refundable">
                                                { /* eslint-disable-next-line */}
                                                <a href="/" onClick={(e)=>e.preventDefault()}>
                                                    <i className="fa icon icon-reg" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            :
                                            ""
                                    }
                                </ul>
                                : <ul className="list-icon disabled-section">
                                    { /* eslint-disable-next-line */}
                                    <li><a href="/" onClick={(e)=>e.preventDefault()}><i className="fa icon-bag" aria-hidden="true"></i></a></li>
                                    { /* eslint-disable-next-line */}
                                    <li><a href="/" onClick={(e)=>e.preventDefault()}><i className="fa icon-edit-right" aria-hidden="true"></i></a></li>
                                    { /* eslint-disable-next-line */}
                                    <li><a href="/" onClick={(e)=>e.preventDefault()}><i className="fa icon icon-reg" aria-hidden="true"></i></a></li>
                                </ul>
                        }
                        <div className="last-col-info">
                            {
                                flightPriceData && flightPriceData.results[0][0].result_index === data.result_index &&
                                <React.Fragment>
                                    { /* eslint-disable-next-line */}
                                    <a href="/" className="btn btn-yellow" onClick={(e) => {e.preventDefault(); this.startBooking(flightPriceData.results[0][0])}}>Book Now</a>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {
                        data.is_remark_available === true ? <div className="fl-sme"><span>{data.airline_remark ? data.airline_remark : ''}</span></div> : ''
                    }


                </div>
                <span className="block-title">
                    <h2 onClick={(event) => this.toggleDetailsAccordion(event, data.result_index)}>Flight Detail</h2>
                    <span className="mail-icon pull-right">
                        <input type="checkbox" value={data.result_index} name="sendMail" onChange={(event) => this.emailData(event)} />
                        <i className="icon icon-mail1"></i> <i className="icon icon-checked"></i>
                    </span>
                </span>

                <div className="block-accordion">
                    <div className="block-describe">
                        <div className="info-detail">
                            <div className="info-title">
                                <div className="col-left-info">
                                    <div className="city-to-other">
                                        <span>{searchQuery.segments[0].originCityName}</span>
                                        <small><img src="assets/images/flight-continue.svg" alt="" /></small>
                                        <span>{searchQuery.segments[0].destinationCityName}</span>
                                    </div>
                                    <div className="detail-time-info">
                                        <ul className="flight-info">
                                            <li><span>{flightHelper.getDepartureDate(data.segments[0][0].origin.dep_time, 'FDAY, DD MMM')}</span></li>
                                            <li><span>{flightHelper.getDuration(data.segments[0], 'h')}</span></li>
                                            <li><span>{flightHelper.getJournyType(stopData)}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-mid-info"> {flightHelper.isRefundable(data.is_refundable)} </div>
                                <div className="col-right-info">
                                    {
                                        flightPriceData && flightPriceData.results[0][0].result_index === data.result_index &&
                                        <div className="total-amount"><span>{currencyFormat(flightPriceData.results[0][0].fare.published_fare)} </span>
                                            <div className="tooltip-amount">{ /* eslint-disable-next-line */}
                                                <a className="tooltip-link" href="/" onClick={(e)=>e.preventDefault()}>Fare Breakup</a>
                                                <div className="amount-detail">
                                                    <div className="amount-detail">
                                                        <ul>
                                                            <li>Base Fare <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.base_fare)}</span></li>
                                                            {
                                                                flightPriceData.results[0][0].fare.other_charges !== 0
                                                                    ? <li>Other Charges <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.other_charges)}</span></li>
                                                                    : ''
                                                            }

                                                            {
                                                                flightPriceData.results[0][0].fare.tax !== 0
                                                                    ? <li>Tax
                                                                <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.tax)}</span>
                                                                    </li>
                                                                    : ''
                                                            }

                                                            {
                                                                flightPriceData.results[0][0].fare.yq_tax !== 0
                                                                    ? <li>YQ Tax (Included) <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.yq_tax)}</span></li>
                                                                    : ''
                                                            }

                                                            {
                                                                flightPriceData.results[0][0].fare.other_tax !== 0
                                                                    ? <li>Other Tax <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.other_tax)}</span></li>
                                                                    : ''
                                                            }

                                                            {
                                                                flightPriceData.results[0][0].fare.service_charge !== 0
                                                                    ? <li>Service Charge <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.service_charge)}</span></li>
                                                                    : ''
                                                            }

                                                            {/* {
                                                            flightPriceData.results[0][0].fare.discount+flightPriceData.results[0][0].fare.agent_discount !== 0 
                                                            ?<li>Discount <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.discount+flightPriceData.results[0][0].fare.agent_discount)}</span></li>
                                                            :''
                                                        } */}

                                                            <li className="last-amount-total"> <span><small>Total:</small><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(flightPriceData.results[0][0].fare.published_fare)}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="sub-info"><span>({flightHelper.getPaxSummary(searchQuery.adult_count, searchQuery.child_count, searchQuery.infant_count)})</span></div>
                                </div>
                            </div>
                            <div className="time-duration-section">
                                <ul className="flight-duration">
                                    {
                                        data.segments[0].map(function (segment, segmentIndex) {
                                            return (
                                                <li key={segmentIndex}>
                                                    {
                                                        segmentIndex !== 0
                                                            ? <div className="time-col"><span>Change plane at {segment.origin.airport.city_name}</span></div>
                                                            : ""
                                                    }
                                                    <div className="detail-bx">
                                                        <div className="fl-info">
                                                            <div className="fl-name">
                                                                <span>{flightHelper.getAirlineNameAndCode(segment)}</span> <small>Aircraft - {segment.craft}</small>
                                                            </div>

                                                            {/* Layover Start */}
                                                            <div className="fl-information">
                                                                {
                                                                    segmentIndex !== 0 && timeDifference(data.segments[0][segmentIndex - 1].destination.destination_arr_time, segment.origin.dep_time) > 0
                                                                        ? <span> Layover {minutesToDuration(timeDifference(data.segments[0][segmentIndex - 1].destination.arr_time, segment.origin.dep_time), 'h')}</span>
                                                                        : ""
                                                                }
                                                            </div>
                                                            {/* Layover End */}
                                                            {segment.craft && segment.remark && segment.craft === "BUS" ? <div className="fn-segment"><span className="red-text">{segment.remark}</span></div> : ""}

                                                        </div>
                                                        <div className="fl-detail">
                                                            <div className="fl-inner">
                                                                <div className="fl-col"> <span> {segment.origin.airport.city_name} </span> <small> {dateFormat(segment.origin.dep_time, "DAY, DD MMM, HH:MM")} </small>
                                                                    <div className="terminal-nm"> <span> {segment.origin.airport.airport_name}{showValue(" - T-", segment.origin.airport.terminal, "")}</span> </div>
                                                                </div>
                                                                <div className="fl-mid">
                                                                    <div className="fl-mid-inner">
                                                                        <ul>
                                                                            <li>
                                                                                <i className="fa fa-clock-o" aria-hidden="true"></i> <span>{flightHelper.getDuration(segment, 'h')}</span>
                                                                            </li>
                                                                            {
                                                                                segment.cabin_baggage !== null
                                                                                    ? (<li className="mid-meal"> <i className="material-icons">card_travel</i> <span>{segment.cabin_baggage}</span> </li>)
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                segment.baggage !== null
                                                                                    ? (<li> <i className="material-icons">shopping_cart</i> <span>{segment.baggage}</span> </li>)
                                                                                    : ""
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="mid-line"> <small className="dot"></small> <small className="dot"></small> <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span> </div>
                                                                </div>
                                                                <div className="fl-col"> <span> {segment.destination.airport.city_name} </span> <small> {dateFormat(segment.destination.arr_time, "DAY, DD MMM, HH:MM")} </small>
                                                                    <div className="terminal-nm"> <span> {segment.destination.airport.airport_name} {showValue(" - T-", segment.destination.airport.terminal, "")}</span> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="advance-search">
                                                        <div className="seat-list">
                                                            {
                                                                segment && segment.availability && segment.availability.map(function (seatClass, seatClassIndex) {
                                                                    let selectedSeatInfo = data.result_index + '-' + segmentIndex + '-' + seatClass.class + '-' + seatClass.seats;
                                                                    let activeSeat = ''
                                                                    let seatIndex = self.props.selectedSeatClass.indexOf(selectedSeatInfo);
                                                                    if (seatIndex > -1) {
                                                                        activeSeat = 'active'
                                                                    }
                                                                    else {
                                                                        activeSeat = ''
                                                                    }
                                                                    return (
                                                                        <span key={seatClassIndex} className={activeSeat} onClick={(event) => self.callSelectedSeatClass(event, selectedSeatInfo)}>{seatClass.class + seatClass.seats}</span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                           segment && segment.availability && segment.availability.map(function (seatClass, seatClassIndex) {
                                                                let selectedSeatInfo = data.result_index + '-' + segmentIndex + '-' + seatClass.class + '-' + seatClass.seats;
                                                                let seatIndex = self.props.selectedSeatClass.indexOf(selectedSeatInfo);
                                                                if (seatIndex > -1) {
                                                                    if (totalTraveller <= selectedSeatInfo.split('-')[3]) {
                                                                        availbleSeatsClass.push(seatClass);
                                                                        return (
                                                                            <div className="seat-available" key={seatClassIndex}>
                                                                                <span>{totalTraveller} Seat(s) in {selectedSeatInfo.split('-')[2]} Class</span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else {
                                                                        notAvailbleSeatsClass.push(seatClass);
                                                                        return (
                                                                            <div className="seat-available no-seats" key={seatClassIndex}>
                                                                                <span>{totalTraveller} Seat(s) not available</span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        segmentIndex === data.segments[0].length - 1 &&
                                                        <div className="flight-price loading-btn">
                                                            <span className={data.segments[0].length === availbleSeatsClass.length ? 'btn btn-yellow anchor' : 'btn btn-yellow anchor disabled'} onClick={(event) => self.callGetFlightPrice(event, data, self.props.selectedSeatClass)}>
                                                                {
                                                                    self.props.flightPriceData.loading === true && self.props.currentResultIndex === data.result_index ?
                                                                        <React.Fragment>
                                                                            Price This Flight
                                                                <div className="flight-animated">
                                                                                <label><span><small></small><small></small><small></small></span><img src="/assets/images/flight-continue.svg" alt="" /></label>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        :
                                                                        'Price This Flight'
                                                                }
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        self.props.flightPriceData.error && segmentIndex === data.segments[0].length - 1 && self.props.currentResultIndex === data.result_index &&
                                                        <div className="seat-error-message text-center">
                                                            <span className="red-text">
                                                                {self.props.flightPriceData.error}
                                                            </span>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default FlightAdvanceSearchDetailsRightOneWayComponent