import React, { Component } from 'react';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';

class flightTypesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: history.location.pathname
        }
    }
    componentDidMount() {
        history.listen((location, action) => {
            this.setState({
            url: location.pathname,
            });
        });
    }
   
    render() {
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
            { activeLeftNavigation === "/flight-type-list"?
            <div className="tabs-container-detail">
                <div className="mark-up-info">
                    <div className="tabs-top">
                        <div className="tabs-title">
                            <h2><span>Select Flight Type</span></h2>
                            {/* <div className="pull-right"> <Link to="mark-up-tool" className="sm-btn blue-sm-btn">Markup Tool</Link> </div> */}
                        </div>
                    </div>
                    <div className="category-list updates-list">
                        <ul>
                            <li>
                                <Link to="domestic-markup-tool">
                                    <div className="category-card">
                                        <figure><i className="icon icon-flight-domestic"></i></figure>
                                        <div className="category-card-bottom">
                                            <h3>Domestic</h3>
                                        </div>
                                    </div>
                                </Link>
                            </li>  
                            <li>
                                <Link to ="international-markup-tool"> 
                                    <div className="category-card disabled">
                                        <figure><i className="icon icon-international-flight"></i></figure>
                                        <div className="category-card-bottom">
                                            <h3>International</h3>
                                        </div>
                                    </div>
                                </Link> 
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            :""}
            
           
            </React.Fragment>
            )
    }
}

export default (flightTypesComponent)