import React, { Component } from 'react';
import * as Yup from "yup"
import { Formik } from "formik";
import CarouselSlider from '../shared/CarouselComponent';
import ActiveComponent from '../hotel/HotelSearchActiveComponent';
import moment from 'moment';
import { getCurrentDate } from '../../shared/commonHelper';

class HotelSearchComponent extends Component {

    resetandtoogle= () => {
        this.props.toggleModifySearch()
    }

    handleSubmit(formData, actions){
        this.props.filterhotelResults(null)
        let roomGuests = [];
        let isDomestic = "false";
        for(let i = 0;i<=formData.roomGuests.length-1 ;i++){
            roomGuests.push({
                "no_of_adults": formData.roomGuests[i]['adultsCount'],
                "no_of_child": formData.roomGuests[i]['childCount'],
                "child_age": formData.roomGuests[i]['childAge'] && formData.roomGuests[i]['childAge'].map(Number)
                });
        }

        formData.countryCode = formData.countryCode.replace(/(\r\n|\n|\r)/g,"");
        if(formData.guestNationality.split(",") && formData.guestNationality.split(",")[0] === formData.countryCode)
        {
            isDomestic = "true";
        }
        let searchQuery = {
            check_in_date: formData.checkInDate,
            check_out_date: formData.checkOutDate,
            no_of_nights: formData.nightsCount,
            country_code: formData.countryCode,
            city_id: formData.destinationID.toString(),
            city_name: formData.cityName,
            result_count: null,
            preferred_currency: "INR",
            guest_nationality: formData.guestNationality.split(",") && formData.guestNationality.split(",")[0],
            nationality_id:formData.guestNationality.split(",") && formData.guestNationality.split(",")[1],
            no_of_rooms: formData.roomsCount,
            room_guests: roomGuests,
            preferred_hotel: formData.preferredHotel,
            max_rating: parseInt(formData.hotelRating.split('-')[1]),
            min_rating: parseInt(formData.hotelRating.split('-')[0]),
            review_score: null,
            is_nearby_search_allowed: false,
            is_domestic: isDomestic,
            country_name: formData.countryName,
            selected_active_room: formData.selectedActiveRoom,
            selected_room: formData.selectedRoom,
            api_calling: formData.api_calling
        }
        this.props.hotelSearchSaveQuery(searchQuery);
        if(this.props.modifySearch && this.props.history.location.pathname === '/hotel-search-details')
        {
            if (this.props.searchQuery.api_calling === "BOTH") {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                // this.props.getHotelSearchResults(this.props.searchQuery);
                this.props.getTripjackHotelSearchResults(this.props.searchQuery);
            } else if (this.props.searchQuery.api_calling === "tripjack") {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                this.props.getTripjackHotelSearchResults(this.props.searchQuery);
            } else {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                // this.props.getHotelSearchResults(this.props.searchQuery);

            }
            this.props.toggleModifySearch();
        }
        else
        {
            this.props.history.push({
                pathname:"/hotel-search-details",
                state:{
                    searchAPINo : false
                }
            });
        }
    }

    render() {
        const { noticeResponse,bannerResponse } = this.props;
        let validationSchemaBasicDetails = '';
        validationSchemaBasicDetails = Yup.object().shape({
            destinationID:
                Yup.string()
                    .required('Please Select Destination City.')
        });
        let roomGuests = [];
        if(this.props.modifySearch && this.props.searchQuery){
            for(let i = 0;i< this.props.searchQuery.room_guests.length;i++){
                let item = this.props.searchQuery.room_guests[i];
                let roomDetails ={
                    adultsCount: item.no_of_adults,
                    childCount: item.no_of_child,
                    childAge: item.child_age
                }
                roomGuests.push(roomDetails);
            }
        }

        let importantMessage = '';
        let count = 1;
        if(noticeResponse.response){
            for(let i = 0;i< noticeResponse.response.length;i++){
                if(noticeResponse.response[i].is_important === 1)
                {
                    importantMessage += '('+count+') '+noticeResponse.response[i].notice;
                    count = count + 1;
                }
            }
        }
        importantMessage = importantMessage.replace(/<p>/g,'');
        importantMessage = importantMessage.replace(/<\/\p>/g,'');
        return (
                <React.Fragment>
                {/*Search Component Start*/}
                    {   !this.props.modifySearch ?
                            <section className="mid-wrapper">
                                <div className="container">
                                    <div className="search-mid">
                                        {
                                            importantMessage !== '' &&    
                                            <div className="important-text-search block">
                                                <div className="important-text-search-inner">
                                                    <div className="imp-block">
                                                        <strong className="imp-heading">Important Message</strong>
                                                        {/* eslint-disable-next-line */}
                                                        <marquee scrollamount="3">
                                                            <p dangerouslySetInnerHTML={{ __html: importantMessage }} />
                                                        </marquee>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="search-inner-block">
                                            <div className="col-left">
                                                <div className="search-inner-block">
                                                    <div className="tab-container">
                                                        <Formik
                                                            initialValues={{
                                                                checkInDate: moment(getCurrentDate()).format('DD/MM/YYYY'),
                                                                checkOutDate: moment(getCurrentDate()).add(1,'days').format('DD/MM/YYYY'),
                                                                nightsCount: "1",
                                                                countryCode: "NL",
                                                                destinationID: "",
                                                                resultCount: null,
                                                                preferredCurrency: "INR",
                                                                guestNationality: "IN,106",
                                                                roomsCount: "1",
                                                                roomGuests: [
                                                                    {
                                                                        adultsCount: 1,
                                                                        childCount: 0,
                                                                        childAge: null
                                                                    }
                                                                ],
                                                                preferredHotel: "",
                                                                hotelRating: '3-5',
                                                                reviewScore: null,
                                                                isNearbySearchAllowed: false
                                                            }}
                                                            validationSchema={validationSchemaBasicDetails}
                                                            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                                                            render={(formikProps) =>
                                                                <ActiveComponent
                                                                    {...formikProps}
                                                                    {...this.props}
                                                                    {...this.state}
                                                                />
                                                            }
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-right">
                                                {bannerResponse.response &&
                                                    <CarouselSlider carousalClass="banner-carosuel" dataSlider={bannerResponse.response} />
                                                }
                                            </div>
                                        </div>
                                        {/*news-section Start*/}
                                        {/* <div className="latest-news-block">
                                            <fieldset className="news-inner-info">
                                                <legend>Latest News</legend>
                                                {noticeResponse && noticeResponse.response &&
                                                    <CarouselSlider carousalClass="owl-carousel-news" dataSlider={noticeResponse.response} />
                                                }
                                            </fieldset>
                                        </div> */}
                                        {/*news-section end*/}
                                    </div>
                                </div>
                            </section>
                            :
                            <div className="search-inner-block">
                            <div className="col-left">
                                <div className="popup-head">
                                    <h2>Modify Search</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.resetandtoogle()}></span></div>
                                </div>
                                <div className="search-inner-block">
                                    <div className="tab-container">
                                        <div className="tab-content">
                                        <Formik
                                            initialValues={{
                                                checkInDate: this.props.searchQuery.check_in_date,
                                                checkOutDate: this.props.searchQuery.check_out_date,
                                                nightsCount: this.props.searchQuery.no_of_nights,
                                                countryCode: this.props.searchQuery.country_code,
                                                destinationID: this.props.searchQuery.city_id,
                                                resultCount: this.props.searchQuery.result_count,
                                                preferredCurrency: this.props.searchQuery.preferred_currency,
                                                guestNationality: `${this.props.searchQuery.guest_nationality},${this.props.searchQuery.nationality_id}`,
                                                nationality_id:this.props.nationality_id,
                                                roomsCount: this.props.searchQuery.no_of_rooms,
                                                roomGuests: roomGuests,
                                                preferredHotel:this.props.searchQuery.preferred_hotel,
                                                hotelRating: this.props.searchQuery.min_rating+'-'+this.props.searchQuery.max_rating,
                                                reviewScore: this.props.searchQuery.review_score,
                                                isNearbySearchAllowed: this.props.searchQuery.is_nearby_search_allowed,
                                                cityName: this.props.searchQuery.city_name,
                                                countryName: this.props.searchQuery.country_name,
                                                api_calling: this.props.searchQuery.api_calling
                                            }}
                                            validationSchema={validationSchemaBasicDetails}
                                            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                                            render={(formikProps) =>
                                                <ActiveComponent
                                                    {...formikProps}
                                                    {...this.props}
                                                    {...this.state}
                                                    roomGuests={roomGuests}
                                                />
                                            }
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                {/*Search Component End*/}
                </React.Fragment> )
    }
}
export default HotelSearchComponent