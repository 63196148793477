import React, {Component} from 'react';

class ThankYou extends Component {
    render() {
        return (
            <React.Fragment>
                    <section className="mid-wrapper">
                        <div className="container">
                            <div className="register-mid">
                                <h2><i><img src="assets/images/register-icon.svg" alt=""/></i>Application For Agent
                                    Registration</h2>
                                {/*user-login Start*/}
                                <div className="thank-wrap">
                                    <div className="thank-outer">
                                        <div className="thank-body">
                                            <div className="thank-top">
                                                <div className="thank-icon"><i className="material-icons">check</i></div>
                                            </div>
                                            <h2>Thank You</h2>
                                            <p>You are successfully registered. We will inform you by email after verification.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </React.Fragment>
        );
    }
}

export default ThankYou;