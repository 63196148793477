import API from '.';

var Agent_API = {
    meta: {},
};
//calling my account details Api
Agent_API.getMyAccount = () => {
    return API.getResponseSession('/account/my-account');
};
//calling agent chane passwors Api
Agent_API.agentChangePassword = (credentials) => {
    return API.postResponseSession('/change-password', credentials);
};
//calling bank detail Api
Agent_API.agentBankDetails = () => {
    return API.getResponseSession('/account/bank-details');
};
//calling get all deposit listing request Api
Agent_API.getDepositRequest = (limitOffset, searchValues) => {
    return API.getResponseSession('/account/get-deposit-request' + limitOffset + searchValues);
};
//calling get all credit listing Api
Agent_API.getViewCreditRequest = (limitOffset, searchValues) => {
    return API.getResponseSession('/account/get-credit-request' + limitOffset + searchValues);
};
//calling get all dposit type
Agent_API.getDepositType = () => {
    return API.getResponseSession('/account/get-deposit-types');
};
// calling add new deposit request
Agent_API.newDepositRequest = (credentials) => {
    return API.postResponseSession('/account/add-deposit-request', credentials);
};
//calling add credit request
Agent_API.addCreditRequest = (credentials) => {
    return API.postResponseSession("/account/add-credit-request", credentials)
};
// calling update agent information
Agent_API.updateAgent = (credentials) => {
    return API.postResponseSession("/account/update-agent-detail", credentials)
};
//Calling get domestic markup tool
Agent_API.getMarkupToolInfo = (category) => {
    return API.getResponseSession('/account/get-markup-tool/' + category);
}
//Calling update domestic markup tool
Agent_API.updateDomesticMarkupTool = (credentials) => {
    return API.postResponseSession("/account/add-markup-tool/domestic", credentials)
}
//Calling update International markup tool
Agent_API.updateInternationalMarkupTool = (credentials) => {
    return API.postResponseSession("/account/add-markup-tool/international", credentials)
}
//calling calendar details Api
Agent_API.calendarDetails = (params) => {
    return API.getResponseSession(`/account/calendar?start_date=${params && params.start_date}&end_date=${params && params.end_date}`);
};
//Calling get booking api 
Agent_API.getBookingDetailsApi = (credentials) => {
    return API.postResponseSession("/jfot/flight/booking/get-booking-details", credentials) //sus
}
//calling user type API
Agent_API.getUserTypeApi = () => {
    return API.getResponseSession('/info/user-type');
};
//calling transaction type API
Agent_API.getTransactionTypeApi = () => {
    return API.getResponseSession('/info/transaction-type');
};
//calling booking status API
Agent_API.getBookingStatusApi = (queryString) => {
    return API.getResponseSession('/info/booking-status' + queryString);
};
//calling account statement API
Agent_API.getAccountStatementApi = (queryStr) => {
    if(queryStr && queryStr !== "")
    {
        return API.getResponseSession('/my-transactions'+queryStr);
    }
    else
    {
        return API.getResponseSession('/my-transactions');
    }
    
};
//calling my account API
Agent_API.getMyBookingsApi = (queryString) => {
    return API.getResponseSession('/account/my-bookings' + queryString);
};
//calling cancel booking API
Agent_API.cancelBookingApi = (credentials) => {
    return API.postResponseSession('/jfot/flight/booking/cancel-ticket', credentials); // change
};
//calling Release PNR API
Agent_API.releasePnrApi = (credentials) => {
    return API.postResponseSession('/jfot/flight/booking/cancel-hold-booking', credentials); //change
};
//calling update ticket API
Agent_API.updateTicketApi = (credentials) => {
    return API.postResponseSession('/flight/booking/update-txn-discount', credentials); 
};
//calling email ticket API
Agent_API.emailTicketApi = (credentials) => {
    return API.postResponseSession('/account/email-ticket', credentials);
};
//calling cancelrefund API
Agent_API.cancelRefundApi = (credentials) => {
    return API.postResponseSession('/jfot/flight/booking/get-booking-refund-detail', credentials); // cha
};
//calling booking report API
Agent_API.bookingReportApi = (credentials) => {
    return API.postResponseDownloadSession('/account/booking-report', credentials);
};
//calling email Invoice API
Agent_API.emailInvoiceApi = (credentials) => {
    return API.postResponseSession('/account/email-invoice', credentials);
};
//calling email Invoice for offline booking API
Agent_API.emailOfflineInvoiceApi = (credentials) => {
    return API.postResponseSession('/account/email-offline-invoice', credentials);
};

//calling booking info API
Agent_API.getBookingInfo = (credentials) => {
    return API.postResponseSession('/account/booking-details', credentials);
};

//calling email ticket API
Agent_API.emailOfflineTicketApi = (credentials) => {
    return API.postResponseSession('/account/email-offline-ticket', credentials);
};
//Calling Hotel markup tool
Agent_API.getHotelMarkupInfo = (category) => {
    return API.getResponseSession('/hotel/get-markup');
};
//Calling update International markup tool
Agent_API.submitHotelMarkupInfo = (credentials) => {
    return API.postResponseSession("/hotel/update-markup", credentials)
}

//calling booking info API
Agent_API.getHotelInvoiceInfo = (bookingId) => {
    return API.postResponseSession('/hotel/offline/get-booking-details', bookingId);
};

//calling hotel bookings list API for my account
Agent_API.getMyHotelBookingsApi = (queryString) => {
    return API.getResponseSession('/hotel/booking-list' + queryString);
};

//calling cancel Hotel booking API
Agent_API.cancelHotelBookingApi = (credentials) => {
    return API.postResponseSession('/jfot/hotel/cancel-booking', credentials); // change
};

//calling email Hotel Invoice API
Agent_API.emailHotelInvoiceApi = (credentials) => {
    return API.postResponseSession('/account/email-hotel-invoice', credentials);
};

//calling cancel hotel refund API
Agent_API.cancelHotelRefundApi = (credentials) => {
    return API.postResponseSession('/jfot/hotel/get-cancel-booking-detail', credentials); //cha
};

//calling email flight credit note API
Agent_API.emailFlightCreditNoteApi = (credentials) => {
    return API.postResponseSession('/account/email-flight-credit-note', credentials);
};

//calling email hotel credit note API
Agent_API.emailHotelCreditNoteApi = (credentials) => {
    return API.postResponseSession('/account/email-hotel-credit-note', credentials);
};

//calling partial cancellation detail API
Agent_API.partialCancellationApi = (credentials) => {
    return API.postResponseSession('/jfot/flight/booking/partial-cancel-details', credentials); // cha
};

//calling top initiate payment API
Agent_API.getTopUpApi = (credentials) => {
    return API.postResponseSession('/agent/intiate-topup', credentials);
};

//calling top up payment response API
Agent_API.getTopUpPaymentApi = (credentials) => {
    return API.postResponseSession('/agent/topup-response', credentials);
};

//calling All top up fetch API
Agent_API.getAllTopUpApi = (limitOffset, searchValues) => {
    return API.getResponseSession('/agent/all-topups' + limitOffset + searchValues);
};

//calling train cancel booking API
Agent_API.cancelTrainBookingApi = (credentials) => {
    return API.postResponseSession('/train-cancel-booking', credentials);
};

//calling Release Tripjack Cancel Hold Booking API
Agent_API.cancelHoldBookingApi = (credentials) => {
    return API.postResponseSession('/jfkt/flight/cancel-hold-booking', credentials);
};

//calling tripjack cancel booking API
Agent_API.cancelTripjackBookingApi = (credentials) => {
    return API.postResponseSession('/jfkt/flight/cancel-booking', credentials);
};

//calling kafila cancel booking API
Agent_API.cancelKafilaBookingApi = (credentials) => {
    return API.postResponseSession('/jfak/v2/flight/booking/cancel-ticket', credentials);
};
//calling cancel Hotel Tripjack booking API
Agent_API.cancelTripjackHotelBookingApi = (credentials) => {
    return API.postResponseSession('/jfkt/hotel/cancel-booking', credentials);
};
//calling release Hotel tripjack API
Agent_API.releaseHoldBookingApi=(credentials)=>{
    return API.postResponseSession('/jfkt/hotel/cancel-hold-booking',credentials)
}
// request cancellation
Agent_API.getMyRequestCancellationApi = (queryString) => {
    return API.getResponseSession('/account/flight-requests' + queryString);
};
//calling riya cancel booking API
Agent_API.cancelRiyaBookingApi = (credentials) => {
    return API.postResponseSession('/jfar/flight/booking/cancel-ticket', credentials);
};

export default Agent_API;