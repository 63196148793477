import React from 'react';
import OwlCarousel from 'react-owl-carousel2';

class Carousel extends React.Component {
    render() {
        const carouselData = this.props.dataSlider;
        const options = {
            nav:false,
            items :1,
            itemsDesktop : [1199,1],
            itemsDesktopSmall : [980,1],
            itemsTablet: [768,1],
            itemsTabletSmall: false,
            itemsMobile : [479,1],
            navigation: true,
            autoplay: true,
            //navigationText:false,
            navText : ["<i class='icon icon-circle-left'></i>", "<i class='icon icon-circle-right'></i>"],
        };
        return (
            <OwlCarousel ref="car" options={options} className={this.props.carousalClass} >
                {carouselData && carouselData.map((item,index) => 
                    {
                        if(this.props.carousalClass === 'banner-carosuel')
                        {
                            return (
                                <div key={index}><img src={item.banner_image} alt="" /></div>
                            );
                        }
                        else if(this.props.carousalClass === 'owl-carousel-news' && item.is_important === 0)
                        {
                            return (
                                <div key={index} dangerouslySetInnerHTML={{ __html: item.notice }} />
                            );
                        }
                        else if(this.props.carousalClass === 'photo-carosuel')
                        {
                            return (
                                item.url ?
                                    <li key={index}><img src={item.url} alt="" /></li> :
                                    <li key={index}><img src={item} alt="" /></li>
                            );
                        }
                        else{
                            return false;
                        }
                    })
                }
            </OwlCarousel>
        );
    }
}
export default Carousel;

