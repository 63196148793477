//import all files
import {getFareQuoteApi , getSsrDetailApi,getPaxTitleApi,holdBookingApi,ticketBookingApi,getCompanyDetailApi,getKafilaFareQuoteApi, kafilaPnrCreateApi, kafilaPnrRetrieveApi,kafilaPnrCheckApi, getCellCountryCodeApi,getTripJactFareQuoteApi,getTripJackSeatInfoApi,tripJackTicketBookingApi,tripJackBookDetailsApi, getAirIQFareQuoteApi,airIQTicketBookingApi,airIQBookDetailsApi,tripJackBookConfirmApi, validateTripJackTicketApi, getGoflyFareQuoteApi, goflyBookDetailsApi, goflyTicketBookingApi, geteflyFareQuoteApi, eflyTicketBookingApi, eflyBookDetailsApi,getKafilaSsrDetailApi,
    kafilaCanceReasonApi,getSatkarFareQuoteApi, satkarTicketBookingApi,satkarBookDetailsApi,
    getRiyaFareQuoteApi,
    getRiyaSeatInfoApi,riyaTicketBookingApi, riyaBookDetailsApi,riyaHoldBookingApi, riyaBookConfirmApi, getFareBoutiqueFareQuoteApi, fareBoutiqueTicketBookingApi, fareBoutiqueBookDetailsApi } from '../../api/flight';

export const KAFILA_SEAT_STATE = 'KAFILA_SEAT_STATE';
export const kafilaSeatState = (response) => {
    return {
        type: KAFILA_SEAT_STATE,
        response:response
    };
};

//***************************************************** */Get farequotes data for round trip itenary
export const GET_ROUND_FARE_START = 'GET_ROUND_FARE_START';
export const getRoundFareStart = () => {
    return {
        type: GET_ROUND_FARE_START
    };
};

export const GET_ROUND_FARE_OK = 'GET_ROUND_FARE_OK';
export const getRoundFareSuccess = (response) => {
    return {
        type: GET_ROUND_FARE_OK,
        response: response
    };
};

export const GET_ROUND_FARE_ERR = 'GET_ROUND_FARE_ERR';
export const getRoundFareError = (response) => {
    return {
        type: GET_ROUND_FARE_ERR,
        response: response
    };
};

//***************************************************************/Get fare details for single fareQuotes
export const GET_FARE_START = 'GET_FARE_START';
export const getFareStart = () => {
    return {
        type: GET_FARE_START
    };
};

export const GET_FARE_OK = 'GET_FARE_OK';
export const getFareSuccess = (response) => {
    return {
        type: GET_FARE_OK,
        response: response
    };
};

export const GET_FARE_ERR = 'GET_FARE_ERR';
export const getFareError = (response) => {
    return {
        type: GET_FARE_ERR,
        response: response
    };
};

export const getFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            
            dispatch(getRoundFareStart());
        }
        else{
            
            dispatch(getFareStart());
        }
        callback && dispatch(getSsrStart());
        return getFareQuoteApi(data)
            .then((response) => {
                
                if(response.status === 'ok'){
                    //response.isKafila="true"
                    if(index === 1){
                     
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                        
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};




//**************************************************************************/Get SSR for Round Trip itenary
export const GET_ROUND_SSR_START = 'GET_ROUND_SSR_START';
export const getRoundSsrStart = () => {
    return {
        type: GET_ROUND_SSR_START
    };
};

export const GET_ROUND_SSR_OK = 'GET_ROUND_SSR_OK';
export const getRoundSsrSuccess = (response) => {
    return {
        type: GET_ROUND_SSR_OK,
        response: response
    };
};

export const GET_ROUND_SSR_ERR = 'GET_ROUND_SSR_ERR';
export const getRoundSsrError = (response) => {
   return {
        type: GET_ROUND_SSR_ERR,
        response: response
    };
};

//**************************************************************************/Get SSR for Round Trip itenary
export const GET_KAFILA_ROUND_SSR_START = "GET_KAFILA_ROUND_SSR_START";
export const getKafilaRoundSsrStart = () => {
  return {
    type: GET_KAFILA_ROUND_SSR_START,
  };
};

export const GET_KAFILA_ROUND_SSR_OK = "GET_KAFILA_ROUND_SSR_OK";
export const getKafilaRoundSsrSuccess = (response) => {
  return {
    type: GET_KAFILA_ROUND_SSR_OK,
    response: response,
  };
};

export const GET_KAFILA_ROUND_SSR_ERR = "GET_KAFILA_ROUND_SSR_ERR";
export const getKafilaRoundSsrError = (response) => {
  return {
    type: GET_KAFILA_ROUND_SSR_ERR,
    response: response,
  };
};

//**************************************************************************/Get extra details of flight like baggage, meal for one way and single itenary
export const GET_SSR_START = 'GET_SSR_START';
export const getSsrStart = () => {
    return {
        type: GET_SSR_START
    };
};

export const GET_SSR_OK = 'GET_SSR_OK';
export const getSsrSuccess = (response) => {
    return {
        type: GET_SSR_OK,
        response: response
    };
};

export const GET_SSR_ERR = 'GET_SSR_ERR';
export const getSsrError = (response) => {
   return {
        type: GET_SSR_ERR,
        response: response
    };
};

export const getSsrDetail = (data,index,errorCb) => {
    return (dispatch, getState) => {
        if(index === 1){
           
            dispatch(getRoundSsrStart());
        }else{
            
            dispatch(getSsrStart());
        }
        return getSsrDetailApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                   
                    //If meal dynamic is not empty
                    if(response.meal_dynamic){
                        //Update meal dynamic according to origin and destination
                        let meal_dynamic = [];
                        for(let mealOuter = 0 ;mealOuter < response.meal_dynamic.length;mealOuter++){
                            const total_meals = {}
                            for(let mealInner = 0;mealInner < response.meal_dynamic[mealOuter].length ; mealInner++){
                                let item = response.meal_dynamic[mealOuter][mealInner];
                                total_meals[`${item.origin}_${item.destination}`] = total_meals[`${item.origin}_${item.destination}`] || []
                                total_meals[`${item.origin}_${item.destination}`].push(item) 
                            }
                            meal_dynamic[mealOuter] = total_meals;
                        }
                        response.meal_dynamic = meal_dynamic;
                    }
                    //If baggage dynamic is not empty
                    if(response.baggage){
                        //Update baggage according to origin and destination
                        let baggage = [];
                        for(let baggageOuter = 0 ;baggageOuter < response.baggage.length;baggageOuter++){
                            const total_baggage = {}
                            for(let baggageInner = 0;baggageInner < response.baggage[baggageOuter].length ; baggageInner++){
                                let item = response.baggage[baggageOuter][baggageInner];
                                total_baggage[`${item.origin}_${item.destination}`] = total_baggage[`${item.origin}_${item.destination}`] || []
                                total_baggage[`${item.origin}_${item.destination}`].push(item) 
                            }
                            baggage[baggageOuter] = total_baggage;
                        }
                        response.baggage = baggage;
                    }
                    if(index === 1){
                     
                        dispatch(getRoundSsrSuccess(response));
                    }else{
                        
                        dispatch(getSsrSuccess(response));
                    }
                    // successCb && successCb(response);
                } else{
                   
                    if(index === 1){
                      
                        dispatch(getRoundSsrError(response));
                    }else{
                       
                        dispatch(getSsrError(response));
                    }
                   
                    errorCb && errorCb(response);
                }
            });
    }
};

//Get pax title
export const GET_PAX_TITLE_START = 'GET_PAX_TITLE_START';
export const getPaxTitleStart = () => {
    return {
        type: GET_PAX_TITLE_START
    };
};

export const GET_PAX_TITLE_OK = 'GET_PAX_TITLE_OK';
export const getPaxTitleSuccess = (response) => {
    return {
        type: GET_PAX_TITLE_OK,
        response: response
    };
};

export const GET_PAX_TITLE_ERR = 'GET_PAX_TITLE_ERR';
export const getPaxTitleError = (response) => {
    return {
        type: GET_PAX_TITLE_ERR,
        response: response
    };
};

export const getPaxTitle = (data) => {
    return (dispatch, getState) => {
        dispatch(getPaxTitleStart());
        return getPaxTitleApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(getPaxTitleSuccess(response));
                    // successCb && successCb(response);
                } else{
                    dispatch(getPaxTitleError(response));
                    // errorCb && errorCb(response);
                }
            });
    }
};

//Hold booking
export const HOLD_BOOKING_START = 'HOLD_BOOKING_START';
export const holdBookingStart = () => {
    return {
        type: HOLD_BOOKING_START
    };
};

export const HOLD_BOOKING_OK = 'HOLD_BOOKING_OK';
export const holdBookingSuccess = (response) => {
    return {
        type: HOLD_BOOKING_OK,
        response: response
    };
};

export const HOLD_BOOKING_ERR = 'HOLD_BOOKING_ERR';
export const holdBookingErr = (response) => {
    return {
        type: HOLD_BOOKING_ERR,
        response: response
    };
};

export const holdBooking = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(holdBookingStart());
        return holdBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(holdBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(holdBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//Confirm booking
export const TICKET_BOOKING_START = 'TICKET_BOOKING_START';
export const ticketBookingStart = () => {
    return {
        type: TICKET_BOOKING_START
    };
};

export const TICKET_BOOKING_OK = 'TICKET_BOOKING_OK';
export const ticketBookingSuccess = (response) => {
    return {
        type: TICKET_BOOKING_OK,
        response: response
    };
};

export const TICKET_BOOKING_ERR = 'TICKET_BOOKING_ERR';
export const ticketBookingErr = (response) => {
    return {
        type: TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(ticketBookingStart());
        return ticketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(ticketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(ticketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};




//*********************************************************Get GST Company detail************************************************************/
export const GET_COMPANY_DETAIL_START = 'GET_COMPANY_DETAIL_START';
export const getCompanyDetailStart = () => {
    return {
        type: GET_COMPANY_DETAIL_START
    };
};

export const GET_COMPANY_DETAIL_OK = 'GET_COMPANY_DETAIL_OK';
export const getCompanyDetailSuccess = (response) => {
    return {
        type: GET_COMPANY_DETAIL_OK,
        response: response
    };
};

export const GET_COMPANY_DETAIL_ERR = 'GET_COMPANY_DETAIL_ERR';
export const getCompanyDetailErr = (response) => {
    return {
        type: GET_COMPANY_DETAIL_ERR,
        response: response
    };
};

export const getCompanyDetail = () => {
    return (dispatch, getState) => {
        dispatch(getCompanyDetailStart());
        return getCompanyDetailApi()
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(getCompanyDetailSuccess(response));
                    // successCb && successCb(response);
                } else{
                    dispatch(getCompanyDetailErr(response));
                    // errorCb && errorCb(response);
                }
            });
    }
};
//********************************************************* Cell Country Code ************************************************************/

export const GET_CELL_COUNTRY_CODE_START = 'GET_CELL_COUNTRY_CODE_START';
export const getCellCountryCodeStart = () => {
    return {
        type: GET_CELL_COUNTRY_CODE_START
    };
};

export const GET_CELL_COUNTRY_CODE_OK = 'GET_CELL_COUNTRY_CODEL_OK';
export const getCellCountryCodeSuccess = (response) => {
    return {
        type: GET_CELL_COUNTRY_CODE_OK,
        response: response
    };
};

export const GET_CELL_COUNTRY_CODE_ERR = 'GET_CELL_COUNTRY_CODE_ERR';
export const getCellCountryCodeErr = (response) => {
    return {
        type: GET_CELL_COUNTRY_CODE_ERR,
        response: response
    };
};
export const getCellCountryCode = () => {
    return (dispatch, getState) => {
        dispatch(getCellCountryCodeStart());
        return getCellCountryCodeApi()
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(getCellCountryCodeSuccess(response));
                    // successCb && successCb(response);
                } else{
                    dispatch(getCellCountryCodeErr(response));
                    // errorCb && errorCb(response);
                }
            });
    }
};
//*********************************************************Save Round trip booking in FareQuote ************************************************************/
export const SET_FARE_QUOTE_VALUE = 'SET_FARE_QUOTE_VALUE';
export const setFareQuoteValue = (response) => {
        return {
            type: SET_FARE_QUOTE_VALUE,
            response: response
        };
};

//*********************************************************Save Round trip booking in SSR ************************************************************/
export const SET_SSR_VALUE = 'SET_SSR_VALUE';
export const setSSRValue = (response) => {
        return {
            type: SET_SSR_VALUE,
            response: response
        };
};
//=========================== Save Round trip pax info =========================================
//Save outbound booking pax details for showing pax details prefilled in inbound details
export const SAVE_OUTBOUND_PAX_INFO = 'SAVE_OUTBOUND_PAX_INFO';
export const saveFlightOutboundPaxInfo = (data,booking_id,outboundPub) => {
    return {
        type: SAVE_OUTBOUND_PAX_INFO,
        outboundDetails: data,
        booking_id:booking_id,
        outboundPub:outboundPub
    };
};



//*********************************************************Save Round trip booking in Seat Info ************************************************************/
export const SET_TRIP_JACK_SEAT_INFO = 'SET_TRIP_JACK_SEAT_INFO';
export const setTripJackSeatInfo = (response) => {
        return {
            type: SET_TRIP_JACK_SEAT_INFO,
            response: response
        };
};
//kafila fare quote 
export const getKafilaFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            
            dispatch(getRoundFareStart());
        }
        else{
         
            dispatch(getFareStart());
        }
        callback && dispatch(getSsrStart());
        return getKafilaFareQuoteApi(data)
            .then((response) => {
             
                if(response.status === 'ok'){
                    response.isKafila="true";
                    if(index === 1){
                      
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                       
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                   
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//**************************************************************************/Get extra details of flight like baggage, meal for one way and single itenary
export const GET_KAFILA_SSR_START = "GET_KAFILA_SSR_START";
export const getKafilaSsrStart = () => {
  return {
    type: GET_KAFILA_SSR_START,
  };
};

export const GET_KAFILA_SSR_OK = "GET_KAFILA_SSR_OK";
export const getKafilaSsrSuccess = (response) => {
  return {
    type: GET_KAFILA_SSR_OK,
    response: response,
  };
};

export const GET_KAFILA_SSR_ERR = "GET_KAFILA_SSR_ERR";
export const getKafilaSsrError = (response) => {
  return {
    type: GET_KAFILA_SSR_ERR,
    response: response,
  };
};

export const getKafilaSsrDetail = (data, index, errorCb) => {
  return (dispatch, getState) => {
    if (index === 1) {
      dispatch(getKafilaRoundSsrStart());
    } else {
      dispatch(getKafilaSsrStart());
    }
    return getKafilaSsrDetailApi(data).then((response) => {
      if (response.status === "ok") {
        //If meal dynamic is not empty
        if (response && response.result && response.result.meal_dynamic && response.result.meal_dynamic.length>0) {
          //Update meal dynamic according to origin and destination
          let meal_dynamic = [];
          for (
            let mealOuter = 0;
            mealOuter < response.result.meal_dynamic.length;
            mealOuter++
          ) {
            const total_meals = {};
            let item = response.result&&response.result.meal_dynamic&&response.result.meal_dynamic[mealOuter];
            total_meals[`${item.origin}_${item.destination}`] =
              total_meals[`${item.origin}_${item.destination}`] || [];
            total_meals[`${item.origin}_${item.destination}`].push(item);
            meal_dynamic[mealOuter] = total_meals;
          }
          const organizedObject = {};

          meal_dynamic.forEach((item) => {
            const destination = Object.keys(item)[0];

            if (!organizedObject[destination]) {
              organizedObject[destination] = [];
            }

            organizedObject[destination].push(item[destination][0]);
          });

          const organizedArray = Object.entries(organizedObject).map(
            ([destination, items]) => ({
              [destination]: items,
            })
          );
          response.result.meal_dynamic = organizedArray;
        }
        //If baggage dynamic is not empty
        if (response && response.result && response.result.baggage) {
          //Update baggage according to origin and destination
          let baggage = [];
          for (
            let baggageOuter = 0;
            baggageOuter < response.result.baggage.length;
            baggageOuter++
          ) {
            const total_baggage = {};
            let item = response.result.baggage[baggageOuter];
            total_baggage[`${item.origin}_${item.destination}`] =
              total_baggage[`${item.origin}_${item.destination}`] || [];
            total_baggage[`${item.origin}_${item.destination}`].push(item);
            baggage[baggageOuter] = total_baggage;
          }

          const organizedObject = {};

          baggage.forEach((item) => {
            const destination = Object.keys(item)[0];

            if (!organizedObject[destination]) {
              organizedObject[destination] = [];
            }

            organizedObject[destination].push(item[destination][0]);
          });

          const organizedArray = Object.entries(organizedObject).map(
            ([destination, items]) => ({
              [destination]: items,
            })
          );
          response.baggage = organizedArray;
        }
        if (index === 1) {
          dispatch(getKafilaRoundSsrSuccess(response));
        } else {
          dispatch(getKafilaSsrSuccess(response));
        }
        // successCb && successCb(response);
      } else {
        if (index === 1) {
          dispatch(getKafilaRoundSsrError(response));
        } else {
          dispatch(getKafilaSsrError(response));
        }
        errorCb && errorCb(response);
      }
    });
  };
};

// kafila cancel reason

export const KAFILA_CANCEL_REASON_START = "KAFILA_CANCEL_REASON_START";
export const kafila_cancel_reason_start = () => {
  return {
    type: KAFILA_CANCEL_REASON_START,
  };
};

export const KAFILA_CANCEL_REASON_OK = "KAFILA_CANCEL_REASON_OK";
export const kafila_cancel_reason_success = (response) => {
  return {
    type: KAFILA_CANCEL_REASON_OK,
    response: response,
  };
};

export const KAFILA_CANCEL_REASON_ERR = "KAFILA_CANCEL_REASON_ERR";
export const kafila_cancel_reason_err = (response) => {
  return {
    type: KAFILA_CANCEL_REASON_ERR,
    response: response,
  };
};

export const kafilaCancelReasonSubmit = (data) => {
  return (dispatch) => {
    dispatch(kafila_cancel_reason_start());
    return kafilaCanceReasonApi().then((response) => {
      if (response.status === "ok") {
        dispatch(kafila_cancel_reason_success(response));
        // successCb && successCb(response);
      } else {
        dispatch(kafila_cancel_reason_err(response));
        // errorCb && errorCb(response);
      }
    });
  };
};

//kafila pnr create

export const CREATE_PNR_START = 'CREATE_PNR_START';
export const createPnrStart = () => {
    return {
        type: CREATE_PNR_START
    };
};

export const CREATE_PNR_OK = 'CREATE_PNR_OK';
export const createPnrSuccess = (response,loadingVal) => {
    return {
        type: CREATE_PNR_OK,
        response: response,
        loadingVal : loadingVal
    };
};

export const CREATE_PNR_ERR = 'CREATE_PNR_ERR';
export const createPnrErr = (response) => {
    return {
        type: CREATE_PNR_ERR,
        response: response
    };
};

export const kafilaPnrCreate = (data,errorCb,callback) => {
    return (dispatch, getState) => {
        dispatch(createPnrStart());
        return kafilaPnrCreateApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    let loadingVal = true;
                    if(response.booking_type === "drop")
                    {
                        loadingVal = false;
                    }
                    dispatch(createPnrSuccess(response,loadingVal));
                    callback && callback(response);
                } else{
                    dispatch(createPnrErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//kafila pnr retrieve

export const RETRIEVE_PNR_START = 'RETRIEVE_PNR_START';
export const retrievePnrStart = () => {
    return {
        type: RETRIEVE_PNR_START
    };
};

export const RETRIEVE_PNR_OK = 'RETRIEVE_PNR_OK';
export const retrievePnrSuccess = (response) => {
    return {
        type: RETRIEVE_PNR_OK,
        response: response
    };
};

export const RETRIEVE_PNR_ERR = 'RETRIEVE_PNR_ERR';
export const retrievePnrErr = (response) => {
    return {
        type: RETRIEVE_PNR_ERR,
        response: response
    };
};

export const kafilaPnrRetrieve = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(retrievePnrStart());
        return kafilaPnrRetrieveApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(retrievePnrSuccess(response));
                    successCb && successCb(response);
                } else{
                    if(!data.showLoader)
                    {
                        dispatch(retrievePnrErr(response.status, response.message, response.errors));
                    }
                    errorCb && errorCb(response);
                }
            });
    }
};

//kafila pnr check

export const PNR_CHECK_START = 'PNR_CHECK_START';
export const pnrCheckStart = () => {
    return {
        type: PNR_CHECK_START
    };
};

export const PNR_CHECK_OK = 'PNR_CHECK_OK';
export const pnrCheckSuccess = (response) => {
    return {
        type: PNR_CHECK_OK,
        response: response
    };
};

export const PNR_CHECK_ERR = 'PNR_CHECK_ERR';
export const pnrCheckErr = (response) => {
    return {
        type: PNR_CHECK_ERR,
        response: response
    };
};

export const kafilaPnrCheck = (data, successCb, errorCb) => {
    return (dispatch, getState) => {
        dispatch(pnrCheckStart());
        return kafilaPnrCheckApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(pnrCheckSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(pnrCheckErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


//***************************************************** */Get farequotes data for round trip itenary Tripjack
export const GET_ROUND_FARE_TRIP_JACK_START = 'GET_ROUND_FARE_TRIP_JACK_START';
export const getRoundFareTripJackStart = () => {
    return {
        type: GET_ROUND_FARE_TRIP_JACK_START
    };
};

export const GET_ROUND_FARE_TRIP_JACK_OK = 'GET_ROUND_FARE_TRIP_JACK_OK';
export const getRoundFareTripJackSuccess = (response) => {
    return {
        type: GET_ROUND_FARE_TRIP_JACK_OK,
        response: response
    };
};

export const GET_ROUND_FARE_TRIP_JACK_ERR = 'GET_ROUND_FARE_TRIP_JACK_ERR';
export const getRoundFareTripJackError = (response) => {
    return {
        type: GET_ROUND_FARE_TRIP_JACK_ERR,
        response: response
    };
};

//***************************************************************/Get fare details for single fareQuotes Tripjack
export const GET_FARE_TRIP_JACK_START = 'GET_FARE_TRIP_JACK_START';
export const getFareTripJackStart = () => {
    return {
        type: GET_FARE_TRIP_JACK_START
    };
};

export const GET_FARE_TRIP_JACK_OK = 'GET_FARE_TRIP_JACK_OK';
export const getFareTripJackSuccess = (response) => {
    return {
        type: GET_FARE_TRIP_JACK_OK,
        response: response
    };
};

export const GET_FARE_TRIP_JACK_ERR = 'GET_FARE_TRIP_JACK_ERR';
export const getFareTripJackError = (response) => {
    return {
        type: GET_FARE_TRIP_JACK_ERR,
        response: response
    };
};

//Trip Jack fare quote 
export const getTripJackFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundFareTripJackStart());
        }
        else{
            dispatch(getFareTripJackStart());
        }
        return getTripJactFareQuoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    if(index === 1){
                        dispatch(getRoundFareTripJackSuccess(response));
                    }
                    else{
                        dispatch(getFareTripJackSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//Trip Jack seat info Round wise
export const GET_ROUND_SEAT_INFO_START = 'GET_ROUND_SEAT_INFO_START';
export const getRoundSeatInfoStart = () => {
    return {
        type: GET_ROUND_SEAT_INFO_START
    };
};

export const GET_ROUND_SEAT_INFO_OK = 'GET_ROUND_SEAT_INFO_OK';
export const getRoundSeatInfoSuccess = (response) => {
    return {
        type: GET_ROUND_SEAT_INFO_OK,
        response: response
    };
};

export const GET_ROUND_SEAT_INFO_ERR = 'GET_ROUND_SEAT_INFO_ERR';
export const getRoundSeatInfoError = (response) => {
   return {
        type: GET_ROUND_SEAT_INFO_ERR,
        response: response
    };
};

//Trip Jack seat info One way 

export const GET_SEAT_INFO_START = 'GET_SEAT_INFO_START';
export const getSeatInfoStart = () => {
    return {
        type: GET_SEAT_INFO_START
    };
};

export const GET_SEAT_INFO_OK = 'GET_SEAT_INFO_OK';
export const getSeatInfoSuccess = (response) => {
    return {
        type: GET_SEAT_INFO_OK,
        response: response
    };
};

export const GET_SEAT_INFO_ERR = 'GET_SEAT_INFO_ERR';
export const getSeatInfoError = (response) => {
   return {
        type: GET_SEAT_INFO_ERR,
        response: response
    };
};
export const getTripJackSeatInfo = (data,index,errorCb) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundSeatInfoStart());
        }else{
            dispatch(getSeatInfoStart());
        }
        return getTripJackSeatInfoApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    
                    if(index === 1){
                        dispatch(getRoundSeatInfoSuccess(response));
                    }else{
                        dispatch(getSeatInfoSuccess(response));
                    }
                    // successCb && successCb(response);
                } else{
                    if(index === 1){
                        dispatch(getRoundSeatInfoError(response));
                    }else{
                        dispatch(getSeatInfoError(response));
                    }
                    errorCb && errorCb(response);
                }
            });
    }
};

//Trip Jack booking
export const TRIP_JACK_TICKET_BOOKING_START = 'TRIP_JACK_TICKET_BOOKING_START';
export const tripJackTicketBookingStart = () => {
    return {
        type: TRIP_JACK_TICKET_BOOKING_START
    };
};

export const TRIP_JACK_TICKET_BOOKING_OK = 'TRIP_JACK_TICKET_BOOKING_OK';
export const tripJackTicketBookingSuccess = (response) => {
    return {
        type: TRIP_JACK_TICKET_BOOKING_OK,
        response: response
    };
};

export const TRIP_JACK_TICKET_BOOKING_ERR = 'TRIP_JACK_TICKET_BOOKING_ERR';
export const tripJackTicketBookingErr = (response) => {
    return {
        type: TRIP_JACK_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookTripJacKTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(tripJackTicketBookingStart());
        return tripJackTicketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(tripJackTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(tripJackTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_TRIP_JACK_BOOK_DETAILS_START = "GET_TRIP_JACK_BOOK_DETAILS_START";
const getTripJackBookDetailsStart=()=>{

    return {
        type: GET_TRIP_JACK_BOOK_DETAILS_START
        }
}

export const GET_TRIP_JACK_BOOK_DETAILS_OK = "GET_TRIP_JACK_BOOK_DETAILS_OK";
export const getTripJackBookDetailsOk=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_TRIP_JACK_BOOK_DETAILS_ERR = "GET_TRIP_JACK_BOOK_DETAILS_ERR";
export const getTripJackBookDetailsErr=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_DETAILS_ERR,
        response : response
        }
}

export const tripJackBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getTripJackBookDetailsStart())
       return tripJackBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getTripJackBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getTripJackBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}



export const GET_TRIP_JACK_BOOK_VALIDATE_OK = "GET_TRIP_JACK_BOOK_VALIDATE_OK";
export const getTripJackBookValidateOk=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_VALIDATE_OK,
        response : response
        }
}
export const GET_TRIP_JACK_BOOK_VALIDATE_ERR = "GET_TRIP_JACK_BOOK_VALIDATE_ERR";
export const getTripJackBookValidateErr=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_VALIDATE_ERR,
        response : response
        }
}

export const validateTripJackTicket=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
       return validateTripJackTicketApi(data)
       .then((response)=>{
        if(response.status.success && response.status.success === true){
            dispatch(getTripJackBookValidateOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getTripJackBookValidateErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}
export const GET_TRIP_JACK_BOOK_CONFIRM_OK = "GET_TRIP_JACK_BOOK_CONFIRM_OK";
export const getTripJackBookConfirmOk=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_CONFIRM_OK,
        response : response
        }
}
export const GET_TRIP_JACK_BOOK_CONFIRM_ERR = "GET_TRIP_JACK_BOOK_CONFIRM_ERR";
export const getTripJackBookConfirmErr=(response)=>{
    return {
        type: GET_TRIP_JACK_BOOK_CONFIRM_ERR,
        response : response
        }
}

export const tripJackBookConfirm=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
       return tripJackBookConfirmApi(data)
       .then((response)=>{
        if(response.status.success && response.status.success === true){
            dispatch(getTripJackBookConfirmOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getTripJackBookConfirmErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}
//Air IQ fare quote 
export const getAirIQFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundFareStart());
        }
        else{
            dispatch(getFareStart());
        }
        return getAirIQFareQuoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    if(index === 1){
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


//Air IQ booking
export const AIRIQ_TICKET_BOOKING_START = 'AIRIQ_TICKET_BOOKING_START';
export const airIQTicketBookingStart = () => {
    return {
        type: AIRIQ_TICKET_BOOKING_START
    };
};

export const AIRIQ_TICKET_BOOKING_OK = 'AIRIQ_TICKET_BOOKING_OK';
export const airIQTicketBookingSuccess = (response) => {
    return {
        type: AIRIQ_TICKET_BOOKING_OK,
        response: response
    };
};

export const AIRIQ_TICKET_BOOKING_ERR = 'AIRIQ_TICKET_BOOKING_ERR';
export const airIQTicketBookingErr = (response) => {
    return {
        type: AIRIQ_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookAirIQTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(airIQTicketBookingStart());
        return airIQTicketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(airIQTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(airIQTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_AIRIQ_BOOK_DETAILS_START = "GET_AIRIQ_BOOK_DETAILS_START";
const getAirIQBookDetailsStart=()=>{
    return {
        type: GET_AIRIQ_BOOK_DETAILS_START
        }
}

export const GET_AIRIQ_BOOK_DETAILS_OK = "GET_AIRIQ_BOOK_DETAILS_OK";
export const getAirIQBookDetailsOk=(response)=>{
    return {
        type: GET_AIRIQ_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_AIRIQ_BOOK_DETAILS_ERR = "GET_AIRIQ_BOOK_DETAILS_ERR";
export const getAirIQBookDetailsErr=(response)=>{
    return {
        type: GET_AIRIQ_BOOK_DETAILS_ERR,
        response : response
        }
}

export const airIQBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getAirIQBookDetailsStart())
       return airIQBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getAirIQBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getAirIQBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

//Gofly  fare quote 
export const getGoflyFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundFareStart());
        }
        else{
            dispatch(getFareStart());
        }
        return getGoflyFareQuoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    if(index === 1){
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//Gofly booking
export const GOFLY_TICKET_BOOKING_START = 'GOFLY_TICKET_BOOKING_START';
export const goflyTicketBookingStart = () => {
    return {
        type: GOFLY_TICKET_BOOKING_START
    };
};

export const GOFLY_TICKET_BOOKING_OK = 'GOFLY_TICKET_BOOKING_OK';
export const goflyTicketBookingSuccess = (response) => {
    return {
        type: GOFLY_TICKET_BOOKING_OK,
        response: response
    };
};

export const GOFLY_TICKET_BOOKING_ERR = 'GOFLY_TICKET_BOOKING_ERR';
export const goflyTicketBookingErr = (response) => {
    return {
        type: GOFLY_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookGoflyTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(goflyTicketBookingStart());
        return goflyTicketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(goflyTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(goflyTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_GOFLY_BOOK_DETAILS_START = "GET_GOFLY_BOOK_DETAILS_START";
const getGoflyBookDetailsStart=()=>{
    return {
        type: GET_GOFLY_BOOK_DETAILS_START
        }
}

export const GET_GOFLY_BOOK_DETAILS_OK = "GET_GOFLY_BOOK_DETAILS_OK";
export const getGoflyBookDetailsOk=(response)=>{
    return {
        type: GET_GOFLY_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_GOFLY_BOOK_DETAILS_ERR = "GET_GOFLY_BOOK_DETAILS_ERR";
export const getGoflyBookDetailsErr=(response)=>{
    return {
        type: GET_GOFLY_BOOK_DETAILS_ERR,
        response : response
        }
}

export const goflyBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getGoflyBookDetailsStart())
       return goflyBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getGoflyBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getGoflyBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

//Get efly fare Quote
export const geteflyFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundFareStart());
        }
        else{
            dispatch(getFareStart());
        }
        return geteflyFareQuoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    if(index === 1){
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//efly booking
export const EFLY_TICKET_BOOKING_START = 'EFLY_TICKET_BOOKING_START';
export const eflyTicketBookingStart = () => {
    return {
        type: EFLY_TICKET_BOOKING_START
    };
};

export const EFLY_TICKET_BOOKING_OK = 'EFLY_TICKET_BOOKING_OK';
export const eflyTicketBookingSuccess = (response) => {
    return {
        type: EFLY_TICKET_BOOKING_OK,
        response: response
    };
};

export const EFLY_TICKET_BOOKING_ERR = 'EFLY_TICKET_BOOKING_ERR';
export const eflyTicketBookingErr = (response) => {
    return {
        type: EFLY_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookeflyTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(eflyTicketBookingStart());
        return eflyTicketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(eflyTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(eflyTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//Efly booking details
export const GET_EFLY_BOOK_DETAILS_START = "GET_EFLY_BOOK_DETAILS_START";
const geteflyBookDetailsStart=()=>{
    return {
        type: GET_GOFLY_BOOK_DETAILS_START
        }
}

export const GET_EFLY_BOOK_DETAILS_OK = "GET_EFLY_BOOK_DETAILS_OK";
export const geteflyBookDetailsOk=(response)=>{
    return {
        type: GET_GOFLY_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_EFLY_BOOK_DETAILS_ERR = "GET_EFLY_BOOK_DETAILS_ERR";
export const geteflyBookDetailsErr=(response)=>{
    return {
        type: GET_EFLY_BOOK_DETAILS_ERR,
        response : response
        }
}

export const eflyBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(geteflyBookDetailsStart())
       return eflyBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(geteflyBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(geteflyBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

//satkar   fare quote 
export const getSatkarFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRoundFareStart());
        }
        else{
            dispatch(getFareStart());
        }
        return getSatkarFareQuoteApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    if(index === 1){
                        dispatch(getRoundFareSuccess(response));
                    }
                    else{
                        dispatch(getFareSuccess(response));
                    }
                    callback && callback(response);
                } else{
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//satkar booking
export const SATKAR_TICKET_BOOKING_START = 'SATKAR_TICKET_BOOKING_START';
export const satkarTicketBookingStart = () => {
    return {
        type: SATKAR_TICKET_BOOKING_START
    };
};

export const SATKAR_TICKET_BOOKING_OK = 'SATKAR_TICKET_BOOKING_OK';
export const satkarTicketBookingSuccess = (response) => {
    return {
        type: SATKAR_TICKET_BOOKING_OK,
        response: response
    };
};

export const SATKAR_TICKET_BOOKING_ERR = 'SATKAR_TICKET_BOOKING_ERR';
export const satkarTicketBookingErr = (response) => {
    return {
        type: SATKAR_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookSatkarTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(satkarTicketBookingStart());
        return satkarTicketBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(satkarTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(satkarTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_SATKAR_BOOK_DETAILS_START = "GET_SATKAR_BOOK_DETAILS_START";
const getSatkarBookDetailsStart=()=>{
    return {
        type: GET_SATKAR_BOOK_DETAILS_START
        }
}

export const GET_SATKAR_BOOK_DETAILS_OK = "GET_SATKAR_BOOK_DETAILS_OK";
export const getSatkarBookDetailsOk=(response)=>{
    return {
        type: GET_SATKAR_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_SATKAR_BOOK_DETAILS_ERR = "GET_SATKAR_BOOK_DETAILS_ERR";
export const getSatkarBookDetailsErr=(response)=>{
    return {
        type: GET_SATKAR_BOOK_DETAILS_ERR,
        response : response
        }
}

export const satkarBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getSatkarBookDetailsStart())
       return satkarBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getSatkarBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getSatkarBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

//Riya  fare quote 
// export const getRiyaFareQuote = (data,errorCb,callback,index) => {
//     return (dispatch, getState) => {
//         if(index === 1){
//             dispatch(getRoundFareStart());
//         }
//         else{
//             dispatch(getFareStart());
//         }
//         return getRiyaFareQuoteApi(data)
//             .then((response) => {
//                 if(response.status === 'ok'){
//                     if(index === 1){
//                         dispatch(getRoundFareSuccess(response));
//                     }
//                     else{
//                         dispatch(getFareSuccess(response));
//                     }
//                     callback && callback(response);
//                 } else{
//                     // dispatch(getFareError(response));
//                     errorCb && errorCb(response);
//                 }
//             });
//     }
// };
//***************************************************** */Get farequotes data for round trip itenary Riya
export const GET_ROUND_FARE_RIYA_START = 'GET_ROUND_FARE_RIYA_START';
export const getRoundFareRiyaStart = () => {
    return {
        type: GET_ROUND_FARE_RIYA_START
    };
};

export const GET_ROUND_FARE_RIYA_OK = 'GET_ROUND_FARE_RIYA_OK';
export const getRoundFareRiyaSuccess = (response) => {
    return {
        type: GET_ROUND_FARE_RIYA_OK,
        response: response
    };
};

export const GET_ROUND_FARE_RIYA_ERR = 'GET_ROUND_FARE_RIYA_ERR';
export const getRoundFareRiyaError = (response) => {
    return {
        type: GET_ROUND_FARE_RIYA_ERR,
        response: response
    };
};

//***************************************************************/Get fare details for single fareQuotes Riya
export const GET_FARE_RIYA_START = 'GET_FARE_RIYA_START';
export const getFareRiyaStart = () => {
    return {
        type: GET_FARE_RIYA_START
    };
};

export const GET_FARE_RIYA_OK = 'GET_FARE_RIYA_OK';
export const getFareRiyaSuccess = (response) => {
    return {
        type: GET_FARE_RIYA_OK,
        response: response
    };
};

export const GET_FARE_RIYA_ERR = 'GET_FARE_RIYA_ERR';
export const getFareRiyaError = (response) => {
    return {
        type: GET_FARE_RIYA_ERR,
        response: response
    };
};

//riya fare quote 
export const getRiyaFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
           
            dispatch(getRoundFareRiyaStart());
        }
        else{
         
            dispatch(getFareRiyaStart());
        }
        return getRiyaFareQuoteApi(data)
            .then((response) => {
             
                if(response.status === 'ok'){
                    if(index === 1){
                        
                        dispatch(getRoundFareRiyaSuccess(response));
                    }
                    else{
                        
                        dispatch(getFareRiyaSuccess(response));
                    }
                    callback && callback(response);
                } else{
                  
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};
//Riya Travel seat info Round wise
export const GET_RIYA_ROUND_SEAT_INFO_START = 'GET_RIYA_ROUND_SEAT_INFO_START';
export const getRiyaRoundSeatInfoStart = () => {
    return {
        type: GET_RIYA_ROUND_SEAT_INFO_START
    };
};

export const GET_RIYA_ROUND_SEAT_INFO_OK = 'GET_RIYA_ROUND_SEAT_INFO_OK';
export const getRiyaRoundSeatInfoSuccess = (response) => {
    return {
        type: GET_RIYA_ROUND_SEAT_INFO_OK,
        response: response
    };
};

export const GET_RIYA_ROUND_SEAT_INFO_ERR = 'GET_RIYA_ROUND_SEAT_INFO_ERR';
export const getRiyaRoundSeatInfoError = (response) => {
   return {
        type: GET_RIYA_ROUND_SEAT_INFO_ERR,
        response: response
    };
};

//riya seat info One way 

export const GET_RIYA_SEAT_INFO_START = 'GET_RIYA_SEAT_INFO_START';
export const getRiyaSeatInfoStart = () => {
    return {
        type: GET_RIYA_SEAT_INFO_START
    };
};

export const GET_RIYA_SEAT_INFO_OK = 'GET_RIYA_SEAT_INFO_OK';
export const getRiyaSeatInfoSuccess = (response) => {
    return {
        type: GET_RIYA_SEAT_INFO_OK,
        response: response
    };
};

export const GET_RIYA_SEAT_INFO_ERR = 'GET_RIYA_SEAT_INFO_ERR';
export const getRiyaSeatInfoError = (response) => {
   return {
        type: GET_RIYA_SEAT_INFO_ERR,
        response: response
    };
};
export const getRiyaSeatInfo = (data,index,errorCb) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(getRiyaRoundSeatInfoStart());
        }else{
            dispatch(getRiyaSeatInfoStart());
        }
        return getRiyaSeatInfoApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    
                    if(index === 1){
                        dispatch(getRiyaRoundSeatInfoSuccess(response));
                    }else{
                        dispatch(getRiyaSeatInfoSuccess(response));
                    }
                    // successCb && successCb(response);
                } else{
                    if(index === 1){
                        dispatch(getRiyaRoundSeatInfoError(response));
                    }else{
                        dispatch(getRiyaSeatInfoError(response));
                    }
                    errorCb && errorCb(response);
                }
            });
    }
};

//riya booking 
export const RIYA_TICKET_BOOKING_START = 'RIYA_TICKET_BOOKING_START';
export const riyaTicketBookingStart = () => {
    return {
        type: RIYA_TICKET_BOOKING_START
    };
};

export const RIYA_TICKET_BOOKING_OK = 'RIYA_TICKET_BOOKING_OK';
export const riyaTicketBookingSuccess = (response) => {
    return {
        type: RIYA_TICKET_BOOKING_OK,
        response: response
    };
};

export const RIYA_TICKET_BOOKING_ERR = 'RIYA_TICKET_BOOKING_ERR';
export const riyaTicketBookingErr = (response) => {
    return {
        type: RIYA_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookriyaTicket = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(riyaTicketBookingStart());
        return riyaTicketBookingApi(data)
            .then((response) => {
                
                if(response.status === 'ok'){
                    dispatch(riyaTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(riyaTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_RIYA_BOOK_DETAILS_START = "GET_RIYA_BOOK_DETAILS_START";
const getriyaBookDetailsStart=()=>{

    return {
        type: GET_RIYA_BOOK_DETAILS_START
        }
}

export const GET_RIYA_BOOK_DETAILS_OK = "GET_RIYA_BOOK_DETAILS_OK";
export const getriyaBookDetailsOk=(response)=>{
    return {
        type: GET_RIYA_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_RIYA_BOOK_DETAILS_ERR = "GET_RIYA_BOOK_DETAILS_ERR";
export const getriyaBookDetailsErr=(response)=>{
    return {
        type: GET_RIYA_BOOK_DETAILS_ERR,
        response : response
        }
}

export const riyaBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getriyaBookDetailsStart())
       return riyaBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getriyaBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getriyaBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

export const RIYA_HOLD_BOOKING_START = 'RIYA_HOLD_BOOKING_START';
export const riyaHoldBookingStart = () => {
    return {
        type: RIYA_HOLD_BOOKING_START
    };
};

export const RIYA_HOLD_BOOKING_OK = 'RIYA_HOLD_BOOKING_OK';
export const riyaHoldBookingSuccess = (response) => {
    return {
        type: RIYA_HOLD_BOOKING_OK,
        response: response
    };
};

export const RIYA_HOLD_BOOKING_ERR = 'RIYA_HOLD_BOOKING_ERR';
export const riyaHoldBookingErr = (response) => {
    return {
        type: RIYA_HOLD_BOOKING_ERR,
        response: response
    };
};

export const riyaHoldBooking = (data,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(riyaHoldBookingStart());
        return riyaHoldBookingApi(data)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(riyaHoldBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(riyaHoldBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

export const GET_RIYA_BOOK_CONFIRM_OK = "GET_RIYA_BOOK_CONFIRM_OK";
export const getRiyaBookConfirmOk=(response)=>{
    return {
        type: GET_RIYA_BOOK_CONFIRM_OK,
        response : response
        }
}
export const GET_RIYA_BOOK_CONFIRM_ERR = "GET_RIYA_BOOK_CONFIRM_ERR";
export const getRiyaBookConfirmErr=(response)=>{
    return {
        type: GET_RIYA_BOOK_CONFIRM_ERR,
        response : response
        }
}

export const riyaBookConfirm=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
       return riyaBookConfirmApi(data)
       .then((response)=>{
       
        if(response && response.status === 'ok'){
            dispatch(getRiyaBookConfirmOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getRiyaBookConfirmErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}

//***************************************************** */Get farequotes data for round trip itenary Fare Boutique

export const GET_ROUND_FARE_FAREBOUTIQUE_START = 'GET_ROUND_FARE_FAREBOUTIQUE_START';
export const getRoundFareBoutiqueStart = () => {
    return {
        type: GET_ROUND_FARE_FAREBOUTIQUE_START
    };
};

export const GET_ROUND_FARE_FAREBOUTIQUE_OK = 'GET_ROUND_FARE_FAREBOUTIQUE_OK';
export const getRoundFareBoutiqueSuccess = (response) => {
    return {
        type: GET_ROUND_FARE_FAREBOUTIQUE_OK,
        response: response
    };
};

export const GET_ROUND_FARE_FAREBOUTIQUE_ERR = 'GET_ROUND_FARE_FAREBOUTIQUE_ERR';
export const getRoundFareBoutiqueError = (response) => {
    return {
        type: GET_ROUND_FARE_FAREBOUTIQUE_ERR,
        response: response
    };
};

//***************************************************************/Get fare details for single fareQuotes Riya
export const GET_FARE_FAREBOUTIQUE_START = 'GET_FARE_FAREBOUTIQUE_START';
export const getFareBoutiqueStart = () => {
    return {
        type: GET_FARE_FAREBOUTIQUE_START
    };
};

export const GET_FARE_FAREBOUTIQUE_OK = 'GET_FARE_FAREBOUTIQUE_OK';
export const getFareBoutiqueSuccess = (response) => {
    return {
        type: GET_FARE_FAREBOUTIQUE_OK,
        response: response
    };
};

export const GET_FARE_FAREBOUTIQUE_ERR = 'GET_FARE_FAREBOUTIQUE_ERR';
export const getFareBoutiqueError = (response) => {
    return {
        type: GET_FARE_FAREBOUTIQUE_ERR,
        response: response
    };
};

//riya fare quote 
export const getFareBoutiqueFareQuote = (data,errorCb,callback,index) => {
    return (dispatch, getState) => {
        if(index === 1){
           
            dispatch(getRoundFareBoutiqueStart());
        }
        else{
         
            dispatch(getFareBoutiqueStart());
        }
        return getFareBoutiqueFareQuoteApi(data)
            .then((response) => {
             
                if(response.status === 'ok'){
                    if(index === 1){
                        
                        dispatch(getRoundFareBoutiqueSuccess(response));
                    }
                    else{
                        
                        dispatch(getFareBoutiqueSuccess(response));
                    }
                    callback && callback(response);
                } else{
                  
                    // dispatch(getFareError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//FareBoutique booking
export const FAREBOUTIQUE_TICKET_BOOKING_START = 'FAREBOUTIQUE_TICKET_BOOKING_START';
export const fareBoutiqueTicketBookingStart = () => {
    return {
        type: FAREBOUTIQUE_TICKET_BOOKING_START
    };
};

export const FAREBOUTIQUE_TICKET_BOOKING_OK = 'FAREBOUTIQUE_TICKET_BOOKING_OK';
export const fareBoutiqueTicketBookingSuccess = (response) => {
    return {
        type: FAREBOUTIQUE_TICKET_BOOKING_OK,
        response: response
    };
};

export const FAREBOUTIQUE_TICKET_BOOKING_ERR = 'FAREBOUTIQUE_TICKET_BOOKING_ERR';
export const fareBoutiqueTicketBookingErr = (response) => {
    return {
        type: FAREBOUTIQUE_TICKET_BOOKING_ERR,
        response: response
    };
};

export const bookFareBoutiqueTicket = (data,successCb,errorCb) => {

    return (dispatch, getState) => {
        dispatch(fareBoutiqueTicketBookingStart());
        return fareBoutiqueTicketBookingApi(data)
            .then((response) => {
               
                if(response.status === 'ok'){
                  
                    dispatch(fareBoutiqueTicketBookingSuccess(response));
                    successCb && successCb(response);
                } else{
                  
                    dispatch(fareBoutiqueTicketBookingErr(response));
                    errorCb && errorCb(response);
                }
            });
    }
};


export const GET_FAREBOUTIQUE_BOOK_DETAILS_START = "GET_FAREBOUTIQUE_BOOK_DETAILS_START";
const getFareBoutiqueBookDetailsStart=()=>{
    return {
        type: GET_FAREBOUTIQUE_BOOK_DETAILS_START
        }
}

export const GET_FAREBOUTIQUE_BOOK_DETAILS_OK = "GET_FAREBOUTIQUE_BOOK_DETAILS_OK";
export const getFareBoutiqueBookDetailsOk=(response)=>{
    return {
        type: GET_FAREBOUTIQUE_BOOK_DETAILS_OK,
        response : response
        }
}
export const GET_FAREBOUTIQUE_BOOK_DETAILS_ERR = "GET_FAREBOUTIQUE_BOOK_DETAILS_ERR";
export const getFareBoutiqueBookDetailsErr=(response)=>{
    return {
        type: GET_FAREBOUTIQUE_BOOK_DETAILS_ERR,
        response : response
        }
}

export const fareBoutiqueBookDetails=(data,callback,errorCb)=>{
    return (dispatch,getState)=>
    {
        dispatch(getFareBoutiqueBookDetailsStart())
       return fareBoutiqueBookDetailsApi(data)
       .then((response)=>{
        if(response.status === 'ok'){
            dispatch(getFareBoutiqueBookDetailsOk(response))
            callback && callback(response)
        }
        else{
            dispatch(getFareBoutiqueBookDetailsErr(response))
            errorCb && errorCb(response)
        }
       })
    }
}
