import React from "react";
import FlightSearchHeaderComponent from "../../components/flights/FlightSearchHeaderComponent";
import FlightBookTicketComponent from "../../components/flights/booking/FlightBookTicketComponent";
import { connect } from "react-redux";
import {
  flightSearchSaveQuery,
  getAllAirlines,
} from "../../actions/flight/flightSearchAction";
import {
  kafilaPnrRetrieve,
  kafilaPnrCreate,
  getKafilaFareQuote,
  getFareQuote,
  getTripJackFareQuote,
  getSsrDetail,
  getPaxTitle,
  holdBooking,
  bookTicket,
  getCompanyDetail,
  getCellCountryCode,
  setFareQuoteValue,
  setSSRValue,
  saveFlightOutboundPaxInfo,
  getTripJackSeatInfo,
  bookTripJacKTicket,
  tripJackBookDetails,
  setTripJackSeatInfo,
  getAirIQFareQuote,
  bookAirIQTicket,
  airIQBookDetails,
  validateTripJackTicket,
  tripJackBookConfirm,
  getGoflyFareQuote,
  bookGoflyTicket,
  goflyBookDetails,
  eflyBookDetails,
  bookeflyTicket,
  geteflyFareQuote,
  getKafilaSsrDetail,
  kafilaSeatState,
  getSatkarFareQuote,
  bookSatkarTicket,
  satkarBookDetails,
  getRiyaFareQuote,
  getRiyaSeatInfo,
  getKafilaSsrStart,
  bookriyaTicket,
  riyaBookDetails,
  riyaHoldBooking,
  riyaBookConfirm,
  getFareBoutiqueFareQuote,
  bookFareBoutiqueTicket,
  fareBoutiqueBookDetails,
} from "../../actions/flight/flightBookTicketAction";
import {
  getlocation,
  getCountryDetails,
} from "../../actions/shared/registerAction";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import BookingConfirmComponent from "../../components/flights/booking/BookingConfirmComponent";
import { myAccountAction } from "../../actions/agentAction";
import {
  getFlightFareRuleDetails,
  getKafilaFlightFareRuleDetails,
  getTripJackFlightFareRuleDetails,
  setTripJackFlightFareRuleDetails,
  getAirIQFlightFareRuleDetails,
  setAirIQFlightFareRuleDetails,
  setKafilaFlightFareRuleDetails,
  setFlightFareRuleDetails,
  getGoflyFlightFareRuleDetails,
  geteflyFlightFareRuleDetails,
  getSatkarFlightFareRuleDetails,
  getRiyaFlightFareRuleDetails,
  setRiyaFlightFareRuleDetails,
  getFareBoutiqueFlightFareRuleDetails,
} from "../../actions/flight/flightSearchDetailsAction";
import {
  addPassenger,
  getCustomerList,
  addCompanyGSTDetails,
  isBookingPending
} from "../../actions/shared/sharedAction";
import {
  getFare,
  getPassengerDetails,
  getSegments,
} from "../../shared/flightHelper";
// import DropBookingComponent from "../../components/flights/booking/DropBookingComponent";
import {
  getValidationDate,
} from "../../shared/commonHelper";

class FlightBookTicketContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      addPassengerSuccess: null,
      passengers: null,
      showSeatPopup: false,
      booking_id: null,
      outboundPubFare: 0,
      formikValues: null,
      pnrRetriveTimeOut: 0,
      MergeInput:"",
      requiredField: false,
      popupMessages: [],
      mealSelected: false,
      baggageSelected: false, 
      seatSelected: false,
    };
    this.handleConfirmationPopupModel = this.handleConfirmationPopupModel.bind(this);
  
  }

  initialState = () => {
    this.setState({
      addPassengerSuccess: null,
    });
  };

  //Get fare quote data
  getFareQuoteSegments = (searchType, index) => {
    
    let segment = [];
   
    if (
      (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source &&
        this.props.location.state.data.api_source === "kafila") ||
      ((searchType === "round-trip" ||
        searchType === "round-trip-complete" ||
        searchType === "lcc-special-return") &&
        this.props.location.state.data &&
        this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source === "kafila")
    ) { 
      if (searchType === "round-trip" || searchType === "round-trip-complete") {
        segment = this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index].segments;
      } else {
        segment = this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.segments;
      }
    } else if (
      (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source &&
        this.props.location.state.data.api_source === "tripjack") ||
      ((searchType === "round-trip" ||
        searchType === "round-trip-complete" ||
        searchType === "lcc-special-return") &&
        this.props.location.state.data &&
        this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source === "tripjack")
    ) {
      if (searchType === "round-trip" || searchType === "round-trip-complete") {
        segment = this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index].segments;
      } else {
        segment = this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.segments;
      }
    } else {
      if (
        searchType === "round-trip" ||
        searchType === "round-trip-complete" ||
        searchType === "lcc-special-return"
      ) {
        index = !index ? this.props.location.state.index : index;
        let item = this.props.searchQuery.segments[index];
        segment.push({
          origin: item.origin,
          destination: item.destination,
          flight_cabin_class: item.flight_cabin_class,
          preferred_departure_time: item.preferred_departure_time,
          preferred_arrival_time: item.preferred_arrival_time,
        });
      } else {
        if (this.props.searchQuery) {
          segment = this.props.searchQuery.segments.map((item, index) => {
            return {
              origin: item.origin,
              destination: item.destination,
              flight_cabin_class: item.flight_cabin_class,
              preferred_departure_time: item.preferred_departure_time,
              preferred_arrival_time: item.preferred_arrival_time,
            };
          });
        }
      }
    }
    return segment;
  };

  scrollToFirstError = (value) => {
    let str = Object.values(value) && Object.values(value).length && Object.values(value)[0]
    let res = str && str.split(" ") && str.split(" ").join("").replace(/[^A-Za-z\s]/g, '');
    const firstErrorElement = Object.values(value) && Object.values(value).length && document.querySelector(`.${res}`)
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  fareQuoteData = (index) => {
    
    let data = {};
    if (
      this.props.location.state.searchType === "one-way" ||
      this.props.location.state.searchType === "multi-city" ||
      this.props.location.state.searchType === "special-return"
    ) {
      let segements=this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
      this.props.location.state.data.segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline && this.props.location.state.data.segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      let segements=this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
      this.props.location.state.data[index].segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        is_domestic: this.props.searchQuery.is_domestic,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && this.props.location.state.data[index].segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (this.props.location.state.searchType === "lcc-special-return") {
      index = !index ? this.props.location.state.index : index;
      let segements=this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
      this.props.location.state.data[index].segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index:
          this.props.location.state.data[0].result_index +
          "," +
          this.props.location.state.data[1].result_index,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
        //"segments": this.props.location.state.data[index].segments
      };
    } else if (
      this.props.location.state.searchType === "advance-search-round"
    ) {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index:
          this.props.location.state.priceRBD.results[0][0].result_index,
          fare_type: this.props.location.state.data && this.props.location.state.data[0][0] && this.props.location.state.data[0][0].supplier_airline_remark,
        is_domestic: this.props.searchQuery.is_domestic,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };
  tripJackFareQuote = (index) => {
    let data = {};
    if (
      this.props.location.state.searchType === "one-way" ||
      this.props.location.state.searchType === "multi-city" ||
      this.props.location.state.searchType === "special-return"
    ) {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        uid: this.props.location.state.data.uid,
        journey_type: this.props.searchQuery.journey_type,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        uid: this.props.location.state.data[index].uid,
        journey_type: this.props.searchQuery.journey_type,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };

  airIQFareQuoteData = (index) => {
    let data = {};
    if (this.props.location.state.searchType === "one-way") {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        origin_code:this.props.searchQuery.originAirportCode,
        destination_code:this.props.searchQuery.destinationAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            origin_code:
            index === 1
              ? this.props.searchQuery.destinationAirportCode
              : this.props.searchQuery.originAirportCode,
            destination_code:index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        ticket_id: this.props.location.state.data[index].ticket_id,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };

  goflyFareQuoteData = (index) => {
    let data = {};

    if (this.props.location.state.searchType === "one-way") {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data[index].ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].fare && this.props.location.state.data[this.props.location.state.index].fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }else if (
      this.props.location.state.searchType === "special-return"
    ) 
    {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };

  eflyFareQuoteData = (index) => {
    let data = {};

    if (this.props.location.state.searchType === "one-way") {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data[index].ticket_id,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };
  bookingError = (response) => {
    this.props.history.push({
      pathname: "/booking-error",
      state: {
        page: "booking",
        response: response.message
          ? response.message
          : response.errorMessage.data &&
            response.errorMessage.data.content &&
            response.errorMessage.data.content.tbo_response &&
            response.errorMessage.data.content.tbo_response.flight_itinerary
          ? response.errorMessage.data.content.tbo_response.flight_itinerary
          : {},
        searchQuery: this.props.searchQuery,
        productResponse: this.props.productResponse,
        agentProductAccess: this.props.agentProductAccess,
      },
    });
  };

  callKafilaFareQuote = (searchType, index, isOutboundVendor) => {
   
    let self = this;
    let input = {};
    if (searchType === "round-trip" || searchType === "round-trip-complete") {
     
      let segements=this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
      this.props.location.state.data[index].segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      let stateData = this.props.location.state.data[index];
      let searchQuerysegment = this.props.searchQuery.segments[index];
      let param = "";
      if (index === 0) {
        param = this.props.location.state.param;
      } else {
        param = this.props.location.state.param;
      }
      input = {
        // "flight": {
        //     "UID": stateData.UID,
        //     "ID":  stateData.ID,
        //     "TID":  stateData.TID
        // },
        Param: param,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: [{ searchQuerysegment }], //stateData.segments
        SelectedFlights: stateData.SelectedFlights,
        gst_number:this.state.formikValues && this.state.formikValues.gst_checked==="show"? this.state.formikValues.gst_number.trim() :"",
        gst_company_address:this.state.formikValues && this.state.formikValues.gst_checked==="show"? this.state.formikValues.gst_company_address.trim():"",
        gst_company_email:this.state.formikValues && this.state.formikValues.gst_checked==="show"? this.state.formikValues.gst_company_email.trim():"",
        gst_company_name:this.state.formikValues && this.state.formikValues.gst_checked==="show"? this.state.formikValues.gst_company_name.trim():"",
        gst_company_contact_number:this.state.formikValues && this.state.formikValues.gst_checked==="show"? this.state.formikValues.gst_company_number:"",
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (this.props.location.state.searchType === "lcc-special-return") {
    
      let segements=this.props.location.state.data && this.props.location.state.data[0].segments && this.props.location.state.data[0].segments.length && 
      this.props.location.state.data[0].segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      let result_type = "";
      let searchQuerysegment = this.props.searchQuery.segments[index];
      if (index === 0) {
        result_type = "OW";
      } else {
        result_type = "RT";
      }
      input = {
        // "flightow": {
        //     "UID": this.props.location.state.data[0].UID,
        //     "ID": this.props.location.state.data[0].ID,
        //     "TID": this.props.location.state.data[0].TID
        // },
        // "flightrt": {
        //     "UID": this.props.location.state.data[1].UID,
        //     "ID": this.props.location.state.data[1].ID,
        //     "TID": this.props.location.state.data[1].TID
        // },
        Param: this.props.location.state.param,
        result_type: result_type,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data[0].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[0].segments && this.props.location.state.data[0].segments.length && 
        this.props.location.state.data[0].segments[0].length && this.props.location.state.data[0].segments[0][0].airline && 
        this.props.location.state.data[0].segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: [{ searchQuerysegment }],
        SelectedFlights:
          this.props.location.state &&
          this.props.location.state.data[0] &&
          this.props.location.state.data[0].SelectedFlights, //this.props.location.state.data[index].segments
        gst_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_number.trim() : "",
        gst_company_address: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_address.trim() : "",
        gst_company_email: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_email.trim() : "",
        gst_company_name: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_name.trim() : "",
        gst_company_contact_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_number : "",
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (this.props.location.state.searchType === "special-return") {
     
      let segements=this.props.location.state.data && this.props.location.state.data[0].segments && this.props.location.state.data[0].segments.length && 
      this.props.location.state.data[0].segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      let result_type = "";
      if (index === 0) {
        result_type = "OW";
      } else {
        result_type = "RT";
      }
      input = {
        // "flightow": {
        //     "UID": this.props.location.state.data.segments[0][0].UID,
        //     "ID": String(this.props.location.state.data.segments[0][0].ID),
        //     "TID": this.props.location.state.data.segments[0][0].TID
        // },
        // "flightrt": {
        //     "UID": this.props.location.state.data.segments[1][0].UID,
        //     "ID": String(this.props.location.state.data.segments[1][0].ID),
        //     "TID": this.props.location.state.data.segments[1][0].TID
        // },
        Param: this.props.location.state.param,
        result_type: result_type,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: this.props.searchQuery.segments, //this.props.location.state.data.segments
        SelectedFlights:
          this.props.location.state &&
          this.props.location.state.data &&
          this.props.location.state.data.SelectedFlights,
        gst_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_number.trim() : "",
        gst_company_address: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_address.trim() : "",
        gst_company_email: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_email.trim() : "",
        gst_company_name: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_name.trim() : "",
        gst_company_contact_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_number : "",
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else {

      let segements=this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
      this.props.location.state.data.segments;
      let segment = [{
        "origin": segements.length && segements[0] && segements[0].length && segements[0][0] && segements[0][0].origin.airport && segements[0][0].origin.airport.airport_code,
        "destination":   segements.length && segements[0] && segements[0].length && segements[0][segements[0].length-1] && segements[0][segements[0].length-1].destination.airport && segements[0][segements[0].length-1].destination.airport.airport_code,
    }]
      input = {
        // "flight": {
        //     "UID": this.props.location.state.data.UID,
        //     "ID":  this.props.location.state.data.ID,
        //     "TID":  this.props.location.state.data.TID
        // },
        Param: this.props.location.state.param,
        is_domestic: this.props.searchQuery.is_domestic,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        origin_code:segment[0].origin,
        destination_code:segment[0].destination,
        segments: this.props.searchQuery.segments, //this.props.location.state.data.segments
        SelectedFlights:
          this.props.location.state &&
          this.props.location.state.data &&
          this.props.location.state.data.SelectedFlights,
        gst_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_number.trim() : "",
        gst_company_address: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_address.trim() : "",
        gst_company_email: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_email.trim() : "",
        gst_company_name: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_name.trim() : "",
        gst_company_contact_number: this.state.formikValues && this.state.formikValues.gst_checked === "show" ? this.state.formikValues.gst_company_number : "",
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }

    if (isOutboundVendor) {
      index = 0;
    }
    this.props.getKafilaFareQuote(
      input,
      this.bookingError,
      function (response) {
        if (
          (response &&
            response.results &&
            response.results.segments &&
            response.results.segments.length > 0 &&
            response.results.segments[0] &&
            response.results.segments[0].length > 0 &&
            response.results.segments[0][0] &&
            response.results.segments[0][0].airline &&
            response.results.segments[0][0].airline.airline_code === "UK") ||
          (response &&
            response.results &&
            response.results.segments &&
            response.results.segments.length > 0 &&
            response.results.segments[0] &&
            response.results.segments[0].length > 0 &&
            response.results.segments[0][0] &&
            response.results.segments[0][0].airline &&
            response.results.segments[0][0].airline.airline_code === "AI")
        ) {
          self.props.getKafilaSsrStart(); 
          return;
        } else {
          self.props.getKafilaSsrDetail(response.api_respone, index);
        }
      },
      index
    );
  };

  satkarFareQuoteData = (index) => {
    let data = {};

    if (this.props.location.state.searchType === "one-way") {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data[index].ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].fare && this.props.location.state.data[this.props.location.state.index].fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }else if (
      this.props.location.state.searchType === "special-return"
    ) 
    {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
    return data;
  };
  fareBoutiqueFareQuoteData = (index) => {
    let data = {};

    if (this.props.location.state.searchType === "one-way") {
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    } else if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length && 
        this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline && 
        this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data[index].ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].fare && this.props.location.state.data[this.props.location.state.index].fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }else if (
      this.props.location.state.searchType === "special-return"
    ) 
    {
      index = !index ? this.props.location.state.index : index;
      data = {
        trace_id: this.props.location.state.trace_id,
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code:this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length && 
        this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
        this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        originAirportCode:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
            destination_code:
          index === 1
            ? this.props.searchQuery.originAirportCode
            : this.props.searchQuery.destinationAirportCode,
        origin_code:
          index === 1
            ? this.props.searchQuery.destinationAirportCode
            : this.props.searchQuery.originAirportCode,
        ticket_id: this.props.location.state.data.ticket_id,
        amount:this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(
          this.props.location.state.searchType,
          index
        ),
        category : this.props.searchQuery.is_domestic === "true" ? 1: 2,
      };
    }
   
    return data;
  };

   formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');;
  }

  getFlightDetails = (searchType, index) => {

    let segment = [];
    if (searchType === "round-trip" || searchType === "round-trip-complete" || searchType === "lcc-special-return") {
      index = !index ? this.props.location.state.index : index;
      let item1 = this.props.location.state.data && this.props.location.state.data && this.props.location.state.data[index];
      segment = item1 && item1.segments && item1.segments[0].map((item, index) => {
        const DepartureDateTime = this.formatDate(item.origin.dep_time);
        const ArrivalDateTime = this.formatDate(item.destination.arr_time);
        return {
          FlightID: item && item.flightId,
          FlightNumber: item.airline.airline_code + ' ' + item.airline.flight_number,
          Origin: item.origin.airport.airport_code,
          Destination: item.destination.airport.airport_code,
          DepartureDateTime: DepartureDateTime,
          ArrivalDateTime: ArrivalDateTime
        };
      })
    } else {
      if (this.props.searchQuery) {
        segment = this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments[0].map((item, index) => {
          const DepartureDateTime = this.formatDate(item.origin.dep_time);
          const ArrivalDateTime = this.formatDate(item.destination.arr_time);
          return {
            FlightID: item && item.flightId,
            FlightNumber: item.airline.airline_code + ' ' + item.airline.flight_number,
            Origin: item.origin.airport.airport_code,
            Destination: item.destination.airport.airport_code,
            DepartureDateTime: DepartureDateTime,
            ArrivalDateTime: ArrivalDateTime
          };
        });
      }
    }
    return segment;
  };

  getRiyaFlightDetails = (segments) => {
    let allSegments = [];
  
    segments.forEach(segmentList => {
      segmentList.forEach(item => {
       
        const DepartureDateTime = this.formatDate(item.origin.dep_time);
        const ArrivalDateTime = this.formatDate(item.destination.arr_time);
  
        allSegments.push({
          FlightID: item.flightId,
          FlightNumber: item.airline.airline_code + ' ' + item.airline.flight_number,
          Origin: item.origin.airport.airport_code,
          Destination: item.destination.airport.airport_code,
          DepartureDateTime: DepartureDateTime,
          ArrivalDateTime: ArrivalDateTime
        });
      });
    });
  
    return allSegments;
  };


  riyaFareQuoteData = (index) => {
    let data = {};
    if (this.props.location.state.searchType === "one-way") {
      data = {
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code: this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length &&
          this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
          this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        journey_type: this.props.searchQuery.journey_type,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: this.props.searchQuery.destinationAirportCode,
        originAirportCode: this.props.searchQuery.originAirportCode,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        amount: this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(this.props.location.state.searchType),
        category: this.props.searchQuery.is_domestic === "true" ? 1 : 2,
        trackId: this.props.location.state.data.trackId,
        //flightId: this.props.location.state.data.flightId,
        ItineraryInfo: [
          {
            FlightDetails: this.getFlightDetails(this.props.location.state.searchType),
            BaseAmount: this.props.location.state && this.props.location.state.data.fare.finalBaseAmount,
            GrossAmount: this.props.location.state && this.props.location.state.data.fare.finalgrossAmount
          }
        ]
      };
    } else if (this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") {
      index = !index ? this.props.location.state.index : index;
      data = {
        result_index: this.props.location.state.data[index].result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].supplier_airline_remark,
        airline_code: this.props.location.state.data && this.props.location.state.data[index].segments && this.props.location.state.data[index].segments.length &&
          this.props.location.state.data[index].segments[0].length && this.props.location.state.data[index].segments[0][0].airline &&
          this.props.location.state.data[index].segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        journey_type: this.props.searchQuery.journey_type,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: index === 1 ? this.props.searchQuery.originAirportCode : this.props.searchQuery.destinationAirportCode,
        originAirportCode: index === 1 ? this.props.searchQuery.destinationAirportCode : this.props.searchQuery.originAirportCode, destination_code: index === 1 ? this.props.searchQuery.originAirportCode : this.props.searchQuery.destinationAirportCode,
        origin_code: index === 1 ? this.props.searchQuery.destinationAirportCode : this.props.searchQuery.originAirportCode,
        amount: this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].fare && this.props.location.state.data[this.props.location.state.index].fare.offered_fare_old,
        segments: this.getFareQuoteSegments(this.props.location.state.searchType, index),
        category: this.props.searchQuery.is_domestic === "true" ? 1 : 2,
        trackId: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].trackId,
        //flightId: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].flightId,
        ItineraryInfo: [
          {
            FlightDetails: this.getFlightDetails(this.props.location.state.searchType, index),
            BaseAmount: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].fare.finalBaseAmount,
            GrossAmount: this.props.location.state.data && this.props.location.state.data[index] && this.props.location.state.data[index].fare.finalgrossAmount
          }
        ]
      };
    } else if (this.props.location.state.searchType === "special-return") {
      index = !index ? this.props.location.state.index : index;
      
      data = {
        result_index: this.props.location.state.data.result_index,
        fare_type: this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
        airline_code: this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length &&
          this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
          this.props.location.state.data.segments[0][0].airline.airline_code,
        is_domestic: this.props.searchQuery.is_domestic,
        journey_type: this.props.searchQuery.journey_type,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destinationAirportCode: index === 1 ? this.props.searchQuery.originAirportCode : this.props.searchQuery.destinationAirportCode,
        originAirportCode: index === 1 ? this.props.searchQuery.destinationAirportCode : this.props.searchQuery.originAirportCode, destination_code: index === 1 ? this.props.searchQuery.originAirportCode : this.props.searchQuery.destinationAirportCode,
        origin_code: index === 1 ? this.props.searchQuery.destinationAirportCode : this.props.searchQuery.originAirportCode,
        amount: this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.offered_fare_old,
        segments: this.getFareQuoteSegments(this.props.location.state.searchType, index),
        category: this.props.searchQuery.is_domestic === "true" ? 1 : 2,
        trackId: this.props.location.state.data.trackId,
        ItineraryInfo: [
          {
            FlightDetails: this.getRiyaFlightDetails(this.props.location.state.data.segments),
            BaseAmount: this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.finalBaseAmount,
            GrossAmount: this.props.location.state && this.props.location.state.data && this.props.location.state.data.fare && this.props.location.state.data.fare.finalgrossAmount,
          }
        ]
      };
    }
    return data;
  };

  //    filterSeatsByCode(seatData, seatCode) {
  //     const filteredSeats = [];

  //     for (let segment of seatData) {
  //         const segmentSeats = [];

  //         for (let rowSeats of segment.row_seats) {
  //             const filteredRowSeats = rowSeats.filter(seat => seatCode.includes(seat.code));
  //             if (filteredRowSeats.length > 0) {
  //                 segmentSeats.push(filteredRowSeats);
  //             }
  //         }

  //         if (segmentSeats.length > 0) {
  //             filteredSeats.push(segmentSeats);
  //         }
  //     }
  //     return filteredSeats;
  // }

  componentDidMount() {  
    this.props.getCompanyDetail();
    this.props.getCellCountryCode();
    // for kafila gst farequote=============================================
    if ((!this.props.type &&
      this.props.location.state.searchType === "round-trip-complete") ||
    this.props.location.state.isOutBoundVendor) {
      if(this.props.location.state.searchType === "round-trip-complete" &&
      this.props.location.state.data[1].is_vendor !== 1 &&
      this.props.location.state.data[1].api_source === "kafila"){ 
       
        let data = this.fareQuoteData(1);
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(this.props.location.state.searchType, 1);
      } else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[1].api_source === "riya_travel" && this.props.location.state.data[1].is_vendor !== 1 )){
  
         let data = this.riyaFareQuoteData(1);
        let riyaSegment = [{
          "origin": data && data.segments && data.segments[0].origin,
          "destination": data && data.segments && data.segments[0].destination
        }]
        let FareRuleDetailsCredential = {
          "result_index": this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].result_index,
          "segments": riyaSegment,
          "fare_type": this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].supplier_airline_remark,
          "airline_code": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index].segments && 
            this.props.location.state.data[this.props.location.state.index].segments[0] && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0] && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0].airline && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0].airline.airline_code,
          "origin_code": riyaSegment[0].origin,
          "destination_code": riyaSegment[0].destination,
          "category": this.props.searchQuery.is_domestic === "true" ? 1 : 2,
          "trackId": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index] &&
            this.props.location.state.data[this.props.location.state.index].trackId,
          "flightId": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index] &&
            this.props.location.state.data[this.props.location.state.index].flightId,
        }
        this.props.getRiyaFlightFareRuleDetails(FareRuleDetailsCredential);
        this.props.getRiyaFareQuote(data, this.bookingError, 
          function (response) { 
            // let seatInfo = {
            //   bookingId: response.bookingId,
            // };
            //self.props.getRiyaSeatInfo(seatInfo);
          }
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "tbo" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let self = this;
        let data = this.fareQuoteData(1);

        // First API call getFareQuote
        this.props.getFareQuote(data,this.bookingError,function (response) {
            let ssrRequest = {
              trace_id: response.trace_id,
              result_index: response.results.result_index,
            };

            self.props.getFlightFareRuleDetails(data, 1);
            self.props.getSsrDetails(ssrRequest, 1);
            
          },
          1
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "tripjack" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let self = this;
        let data = this.tripJackFareQuote(1);
        let segment_length = data.segments[0].length;
        let origin = data && data.segments && data.segments[0] && data.segments[0][segment_length -1] && 
          data.segments[0][segment_length - 1].origin.airport.airport_code;
        let destination = data && data.segments && data.segments[0] && data.segments[0][segment_length -1] &&
          data.segments[0][segment_length - 1].destination.airport.airport_code;
        let FareRuleDetailsCredential = {
          uid: data.uid,
          flowType: "SEARCH",
          origin: origin,
          destination: destination,
          origin_code: origin,
          destination_code: destination,
          fare_type: data && data.fare_type,
          airline_code: data && data.airline_code,
          category: data && data.category,
        };
        this.props.getTripJackFlightFareRuleDetails(
          FareRuleDetailsCredential,
          1
        );

        this.props.getTripJackFareQuote(
          data,
          this.bookingError,
          function (response) {
            let seatInfo = {
              bookingId: response.bookingId,
            };
            self.props.getTripJackSeatInfo(seatInfo, 1);
          },
          1
        );
      }
      else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "gofly" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let data = this.goflyFareQuoteData(1);
        this.props.getGoflyFlightFareRuleDetails(data);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "satkar" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let data = this.satkarFareQuoteData(1);
        this.props.getSatkarFlightFareRuleDetails(data);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "omairiq" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let data = this.airIQFareQuoteData(1);
        this.props.getAirIQFlightFareRuleDetails(data);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "efly" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let data = this.eflyFareQuoteData(1);
        this.props.geteflyFlightFareRuleDetails(data);
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      }else if((this.props.location.state.searchType === "round-trip-complete" && this.props.location.state.data[this.props.location.state.index].api_source === "fbq" && this.props.location.state.data[this.props.location.state.index].is_vendor !== 1 )){
        let data = this.fareBoutiqueFareQuoteData(1);
        this.props.getFareBoutiqueFlightFareRuleDetails(data);
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      }
      
    }
    // for kafila gst farequote ends=============================================
  
    if (
      (!this.props.type &&
        this.props.location.state.searchType !== "round-trip-complete") ||
      this.props.location.state.isOutBoundVendor
    ) {
      
      this.props.getAllAirlines(null);
      //Get fare quote data

      this.props.getPaxTitle();
      this.props.getlocation();
      this.props.getCountryDetails();
      let self = this;
      if (
        this.props.location.state.data.api_source === "kafila" &&
        this.props.location.state.searchType !== "round-trip" &&
        this.props.location.state.searchType !== "lcc-special-return" &&
        this.props.location.state.searchType !== "special-return"
      ) {
       
        let data = this.fareQuoteData();
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(this.props.location.state.searchType);
      } else if (
       
        (this.props.location.state.searchType === "round-trip" ||
          this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.data[0].api_source === "kafila"
      ) {
      
        let data = this.fareQuoteData(0);
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(this.props.location.state.searchType, 0);
      } else if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "kafila"
      ) {
        
        let data = this.fareQuoteData(0);
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(this.props.location.state.searchType, 0);
      } else if (
        this.props.location.state.isOutBoundVendor &&
        this.props.location.state.searchType === "round-trip-complete" &&
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "kafila"
      ) {
        
        let data = this.fareQuoteData(this.props.location.state.index);
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(
          this.props.location.state.searchType,
          this.props.location.state.index,
          this.props.location.state.isOutBoundVendor
        );
      } else if(this.props.location.state.data.api_source === "riya_travel" &&
      this.props.location.state.searchType !== "round-trip" &&
      this.props.location.state.searchType !== "lcc-special-return" &&
      this.props.location.state.searchType !== "special-return")
      {
        //for one way
       
        let data = this.riyaFareQuoteData();
      
        let riyaSegment = [{
          "origin": data && data.segments && data.segments[0].origin,
          "destination": data && data.segments && data.segments[0].destination
        }]
        let FareRuleDetailsCredential = {
          "result_index": this.props.location.state.data.result_index,
          "segments": riyaSegment,
          "fare_type": this.props.location.state.data && this.props.location.state.data.supplier_airline_remark,
          "airline_code": this.props.location.state.data && this.props.location.state.data.segments && this.props.location.state.data.segments.length &&
            this.props.location.state.data.segments[0].length && this.props.location.state.data.segments[0][0].airline &&
            this.props.location.state.data.segments[0][0].airline.airline_code,
          "origin_code": riyaSegment[0].origin,
          "destination_code": riyaSegment[0].destination,
          "category": this.props.searchQuery.is_domestic === "true" ? 1 : 2,
          "trackId": this.props.location.state.data.trackId,
          "flightId": this.props.location.state.data.flightId,
        }
    
        this.props.getRiyaFlightFareRuleDetails(FareRuleDetailsCredential);
        this.props.getRiyaFareQuote(data, this.bookingError,
           function (response) { 
        
           }
          );
      }
    //   else if((this.props.location.state.searchType === "round-trip" ||
    //   this.props.location.state.searchType === "lcc-special-return") &&
    // this.props.location.state.data[0].api_source === "riya_travel"){
    //   //for round trip
   
    //   }
      else if((this.props.location.state.searchType === "special-return" && this.props.location.state.data.api_source === "riya_travel")
        ||(this.props.location.state.searchType === "round-trip" && this.props.location.state.data[0].api_source === "riya_travel")
    ){
      
        //for special return round trip
      
        let data = this.riyaFareQuoteData(0);
        let riyaSegment = [{
          "origin": data && data.segments && data.segments[0].origin,
          "destination": data && data.segments && data.segments[0].destination
        }]
        let FareRuleDetailsCredential = {
          "result_index": this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].result_index,
          "segments": riyaSegment,
          "fare_type": this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].supplier_airline_remark,
          "airline_code": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index].segments && 
            this.props.location.state.data[this.props.location.state.index].segments[0] && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0] && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0].airline && 
            this.props.location.state.data[this.props.location.state.index].segments[0][0].airline.airline_code,
          "origin_code": riyaSegment[0].origin,
          "destination_code": riyaSegment[0].destination,
          "category": this.props.searchQuery.is_domestic === "true" ? 1 : 2,
          "trackId": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index] &&
            this.props.location.state.data[this.props.location.state.index].trackId,
          "flightId": this.props.location && 
            this.props.location.state && 
            this.props.location.state.data && 
            this.props.location.state.data[this.props.location.state.index] && 
            this.props.location.state.data[this.props.location.state.index] &&
            this.props.location.state.data[this.props.location.state.index].flightId,
        }
        this.props.getRiyaFlightFareRuleDetails(FareRuleDetailsCredential);
        this.props.getRiyaFareQuote(data, this.bookingError, 
          function (response) { 
            // let seatInfo = {
            //   bookingId: response.bookingId,
            // };
            //self.props.getRiyaSeatInfo(seatInfo);
          }
        );
      }
     
      // else if( this.props.location.state.isOutBoundVendor &&
      //   this.props.location.state.searchType === "round-trip-complete" &&
      //   this.props.location.state.data[this.props.location.state.index]
      //     .api_source === "riya_travel"){
      //       //for round trip complete
      // }
      else if (
        (this.props.location.state.searchType === "round-trip" &&
          this.props.location.state.data[0].api_source === "tripjack") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "tripjack")
      ) {
        let data = this.tripJackFareQuote(0);
        let origin = data && data.segments[0][0] && data.segments[0][0].origin && data.segments[0][0].origin.airport && data.segments[0][0].origin.airport.airport_code;
        let destination = data && data.segments[0][0] && data.segments[0][0].destination && data.segments[0][0].destination.airport &&  data.segments[0][0].destination.airport.airport_code;
        let FareRuleDetailsCredential = {
          uid: data.uid,
          flowType: "SEARCH",
          origin: origin,
          destination: destination,
          origin_code: origin,
          destination_code: destination,
          fare_type: data && data.fare_type,
          airline_code: data && data.airline_code,
          category: data && data.category,
        };
        this.props.getTripJackFlightFareRuleDetails(FareRuleDetailsCredential);
        this.props.getTripJackFareQuote(
          data,
          this.bookingError,
          function (response) {
            let seatInfo = {
              bookingId: response.bookingId,
            };
            self.props.getTripJackSeatInfo(seatInfo);
          }
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "omairiq"
      ) {
      
        let data = this.airIQFareQuoteData(0);
        this.props.getAirIQFlightFareRuleDetails(data);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "gofly") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "gofly")
      ) {
      
        let data = this.goflyFareQuoteData(0);
        this.props.getGoflyFlightFareRuleDetails(data);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "satkar") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "satkar")
      ) {
       
        let data = this.satkarFareQuoteData(0);
        this.props.getSatkarFlightFareRuleDetails(data);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } 
      else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "fbq") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "fbq")
      ) {
       
        let data = this.fareBoutiqueFareQuoteData(0);
        this.props.getFareBoutiqueFlightFareRuleDetails(data);
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } 
      else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "efly"
      ) {
        
        let data = this.eflyFareQuoteData(0);
        this.props.geteflyFlightFareRuleDetails(data);
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.data.api_source === "tripjack" &&
        this.props.location.state.searchType !== "round-trip" &&
        this.props.location.state.searchType !== "special-return"
      ) {
        let data = this.tripJackFareQuote();
        let origin = data.segments[0][0].origin.airport.airport_code;
        let destination =
          data.segments[0][data.segments[0].length - 1].destination.airport
            .airport_code;
        let FareRuleDetailsCredential = {
          uid: data.uid,
          flowType: "SEARCH",
          origin: origin,
          destination: destination,
          origin_code: origin,
          destination_code: destination,
          fare_type: data && data.fare_type,
          airline_code: data && data.airline_code,
          category : data && data.category,
        };
        this.props.getTripJackFlightFareRuleDetails(FareRuleDetailsCredential);
        this.props.getTripJackFareQuote(
          data,
          this.bookingError,
          function (response) {
            let seatInfo = {
              bookingId: response.bookingId,
            };
            self.props.getTripJackSeatInfo(seatInfo);
          }
        );
      } else if (
        this.props.location.state.data.api_source === "omairiq" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
     
        let data = this.airIQFareQuoteData();
        this.props.getAirIQFlightFareRuleDetails(data);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.data.api_source === "gofly" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
        
        let data = this.goflyFareQuoteData();
        this.props.getGoflyFlightFareRuleDetails(data);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.data.api_source === "efly" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
       
        let data = this.eflyFareQuoteData();
        this.props.geteflyFlightFareRuleDetails(data);

        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.data.api_source === "satkar" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
       
        let data = this.satkarFareQuoteData();
        this.props.getSatkarFlightFareRuleDetails(data);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.data.api_source === "fbq" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
        
        let data = this.fareBoutiqueFareQuoteData();
       this.props.getFareBoutiqueFlightFareRuleDetails(data);
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } 
      else if( (this.props.location.state.searchType === "round-trip" || 
      this.props.location.state.searchType === "lcc-special-return") &&
    this.props.location.state.data[0].api_source === "tbo") {
       
        let data = this.fareQuoteData();
   
        // First API call getFareQuote
        this.props.getFareQuote(data, this.bookingError, function (response) {
            let ssrRequest = {
                trace_id: response.trace_id,
                result_index: response.results.result_index,
            };
    
            self.props.getFlightFareRuleDetails(data)
    
            self.props.getSsrDetails(ssrRequest);
        });
    }else if((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "multi-city") &&  this.props.location.state.data.api_source === "tbo" ){
    
      let data = this.fareQuoteData();
   
      // First API call getFareQuote
      this.props.getFareQuote(data, this.bookingError, function (response) {
          let ssrRequest = {
              trace_id: response.trace_id,
              result_index: response.results.result_index,
          };
  
          self.props.getFlightFareRuleDetails(data)
  
          self.props.getSsrDetails(ssrRequest);
      });
    }
    
    
  
      
      if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "tripjack"
      ) {
       
        let data = this.tripJackFareQuote(1);
        let segment_length = data.segments[0].length;
        let origin =
          data.segments[0][segment_length - 1].origin.airport.airport_code;
        let destination =
          data.segments[0][segment_length - 1].destination.airport.airport_code;
        let FareRuleDetailsCredential = {
          uid: data.uid,
          flowType: "SEARCH",
          origin: origin,
          destination: destination,
          origin_code: origin,
          destination_code: destination,
          fare_type:data && data.fare_type,
          airline_code: data && data.airline_code,
          category : data && data.category,
        };
        this.props.getTripJackFlightFareRuleDetails(
          FareRuleDetailsCredential,
          1
        );

        this.props.getTripJackFareQuote(
          data,
          this.bookingError,
          function (response) {
            let seatInfo = {
              bookingId: response.bookingId,
            };
            self.props.getTripJackSeatInfo(seatInfo, 1);
          },
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "omairiq"
      ) {
       
        let data = this.airIQFareQuoteData(1);
        this.props.getAirIQFlightFareRuleDetails(data, 1);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "gofly"
      ) {
       
        let data = this.goflyFareQuoteData(1);
        this.props.getGoflyFlightFareRuleDetails(data, 1);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "efly"
      ) {
        
        let data = this.eflyFareQuoteData(1);
        this.props.geteflyFlightFareRuleDetails(data, 1);
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "satkar"
      ) {
       
        let data = this.satkarFareQuoteData(1);
        this.props.getSatkarFlightFareRuleDetails(data, 1);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "fbq"
      ) {
        
        let data = this.fareBoutiqueFareQuoteData(1);
        this.props.getFareBoutiqueFlightFareRuleDetails(data, 1);
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } 
      

      if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source !== "kafila" &&
        this.props.location.state.data.api_source !== "tripjack" &&
        this.props.location.state.data.api_source !== "omairiq" &&
        this.props.location.state.data.api_source !== "gofly" &&
        this.props.location.state.data.api_source !== "satkar" &&
        this.props.location.state.data.api_source !== "fbq" &&
        this.props.location.state.data.api_source !== "riya_travel"
      ) {
       
        // let data = this.fareQuoteData(1);
        // this.props.getFareQuote(
        //   data,
        //   this.bookingError,
        //   function (response) {
        //     let ssrResquest = {
        //       trace_id: response.trace_id,
        //       result_index: response.results.result_index,
        //     };
        //     self.props.getSsrDetails(ssrResquest);
        //   },
        //   1
        // );
        let data = this.fareQuoteData();
   
        // First API call getFareQuote
        this.props.getFareQuote(data, this.bookingError, function (response) {
            let ssrRequest = {
                trace_id: response.trace_id,
                result_index: response.results.result_index,
            };
    
            self.props.getFlightFareRuleDetails(data)
    
            self.props.getSsrDetails(ssrRequest);
        });
      } else if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "kafila"
      ) {
        
        let data = this.fareQuoteData(1);
        this.props.getKafilaFlightFareRuleDetails(data);
        this.callKafilaFareQuote(this.props.location.state.searchType, 1);
      } else if (
        // (this.props.location.state.searchType === "round-trip" &&
        //   this.props.location.state.data[1].api_source === "tripjack") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "tripjack")
      ) {
    
        let data = this.tripJackFareQuote(1);
        let segment_length = data.segments[0].length;
        let origin = data && data.segments && data.segments[0] && data.segments[0][segment_length -1] && 
          data.segments[0][segment_length - 1].origin.airport.airport_code;
        let destination = data && data.segments && data.segments[0] && data.segments[0][segment_length -1] && 
          data.segments[0][segment_length - 1].destination.airport.airport_code;
        let FareRuleDetailsCredential = {
          uid: data.uid,
          flowType: "SEARCH",
          origin: origin,
          destination: destination,
          origin_code: origin,
          destination_code: destination,
          fare_type: data && data.fare_type,
          airline_code: data && data.airline_code,
          category: data && data.category,
        };
        this.props.getTripJackFlightFareRuleDetails(
          FareRuleDetailsCredential,
          1
        );

        this.props.getTripJackFareQuote(
          data,
          this.bookingError,
          function (response) {
            let seatInfo = {
              bookingId: response.bookingId,
            };
            self.props.getTripJackSeatInfo(seatInfo, 1);
          },
          1
        );
      }
     
      this.props.getCustomerList();
    }
  }

  //Function to save passengers into customer list and to save passengers data for booking
  addPassengersValues = (values) => {
    let isDomestic =
      this.props.searchQuery && this.props.searchQuery.is_domestic;
    let fare =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.fare_breakdown;
    let finalArray = [];
    let isKafilaTrue = (
      (this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") &&  
      (this.props.location.state.data.api_source === "kafila")
    ) || (
      (this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete" || this.props.location.state.searchType === "lcc-special-return") && 
      (
        this.props.location.state.data &&
        this.props.location.state.data[this.props.location.state.index] &&
        this.props.location.state.data[this.props.location.state.index].api_source === "kafila"
      )
    );
    
    let saveCustomer = [];
    let typeArray = ["adult", "child", "infant"];
    let passengerCount = 0;
    let tripJackSeat = false;
    let riyaSeat = false
    // filter nationality short name
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" || this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      tripJackSeat = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tripjack"
    ) {
      tripJackSeat = true;
    }

    //tbo check 
    let tboCheck = false
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" || this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "tbo"
    ) {
      tboCheck = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tbo"
    ) {
      tboCheck = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" || this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "riya_travel"
    ) {
      riyaSeat = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "riya_travel"
    ) {
      riyaSeat = true;
    }
    for (let j = 0; j < 3; j++) {
      for (
        let i = 1;
        i <= this.props.searchQuery[typeArray[j] + "_count"];
        i++
      ) {
        //Get fare according to passenger
        let fare_distribution = null;
        if (fare) {
          for (let k = 0; k < fare.length; k++) {
            if (fare[k].passenger_type === parseInt(j) + 1)
              fare_distribution = fare[k];
          }
        }
        let baggageDetail = [];
        let descArrays = [];
        let tboBaggageArrays = []
        let kafilaBaggage=[]
        let mealDetail = [];
        let mealDetailKafila=[];
        let mealDetailKafilaRound=[]
        let gdsMealKafila = null;
        let gdsSeatKafila = null;
        let gdsMeal = null;
        let gdsSeat = null;
        passengerCount = parseInt(passengerCount) + 1;
        let seatData = [];
        // seat
        if (
          values.selectedSeat &&
          values.selectedSeat.length > 0 &&
          this.props.ssrDetails.response &&
          this.props.ssrDetails.response.seat_dynamic
        ) {
         
          for (
            let seatOuter = 0;
            seatOuter < values.selectedSeat.length;
            seatOuter++
          ) {
            for (
              let seatInner = 0;
              seatInner < values.selectedSeat[seatOuter].length;
              seatInner++
            ) {
              for (
                let seatInner2 = 0;
                seatInner2 < values.selectedSeat[seatOuter][seatInner].length;
                seatInner2++
              ) {
                if (parseInt(seatInner2) + 1 === passengerCount) {
                  let seatCode =
                    values.selectedSeat[seatOuter][seatInner][seatInner2];
                  let index =
                    this.props.ssrDetails.response.seat_dynamic[seatOuter]
                      .segment_seat[seatInner].row_seats;
                  for (
                    let seatDynamic = 0;
                    seatDynamic < index.length;
                    seatDynamic++
                  ) {
                    for (
                      let seatDynamicInner = 0;
                      seatDynamicInner < index[seatDynamic].seats.length;
                      seatDynamicInner++
                    ) {
                      if (
                        index[seatDynamic].seats[seatDynamicInner].code ===
                        seatCode
                      ) {
                        seatData.push(
                          index[seatDynamic].seats[seatDynamicInner]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (
          (
            (values.selectedSeatKafila &&
            values.selectedSeatKafila.length > 0 &&
            this.props.location.state &&
            this.props.location.state.index !== 1 &&
            this.props.ssrKafilaDetails.response &&
            this.props.ssrKafilaDetails.response.result &&
            this.props.ssrKafilaDetails.response.result.seat_dynamic) &&
            (
              (
                this.props.location.state.searchType === "one-way" ||
                this.props.location.state.searchType === "special-return"
              ) &&
              (this.props.location.state.data.api_source === "kafila")
            )
          ) ||
          (
            (
              this.props.location.state.searchType === "round-trip" ||
              this.props.location.state.searchType === "round-trip-complete" ||
              this.props.location.state.searchType === "lcc-special-return"
            ) &&
            (
              this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index].api_source === "kafila"
            )
          )
        ) {
          for (
            let seatOuter = 0;
            seatOuter < (values.selectedSeatKafila && values.selectedSeatKafila.length);
            seatOuter++
          ) {
            for (
              let seatInner = 0;
              seatInner < values.selectedSeatKafila[seatOuter].length;
              seatInner++
            ) {
              if(values.selectedSeatKafila&&values.selectedSeatKafila[seatOuter]&&values.selectedSeatKafila[seatOuter][seatInner]&&values.selectedSeatKafila[seatOuter][seatInner].length){
                for (
                  let seatInner2 = 0;
                  seatInner2 < values.selectedSeatKafila[seatOuter][seatInner].length;
                  seatInner2++
                ) {
                  if (parseInt(seatInner2) + 1 === passengerCount) {
                    let seatCode =
                      values.selectedSeatKafila[seatOuter][seatInner][seatInner2];
                    let index =
                      this.props.ssrKafilaDetails.response.result.seat_dynamic[
                        seatInner
                      ] &&
                      this.props.ssrKafilaDetails.response.result.seat_dynamic[
                        seatInner
                      ].segment_seat[parseInt(seatCode) - 1] &&
                      this.props.ssrKafilaDetails.response.result.seat_dynamic[
                        seatInner
                      ].segment_seat[parseInt(seatCode) - 1].row_seats;
                    if (index && index.length > 0) {
                      for (
                        let seatDynamic = 0;
                        seatDynamic < index.length;
                        seatDynamic++
                      ) {
                        if (index[seatDynamic].code === seatCode) {
                          seatData.push(index[seatDynamic]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        else if (
          (
            values.selectedSeatKafila &&
            values.selectedSeatKafila.length > 0 &&
            this.props.location.state &&
            this.props.location.state.index === 1 &&
            this.props.ssrKafilaDetailsRound.response &&
            this.props.ssrKafilaDetailsRound.response.result &&
            this.props.ssrKafilaDetailsRound.response.result.seat_dynamic
          ) &&
          (
            (
              (this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") &&
              this.props.location.state.data &&
              this.props.location.state.data.api_source === "kafila"
            ) || 
            (
              (
                this.props.location.state.searchType === "round-trip" ||
                this.props.location.state.searchType === "round-trip-complete" ||
                this.props.location.state.searchType === "lcc-special-return"
              ) &&
              this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index].api_source === "kafila"
            )
          )
        ) {
          for (
            let seatOuter = 0;
            seatOuter < values.selectedSeatKafila.length;
            seatOuter++
          ) {
            for (
              let seatInner = 0;
              seatInner < values.selectedSeatKafila[seatOuter].length;
              seatInner++
            ) {
              if( values.selectedSeatKafila&&values.selectedSeatKafila[seatOuter]&&values.selectedSeatKafila[seatOuter][seatInner]&&values.selectedSeatKafila[seatOuter][seatInner].length){
                for (
                  let seatInner2 = 0;
                  seatInner2 < values.selectedSeatKafila[seatOuter][seatInner].length;
                  seatInner2++
                ) {
                  if (parseInt(seatInner2) + 1 === passengerCount) {
                    let seatCode =
                      values.selectedSeatKafila[seatOuter][seatInner][seatInner2];
                    let index =
                      this.props.ssrKafilaDetailsRound.response.result.seat_dynamic[
                        seatInner
                      ] &&
                      this.props.ssrKafilaDetailsRound.response.result.seat_dynamic[
                        seatInner
                      ].segment_seat[parseInt(seatCode) - 1] &&
                      this.props.ssrKafilaDetailsRound.response.result.seat_dynamic[
                        seatInner
                      ].segment_seat[parseInt(seatCode) - 1].row_seats;
                    if (index && index.length > 0) {
                      for (
                        let seatDynamic = 0;
                        seatDynamic < index.length;
                        seatDynamic++
                      ) {
                        if (index[seatDynamic].code === seatCode) {
                          seatData.push(index[seatDynamic]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (
          this.props.ssrDetails &&
          this.props.ssrDetails.response &&
          this.props.ssrDetails.response.baggage
        ) {
        
          for (
            let baggageOuter = 0;
            baggageOuter < this.props.ssrDetails.response.baggage.length;
            baggageOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(this.props.ssrDetails.response.baggage[baggageOuter])
                .length;
              objectKeys++
            ) {
              let currentDescArray = []
              let key = Object.keys(
                this.props.ssrDetails.response.baggage[baggageOuter]
              )[objectKeys];
             
              for (
                let baggageInner = 0;
                baggageInner <
                this.props.ssrDetails.response.baggage[baggageOuter][key]
                  .length;
                baggageInner++
              ) {
                let item =
                  this.props.ssrDetails.response.baggage[baggageOuter][key][
                    baggageInner
                  ];
                //Kindly note that in case of Fly Dubai and all the International LCC, the free baggage should be added from the SSR response to the ticket request then only you will be able to to avail yourself with the benefit.
                //If in case the free baggage is not selected from SSR response with Price node 0 then no free or default baggage would be provided from the airline end.
                //For Ex : 20 kg, 30 kg and 40 kg baggage are included in the fare only until and unless you will not select the this baggage in the ticket request you will not be able to avail yourself with the same.
                if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  item.weight !== 0 &&
                  item.price === 0
                ) {
                  currentDescArray.push(item);
                } else if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  item.weight === 0 &&
                  item.price === 0 &&
                  item.code === "NoBaggage"
                ) {
                  currentDescArray.push(item);
                } else {
                  if (
                    parseInt(item.weight) ===
                      parseInt(
                        values[
                          "baggage_kg_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ]
                      ) &&
                    parseInt(item.price ? item.price : 0) ===
                      parseInt(
                        values[
                          "baggage_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ]
                      )
                  ) {
                    baggageDetail.push(item);
                  }
                }
              }
              tboBaggageArrays.push(currentDescArray)
            }
          }
        }
        tboBaggageArrays = tboBaggageArrays && tboBaggageArrays.length && tboBaggageArrays.filter((item)=>{
          return item.length > 0
        })
        function findMaxDescForEachArrayTbo(arr) {
          return arr.map(innerArr => {
            let maxDescObject = null;
            let maxDescValue = Number.MIN_SAFE_INTEGER;
        
            innerArr.forEach(obj => {
              const descValue = parseInt(obj.weight);
              if (descValue > maxDescValue) {
                maxDescValue = descValue;
                maxDescObject = obj;
              }
            });
        
            return maxDescObject;
          });
        }
        const maxDescObjectsArrayTbo = tboBaggageArrays &&  tboBaggageArrays.length && findMaxDescForEachArrayTbo(tboBaggageArrays);
        maxDescObjectsArrayTbo && maxDescObjectsArrayTbo.length && maxDescObjectsArrayTbo.forEach((item)=>{
          baggageDetail.push(item)
        })
        if (
          this.props.location.state.index !== 1 &&
          this.props.ssrKafilaDetails.response &&
          this.props.ssrKafilaDetails.response.baggage &&
          this.props.ssrKafilaDetails.response.baggage.length > 0
        ) {
          for (
            let baggageOuter = 0;
            baggageOuter < this.props.ssrKafilaDetails.response.baggage.length;
            baggageOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrKafilaDetails.response.baggage[baggageOuter]
              ).length;
              objectKeys++
            ) {
              let key = Object.keys(
                this.props.ssrKafilaDetails.response.baggage[baggageOuter]
              )[objectKeys];
              for (
                let baggageInner = 0;
                baggageInner <
                this.props.ssrKafilaDetails.response.baggage[baggageOuter][key]
                  .length;
                baggageInner++
              ) {
                let item =
                  this.props.ssrKafilaDetails.response.baggage[baggageOuter][
                    key
                  ][baggageInner];
                // let wei=values["baggage_kg_" + typeArray[j] + baggageOuter + objectKeys + "_" + i]
                const numericWeight = item && item.weight && item.weight.match(/\d+/) !== null && parseFloat(item.weight.match(/\d+/)[0]);
                // const numericWeight2 = parseFloat(wei.match(/\d+/)[0]);
                const price = {price : parseInt(values["baggage_" + typeArray[j] + baggageOuter + objectKeys + "_" + i])}
                let newItem = {
                  ...item,
                  ...price
                }
                //Kindly note that in case of Fly Dubai and all the International LCC, the free baggage should be added from the SSR response to the ticket request then only you will be able to to avail yourself with the benefit.
                //If in case the free baggage is not selected from SSR response with Price node 0 then no free or default baggage would be provided from the airline end.
                //For Ex : 20 kg, 30 kg and 40 kg baggage are included in the fare only until and unless you will not select the this baggage in the ticket request you will not be able to avail yourself with the same.
                if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  numericWeight !== 0 &&
                  item.price === 0
                ) {
                  kafilaBaggage.push(item);
                } else if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  numericWeight === 0 &&
                  item.price === 0 &&
                  item.code === "NoBaggage"
                ) {
                  kafilaBaggage.push(item);
                } else {
                  if (
                    item.SsrFor === values["ssr_For_" + typeArray[j] + baggageOuter + objectKeys + "_" + i] &&
                    item.weight ===
                      values[
                        "baggage_kg_" +
                          typeArray[j] +
                          baggageOuter +
                          objectKeys +
                          "_" +
                          i
                      ] ||
                    parseInt(item.price ? item.price : 0) ===
                      parseInt(
                        values[
                          "baggage_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ]
                      )
                  ) {
                    kafilaBaggage.push(newItem);
                  }
                }
              }
            }
          }
        }
        if (
          this.props.location.state.index === 1 &&
          this.props.ssrKafilaDetailsRound.response &&
          this.props.ssrKafilaDetailsRound.response.baggage &&
          this.props.ssrKafilaDetailsRound.response.baggage.length > 0
        ) {
          for (
            let baggageOuter = 0;
            baggageOuter <
            this.props.ssrKafilaDetailsRound.response.baggage.length;
            baggageOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrKafilaDetailsRound.response.baggage[baggageOuter]
              ).length;
              objectKeys++
            ) {
              let key = Object.keys(
                this.props.ssrKafilaDetailsRound.response.baggage[baggageOuter]
              )[objectKeys];
              for (
                let baggageInner = 0;
                baggageInner <
                this.props.ssrKafilaDetailsRound.response.baggage[baggageOuter][
                  key
                ].length;
                baggageInner++
              ) {
                let item =
                  this.props.ssrKafilaDetailsRound.response.baggage[
                    baggageOuter
                  ][key][baggageInner];
                // let wei=values["baggage_kg_" + typeArray[j] + baggageOuter + objectKeys + "_" + i]
                const numericWeight = item && item.weight && item.weight.match(/\d+/) !== null && parseFloat(item.weight.match(/\d+/)[0]);
                // const numericWeight2 = parseFloat(wei.match(/\d+/)[0]);
                const price = {price : parseInt(values["baggage_" + typeArray[j] + baggageOuter + objectKeys + "_" + i])}
                let newItem = {
                  ...item,
                  ...price
                }
                //Kindly note that in case of Fly Dubai and all the International LCC, the free baggage should be added from the SSR response to the ticket request then only you will be able to to avail yourself with the benefit.
                //If in case the free baggage is not selected from SSR response with Price node 0 then no free or default baggage would be provided from the airline end.
                //For Ex : 20 kg, 30 kg and 40 kg baggage are included in the fare only until and unless you will not select the this baggage in the ticket request you will not be able to avail yourself with the same.
                if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  numericWeight !== 0 &&
                  item.price === 0
                ) {
                  kafilaBaggage.push(item);
                } else if (
                  !values[
                    "baggage_kg_" +
                      typeArray[j] +
                      baggageOuter +
                      objectKeys +
                      "_" +
                      i
                  ] &&
                  numericWeight === 0 &&
                  item.price === 0 &&
                  item.code === "NoBaggage"
                ) {
                  kafilaBaggage.push(item);
                } else {
                  if (
                    item.SsrFor === values["ssr_For_" + typeArray[j] + baggageOuter + objectKeys + "_" + i] &&
                    item.weight ===
                      values[
                        "baggage_kg_" +
                          typeArray[j] +
                          baggageOuter +
                          objectKeys +
                          "_" +
                          i
                      ] ||
                    parseInt(item.price ? item.price : 0) ===
                      parseInt(
                        values[
                          "baggage_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ]
                      )
                  ) {
                    kafilaBaggage.push(newItem);
                  }
                }
              }
            }
          }
        }

        if (
          this.props.ssrDetails.response &&
          this.props.ssrDetails.response.meal_dynamic
        ) {
          for (
            let mealOuter = 0;
            mealOuter < this.props.ssrDetails.response.meal_dynamic.length;
            mealOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrDetails.response.meal_dynamic[mealOuter]
              ).length;
              objectKeys++
            ) {
              let key = Object.keys(
                this.props.ssrDetails.response.meal_dynamic[mealOuter]
              )[objectKeys];
              for (
                let mealInner = 0;
                mealInner <
                this.props.ssrDetails.response.meal_dynamic[mealOuter][key]
                  .length;
                mealInner++
              ) {
                if (
                  values[
                    "meal_val_" +
                      typeArray[j] +
                      mealOuter +
                      objectKeys +
                      "_" +
                      i
                  ]
                ) {
                  let item =
                    this.props.ssrDetails.response.meal_dynamic[mealOuter][key][
                      mealInner
                    ];
                  if (
                    item.code ===
                    values[
                      "meal_val_" +
                        typeArray[j] +
                        mealOuter +
                        objectKeys +
                        "_" +
                        i
                    ]
                  ) {
                    mealDetail.push(item);
                  }
                }
              }
            }
          }
        }

        if (
          this.props.ssrKafilaDetails.response &&
          this.props.ssrKafilaDetails.response.result &&
          this.props.ssrKafilaDetails.response.result.meal_dynamic &&
          this.props.ssrKafilaDetails.response.result.meal_dynamic.length > 0
        ) {
          for (
            let mealOuter = 0;
            mealOuter <
            this.props.ssrKafilaDetails.response.result.meal_dynamic.length;
            mealOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrKafilaDetails.response.result.meal_dynamic[
                  mealOuter
                ]
              ).length;
              objectKeys++
            ) {
              let key = Object.keys(
                this.props.ssrKafilaDetails.response.result.meal_dynamic[
                  mealOuter
                ]
              )[objectKeys];
              for (
                let mealInner = 0;
                mealInner <
                this.props.ssrKafilaDetails.response.result.meal_dynamic[
                  mealOuter
                ][key].length;
                mealInner++
              ) {
                if (
                  values[
                    "meal_val_" +
                      typeArray[j] +
                      mealOuter +
                      objectKeys +
                      "_" +
                      i
                  ]
                ) {
                  let item =
                    this.props.ssrKafilaDetails.response.result.meal_dynamic[
                      mealOuter
                    ][key][mealInner];
                  if (
                    item.code ===
                    values[
                      "meal_val_" +
                        typeArray[j] +
                        mealOuter +
                        objectKeys +
                        "_" +
                        i
                    ]
                  ) {
                    mealDetailKafila.push(item)
                  }
                }
              }
            }
          }
        }

        if (
          this.props.location.state.index === 1 &&
          this.props.ssrKafilaDetailsRound.response &&
          this.props.ssrKafilaDetailsRound.response.result &&
          this.props.ssrKafilaDetailsRound.response.result.meal_dynamic &&
          this.props.ssrKafilaDetailsRound.response.result.meal_dynamic.length >
            0
        ) {
          for (
            let mealOuter = 0;
            mealOuter <
            this.props.ssrKafilaDetailsRound.response.result.meal_dynamic
              .length;
            mealOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrKafilaDetailsRound.response.result.meal_dynamic[
                  mealOuter
                ]
              ).length;
              objectKeys++
            ) {
              let key = Object.keys(
                this.props.ssrKafilaDetailsRound.response.result.meal_dynamic[
                  mealOuter
                ]
              )[objectKeys];
              for (
                let mealInner = 0;
                mealInner <
                this.props.ssrKafilaDetailsRound.response.result.meal_dynamic[
                  mealOuter
                ][key].length;
                mealInner++
              ) {
                if (
                  values[
                    "meal_val_" +
                      typeArray[j] +
                      mealOuter +
                      objectKeys +
                      "_" +
                      i
                  ]
                ) {
                  let item =
                    this.props.ssrKafilaDetailsRound.response.result
                      .meal_dynamic[mealOuter][key][mealInner];
                  if (
                    item.code ===
                    values[
                      "meal_val_" +
                        typeArray[j] +
                        mealOuter +
                        objectKeys +
                        "_" +
                        i
                    ]
                  ) {
                    mealDetailKafilaRound.push(item);
                  }
                }
              }
            }
          }
        }

        if (!values.isLcc) {
          if (
            values["gds_meal_" + typeArray[j] + "_" + i] &&
            this.props.ssrDetails.response
          ) {
            for (
              let mealOuter = 0;
              mealOuter < this.props.ssrDetails.response.meal.length;
              mealOuter++
            ) {
              let item = this.props.ssrDetails.response.meal[mealOuter];
              if (item.code === values["gds_meal_" + typeArray[j] + "_" + i]) {
                gdsMeal = item;
              }
            }
          }
          if (
            values["gds_seat_" + typeArray[j] + "_" + i] &&
            this.props.ssrDetails.response
          ) {
            for (
              let seatOuter = 0;
              seatOuter < this.props.ssrDetails.response.seat_preference.length;
              seatOuter++
            ) {
              let item =
                this.props.ssrDetails.response.seat_preference[seatOuter];
              if (item.code === values["gds_seat_" + typeArray[j] + "_" + i]) {
                gdsSeat = item;
              }
            }
          }
        }
        // changes meal  kafila
        if (!values.isLcc && this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result) {
          if (values["gds_meal_" + typeArray[j] + "_" + i] && this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result&&this.props.ssrKafilaDetails.response.result.meal) {
              for (let mealOuter = 0; mealOuter < this.props.ssrKafilaDetails.response.result.meal.length; mealOuter++) {
                  let item = this.props.ssrKafilaDetails.response.result.meal[mealOuter];
                  if (item.code === values["gds_meal_" + typeArray[j] + "_" + i]) {
                      gdsMealKafila = item;
                  }
              }
          }
          if (values["gds_seat_" + typeArray[j] + "_" + i] && this.props.ssrDetails.response) {
              for (let seatOuter = 0; seatOuter < this.props.ssrDetails.response.seat_preference.length; seatOuter++) {
                  let item = this.props.ssrDetails.response.seat_preference[seatOuter];
                  if (item.code === values["gds_seat_" + typeArray[j] + "_" + i]) {
                      gdsSeatKafila = item;
                  }
              }
          }
      }
      //tripjack seat
    

        if (
          values.selectedSeat.length > 0 &&
          tripJackSeat &&
          this.props.seatInfo.response &&
          this.props.seatInfo.response.results
        ) {
    
        
          let seatTracker = {}; // Object to track added seats
        
          for (let seatOuter = 0; seatOuter < values.selectedSeat.length; seatOuter++) {
       
            
            for (let seatInner = 0; seatInner < values.selectedSeat[seatOuter].length; seatInner++) {
          
              
              const origin = {
                origin: this.props.fareQuote.response && this.props.fareQuote.response.results &&this.props.fareQuote.response.results.segments[seatOuter][seatInner].origin.airport.airport_code,
              };
              const destination = {
                destination: this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[seatOuter][seatInner].destination.airport.airport_code,
              };
              const airline_code = {
                airline_code:  this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[seatOuter][seatInner].airline.airline_code,
              };
              const flight_number = {
                flight_number:  this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[seatOuter][seatInner].airline.flight_number,
              };
              const key = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[seatOuter][seatInner].segment_id;
             
        
              for (let seatInner2 = 0; seatInner2 < values.selectedSeat[seatOuter][seatInner].length; seatInner2++) {
                if (parseInt(seatInner2) + 1 === passengerCount) {
                  const seatCode = values.selectedSeat[seatOuter][seatInner][seatInner2];
                  const index = this.props.seatInfo.response.results[0][key].sInfo;
                  const segment_id = { segment_id: key };
        
                  for (let seatDynamic = 0; seatDynamic < index.length; seatDynamic++) {
                    if (index[seatDynamic].code === seatCode) {
                      const seat = index[seatDynamic];
                      const price = { price: index[seatDynamic].amount };
                      const item = {
                        ...seat,
                        ...origin,
                        ...destination,
                        ...segment_id,
                        ...airline_code,
                        ...flight_number,
                        ...price,
                      };
        
                      // Create a unique key for the seat using its code and segment_id
                      const seatKey = `${item.code}-${item.segment_id}`;
                     
                      if (!seatTracker[seatKey]) {
                        seatData.push(item);
                        seatTracker[seatKey] = true; // Mark this seat as added
                      }
               
                    }
                  }
                }
              }
            }
          }
        }
        
        
        if (this.props.ssrDetails.response) {
          for (
            let baggageOuter = 0;
            baggageOuter < this.props.ssrDetails.response.length;
            baggageOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(this.props.ssrDetails.response[baggageOuter]).length;
              objectKeys++
            ) {
              let item =
                this.props.ssrDetails.response[baggageOuter][objectKeys];
                let currentDescArray = []
              item.ssr_info &&
                item.ssr_info.BAGGAGE &&
                item.ssr_info.BAGGAGE.forEach((baggage, key) => {
                  
                  const origin = { origin: item.origin.airport.airport_code };
                  const destination = {
                    destination: item.destination.airport.airport_code,
                  };
                  const segment_id = { segment_id: item.segment_id };
                  const airline_code = {
                    airline_code: item.airline.airline_code,
                  };
                  const flight_number = {
                    flight_number: item.airline.flight_number,
                  };
                  const description = { description: baggage.desc };
                  const price = { price: baggage.amount };
                  let newItem = {
                    ...baggage,
                    ...origin,
                    ...destination,
                    ...segment_id,
                    ...airline_code,
                    ...flight_number,
                    ...description,
                    ...price,
                  };
                  if (
                    !values[
                      "baggage_kg_" +
                        typeArray[j] +
                        baggageOuter +
                        objectKeys +
                        "_" +
                        i
                    ] &&
                    baggage.desc !== 0 &&
                    baggage.amount === 0
                  ) {
                    currentDescArray.push(newItem);
                  } else {
                    if (
                      baggage.desc ===
                        values[
                          "baggage_kg_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ] &&
                      parseInt(baggage.amount ? baggage.amount : 0) ===
                        parseInt(
                          values[
                            "baggage_" +
                              typeArray[j] +
                              baggageOuter +
                              objectKeys +
                              "_" +
                              i
                          ]
                        )
                    ) {
                      baggageDetail.push(newItem);
                    }
                  }
                });
                descArrays.push(currentDescArray);
            }
          }
        }
        // ssr baggage outer
        if (
          this.props.ssrKafilaDetails.response &&
          this.props.ssrKafilaDetails.response.result
        ) {
          
          for (
            let baggageOuter = 0;
            baggageOuter < this.props.ssrKafilaDetails.response.result.length;
            baggageOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(
                this.props.ssrKafilaDetails.response.result[baggageOuter]
              ).length;
              objectKeys++
            ) {
              let item =
                this.props.ssrKafilaDetails.response.result[baggageOuter][
                  objectKeys
                ];
              item.ssr_info &&
                item.ssr_info.BAGGAGE &&
                item.ssr_info.BAGGAGE.forEach((baggage, key) => {
                  //Kindly note that in case of Fly Dubai and all the International LCC, the free baggage should be added from the SSR response to the ticket request then only you will be able to to avail yourself with the benefit.
                  //If in case the free baggage is not selected from SSR response with Price node 0 then no free or default baggage would be provided from the airline end.
                  //For Ex : 20 kg, 30 kg and 40 kg baggage are included in the fare only until and unless you will not select the this baggage in the ticket request you will not be able to avail yourself with the same.
                  const origin = {
                    origin:
                      item.origin &&
                      item.origin.airport &&
                      item.origin.airport.airport_code,
                  };
                  const destination = {
                    destination:
                      item.destination &&
                      item.destination.airport &&
                      item.destination.airport.airport_code,
                  };
                  const segment_id = { segment_id: item.segment_id };
                  const airline_code = {
                    airline_code: item.airline.airline_code,
                  };
                  const flight_number = {
                    flight_number: item.airline.flight_number,
                  };
                  const description = { description: baggage.desc };
                  const price = { price: baggage.amount };
                  let newItem = {
                    ...baggage,
                    ...origin,
                    ...destination,
                    ...segment_id,
                    ...airline_code,
                    ...flight_number,
                    ...description,
                    ...price,
                  };
                  if (
                    !values[
                      "baggage_kg_" +
                        typeArray[j] +
                        baggageOuter +
                        objectKeys +
                        "_" +
                        i
                    ] &&
                    baggage.desc !== 0 &&
                    baggage.amount === 0
                  ) {
                    kafilaBaggage.push(newItem);      
                  } else {
                    if (
                      baggage.desc ===
                        values[
                          "baggage_kg_" +
                            typeArray[j] +
                            baggageOuter +
                            objectKeys +
                            "_" +
                            i
                        ] &&
                      parseInt(baggage.amount) ===
                        parseInt(
                          values[
                            "baggage_" +
                              typeArray[j] +
                              baggageOuter +
                              objectKeys +
                              "_" +
                              i
                          ]
                        )
                    ) {
                      kafilaBaggage.push(newItem);
                    }
                  }
                });
            }
          }
        }
         descArrays = descArrays && descArrays.length && descArrays.filter((item)=>{
          return item.length > 0
        })
        function findMaxDescForEachArray(arr) {
          return arr.map(innerArr => {
            let maxDescObject = null;
            let maxDescValue = Number.MIN_SAFE_INTEGER;
        
            innerArr.forEach(obj => {
              const descValue = parseInt(obj.desc.match(/\d+/)[0]);
              if (descValue > maxDescValue) {
                maxDescValue = descValue;
                maxDescObject = obj;
              }
            });
        
            return maxDescObject;
          });
        }
        
        const maxDescObjectsArray = descArrays && descArrays.length && findMaxDescForEachArray(descArrays);
        maxDescObjectsArray && maxDescObjectsArray.length && maxDescObjectsArray.forEach((item)=>{
          baggageDetail.push(item)
        })
        if (this.props.ssrDetails.response) {
          for (
            let mealOuter = 0;
            mealOuter < this.props.ssrDetails.response.length;
            mealOuter++
          ) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(this.props.ssrDetails.response[mealOuter]).length;
              objectKeys++
            ) {
              let item = this.props.ssrDetails.response[mealOuter][objectKeys];
              item.ssr_info &&
                item.ssr_info.MEAL &&
                item.ssr_info.MEAL.forEach((meal, key) => {
                  const origin = { origin: item.origin.airport.airport_code };
                  const destination = {
                    destination: item.destination.airport.airport_code,
                  };
                  const segment_id = { segment_id: item.segment_id };
                  const airline_code = {
                    airline_code: item.airline.airline_code,
                  };
                  const flight_number = {
                    flight_number: item.airline.flight_number,
                  };
                  const description = { description: meal.desc };
                  const price = { price: meal.amount };
                  let newItem = {
                    ...meal,
                    ...origin,
                    ...destination,
                    ...segment_id,
                    ...airline_code,
                    ...flight_number,
                    ...description,
                    ...price,
                  };
                  if (
                    meal.code ===
                    values[
                      "meal_val_" +
                        typeArray[j] +
                        mealOuter +
                        objectKeys +
                        "_" +
                        i
                    ]
                  ) {
                    mealDetail.push(newItem);
                  }
                });
            }
          }
        }

        //riya baggage
      
        if (
          this.props.ssrDetails.response &&
          this.props.ssrDetails.response.results &&
          this.props.ssrDetails.response.results.segments
        ) {
          const segmentsLength = this.props.ssrDetails.response.results.segments.length;

          const uniqueItems = new Set();
          
          for (let baggageOuter = 0; baggageOuter < segmentsLength; baggageOuter++) {
            const segment = this.props.ssrDetails.response.results.segments[baggageOuter];
        
            for (let objectKeys = 0; objectKeys < Object.keys(segment).length; objectKeys++) {
              let item = segment[objectKeys];
        
              if (this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.ssr_info) {
                this.props.ssrDetails.response.ssr_info.baggage.forEach((baggage, key) => {
                  const origin = item.origin && item.origin.airport && item.origin.airport.airport_code;
                  const destination = item.destination && item.destination.airport && item.destination.airport.airport_code;
                  const airline_code = item.airline.airline_code;
                  const flight_number = item.airline.flight_number;
                  const description = baggage.description;
                  const price = baggage.price;
        
                  const newItem = { 
                    ...baggage, 
                    origin, 
                    destination, 
                    airline_code, 
                    flight_number, 
                    description, 
                    price 
                  };
        
                  const itemKey = `${origin}_${destination}_${airline_code}_${flight_number}_${description}_${price}`;
                  
                  if (!uniqueItems.has(itemKey)) {
                    uniqueItems.add(itemKey);
        
                    if (!values["baggage_kg_" + typeArray[j] + baggageOuter + objectKeys + i] && baggage.price === 0) {
                      baggageDetail.push(newItem);
                    } else {
                      if (
                        baggage.description === values["baggage_kg_" + typeArray[j] + baggageOuter + objectKeys + i] &&
                        parseInt(baggage.price || 0) === parseInt(values["baggage_" + typeArray[j] + baggageOuter + objectKeys + i])
                      ) {
                        baggageDetail.push(newItem);
                      }
                    }
                  }
                });
              }
            }
          }
        }
        
        
        //riya meal
        if (
          this.props.ssrDetails.response &&
          this.props.ssrDetails.response.results &&
          this.props.ssrDetails.response.results.segments
        ) {
          const segmentsLength = this.props.ssrDetails.response.results.segments.length;
        
          for (let mealOuter = 0; mealOuter < segmentsLength; mealOuter++) {
            for (
              let objectKeys = 0;
              objectKeys <
              Object.keys(this.props.ssrDetails.response.results.segments[mealOuter])
                .length;
              objectKeys++
            ) {
              let item = this.props.ssrDetails.response.results.segments[mealOuter][objectKeys];
          
              if (
                this.props.ssrDetails &&
                this.props.ssrDetails.response &&
                this.props.ssrDetails.response.ssr_info
              ) {
                this.props.ssrDetails.response.ssr_info.meal_dynamic.forEach((meal, key) => {
             
                  if (
                    item.origin.airport.airport_code === meal.origin &&
                    item.destination.airport.airport_code === meal.destination
                  ) {
                  
                    const newItem = {
                      ...meal,
                      origin: item.origin.airport.airport_code,
                      destination: item.destination.airport.airport_code,
                      airline_code: item.airline.airline_code,
                      flight_number: item.airline.flight_number,
                      description: meal.description,
                      price: meal.price,
                    };
       
            
                    if (isDomestic === "false"){
                    
                      if (meal.code === values["meal_val_" + typeArray[j] +  objectKeys + mealOuter + "_" + i]) { 
                        mealDetail.push(newItem);
                      }
                    }else{
                    
                      if (meal.code === values["meal_val_" + typeArray[j] + mealOuter + objectKeys + "_" + i]) {
                        mealDetail.push(newItem);
                      }
                    } 
      
                  }
                 
                });
              }
            }
          }
        
          // Filter mealDetail to keep only one item from each pair
          const uniqueMeals = [];
          const addedKeys = new Set();
        
          mealDetail.forEach((meal) => {
            const key = `${meal.origin}-${meal.destination}-${meal.airline_code}-${meal.flight_number}`;
            if (!addedKeys.has(key)) {
              uniqueMeals.push(meal);
              addedKeys.add(key);
            }
          });
        
          // The uniqueMeals array now contains only one item from each pair
          mealDetail = uniqueMeals;
        }
        
        
        //riya seat 

        if (
          values.selectedSeat.length > 0 &&
          riyaSeat &&
          this.props.seatRiyaInfo.response
        ) {
          
          let uniqueCombinations = new Set(); // Set to track unique combinations of origin, destination, and flight_number
      
          for (let seatOuter = 0; seatOuter < values.selectedSeat.length; seatOuter++) {
            const segments = this.props.fareQuote.response.results.segments[seatOuter];
            const segmentLength = segments.length;
        
            let segmentSeatData = []; // Store seat data for the current segment
          
            for (let seatInner = 0; seatInner < values.selectedSeat[seatOuter].length; seatInner++) {
           
              const origin = segments[seatInner].origin.airport.airport_code;
              const destination = segments[seatInner].destination.airport.airport_code;
              const airline_code = segments[seatInner].airline.airline_code;
              const flight_number = segments[seatInner].airline.flight_number;
        
              for (let seatInner2 = 0; seatInner2 < values.selectedSeat[seatOuter][seatInner].length; seatInner2++) {

                if (parseInt(seatInner2) + 1 === passengerCount) {
                 
                  let seatCode = values.selectedSeat[seatOuter][seatInner][seatInner2];
        
                  let response = this.props.seatRiyaInfo.response;
        
                  Object.keys(response).forEach(key => {
                    if (Array.isArray(response[key])) {
                
                      response[key].forEach(seat => {
                      
                        if (seat.SeatName === seatCode &&
                            seat.Origin === origin && 
                            seat.Destination === destination) {
                            
                          const price = { price: seat.SeatAmount };
                          let item = {
                            ...seat,
                            origin,
                            destination,
                            airline_code,
                            flight_number,
                            ...price,
                          };
                          
                          const uniqueKey = `${origin}-${destination}}`;
                          
                          // Check if this combination already exists in the uniqueCombinations set
                          if (!uniqueCombinations.has(uniqueKey)) {
                            
                            uniqueCombinations.add(uniqueKey);
                            segmentSeatData.push(item);
                          }
                        }
                      });
                    }
                  });
                }
              }
            }
   
            // Add the segment seat data to the overall seat data if it matches the segment length
            if (segmentSeatData.length === segmentLength) {
                     seatData.push(...segmentSeatData);
            }else{
              seatData.push(...segmentSeatData)
            }
          }
        }

        //Array to save customers for final booking
        let pax_title = "";
        let pax_title_id = 0;
        let count = 0;
        if (
          (!values["title_name_" + typeArray[j] + "_" + i] ||
            values["title_name_" + typeArray[j] + "_" + i] === "") &&
          this.props.paxTitles.response
        ) {
          for (
            let i = 0;
            i < this.props.paxTitles.response.pax_titles.length;
            i++
          ) {
            let item = this.props.paxTitles.response.pax_titles[i];
            if (item.pax_type.toLowerCase() === typeArray[j]) {
              if (count === 0) {
                pax_title = item.passenger_title;
                pax_title_id = item.title_id;
              }
              count = parseInt(count) + 1;
            }
          }
        } else {
          pax_title = values["title_name_" + typeArray[j] + "_" + i];
          pax_title_id = values["passenger_title_" + typeArray[j] + "_" + i];
        }
        let nationality_shortname =
          this.props.country_details &&
          this.props.country_details.response &&
          this.props.country_details.response.country_details &&
          this.props.country_details.response.country_details.filter((item) => {
            if (isDomestic === "false") {
              if (
                values["nationality_" + typeArray[j] + "_" + i] ===
                item.nationality
              ) {
                return item.short_name;
              }else{
                return false;
              }
            } else {
              return null;
            }
          });
       
        finalArray.push({
          first_name: values["first_name_" + typeArray[j] + "_" + i],
          last_name: values["last_name_" + typeArray[j] + "_" + i],
          mobile_no: parseInt(values.mobile_no),
          passenger_type: parseInt(j) + 1,
          title_name: pax_title,
          date_of_birth: values["dob_" + typeArray[j] + "_" + i]
            ? moment(values["dob_" + typeArray[j] + "_" + i]).format(
                "YYYY-MM-DD"
              ) + "T00:00:00"
            : "",
          passport_no:
            isDomestic === "false"
              ? values["passport_" + typeArray[j] + "_" + i]
              : null,
          passport_exp:
            isDomestic === "false"
              ? values["passportExpiry_" + typeArray[j] + "_" + i]
                ? moment(
                    values["passportExpiry_" + typeArray[j] + "_" + i]
                  ).format("YYYY-MM-DD") + "T00:00:00"
                : null
              : null,
          passport_issue_date:
            isDomestic === "false"
              ? (values["passportIssue_" + typeArray[j] + "_" + i] && values["passportIssue_" + typeArray[j] + "_" + i] !== "0000-00-00"
              )
                ? moment(
                    values["passportIssue_" + typeArray[j] + "_" + i]
                  ).format("YYYY-MM-DD") + "T00:00:00"
                : null
              : null,
          passport_issue_country_code:
            isDomestic === "false"
              ? values["passport_issue_country_code_" + typeArray[j] + "_" + i]
              : null,
          frequent_flyer_no: values["frequentFlyer_" + typeArray[j] + "_" + i],
          ff_airline_code: values["ff_airline_" + typeArray[j] + "_" + i],
          addressLine1:
            values.addressLine1 && values.addressLine1 !== ""
              ? values.addressLine1
              : this.props.accountResponse.myAccountResponse.response
              ? this.props.accountResponse.myAccountResponse.response
                  .office_address
              : "",
          addressLine2: values.addressLine2,
          city_name: values.city_name,
          gst_no: values.gst_checked ? values.gst_number.trim() : "",
          gst_company_name: values.gst_checked
            ? values.gst_company_name.trim()
            : "",
          gst_company_contact_no:
            values.gst_checked && values.gst_company_number !== ""
              ? parseInt(values.gst_company_number)
              : "",
          gst_company_address: values.gst_checked
            ? values.gst_company_address.trim()
            : "",
          gst_company_email: values.gst_checked
            ? values.gst_company_email.trim()
            : "",
          email_id: values.email,
          is_lead_pax: j === 0 && i === 1 ? true : false,
          gender: values["gender_" + typeArray[j] + "_" + i],
          country_name: values.country_name,
          country_code: values.country_code,
          cell_country_code: values.cell_country_code,
          fare_distribution: fare_distribution,
          nationality:
            isDomestic === "false"
              ? values["nationality_" + typeArray[j] + "_" + i]
              : null,
          nationality_short_name:
            isDomestic === "false" &&
            nationality_shortname &&
            nationality_shortname.length > 0 &&
            nationality_shortname[0] &&
            nationality_shortname[0].short_name
              ? nationality_shortname &&
                nationality_shortname.length > 0 &&
                nationality_shortname[0] &&
                nationality_shortname[0].short_name
              : "IN",
          baggage: isKafilaTrue ? kafilaBaggage : baggageDetail,
          meal_dynamic: (isKafilaTrue && this.props.location && this.props.location.state && this.props.location.state.index !==1) ? mealDetailKafila : (isKafilaTrue && this.props.location && this.props.location.state && this.props.location.state.index===1) ? mealDetailKafilaRound : mealDetail,
          seat_dynamic: seatData,
          meal: isKafilaTrue? gdsMealKafila : gdsMeal,
          seat: isKafilaTrue ? gdsSeatKafila : gdsSeat,
          student_id: values["document_type_" + typeArray[j] + "_" + i],
          senior_citizen_id:
            values["senior_citizen_id_" + typeArray[j] + "_" + i],
        });
       
  
        if (values["saveCustomer_" + typeArray[j] + "_" + i]) {
          //Array to save passangers into customer list
          saveCustomer.push({
            first_name: values["first_name_" + typeArray[j] + "_" + i],
            last_name: values["last_name_" + typeArray[j] + "_" + i],
            // document_type: values["document_type_" + typeArray[j] + "_" + i],
            senior_citizen_id:
              values["senior_citizen_id_" + typeArray[j] + "_" + i],
            passenger_type: parseInt(j) + 1,
            gender: values["gender_" + typeArray[j] + "_" + i],
            date_of_birth: values["dob_" + typeArray[j] + "_" + i]
              ? moment(values["dob_" + typeArray[j] + "_" + i]).format(
                  "YYYY-MM-DD"
                )
              : "",
            student_id: values["document_type_" + typeArray[j] + "_" + i],
            passport_no: values["passport_" + typeArray[j] + "_" + i],
            passport_exp: moment(
              values["passportExpiry_" + typeArray[j] + "_" + i]
            ).format("YYYY-MM-DD") !== "Invalid date" ? moment(
              values["passportExpiry_" + typeArray[j] + "_" + i]
            ).format("YYYY-MM-DD") : "",
            passport_issue_country_code:
              values["passport_issue_country_code" + typeArray[j] + "_" + i],
            passport_issue_date: (moment(
              values["passportIssue_" + typeArray[j] + "_" + i]
            ).format("YYYY-MM-DD") !== "Invalid date") || values["passportIssue_" + typeArray[j] + "_" + i] !== "0000-00-00"
            ?  moment(
              values["passportIssue_" + typeArray[j] + "_" + i]
            ).format("YYYY-MM-DD") : null,
            passenger_title: parseInt(pax_title_id),
            frequent_flyer_no:
              values["frequentFlyer_" + typeArray[j] + "_" + i],
            nationality:
              isDomestic === "false"
                ? values["nationality_" + typeArray[j] + "_" + i]
                : null,
            mobile_no: parseInt(values.mobile_no), //Add mobile_no only for first adult,
            email_id: j === 0 && i === 1 && values.email ? values.email : null, //Add email only for first adult,
            address:
              j === 0 && i === 1 && values.addressLine1
                ? values.addressLine1 + " " + values.addressLine2
                : null, //Add address only for first adult,
            country:
              j === 0 && i === 1 && values.country ? values.country : null, //Add country only for first adult,
            city: j === 0 && i === 1 && values.city ? values.city : null, //Add city only for first adult,
            gst_no:
              j === 0 && i === 1 && values.gst_checked && values.gst_number
                ? values.gst_number.trim()
                : null, //Add gst number only for first adult
            gst_company_name:
              j === 0 &&
              i === 1 &&
              values.gst_checked &&
              values.gst_company_name
                ? values.gst_company_name.trim()
                : null, //Add gst company name only for first adult
            gst_company_contact_no:
              j === 0 &&
              i === 1 &&
              values.gst_checked &&
              values.gst_company_number
                ? parseInt(values.gst_company_number)
                : null, //Add gst company contact number only for first adult
            gst_company_address:
              j === 0 &&
              i === 1 &&
              values.gst_checked &&
              values.gst_company_address
                ? values.gst_company_address.trim()
                : null, //Add gst company address only for first adult
            gst_company_email:
              j === 0 &&
              i === 1 &&
              values.gst_checked &&
              values.gst_company_email
                ? values.gst_company_email.trim()
                : null, //Add gst company email only for first adult
          });
       
        }
      }
    }

    
    let requiredFieldValidators =  this.props.fareQuote.response &&
    this.props.fareQuote.response.results &&
    this.props.fareQuote.response.results && this.props.fareQuote.response.results.requiredFieldValidators 

    

    if (!tboCheck) {
     
     this.setState({
       passengers: finalArray,
       addPassengerSuccess: true,
       formikValues: values,
     });
   
   }else if(tboCheck && requiredFieldValidators && Object.keys(requiredFieldValidators).length > 0 && !this.props.ssrDetails.loading && !this.props.ssrDetails.error )
    {

      const segmentsArray = this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments;
      const allSegments = segmentsArray.flat();
    
      let category_name = this.props.searchQuery && this.props.searchQuery.is_domestic === "true"
        ? "Domestic"
        : "International";
    
      let mealSuccess = false;
      let baggageSuccess = false;
      let seatSuccess = false;
    
      const mealPresent = this.props.ssrDetails && ( this.props.ssrDetails  &&  this.props.ssrDetails.response && this.props.ssrDetails.response.meal || this.props.ssrDetails  &&  this.props.ssrDetails.response && this.props.ssrDetails.response.meal_dynamic);
      const baggagePresent = this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.baggage;
      const seatPresent = this.props.ssrDetails && (this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.seat_preference || this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic);
     
      const updateMealSuccess = () => {
        let allSegmentsMatch = true; // Assume all segments match initially
      
        finalArray.forEach((finalItem) => {
          if (requiredFieldValidators.IsMealRequired && finalItem.meal_dynamic) {
            if ((category_name === "Domestic" || category_name === "International") && (allSegments.length === finalItem.meal_dynamic.length)) {
             
              
              const segmentsMatch = allSegments.every((segment, index) => {
                const meal = finalItem.meal_dynamic[index];
                return segment.origin.airport.airport_code === meal.origin &&
                       segment.destination.airport.airport_code === meal.destination;
              });
      
              if (!segmentsMatch) {
                allSegmentsMatch = false;
              }
            } else if ((category_name === "Domestic" || category_name === "International") && (finalItem.meal !== null)) {
       
              allSegmentsMatch = true;
            } else {
            
              allSegmentsMatch = false;
            }
          }
        });
      
        // Update mealSuccess and state based on the result
        if (allSegmentsMatch) {
      
          this.setState({ mealSelected: true });
          mealSuccess = true;
        } else {
        
          this.setState({ mealSelected: false });
          mealSuccess = false;
        }
      };
      
    
      const updateBaggageSuccess = () => {
        let allSegmentsMatch = true; // Assume all segments match initially
        
        finalArray.forEach((finalItem) => {
          // Check if baggage is required and there is baggage data present
          if (requiredFieldValidators.IsBaggageRequired && finalItem.baggage) {
         
            
            // Check if the passenger type is not "3" (infant)
            if (finalItem && finalItem.fare_distribution && finalItem.fare_distribution.passenger_type !== 3) {
             
              
              // Fetch all baggage segments for comparison
              const allBaggageSegments = this.getFareQuoteSegments(
                this.props.location && this.props.location.state && this.props.location.state.searchType
              );
              
              
              // Verify if all segments match
              if (allBaggageSegments.length === finalItem.baggage.length) {
                const segmentsMatch = allBaggageSegments.every((segment, index) => {
                  const baggage = finalItem.baggage[index];
                  return segment.origin === baggage.origin &&
                         segment.destination === baggage.destination &&
                         baggage.code !== 'NoBaggage';
                });
                
                
                if (!segmentsMatch) {
                  allSegmentsMatch = false;
                }
              } else {
                allSegmentsMatch = false;
              }
            }
          }
        });
        
        // Update baggageSuccess and state based on the result
        if (allSegmentsMatch) {
         
          this.setState({ baggageSelected: true });
          baggageSuccess = true;
        } else {
       
          this.setState({ baggageSelected: false });
          baggageSuccess = false;
        }
      };
      
      
      const updateSeatSuccess = () => {
  let allSegmentsMatch = true; // Assume all segments match initially
  let anySeatMissing = false; 

  finalArray.forEach((finalItem) => {
    if (requiredFieldValidators.IsSeatRequired && finalItem.seat_dynamic) {

      if (finalItem && finalItem.fare_distribution && finalItem.fare_distribution.passenger_type !== 3) {
     
        if ((category_name === "Domestic" || category_name === "International") && (allSegments.length === finalItem.seat_dynamic.length)) {
        
          
          const segmentsMatch = allSegments.every((segment, index) => {
            const seat = finalItem.seat_dynamic[index];
            return (segment && segment.origin && segment.origin.airport && segment.origin.airport.airport_code === seat.origin) &&
                   (segment && segment.destination && segment.destination.airport && segment.destination.airport.airport_code === seat.destination);
          });

     

          if (!segmentsMatch) {
            allSegmentsMatch = false;
          }
        } else if ((category_name === "Domestic" || category_name === "International") && finalItem.seat !== null) {
   
          anySeatMissing = finalArray.some(item => item.seat === null);
          allSegmentsMatch = !anySeatMissing;
        
        } else if (category_name === "International" &&
                   this.props.ssrDetails &&
                   this.props.ssrDetails.response &&
                   this.props.ssrDetails.response.seat_dynamic) {
  

          const segmentSeatLength = this.props.ssrDetails.response.seat_dynamic.reduce((acc, dynamic) => {
            return acc + dynamic.segment_seat.length;
          }, 0);

          if (finalItem.seat_dynamic.length === segmentSeatLength) {
            let segmentsMatch = true;

            for (let i = 0; i < this.props.ssrDetails.response.seat_dynamic.length; i++) {
              const segmentSeats = this.props.ssrDetails.response.seat_dynamic[i].segment_seat;

              const flatRowSeats = segmentSeats.flatMap(segment => segment.row_seats);

              const segmentMatch = flatRowSeats.every(rowSeat => {
                return finalItem.seat_dynamic.some(segment => {
                  return (
                    segment.origin === rowSeat && rowSeat.seats[0].origin &&
                    segment.destination === rowSeat && rowSeat.seats[0].destination
                  );
                });
              });

              if (!segmentMatch) {
                segmentsMatch = false;
                break;
              }
            }

            if (!segmentsMatch) {
              allSegmentsMatch = false;
            }
          } else {
            allSegmentsMatch = false;
          }
        } else {
          allSegmentsMatch = false;
        }
      }
    }
  });

  // Update seatSuccess and state based on the result
  if (allSegmentsMatch) {
  
    this.setState({ seatSelected: true });
    seatSuccess = true;
  } else {
    
    this.setState({ seatSelected: false });
    seatSuccess = false;
  }


};


      updateMealSuccess();
      updateBaggageSuccess();
      updateSeatSuccess();
 
      const addPassengerSuccess = (
        (mealPresent && mealSuccess && !baggagePresent && !seatPresent) ||
        (!mealPresent && baggagePresent && baggageSuccess && seatPresent && seatSuccess) ||
        (mealPresent && mealSuccess && baggagePresent && baggageSuccess && seatPresent && seatSuccess) ||
        (mealPresent && mealSuccess && !baggagePresent && seatPresent && seatSuccess)
      );
    
    
      this.setState({
        passengers: finalArray,
        addPassengerSuccess: addPassengerSuccess,
        formikValues: values,
      }, () => {
  
        this.handleSubmitValidation(values);
      });
    
     }else {
     
      this.setState({
        passengers: finalArray,
        addPassengerSuccess: true,
        formikValues: values,
      });
     }
 
    
    let isAirAsia = (this.props.location && this.props.location.state && this.props.location.state.data &&
      this.props.location.state.data.segments &&
      this.props.location.state.data.segments[0] &&
      this.props.location.state.data.segments[0][0] &&
      this.props.location.state.data.segments[0][0].airline &&
      (this.props.location.state.data.segments[0][0].airline.airline_code === "SG")) &&
      (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === "kafila");

      let isAirAsiaR = (this.props.location && this.props.location.state && this.props.location.state.data && 
        this.props.location.state.data[this.props.location.state.index]&&
        this.props.location.state.data[this.props.location.state.index].segments&&
        this.props.location.state.data[this.props.location.state.index].segments[0] &&
        this.props.location.state.data[this.props.location.state.index].segments[0][0]&&
        this.props.location.state.data[this.props.location.state.index].segments[0][0].airline&&
        (this.props.location.state.data[this.props.location.state.index].segments[0][0].airline.airline_code === "SG")) &&
        (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] &&
          this.props.location.state.data[this.props.location.state.index].api_source === "kafila")
    //Get fare quote data
    if(
      (!this.props.type &&
        this.props.location.state.searchType === "round-trip-complete") ||
      this.props.location.state.isOutBoundVendor
    ) {
      if (
        (this.props.location.state.searchType === "round-trip-complete" ||
          this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.data[1].is_vendor !== 1 &&
        this.props.location.state.data[1].api_source === "kafila"
      ) {
        
        if(isAirAsiaR){
         
        }else{
   
        this.callKafilaFareQuote(this.props.location.state.searchType, 1);
        }
      }
    }
    if (
      (!this.props.type &&
        this.props.location.state.searchType !== "round-trip-complete") ||
      this.props.location.state.isOutBoundVendor
    ) {
      let self = this;
      if (
        this.props.location.state.data.api_source === "kafila" &&
        this.props.location.state.searchType !== "round-trip" &&
        this.props.location.state.searchType !== "lcc-special-return" &&
        this.props.location.state.searchType !== "special-return"
      ) {
      
        if(isAirAsia){
    
        }else{
        
        this.callKafilaFareQuote(this.props.location.state.searchType);
        }
      } else if (
        (this.props.location.state.searchType === "round-trip" ||
          this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.data[0].api_source === "kafila"
      ) {
   
        if(isAirAsiaR){
       
        }else{
         
        this.callKafilaFareQuote(this.props.location.state.searchType, 0);
        }
      } else if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "kafila"
      ) {
       
        this.callKafilaFareQuote(this.props.location.state.searchType, 0);
      } else if (
        this.props.location.state.isOutBoundVendor &&
        this.props.location.state.searchType === "round-trip-complete" &&
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "kafila"
      ) {
       
        this.callKafilaFareQuote(
          this.props.location.state.searchType,
          this.props.location.state.index,
          this.props.location.state.isOutBoundVendor
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
          this.props.location.state.data[0].api_source === "tripjack") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "tripjack")
      ) {
        let seatInfo = {
          bookingId:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.bookingId,
        };
        self.props.getTripJackSeatInfo(seatInfo);
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "omairiq"
      ) {
        let data = this.airIQFareQuoteData(0);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "gofly"
      ) {
        let data = this.goflyFareQuoteData(0);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "efly"
      ) {
        let data = this.eflyFareQuoteData(0);
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "satkar"
      ) {
        let data = this.satkarFareQuoteData(0);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[0].api_source === "fbq"
      ) {
    
        // let data = this.fareBoutiqueFareQuoteData(0);
        // this.props.getFareBoutiqueFareQuote(
        //   data,
        //   this.bookingError,
        //   function (response) {}
        // );
      } 
      // else if (this.props.location.state.searchType === "round-trip" && this.props.location.state.data[0].api_source === "riya_travel") {
      //   let data = this.riyaFareQuoteData(0);
      //   this.props.getRiyaFareQuote(data, this.bookingError, function (response) {
      //     // let seatInfo = {
      //     //   bookingId:
      //     //     this.props.fareQuote.response &&
      //     //     this.props.fareQuote.response.bookingId,
      //     // };
      //     // self.props.getRiyaSeatInfo(seatInfo);
      //    });
      // }
       else if (
        this.props.location.state.data.api_source === "tripjack" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
        let seatInfo = {
          bookingId:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.bookingId,
        };
        self.props.getTripJackSeatInfo(seatInfo);
      } else if (
        this.props.location.state.data.api_source === "omairiq" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
        let data = this.airIQFareQuoteData();
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        (this.props.location.state.data.api_source === "gofly" &&
        this.props.location.state.searchType !== "round-trip") 
        || (this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "gofly")
      ) {
        let data = this.goflyFareQuoteData();
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        (this.props.location.state.data.api_source === "satkar" &&
        this.props.location.state.searchType !== "round-trip") 
        || (this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "satkar")
      ) {
        let data = this.satkarFareQuoteData();
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } else if (
        // (this.props.location.state.data.api_source === "fbq" &&
        // this.props.location.state.searchType !== "round-trip") || 
        (this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "fbq")
      ) {
   
        let data = this.fareBoutiqueFareQuoteData();
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      }else if ((this.props.location.state.data.api_source === "riya_travel" && this.props.location.state.searchType !== "round-trip") || (this.props.location.state.searchType === "special-return" && this.props.location.state.data.api_source === "riya_travel")) {
        let data = this.riyaFareQuoteData();
        // this.props.getRiyaFareQuote(data, this.bookingError, function (response) {
        //   // let seatInfo = {
        //   //   bookingId:
        //   //     this.props.fareQuote.response &&
        //   //     this.props.fareQuote.response.bookingId,
        //   // };
        //   // self.props.getRiyaSeatInfo(seatInfo);
        //  });
      } else if (
        this.props.location.state.data.api_source === "efly" &&
        this.props.location.state.searchType !== "round-trip"
      ) {
        let data = this.eflyFareQuoteData();
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {}
        );
      } 
      // else {
      //   let data = this.fareQuoteData();
      //   // this.props.getFareQuote(data, this.bookingError, function (response) {
      //   //   let ssrResquest = {
      //   //     trace_id: response.trace_id,
      //   //     result_index: response.results.result_index,
      //   //   };
      //   //   self.props.getSsrDetails(ssrResquest);
      //   // });
      // }
      if (
        (this.props.location.state.searchType === "round-trip" ||
          this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.data[1].is_vendor !== 1 &&
        this.props.location.state.data[1].api_source !== "kafila" &&
        this.props.location.state.data[1].api_source !== "tripjack" &&
        this.props.location.state.data[1].api_source !== "omairiq"
      ) {
        let seatInfo = {
          bookingId:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.bookingId,
        };
        // self.props.getTripJackSeatInfo(seatInfo);
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 && this.props.location.state.data[1].is_vendor !== null &&
        this.props.location.state.data[1].api_source === "tripjack"
      ) {
        let seatInfo = {
          bookingId:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.bookingId,
        };
        self.props.getTripJackSeatInfo(seatInfo, 1);
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 &&
        this.props.location.state.data[1].api_source === "omairiq"
      ) {
        let data = this.airIQFareQuoteData(1);
        this.props.getAirIQFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].api_source === "gofly") 
        // ||
        // (this.props.location.state.searchType === "special-return" &&
        // this.props.location.state.data.api_source === "gofly")
      ) {
        let data = this.goflyFareQuoteData(1);
        this.props.getGoflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].is_vendor !== 1 &&
        this.props.location.state.data[1].api_source === "efly"
      ) {
        let data = this.eflyFareQuoteData(1);
        this.props.geteflyFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].api_source === "satkar") 
        // ||
        // (this.props.location.state.searchType === "special-return" &&
        // this.props.location.state.data.api_source === "satkar")
      ) {
        let data = this.satkarFareQuoteData(1);
        this.props.getSatkarFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      } else if (
        (this.props.location.state.searchType === "round-trip" &&
        this.props.location.state.data[1].api_source === "fbq") 
        // ||
        // (this.props.location.state.searchType === "special-return" &&
        // this.props.location.state.data.api_source === "fbq")
      ) {
      
        let data = this.fareBoutiqueFareQuoteData(1);
        this.props.getFareBoutiqueFareQuote(
          data,
          this.bookingError,
          function (response) {},
          1
        );
      }else if ((this.props.location.state.searchType === "round-trip" && this.props.location.state.data[1].api_source === "riya_travel")) {
        let data = this.riyaFareQuoteData(1);
        this.props.getRiyaFareQuote(data, this.bookingError, function (response) {
          // let seatInfo = {
          //   bookingId:
          //     this.props.fareQuote.response &&
          //     this.props.fareQuote.response.bookingId,
          // };
          // self.props.getRiyaSeatInfo(seatInfo, 1);
         }, 1);
      } 
      // else if (
      //   (this.props.location.state.searchType === "round-trip" ||
      //     this.props.location.state.searchType === "lcc-special-return") &&
      //   this.props.location.state.data[1].is_vendor !== 1 &&
      //   this.props.location.state.data[1].api_source === "kafila"
      // ) {
      //   this.callKafilaFareQuote(this.props.location.state.searchType, 1);
      // }

     
      if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source !== "kafila" &&
        this.props.location.state.data.api_source !== "omairiq" &&
        this.props.location.state.data.api_source !== "tripjack" &&
        this.props.location.state.data.api_source !== "gofly" &&
        this.props.location.state.data.api_source !== "satkar" &&
        this.props.location.state.data.api_source !== "fbq" &&
        this.props.location.state.data.api_source !== "riya_travel" &&
        this.props.location.state.data.api_source !== "tbo" &&
        this.props.location.state.data.api_source !== "efly"
      ) {
       
        let data = this.fareQuoteData(1);
        this.props.getFareQuote(
          data,
          this.bookingError,
          function (response) {
            let ssrResquest = {
              trace_id: response.trace_id,
              result_index: response.results.result_index,
            };
            self.props.getSsrDetails(ssrResquest);
          },
          1
        );
      } else if (
        this.props.location.state.searchType === "special-return" &&
        this.props.location.state.data.api_source === "kafila"
      ) {
        this.callKafilaFareQuote(this.props.location.state.searchType, 1);
      } else if (
        // (this.props.location.state.searchType === "round-trip" &&
        //   this.props.location.state.data[1].api_source === "tripjack") ||
        (this.props.location.state.searchType === "special-return" &&
          this.props.location.state.data.api_source === "tripjack")
      ) {
      
        let seatInfo = {
          bookingId:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.bookingId,
        };
        self.props.getTripJackSeatInfo(seatInfo);
      }
    }
    // this.props.getFareQuote(data,this.bookingError);
    //Save passengers data into customer list
    if (saveCustomer.length > 0) {
      this.props.addPassenger(saveCustomer);
    }

    if (values.save_company_gst_details) {
      let gstData = {
        company_gst_no: values.gst_number,
        company_registration_no: "",
        company_name: values.gst_company_name,
        company_contact_number: values.gst_company_number,
        company_address: values.gst_company_address,
        company_email: values.gst_company_email,
      };
      this.props.addCompanyGSTDetails(gstData);
    }
  };

  //Function to switch page between traveller and review booking
  switchPage = (value) => {
    this.setState({
      addPassengerSuccess: value,
      showSeatPopup: value,
    });
  };

 
  //Function to handle hold button click
  handleHold = (bookingType) => {
    let index = this.props.location.state.index;
    let segment = this.props.location.state.data;
    let searchtype =
      this.props.location.state && this.props.location.state.searchType;
    let tripJackChecking = false;
    let riyaCheckBooking = false;
    let riyaFareRule = '';
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" ||  this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      tripJackChecking = true;
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "tripjack"
    ) {
      tripJackChecking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" ||  this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "riya_travel"
    ) {
      riyaCheckBooking = true;
      riyaFareRule = this.props.riyaFlightFareRuleDetails && this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.response[0] && this.props.riyaFlightFareRuleDetails.response[0].fare_rule_detail
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "riya_travel"
    ) {
      riyaCheckBooking = true;
      if (this.props.location.state && this.props.location.state.index === 1) {
        riyaFareRule = this.props.riyaRoundFlightFareRuleDetails && this.props.riyaRoundFlightFareRuleDetails.response && this.props.riyaRoundFlightFareRuleDetails.response[index] && this.props.riyaRoundFlightFareRuleDetails.response[index].fare_rule_detail
      } else {
        riyaFareRule = this.props.riyaFlightFareRuleDetails && this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.response[0] && this.props.riyaFlightFareRuleDetails.response[0].fare_rule_detail
      }
    }

    let passengersList = getPassengerDetails(
      this.state.passengers,
      this.props.fareQuote.response,
      this.props.location
    );
    let segments = getSegments(
      this.props.fareQuote.response,
      segment,
      searchtype
    );
    let fare = getFare(this.props.fareQuote.response);
    let result_index = "";
    let admin_unique_id = "";
    let agent_unique_id = "";
    let originCity = "";
    let destinationCity = "";
    let fareRules;
   
    const { tripJackRoundFlightFareRuleDetails, tripJackFlightFareRuleDetails, location } = this.props;
        const fareRuleDetails = location.state && location.state.index === 1 ? tripJackRoundFlightFareRuleDetails : tripJackFlightFareRuleDetails;
        if (fareRuleDetails && fareRuleDetails.response && Object.keys(fareRuleDetails.response).length && Object.keys(fareRuleDetails.response)[0] !== '0') {
          fareRules = JSON.stringify(fareRuleDetails.response.fareRule);
        } else {
          fareRules = fareRuleDetails.response && fareRuleDetails.response.fareRule;
        }
    if (
      this.props.location.state.searchType === "one-way" ||
      this.props.location.state.searchType === "multi-city" ||
      this.props.location.state.searchType === "special-return"
    ) {
      //result_index = this.props.fareQuote.response.results.result_index;
      result_index =
        this.props.fareQuote &&
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results &&
        this.props.fareQuote.response.results.result_index;
      admin_unique_id = this.props.location.state.data.fare.admin_unique_id;
      agent_unique_id = this.props.location.state.data.fare.agent_unique_id;
      originCity = this.props.searchQuery.originAirportCode;
      destinationCity = this.props.searchQuery.destinationAirportCode;
    } else if (this.props.location.state.searchType === "round-trip") {
      let index = this.props.location.state.index;
      // result_index = this.props.location.state.data[index].result_index;
      result_index =
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.data &&
        this.props.location.state.data[index] &&
        this.props.location.state.data[index].result_index;
      admin_unique_id =
        this.props.location.state.data[index].fare.admin_unique_id;
      agent_unique_id =
        this.props.location.state.data[index].fare.agent_unique_id;
      originCity = this.props.searchQuery.originAirportCode;
      destinationCity = this.props.searchQuery.destinationAirportCode;
    } else if (this.props.location.state.searchType === "round-trip-complete") {
      let index = this.props.location.state.index;
      // result_index = this.props.location.state.data[index].result_index;
      result_index =
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.data &&
        this.props.location.state.data[index] &&
        this.props.location.state.data[index].result_index;
      admin_unique_id =
        this.props.location.state.data[index].fare.admin_unique_id;
      agent_unique_id =
        this.props.location.state.data[index].fare.agent_unique_id;
      originCity = this.props.searchQuery.destinationAirportCode;
      destinationCity = this.props.searchQuery.originAirportCode;
    } else if (
      this.props.location.state.searchType === "advance-search-round"
    ) {
      let index = this.props.location.state.index;
      result_index =
        this.props.location.state.priceRBD.results[0][0].result_index;
      admin_unique_id =
        this.props.location.state.data[index].fare.admin_unique_id;
      agent_unique_id =
        this.props.location.state.data[index].fare.agent_unique_id;
      originCity = this.props.searchQuery.originAirportCode;
      destinationCity = this.props.searchQuery.destinationAirportCode;
    }
    let browserInfo = {
      userAgent: navigator.userAgent,
      language: navigator.language,
    }
    
    // Extracting browser version
    const matchBrowser = navigator.userAgent.match(/(Chrome|Safari|Firefox|Edge|MSIE|Opera|Trident)\/(\d+)/);
    if (matchBrowser) {
      browserInfo.browserName = matchBrowser[1];
      browserInfo.browserVersion = matchBrowser[2];
    }
    
    // Extracting platform
    const matchPlatform = navigator.userAgent.match(/(Win|Mac|Linux|iPhone|iPad|Android)/);
    if (matchPlatform) {
      browserInfo.platform = matchPlatform[1];
    }
    
    // Extracting vendor
    const matchVendor = navigator.userAgent.match(/(Apple|Google|Microsoft|Opera)/);
    if (matchVendor) {
      browserInfo.vendor = matchVendor[1];
    }
    
    // Adjusting browser name for Edge
    if (browserInfo.browserName === "Chrome" && navigator.userAgent.includes("Edg")) {
      browserInfo.browserName = "Edge";
    }
    

    let holdData = {
      browser_info : browserInfo,
      uid: this.props.location.state.data.uid,
      trace_id: this.props.location.state.trace_id,
      result_index: result_index,
      flight_cabin_class: parseInt(
        this.props.searchQuery.segments[0].flight_cabin_class
      ),
      trip_type: parseInt(this.props.searchQuery.journey_type),
      agent_discount:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.agent_discount &&
        Number(this.props.fareQuote.response.results.fare.agent_discount),
      agent_discount_type:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.agent_discount_type &&
        this.props.fareQuote.response.results.fare.agent_discount_type,
      passengers: passengersList,
      admin_unique_id: admin_unique_id,
      agent_unique_id: agent_unique_id,
      segments: segments,
      service_charge:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.service_charge,
      cgst:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.cgst,
      igst:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.igst,
      sgst:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.sgst,
      offered_fare: parseInt(
        this.props.fareQuote.response.results.fare.offered_fare
      ),
      offered_fare_old: parseInt(
        this.props.fareQuote.response.results.fare.offered_fare_old
      ),
      published_fare_old:
        this.props.fareQuote.response.results.fare.published_fare_old,
      fare: fare,
      category_name:
        this.props.searchQuery && this.props.searchQuery.is_domestic === "true"
          ? "Domestic"
          : "International",
      tds:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.tds,
      source: this.props.location.state.data.source,
      drop_booking: bookingType === "hold" ? false : true,
      price_modified:
        this.props.fareQuote.response &&
        this.props.fareQuote.response.results.fare.price_modified,
      origin: originCity,
      destination: destinationCity,
      penalty:
        this.props.fareQuote.response && this.props.fareQuote.response.penalty,
      airline_remark:
      this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.airline_remark ||
      this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].airline_remark,

      conditions:
        this.props.fareQuote &&
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions,
      fare_type: this.props.searchQuery && this.props.searchQuery.pft,
      fare_rules: riyaCheckBooking === true ? riyaFareRule : fareRules
    };
    if (tripJackChecking === true) {
      holdData.tripjack_bookingId =
        this.props.fareQuote.response &&
        this.props.fareQuote.response.bookingId;
      holdData.booking_type = bookingType;
      if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.dc &&
        this.props.fareQuote.response.conditions.dc.idm &&
        this.props.fareQuote.response.conditions.dc.idm === true
      ) {
        holdData.di = true;
      }
    }

    if (riyaCheckBooking === true) {
   
      holdData.trackId = this.props.fareQuote.response.trackId
      holdData.bookingToken = this.props.fareQuote.response.results.bookingToken
      holdData.flightInfo =  this.getRiyaFlightDetails(this.props.fareQuote.response.results.segments)
      holdData.adult_count = this.props.searchQuery.adult_count
      holdData.child_count = this.props.searchQuery.child_count
      holdData.is_domestic = this.props.searchQuery.is_domestic
      holdData.journey_type = this.props.searchQuery.journey_type
      holdData.infant_count = this.props.searchQuery.infant_count
      holdData.api = "hold"
    }

    if (this.props.location.state.searchType === "round-trip-complete")
      holdData.outbound_booking_id = this.props.outboundPaxInfo.booking_id;

    const successCb = (response) => {
      if (response.booking_status === "ticket_confirmed") {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "confirm",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          }
        });
      } else if (bookingType === "hold") {
        if (this.props.location.state.searchType === "round-trip") {
          // this.setState({
          //     booking_id : response.booking_id
          // });
          this.props.saveFlightOutboundPaxInfo(
            this.state.formikValues,
            response.booking_id,
            response.tbo_response.flight_itinerary.fare.published_fare
          );
        }
        this.props.history.push({
          pathname: "/hold-confirm",
          state: {
            data: response,
            bookingType: "hold",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            fareQuote: this.props.fareQuote.response,
            trace_id:this.props.location.state && this.props.location.state.trace_id,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      } else if (response.booking_status === "ticket_pending") {
        this.props.history.push({
          pathname: "/hold-confirm",
          state: {
            data: response,
            bookingType: "ticket_pending",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            fareQuote: this.props.fareQuote.response,
            trace_id:this.props.location.state && this.props.location.state.trace_id,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      } else {
        if (response.booking_status === "drop_pending") {
          this.props.history.push({
            pathname: "/hold-confirm",
            state: {
              data: response,
              bookingType: "drop",
              searchType: this.props.location.state.searchType,
              searchData: this.props.location.state.data,
              initialState: this.initialState(),
              fareQuote: this.props.fareQuote.response,
              param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
            },
          });
        } 
        else {
          let bookTicket = {
            trace_id: response.trace_id,
            pnr: response.pnr,
            tbo_booking_id: parseInt(response.tbo_booking_id),
            booking_id: parseInt(response.booking_id),
            agent_discount: Number(
              response.tbo_response.flight_itinerary.fare.agent_discount
            ),
            agent_discount_type:
              response.tbo_response.flight_itinerary.fare.agent_discount_type,
            service_charge:
              this.props.fareQuote.response &&
              this.props.fareQuote.response.results.fare.service_charge,
            cgst:
              this.props.fareQuote.response &&
              this.props.fareQuote.response.results.fare.cgst,
            igst:
              this.props.fareQuote.response &&
              this.props.fareQuote.response.results.fare.igst,
            sgst:
              this.props.fareQuote.response &&
              this.props.fareQuote.response.results.fare.sgst,
            passengers:
              response && response.tbo_response.flight_itinerary.passenger,
            segments: segments,
            offered_fare:
              response &&
              Number(response.tbo_response.flight_itinerary.fare.offered_fare),
            category_name:
              this.props.searchQuery &&
              this.props.searchQuery.is_domestic === "true"
                ? "Domestic"
                : "International",
            fare_type: this.props.searchQuery && this.props.searchQuery.pft,
          
            airline_remark:
            this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.airline_remark ||
            this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].airline_remark,
      
          };
          this.bookTicket(bookTicket);
        }
      }
    };
    if (tripJackChecking === true) {
      const holdBookDetails = (response) => {
        let bookDetailRequest = {
          tripjack_booking_id: response.tripjack_response.bookingId,
          booking_id: response.flight_booking_id,
        };
        let mergeData = { ...bookDetailRequest, ...holdData };
        this.props.tripJackBookDetails(mergeData, successCb, this.bookingError);
      };
      this.props.bookTripJacKTicket(
        holdData,
        holdBookDetails,
        this.bookingError
      );
    }
     else if(riyaCheckBooking === true){
      const holdBookDetails = (response) => {
    
        let bookDetailRequest = {
          riya_booking_id:  response && response.response && response.response.data && response.response.data.booking_id,
               booking_id: response.flight_booking_id,
        };
        let mergeData = { ...bookDetailRequest, ...holdData };
        this.props.riyaBookDetails(mergeData, successCb, this.bookingError);
      };
      this.props.riyaHoldBooking(
        holdData,
        holdBookDetails,
        this.bookingError
      );
      //this.props.riyaHoldBooking(holdData, successCb, this.bookingError);
    }
    else if(riyaCheckBooking === true){
      const holdBookDetails = (response) => {
    
        let bookDetailRequest = {
          riya_booking_id:  response && response.response && response.response.data && response.response.data.booking_id,
               booking_id: response.flight_booking_id,
        };
        let mergeData = { ...bookDetailRequest, ...holdData };
        this.props.riyaBookDetails(mergeData, successCb, this.bookingError);
      };
      this.props.riyaHoldBooking(
        holdData,
        holdBookDetails,
        this.bookingError
      );
     
    }
    else {
     
      this.props.holdBooking(holdData, successCb, this.bookingError);
    }
  };

  //Function to handle Book ticket button click
  bookTicket = (data) => {

    let index = this.props.location.state.index;
    let segment = this.props.location.state.data;
    let searchtype =
      this.props.location.state && this.props.location.state.searchType;
    let kafilaCheckBooking = false;
    let airIQCheckBooking = false;
    let tripJackChecking = false;
    let goflyCheckBooking = false;
    let eflyCheckBooking = false;
    let satkarCheckBooking = false;
    let riyaCheckBooking = false;
    let fareBoutiqueBooking = false;
    let bookTicket = null;
    let self = this;
    let kafilaBookTicket = null;
    let goflyFareRule = '';
    let eflyFareRule = '';
    let omairiqFareRule = '';
    let tboFareRule = '';
    let satkarFareRule = '';
    let riyaFareRule = '';
    let fareBoutiqueFareRule = '';
  
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "kafila"
    ) {
      kafilaCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
      this.props.location.state.data[index].api_source === "kafila"
    ) {
      kafilaCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" ||  this.props.location.state.searchType === "multi-city") &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      tripJackChecking = true;
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "tripjack"
    ) {
      tripJackChecking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "omairiq"
    ) {
      airIQCheckBooking = true;
      omairiqFareRule = this.props.airIQFlightFareRuleDetails && this.props.airIQFlightFareRuleDetails.response && this.props.airIQFlightFareRuleDetails.response[0] && this.props.airIQFlightFareRuleDetails.response[0].fare_rule_detail
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "omairiq"
    ) {
      if(this.props.location.state && this.props.location.state.index === 1){
      omairiqFareRule = this.props.airIQRoundFlightFareRuleDetails && this.props.airIQRoundFlightFareRuleDetails.response && this.props.airIQRoundFlightFareRuleDetails.response[index] && this.props.airIQRoundFlightFareRuleDetails.response[index].fare_rule_detail
      }else{
      omairiqFareRule = this.props.airIQFlightFareRuleDetails && this.props.airIQFlightFareRuleDetails.response && this.props.airIQFlightFareRuleDetails.response[0] && this.props.airIQFlightFareRuleDetails.response[0].fare_rule_detail
      }
      airIQCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "gofly"
    ) {
      goflyCheckBooking = true;
      goflyFareRule = this.props.goflyFlightFareRuleDetails && this.props.goflyFlightFareRuleDetails.response && this.props.goflyFlightFareRuleDetails.response[0] && this.props.goflyFlightFareRuleDetails.response[0].fare_rule_detail
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "gofly"
    ) {
      goflyCheckBooking = true;
      if(this.props.location.state && this.props.location.state.index === 1){
      goflyFareRule = this.props.goflyRoundFlightFareRuleDetails && this.props.goflyRoundFlightFareRuleDetails.response && this.props.goflyRoundFlightFareRuleDetails.response[index] && this.props.goflyRoundFlightFareRuleDetails.response[index].fare_rule_detail
      }else{
      goflyFareRule = this.props.goflyFlightFareRuleDetails && this.props.goflyFlightFareRuleDetails.response && this.props.goflyFlightFareRuleDetails.response[0] && this.props.goflyFlightFareRuleDetails.response[0].fare_rule_detail
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "efly"
    ) {
      eflyCheckBooking = true;
      eflyFareRule = this.props.eflyFlightFareRuleDetails && this.props.eflyFlightFareRuleDetails.response && this.props.eflyFlightFareRuleDetails.response[0] && this.props.eflyFlightFareRuleDetails.response[0].fare_rule_detail
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "efly"
    ) {
      eflyCheckBooking = true;
      if(this.props.location.state && this.props.location.state.index === 1){
      eflyFareRule = this.props.eflyRoundFlightFareRuleDetails && this.props.eflyRoundFlightFareRuleDetails.response && this.props.eflyRoundFlightFareRuleDetails.response[index] && this.props.eflyRoundFlightFareRuleDetails.response[index].fare_rule_detail
      }else{
      eflyFareRule = this.props.eflyFlightFareRuleDetails && this.props.eflyFlightFareRuleDetails.response && this.props.eflyFlightFareRuleDetails.response[0] && this.props.eflyFlightFareRuleDetails.response[0].fare_rule_detail
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tbo"
    ) {
      tboFareRule = this.props.flightFareRuleDetails && this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.response[0] && this.props.flightFareRuleDetails.response[0].fare_rule_detail
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[index].api_source === "tbo"
    ) {
      if(this.props.location.state && this.props.location.state.index === 1){
      tboFareRule = this.props.roundFlightFareRuleDetails && this.props.roundFlightFareRuleDetails.response && this.props.roundFlightFareRuleDetails.response[index] && this.props.roundFlightFareRuleDetails.response[index].fare_rule_detail
      }else{
      tboFareRule = this.props.flightFareRuleDetails && this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.response[0] && this.props.flightFareRuleDetails.response[0].fare_rule_detail
      }
    }

  
   
    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "satkar") {
      satkarCheckBooking = true;
      satkarFareRule = this.props.satkarFlightFareRuleDetails && this.props.satkarFlightFareRuleDetails.response && this.props.satkarFlightFareRuleDetails.response[0] && this.props.satkarFlightFareRuleDetails.response
    }
    if ((this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") && this.props.location.state.data[index].api_source === "satkar") {
      satkarCheckBooking = true;
      if (this.props.location.state && this.props.location.state.index === 1) {
        satkarFareRule = this.props.satkarRoundFlightFareRuleDetails && this.props.satkarRoundFlightFareRuleDetails.response && this.props.satkarRoundFlightFareRuleDetails.response[index] && this.props.satkarRoundFlightFareRuleDetails.response
      } else {
        satkarFareRule = this.props.satkarFlightFareRuleDetails && this.props.satkarFlightFareRuleDetails.response && this.props.satkarFlightFareRuleDetails.response[0] && this.props.satkarFlightFareRuleDetails.response
      }
    }

    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "riya_travel") {
      riyaCheckBooking = true;
      riyaFareRule = this.props.riyaFlightFareRuleDetails && this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.response[0] && this.props.riyaFlightFareRuleDetails.response[0].fare_rule_detail
    }
    if ((this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") && this.props.location.state.data[index].api_source === "riya_travel") {
      riyaCheckBooking = true;
      if (this.props.location.state && this.props.location.state.index === 1) {
        riyaFareRule = this.props.riyaRoundFlightFareRuleDetails && this.props.riyaRoundFlightFareRuleDetails.response && this.props.riyaRoundFlightFareRuleDetails.response[index] && this.props.riyaRoundFlightFareRuleDetails.response[index].fare_rule_detail
      } else {
        riyaFareRule = this.props.riyaFlightFareRuleDetails && this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.response[0] && this.props.riyaFlightFareRuleDetails.response[0].fare_rule_detail
      }
    }

    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "fbq") {
      fareBoutiqueBooking = true;
      fareBoutiqueFareRule = this.props.fareBoutiqueFlightFareRuleDetails && this.props.fareBoutiqueFlightFareRuleDetails.response && this.props.fareBoutiqueFlightFareRuleDetails.response[0] && this.props.fareBoutiqueFlightFareRuleDetails.response
    }
    if ((this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") && this.props.location.state.data[index].api_source === "fbq") {
      fareBoutiqueBooking = true;
      if (this.props.location.state && this.props.location.state.index === 1) {
        fareBoutiqueFareRule = this.props.fareBoutiqueRoundFlightFareRuleDetails && this.props.fareBoutiqueRoundFlightFareRuleDetails.response && this.props.fareBoutiqueRoundFlightFareRuleDetails.response[index] && this.props.fareBoutiqueRoundFlightFareRuleDetails.response
      } else {
       fareBoutiqueFareRule = this.props.fareBoutiqueFlightFareRuleDetails && this.props.fareBoutiqueFlightFareRuleDetails.response && this.props.fareBoutiqueFlightFareRuleDetails.response[0] && this.props.fareBoutiqueFlightFareRuleDetails.response
      }
    }


    if (data) {

      bookTicket = data;
      kafilaBookTicket = data;
    } else {
      let passengersList = getPassengerDetails(
        this.state.passengers,
        this.props.fareQuote.response
      );
      let segments = getSegments(
        this.props.fareQuote.response,
        segment,
        searchtype
      );
      let fare = getFare(this.props.fareQuote.response);
      let result_index = "";
      let admin_unique_id = "";
      let agent_unique_id = "";
      let source = "";
      let originCity = "";
      let destinationCity = "";
      if (
        this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "multi-city" ||
        (this.props.location.state.searchType === "lcc-special-return" &&
          this.props.searchQuery.is_domestic === "false") ||
        this.props.location.state.searchType === "special-return"
      ) {
        
        result_index = this.props.fareQuote.response.results.result_index ;
        admin_unique_id = this.props.location.state.data.fare.admin_unique_id;
        agent_unique_id = this.props.location.state.data.fare.agent_unique_id;
        source = this.props.location.state.data.source;
        originCity = this.props.searchQuery.originAirportCode;
        destinationCity = this.props.searchQuery.destinationAirportCode;
      } else if (this.props.location.state.searchType === "round-trip") {
        
        let index = this.props.location.state.index;
        result_index = this.props.location.state.data[index].result_index;
        admin_unique_id =
          this.props.location.state.data[index].fare.admin_unique_id;
        agent_unique_id =
          this.props.location.state.data[index].fare.agent_unique_id;
        source = this.props.location.state.data[index].source;
        originCity = this.props.searchQuery.originAirportCode;
        destinationCity = this.props.searchQuery.destinationAirportCode;
      } else if (
        this.props.location.state.searchType === "round-trip-complete"
      ) {
        
        let index = this.props.location.state.index;
        result_index = this.props.location.state.data[index].result_index;
        admin_unique_id =
          this.props.location.state.data[index].fare.admin_unique_id;
        agent_unique_id =
          this.props.location.state.data[index].fare.agent_unique_id;
        source = this.props.location.state.data[index].source;
        originCity = this.props.searchQuery.destinationAirportCode;
        destinationCity = this.props.searchQuery.originAirportCode;
      } else if (
        this.props.location.state.searchType === "lcc-special-return" &&
        this.props.searchQuery.is_domestic === "true"
      ) {
      
        let index = this.props.location.state.index;
        result_index = this.props.location.state.data[index].result_index;
        admin_unique_id =
          this.props.location.state.data[index].fare.admin_unique_id;
        agent_unique_id =
          Number(this.props.location.state.data[0].fare.agent_unique_id) +
          Number(this.props.location.state.data[1].fare.agent_unique_id);
        source = this.props.location.state.data[index].source;
        originCity = this.props.searchQuery.originAirportCode;
        destinationCity = this.props.searchQuery.destinationAirportCode;
      } else if (
        this.props.location.state.searchType === "advance-search-round"
      ) {
        
        let index = this.props.location.state.index;
        result_index = 
          this.props.location.state.priceRBD.results[0][0].result_index;
        admin_unique_id =
          this.props.location.state.data[index].fare.admin_unique_id;
        agent_unique_id =
          this.props.location.state.data[index].fare.agent_unique_id;
        source = this.props.location.state.data.source;
        originCity = this.props.searchQuery.originAirportCode;
        destinationCity = this.props.searchQuery.destinationAirportCode;
      }

      let browserInfo = {
        userAgent: navigator.userAgent,
        language: navigator.language,
      }
      
      // Extracting browser version
      const matchBrowser = navigator.userAgent.match(/(Chrome|Safari|Firefox|Edge|MSIE|Opera|Trident)\/(\d+)/);
      if (matchBrowser) {
        browserInfo.browserName = matchBrowser[1];
        browserInfo.browserVersion = matchBrowser[2];
      }
      
      // Extracting platform
      const matchPlatform = navigator.userAgent.match(/(Win|Mac|Linux|iPhone|iPad|Android)/);
      if (matchPlatform) {
        browserInfo.platform = matchPlatform[1];
      }
      
      // Extracting vendor
      const matchVendor = navigator.userAgent.match(/(Apple|Google|Microsoft|Opera)/);
      if (matchVendor) {
        browserInfo.vendor = matchVendor[1];
      }
      
      // Adjusting browser name for Edge
      if (browserInfo.browserName === "Chrome" && navigator.userAgent.includes("Edg")) {
        browserInfo.browserName = "Edge";
      }

      
      if (kafilaCheckBooking === false) {

        bookTicket = {
          browser_info : browserInfo,
          trace_id: this.props.location.state.trace_id,
          
          result_index: result_index,
          flight_cabin_class: parseInt(
            this.props.searchQuery.segments[0].flight_cabin_class
          ),
          trip_type: this.props.searchQuery.journey_type,
          offered_fare: goflyCheckBooking === true ? (this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && this.props.fareQuote.response.results.fare.offered_fare):  parseInt(this.props.fareQuote.response.results.fare.offered_fare),
          offered_fare_old: goflyCheckBooking === true ? (this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && this.props.fareQuote.response.results.fare.offered_fare_old): parseInt(this.props.fareQuote.response.results.fare.offered_fare_old),
          published_fare_old:
            this.props.fareQuote.response.results.fare.published_fare_old,
          fare: fare,
          agent_discount:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.agent_discount &&
            Number(this.props.fareQuote.response.results.fare.agent_discount),
          agent_discount_type:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.agent_discount_type &&
            this.props.fareQuote.response.results.fare.agent_discount_type,
          passengers: passengersList,
          admin_unique_id: admin_unique_id,
          agent_unique_id: agent_unique_id,
          segments: segments,
          service_charge:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.service_charge,
          cgst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.cgst,
          igst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.igst,
          sgst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.sgst,
          category_name:
            this.props.searchQuery &&
            this.props.searchQuery.is_domestic === "true"
              ? "Domestic"
              : "International",
          tds:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.tds,
          source: source,
          price_modified:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.price_modified,
          origin: originCity,
          destination: destinationCity,
          penalty:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.penalty,
          
          airline_remark:
                this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.airline_remark ||
                this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].airline_remark,
          
          conditions:
            this.props.fareQuote &&
            this.props.fareQuote.response &&
            this.props.fareQuote.response.conditions,
          fare_type: this.props.searchQuery && this.props.searchQuery.pft,
          
        };
      } else {
        kafilaBookTicket = {
          browser_info : browserInfo,
          id:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.id,
          tripid:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.trip_id,
          refid:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.ref_id,
          admin_unique_id: admin_unique_id,
          agent_discount:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.agent_discount &&
            Number(this.props.fareQuote.response.results.fare.agent_discount),
          agent_discount_type:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.agent_discount_type &&
            this.props.fareQuote.response.results.fare.agent_discount_type,
          agent_unique_id: agent_unique_id,
          category_name:
            this.props.searchQuery &&
            this.props.searchQuery.is_domestic === "true"
              ? "Domestic"
              : "International",
          cgst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.cgst,
          destination: destinationCity,
          fare: fare,
          flight_cabin_class: parseInt(
            this.props.searchQuery.segments[0].flight_cabin_class
          ),
          igst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.igst,
          offered_fare: parseInt(
            this.props.fareQuote.response.results.fare.offered_fare
          ),
          origin: originCity,
          passengers: passengersList,
          price_modified:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.price_modified,
          result_index: this.props.location.state.data.result_index,
          segments: segments,
          service_charge:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.service_charge,
          sgst:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.sgst,
          source:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.source,
          tds:
            this.props.fareQuote.response &&
            this.props.fareQuote.response.results.fare.tds,
          trace_id: null,
          trip_type: this.props.searchQuery.journey_type,
          fare_type: this.props.searchQuery && this.props.searchQuery.pft,
          fare_rules: this.props.kafilaFlightFareRuleDetails && this.props.kafilaFlightFareRuleDetails.response && 
          this.props.kafilaFlightFareRuleDetails.response[0] && this.props.kafilaFlightFareRuleDetails.response[0].fare_rule_detail
        };
      }
      if (
        this.props.location.state.searchType === "round-trip-complete" &&
        !kafilaCheckBooking
      ) {
        bookTicket.outbound_booking_id = this.props.outboundPaxInfo.booking_id;
      } else if (kafilaCheckBooking === true) {
        kafilaBookTicket.outbound_booking_id =
          this.props.outboundPaxInfo.booking_id;
      }
      if (tripJackChecking === true) {
        bookTicket.tripjack_bookingId =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.bookingId;
        bookTicket.uid =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.results.uid;
        const { tripJackRoundFlightFareRuleDetails, tripJackFlightFareRuleDetails, location } = this.props;
        const fareRuleDetails = location.state && location.state.index === 1 ? tripJackRoundFlightFareRuleDetails : tripJackFlightFareRuleDetails;
        if (fareRuleDetails && fareRuleDetails.response && Object.keys(fareRuleDetails.response).length && Object.keys(fareRuleDetails.response)[0] !== '0') {
          bookTicket.fare_rules = JSON.stringify(fareRuleDetails.response.fareRule);
        } else {
          bookTicket.fare_rules = fareRuleDetails.response && fareRuleDetails.response.fareRule ? fareRuleDetails.response.fareRule : JSON.stringify(fareRuleDetails.response) ;
        }
        if (
          this.props.fareQuote.response &&
          this.props.fareQuote.response.conditions &&
          this.props.fareQuote.response.conditions.dc &&
          this.props.fareQuote.response.conditions.dc.idm &&
          this.props.fareQuote.response.conditions.dc.idm === true
        ) {
          bookTicket.di = true;
        }
      }
      if (airIQCheckBooking === true) {
        bookTicket.ticket_id =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.ticket_id;
          bookTicket.fare_rules = omairiqFareRule 
      }
      if (goflyCheckBooking === true) {
        bookTicket.ticket_id =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.gofly_ticket_id;
          bookTicket.fare_rules = goflyFareRule

      }
      if (eflyCheckBooking === true) {
        bookTicket.ticket_id =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.efly_ticket_id;
          bookTicket.fare_rules = eflyFareRule
      }
      if (satkarCheckBooking === true) {
        bookTicket.ticket_id =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.ticket_id;
          bookTicket.fare_rules = JSON.stringify(satkarFareRule)
      }

      if (fareBoutiqueBooking === true) {
        bookTicket.ticket_id =
          this.props.fareQuote.response &&
          this.props.fareQuote.response.ticket_id
        bookTicket.result_index = (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.result_index) ||
        (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[index].result_index)
        bookTicket.adult_count = this.props.searchQuery.adult_count
        bookTicket.child_count = this.props.searchQuery.child_count
        bookTicket.infant_count = this.props.searchQuery.infant_count
        bookTicket.booking_token = this.props.fareQuote.response && this.props.fareQuote.response.booking_token
          //bookTicket.fare_rules = JSON.stringify(fareBoutiqueFareRule)
      }

     
      if (riyaCheckBooking === true) {
        bookTicket.trackId =  this.props.fareQuote.response.trackId
          bookTicket.bookingToken = this.props.fareQuote.response.results.bookingToken
          bookTicket.flightInfo =  this.getRiyaFlightDetails(this.props.fareQuote.response.results.segments)
          bookTicket.adult_count = this.props.searchQuery.adult_count
          bookTicket.child_count = this.props.searchQuery.child_count
          bookTicket.is_domestic = this.props.searchQuery.is_domestic
          bookTicket.journey_type = this.props.searchQuery.journey_type
          bookTicket.infant_count = this.props.searchQuery.infant_count
          bookTicket.fare_rules = this.props.riyaFlightFareRuleDetails.response[0].fare_rule_detail
          bookTicket.total_amount = this.props.fareQuote.response.results.fare.offered_fare_old

         
      }
    }
    const successCb = (response) => {
      this.props.myAccountAction();
      // let self = this;
      if (this.props.location.state.searchType === "round-trip") {
        // self.setState({
        //     booking_id : response.booking_id,
        //     outboundPubFare : response.tbo_response.flight_itinerary.fare.published_fare
        // });
        this.props.saveFlightOutboundPaxInfo(
          this.state.formikValues,
          response.booking_id,
          response.tbo_response.flight_itinerary.fare.published_fare
        );
      }
      if (kafilaCheckBooking === true && response.booking_status === "ticket_pending") {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "kafila-pending",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }

      if (kafilaCheckBooking === true && response.booking_status === "ticket_confirmed") {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "kafila-confirm",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }

      if (kafilaCheckBooking === false && response.booking_status === "drop_pending") {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "drop",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            index: this.props.location.state.index,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }
      if (kafilaCheckBooking === false && response.booking_status === "ticket_pending" && !tripJackChecking) {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "ticket_pending",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            index: this.props.location.state.index,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }else if (tripJackChecking === true && response.booking_status === "ticket_pending") {
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "ticket_pending",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            index: this.props.location.state.index,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }else if (kafilaCheckBooking === false && response.booking_status !== "ticket_pending") {
        this.props.isBookingPending(false)
        this.props.history.push({
          pathname: "/booking-confirm",
          state: {
            data: response,
            bookingType: "confirm",
            searchType: this.props.location.state.searchType,
            searchData: this.props.location.state.data,
            initialState: this.initialState(),
            trace_id: this.props.location.state.trace_id,
            fareQuote: this.props.fareQuote.response,
            index: this.props.location.state.index,
            param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
          },
        });
      }
    };
    const successCb1 = (response)=>{
      this.props.myAccountAction()
      if (this.props.location.state.searchType === "round-trip") {
        this.props.saveFlightOutboundPaxInfo(
          this.state.formikValues,
          response.booking_id,
          response.tbo_response.flight_itinerary.fare.published_fare
        );
      }
      const tripjackErrorCb = (response) => {
        this.bookingError(response);
      };
      const makeTripJackBookDetailsCall = (apiCallCount) => {
        if (apiCallCount < 4 && tripJackChecking && response.booking_status === "ticket_pending") {
          this.props.isBookingPending(true);
    
          setTimeout(() => {
            this.props.isBookingPending(false);
            this.props.tripJackBookDetails(
              this.state.MergeInput,
              makeTripJackBookDetailsCall.bind(null, apiCallCount + 1),
              tripjackErrorCb
            );
          }, 5000);
        } else {
          this.props.isBookingPending(false);
          successCb(response);
        }
      };
    
      makeTripJackBookDetailsCall(0);
    };
    
    
 
    
    
    if (kafilaCheckBooking === true) {
      let data = {
        fare:
          this.props.fareQuote &&
          this.props.fareQuote.response &&
          this.props.fareQuote.response.results &&
          this.props.fareQuote.response.results.fare,
        kaf_api_respone: {
          IsFareUpdate:
            this.props.fareQuote.response.api_respone &&
            this.props.fareQuote.response.api_respone.IsFareUpdate,
          IsAncl:
            this.props.ssrKafilaDetails &&
            this.props.ssrKafilaDetails.response &&
            this.props.ssrKafilaDetails.response.api_response &&
            this.props.ssrKafilaDetails.response.api_response.IsAncl
              ? this.props.ssrKafilaDetails.response.api_response.IsAncl
              : false,
          Param:
            this.props.fareQuote.response.api_respone &&
            this.props.fareQuote.response.api_respone.Param,
          SelectedFlight:
            this.props.fareQuote.response.api_respone &&
            this.props.fareQuote.response.api_respone.SelectedFlight,
          FareBreakup:
            this.props.fareQuote.response.api_respone &&
            this.props.fareQuote.response.api_respone.FareBreakup,
          GstData:
            this.props.fareQuote.response.api_respone &&
            this.props.fareQuote.response.api_respone.GstData,
        },
      };
      let mergeData = { ...kafilaBookTicket, ...data };
      this.props.kafilaPnrCreate(
        mergeData,
        this.bookingError,
        function (response) {
          if (response.booking_type === "drop") {
            if (self.props.location.state.searchType === "round-trip") {
              self.props.saveFlightOutboundPaxInfo(
                self.state.formikValues,
                response.response.other_data.booking_id,
                response.response.flight_itinerary.fare.published_fare
              );
            }
            let kafilaUpdatesResponse = {};
            kafilaUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: kafilaUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          } else {
            let pnrRetrieveResquest = {
              kaf_booking_id: response.kafila_response.BookingId,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...pnrRetrieveResquest, ...kafilaBookTicket };
            mergeInput.showLoader = true;
            const kafilaPNRErrorCb = (response) => {
              if (self.state.pnrRetriveTimeOut < 54000) {
                let pnrRetriveTimeOut =
                  parseInt(self.state.pnrRetriveTimeOut) + 9000;
                setTimeout(function () {
                  self.setState({
                    pnrRetriveTimeOut: pnrRetriveTimeOut,
                  });
                  if (pnrRetriveTimeOut === 54000) {
                    mergeInput.showLoader = false;
                  }
                  self.props.kafilaPnrRetrieve(
                    mergeInput,
                    successCb,
                    kafilaPNRErrorCb
                  );
                }, 9000);
              } else {
                self.bookingError(response);
              }
            };
            setTimeout(function () {
              self.setState({
                pnrRetriveTimeOut: 9000,
              });
              self.props.kafilaPnrRetrieve(
                mergeInput,
                successCb,
                kafilaPNRErrorCb
              );
            }, 9000);
          }
        }
      );
    } else if (tripJackChecking === true) {
      let mergeData = { ...bookTicket };
      this.props.bookTripJacKTicket(
        mergeData,
        function (response) {
          if (response.booking_type === "drop") {
            let TripUpdatesResponse = {};
            TripUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: TripUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          } else {
           
            let bookDetailRequest = {
              tripjack_booking_id: response.tripjack_response.bookingId,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            self.setState({MergeInput:mergeInput})
            const tripjackErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.tripJackBookDetails(
              mergeInput,
              successCb1,
              tripjackErrorCb
            );
          }
        },
        this.bookingError
      );
    } else if (airIQCheckBooking === true) {
      let mergeData = { ...bookTicket };
      this.props.bookAirIQTicket(
        mergeData,
        function (response) {
          if (response.booking_type === "drop") {
            let airIqUpdatesResponse = {};
            airIqUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: airIqUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          } else {
            let bookDetailRequest = {
              airiq_booking_id: response.ariiq_response.booking_id,
              ticket_id: self.props.fareQuote.response.ticket_id,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const AirIqErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.airIQBookDetails(mergeInput, successCb, AirIqErrorCb);
          }
        },
        this.bookingError
      );
    } else if (goflyCheckBooking === true) {
      let mergeData = { ...bookTicket };
      this.props.bookGoflyTicket(
        mergeData,
        function (response) {
          if (response.booking_type === "drop") {
            let goflyUpdatesResponse = {};
            goflyUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: goflyUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          } else {
            let bookDetailRequest = {
              gofly_booking_id: response.gf_response.data.booking_id,
              ticket_id: self.props.fareQuote.response.gofly_ticket_id,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const GoflyErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.goflyBookDetails(mergeInput, successCb, GoflyErrorCb);
          }
        },
        this.bookingError
      );
    } else if (eflyCheckBooking === true) {
      let mergeData = { ...bookTicket };
      this.props.bookeflyTicket(
        mergeData,
        function (response) {
          if (response.booking_type === "drop") {
            let eflyUpdatesResponse = {};
            eflyUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: eflyUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
              },
            });
          } else {
            let bookDetailRequest = {
              efly_booking_id: response.ef_response.booking_id,
              ticket_id: self.props.fareQuote.response.efly_ticket_id,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const EflyErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.eflyBookDetails(mergeInput, successCb, EflyErrorCb);
          }
        },
        this.bookingError
      );
    } 
    else if (satkarCheckBooking === true) {
      let mergeData = { ...bookTicket };
      this.props.bookSatkarTicket(
        mergeData,
        function (response) {
          if (response.booking_type === "drop") {
            let satkarUpdatesResponse = {};
            satkarUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: satkarUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          }
           else {
            let bookDetailRequest = {
              satkar_booking_id: response.response.data.booking_id,
              ticket_id: self.props.fareQuote.response.ticket_id,
              booking_id: response.flight_booking_id,
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const satkarErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.satkarBookDetails(mergeInput, successCb, satkarErrorCb);
          }
        },
        this.bookingError
      );
    }else if (fareBoutiqueBooking === true) {
     
      let mergeData = { ...bookTicket };
      this.props.bookFareBoutiqueTicket(
        mergeData,
        function (response) {
        
          if (response.booking_type === "drop") {
     
            let fareBoutiqueUpdatesResponse = {};
            fareBoutiqueUpdatesResponse.tbo_response = response.response;
            self.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: fareBoutiqueUpdatesResponse,
                bookingType: "drop",
                searchType: self.props.location.state.searchType,
                searchData: self.props.location.state.data,
                initialState: self.initialState(),
                fareQuote: self.props.fareQuote.response,
                param_return:(this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          }
           else {
            
            let bookDetailRequest = {
              fbq_booking_id: response.response.data.booking_id,
             // ticket_id: self.props.fareQuote.response.ticket_id,
              booking_id: response.flight_booking_id,
            };
      
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
           
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const fareBoutiqueErrorCb = (response) => {
              self.bookingError(response);
            };
            self.props.fareBoutiqueBookDetails(mergeInput, successCb, fareBoutiqueErrorCb);
          }
        },
        this.bookingError
      );
    }
    else if (riyaCheckBooking === true) {
    
      let mergeData = { ...bookTicket };
   
      // Preserve the context of `this` by using arrow functions
      this.props.bookriyaTicket(
        mergeData,
        (response) => { // Arrow function preserves `this` context
          if (response.booking_type === "drop") {
            let riyaUpdatesResponse = {};
            riyaUpdatesResponse.tbo_response = response.response;
            this.props.history.push({
              pathname: "/hold-confirm",
              state: {
                data: riyaUpdatesResponse,
                bookingType: "drop",
                searchType: this.props.location.state.searchType,
                searchData: this.props.location.state.data,
                initialState: this.initialState(),
                fareQuote: this.props.fareQuote.response,
                param_return: (this.props.location.state && this.props.location.state.param_return) ? this.props.location.state.param_return : ""
              },
            });
          } else {
        
            let bookDetailRequest = {
              riya_booking_id:  response && response.response && response.response.data && response.response.data.booking_id,
               booking_id: response.flight_booking_id,
             
            };
            let mergeInput = { ...bookDetailRequest, ...bookTicket };
            mergeInput.showLoader = true;
            mergeInput.showLoader = false;
            const riyaErrorCb = (response) => {
              this.bookingError(response); // Use arrow function here as well
            };
          
            this.props.riyaBookDetails(mergeInput, successCb, riyaErrorCb);
          }
        },
        this.bookingError
      );
    }
    else {
      let data = {
        fare_rules : JSON.stringify(tboFareRule)
      }
      let mergeData = { ...bookTicket, ...data };
      this.props.bookTicket(mergeData, successCb, this.bookingError);
    }
  };

  //Function to show and hide seat selection pop up
  handleSeatPopup = (type) => {
    this.setState({
      showSeatPopup: type,
    });
  };

  //When spice jet is going to Dubai,Riyadh or Sharjah , then Passport is mandatory for all types of passenger, otherwise it is not required
  checkPaasportCity = () => {
    let searchData = this.props.searchQuery;
    let checkPaasportCity = false;
    searchData &&
      searchData.segments.map((item, index) => {
        if (
          item.destination === "DXB" ||
          item.destination === "RUH" ||
          item.destination === "SHJ"
        ) {
          checkPaasportCity = true;
        } else if (item.destinationCountryName === "Nepal") {
          //Nepal
          checkPaasportCity = "Nepal";
        }
        return true;
      });
    return checkPaasportCity;
  };

  riyaSeatSelection = (values) => {

    let typeArray = ["adult", "child", "infant"];
    let paxDetails = [];
    let paxCount = 0;

    for (let j = 0; j < 3; j++) {
      for (let i = 1; i <= this.props.searchQuery[typeArray[j] + "_count"]; i++) {
          paxCount++;
          paxDetails.push({
              PaxRefNumber: paxCount.toString(),
              Title: values["title_name_" + typeArray[j] + "_" + i] === ''
              ? (typeArray[j].toUpperCase() === 'ADULT' ? 'MR' : 'MSTR')
              : (typeArray[j].toUpperCase() === 'ADULT' && (values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === 'MSTR' || values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === "MISS")
                  ? 'MR'
                  : (values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === 'MR' 
                      ? (typeArray[j].toUpperCase() === 'ADULT' 
                          ? 'MR' 
                          : (typeArray[j].toUpperCase() === 'CHILD' || typeArray[j].toUpperCase() === 'INFANT' 
                              ? 'MSTR' 
                              : values["title_name_" + typeArray[j] + "_" + i].toUpperCase()
                          )
                      ) 
                      : (typeArray[j].toUpperCase() === 'CHILD' || typeArray[j].toUpperCase() === 'INFANT') && 
                        (values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === 'MS' || values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === 'MRS' || values["title_name_" + typeArray[j] + "_" + i].toUpperCase() === 'MR')
                        ? 'MSTR'
                        : values["title_name_" + typeArray[j] + "_" + i].toUpperCase()
                  )
              ),
            
              PaxType: typeArray[j].toUpperCase() === 'ADULT' ? 'ADT' 
                  : typeArray[j].toUpperCase() === "CHILD" ? 'CHD' 
                  : typeArray[j].toUpperCase() === "INFANT" ? "INF" : '', // Assuming ADT for adult, CHD for child, and INF for infant
              FirstName: values["first_name_" + typeArray[j] + "_" + i],
              LastName: values["last_name_" + typeArray[j] + "_" + i]
          });
      }
  }
    let segment = [];
    if (this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments) {
        this.props.fareQuote.response.results.segments.forEach(segmentsItem => {
         
            segmentsItem.forEach(item => {
                const DepartureDateTime = this.formatDate(item.origin.dep_time);
                const ArrivalDateTime = this.formatDate(item.destination.arr_time);
              
                segment.push({
                    FlightID: item && item.flightId,
                    FlightNumber: item.airline.airline_code + ' ' + item.airline.flight_number,
                    Origin: item.origin.airport.airport_code,
                    Destination: item.destination.airport.airport_code,
                    DepartureDateTime: DepartureDateTime,
                    ArrivalDateTime: ArrivalDateTime
                });
            });
        });
    }



    let data = {};
    if (this.props.location.state.searchType === "one-way") {
        data = {
            journey_type: this.props.searchQuery.journey_type,
            is_domestic: this.props.searchQuery.is_domestic,
            adult_count: this.props.searchQuery.adult_count,
            child_count: this.props.searchQuery.child_count,
            infant_count: this.props.searchQuery.infant_count,
            destination_code: this.props.searchQuery.destinationAirportCode,
            origin_code: this.props.searchQuery.originAirportCode,
            segments: this.getFareQuoteSegments(this.props.location.state.searchType),
            trackId: this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.trackId,
            FlightDetails: segment,
            paxDetails: paxDetails
        };
    } 

    if ((this.props.location.state.searchType === "round-trip")) {
      
      let index =  this.props.location && this.props.location.state && this.props.location.state.index ;
      let item = this.props.searchQuery.segments[index];
      let segmentFlight = [];
      segmentFlight.push({
        origin: item.origin,
        destination: item.destination,
        flight_cabin_class: item.flight_cabin_class,
        preferred_departure_time: item.preferred_departure_time,
        preferred_arrival_time: item.preferred_arrival_time,
      });
      data = {
          journey_type: this.props.searchQuery.journey_type,
          is_domestic: this.props.searchQuery.is_domestic,
          adult_count: this.props.searchQuery.adult_count,
          child_count: this.props.searchQuery.child_count,
          infant_count: this.props.searchQuery.infant_count,
          destination_code: this.props.searchQuery.destinationAirportCode,
          origin_code: this.props.searchQuery.originAirportCode,
          segments: segmentFlight,
          trackId: this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.trackId,
          FlightDetails: segment,
          paxDetails: paxDetails
      };
  }   
  if ((this.props.location.state.searchType === "round-trip-complete")) {
  
    let index =  this.props.location && this.props.location.state && this.props.location.state.index ;
    let item = this.props.searchQuery.segments[index];
    let segmentFlight = [];
    segmentFlight.push({
      origin: item.origin,
      destination: item.destination,
      flight_cabin_class: item.flight_cabin_class,
      preferred_departure_time: item.preferred_departure_time,
      preferred_arrival_time: item.preferred_arrival_time,
    });
    data = {
        journey_type: this.props.searchQuery.journey_type,
        is_domestic: this.props.searchQuery.is_domestic,
        adult_count: this.props.searchQuery.adult_count,
        child_count: this.props.searchQuery.child_count,
        infant_count: this.props.searchQuery.infant_count,
        destination_code: this.props.searchQuery.destinationAirportCode,
        origin_code: this.props.searchQuery.originAirportCode,
        segments: segmentFlight,
        trackId: this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.trackId,
        FlightDetails: segment,
        paxDetails: paxDetails
    };
}  
if ((this.props.location.state.searchType === "special-return")) {
      
  //let index =  this.props.location && this.props.location.state && this.props.location.state.index ;
  let items = this.props.searchQuery.segments;
  let segmentFlight = [];

  items.forEach(item => {
    segmentFlight.push({
      origin: item.origin,
      destination: item.destination,
      flight_cabin_class: item.flight_cabin_class,
      preferred_departure_time: item.preferred_departure_time,
      preferred_arrival_time: item.preferred_arrival_time,
    });
  });
  data = {
      journey_type: this.props.searchQuery.journey_type,
      is_domestic: this.props.searchQuery.is_domestic,
      adult_count: this.props.searchQuery.adult_count,
      child_count: this.props.searchQuery.child_count,
      infant_count: this.props.searchQuery.infant_count,
      destination_code: this.props.searchQuery.destinationAirportCode,
      origin_code: this.props.searchQuery.originAirportCode,
      segments: segmentFlight,
      trackId: this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.trackId,
      FlightDetails: segment,
      paxDetails: paxDetails
  };
} 
   this.props.getRiyaSeatInfo(data);
    // return data;
};

handleConfirmationPopupModel = (action, messages = []) => {
  this.setState({
    requiredField: action,
    popupMessages: messages,
  });
};



handleSubmitValidation = (values) => {
  let requiredFieldValidators =  this.props.fareQuote.response &&
  this.props.fareQuote.response.results &&
  this.props.fareQuote.response.results && this.props.fareQuote.response.results.requiredFieldValidators 

  const adultCount = this.props.searchQuery.adult_count;
  const childCount = this.props.searchQuery.child_count;
  const infantCount = this.props.searchQuery.infant_count;

  const messages = [];

  if (requiredFieldValidators.IsMealRequired && this.props.ssrDetails &&
    (this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.meal || this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.meal_dynamic) && !this.state.mealSelected) {
    messages.push("Please select meal to continue");
  }
  

  if (requiredFieldValidators.IsBaggageRequired && this.props.ssrDetails && this.props.ssrDetails.response && 
    this.props.ssrDetails.response.baggage && !this.state.baggageSelected  ) {
      if (infantCount === 0 || (infantCount >= 1 && !this.state.baggageSelected && (adultCount >= 0 || childCount >= 0))) {
        messages.push("Please select baggage to continue");
      }
    
  }

  if (requiredFieldValidators.IsSeatRequired && this.props.ssrDetails &&
    (this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.seat_preference || this.props.ssrDetails && this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic) && !this.state.seatSelected) {
      if (infantCount === 0 || (infantCount >= 1 && !this.state.seatSelected && (adultCount >= 0 || childCount >= 0))) {
      messages.push("Please select seat to continue");
      }
  }

  if (messages.length > 0) {
    this.handleConfirmationPopupModel(true, messages);
    return false;
  }

  return true;
};

  render() {
 
    let isLcc =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.is_lcc;
    let isDomestic =
      this.props.searchQuery && this.props.searchQuery.is_domestic;
    let checkPaasport = this.checkPaasportCity();
    let airline_code =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.airline_code;
    let isPassportMandatory =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.is_passport_mandatory;
    let pcs_pid =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.conditions &&
      this.props.fareQuote.response.conditions.pcs &&
      this.props.fareQuote.response.conditions.pcs.pid; /// passport issue date required or not check in fare quote for trip jack
     

      let typeArray = ["adult", "child", "infant"];
    var validationSchema = {};
    let self = this;
    let gstNumber = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/; ///^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
    let passportValidation = {
      adult: false,
      child: false,
      infant: false,
    };
    let tripJackFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
      this.props.location.state.searchType === "multi-city"||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      tripJackFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tripjack"
    ) {   
      tripJackFlight = true; 
    }
    let goflyJackFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "gofly"
    ) {
      goflyJackFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "gofly"
    ) {
      goflyJackFlight = true;
    }
    let airiqJackFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "omairiq"
    ) {
      airiqJackFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "omairiq"
    ) {
      airiqJackFlight = true;
    }
    let tboJackFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "multi-city" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tbo"
    ) {
      tboJackFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
      this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tbo"
    ) {
      tboJackFlight = true;
    }
    // let eflyJackFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "efly"
    ) {
      // eflyJackFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "efly"
    ) {
      // eflyJackFlight = true;
    }
    let kafilaFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "kafila"
    ) {  
      kafilaFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "kafila"
    ) { 
      kafilaFlight = true;
    }
    let satkarFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "satkar"
    ) {
      satkarFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") && 
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "satkar"
    ) {
      satkarFlight = true;
    }

    let fareBoutiqueFlight = false;
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "fbq"
    ) {
      fareBoutiqueFlight = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete" ||
        this.props.location.state.searchType === "lcc-special-return") && 
        this.props.location.state.index !== undefined &&
      this.props.location.state.data &&
      this.props.location.state.data[this.props.location.state.index] &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "fbq"
    ) {
      fareBoutiqueFlight = true;
    }

    let riyaJackFlight = false;
    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "riya_travel") {
      riyaJackFlight = true;
    } else if ((this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete" || this.props.location.state.searchType === "lcc-special-return") &&  this.props.location.state.index !== undefined && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel") {
      riyaJackFlight = true;
    }

    //Set dynamic initial value for passengers detail
    let initialValues = {};
    if (this.props.location.state.searchType === "round-trip-complete") {
      initialValues = this.props.outboundPaxInfo.details;
    }
    for (let j = 0; j < 3; j++) {
      for (
        let i = 1;
        i <= this.props.searchQuery[typeArray[j] + "_count"];
        i++
      ) {
        if (this.props.location.state.searchType !== "round-trip-complete") {
          initialValues["first_name_" + typeArray[j] + "_" + i] = "";
          initialValues["last_name_" + typeArray[j] + "_" + i] = "";
          initialValues["senior_citizen_id_" + typeArray[j] + "_" + i] = "";
          initialValues["gender_" + typeArray[j] + "_" + i] = "male";
          initialValues["dob_" + typeArray[j] + "_" + i] = null;
          initialValues["frequentFlyer_" + typeArray[j] + "_" + i] = "";
          initialValues["ff_airline_" + typeArray[j] + "_" + i] = "";
          initialValues["passport_" + typeArray[j] + "_" + i] = "";
          initialValues["passenger_title_" + typeArray[j] + "_" + i] = "1";
          initialValues["saveCustomer_" + typeArray[j] + "_" + i] = "";
          initialValues["title_name_" + typeArray[j] + "_" + i] = "";
          initialValues["passportExpiry_" + typeArray[j] + "_" + i] = null;
          initialValues["passportIssue_" + typeArray[j] + "_" + i] = null;
          initialValues["nationality_" + typeArray[j] + "_" + i] = "Indian";

          initialValues["baggage_" + typeArray[j] + "_" + i] = 0;
          initialValues["baggage_kg_" + typeArray[j] + "_" + i] = 0;
          initialValues["meal_" + typeArray[j] + "_" + i] = 0;
          initialValues["meal_val_" + typeArray[j] + "_" + i] = 0;
          initialValues[
            "passport_issue_country_code_" + typeArray[j] + "_" + i
          ] = "IN";
          initialValues["document_type_" + typeArray[j] + "_" + i] = "";

          initialValues["passporDaytIssue_" + typeArray[j] + "_" + i] = null;
          initialValues["passporMonthtIssue_" + typeArray[j] + "_" + i] = null;
          initialValues["passporYeartIssue_" + typeArray[j] + "_" + i] = null;

          initialValues["passportDayExpiry_" + typeArray[j] + "_" + i] = null;
          initialValues["passportMonthExpiry_" + typeArray[j] + "_" + i] = null;
          initialValues["passportYearExpiry_" + typeArray[j] + "_" + i] = null;

          initialValues["dobDay_" + typeArray[j] + "_" + i] = null;
          initialValues["dobMonth_" + typeArray[j] + "_" + i] = null;
          initialValues["dobYear_" + typeArray[j] + "_" + i] = null;
          initialValues["ssr_For_" + typeArray[j] + "_" + i] = null;
        }

        validationSchema = Object.assign(validationSchema, {
          ["first_name_" + typeArray[j] + "_" + i]: Yup.string()
            .test(
              ["first_name_" + typeArray[j] + "_" + i],
              "First Name is invalid.",
              function (value) {
                if (value === "" || !value) {
                  return false;
                } else {
                  let flag = true;
                  if (self.props.paxTitles.response) {
                    for (
                      let i = 0;
                      i < self.props.paxTitles.response.pax_titles.length;
                      i++
                    ) {
                      let titles =
                        self.props.paxTitles.response.pax_titles[i]
                          .passenger_title;
                      if (value.toLowerCase() === titles.toLowerCase())
                        flag = false;
                    }
                  }
                  if (flag) return true;
                  else return false;
                }
              }
            )
            .test(
              ["first_name_" + typeArray[j] + "_" + i],
              "Name should not be same.",
              function (value) {
                if (value === "" || !value) {
                  return false;
                } else {
                  let flag = false;
                  let firstName_1 = "";
                  let lastName_1 = "";
                  let fullName_1 = "";
                  let firstName_2 = "";
                  let lastName_2 = "";
                  let fullName_2 = "";
                  for (let m = 0; m < i; m++) {
                    firstName_1 = this.resolve(
                      Yup.ref("first_name_" + typeArray[j] + "_" + (m + 1))
                    );
                    lastName_1 = this.resolve(
                      Yup.ref("last_name_" + typeArray[j] + "_" + (m + 1))
                    );
                    fullName_1 = (firstName_1 + " " + lastName_1).toLowerCase();
                    for (let p = 0; p < i; p++) {
                      if (p !== m) {
                        firstName_2 = this.resolve(
                          Yup.ref("first_name_" + typeArray[j] + "_" + (p + 1))
                        );
                        lastName_2 = this.resolve(
                          Yup.ref("last_name_" + typeArray[j] + "_" + (p + 1))
                        );
                        fullName_2 = (
                          firstName_2 +
                          " " +
                          lastName_2
                        ).toLowerCase();
                      }
                      if (fullName_1 === fullName_2) {
                        flag = true;
                        break;
                      }
                    }
                    if (flag) {
                      break;
                    }
                  }
                  if (flag) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            )
            .matches(/^[a-zA-Z\s]+$/, "First Name is invalid.")
            .test(
              "len",
              "First Name should be 2 to 32 characters long.",
              (val) => val && val.length > 1 && val.length < 33
            ),
          // .required('First Name is required'),
          ["last_name_" + typeArray[j] + "_" + i]: Yup.string()
            .test(
              ["last_name_" + typeArray[j] + "_" + i],
              "Last Name is invalid.",
              function (value) {
                if (value === "" || !value) {
                  return false;
                } else {
                  let flag = true;
                  if (self.props.paxTitles.response) {
                    for (
                      let i = 0;
                      i < self.props.paxTitles.response.pax_titles.length;
                      i++
                    ) {
                      let titles =
                        self.props.paxTitles.response.pax_titles[i]
                          .passenger_title;
                      if (value.toLowerCase() === titles.toLowerCase())
                        flag = false;
                    }
                  }
                  if (flag) return true;
                  else return false;
                }
              }
            )
            .matches(/^[a-zA-Z\s]+$/, "Last Name is invalid.")
            .test(
              "len",
              "Last Name should be 2 to 32 characters long.",
              (val) => val && val.length > 1 && val.length < 33
            ),
          // .required('Last Name is required'),
          ["frequentFlyer_" + typeArray[j] + "_" + i]: Yup.string(),
          ["ff_airline_" + typeArray[j] + "_" + i]: Yup.string()
          .when(["frequentFlyer_" + typeArray[j] + "_" + i], {
            is: (value) => value && value.trim() !== '',
            then: Yup.string().required('Airline code is required if Frequent Flyer Number is entered'),
            otherwise: Yup.string()
          })
          .test(
            ["ff_airline_" + typeArray[j] + "_" + i],
            "Airline code seems to be invalid.",
            function (value) {
              if (value === "" || !value) {
                return true;
              } else {
                let flag = false;
                if (self.props.allAirlinesData) {
                  for (
                    let i = 0;
                    i < self.props.allAirlinesData.response.length;
                    i++
                  ) {
                    if (
                      value ===
                      self.props.allAirlinesData.response[i].airline_code
                    )
                      flag = true;
                  }
                }
                if (flag) return true;
                else return false;
              }
            }
          ),
        });

        let isDobAdult = false;
        let isDobInfant = false;
        let isDobChild = false;
        //DOB of Child and Infant are mandatory
        if (typeArray[j] !== "adult") {
          if (
            this.props.location.state.data.api_source === "tripjack" ||
            (this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index]
                .api_source === "tripjack")
          ) {
            if (typeArray[j] === "infant") {
              if (
                this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.pcs &&
                this.props.fareQuote.response.conditions.dob
              ) {
                isDobInfant = this.props.fareQuote.response.conditions.pcs.dobe;
              } else if (
                this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.dob &&
                this.props.fareQuote.response.conditions.pcs === undefined
              ) {
                isDobInfant =
                  this.props.fareQuote.response.conditions.dob.idobr;
              }
            } else if (typeArray[j] === "child") {
              if (
                this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.pcs &&
                this.props.fareQuote.response.conditions.dob
              ) {
                isDobChild = this.props.fareQuote.response.conditions.pcs.dobe;
              } else if (
                this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.dob &&
                this.props.fareQuote.response.conditions.pcs === undefined
              ) {
                isDobChild = this.props.fareQuote.response.conditions.dob.cdobr;
              }
            }
            if (typeArray[j] === "child" && isDobChild) {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Date of birth is required")
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid child age should be above 2 years.",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twentyFour = new Date(
                        date1.setMonth(date1.getMonth() - 24)
                      );
                      //twentyFour.setDate(twentyFour.getDate() - 1)
                      if (date2 > twentyFour) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid child age is less than twelve years and greater than equal to 2 years.",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twelveMonth = new Date(
                        date1.setMonth(date1.getMonth() - 144)
                      );
                      if (date2 > twelveMonth) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  ),
              });
            }
            if (typeArray[j] === "infant" && isDobInfant) {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Date of birth is required")
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twentyFour = new Date(
                        date1.setMonth(date1.getMonth() - 24)
                      );
                      twentyFour.setDate(twentyFour.getDate() + 1);
                      if (date2 < twentyFour) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      let Difference_In_Time =
                        date1.getTime() - date2.getTime();
                      let Difference_In_Days =
                        Difference_In_Time / (1000 * 3600 * 24);
                      let data = Difference_In_Days.toFixed(0);
                      if (data < 0) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  ),
              });
            }
          } else if (
            this.props.location.state.data.api_source !== "tripjack" ||
            (this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index]
                .api_source !== "tripjack")
          ) {
            if (typeArray[j] === "child") {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Date of birth is required")
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid child age should be above 2 years.",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twentyFour = new Date(
                        date1.setMonth(date1.getMonth() - 24)
                      );
                      //twentyFour.setDate(twentyFour.getDate() - 1)
                      if (date2 > twentyFour) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid child age is less than twelve years and greater than equal to 2 years.",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twelveMonth = new Date(
                        date1.setMonth(date1.getMonth() - 144)
                      );
                      if (date2 > twelveMonth) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  ),
              });
            }
            if (typeArray[j] === "infant") {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Date of birth is required")
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twentyFour = new Date(
                        date1.setMonth(date1.getMonth() - 24)
                      );
                      twentyFour.setDate(twentyFour.getDate() + 1);
                      if (date2 < twentyFour) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                    function (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      let Difference_In_Time =
                        date1.getTime() - date2.getTime();
                      let Difference_In_Days =
                        Difference_In_Time / (1000 * 3600 * 24);
                      let data = Difference_In_Days.toFixed(0);
                      if (data < 0) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  ),
              });
            }
          }
        } else {
          if (
            this.props.location.state.data.api_source === "tripjack" ||
            (this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index]
                .api_source === "tripjack")
          ) {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.pcs &&
              this.props.fareQuote.response.conditions.dob
            ) {
              isDobAdult = this.props.fareQuote.response.conditions.pcs.dobe;
            } else if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.dob &&
              this.props.fareQuote.response.conditions.pcs === undefined
            ) {
              isDobAdult = this.props.fareQuote.response.conditions.dob.adobr;
            }

            if (typeArray[j] === "adult" && isDobAdult) {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Adult age should be not be less than 12 years.",
                    function (value) {
                      if (value === null) {
                        return true;
                      } else {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_departure_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const twelveMonth = new Date(
                          date1.setMonth(date1.getMonth() - 144)
                        );
                        if (date2 <= twelveMonth) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Date of birth is required.",
                    function (value) {
                      let passportNo = this.resolve(
                        Yup.ref("passport_" + typeArray[j] + "_" + i)
                      );
                      let passportExpDate = this.resolve(
                        Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                      );
                      if (passportNo || passportExpDate) {
                        return value ? true : false;
                      } else {
                        return true;
                      }
                    }
                  ),
              });
            }
          } else if (
            this.props.location.state.data.api_source !== "tripjack" ||
            (this.props.location.state.data &&
              this.props.location.state.data[this.props.location.state.index] &&
              this.props.location.state.data[this.props.location.state.index]
                .api_source !== "tripjack")
          ) {
            if (isDomestic === "false") {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Date of birth is required")
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Adult age should be not be less than 12 years.",
                    function (value) {
                      if (value === null) {
                        return true;
                      } else {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_departure_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const twelveMonth = new Date(
                          date1.setMonth(date1.getMonth() - 144)
                        );
                        if (date2 <= twelveMonth) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Date of birth is required.",
                    function (value) {
                      let passportNo = this.resolve(
                        Yup.ref("passport_" + typeArray[j] + "_" + i)
                      );
                      let passportExpDate = this.resolve(
                        Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                      );
                      if (passportNo || passportExpDate) {
                        return value ? true : false;
                      } else {
                        return true;
                      }
                    }
                  ),
              });
            } else {
              validationSchema = Object.assign(validationSchema, {
                ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Adult age should be not be less than 12 years.",
                    function (value) {
                      if (value === null) {
                        return true;
                      } else {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_departure_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const twelveMonth = new Date(
                          date1.setMonth(date1.getMonth() - 144)
                        );
                        if (date2 <= twelveMonth) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  )
                  .test(
                    ["dob_" + typeArray[j] + "_" + i],
                    "Date of birth is required.",
                    function (value) {
                      let passportNo = this.resolve(
                        Yup.ref("passport_" + typeArray[j] + "_" + i)
                      );
                      let passportExpDate = this.resolve(
                        Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                      );
                      if (passportNo || passportExpDate) {
                        return value ? true : false;
                      } else {
                        return true;
                      }
                    }
                  ),
              });
            }
          }
        }

        //Spice Jet Passenger Name(First + Last) must be distinct
        // if(airline_code === 'SG')
        // {
        //     validationSchema = Object.assign(validationSchema,
        //         {
        //             ["first_name_"+typeArray[j]+"_"+i]: Yup.string()
        //             .required('First Name is required'),
        //             ["last_name_"+typeArray[j]+"_"+i]: Yup.string()
        //             // .notOneOf([Yup.ref("first_name_"+typeArray[j]+"_"+i),null],"First and last name can't same.")
        //             .required('Last Name is required'),
        //         })
        // }

        //If there is any GDS which is not going Nepal, then Passport is mandatory for Adult and Child.
        if (
          isDomestic === "false" &&
          checkPaasport !== "Nepal" &&
          (isLcc === false ||
            tripJackFlight === true ||
            goflyJackFlight === true ||
            airiqJackFlight === true ||
            tboJackFlight === true ||
            kafilaFlight === true ||
            satkarFlight === true ||
            fareBoutiqueFlight === true ||
            riyaJackFlight === true)
        ) {
          passportValidation = {
            adult: true,
            child: true,
            infant: true,
          };
          if (goflyJackFlight === true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
          if (airiqJackFlight === true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
          if (tboJackFlight === true) {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.results &&
              this.props.fareQuote.response.results.airline_remark &&
              this.props.fareQuote.response.results.airline_remark.includes(
                "NDC"
              )
            ) {
              validationSchema = Object.assign(validationSchema, {
                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Expriry date is required")
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Your passport is expired.",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 >
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  )
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Expiry date cannot be less than 6 months from last travel date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          const date1 = new Date(tripDate);
                          const date2 = new Date(value);
                          const sixMonthsLater = new Date(
                            date1.setMonth(date1.getMonth() + 6)
                          );
                          sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                          if (date2 >= sixMonthsLater) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      }
                    }
                  ),
               
                    ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                    .required("Passport Issue date is required")
                    .test(
                      ["passportIssue_" + typeArray[j] + "_" + i],
                      "Current date can't occur before Passport Issue Date",
                      function (value) {
                        if (!value) {
                          return true; // If no value, required validator will handle it
                        }

                        let isDomestic = self.props.searchQuery && self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") {
                          return true; // Pass validation if the trip is domestic
                        }

                        const currentDate = new Date();
                        if (new Date(value).getTime() >= currentDate.getTime()) {
                          return false; // Passport issue date must be before the current date
                        }

                        return true; // Passport issue date is valid
                      }
                ),
                // ["passport_issue_country_code_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue Country Code is required'),
                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9_]+$/,
                    "Passport number cannot contain space and special character"
                  )
                  .test(
                    ["passport_" + typeArray[j] + "_" + i],
                    "Passport is required",
                    function (value) {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else {
                        if (!value) return false;
                        else return true;
                      }
                    }
                  ),
              });
            } else {
              validationSchema = Object.assign(validationSchema, {
                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Expriry date is required")
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Your passport is expired.",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 >
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  )
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Expiry date cannot be less than 6 months from last travel date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          const date1 = new Date(tripDate);
                          const date2 = new Date(value);
                          const sixMonthsLater = new Date(
                            date1.setMonth(date1.getMonth() + 6)
                          );
                          sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                          if (date2 >= sixMonthsLater) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      }
                    }
                  ),
                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9_]+$/,
                    "Passport number cannot contain space and special character"
                  )
                  .test(
                    ["passport_" + typeArray[j] + "_" + i],
                    "Passport is required",
                    function (value) {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else {
                        if (!value) return false;
                        else return true;
                      }
                    }
                  ),
              });
            }
          }
          if (tripJackFlight === true) {
            if(pcs_pid === true){
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }else{
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                //.required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
          }
          if (kafilaFlight === true) {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.airline_remark &&
              this.props.fareQuote.response.airline_remark.includes("NDC")
            ) {
              validationSchema = Object.assign(validationSchema, {
                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Expriry date is required")
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Your passport is expired.",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 >
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  )
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Expiry date cannot be less than 6 months from last travel date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          const date1 = new Date(tripDate);
                          const date2 = new Date(value);
                          const sixMonthsLater = new Date(
                            date1.setMonth(date1.getMonth() + 6)
                          );
                          sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                          if (date2 >= sixMonthsLater) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      }
                    }
                  ),
                //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
                ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Issue date is required")
                  .test(
                    ["passportIssue_" + typeArray[j] + "_" + i],
                    "Travel date can't occur before Passport Issue Date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 <=
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  ),
                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9_]+$/,
                    "Passport number cannot contain space and special character"
                  )
                  .test(
                    ["passport_" + typeArray[j] + "_" + i],
                    "Passport is required",
                    function (value) {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else {
                        if (!value) return false;
                        else return true;
                      }
                    }
                  ),
              });
            } else {
              validationSchema = Object.assign(validationSchema, {
                ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Expriry date is required")
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Your passport is expired.",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 >
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  )
                  .test(
                    ["passportExpiry_" + typeArray[j] + "_" + i],
                    "Expiry date cannot be less than 6 months from last travel date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          const date1 = new Date(tripDate);
                          const date2 = new Date(value);
                          const sixMonthsLater = new Date(
                            date1.setMonth(date1.getMonth() + 6)
                          );
                          sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                          if (date2 >= sixMonthsLater) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      }
                    }
                  ),
                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9_]+$/,
                    "Passport number cannot contain space and special character"
                  )
                  .test(
                    ["passport_" + typeArray[j] + "_" + i],
                    "Passport is required",
                    function (value) {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else {
                        if (!value) return false;
                        else return true;
                      }
                    }
                  ),
              });
            }
          }
          if (satkarFlight === true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
                ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
          if (fareBoutiqueFlight=== true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
                ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
          if (riyaJackFlight === true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
                  //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
                  ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                  .required("Passport Issue date is required")
                  .test(
                    ["passportIssue_" + typeArray[j] + "_" + i],
                    "Travel date can't occur before Passport Issue Date",
                    function (value) {
                      if (!value) {
                        return true;
                      } else {
                        let isDomestic =
                          self.props.searchQuery &&
                          self.props.searchQuery.is_domestic;
                        if (isDomestic === "true") return true;
                        else if (value) {
                          let triplength =
                            self.props.searchQuery.segments.length;
                          let tripDate =
                            self.props.searchQuery.segments[triplength - 1]
                              .preferred_arrival_time;
                          //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                          if (
                            getValidationDate(tripDate).getTime() + 86400000 <=
                            getValidationDate(value).getTime()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }
                    }
                  ),
                ["passport_issue_country_code_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue Country Code is required'),
                ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                  .matches(
                    /^[a-zA-Z0-9_]+$/,
                    "Passport number cannot contain space and special character"
                  )
                  .test(
                    ["passport_" + typeArray[j] + "_" + i],
                    "Passport is required",
                    function (value) {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else {
                        if (!value) return false;
                        else return true;
                      }
                    }
                  ),
            });
          }
          if (
            typeArray[j] !== "infant" &&
            goflyJackFlight === false &&
            airiqJackFlight === false &&
            tboJackFlight === false &&
            kafilaFlight === false &&
            satkarFlight === false &&
            fareBoutiqueFlight === false &&
            riyaJackFlight === false &&
            tripJackFlight === false
          ) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Expriry date is required")
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              // ["passport_issue_country_code_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue Country Code is required'),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                ),
            });
          }
        }
        //For FlyDubai, Passport is mandatory for all types of Passenger.
        if (
          isDomestic === "false" &&
          (airline_code === "FZ" || airline_code === "6E")
        ) {
          passportValidation = {
            adult: true,
            child: true,
            infant: true,
          };
          if (tripJackFlight === true) {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "passport expiry date is required.",
                  function (value) {
                    let passportNo = this.resolve(
                      Yup.ref("passport_" + typeArray[j] + "_" + i)
                    );
                    if (passportNo) {
                      return value ? true : false;
                    } else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Travel date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 <=
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport no is required.",
                  function (value) {
                    let passportExpDate = this.resolve(
                      Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                    );
                    if (passportExpDate) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          } else {
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "passport expiry date is required.",
                  function (value) {
                    let passportNo = this.resolve(
                      Yup.ref("passport_" + typeArray[j] + "_" + i)
                    );
                    if (passportNo) {
                      return value ? true : false;
                    } else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              // ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport is required",
                  function (value) {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else {
                      if (!value) return false;
                      else return true;
                    }
                  }
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport no is required.",
                  function (value) {
                    let passportExpDate = this.resolve(
                      Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                    );
                    if (passportExpDate) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          }
        }

        //If Source is SpiceJet and Passenger is Adult then DOB is mandatory for international flight
        if (
          typeArray[j] === "adult" &&
          (airline_code === "SG" ||
            airline_code === "G8" ||
            airline_code === "6E" ||
            airline_code === "EK") &&
          isDomestic === "false"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        // Adult then DOB is mandatory for international tbo flight
        if (
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "tbo" &&
          isDomestic === "false"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        if (
          isDomestic === "false" &&
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "omairiq"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }
        if (
          isDomestic === "false" &&
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "gofly"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        if (
          isDomestic === "false" &&
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "satkar"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        if (
          isDomestic === "false" &&
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "fbq"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        if (isDomestic === "false" && typeArray[j] === "adult" && this.props.location.state.data.api_source === "riya_travel") {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(["dob_" + typeArray[j] + "_" + i], "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate = self.props.searchQuery.segments[triplength - 1].preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(date1.setMonth(date1.getMonth() - 144));
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }
        // If Source is Air Asia and Akasa air tbo domestic is Adult then DOB is mandatory
        if (
          typeArray[j] === "adult" &&
          this.props.location.state.data.api_source === "tbo" &&
          (airline_code === "Air Asia" || airline_code === "Akasa Air") &&
          isDomestic === "true"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }

        //If Source is AirAsia and Passenger is Adult then DOB is mandatory
        if (
          typeArray[j] === "adult" &&
          (airline_code === "IX" ||
            airline_code === "G9" ||
            airline_code === "I5" ||
            airline_code === "FD" ||
            airline_code === "AK" ||
            airline_code === "D7" ||
            airline_code === "QZ" ||
            airline_code === "QZ2" ||
            airline_code === "YY")
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Adult age should be not be less than 12 years.",
                function (value) {
                  if (value === null) {
                    return false;
                  } else {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 <= twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              ),
          });
        }
        let isInfant = false;
        let isChild = false;
        let isAdult = false;
        if (
          airline_code === "OG" ||
          airline_code === "S5" ||
          this.props.location.state.data.api_source === "tripjack" ||
          (this.props.location.state.data &&
            this.props.location.state.data[this.props.location.state.index] &&
            this.props.location.state.data[this.props.location.state.index]
              .api_source === "tripjack")
        ) {
          if (typeArray[j] === "infant") {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.pcs &&
              this.props.fareQuote.response.conditions.dob
            ) {
              isInfant = this.props.fareQuote.response.conditions.pcs.dobe;
            } else if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.dob &&
              this.props.fareQuote.response.conditions.pcs === undefined
            ) {
              isInfant = this.props.fareQuote.response.conditions.dob.idobr;
            }
          } else if (typeArray[j] === "child") {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.pcs &&
              this.props.fareQuote.response.conditions.dob
            ) {
              isChild = this.props.fareQuote.response.conditions.pcs.dobe;
            } else if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.dob &&
              this.props.fareQuote.response.conditions.pcs === undefined
            ) {
              isChild = this.props.fareQuote.response.conditions.dob.cdobr;
            }
          } else if (typeArray[j] === "adult") {
            if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.pcs &&
              this.props.fareQuote.response.conditions.dob
            ) {
              isAdult = this.props.fareQuote.response.conditions.pcs.dobe;
            } else if (
              this.props.fareQuote.response &&
              this.props.fareQuote.response.conditions &&
              this.props.fareQuote.response.conditions.dob &&
              this.props.fareQuote.response.conditions.pcs === undefined
            ) {
              isAdult = this.props.fareQuote.response.conditions.dob.adobr;
            }
          }
          if (typeArray[j] === "adult" && isAdult) {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Adult age should be not be less than 12 years.",
                  function (value) {
                    if (value === null) {
                      return false;
                    } else {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twelveMonth = new Date(
                        date1.setMonth(date1.getMonth() - 144)
                      );
                      if (date2 <= twelveMonth) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                ),
            });
          }
          if (typeArray[j] === "child" && isChild) {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid child age should be above 2 years.",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twentyFour = new Date(
                      date1.setMonth(date1.getMonth() - 24)
                    );
                    //twentyFour.setDate(twentyFour.getDate() - 1)
                    if (date2 > twentyFour) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid child age is less than twelve years and greater than equal to 2 years.",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 > twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                ),
            });
          }
          if (typeArray[j] === "infant" && isInfant) {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twentyFour = new Date(
                      date1.setMonth(date1.getMonth() - 24)
                    );
                    twentyFour.setDate(twentyFour.getDate() + 1);
                    if (date2 < twentyFour) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    let Difference_In_Time = date1.getTime() - date2.getTime();
                    let Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    let data = Difference_In_Days.toFixed(0);
                    if (data < 0) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          }
        }

        if (
          isDomestic === "false" &&
          (airline_code === "IX" ||
            airline_code === "G9" ||
            airline_code === "I5" ||
            airline_code === "FD" ||
            airline_code === "AK" ||
            airline_code === "D7" ||
            airline_code === "QZ" ||
            airline_code === "QZ2" ||
            airline_code === "YY")
        ) {
          if (tripJackFlight === true) {
           
            passportValidation = {
              adult: false,
              child: false,
              infant: false,
            };
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "passport expiry date is required.",
                  function (value) {
                    let passportNo = this.resolve(
                      Yup.ref("passport_" + typeArray[j] + "_" + i)
                    );
                    if (passportNo) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed(),
              ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().test(
                ["passportIssue_" + typeArray[j] + "_" + i],
                "Travel date can't occur before Passport Issue Date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 <=
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              ),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport no is required.",
                  function (value) {
                    let passportExpDate = this.resolve(
                      Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                    );
                    if (passportExpDate) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          } else {
      
            passportValidation = {
              adult: false,
              child: false,
              infant: false,
            };
    
            validationSchema = Object.assign(validationSchema, {
              ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "passport expiry date is required.",
                  function (value) {
                    let passportNo = this.resolve(
                      Yup.ref("passport_" + typeArray[j] + "_" + i)
                    );
                    if (passportNo) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Your passport is expired.",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                        if (
                          getValidationDate(tripDate).getTime() + 86400000 >
                          getValidationDate(value).getTime()
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                )
                .test(
                  ["passportExpiry_" + typeArray[j] + "_" + i],
                  "Expiry date cannot be less than 6 months from last travel date",
                  function (value) {
                    if (!value) {
                      return true;
                    } else {
                      let isDomestic =
                        self.props.searchQuery &&
                        self.props.searchQuery.is_domestic;
                      if (isDomestic === "true") return true;
                      else if (value) {
                        let triplength = self.props.searchQuery.segments.length;
                        let tripDate =
                          self.props.searchQuery.segments[triplength - 1]
                            .preferred_arrival_time;
                        const date1 = new Date(tripDate);
                        const date2 = new Date(value);
                        const sixMonthsLater = new Date(
                          date1.setMonth(date1.getMonth() + 6)
                        );
                        sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                        if (date2 >= sixMonthsLater) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }
                  }
                ),
              // o["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
              ["passport_" + typeArray[j] + "_" + i]: Yup.string()
                .matches(
                  /^[a-zA-Z0-9_]+$/,
                  "Passport number cannot contain space and special character"
                )
                .test(
                  ["passport_" + typeArray[j] + "_" + i],
                  "Passport no is required.",
                  function (value) {
                    let passportExpDate = this.resolve(
                      Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                    );
                    if (passportExpDate) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          }
        }

        if (
          typeArray[j] === "adult" &&
          (airline_code === "IX" || airline_code === "G9")
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "passport expiry date is required.",
                function (value) {
                  let passportNo = this.resolve(
                    Yup.ref("passport_" + typeArray[j] + "_" + i)
                  );
                  if (passportNo) {
                    return value ? true : false;
                  } else {
                    return true;
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Your passport is expired.",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 >
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Expiry date cannot be less than 6 months from last travel date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const sixMonthsLater = new Date(
                        date1.setMonth(date1.getMonth() + 6)
                      );
                      sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                      if (date2 >= sixMonthsLater) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                }
              ),
            //["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed().required('Passport Issue date is required'),
            ["passport_" + typeArray[j] + "_" + i]: Yup.string()
              .matches(
                /^[a-zA-Z0-9_]+$/,
                "Passport number cannot contain space and special character"
              )
              .test(
                ["passport_" + typeArray[j] + "_" + i],
                "passport no is required.",
                function (value) {
                  let passportExpDate = this.resolve(
                    Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                  );
                  if (passportExpDate) {
                    return value ? true : false;
                  } else {
                    return true;
                  }
                }
              ),
          });
        }

        if (
          !validationSchema["passport_" + typeArray[j] + "_" + i] ||
          !validationSchema["passportExpiry_" + typeArray[j] + "_" + i]
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["passport_" + typeArray[j] + "_" + i]: Yup.mixed().test(
              ["passport_" + typeArray[j] + "_" + i],
              "Passport no is required.",
              function (value) {
                let passportExpDate = this.resolve(
                  Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                );
                if (passportExpDate) {
                  return value ? true : false;
                } else {
                  return true;
                }
              }
            ),
            ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "passport expiry date is required.",
                function (value) {
                  let passportNo = this.resolve(
                    Yup.ref("passport_" + typeArray[j] + "_" + i)
                  );
                  if (passportNo) {
                    return value ? true : false;
                  } else {
                    return true;
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Your passport is expired.",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 >
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Expiry date cannot be less than 6 months from last travel date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const sixMonthsLater = new Date(
                        date1.setMonth(date1.getMonth() + 6)
                      );
                      sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                      if (date2 >= sixMonthsLater) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                }
              ),
          });
        }

        if (
          isDomestic === "false" &&
          checkPaasport !== "Nepal" &&
          (isLcc === false || tboJackFlight === true) &&
          (airline_code === "YY" || airline_code === "IX") &&
          this.props.location.state.data.api_source === "tbo"
        ) {
          passportValidation = {
            adult: true,
            child: true,
            infant: true,
          };
          validationSchema = Object.assign(validationSchema, {
            ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Passport Expriry date is required")
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Your passport is expired.",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 >
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Expiry date cannot be less than 6 months from last travel date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const sixMonthsLater = new Date(
                        date1.setMonth(date1.getMonth() + 6)
                      );
                      sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                      if (date2 >= sixMonthsLater) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                }
              ),
            ["passport_" + typeArray[j] + "_" + i]: Yup.string()
              .matches(
                /^[a-zA-Z0-9_]+$/,
                "Passport number cannot contain space and special character"
              )
              .test(
                ["passport_" + typeArray[j] + "_" + i],
                "Passport is required",
                function (value) {
                  let isDomestic =
                    self.props.searchQuery &&
                    self.props.searchQuery.is_domestic;
                  if (isDomestic === "true") return true;
                  else {
                    if (!value) return false;
                    else return true;
                  }
                }
              ),
          });
        }

        if (
          isDomestic === "false" &&
          tboJackFlight === true &&
          airline_code === "G9"
        ) {
          passportValidation = {
            adult: true,
            child: true,
            infant: true,
          };
          validationSchema = Object.assign(validationSchema, {
            ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Passport Expriry date is required")
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Your passport is expired.",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 >
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Expiry date cannot be less than 6 months from last travel date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const sixMonthsLater = new Date(
                        date1.setMonth(date1.getMonth() + 6)
                      );
                      sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                      if (date2 >= sixMonthsLater) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                }
              ),
            ["passport_" + typeArray[j] + "_" + i]: Yup.string()
              .matches(
                /^[a-zA-Z0-9_]+$/,
                "Passport number cannot contain space and special character"
              )
              .test(
                ["passport_" + typeArray[j] + "_" + i],
                "Passport is required",
                function (value) {
                  let isDomestic =
                    self.props.searchQuery &&
                    self.props.searchQuery.is_domestic;
                  if (isDomestic === "true") return true;
                  else {
                    if (!value) return false;
                    else return true;
                  }
                }
              ),
          });
        }

       
        if (
          isDomestic === "false" &&
          tboJackFlight === true &&
          isPassportMandatory === true
        ) {
          passportValidation = {
            adult: true,
            child: true,
            infant: true,
          };
         
          if (typeArray[j] === "child") {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid child age should be above 2 years.",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twentyFour = new Date(
                      date1.setMonth(date1.getMonth() - 24)
                    );
                    //twentyFour.setDate(twentyFour.getDate() - 1)
                    if (date2 > twentyFour) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid child age is less than twelve years and greater than equal to 2 years.",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twelveMonth = new Date(
                      date1.setMonth(date1.getMonth() - 144)
                    );
                    if (date2 > twelveMonth) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                ),
            });
          }
          if (typeArray[j] === "infant") {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    const twentyFour = new Date(
                      date1.setMonth(date1.getMonth() - 24)
                    );
                    twentyFour.setDate(twentyFour.getDate() + 1);
                    if (date2 < twentyFour) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Valid Infant age should be under 2 years till the time of return flight last segment departue date",
                  function (value) {
                    let triplength = self.props.searchQuery.segments.length;
                    let tripDate =
                      self.props.searchQuery.segments[triplength - 1]
                        .preferred_departure_time;
                    const date1 = new Date(tripDate);
                    const date2 = new Date(value);
                    let Difference_In_Time = date1.getTime() - date2.getTime();
                    let Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    let data = Difference_In_Days.toFixed(0);
                    if (data < 0) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          }

          if (typeArray[j] === "adult") {
            validationSchema = Object.assign(validationSchema, {
              ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Date of birth is required")
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Adult age should be not be less than 12 years.",
                  function (value) {
                    if (value === null) {
                      return true;
                    } else {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_departure_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const twelveMonth = new Date(
                        date1.setMonth(date1.getMonth() - 144)
                      );
                      if (date2 <= twelveMonth) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                )
                .test(
                  ["dob_" + typeArray[j] + "_" + i],
                  "Date of birth is required.",
                  function (value) {
                    let passportNo = this.resolve(
                      Yup.ref("passport_" + typeArray[j] + "_" + i)
                    );
                    let passportExpDate = this.resolve(
                      Yup.ref("passportExpiry_" + typeArray[j] + "_" + i)
                    );
                    if (passportNo || passportExpDate) {
                      return value ? true : false;
                    } else {
                      return true;
                    }
                  }
                ),
            });
          }
          validationSchema = Object.assign(validationSchema, {
            ["passportExpiry_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Passport Expriry date is required")
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Your passport is expired.",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      //86400000 miliseconds in 24 hour, validate for not to expire password on same trip date
                      if (
                        getValidationDate(tripDate).getTime() + 86400000 >
                        getValidationDate(value).getTime()
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }
              )
              .test(
                ["passportExpiry_" + typeArray[j] + "_" + i],
                "Expiry date cannot be less than 6 months from last travel date",
                function (value) {
                  if (!value) {
                    return true;
                  } else {
                    let isDomestic =
                      self.props.searchQuery &&
                      self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") return true;
                    else if (value) {
                      let triplength = self.props.searchQuery.segments.length;
                      let tripDate =
                        self.props.searchQuery.segments[triplength - 1]
                          .preferred_arrival_time;
                      const date1 = new Date(tripDate);
                      const date2 = new Date(value);
                      const sixMonthsLater = new Date(
                        date1.setMonth(date1.getMonth() + 6)
                      );
                      sixMonthsLater.setDate(sixMonthsLater.getDate() + 1);
                      if (date2 >= sixMonthsLater) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                }
              ),
            
            ["passportIssue_" + typeArray[j] + "_" + i]: Yup.mixed()
                .required("Passport Issue date is required")
                .test(
                  ["passportIssue_" + typeArray[j] + "_" + i],
                  "Current date can't occur before Passport Issue Date",
                  function (value) {
                    if (!value) {
                      return true; // If no value, required validator will handle it
                    }

                    let isDomestic = self.props.searchQuery && self.props.searchQuery.is_domestic;
                    if (isDomestic === "true") {
                      return true; // Pass validation if the trip is domestic
                    }

                    const currentDate = new Date();
                    if (new Date(value).getTime() >= currentDate.getTime()) {
                      return false; // Passport issue date must be before the current date
                    }

                    return true; // Passport issue date is valid
                  }
            ),

            ["passport_issue_country_code_" + typeArray[j] + "_" + i]:
              Yup.mixed().required("Passport Issue Country Code is required"),
            ["passport_" + typeArray[j] + "_" + i]: Yup.string()
              .matches(
                /^[a-zA-Z0-9_]+$/,
                "Passport number cannot contain space and special character"
              )
              .test(
                ["passport_" + typeArray[j] + "_" + i],
                "Passport is required",
                function (value) {
                  let isDomestic =
                    self.props.searchQuery &&
                    self.props.searchQuery.is_domestic;
                  if (isDomestic === "true") return true;
                  else {
                    if (!value) return false;
                    else return true;
                  }
                }
              ),
          });
        }
    
        if (
          typeArray[j] === "adult" &&
          tboJackFlight === true &&
          this.props.searchQuery.pft === "STUDENT" &&
          (airline_code === "6E" || airline_code === "SG")
        ) {
          validationSchema = Object.assign(validationSchema, {
            // document_type_: Yup.string()
            // .required('Student ID is required'),
            ["document_type_" + typeArray[j] + "_" + i]: Yup.string().required(
              "Student ID is required"
            ),
          });
        }
        if (
          typeArray[j] === "adult" &&
          kafilaFlight === true &&
          this.props.searchQuery.pft === "STUDENT"
        ) {
          validationSchema = Object.assign(validationSchema, {
            // document_type_: Yup.string()
            // .required('Student ID is required'),
            ["document_type_" + typeArray[j] + "_" + i]: Yup.string().required(
              "Student ID is required"
            ),
          });
        }

        if (
          typeArray[j] === "adult" &&
          this.props.searchQuery &&
          this.props.searchQuery.pft === "SENIOR_CITIZEN" &&
          (tboJackFlight === true || kafilaFlight === true || riyaJackFlight === true || tripJackFlight === true || satkarFlight === true || fareBoutiqueFlight === true || airiqJackFlight === true ||goflyJackFlight === true 
          )
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["dob_" + typeArray[j] + "_" + i]: Yup.mixed()
              .required("Date of birth is required")
              .test(
                ["dob_" + typeArray[j] + "_" + i],
                "Valid Senior Ciizen age should be grater than 60 years till the time of return flight last segment departue date1",
                function (value) {
                  let tripDate =
                    self.props.searchQuery.segments[0].preferred_departure_time;
                  const date1 = new Date(tripDate);
                  const date2 = new Date(value);
                  const sixty = new Date(
                    date1.setMonth(date1.getMonth() - 720)
                  );
                  sixty.setDate(sixty.getDate() + 1);
                  if (date2 < sixty) {
                    return true;
                  } else {
                    return false;
                  }
                }
              ),
          });
        }
        if (
          typeArray[j] === "adult" &&
          (tboJackFlight === true || kafilaFlight === true) &&
          this.props.searchQuery.pft === "SENIOR_CITIZEN"
        ) {
          validationSchema = Object.assign(validationSchema, {
            ["senior_citizen_id_" + typeArray[j] + "_" + i]:
              Yup.string().required("ID is required"),
          });
        }

      
      }
    }



    //Set dynamic validation for passengers detail
    validationSchema = Object.assign(validationSchema, {
      country: Yup.string().required("Please select country"),
      addressLine1: Yup.string().required("Please enter address"),
      mobile_no: Yup.string()
        .required("Please enter phone number")
        .test(
          "test-name",
          "Please enter valid mobile number",
          function (value) {
            var numberPattern =
              /^[+]?[(]?[0-9]{3}[)]?[-\\.]?[0-9]{3}[-\\.]?[0-9]{4,6}$/;
            return numberPattern.test(value);
          }
        ),
      email: Yup.string()
        .required("Please enter email address")
        .email("Please enter a valid email address"),
      gst_checked: Yup.string(),
    });

    let country_id = "";
    this.props.locations.response &&
      this.props.locations.response.country &&
      this.props.locations.response.country.map((item, key) => {
        if (item.country === "India") country_id = item.id;
        return true;
      });
    //Set common intital values
    if (this.props.location.state.searchType !== "round-trip-complete") {
      initialValues["gst_number"] = "";
      initialValues["gst_company_name"] = "";
      initialValues["gst_company_number"] = "";
      initialValues["gst_company_address"] = "";
      initialValues["gst_company_email"] = "";
      initialValues["save_company_gst_details"] = false;
      initialValues["email"] = this.props.accountResponse.myAccountResponse
        .response
        ? this.props.accountResponse.myAccountResponse.response.email_address
        : "";
      initialValues["addressLine1"] = this.props.accountResponse
        .myAccountResponse.response
        ? this.props.accountResponse.myAccountResponse.response.office_address
        : "";
      initialValues["addressLine2"] = "";
      initialValues["country"] = country_id !== "" ? country_id : "";
      initialValues["city"] = this.props.accountResponse.myAccountResponse
        .response
        ? this.props.accountResponse.myAccountResponse.response.city_id
        : "";
      initialValues["country_name"] = "India";
      initialValues["city_name"] = this.props.accountResponse.myAccountResponse
        .response
        ? this.props.accountResponse.myAccountResponse.response.city
        : "";
      initialValues["mobile_no"] = this.props.accountResponse.myAccountResponse
        .response
        ? this.props.accountResponse.myAccountResponse.response.mobile_number
        : "";
      initialValues["submit"] = null;
      initialValues["gst_checked"] = "";
      initialValues["country_code"] = "IN";
      initialValues["cell_country_code"] = "+91";
      initialValues["document_type_"] = "";
      initialValues["seat_preference"] = "";
    }

    //If GST is mandatory for some flights then make GST values
    /*if (this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.gst) {
            if (this.props.fareQuote.response.conditions.gst.igm === true && this.props.fareQuote.response.conditions.gst.gstappl === false) {
                validationSchema = Object.assign(validationSchema,
                    {
                        gst_number:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().required('Please enter GST number').matches(gstNumber, 'GST number is not valid'),
                                }),
                        gst_company_name:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().trim().required('Please enter GST company name'),
                                }),
                        gst_company_number:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().typeError('Please enter valid phone number').required('Please enter GST company number').matches(/^[0-9]+$/, "Must be only digits")
                                    .min(10, 'Must be exactly 10 digits')
                                    .max(10, 'Must be exactly 10 digits'),
                                }),
                        gst_company_address:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().trim().required('Please enter GST company address'),
                                }),
                        gst_company_email:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().email('Please enter a valid email'), //.required('Please enter GST company email'),
                                }),
                    });
            } else if (this.props.fareQuote.response.conditions.gst.gstappl === true && this.props.fareQuote.response.conditions.gst.igm === true) {
                validationSchema = Object.assign(validationSchema,
                    {
                        gst_number:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().required('Please enter GST number').matches(gstNumber, 'GST number is not valid'),
                                }),
                        gst_company_name:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().trim().required('Please enter GST company name'),
                                }),
                        gst_company_number:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().typeError('Please enter valid phone number').required('Please enter GST company number').matches(/^[0-9]+$/, "Must be only digits")
                                    .min(10, 'Must be exactly 10 digits')
                                    .max(10, 'Must be exactly 10 digits'),
                                }),
                        gst_company_address:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().trim().required('Please enter GST company address'),
                                }),
                        gst_company_email:
                            Yup.mixed()
                                .when("gst_checked", {
                                    is: 'show',
                                    then: Yup.string().email('Please enter a valid email').required('Please enter GST company email'),
                                }),
                    });
            }
        } else {*/
    validationSchema = Object.assign(validationSchema, {
      gst_number: Yup.mixed().when("gst_checked", {
        is: "show",
        then: Yup.string()
          .required("Please enter GST number")
          .matches(gstNumber, "GST number is not valid"),
      }),
      gst_company_name: Yup.mixed().when("gst_checked", {
        is: "show",
        then: Yup.string().trim().required("Please enter GST company name"),
      }),
      gst_company_number: Yup.mixed().when("gst_checked", {
        is: "show",
        then: Yup.string()
          .typeError("Please enter valid phone number")
          .required("Please enter GST company number")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Must be exactly 10 digits")
          .max(10, "Must be exactly 10 digits"),
      }),
      gst_company_address: Yup.mixed().when("gst_checked", {
        is: "show",
        then: Yup.string().trim().required("Please enter GST company address"),
      }),
      gst_company_email: Yup.mixed().when("gst_checked", {
        is: "show",
        then: Yup.string()
          .email("Please enter a valid email")
          .required("Please enter GST company email"),
      }),
    });

    
    
    /*}*/
    var validationSchemas = Yup.object().shape(validationSchema);
    return (
      <React.Fragment>
    
        <FlightSearchHeaderComponent {...this.props} pageType="booking" />
        {
          <Formik
            initialValues={initialValues} //Set initial values of fields to blank
            validationSchema={validationSchemas}
            onSubmit={(values) => {
          
              if((this.props.location.state.data.api_source === "tbo" ||
                (this.props.location.state.index !== undefined &&
                  this.props.location.state.data[this.props.location.state.index]
                    .api_source === "tbo"))){

                      if (values.submit === "seat") {
                        const isValid = this.handleSubmitValidation(values);
                   
                        this.handleSeatPopup(true);
                        if (!isValid) return;
                      }else{
                        const isValid = this.handleSubmitValidation(values);
                   
                        this.addPassengersValues(values);
                        if (!isValid) return;
                      }
                      
                    }
             

              if (values.submit === "seat") {
                if ((this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === 'riya_travel') 
                  ||  (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel")) {
                  this.riyaSeatSelection(values);
                }
                this.handleSeatPopup(true);
              }
              else {
                this.addPassengersValues(values);
              }
            }}
            render={(formikProps) =>
              this.props.type ? (
                <BookingConfirmComponent
                  {...formikProps}
                  {...this.props}
                  initialState={this.initialState}
                  fareQuoteData={this.fareQuoteData}
                  bookingError={this.bookingError}
                  outboundPubFare={this.state.outboundPubFare}
                  requiredField={this.state.requiredField} 
                  popupMessages={this.state.popupMessages}
                />
              ) : (
                <FlightBookTicketComponent
                  {...formikProps}
                  {...this.props}
                  handleSeatPopup={this.handleSeatPopup}
                  handleHold={this.handleHold}
                  bookTicket={this.bookTicket}
                  switchPage={this.switchPage}
                  addPassengerSuccess={this.state.addPassengerSuccess}
                  passengers={this.state.passengers}
                  showSeatPopup={this.state.showSeatPopup}
                  passportValidation={passportValidation}
                  country_id={country_id}
                  isLcc={isLcc}
                  outboundPubFare={this.state.outboundPubFare}
                  scrollToFirstError={this.scrollToFirstError}
                
                  requiredField={this.state.requiredField} 
                  popupMessages={this.state.popupMessages}
                 
                />
              )
            }
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isBookingPendingData:state.shared.isBookingPending,
    searchQuery: state.flightSearchReducer.searchQuery,
    fareQuote: state.flightBookTicketReducer.fareQuote,
    addPassengerData: state.shared.addPassenger,
    customerList: state.shared.customerList,
    ssrDetails: state.flightBookTicketReducer.ssrDetails,
    paxTitles: state.flightBookTicketReducer.paxTitles,
    locations: state.register.locations,
    country_details: state.register.countryDetails,
    productResponse: state.shared.productResponse, //get products for header
    accountResponse: state.myAccount, //my account details
    holdBookingData: state.flightBookTicketReducer.holdBookingData, //get response from hold booking
    ticketBookingData: state.flightBookTicketReducer.ticketBookingData, //get response from ticket booking
    tripJackBookDetailsData:
      state.flightBookTicketReducer.tripJackTicketBookDetails, // get response form trip Jack Book Details api
    airIQBookDetails: state.flightBookTicketReducer.airIQBookDetails, // get response form Air IQ Book Details api
    pnrCreateData: state.flightBookTicketReducer.pnrCreateData, //get response from pnr Create
    pnrRetrieveData: state.flightBookTicketReducer.pnrRetrieveData, //get response from pnr retrieve
    allAirlinesData: state.flightSearchReducer.allAirlinesData, //Get types of airlines
    companyDetails: state.flightBookTicketReducer.companyDetails, //Get company GST details
    cellCountryCode: state.flightBookTicketReducer.cellCountryCode, //Get cell Country codes
    flightFareRuleDetails:
      state.flightSearchDetailsReducer.flightFareRuleDetails,
    kafilaFlightFareRuleDetails:
      state.flightSearchDetailsReducer.kafilaFlightFareRuleDetails,
    airIQFlightFareRuleDetails:
      state.flightSearchDetailsReducer.airIQFlightFareRuleDetails,
    tripJackFlightFareRuleDetails:
      state.flightSearchDetailsReducer.tripJackFlightFareRuleDetails,
    tripJackRoundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.tripJackRoundFlightFareRuleDetails,
    roundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.roundFlightFareRuleDetails,
    kafilaRoundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.kafilaRoundFlightFareRuleDetails,
    airIQRoundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.airIQRoundFlightFareRuleDetails,
    agentProductAccess: state.shared.agentProductAccess,
    fareQuoteRound: state.flightBookTicketReducer.fareQuoteRound,
    ssrDetailsRound: state.flightBookTicketReducer.ssrDetailsRound,
    seatInfoRound: state.flightBookTicketReducer.seatInfoRound,
    seatInfo: state.flightBookTicketReducer.seatInfo,
    addCompanyGSTDetails: state.shared.addCompanyGSTDetails,
    outboundPaxInfo: state.flightBookTicketReducer.outboundPaxInfo,
    goflyFlightFareRuleDetails:
      state.flightSearchDetailsReducer.goflyFlightFareRuleDetails,
    goflyRoundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.goflyRoundFlightFareRuleDetails,
    goflyBookDetails: state.flightBookTicketReducer.goflyBookDetails, // get response form Air IQ Book Details api
    eflyFlightFareRuleDetails:
      state.flightSearchDetailsReducer.eflyFlightFareRuleDetails,
    eflyRoundFlightFareRuleDetails:
      state.flightSearchDetailsReducer.eflyRoundFlightFareRuleDetails,
    eflyBookDetails: state.flightBookTicketReducer.eflyBookDetails,
    ssrKafilaDetails: state.flightBookTicketReducer.ssrKafilaDetails,
    ssrKafilaDetailsRound: state.flightBookTicketReducer.ssrKafilaDetailsRound,
    kafilaSeatStates: state.flightBookTicketReducer.kafilaSeatState,
    satkarFlightFareRuleDetails: state.flightSearchDetailsReducer.satkarFlightFareRuleDetails,
    satkarRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.satkarRoundFlightFareRuleDetails,
    satkarBookDetails: state.flightBookTicketReducer.satkarBookDetails,
    riyaFlightFareRuleDetails: state.flightSearchDetailsReducer.riyaFlightFareRuleDetails,
    riyaRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.riyaRoundFlightFareRuleDetailsas,
    seatRiyaInfo: state.flightBookTicketReducer.seatRiyaInfo,
    riyaBookDetails : state.flightBookTicketReducer.riyaBookDetails,
    fareBoutiqueFlightFareRuleDetails: state.flightSearchDetailsReducer.fareBoutiqueFlightFareRuleDetails,
    fareBoutiqueRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.fareBoutiqueRoundFlightFareRuleDetails,
    fareBoutiqueBookDetails : state.flightBookTicketReducer.fareBoutiqueBookDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    flightSearchSaveQuery: (formData) =>
      dispatch(flightSearchSaveQuery(formData)),
    getFareQuote: (data, errorcb, callback, index) =>
      dispatch(getFareQuote(data, errorcb, callback, index)), //get flight fare details
    getTripJackFareQuote: (data, errorcb, callback, index) =>
      dispatch(getTripJackFareQuote(data, errorcb, callback, index)), //get Trip Jack flight fare details
    getKafilaFareQuote: (data, errorcb, callback, index) =>
      dispatch(getKafilaFareQuote(data, errorcb, callback, index)), //get flight fare details
    getAirIQFareQuote: (data, errorcb, callback, index) =>
      dispatch(getAirIQFareQuote(data, errorcb, callback, index)),
    addPassenger: (data, successcb, errorcb) =>
      dispatch(addPassenger(data, successcb, errorcb)), //add passenger in customer list
    getCustomerList: () => dispatch(getCustomerList()), //get customer list
    getSsrDetails: (data, index, errorcb) =>
      dispatch(getSsrDetail(data, index, errorcb)), //get baggage,meal and seat preferences
    getTripJackSeatInfo: (data, index, errorcb) =>
      dispatch(getTripJackSeatInfo(data, index, errorcb)), //get baggage,meal and seat preferences
    getPaxTitle: () => dispatch(getPaxTitle()), //get pax titles
    getlocation: () => dispatch(getlocation()), //get country ,state and city
    getCountryDetails: () => dispatch(getCountryDetails()), //For get all country details like Nationality, Short name
    getAllAirlines: (credentials) => dispatch(getAllAirlines(credentials)),
    bookTicket: (credentials, successCb, errorcb) =>
      dispatch(bookTicket(credentials, successCb, errorcb)), //start ticket booking
    bookTripJacKTicket: (credentials, successCb, errorcb) =>
      dispatch(bookTripJacKTicket(credentials, successCb, errorcb)), //start trip jack ticket booking
    validateTripJackTicket: (credentials, successCb, errorcb) =>
      dispatch(validateTripJackTicket(credentials, successCb, errorcb)),
    tripJackBookConfirm: (credentials, successCb, errorcb) =>
      dispatch(tripJackBookConfirm(credentials, successCb, errorcb)),
    tripJackBookDetails: (credentials, successCb, errorcb) =>
      dispatch(tripJackBookDetails(credentials, successCb, errorcb)), //Tripjack book details
    bookAirIQTicket: (credentials, successCb, errorcb) =>
      dispatch(bookAirIQTicket(credentials, successCb, errorcb)), //start airIQ jack ticket booking
    airIQBookDetails: (credentials, successCb, errorcb) =>
      dispatch(airIQBookDetails(credentials, successCb, errorcb)), //AirIQ book details
    kafilaPnrCreate: (data, errorcb, callback) =>
      dispatch(kafilaPnrCreate(data, errorcb, callback)),
    kafilaPnrRetrieve: (data, successCb, errorcb) =>
      dispatch(kafilaPnrRetrieve(data, successCb, errorcb)),
    holdBooking: (credentials, successCb, errorcb) =>
      dispatch(holdBooking(credentials, successCb, errorcb)),
    myAccountAction: () => dispatch(myAccountAction()),
    getCompanyDetail: () => dispatch(getCompanyDetail()),
    getCellCountryCode: () => dispatch(getCellCountryCode()),
    getFlightFareRuleDetails: (credential, index) =>
      dispatch(getFlightFareRuleDetails(credential, index)),
    getKafilaFlightFareRuleDetails: (credential, index) =>
      dispatch(getKafilaFlightFareRuleDetails(credential, index)),
    getAirIQFlightFareRuleDetails: (credential, index) =>
      dispatch(getAirIQFlightFareRuleDetails(credential, index)),
    getTripJackFlightFareRuleDetails: (credential, index) =>
      dispatch(getTripJackFlightFareRuleDetails(credential, index)),
    setFareQuoteValue: (response) => dispatch(setFareQuoteValue(response)),
    setSSRValue: (credential) => dispatch(setSSRValue(credential)),
    setTripJackSeatInfo: (credential) =>
      dispatch(setTripJackSeatInfo(credential)),
    setTripJackFlightFareRuleDetails: (credential) =>
      dispatch(setTripJackFlightFareRuleDetails(credential)),
    setAirIQFlightFareRuleDetails: (credential) =>
      dispatch(setAirIQFlightFareRuleDetails(credential)),
    setKafilaFlightFareRuleDetails: (credential) =>
      dispatch(setKafilaFlightFareRuleDetails(credential)),
    setFlightFareRuleDetails: (credential) =>
      dispatch(setFlightFareRuleDetails(credential)),

    addCompanyGSTDetails: (data, successcb, errorcb) =>
      dispatch(addCompanyGSTDetails(data, successcb, errorcb)), //add company GST Details
    saveFlightOutboundPaxInfo: (data, bookig_id, outboundPub) =>
      dispatch(saveFlightOutboundPaxInfo(data, bookig_id, outboundPub)), //Save outbound booking pax details for showing pax details prefilled in inbound details
    getGoflyFlightFareRuleDetails: (credential, index) =>
      dispatch(getGoflyFlightFareRuleDetails(credential, index)),
    getGoflyFareQuote: (data, errorcb, callback, index) =>
      dispatch(getGoflyFareQuote(data, errorcb, callback, index)),
    bookGoflyTicket: (credentials, successCb, errorcb) =>
      dispatch(bookGoflyTicket(credentials, successCb, errorcb)),
    goflyBookDetails: (credentials, successCb, errorcb) =>
      dispatch(goflyBookDetails(credentials, successCb, errorcb)),
    geteflyFareQuote: (data, errorcb, callback, index) =>
      dispatch(geteflyFareQuote(data, errorcb, callback, index)), // get efly fare quote details
    geteflyFlightFareRuleDetails: (credential, index) =>
      dispatch(geteflyFlightFareRuleDetails(credential, index)), // get efly fare rule details
    bookeflyTicket: (credentials, successCb, errorcb) =>
      dispatch(bookeflyTicket(credentials, successCb, errorcb)), // efly booking ticket
    eflyBookDetails: (credentials, successCb, errorcb) =>
      dispatch(eflyBookDetails(credentials, successCb, errorcb)), // get efly book details
    getKafilaSsrDetail: (data, index, errorcb) =>
      dispatch(getKafilaSsrDetail(data, index, errorcb)), //get baggage,meal and seat preferences
    isBookingPending : (response) => dispatch(isBookingPending(response)),
    kafilaSeatState : (response) => dispatch(kafilaSeatState(response)),
    // satkar
    getSatkarFlightFareRuleDetails: (credential, index) =>dispatch(getSatkarFlightFareRuleDetails(credential, index)),
    getSatkarFareQuote: (data, errorcb, callback, index) =>dispatch(getSatkarFareQuote(data, errorcb, callback, index)),
    bookSatkarTicket: (credentials, successCb, errorcb) =>dispatch(bookSatkarTicket(credentials, successCb, errorcb)),
    satkarBookDetails: (credentials, successCb, errorcb) => dispatch(satkarBookDetails(credentials, successCb, errorcb)),
    //riya
    getRiyaFlightFareRuleDetails: (credential, index) => dispatch(getRiyaFlightFareRuleDetails(credential, index)),
    getRiyaFareQuote: (data, errorcb, callback, index) => dispatch(getRiyaFareQuote(data, errorcb, callback, index)),
    getRiyaSeatInfo: (data, index, errorcb) => dispatch(getRiyaSeatInfo(data, index, errorcb)), //get baggage,meal and seat preferences
    getKafilaSsrStart: () => dispatch(getKafilaSsrStart()),
    bookriyaTicket: (credentials, successCb, errorcb) =>
      dispatch(bookriyaTicket(credentials, successCb, errorcb)), //start riya ticket booking
    riyaBookDetails: (credentials, successCb, errorcb) =>
      dispatch(riyaBookDetails(credentials, successCb, errorcb)), //riya book details
    riyaHoldBooking: (credentials, successCb, errorcb) =>
      dispatch(riyaHoldBooking(credentials, successCb, errorcb)),
    riyaBookConfirm: (credentials, successCb, errorcb) =>
      dispatch(riyaBookConfirm(credentials, successCb, errorcb)),
    setRiyaFlightFareRuleDetails: (credential) =>
      dispatch(setRiyaFlightFareRuleDetails(credential)),
    //fare boutique 
    getFareBoutiqueFlightFareRuleDetails: (credential, index) =>dispatch(getFareBoutiqueFlightFareRuleDetails(credential, index)),
    getFareBoutiqueFareQuote: (data, errorcb, callback, index) =>dispatch(getFareBoutiqueFareQuote(data, errorcb, callback, index)),
    bookFareBoutiqueTicket:(credentials, successCb, errorcb) =>dispatch(bookFareBoutiqueTicket(credentials, successCb, errorcb)),
    fareBoutiqueBookDetails: (credentials, successCb, errorcb) => dispatch(fareBoutiqueBookDetails(credentials, successCb, errorcb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightBookTicketContainer);
