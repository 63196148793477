import {
    HOTEL_SEARCH_SAVE_QUERY,
} from '../../actions/hotel/hotelSearchAction';

export const initialState = {
    searchQuery: JSON.parse(sessionStorage.getItem('hotelSearchQuery')) || {
        "check_in_date": "15/04/2019",
        "no_of_nights": "1",
        "country_code": "NL",
        "city_id": "14621",
        "result_count": null,
        "preferred_currency": "INR",
        "guest_nationality": "IN",
        "no_of_rooms": "1",
        "room_guests": [
          {
                   "no_of_adults": 1,
                   "no_of_child": 0,
                   "child_age": null
          }
        ],
        "preferred_hotel": "",
        "max_rating": 5,
        "min_rating": 0,
        "review_score": null,
        "is_nearby_search_allowed": false
    },
};

export function hotelSearchReducer(state = initialState, action) {
    switch (action.type) {
        case HOTEL_SEARCH_SAVE_QUERY :
            sessionStorage.setItem("hotelSearchQuery", JSON.stringify(action.hotelSerarchQuery))
            return {
                ...state,
                searchQuery: action.hotelSerarchQuery
            }
        default:
            return state;
    }
}