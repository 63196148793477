import React, {Component} from 'react';
import Link from "react-router-dom/es/Link";
import { Field } from 'formik';

class CompanyDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader  : false,
            gstCertificateClass : false,
            selectedFile : null,
            thumb: undefined,
            gstFlage:false,
        };
    }

    continueHandler=()=>{
        this.setState( {
            loader : true
        });
        const self = this;
        setTimeout(function(){ self.props.handleValues(); }, 1000);
    };

    handleGst(event){
        this.setState({
            gstCertificateClass : event.checked ? true : false
        });
    };
    render() {
        const {values, errors, touched, handleBlur, handleChange ,setFieldValue ,setFieldTouched} = this.props;
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Company Name<span
                            className="red-error">*</span></label>
                        <div className="control-group">
                            <Field className="form-control" type="text" value={values.company_name} placeholder="(ex. abc pvt ltd)" name="company_name" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.company_name && touched.company_name && <span className="error-msg">{errors.company_name}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <label className="control-label">Type of Ownership<span className="red-error">*</span></label>
                        <div className="control-group">
                            <div className="select-custom">
                                <select className="form-control" name="ownershipType" onChange={handleChange} onBlur={handleBlur} value={values.ownershipType}>
                                    <option>Select Type of Ownership</option>
                                    {
                                        this.props.ownerShip.response && this.props.ownerShip.response.ownerships && 
                                        this.props.ownerShip.response.ownerships.map((item,key) => {
                                                return (
                                                    <option key={key} value={item.type_id}>{item.ownership_type}</option>
                                                );
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {errors.ownershipType && touched.ownershipType && <span className="error-msg">{errors.ownershipType}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-block">
                        <div className="control-group">
                            <div className="checklabel">
                                <span>GST</span>
                                <small className="checkbox">
                                    <Field type="checkbox" name="check_gst" onChange={(e) => { this.handleGst(e.target)}}/>
                                    <label></label>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={"form-block "+(this.state.gstCertificateClass === false ? 'disabled-section':'')} >
                        <label className="control-label">GST Number</label>
                        <div className="control-group">
                            <Field className="form-control" type="text" placeholder="(ex. 304001)" name="gst_no" onBlur={handleBlur} onChange={handleChange} />
                        </div>
                        {errors.gst_no && touched.gst_no && <span className="error-msg">{errors.gst_no}</span>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={"form-block "+(this.state.gstCertificateClass === false ? 'disabled-section':'')} >
                        <label className="control-label">GST Certificate<span className="red-error">*</span></label>
                        <div className="input-group">
                            <div className="file-upload-filed">
                                <div className="file-btn">Upload File
                                    <input type="file" id="upload" name="gst_certificate" onChange={(event) => {
                                         if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                         {
                                            setFieldTouched('gst_certificate_file');
                                            setFieldValue("gst_certificate_file", event.currentTarget.files[0]);
                                            this.props.uploadFile(this,'gst_certificate',event.currentTarget.files[0]);
                                            this.setState({gstFlage:true});
                                         }
                                         else{
                                            this.setState({gstFlage:false});
                                            setFieldValue("gst_certificate_file", "");
                                            setFieldValue("gst_certificate", "");
                                         }
                                    }}/>
                                 </div>
                                <div className="file_name">{this.state.gstFlage && values.gst_certificate_file.name}</div>
                            </div>
                        </div>
                        {errors.gst_certificate_file && touched.gst_certificate_file && <span className="error-msg">{errors.gst_certificate_file}</span>}
                    </div>
                </div>
                <div className="form-btn-block">
				{ /* eslint-disable-next-line */} 
                    <a className="btn btn-dark" href="/" onClick={(e)=>{e.preventDefault();this.props.back()}}>
                    <i className="fa fa-arrow-left"></i>
                    <span>Back</span>
                    </a>
					{ /* eslint-disable-next-line */} 
                    <a href="/" className={`btn btn-orange loading-btn ${values.company_name && values.ownershipType && (this.state.gstCertificateClass === true ? values.gst_certificate && values.gst_certificate_file && values.gst_no : true)
                        ? "" : "disabled" }`}  onClick={(e)=>{e.preventDefault();this.continueHandler()}}><span>Continue</span>
                        {
                            this.state.loader
                                ?
                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                <img src="/assets/images/flight-continue.svg" alt=""/></label></div>
                                :''
                        }
                    </a>
                    <Link to={'/login'} className="btn btn-grey">Cancel</Link>
                </div>
            </div>
        );
    }
}

export default CompanyDetail; 