import React, { Component } from 'react';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import history from "../../shared/history";

class AgentBankDetail extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/bank-detail',
          url: history.location.pathname
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    render() {
        const { accountResponse,productResponse,pagesResponse} = this.props;
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                <div className="profile-detail-info">
                                    <div className="edit-profile-bar">
                                        <div className="pull-right">
                                            { /* eslint-disable-next-line */}
                                            <a href="" onClick={(e) => {e.preventDefault()}} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                        </div>
                                    </div>
                                    <div className="tabs-container-detail">
                                        <div className="statement-info">
                                            <div className="tabs-top">
                                                <div className="tabs-title">
                                                    <h2><i className="fa fa-bank"></i><span>Bank Detail</span></h2>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['bank_detail'].page_description }} />
                                            </div>
                                        <div className="statement-outer">
                                            <div className="balance-sheet-info bank-detail-table">
                                                    <div className="balance-table">
                                                        <div className="table-outer">
                                                            <div className="table-container">
                                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>S.No.</th>
                                                                            <th>Bank Name</th>
                                                                            <th>Address</th>
                                                                            <th>Account Name</th>
                                                                            <th>Account No.</th>
                                                                            <th>Ifsc Code</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        this.props.agentBankDetails.response && 
                                                                        this.props.agentBankDetails.response.response.length > 0 ? 
                                                                        this.props.agentBankDetails.response.response.map((item,key) => {
                                                                            return (
                                                                                <React.Fragment key={key}>
                                                                                    <tr>
                                                                                        <td>{key + 1}</td>
                                                                                        <td>{item.bank_name}</td>
                                                                                        <td>{item.bank_address}</td>
                                                                                        <td>{item.account_name}</td>
                                                                                        <td>{item.account_number}</td>
                                                                                        <td>{item.ifsc_code}</td>
                                                                                    </tr>
                                                                                    {
                                                                                        item.remarks !== '' ?
                                                                                        <tr>
                                                                                            <td colSpan="100%" className="list-td"><p>{item.remarks}</p></td>
                                                                                        </tr>
                                                                                        : ''
                                                                                    }
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                        :  
                                                                        <tr><td colSpan="9"><span className="no-records">There is no Bank Detail found.</span></td></tr>
                                                                    } 
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default (AgentBankDetail)