/* external dependencies */
import React from 'react'
import {Formik} from "formik";
import UserSubscribeForm from '../../components/shared/UserSubscribeComponent';
import { connect } from 'react-redux'
import { subscribeAction } from '../../actions/shared/sharedAction';

class UserSubscribe extends React.Component {
   
    handleSubscribeSubmit = (values) => {
        this.props.subscribeAction(values);
    };

    render() {
        return (
            <Formik
                initialValues={{email: ''}}
                onSubmit={(values) => this.handleSubscribeSubmit(values)}
                render={(formikProps) =>
                    <UserSubscribeForm
                        {...formikProps}
                        {...this.props}
                    />
                }
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscribeResponse: state.shared.subscribeResponse
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        subscribeAction: (credentials) => dispatch(subscribeAction(credentials)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(UserSubscribe)