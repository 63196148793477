import React, { Component } from 'react';
import { getCalendarDetail,bookingInfoAction } from '../../actions/agentAction';
import { connect } from 'react-redux';
// import PrintInvoice from '../../components/agent/PrintInvoice';
import PrintOfflineBookingInvoice from '../../components/agent/PrintOfflineBookingInvoice';

class PrintInvoiceContainer extends Component {
    componentDidMount() {
        let data = localStorage.getItem('print-invoice-data');
        let booking_id = JSON.parse(data).booking_id;
        const booking = {
            "booking_id": parseInt(booking_id)
        };
        // if(JSON.parse(data).booking_type === 'offline' || JSON.parse(data).booking_type === 'drop'){
        this.props.bookingInfoAction(booking);
        // }
        // else{
        //     const bookingData = {
        //         "tbo_booking_id": parseInt(JSON.parse(data).tbo_booking_id),
        //         "pnr":JSON.parse(data).pnr
        //     };
        //     this.props.getBookingDetails(bookingData);
        // }
    }
    render() {
        let data = localStorage.getItem('print-invoice-data');
        let bookingData = null;
        // if(JSON.parse(data).booking_type === 'offline'){
        //if(this.props.calendarResponse.response){
                if(this.props.bookingInfoResponse.response){
                for(let i = 0;i<this.props.bookingInfoResponse.response.length ; i++){
                    for(let j=0;j<this.props.bookingInfoResponse.response[i].bookings.length;j++){
                    let value = this.props.bookingInfoResponse.response[i].bookings[j]
                    if ((Number(value.details.transaction_no) === Number(JSON.parse(data).tbo_booking_id) || value.details.transaction_no === JSON.parse(data).tbo_booking_id) && value.details.pnr === JSON.parse(data).pnr) {
                        bookingData = value;
                    }
                }
            }
        }
        // }
        return (
            <React.Fragment>
                {/* {
                    data && JSON.parse(data).booking_type === 'online' &&
                    <PrintInvoice {...this.props}/>
                }
                {
                    data && (JSON.parse(data).booking_type === 'offline' || JSON.parse(data).booking_type === 'drop') && */}
                    <PrintOfflineBookingInvoice {...this.props} bookingData={bookingData}/>
                {/* } */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        calendarResponse        : state.myAccount.calendarResponse,
        bookingInfoResponse         : state.myAccount.bookingInfoResponse,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCalendarDetail   :() => dispatch(getCalendarDetail()),
        bookingInfoAction: (data) => dispatch(bookingInfoAction(data)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(PrintInvoiceContainer)