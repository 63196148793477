import REGISTER_API from '../../api/shared/registerApi';

/* Functions to check username availibility ----- Start*/
export const CHECK_USERNAME = 'CHECK_USERNAME';
const checkUsername = () => {
    return {
        type: CHECK_USERNAME
    };
};

export const USERNAME_AVAILABLE = 'USERNAME_AVAILABLE';
const usernameAvailable = () => {
    return {
        type: USERNAME_AVAILABLE,
    }
};

export const USERNAME_NOT_AVAILABLE = 'USERNAME_NOT_AVAILABLE';
const usernameNotAvaiable = () => {
    return {
        type: USERNAME_NOT_AVAILABLE,
    }
};

export function checkUsernameAction (credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(checkUsername());
        return REGISTER_API.checkusername(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    setTimeout(function(){ dispatch(usernameAvailable()); }, 1000);
                    successCb && successCb('success');
                } else {
                    setTimeout(function(){ dispatch(usernameNotAvaiable()); }, 1000);
                    errorCb && errorCb('error');
                }
            });
    }
}

/* Functions to check username availibility ----- End*/

/* Functions for Agent registration ----- Start*/
export const AGENT_REGISTRATION = 'AGENT_REGISTRATION';
const agentRegistrationStart = () => {
    return {
        type: AGENT_REGISTRATION
    };
};

export const AGENT_REGISTRATION_OK = 'AGENT_REGISTRATION_OK';
const agentRegistrationSuccess = () => {
    return {
        type: USERNAME_AVAILABLE,
    }
};

export const AGENT_REGISTRATION_ERR = 'AGENT_REGISTRATION_ERR';
const agentRegistrationError = (payload) => {
    return {
        type: AGENT_REGISTRATION_ERR,
        payload: payload
    }
};

export function agentRegistration (credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(agentRegistrationStart());
        return REGISTER_API.register(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(agentRegistrationSuccess());
                    successCb && successCb();
                } else {
                    dispatch(agentRegistrationError(payload));
                    errorCb && errorCb(payload);
                }
            });
    }
}

/* Functions for Agent registration ----- End*/

/* Functions for Agent file Upload ----- Start*/
export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
const fileUploadStart = () => {
    return {
        type: FILE_UPLOAD_START
    };
};

export const FILE_UPLOAD_OK = 'FILE_UPLOAD_OK';
const fileUploadSuccess = (imgPath) => {
    return {
        type: FILE_UPLOAD_OK,
        imgPath
    }
};

export const FILE_UPLOAD_ERR = 'FILE_UPLOAD_ERR';
const fileUploadError = () => {
    return {
        type: FILE_UPLOAD_ERR,
    }
};

export function fileUpload (credentials,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(fileUploadStart());
        return REGISTER_API.uploadFile(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(fileUploadSuccess(payload.file_path[0]));
                    successCb && successCb(payload.file_path[0]);
                } else {
                    dispatch(fileUploadError());
                    errorCb && errorCb('error');
                }
            });
    }
}

/* Functions for Agent file Upload ----- End*/

/* Functions for getting Ownership Type ----- Start*/
export const OWNERSHIP_START = 'OWNERSHIP_START';
const ownershipStart = () => {
    return {
        type: OWNERSHIP_START
    };
};

export const OWNERSHIP_OK = 'OWNERSHIP_OK';
const ownershipSuccess = (payload) => {
    return {
        type: OWNERSHIP_OK,
        payload
    }
};

export const OWNERSHIP_ERR = 'OWNERSHIP_ERR';
const ownershipError = () => {
    return {
        type: OWNERSHIP_ERR,
    }
};

export function getOwnership() {
    return (dispatch, getState) => {
        dispatch(ownershipStart());
        return REGISTER_API.ownership()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(ownershipSuccess(payload));
                } else {
                    dispatch(ownershipError());
                }
            });
    }
}

/* Functions for getting Ownership Type ----- End*/

/* Functions for getting addressproof Type ----- Start*/
export const ADDRESSPROOF_START = 'ADDRESSPROOF_START';
const addressProofStart = () => {
    return {
        type: ADDRESSPROOF_START
    };
};

export const ADDRESSPROOF_OK = 'ADDRESSPROOF_OK';
const addressProofSuccess = (payload) => {
    return {
        type: ADDRESSPROOF_OK,
        payload
    }
};

export const ADDRESSPROOF_ERR = 'ADDRESSPROOF_ERR';
const addressProofError = () => {
    return {
        type: ADDRESSPROOF_ERR,
    }
};

export function getAddressProofType() {
    return (dispatch, getState) => {
        dispatch(addressProofStart());
        return REGISTER_API.addressProof()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(addressProofSuccess(payload));
                } else {
                    dispatch(addressProofError());
                }
            });
    }
}

/* Functions for getting addressproof Type ----- End*/

/* Functions for getting cities and state ----- Start*/
export const LOCATION_START = 'LOCATION_START';
const locationStart = () => {
    return {
        type: LOCATION_START
    };
};

export const LOCATION_OK = 'LOCATION_OK';
const locationSuccess = (city,state,country_state,country,country_city) => {
    return {
        type: LOCATION_OK,
        payload : {
            city,
            state,
            country_state,
            country,
            country_city
        }
    }
};

export const LOCATION_ERR = 'LOCATION_ERR';
const locationError = () => {
    return {
        type: LOCATION_ERR,
    }
};

export function getlocation() {
    return (dispatch, getState) => {
        dispatch(locationStart());
        return REGISTER_API.location()
            .then((payload) => {
                if (payload.status === 'ok') {
                    var state = [];
                    var country_state = [];
                    var state_city = [];
                    var country    = [];
                    var country_city = [];
                    //Get Country
                    for(let i=0;i<payload.locations.length;i++){
                        if(payload.locations[i].city === null && payload.locations[i].state === null){
                            country.push({'id' :payload.locations[i].location_id ,"country" : payload.locations[i].country ,"locationCode" : payload.locations[i].location_code,"nationality" : payload.locations[i].nationality});
                        }
                    }
                    //Get Country wise states
                    for(let i =0;i<country.length;i++){
                        var states = [];
                        for(let j=0;j<payload.locations.length;j++){
                            if(country[i].country === payload.locations[j].country && payload.locations[j].state && payload.locations[j].city === null){
                                states.push({'id' :payload.locations[j].location_id ,"state" : payload.locations[j].state });
                            }                            
                        }
                        country_state[country[i].id] = states;
                    }

                    //Get states
                    for(let i=0;i<payload.locations.length;i++){
                        if(payload.locations[i].city === null && payload.locations[i].state){
                            state.push({'id' :payload.locations[i].location_id ,"state" : payload.locations[i].state });
                        }
                    }
                    //Get State wise cities
                    for(let i=0;i< state.length;i++){
                        var cities = [];
                        for(let j=0;j<payload.locations.length ;j++){
                            if(state[i].state === payload.locations[j].state && payload.locations[j].city)
                                cities.push({'id' :payload.locations[j].location_id ,"city" : payload.locations[j].city }); 
                        }
                        state_city[state[i].id] = cities;
                    }
                    //Get State wise cities
                    for(let i =0;i<country.length;i++){
                        var city = [];
                        for(let j =0;j<payload.locations.length;j++){
                            if(country[i].country ===  payload.locations[j].country && payload.locations[j].city)
                                city.push({'id' :payload.locations[j].location_id ,"city" : payload.locations[j].city }); 
                        }
                        country_city[country[i].id] = city;
                    }
                    dispatch(locationSuccess(state_city,state,country_state, country,country_city));
                } else {
                    dispatch(locationError());
                }
            });
    }
}

/* Functions for getting cities and state  ----- End*/

/* Functions for getting country-details ----- Start*/
export const COUNTRY_DETAILS_START = 'COUNTRY_DETAILS_START';
const countryDetailsStart = () => {
    return {
        type: COUNTRY_DETAILS_START
    };
};

export const COUNTRY_DETAILS_OK = 'COUNTRY_DETAILS_OK';
const countryDetailsSuccess = (payload) => {
    return {
        type: COUNTRY_DETAILS_OK,
        payload
    }
};

export const COUNTRY_DETAILS_ERR = 'COUNTRY_DETAILS_ERR';
const countryDetailsError = () => {
    return {
        type: COUNTRY_DETAILS_ERR,
    }
};

export function getCountryDetails() {
    return (dispatch, getState) => {
        dispatch(countryDetailsStart());
        return REGISTER_API.countryDetails()
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(countryDetailsSuccess(payload));
                } else {
                    dispatch(countryDetailsError());
                }
            });
    }
}

/* Functions for getting Country details  ----- End*/