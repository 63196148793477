import React, { Component } from 'react';
import AgentInfoComponent from '../../components/agent/AgentInfoUpdateComponent';
import { connect } from 'react-redux'
import { myAccountAction,updateAgentInfo } from '../../actions/agentAction';
import { getOwnership ,getAddressProofType ,getlocation} from '../../actions/shared/registerAction';
import { Formik } from "formik";
import * as Yup from "yup"
import { getNewDateFormted } from '../../shared/commonHelper';

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,//my account details
        productResponse : state.shared.productResponse,//get products for navigations
        //fileUploadData      : state.register.uploadedFile, //for uploading any file
        ownerShip           : state.register.ownershipTypes, // For getting type of ownership
        addressProof        : state.register.addressProof,// For getting type of addressproof
        allCities           : state.register.locations.response,// For getting all cities and states
        agentProductAccess     : state.shared.agentProductAccess,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        myAccountAction: () => dispatch(myAccountAction()),
        //fileUpload  : (data,success,error) => dispatch(fileUpload(data,success,error)), //for uploading any file
        updateAgentInfo  : (data,success,error) => dispatch(updateAgentInfo(data,success,error)), //for updating agent info
        getOwnership              : () => dispatch(getOwnership()),// For getting type of ownership
        getAddressProofType       : () => dispatch(getAddressProofType()),// For getting type of addressproof
        getlocation               : () => dispatch(getlocation())// For getting all cities and states
    }
};

class AgentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            uploadFileData : null,
            updateAgentMsg: null,
            hideMsg:false,
        };
    }
    componentDidMount() {
        this.props.getOwnership(); 
        this.props.getAddressProofType();
        this.props.getlocation();
    }
    showReplyForm = () => {
        this.setState({hideMsg: true});
    };
    //After all validations submit form
    submitForm(values) {
        const formValues = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "gender": values.gender,
            "dateofbirth": values.dobyear + "-" + values.dobmonth + "-" + values.dobDay,
            // "gst_certificate": values.gst_certificate,
            // "address_proof": values.address_proof,
            // "address_proof_type": values.address_proof_type,
            // "company_name": values.company_name,
            "office_address": values.office_address,
            "state": values.state,
            "city": values.city,
            "pincode": values.pincode,
            "phone_number": values.phone_number,
            "mobile_number": values.mobile_number,
            "email_address": values.email_address,
            "alternative_mobile_number": values.alternative_mobile_number,
            "website": values.website,
            // "ownership_type" : values.ownershipType,
            // "pan_card": values.pan_card,
            // "pan_number"     : values.pan_number,
        };
        const successCb = () => {
            this.setState({updateAgentMsg: 'success'});
            this.props.myAccountAction();
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({updateAgentMsg: 'error'});
        }
        this.setState({hideMsg: false});
        this.props.updateAgentInfo(formValues,successCb,errorCb);
        
    } 

    //Function to be called from dumb view to upload file
    uploadfile=(self,file_type,file)=>{
        /* on successful file upload */
        const successCb = (success) => {
            self.props.setFieldValue(file_type, success);
        }
        /* on error, update the error in the state */
        const errorCb = () => {
            self.props.setFieldValue(file_type, '');
        }
        this.props.fileUpload(file,successCb,errorCb);  
    }

    render() {
        let myAccountDetail = this.props.accountResponse.myAccountResponse.response
        let date = myAccountDetail &&  getNewDateFormted(myAccountDetail.dateofbirth);
        let onlyDate = date.getDate();
        let onlyMonth = date.getMonth(); 
        let onlyYear = date.getFullYear(); 

        var initialValues = {
            first_name      : myAccountDetail ? myAccountDetail.first_name :'',
            last_name       : myAccountDetail ? myAccountDetail.last_name : '',
            gender          : myAccountDetail ? myAccountDetail.gender : '',
            dobDay          : onlyDate,
            dobmonth        : parseInt(onlyMonth+1),
            dobyear         : onlyYear,
            company_name    : myAccountDetail ? myAccountDetail.company_name : '',
            ownershipType   : myAccountDetail ? myAccountDetail.ownership_type : '',
            office_address  : myAccountDetail ? myAccountDetail.office_address : '',
            state           : myAccountDetail ? myAccountDetail.state_id : '',
            city            : myAccountDetail ? myAccountDetail.city_id : '',
            phone_number    : myAccountDetail ? myAccountDetail.phone_number : '',
            mobile_number   : myAccountDetail ? myAccountDetail.mobile_number : '',
            email_address   : myAccountDetail ? myAccountDetail.email_address : '',
            alternative_mobile_number: myAccountDetail ? myAccountDetail.alternative_mobile_number : '',
            website         : myAccountDetail ? myAccountDetail.website : '',
            pan_card        : myAccountDetail ? myAccountDetail.pan_card:'',
            pan_number      : myAccountDetail ? myAccountDetail.pan_number : '',
            pincode         : myAccountDetail ? myAccountDetail.pincode : '',
            address_proof_type: myAccountDetail ? myAccountDetail.address_proof_type : '',
            address_proof   : myAccountDetail ? myAccountDetail.address_proof:'',
            check_gst       : '',
            gst_certificate : myAccountDetail ? myAccountDetail.gst_certificate:'',
            gst_certificate_file: '',
            pan_file        : '',
            address_proof_file : ''
        };
        const validationSchemaAgent = Yup.object().shape({
            first_name:
                Yup.string()
                    .required("Please enter first name"),
            last_name:
                Yup.string()
                    .required("Please enter last name"),
            gender:
                Yup.string()
                    .required("Please enter gender"),
            company_name:
                Yup.string()
                    .required('Please Enter Company Name'),
            ownershipType:
                Yup.string()
                    .required('Please select ownership type'),
            office_address:
                Yup.string()
                    .required('Please enter office address'),
            state:
                Yup.string()
                    .required('Please select state'),
            city:
                Yup.string()
                    .required('Please select city/district'),
            mobile_number:
                Yup.number()
                .required('Please enter mobile number')
                .typeError('Please enter valid phone number'),
            phone_number:
                Yup.number()
                .typeError('Please enter valid phone number'),
            email_address:
                Yup.string()
                    .required('Please enter email address')
                    .email('Please enter a valid email address'),
            website:
                Yup.string()
                    .url('Please enter valid website'),
            pan_card:
                Yup.string()
                    .required('Please select PAN card'),
            pincode:
                Yup.number()
                    .typeError('Please enter valid pincode'),
            pan_number:
                Yup.string()
                    .required('Please enter PAN number')
        });
        return (
            <Formik 
                enableReinitialize={true}
                initialValues={initialValues} //Set initial values of fields to blank
                validationSchema={validationSchemaAgent}
                onSubmit={(values) => {
                    this.submitForm(values);
                }}
                render={formikProps =>
                    <AgentInfoComponent {...formikProps} {...this.props} {...this.state}  uploadFile={this.uploadfile} showReplyForm={this.showReplyForm} />
                }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentInfo)
