import React, { Component } from 'react';
class RailwayDetailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmationPopup: false
        }
    }

    handleConfirmationPopup = (value) => {
        this.setState({
            showConfirmationPopup: value
        })
    }

    handleConfimationAction = () => {
        this.handleConfirmationPopup(false);
        this.props.makePayment();
    }
    render() {
        return (
            <section className="mid-wrapper">
                {
                    this.props.detailVerification.loading
                        ?
                        <div className="loader relative-loader" style={{ display: 'block' }}>
                            <div className="loader-content"><div className="loader-ring blue-ring">
                                <span /> </div></div></div>
                        :
                        (this.props.detailError
                            ?
                            <div className="container" style={{ height: '700px' }}>
                                <div className="text-center" style={{ paddingTop: '10%' }}>
                                    <p style={{ color: '#FF0000', fontSize: '20px' }}>We are unable to process with your data</p>
                                    <div style={{ paddingTop: '1%' }}>
                                        <button className={`btn btn-yellow text-center railway-payment`} onClick={() => window.location = '/jfrb/login'}>
                                            Click here to return</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container" style={{ backgroundColor: 'white', height: '700px' }}>
                                <div className="mt-2 mb-5 text-center">
                                    <h1 className="text-primary">Details Verification</h1>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Reservation Id</th>
                                            <th scope="col">Currency Type</th>
                                            <th scope="col">Base Fare</th>
                                            <th scope="col">Service Charge</th>
                                            <th scope="col">CGST</th>
                                            <th scope="col">IGST</th>
                                            <th scope="col">SGST</th>
                                            <th scope="col">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.reservation_id}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.currency_type}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.irctc_txn_amount}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.service_charge}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.cgst}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.igst}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.sgst}</td>
                                            <td>{this.props.detailVerification.response && this.props.detailVerification.response.ticket_info && this.props.detailVerification.response.ticket_info.total_payable_amount}</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className="text-center">
                                    <button className={`btn btn-success text-center railway-payment ${this.props.railwayPaymentDetail.loading && 'show'}`} onClick={() => this.handleConfirmationPopup(true)}>
                                        {
                                            this.props.railwayPaymentDetail.loading &&
                                            <div className="loader-ring"> <span></span> </div>
                                        }
                                        Proceed to Payment</button>
                                </div>
                            </div>)
                }
                {/* Popup for booking confirmation */}
                <div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Payment Confirmation</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false)} /></div>
                            </div>
                            <div className="popup-body popup-scorll">
                                <div className="inner-confirmation">
                                    <p>Are you sure you want to continue?</p>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.handleConfimationAction()}>Yes</button>
                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay" />
                </div>
            </section>
        );
    }
}

export default RailwayDetailVerification;