import React from "react";
import { connect } from "react-redux";
import HotelSearchDetailsComponent from "../../components/hotel/HotelSearchDetailsComponent";
import { currencyFormat } from '../../shared/commonHelper';
import { getHotelSearchResults, getHotelRoomDetails,getHotelDetails, filterhotelResults, sendItinerariesEmail,getTripjackHotelSearchResults, getTripjackHotelDetails, getTripjackHotelRoomDetails, getTripjackCancelPolicy,hotel_search_api_type } from "../../actions/hotel/hotelSearchDetailsAction";
import {isFilterChecked,scroll_position} from '../../actions/shared/sharedAction'

class HotelSearchDetailsContainer extends React.Component {
     constructor(props) {
        super(props)
        this.state = { 
            showRoomDetailPopup : false,
            showReadMoreDetailPopup : false,
            showViewOnMapPopup : false,
            galleryImageUrl : null,
            activeTab : 'available-rooms',
            readMoreActiveTab : 'description',
            //hotelDetailData : null,
            roomDetails : null,
            finalPrice:null,
            sendMailerror: null,
            sendMailsuccess: null,
            selectedHotelName:null,
            finalOfferedPrice: null,
            traceId :''
            
        }
    }

    componentDidMount() {
        //this.props.getHotelSearchResults(this.props.searchQuery);
        if(!(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.searchAPINo)){
            if (this.props.searchQuery && this.props.searchQuery.api_calling === "BOTH") {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                // this.props.getHotelSearchResults(this.props.searchQuery);
                this.props.getTripjackHotelSearchResults(this.props.searchQuery);
            } else if (this.props.searchQuery && this.props.searchQuery.api_calling === "tripjack") {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                this.props.getTripjackHotelSearchResults(this.props.searchQuery);
            }
            else {
                this.props.hotel_search_api_type(this.props.searchQuery.api_calling)
                // this.props.getHotelSearchResults(this.props.searchQuery);
            }
        }
    }

    //send itineraries mail
    sendItinerariesMail = (email, sendMailValues, includeFare) => {
        let isMail = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (sendMailValues.length === 0) {
            this.setState({ sendMailerror: 'Please select hotel(s)' ,sendMailsuccess: null});
        } else if (!email) {
            this.setState({ sendMailerror: 'Please enter email address',sendMailsuccess: null });
        } else if (email && sendMailValues.length > 0) {
            if (isMail.test(email)) {
                //Get passenger count
                if (sendMailValues.length > 0) {
                    let itineraries = [];
                    //get data from result array
                    for(let i = 0;i<this.props.hotelSearchResult.hotel_results.length;i++)
                    {
                        let hotelResult = this.props.hotelSearchResult.hotel_results[i];
                        if (sendMailValues.includes(hotelResult.result_index.toString()))
                        {
                            let hotelRoomInfo = [];
                            for(let j = 0;j<this.props.searchQuery.room_guests.length;j++)
                            {
                                let roomPaxInfo = {
                                    "no_of_adults": this.props.searchQuery.room_guests[j].no_of_adults,
                                    "no_of_childs": this.props.searchQuery.room_guests[j].no_of_child
                                }
                                hotelRoomInfo.push(roomPaxInfo);
                            }
                            let hotelinfo = {
                                "hotel_code": hotelResult.hotel_code,
                                "hotel_name": hotelResult.hotel_name,
                                "star_rating": hotelResult.star_rating.toString(),
                                "price": "",
                                "hotel_room_details": hotelRoomInfo
                            }
                            if(includeFare === true)
                            {
                                hotelinfo.price = currencyFormat(hotelResult.price_modified.published_price);
                            }
                            itineraries.push(hotelinfo);
                        }
                    }
                    const finalData = {
                        "email": email,
                        "city_id": this.props.searchQuery.city_id,
                        "destination": this.props.searchQuery.city_name,
                        "check_in_date": this.props.searchQuery.check_in_date,
                        "check_out_date": this.props.searchQuery.check_out_date,
                        "no_of_nights" : this.props.searchQuery.no_of_nights,
                        "itineraries": itineraries
                    }
                    /* on successful mail send*/
                    const successCb = (success) => {
                        this.setState({ sendMailsuccess: success, sendMailerror: null });
                    }
                    /* on error, update the error in the state */
                    const errorCb = (error) => {
                        this.setState({ sendMailerror: error.message, sendMailsuccess: null });
                    }
                    this.props.sendItinerariesEmail(finalData, successCb, errorCb);
                }
            } else {
                this.setState({ sendMailerror: 'Please enter valid email address' ,sendMailsuccess: null});
            }
        }
    }

    getHotelRoomDetails = (trace_id, hotels, api_calling,hotelName,supplier_type) => {
        this.setState({
            selectedHotelName : hotelName
        })
        let result_index    = hotels.result_index;
        let hotel_code      = hotels.hotel_code;
        let roomDetailsData ={
            "trace_id":   trace_id,
            "result_index": result_index,
            "hotel_code":hotel_code,
            "booking_category_id":this.props.searchQuery.is_domestic === 'true' ? 1 :2,
            "star_rating":hotels.star_rating,
            "city_id":this.props.searchQuery.city_id
        }
        if(api_calling === 'both')
        {
            // this.props.getHotelRoomsDetails(roomDetailsData);
            // this.props.getHotelDetails(trace_id, result_index, hotel_code);
            this.setState({
                showRoomDetailPopup: true,
                hotelDetailData : hotels,
                roomDetails : null,
                finalPrice:null,
                finalOfferedPrice:null
            });
            localStorage.setItem("trace_id", JSON.stringify(trace_id));
            localStorage.setItem("hotels", JSON.stringify(hotels));
            localStorage.setItem("api_calling", JSON.stringify(api_calling));
            localStorage.setItem("hotelName", JSON.stringify(hotelName));
            localStorage.setItem("result_index", JSON.stringify(result_index));
            localStorage.setItem("hotel_code", JSON.stringify(hotel_code));
            localStorage.setItem("roomDetailsData", JSON.stringify(roomDetailsData));
            localStorage.setItem("supplier_type", JSON.stringify(supplier_type));
            window.open('/hotel-room-detail', "_blank");
        }
        else if(api_calling === 'imagePopup')
        {
            if (supplier_type === 'tripjack') {
                this.props.getTripjackHotelRoomDetails(roomDetailsData);
                this.props.getTripjackHotelDetails(trace_id, result_index, hotel_code);
            }
            if (supplier_type === 'tbo') {
                this.props.getHotelRoomsDetails(roomDetailsData);
                this.props.getHotelDetails(trace_id, result_index, hotel_code);
            }
            this.setState({
                showRoomDetailPopup: true,
                activeTab: 'image-gallery',
                hotelDetailData : hotels,
                roomDetails : null,
                finalPrice:null,
                finalOfferedPrice:null
            });
        }
        else if(api_calling === 'single')
        {
            this.setState({showReadMoreDetailPopup: true});
            if (supplier_type === 'tripjack') {
                this.props.getTripjackHotelDetails(trace_id, result_index, hotel_code);
            } 
            if (supplier_type === 'tbo') {
                this.props.getHotelDetails(trace_id, result_index, hotel_code);
            }
        }
        else if(api_calling === 'map')
        {
            this.setState({showViewOnMapPopup: true});
            if (supplier_type === 'tripjack') {
                this.props.getTripjackHotelDetails(trace_id, result_index, hotel_code);
            }
            if (supplier_type === 'tbo') {
                this.props.getHotelDetails(trace_id, result_index, hotel_code);
            }
        }
    }
    //Function to get final price
    getFinalPrice(details){
        let finalPrice = 0;
        for(let i = 0 ;i<details.length;i++){
            finalPrice = Number(finalPrice) + Number(details[i] && details[i].price_modified && details[i].price_modified.published_price);
        }
        return finalPrice;
    }

    //Function to get final offered price
    getFinalOfferedPrice(details){
        let finalOfferedPrice = 0;
        for(let i = 0 ;i<details.length;i++){
            finalOfferedPrice = Number(finalOfferedPrice) + Number(details[i] && details[i].price_modified && details[i].price_modified.offered_price);
        }
        return finalOfferedPrice;
    }


    getChooseRooms = (item,arrayFlag,key) => {
        let finalPrice = 0;
        let finalOfferedPrice = 0;
        if(arrayFlag){
            let details = this.state.roomDetails;
            if(details){
                details[key] = item;
            }else{
                details = [];
                details[key] = item;
            }
            finalPrice = this.getFinalPrice(details);
            finalOfferedPrice = this.getFinalOfferedPrice(details);
            this.setState({
                    roomDetails : details,
                    finalPrice: finalPrice,
                    finalOfferedPrice: finalOfferedPrice,
                })
        }else{
            finalPrice = this.getFinalPrice(item);
            finalOfferedPrice = this.getFinalOfferedPrice(item);
            this.setState({
                roomDetails : item,
                finalPrice: finalPrice,
                finalOfferedPrice: finalOfferedPrice,
            })
        }
    };

    getHideRoomDetailPopup = () => {
        this.setState({showRoomDetailPopup: false});
        this.setState({activeTab: 'available-rooms'});
        this.setState({galleryImageUrl : null})
    };
    getHideReadMoreDetailPopup = () => {
        this.setState({showReadMoreDetailPopup: false});
        this.setState({ readMoreActiveTab: 'description' });
    };
    getHideViewOnMapPopup = () => {
        this.setState({showViewOnMapPopup: false});
    };
    getGalleryImages = (url) => {
        this.setState({galleryImageUrl : url})
    }
    handleHotelDetailsTab(searchType,event) {
        this.setState({ activeTab: searchType });
    }
    handleReadMoreDetailsTab(searchType, event) {
        this.setState({ readMoreActiveTab: searchType });
    }
    //Start booking of hotel
    startBooking = (hotelDetailData,trace_id) =>{
        if(this.props.hotelRoomDetail.response.trace_id === null){
            if(Number(this.props.searchQuery.no_of_rooms) === (this.state.roomDetails.room_combination ? this.state.roomDetails.room_combination.length : this.state.roomDetails.length)){
                this.props.history.push({
                    pathname:"/hotel-book",
                    state:{
                        trace_id : trace_id,
                        hoteldata: hotelDetailData,
                        roomData : this.state.roomDetails.room_combination ? this.state.roomDetails.room_combination : this.state.roomDetails
                     }
                });
            }else{
                alert('Please scroll down and select more room(s)');
            }
        }else{
        if(Number(this.props.searchQuery.no_of_rooms) === this.state.roomDetails.length){
            this.props.history.push({
                pathname:"/hotel-book",
                state:{
                    trace_id : trace_id,
                    hoteldata: hotelDetailData,
                    roomData : this.state.roomDetails
                 }
            });
        }else{
            alert('Please scroll down and select more room(s)');
        }
    }
	}
    render() {
        return(
            <div>
                    <HotelSearchDetailsComponent {...this.props}
                        getHotelRoomDetails={this.getHotelRoomDetails.bind(this)}
                        {...this.state}
                        getHideRoomDetailPopup={this.getHideRoomDetailPopup}
                        getHideReadMoreDetailPopup={this.getHideReadMoreDetailPopup}
                        getHideViewOnMapPopup={this.getHideViewOnMapPopup}
                        getGalleryImages={this.getGalleryImages}
                        handleHotelDetailsTab={this.handleHotelDetailsTab.bind(this)}
                        handleReadMoreDetailsTab={this.handleReadMoreDetailsTab.bind(this)}
                        getChooseRooms={this.getChooseRooms}
                        startBooking={this.startBooking}
                        sendItinerariesMail={this.sendItinerariesMail}
                    />
            </div>
        ) 
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...state.hotelSearchDetailsReducer,
        productResponse: state.shared.productResponse,
        agentProductAccess    : state.shared.agentProductAccess,
        searchQuery    : state.hotelSearchReducer.searchQuery,
        isFilterCheckedStatus : state.shared.isFilterChecked,
        track_scroll_position: state.shared.scroll_position,
        //hotelRoomDetail: state.hotelSearchDetailsReducer.hotelRoomDetail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getHotelSearchResults: (searchQuery, callback) => dispatch(getHotelSearchResults(searchQuery, callback)),
        getHotelRoomsDetails : (data) => dispatch(getHotelRoomDetails(data)),
        getHotelDetails : (trace_id, result_index, hotel_code) => dispatch(getHotelDetails(trace_id, result_index, hotel_code)),
        filterhotelResults: (filters)  => dispatch(filterhotelResults(filters)),
        sendItinerariesEmail: (data, successCb, errorCb) => dispatch(sendItinerariesEmail(data, successCb, errorCb)),
        isFilterChecked: (response) => dispatch(isFilterChecked(response)),
        getTripjackHotelSearchResults: (searchQuery, callback) => dispatch(getTripjackHotelSearchResults(searchQuery, callback)), // tripjack hotel search
        getTripjackHotelDetails : (trace_id, result_index, hotel_code) => dispatch(getTripjackHotelDetails(trace_id, result_index, hotel_code)), //tripjack hotel details
        getTripjackHotelRoomDetails : (data) => dispatch(getTripjackHotelRoomDetails(data)), // tripjack hotel room details
        getTripjackCancelPolicy : (data) => dispatch(getTripjackCancelPolicy(data)), // tripjack cancel policy
        hotel_search_api_type:  (payload)=>dispatch(hotel_search_api_type(payload)),
        scroll_position: (response) => dispatch(scroll_position(response)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelSearchDetailsContainer);
