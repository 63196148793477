import React, { Component } from 'react';

class HeaderWithoutSession extends Component {
    render() {
        var showheader = 'light';
        if(this.props.url === '/about-us' || this.props.url === '/contact-us'){
            showheader = 'dark';
        }
        return (
            <div className="login-header">
            {
                showheader === 'light' && 
                    <div className="container">
                        <a href="/login"> <h1 className="logo-figure"><img src={this.props.settingResponse.response && this.props.settingResponse.response.website_logo_light} alt="" /></h1></a>
                    </div>
            }
            {
                showheader === 'dark' && 
                    <div className="container">
                        <a href="/login"> <h1 className="logo-figure"><img src={this.props.settingResponse.response && this.props.settingResponse.response.website_logo_dark} alt="" /></h1></a>
                    </div>
            }
            </div>
        )
    }
}

export default HeaderWithoutSession;