import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  render() {
    const googleMapData = this.props.googleMapData;
    let center = {
      lat: googleMapData ? Number(googleMapData.hotel_details.latitude) : 26.94,
      lng: googleMapData ? Number(googleMapData.hotel_details.longitude) : 75.79
    }
    const style = {
      width: '100%',
      height: '100%'
    }
    let hotelImage = googleMapData && googleMapData.hotel_details && googleMapData.hotel_details.images && googleMapData.hotel_details.images[0];
    return (
      <Map google={this.props.google}
            style={style}
            onClick={this.onMapClicked}
            initialCenter={center}
            zoom={15}
        >
          <Marker onClick={this.onMarkerClick} />

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            position={center}
          >
            <div className="map-tool-tip">
            { 
              hotelImage && 
              <figure>
                <img src={hotelImage.url ? hotelImage.url : hotelImage} alt="" />
              </figure>
            }
              <div className="map-tooltip-text">
                <h6>{googleMapData.hotel_details.hotel_name}</h6>
                <p>{googleMapData.hotel_details.address}</p>
              </div>
            </div>
          </InfoWindow>
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ('AIzaSyDllRMN-unMYH8eIO0_mcSlS5kyUuufvrs')
})(MapContainer)