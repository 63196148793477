import SHARED_API from '../../api/shared/sharedApi';
//=========================== for banner API =========================================
export const IS_BOOKING_PENDING = 'IS_BOOKING_PENDING';
export const isBookingPending = (response) => {
    return {
        type: IS_BOOKING_PENDING,
        response:response
    };
};
export const IS_FILTER_CHECKED = 'IS_FILTER_CHECKED';
export const isFilterChecked = (response) => {
    return {
        type: IS_FILTER_CHECKED,
        response:response
    };
};

export const IS_FILTER_CHECKED_INBOUND = 'IS_FILTER_CHECKED_INBOUND';
export const isFilterCheckedInbound = (response) => {
    return {
        type: IS_FILTER_CHECKED_INBOUND,
        response:response
    };
};

export const SCROLL_POSITION = 'SCROLL_POSITION';
export const scroll_position = (response) => {
    return {
        type: SCROLL_POSITION,
        response:response
    };
};

export const BANNER_FETCH_START = 'BANNER_FETCH_START';
const startBannerFetch = () => {
    return {
        type: BANNER_FETCH_START
    };
};

export const BANNER_FETCH_OK = 'BANNER_FETCH_OK';
const BannerFetchSuccess = (data) => {
    return {
        type: BANNER_FETCH_OK,
        response:data
    }
};

export const BANNER_FETCH_ERR = 'BANNER_FETCH_ERR';
const BannerFetchError = (status, message, errors) => {
    return {
        type: BANNER_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function bannerAction (credentials) {
    return (dispatch, getState) => {

        dispatch(startBannerFetch());

        return SHARED_API.getBanners(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(BannerFetchSuccess(payload.banners));

                } else {
                    dispatch(BannerFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//=========================== for notices API =========================================
export const NOTICE_FETCH_START = 'NOTICE_FETCH_START';
const startNoticeFetch = () => {
    return {
        type: NOTICE_FETCH_START
    };
};

export const NOTICE_FETCH_OK = 'NOTICE_FETCH_OK';
const NoticeFetchSuccess = (data) => {
    return {
        type: NOTICE_FETCH_OK,
        response:data
    }
};

export const NOTICE_FETCH_ERR = 'NOTICE_FETCH_ERR';
const NoticeFetchError = (status, message, errors) => {
    return {
        type: NOTICE_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function noticeAction (credentials) {
    return (dispatch, getState) => {

        dispatch(startNoticeFetch());

        return SHARED_API.getNotices(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(NoticeFetchSuccess(payload.notices));

                } else {
                    dispatch(NoticeFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//=========================== for Products API =========================================
 export const PRODUCTS_FETCH_START = 'PRODUCTS_FETCH_START';
 const startProductFetch = () => {
    return {
        type: PRODUCTS_FETCH_START
    };
 };

 export const PRODUCTS_FETCH_OK = 'PRODUCTS_FETCH_OK';
 const productSuccess = (data) => {
    return {
        type: PRODUCTS_FETCH_OK,
         response:data
    }
 };

 export const PRODUCTS_FETCH_ERR = 'PRODUCTS_FETCH_ERR';
 const productFetchError = (status, message, errors) => {
    return {
        type: PRODUCTS_FETCH_ERR,
        status,
        message,
        errors
    }
 };

 export function productsAction () {
    return (dispatch, getState) => {

        dispatch(startProductFetch());

        return SHARED_API.getProducts()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(productSuccess(payload.products));
                } else {
                    dispatch(productFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
 }


//========================= For cities API  ==================================

export const CITIES_FETCH_START = 'CITIES_FETCH_START';
const getCities = () => {
    return {
        type: CITIES_FETCH_START
    };
};

export const CITIES_FETCH_OK = 'CITIES_FETCH_OK';
const citiesSuccess = (data) => {
    return {
        type: CITIES_FETCH_OK,
        citiesResponse: data,
    }
};


export const CITIES_FETCH_ERR = 'CITIES_FETCH_ERR';
const citiesError = (status, message, errors) => {
    return {
        type: CITIES_FETCH_ERR,
        status,
        message,
        errors
    }
};


export function getCitiesAction(credentials, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(getCities());

        return SHARED_API.cities(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(citiesSuccess(payload.cities));
                    successCb && successCb();
                }
                else {
                    dispatch(citiesError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

//=========================== Start subscribe API =========================================
// Action For Subscribe Users
export const POST_SUBSCRIBE = 'POST_SUBSCRIBE';
const subscribeStart = () => {
    return {
        type: POST_SUBSCRIBE
    }
};

export const SUBSCRIBE_OK = 'SUBSCRIBE_OK';
export const subscribeOk = (status, message) => {
    return {
        type: SUBSCRIBE_OK,
        status,
        message
    }
};

export const SUBSCRIBE_ERR = 'SUBSCRIBE_ERR';
const subscribeErr = (status, message) => {
    return {
        type: SUBSCRIBE_ERR,
        status,
        message
    }
};

export function subscribeAction (credentials) {
    return (dispatch, getState) => {
        dispatch(subscribeStart());
        return SHARED_API.subscribe(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(subscribeOk(payload.status, payload.message));
                } else {
                    dispatch(subscribeErr(payload.status, payload.message, payload.errors));
                }
            });
    }
}
//=========================== End subscribe API ===========================================

//=========================== for General Settings API =========================================
export const GENERAL_SETTINGS_START = 'GENERAL_SETTINGS_START';
const startSettingFetch = () => {
    return {
        type: GENERAL_SETTINGS_START
    };
};

export const GENERAL_SETTINGS_OK = 'GENERAL_SETTINGS_OK';
const settingSuccess = (data) => {
    return {
        type: GENERAL_SETTINGS_OK,
        response:data
    }
};

export const GENERAL_SETTINGS_ERR = 'GENERAL_SETTINGS_ERR';
const settingFetchError = (status, message, errors) => {
    return {
        type: GENERAL_SETTINGS_ERR,
        status,
        message,
        errors
    }
};

export function settingAction () {
    return (dispatch, getState) => {

        dispatch(startSettingFetch());

        return SHARED_API.getSettings()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(settingSuccess(payload.general_settings));
                } else {
                    dispatch(settingFetchError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

//=========================== for General Settings API =========================================
export const PAGES_FETCH_START = 'PAGES_FETCH_START';
const startPagesFetch = () => {
    return {
        type: PAGES_FETCH_START
    };
};

export const PAGES_FETCH_OK = 'PAGES_FETCH_OK';
const pagesSuccess = (data) => {
    return {
        type: PAGES_FETCH_OK,
        response:data
    }
};

export const PAGES_FETCH_ERR = 'PAGES_FETCH_ERR';
const pagesError = (status, message, errors) => {
    return {
        type: PAGES_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function pagesAction (successCb) {
    return (dispatch, getState) => {

        dispatch(startPagesFetch());

        return SHARED_API.getPages()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(pagesSuccess(modifyAPIResponse(payload.pages,'PAGES_FETCH_OK')));
                    successCb && successCb(payload.pages);
                } else {
                    dispatch(pagesError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

function modifyAPIResponse(data, type) {
    var pages = {};
    var mainArray = {};
    pages.my_transaction = []; //if we are getting two pages with same name/url then we have to initialize an array for them link this
    if (type === 'PAGES_FETCH_OK') {
        if(data){
            for(let i=0;i<data.length;i++){
                var page_url = data[i].page_url.replace('-', '_');
                if(!(page_url in pages)) {
                    pages[page_url] = data[i];
                } else {
                    pages.my_transaction.push(data[i]);
                }
            }
        }
        mainArray['pages'] = pages;
        return mainArray;
    } else {
        return data;
    }
}

//=========================== Token JWT Decode =============================================//

export const JWT_FETCH_OK = 'JWT_FETCH_OK';
const jwtSuccess = (response) => {
    return {
        type: JWT_FETCH_OK,
        response:response
    }
};

export const JWT_FETCH_ERR = 'JWT_FETCH_ERR';
const jwtError = (errors) => {
    return {
        type: JWT_FETCH_ERR,
        error:errors
    }
};
export function getUserInfoJwt(token) {
    return (dispatch, getState) => {
        try {
            // Get Token Header
            const base64HeaderUrl = token.split('.')[0];
            const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
            const headerData = JSON.parse(window.atob(base64Header));
            // Get Token payload and date's
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const dataJWT = JSON.parse(window.atob(base64));
            dataJWT.header = headerData;
            // TODO: add expiration at check ...
            dispatch(jwtSuccess(dataJWT));
        } catch (err) {
            //return false;
            dispatch(jwtError(err));
        }
    }
}
//============================
//================================== Chat Actions===================================================//
export const CHAT_FETCH_START = 'CHAT_FETCH_START';
const startChatFetch = () => {
    return {
        type: CHAT_FETCH_START
    };
};

export const CHAT_FETCH_OK = 'CHAT_FETCH_OK';
const chatSuccess = (data) => {
    return {
        type: CHAT_FETCH_OK,
        response:data
    }
};

export const CHAT_FETCH_ERR = 'CHAT_FETCH_ERR';
const chatError = (status, message, errors) => {
    return {
        type: CHAT_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function getAgentChatResponse(credentials,successCb,errorCb)
{
    return (dispatch, getState) => {
        dispatch(startChatFetch());

        return SHARED_API.getChatInfo(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(chatSuccess(payload.messages));
                    successCb && successCb();
                } else {
                    dispatch(chatError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}
/* post message */
export const POST_MESSAGE_FETCH_START = 'POST_MESSAGE_FETCH_START';
const startPostMessageFetch = () => {
    return {
        type: POST_MESSAGE_FETCH_START
    };
};

export const POST_MESSAGE_FETCH_OK = 'POST_MESSAGE_FETCH_OK';
const postMessageSuccess = (data) => {
    return {
        type: POST_MESSAGE_FETCH_OK,
        response:data
    }
};

export const POST_MESSAGE_FETCH_ERR = 'POST_MESSAGE_FETCH_ERR';
const postMessagechatError = (status, message, errors) => {
    return {
        type: POST_MESSAGE_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function postMessageRequest(credentials,successCb,errorCb)
{
    return (dispatch, getState) => {
        dispatch(startPostMessageFetch());

        return SHARED_API.postMessage(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(postMessageSuccess(payload));
                    successCb && successCb();
                } else {
                    dispatch(postMessagechatError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}

/* post message */
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
// const startGetNotifications = () => {
//     return {
//         type: GET_NOTIFICATIONS_START
//     };
// };

export const GET_NOTIFICATIONS_OK = 'GET_NOTIFICATIONS_OK';
const GetNotificationsSuccess = (data) => {
    return {
        type: GET_NOTIFICATIONS_OK,
        response:data
    }
};

export const GET_NOTIFICATIONS_ERR = 'GET_NOTIFICATIONS_ERR';
const GetNotificationsError = (status, message, errors) => {
    return {
        type: GET_NOTIFICATIONS_ERR,
        status,
        message,
        errors
    }
};

export function getNotificationRequest()
{
    return (dispatch, getState) => {
        // dispatch(startGetNotifications());
        return SHARED_API.getNotifications()
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(GetNotificationsSuccess(payload.notifications));
                } else {
                    dispatch(GetNotificationsError(payload.status, payload.message, payload.errors));
                }
            });
    }
}

/* Update Notifications */
export const UPDATE_NOTIFICATIONS_START = 'UPDATE_NOTIFICATIONS_START';
const startUpdateNotifications = () => {
    return {
        type: UPDATE_NOTIFICATIONS_START
    };
};

export const UPDATE_NOTIFICATIONS_OK = 'UPDATE_NOTIFICATIONS_OK';
const updateNotificationsSuccess = (data) => {
    return {
        type: UPDATE_NOTIFICATIONS_OK,
        response:data
    }
};

export const UPDATE_NOTIFICATIONS_ERR = 'UPDATE_NOTIFICATIONS_ERR';
const updateNotificationsError = (status, message, errors) => {
    return {
        type: UPDATE_NOTIFICATIONS_ERR,
        status,
        message,
        errors
    }
};

export function updateNotificationRequest(data,redirection)
{
    return (dispatch, getState) => {
        dispatch(startUpdateNotifications());
        return SHARED_API.updateNotifications(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(updateNotificationsSuccess(payload.notifications));
                } else {
                    dispatch(updateNotificationsError(payload.status, payload.message, payload.errors));
                }
                redirection && redirection();
            });
    }
}

/********************************************************************** Get product access ********************************************************************/
export const GET_PRODUCT_ACCESS_START = 'GET_PRODUCT_ACCESS_START';
const getProductAccessStart = () => {
    return {
        type: GET_PRODUCT_ACCESS_START
    };
};

export const GET_PRODUCT_ACCESS_OK = 'GET_PRODUCT_ACCESS_OK';
const getProductAccessOk = (data) => {
    return {
        type: GET_PRODUCT_ACCESS_OK,
        response:data
    }
};

export const GET_PRODUCT_ACCESS_ERR = 'GET_PRODUCT_ACCESS_ERR';
const getProductAccessError = (status, message, errors) => {
    return {
        type: GET_PRODUCT_ACCESS_ERR,
        status,
        message,
        errors
    }
};

export function getProductAccess(data,redirection,payload_data)
{
    return (dispatch, getState) => {
        dispatch(getProductAccessStart());
        return SHARED_API.getProductAccessApi(data)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(getProductAccessOk(payload));
                } else {
                    dispatch(getProductAccessError(payload.status, payload.message, payload.errors));
                }
                redirection && redirection(payload_data);
            });
    }
}

//=========================== Start contact us API =========================================
export const CONTACT_US_START = 'CONTACT_US_START';
const contactUsStart = () => {
    return {
        type: CONTACT_US_START
    }
};

export const CONTACT_US_OK = 'CONTACT_US_OK';
export const contactUsOk = (status, message) => {
    return {
        type: CONTACT_US_OK,
        status,
        message
    }
};

export const CONTACT_US_ERR = 'CONTACT_US_ERR';
const contactUsErr = (status, message) => {
    return {
        type: CONTACT_US_ERR,
        status,
        message
    }
};

export function contactUsAction (values,successCb,errorCb) {
    return (dispatch, getState) => {
        dispatch(contactUsStart());
        return SHARED_API.contactUs(values)
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(contactUsOk(payload.status, payload.message));
                    successCb && successCb();
                } else {
                    dispatch(contactUsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}
//=========================== End contact us API ===========================================


//========================= For hotel cities API  ==================================

export const HOTEL_CITIES_FETCH_START = 'HOTEL_CITIES_FETCH_START';
const getHotelCities = () => {
    return {
        type: HOTEL_CITIES_FETCH_START
    };
};

export const HOTEL_CITIES_FETCH_OK = 'HOTEL_CITIES_FETCH_OK';
const hotelCitiesSuccess = (data) => {
    return {
        type: HOTEL_CITIES_FETCH_OK,
        citiesResponse: data,
    }
};

export const HOTEL_CITIES_FETCH_ERR = 'HOTEL_CITIES_FETCH_ERR';
const hotelCitiesError = (status, message, errors) => {
    return {
        type: HOTEL_CITIES_FETCH_ERR,
        status,
        message,
        errors
    }
};

export function getHotelCitiesAction(credentials, successCb, errorCb) {
    return (dispatch, getState) => {
        dispatch(getHotelCities());
        return SHARED_API.hotelCities(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    dispatch(hotelCitiesSuccess(payload.cities));
                    successCb && successCb();
                }
                else {
                    dispatch(hotelCitiesError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}


//Add passengers
export const ADD_PASSENGER_START = 'ADD_PASSENGER_START';
export const addPassengerStart = () => {
    return {
        type: ADD_PASSENGER_START
    };
};

export const ADD_PASSENGER_OK = 'ADD_PASSENGER_OK';
export const addPassengerSuccess = (output) => {
    return {
        type: ADD_PASSENGER_OK,
        response: output
    };
};

export const ADD_PASSENGER_ERR = 'ADD_PASSENGER_ERR';
export const addPassengerError = (output) => {
    return {
        type: ADD_PASSENGER_ERR,
        output: output
    };
};

export const addPassenger = (payload) => {
    return (dispatch, getState) => {
        dispatch(addPassengerStart());
        return SHARED_API.addPassengerApi(payload)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(addPassengerSuccess(response));
                } else{
                    dispatch(addPassengerError(response));
                }
            });
    }
};

// Get passengers from customer list
export const GET_CUSTOMERS_START = 'GET_CUSTOMERS_START';
export const getCustomerStart = () => {
    return {
        type: GET_CUSTOMERS_START
    };
};

export const GET_CUSTOMERS_OK = 'GET_CUSTOMERS_OK';
export const getCustomerSuccess = (response) => {
    return {
        type: GET_CUSTOMERS_OK,
        response: response
    };
};

export const GET_CUSTOMERS_ERR = 'GET_CUSTOMERS_ERR';
export const getCustomerError = (response) => {
    return {
        type: GET_CUSTOMERS_ERR,
        response: response
    };
};

export const getCustomerList = () => {
    return (dispatch, getState) => {
        dispatch(getCustomerStart());
        return SHARED_API.getCustomersApi()
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(getCustomerSuccess(response));
                } else{
                    dispatch(getCustomerError(response));
                }
            });
    }
};


//Add company gst details
export const ADD_COMPANY_GST_DETAILS_START = 'ADD_COMPANY_GST_DETAILS_START';
export const addCompanyGSTDetailsStart = () => {
    return {
        type: ADD_COMPANY_GST_DETAILS_START
    };
};

export const ADD_COMPANY_GST_DETAILS_OK = 'ADD_COMPANY_GST_DETAILS_OK';
export const addCompanyGSTDetailsSuccess = (output) => {
    return {
        type: ADD_COMPANY_GST_DETAILS_OK,
        response: output
    };
};

export const ADD_COMPANY_GST_DETAILS_ERR = 'ADD_COMPANY_GST_DETAILS_ERR';
export const addCompanyGSTDetailsError = (output) => {
    return {
        type: ADD_COMPANY_GST_DETAILS_ERR,
        output: output
    };
};

export const addCompanyGSTDetails = (payload) => {
    return (dispatch, getState) => {
        dispatch(addCompanyGSTDetailsStart());
        return SHARED_API.addCompanyGSTDetailsApi(payload)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(addCompanyGSTDetailsSuccess(response));
                } else{
                    dispatch(addCompanyGSTDetailsError(response));
                }
            });
    }
};

//Add company gst details
export const GET_IP_START = 'GET_IP_START';
export const getIpstart = () => {
    return {
        type: GET_IP_START
    };
};

export const GET_IP_OK = 'GET_IP_OK';
export const getIpOk = (output) => {
    return {
        type: GET_IP_OK,
        response: output
    };
};

export const GET_IP_ERR = 'GET_IP_ERR';
export const getIpError = (output) => {
    return {
        type: GET_IP_ERR,
        output: output
    };
};

export const getIp = () => {
    return (dispatch, getState) => {
        dispatch(getIpstart());
        return SHARED_API.getIPApi()
            .then((response) => {
                if (response.status === 'ok') {
                    dispatch(getIpOk(response));
                } else {
                    dispatch(getIpError(response));
                }
            });
    }
};

//Add company gst details
export const GET_NATIONALITY_START = 'GET_NATIONALITY_START';
export const getNationalityStart = () => {
    return {
        type: GET_NATIONALITY_START
    };
};

export const GET_NATIONALITY_OK = 'GET_NATIONALITY_OK';
export const getNationalityOk = (output) => {
    return {
        type: GET_NATIONALITY_OK,
        response: output
    };
};

export const GET_NATIONALITY_ERROR = 'GET_NATIONALITY_ERROR';
export const getNationalityError = (output) => {
    return {
        type: GET_NATIONALITY_ERROR,
        output: output
    };
};

export const getNationalityDetails = () => {
    return (dispatch, getState) => {
        dispatch(getNationalityStart());
        return SHARED_API.apiNationality()
            .then((response) => {
                if (response.status === 'ok') {
                    dispatch(getNationalityOk(response));
                } else {
                    dispatch(getNationalityError(response));
                }
            });
    }
};
