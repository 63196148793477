import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../../../shared/commonHelper';

class TopUpSuccess extends Component {
    constructor(){
        super();
        this.state = {
            isError : false,
            isSuccess : false,
            debitedAmount : 0
        }
    }
    componentDidMount()
    {
        var paymentInfo = localStorage.getItem('payment_response');
        var parsedInfo = JSON.parse(paymentInfo);
        
        if(parsedInfo && parsedInfo.net_amount_debit)
        {
            this.setState({
                debitedAmount : parsedInfo.net_amount_debit
            })
        }
        let successCb = '';
        if(parsedInfo.status === 'success')
        {
            successCb = () => {
                localStorage.removeItem("payment_response");
                this.setState({
                    isSuccess : true
                })
            }
        }else{
            successCb = () => {
                localStorage.removeItem("payment_response");
                this.setState({
                    isError : true
                })
            }
        }
        const errorCb = () => {
            this.setState({
                isError : true
            })
        }
        this.props.topUpPaymentResponseAction(parsedInfo,successCb,errorCb);
    }

    render() {
        return (
            <div className="profile-detail-info">
            <div className="edit-profile-bar">
                <div className="pull-right">
                { /* eslint-disable-next-line */}
                    <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn">
                    <i className="icon icon-menu"></i>Profile Menus</a>
                </div>
            </div>
            <div className="tabs-container-detail">
                <div className="statement-info">
                    <div className="tabs-top">
                        <div className="tabs-title">
                            <h2><i className="icon icon-currency2"></i><span>Top UP</span></h2>
                        </div> 
                    </div>
                    <div className="change-pass-info top-up-tab">
                        <div className="change-pass-form">
                            <div className="loader relative-loader" style={{display: 'block'}}>
                                {
                                    this.state.isSuccess && !this.state.isError &&
                                    <div className="alt-box success-alt-box">
                                        <div className="alt-box-wrap">
                                            <div className="alt-box-icon"><span className="alt-icon"><i className="icon icon-trophy"></i></span></div>
                                            <div className="alt-box-heading">Success</div>
                                            <div className="alt-box-content">Your transaction of ₹{currencyFormat(this.state.debitedAmount)} is completed successfully!</div>
                                            <div className="alt-box-button">
                                                <Link to="/view-topup" className="btn green-btn">VIEW ALL TOPUPS</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.isSuccess && this.state.isError &&
                                    <div className="alt-box failed-alt-box">
                                        <div className="alt-box-wrap">
                                        <div className="alt-box-icon"><span className="alt-icon"><i className="icon icon-cross"></i></span></div>
                                            <div className="alt-box-heading">Error</div>
                                            <div className="alt-box-content">Error While Processing Your Transaction</div>
                                            <div className="alt-box-button">
                                                <Link to="/view-topup" className="btn green-btn">VIEW ALL TOPUPS</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.isSuccess && !this.state.isError &&
                                    <div className="loader-content">
                                        <div className="loader-processing">
                                            <div className="loader-ring blue-ring"> 
                                                <span> </span>
                                            </div>
                                            <div className="loader-processing-heading">processing</div>
                                            <div className="loader-processing-content">Please wait while we process your request...</div>
                                        </div>
                                    </div>
                                }
                                {

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default (TopUpSuccess)