import React, { Component } from 'react';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import history from '../../shared/history';
import { getCurrentDate } from '../../shared/commonHelper';
import Calender from './Calender';

class AgentCalendarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMsg: false,
      leftNavActive: '/booking-calendar',
      url: history.location.pathname,
      currentDate: getCurrentDate(),
      updateCalendar: false,
      initialApiCallDone: false,
      startDate: '',
      endDate: '',
      count: false,
    };
  }

  handleClickValue = (value) => {
    this.setState((prevState) => ({ ...prevState, leftNavActive: value }));
  };

  render() {
    const events = [];
    let tripType = '';
    let color = '#124680';
    let imageUrl = '';

    if (this.props.calendarResponse.response) {
      for (let i = 0; i < this.props.calendarResponse.response.length; i++) {
        for (let j = 0; j < this.props.calendarResponse.response[i].bookings.length; j++) {
          const calenderData = this.props.calendarResponse.response[i].bookings[j];

          if (
            calenderData.booking_status !== 'hold_confirmed' &&
            calenderData.booking_status !== 'hold_failed' &&
            calenderData.booking_status !== 'hold_pending' &&
            calenderData.booking_status !== 'hold_cancelled'
          ) {
            const data = {
              booking_id: calenderData.booking_id,
              transaction_no: calenderData.transaction_no,
              pnr: calenderData.pnr,
              booking_status: calenderData.booking_status,
              booking_type: calenderData.booking_type,
            };

            if (calenderData.trip_type === 2) tripType = 'RT';
            else if (calenderData.trip_type === 3) tripType = '1w';
            else tripType = '1w';

            if (calenderData.booking_status === 'hold_confirmed') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'hold_failed') {
              color = '#dc3545';
            } else if (calenderData.booking_status === 'ticket_confirmed') {
              color = '#124680';
            } else if (calenderData.booking_status === 'cancel_pending') {
              color = '#B22222';
            } else if (calenderData.booking_status === 'cancel_confirmed') {
              color = '#B22222';
            } else if (calenderData.booking_status === 'ticket_failed') {
              color = '#B22222';
            } else if (calenderData.booking_status === 'ticket_pending') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'hold_pending') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'partial_cancel_pending') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'partial_cancel_confirmed') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'hold_cancelled') {
              color = '#f4a934';
            } else if (calenderData.booking_status === 'confirmed') {
              color = '#124680';
            } else if (calenderData.booking_status === 'cancelled ') {
              color = '#B22222';
            } else if (calenderData.booking_status === 'pending') {
              color = '#f4a934';
            }

            if (calenderData.product_id === 1) {
              imageUrl = 'assets/images/flight-nav.svg';
            } else if (calenderData.product_id === 3) {
              imageUrl = 'assets/images/hotel-nav.svg';
            }

            if (calenderData.product_id === 1) {
              events.push({
                sectors: `${calenderData.origin}-${calenderData.destination}(${tripType})`,
                paxInfo: `${calenderData.pnr} ${calenderData.pax_title} ${calenderData.first_name} ${calenderData.last_name}`,
                start: this.props.calendarResponse.response[i].date,
                data,
                color,
                imageurl: imageUrl,
                productId: calenderData.product_id,
                textEscape: false,
              });
            } else if (calenderData.product_id === 3) {
              events.push({
                sectors: `${calenderData.city}(${calenderData.hotel_name})`,
                paxInfo: `${calenderData.no_of_rooms} ${calenderData.title} ${calenderData.first_name} ${calenderData.last_name}`,
                start: this.props.calendarResponse.response[i].date,
                data,
                color,
                imageurl: imageUrl,
                productId: calenderData.product_id,
                textEscape: false,
              });
            }
          }
        }
      }
    }

    return (
      <>
        <AgentHeaderNavigation productResponseList={this.props.productResponse} {...this.props} />
        <section className="mid-wrapper">
          <AgentCommonHeader {...this.props} />
          <div className="my-account-info">
            <div className="container">
              <div className="my-account-sec">
                <div className="my-account-outer">
                  <AgentLeftBar
                    handleClickValue={this.handleClickValue}
                    accountResponse={this.props.accountResponse}
                    activeLeftNavigation={this.state.url}
                    logoutAction={this.props.logoutAction}
                  />
                  <div className="profile-detail-info">
                    <div className="edit-profile-bar">
                      <div className="pull-right">
                        <a href="/" className="btn btn-sky-blue profile-btn" onClick={(e)=>e.preventDefault()}>
                          <i className="icon icon-menu"></i>Profile Menus
                        </a>
                      </div>
                    </div>
                    <div className="tabs-container-detail">
                      <div className="statement-info">
                        <div className="tabs-top">
                          <div className="tabs-title">
                            <h2>
                              <i className="icon icon-calendar"></i>
                              <span>Travel Calendar</span>
                            </h2>
                          </div>
                        </div>
                        <div id="example-component">
                          <Calender {...this.props} events={events} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AgentCalendarComponent;


