/* external dependencies */
import React from 'react';
import { connect } from 'react-redux';
import ContactUsComponent from '../../components/shared/ContactUsComponent';
import { contactUsAction} from '../../actions/shared/sharedAction';

class ContactUsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertClass: null,
			alertMessage : null
        };
    }

    submitContactUs = (values, actions) => {
        this.setState({error: null});

        const successCb = () => {
			this.setState({
                alertClass: 'alert alert-success',
                alertMessage: 'Your details has been submitted successfully...'
            });
        }

        const errorCb = (error) => {
            this.setState({
                alertClass: 'alert alert-danger',
                alertMessage: error
            });
            actions.setSubmitting(false);
        }

        this.props.contactUsAction(values, successCb, errorCb);
    };

    render() {
        let {settingResponse, pagesResponse} = this.props;
        return (
            <ContactUsComponent settingResponse={settingResponse} pagesResponse={pagesResponse} submitContactUs={this.submitContactUs.bind(this)} {...this.state} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contactUsResponse: state.shared.contactUsResponse,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        contactUsAction:(values,successCb,errorCb) => dispatch(contactUsAction(values,successCb,errorCb))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContainer);